import React from "react";

import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "../styles/styles.css";

interface Props {
  count: number;
  description?: string;
  loading?: boolean;
  loadingKey?: string;
}

const Counter = ({ count, description, loading, loadingKey }: Props) => {
  if (loading && loadingKey) {
    return (
      <div className={styles.boxCount}>
        <div className="countLineLoading">
          <I18nSpan msgKey={loadingKey} /> •••
        </div>
      </div>
    );
  }

  const Description = ({ description }) => {
    if (description) return <I18nSpan msgKey={description} />;
    return null;
  };

  return (
    <div className={styles.boxCount}>
      <div className={`countLine ${styles.countLine}`}>
        <div className={styles.counterContainer}>
          <span className={styles.counterCount}>{count}</span>
          <Description description={description} />
        </div>
      </div>
    </div>
  );
};

Counter.DefaultProps = {
  loadingKey: "dataTable.counter.loading",
  loading: false
};

export default Counter;
