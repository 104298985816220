import {
  PENDING_GET_COLOR_ACTION,
  SUCCESS_GET_COLOR_ACTION,
  FAILURE_GET_COLOR_ACTION,
  PENDING_GET_LOGO_ACTION,
  SUCCESS_GET_LOGO_ACTION,
  FAILURE_GET_LOGO_ACTION,
  PENDING_GET_CUSTOM_CSS_ACTION,
  SUCCESS_GET_CUSTOM_CSS_ACTION,
  SET_MODE_ACTION
} from "../actionsTypes";
import ky from "ky";

export const THEME_MODE = "themeMode";

export const getColor = () => async dispatch => {
  dispatch(getColorStart());

  const { default: F } = await import("../../commons/HTTPFetcher");

  return F.get("/customer/color").then(
    color => {
      dispatch(getColorSucceeded({ color }));
      return color;
    },
    errors => {
      dispatch(getColorFailure({ errors }));
      throw errors;
    }
  );
};

const getColorStart = () => ({
  type: PENDING_GET_COLOR_ACTION
});

export const getColorSucceeded = ({ color }) => ({
  type: SUCCESS_GET_COLOR_ACTION,
  payload: {
    color
  }
});

const getColorFailure = errors => ({
  type: FAILURE_GET_COLOR_ACTION,
  payload: {
    errors
  }
});

export const getLogo = () => async dispatch => {
  dispatch(getLogoStart());

  const _logoVersion = new Date().getTime();

  const { default: F } = await import("../../commons/HTTPFetcher");

  return F.get(`/customer/logo?v=${_logoVersion}`).then(
    logo => {
      dispatch(getLogoSucceeded({ logo }));
      return logo;
    },
    errors => {
      dispatch(getLogoFailure({ errors }));
      throw errors;
    }
  );
};

const getLogoStart = () => ({
  type: PENDING_GET_LOGO_ACTION
});

const getLogoSucceeded = ({ logo }) => ({
  type: SUCCESS_GET_LOGO_ACTION,
  payload: {
    logo
  }
});

const getLogoFailure = errors => ({
  type: FAILURE_GET_LOGO_ACTION,
  payload: {
    errors
  }
});

const getCustomCssStart = () => ({
  type: PENDING_GET_CUSTOM_CSS_ACTION
});

const getCustomCssSucceeded = ({ css }) => ({
  type: SUCCESS_GET_CUSTOM_CSS_ACTION,
  payload: {
    css
  }
});

export const getCustomCss = ({ domain }) => async dispatch => {
  dispatch(getCustomCssStart());

  try {
    const response = await ky(`/custom/${domain}/css`).text();

    dispatch(getCustomCssSucceeded({ css: response }));
  } catch {
    dispatch(getCustomCssSucceeded({ css: "" }));
  }
};

const setMode = ({ darkMode }: { mode: boolean }) => ({
  type: SET_MODE_ACTION,
  payload: {
    darkMode
  }
});

export const setDarkMode = mode => dispatch => {
  localStorage.setItem(THEME_MODE, mode);

  const darkMode = mode === "dark";

  dispatch(setMode({ darkMode }));
};
