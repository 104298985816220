import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import Validators from "../../ingenicoForm/validation/Validators";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import If from "../../components/if";
import Manufacturers from "../../pos/constants/Manufacturers";
import Poi from "../constants/PoiFieldConstants";
import ManufacturerUtils from "../../manufacturer/Utils";
import { preventSubmit } from "../commons/FormUtils";
import LinkPopin from "../../components/LinkPopin";

import {
  createPoi,
  getPoi,
  swapPoi,
  getMerchants,
  resetPoi,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  getMerchants: Function;
  createPoi: Function;
  getPoi: Function;
  swapPoi: Function;
  resetPoi: Function;
  match: any;
  history: any;
  location: any;
}

interface State {
  showLinkPopin: boolean;
  form: any;
  options: any;
  currentUser: any;
  isRestrictedByTags: boolean;
}

const PageStatus = {
  CREATE: "CREATE",
  SWAP: "SWAP"
};

const SubmitRef = "submit-ref";

export class CreateSwapPoiView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(newProps: any) {
    const { form, errors } = newProps;

    return this.setState(state => ({
      form: {
        ...form,
        ...state.form
      },
      errors
    }));
  }

  _goToList = e => {
    e.preventDefault();

    const { history } = this.props;

    return history.push("/main/settings/poi");
  };

  componentDidMount() {
    const {
      getMerchants,
      getPoi,
      location: { pathname },
      match: {
        params: { poiId }
      }
    } = this.props;
    const pageStatus = _.includes(pathname, "swap")
      ? PageStatus.SWAP
      : PageStatus.CREATE;

    if (pageStatus === PageStatus.SWAP) {
      getPoi({ id: poiId });

      return this.setState({ pageStatus });
    } else {
      getMerchants({
        filters: [],
        fields: ["merchant.id", "merchant.name"],
        sort: {
          field: "merchant.name.raw",
          order: "ASC"
        }
      });

      return this.setState(prevState => {
        return {
          ...prevState,
          merchantId: "",
          terminalReference: null,
          pageStatus
        };
      });
    }
  }

  componentWillUnmount() {
    const { resetPoi } = this.props;

    resetPoi();
  }

  _showLinkPopin = () => {
    this.setState({
      showLinkPopin: true
    });
  };

  _closeLinkPopin = () => {
    const { history } = this.props;
    history.push("/main/settings/poi");
  };

  _goToLinkPoi = () => {
    const { history } = this.props;
    const { poiId: id } = this.state;

    return history.push(`/main/settings/poi/link-poi/${id}`);
  };

  _goToCreatePos = () => {
    const { history } = this.props;
    const { poiId, form: poi } = this.state;

    const merchantId = `/${poi.merchantId?.value ?? ""}`;

    return history.push(`/main/settings/pos/new-pos/${poiId}${merchantId}`);
  };

  _saveBtnHandler = e => {
    e.preventDefault();

    this._checkErrors(() => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const data = this.state.form;
        this.isCreating()
          ? this._createTerminal(data)
          : this._swapTerminal(data);
      }
    });
  };

  _createTerminal = formData => {
    const {
      createPoi,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const data = _.clone(formData);
    data.merchantId = data.merchantId || null;

    const merchantId = data.merchantId?.value ?? data.merchantId;

    createPoi({ poi: { ...data, merchantId } })
      .then(poi => {
        const { id: poiId } = poi;

        return this.setState(
          state => {
            return {
              ...state.form,
              poiId
            };
          },
          () => {
            addNotificationSuccess("poi.create.success");
            this._showLinkPopin();
          }
        );
      })
      .catch(errorPromise =>
        errorPromise.then(errorData => addNotificationError(errorData))
      );
  };

  _swapTerminal = formData => {
    const {
      swapPoi,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const {
      match: {
        params: { poiId }
      },
      history
    } = this.props;

    const merchantId =
      formData.merchantId === null
        ? formData.merchantId
        : formData.merchantId.value;

    swapPoi({
      deprecatedPoiId: poiId,
      swappingPoi: { ...formData, merchantId }
    })
      .then(() => {
        addNotificationSuccess("poi.swap.success");
        history.push("/main/settings/poi");
      })
      .catch(errorPromise =>
        errorPromise.then(errorData => addNotificationError(errorData))
      );
  };

  _waitForValidation = (
    names: Array<string>,
    newErrors: Array<String>,
    then
  ) => {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        _.set(
          newErrors,
          name,
          _.chain(errors)
            .flatten()
            .compact()
            .value()
        );
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  };

  _checkError = name => {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  };

  _checkErrors = then => {
    this._waitForValidation(_.keys(this.props.form), {}, then);
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    _.set(newForm, name, value);
    this.setState(newForm, this._checkError(name));
  };

  _required = value => {
    return Promise.resolve(Validators.requiredValidator(value));
  };

  _noDot = value => {
    return Promise.resolve(Validators.patternValidator("^[^.]*$")(value));
  };

  _requiredAndNoDot = value => {
    return this._required(value).then(errorCode =>
      errorCode ? errorCode : this._noDot(value)
    );
  };

  _validSerialNumber = (value: string) => {
    const {
      form: { manufacturerId }
    } = this.state;

    if (manufacturerId === Manufacturers.LANDI_CODE) {
      return this._validSerialNumberLandi(value);
    }

    return this._requiredAndNoDot(value);
  };

  _validSerialNumberLandi(value: string) {
    const errorMessage = "serialNumber.landi";

    return Promise.all([
      Validators.requiredValidator(value),
      Validators.patternValidator(
        /^([A-Z0-9]{8}|[A-Z0-9]{12})$/,
        errorMessage
      )(value),
      Validators.asciiUppercaseAndNumberValidator(value)
    ]);
  }

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  isCreating = () => {
    return this.state.pageStatus === PageStatus.CREATE;
  };

  render() {
    const { showLinkPopin, form, errors } = this.state;
    const {
      options,
      isRestrictedByTags,
      currentUser: { hasPosManagement }
    } = this.props;

    const manufacturerName = ManufacturerUtils.manufacturerNameFromCode(
      form.manufacturerId
    );
    const serialNumberI18n = `poi.form.serialNumber.${
      manufacturerName ? manufacturerName.toLowerCase() : ""
    }`;

    const showFormFieldsForMerchant = ManufacturerUtils.computeShownFormFields(
      form.manufacturerId
    );
    const hideFormField = ManufacturerUtils.hideFormField(
      showFormFieldsForMerchant
    );

    const actionKey = this.isCreating()
      ? "poi.create.action"
      : "poi.swap.title";
    return (
      <div>
        <FormTitle titleKey="poi.title" actionKey={actionKey} />
        {showLinkPopin && (
          <LinkPopin
            onClosePopin={this._closeLinkPopin}
            onClickButton={this._goToLinkPoi}
            onCreatePosButton={this._goToCreatePos}
            objectName={name}
            hasPosManagement={hasPosManagement}
          />
        )}
        <form className="ingenico-form form-horizontal create-poi-form">
          <BootstrapInput
            validation={this._required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Poi.MANUFACTURER_ID)}
            name={Poi.MANUFACTURER_ID}
            errors={errors.manufacturerId}
            required={true}
            descriptor={{
              type: "select",
              label: "poi.form.manufacturerId",
              options: options.manufacturers
            }}
            formValue={form.manufacturerId}
          />
          <BootstrapInput
            validation={this._validSerialNumber}
            onChange={this._onChangeHandler}
            onKeyPress={preventSubmit(this.refs[Poi.PART_NUMBER])}
            hide={hideFormField(Poi.SERIAL_NUMBER)}
            name={Poi.SERIAL_NUMBER}
            inputRef={this.setInputRef(Poi.SERIAL_NUMBER)}
            errors={errors.serialNumber}
            required={true}
            descriptor={{ type: "text", label: serialNumberI18n }}
            formValue={form.serialNumber}
          />
          <BootstrapInput
            validation={this._noDot}
            onChange={this._onChangeHandler}
            onKeyPress={preventSubmit(
              this.isCreating()
                ? this.inputRefs[Poi.MERCHANT_ID]
                : this.refs[SubmitRef]
            )}
            hide={hideFormField(Poi.PART_NUMBER)}
            name={Poi.PART_NUMBER}
            inputRef={this.setInputRef(Poi.PART_NUMBER)}
            errors={errors.partNumber}
            required={false}
            descriptor={{ type: "text", label: "poi.form.partNumber" }}
            formValue={form.partNumber}
          />
          <If test={this.isCreating()}>
            <div>
              <BootstrapInput
                onChange={this._onChangeHandler}
                hide={hideFormField(Poi.MERCHANT_ID)}
                name={Poi.MERCHANT_ID}
                inputRef={this.setInputRef(Poi.MERCHANT_ID)}
                errors={errors.merchantId}
                required={isRestrictedByTags}
                descriptor={{
                  type: "multipleselectwindowed",
                  label: "poi.form.merchantId",
                  options: options.merchants,
                  isClearable: true
                }}
              />
            </div>
          </If>
          <div>
            <BootstrapInput
              validation={this._noDot}
              onChange={this._onChangeHandler}
              hide={hideFormField(Poi.TERMINAL_REFERENCE)}
              name={Poi.TERMINAL_REFERENCE}
              inputRef={this.setInputRef(Poi.TERMINAL_REFERENCE)}
              errors={errors.terminalReference}
              required={false}
              descriptor={{
                type: "text",
                label: "poi.form.terminalReference"
              }}
              formValue={form.terminalReference}
            />
          </div>
          <div className="pull-right">
            <button
              onClick={this._saveBtnHandler}
              className="btn btn-ingenico save-button"
              ref={SubmitRef}
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapMerchants = ({ merchants }) => {
  return _.map(merchants, merchant => {
    const {
      merchant: { name, id }
    } = merchant;

    return {
      name,
      id
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isRestrictedByTags },
    merchants: { data: prevMerchants },
    poi: { data }
  } = state;
  const {
    location: { pathname }
  } = ownProps;

  let poi;

  if (_.includes(pathname, "swap")) {
    poi = data;
  }

  const manufacturerOptions = _.zipObject(
    _(Manufacturers.ALL)
      .map(m => [m.code, m.name])
      .value()
  );
  const merchantOptions = mapMerchants({ merchants: prevMerchants });

  const hasPosManagement = _.includes(user.permissions, "posManagement");
  const options = {
    manufacturers: manufacturerOptions,
    merchants: merchantOptions
  };

  return {
    form: {
      manufacturerId: Manufacturers.INGENICO_CODE,
      serialNumber: null,
      partNumber: null,
      terminalReference: poi ? poi.terminalReference : null,
      poiId: undefined,
      merchantId: null
    },
    options,
    errors: {},
    currentUser: {
      hasPosManagement
    },
    isRestrictedByTags
  };
};

const mapDispatchToProps = dispatch => ({
  getPoi: ({ id }) => dispatch(getPoi({ id })),
  createPoi: ({ poi }) => dispatch(createPoi({ poi })),
  resetPoi: () => dispatch(resetPoi()),
  swapPoi: ({ deprecatedPoiId, swappingPoi }) =>
    dispatch(swapPoi({ deprecatedPoiId, swappingPoi })),
  getMerchants: ({ filters, fields, sort, tableCount }) =>
    dispatch(getMerchants({ filters, fields, sort, tableCount })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateSwapPoiView);
