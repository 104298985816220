import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_SERVICES_ACTION,
  SUCCESS_GET_SERVICES_ACTION,
  FAILURE_GET_SERVICES_ACTION
} from "../actionsTypes";

const hasService = (services, service) => {
  return _.includes(services, service);
};

export const hasFraudAlertService = services => {
  return hasService(services, "fraudAlert");
};

export const hasTransactionAlertService = services => {
  return hasService(services, "reportingAlert");
};

export const hasTransactionAlertSmsService = services => {
  return hasService(services, "reportingAlertSms");
};

export const hasMonitoringSimService = services => {
  return hasService(services, "simMonitoring");
};

export const getServices = () => dispatch => {
  dispatch(getServicesStart());

  return F.get("/me/services").then(
    services => {
      dispatch(getServicesSucceeded(services));
      return services;
    },
    errors => {
      dispatch(getServicesFailure({ errors }));

      throw errors;
    }
  );
};

const getServicesStart = () => ({
  type: PENDING_GET_SERVICES_ACTION
});

const getServicesSucceeded = services => ({
  type: SUCCESS_GET_SERVICES_ACTION,
  payload: {
    services
  }
});

const getServicesFailure = errors => ({
  type: FAILURE_GET_SERVICES_ACTION,
  payload: {
    errors
  }
});
