import React, { Component } from "react";
import classNames from "classnames";
import _ from "lodash";
import { connect } from "react-redux";

import HoverMenu from "../../../components/HoverMenu";
import I18nSpan from "../../../i18n/components/I18nSpan";
import Formats from "../../../commons/constants/ExportFormats";
import F from "../../../commons/HTTPFetcher";
import * as ContentTypes from "../../../commons/constants/HTTPContentTypes";

import styles from "../styles/ExportButton.css";
import { addNotificationError } from "../../../redux/actions";

interface Props {
  formats: Array<string>;
  filtersKey: string;
  buildParams: Function;
  exportUrl: string;
  canExport: boolean;
  columns?: any;
}

interface State {
  searchParams: any;
  exportLinkPath: string;
  exportLinkFilename: string;
}

class ExportButton extends Component<Props, State> {
  state = {
    searchParams: null
  };

  _decorateParamList(searchParams: any, format: string) {
    if (searchParams) {
      const cloned = _.clone(searchParams);
      cloned.format = format;
      return cloned;
    }
    return {};
  }

  _export(searchParams) {
    return () => {
      const {
        canExport,
        exportUrl,
        version,
        columns,
        addNotificationError,
        searchContext,
        filtersKey,
        buildTimePeriodParams
      } = this.props;
      if (canExport) {
        const {
          timePeriod = {},
          filtersByAdvancedSearchKey: { [filtersKey]: filters = [] } = {}
        } = searchContext;

        const { begin, end } = buildTimePeriodParams({ timePeriod });

        if (version === "new") {
          const body = {
            filters,
            columns
          };

          const { JSON: contentType } = ContentTypes;

          const url = new URL(exportUrl, PORTAL_URL);

          Object.entries({ begin, end }).forEach(param => {
            const [paramKey, paramValue] = param;

            if (paramValue) {
              return url.searchParams.append(paramKey, paramValue);
            }
          });

          return F.exportNew(`${url.pathname}${url.search}`, {
            body,
            contentType
          })
            .then(({ path, name }) =>
              this.setState(
                {
                  exportLinkPath: path,
                  exportLinkFilename: name
                },
                () => this.exportLink.click()
              )
            )
            .catch(() =>
              addNotificationError("reporting.transaction.notification.export")
            );
        }

        const token = F.jwt;

        const body = {
          token,
          filters: JSON.stringify(filters),
          begin,
          end,
          format: "csv"
        };

        const { FORM: contentType } = ContentTypes;

        return F.export(exportUrl, {
          body,
          contentType
        }).catch(() =>
          addNotificationError("reporting.transaction.notification.export")
        );
      }
    };
  }

  render() {
    const { formats, canExport } = this.props;
    const { searchParams, exportLinkPath, exportLinkFilename } = this.state;

    const { CSV } = Formats;

    const exportFormats = [
      {
        format: "CSV",
        searchParams: this._decorateParamList(searchParams, CSV),
        label: "reporting.transaction.list.button.exportCSV"
      }
    ];

    const ExportLink = ({ href, download }) => (
      <a
        ref={link => (this.exportLink = link)}
        className="hidden"
        href={href}
        download={download}
      />
    );

    const MultipleFormat = ({ exportFormats }: any) => {
      return (
        <HoverMenu
          titleKey="reporting.transaction.list.button.export"
          icon="glyphicon-download"
          trailingIcon="icon-chevron"
        >
          {exportFormats.map(exportFormat => {
            const { searchParams, label, format } = exportFormat;

            if (formats.includes(format)) {
              return (
                <li key={label}>
                  <a onClick={this._export(searchParams)}>
                    <I18nSpan msgKey={label} />
                  </a>
                  <ExportLink
                    href={exportLinkPath}
                    download={exportLinkFilename}
                  />
                </li>
              );
            }

            return null;
          })}
        </HoverMenu>
      );
    };

    const SingleFormat = ({ handleExport }: any) => {
      return (
        <div className={classNames(styles.container, "pull-right text-right")}>
          <button
            onClick={handleExport}
            className={`btn ${styles.export} btn-ingenico`}
          >
            <span
              className={classNames(
                styles.glyphiconButton,
                "glyphicon glyphicon-download"
              )}
            />
            <I18nSpan msgKey="reporting.transaction.list.button.exportToCSV" />
          </button>
          <ExportLink href={exportLinkPath} download={exportLinkFilename} />
        </div>
      );
    };

    if (canExport) {
      if (formats.length === 1) {
        const { searchParams } = exportFormats.find(
          item => item.format === formats[0]
        ) as any;

        return (
          <SingleFormat
            formats={formats}
            exportFormats={exportFormats}
            handleExport={this._export(searchParams)}
          />
        );
      }

      return <MultipleFormat formats={formats} exportFormats={exportFormats} />;
    }

    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default connect(null, mapDispatchToProps)(ExportButton);
