import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { connect } from "react-redux";

import I18nSpan from "../../../i18n/components/I18nSpan";
import TransactionDetailView from "./TransactionDetailView";
import DateFormatter from "../../../formatters/DateFormatter";
import NumberFormatter from "../../../formatters/NumberFormatter";
import Textarea from "../../../ingenicoForm/components/form/Textarea";
import TransactionConstants from "../../constants/TransactionConstants";
import Loading from "../../../components/Loading";

import {
  getTransactionDetail,
  getComment,
  editComment,
  clearTransactionDetail,
  hideTransactionDetail,
  resetCommentState,
  addNotificationSuccess,
  addNotificationError
} from "../../../redux/actions";

interface Props {
  transactionFields: any;
  form: {
    comment: string;
  };
}

interface State {
  transactionsCount: number;
  transactions: any;
  transaction: any;

  showModal: any;
  showEmpty: boolean;
  transactionFieldsWithI18n: any;
  isLoading: boolean;
  transactionIndex: number;
  form: {
    comment: string;
  };
}

class TransactionViewer extends Component<Props, State> {
  state = {
    showEmpty: false,
    form: {}
  } as State;

  componentWillReceiveProps(newProps: any) {
    const { form } = newProps;

    return this.setState({
      form
    });
  }

  close = () => {
    const {
      clearTransactionDetail,
      hideTransactionDetail,
      resetCommentState
    } = this.props;

    clearTransactionDetail();
    hideTransactionDetail();
    resetCommentState();
  };

  changeFieldVisibility = () => {
    this.setState({
      showEmpty: !this.state.showEmpty
    } as State);
  };

  previousTransaction = () => {
    const { transactionIndex } = this.props;

    this.changeTransaction(transactionIndex - 1);
  };

  nextTransaction = () => {
    const { transactionIndex } = this.props;

    this.changeTransaction(transactionIndex + 1);
  };

  changeTransaction(index) {
    const { getTransactionDetail, findComment, transactions } = this.props;

    if (index >= 0 && index < transactions.length) {
      const { transactionId } = transactions[index];

      getTransactionDetail({ id: transactionId });
      findComment({ transactionId });
    }
  }

  _onChangeHandler = (name, value) => {
    return this.setState(state => {
      return {
        ...state,
        form: {
          ...state.form,
          [name]: value
        }
      };
    }, this.checkErrors);
  };

  _saveBtnHandler = e => {
    e.preventDefault();
    const {
      transactions,
      transactionIndex,
      putComment,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const {
      form: { comment }
    } = this.state;

    const { transactionId } = transactions[transactionIndex];

    putComment({ transactionId, comment })
      .then(() => {
        addNotificationSuccess("reporting.comment.edit.success");
      })
      .catch(error => {
        const response = JSON.parse(error.responseText);

        addNotificationError(response.key);
      });
  };

  render() {
    const {
      required,
      showModal,
      isLoading,
      transactionIndex,
      transactionsCount,
      transactions,
      transaction,
      transactionFieldsWithI18n
    } = this.props;
    const {
      showEmpty,
      form: { comment }
    } = this.state;

    const transactionsCountPagination = transactions.length;

    interface CarouselControlProps {
      index: number;
      left?: boolean;
      right?: boolean;
    }

    const CarouselControl = ({ index, left, right }: CarouselControlProps) => {
      switch (true) {
        case left && index > 0: {
          return (
            <a
              className="carousel-control left"
              role="button"
              id="previous-transaction"
              onClick={this.previousTransaction}
            >
              <span>
                <i className="glyphicon glyphicon-chevron-left" />
              </span>
            </a>
          );
        }
        case right && index < transactions.length - 1: {
          return (
            <a
              className="carousel-control right"
              role="button"
              id="next-transaction"
              onClick={this.nextTransaction}
            >
              <span className="glyphicon glyphicon-chevron-right" />
            </a>
          );
        }
        default:
          return null;
      }
    };

    interface PaginationProps {
      index: number;
      count: number;
    }

    const Pagination = ({ index, count }: PaginationProps) => {
      return (
        <div>
          <span>{index + 1}</span>
          <span> / </span>
          <span>{count}</span>
        </div>
      );
    };

    if (transactionsCount > 0) {
      return (
        <div>
          <Modal
            show={showModal}
            onHide={this.close}
            animation={false}
            dialogClassName="transaction-detail-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <I18nSpan msgKey={"reporting.transaction.detail"} />
                <label id="input-show-empty">
                  <input
                    type="checkbox"
                    checked={showEmpty}
                    onChange={this.changeFieldVisibility}
                  />
                  <I18nSpan msgKey="reporting.transaction.form.showEmpty" />
                </label>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Loading isLoading={isLoading}>
                <CarouselControl index={transactionIndex} left={true} />

                <TransactionDetailView
                  transaction={transaction}
                  hideEmpty={!showEmpty}
                  fields={transactionFieldsWithI18n}
                />

                <div className="comment-update form-group row">
                  <label className="text-right-not-xs col-sm-4 col-xs-12">
                    <I18nSpan msgKey="reporting.transaction.form.comment" />
                  </label>
                  <div className="col-sm-8 col-xs-12">
                    <Textarea
                      name="comment"
                      validation={() => undefined}
                      required={required || false}
                      value={comment}
                      onChange={this._onChangeHandler}
                    />
                  </div>
                  <div className="pull-right">
                    <button
                      onClick={this._saveBtnHandler}
                      className="btn btn-ingenico save-button"
                    >
                      <I18nSpan msgKey={"button.label.ok"} />
                    </button>
                    <button
                      onClick={this.close}
                      className="btn btn-ingenico btn-ingenico-alert exit-button"
                    >
                      <I18nSpan msgKey="button.label.exit" />
                    </button>
                  </div>
                </div>

                <CarouselControl index={transactionIndex} right={true} />
              </Loading>
            </Modal.Body>
            <Modal.Footer>
              <Pagination
                index={transactionIndex}
                count={transactionsCountPagination}
              />
            </Modal.Footer>
          </Modal>
        </div>
      );
    }

    return null;
  }
}

const currencySymbolfields = [
  "transactionAmount",
  "supplementaryAmount",
  "amountAuthorised",
  "amountOther",
  "cashbackAmount",
  "chargeAmount"
];

const dccCurrencySymbolFields = [
  "dccTransactionAmount",
  "dccSupplementaryAmount",
  "dccAmountAuthorised",
  "dccAmountOther"
];

const dateTimeFields = [
  "terminalTransactionTime",
  "clientCreationTime",
  "serverTransactionTime"
];

const dateTimeUtcFields = ["mshTransactionTime"];

const computeCurrencySymbol = ({ transaction }: any) => {
  const fields = currencySymbolfields.reduce((acc: any, fieldname: string) => {
    const {
      [fieldname]: transactionField,
      currencyCodeAlpha3,
      currencySymbol,
      currencyExponent
    } = transaction;
    if (transactionField) {
      acc[fieldname] = NumberFormatter.formatAmount(
        transactionField,
        [currencyCodeAlpha3, currencySymbol].join(""),
        currencyExponent
      );
    }
    return acc;
  }, {});

  return {
    ...transaction,
    ...fields
  };
};

const computeDccCurrencySymbol = ({ transaction }: any) => {
  const fields = dccCurrencySymbolFields.reduce(
    (acc: any, fieldname: string) => {
      const {
        [fieldname]: transactionField,
        dccCurrencyCodeAlpha3,
        dccCurrencySymbol,
        dccCurrencyExponent
      } = transaction;

      if (transactionField) {
        acc[fieldname] = NumberFormatter.formatAmount(
          transactionField,
          [dccCurrencyCodeAlpha3, dccCurrencySymbol].join(""),
          dccCurrencyExponent
        );
      }

      return acc;
    },
    {}
  );

  return {
    ...transaction,
    ...fields
  };
};

const computeDateTimeFields = ({ transaction, user }: any) => {
  const fields = dateTimeFields.reduce((acc: any, fieldname: string) => {
    const { [fieldname]: transactionField } = transaction;

    if (transactionField) {
      acc[fieldname] = DateFormatter.formatDateTime(transactionField, user);
    }

    return acc;
  }, {});

  return {
    ...transaction,
    ...fields
  };
};

const computeDateTimeUtcFields = ({ transaction, user }: any) => {
  const fields = dateTimeUtcFields.reduce((acc: any, fieldname: string) => {
    const { [fieldname]: transactionField } = transaction;

    if (transactionField) {
      acc[fieldname] = DateFormatter.formatDateTimeUtc(transactionField, user);
    }

    return acc;
  }, {});

  return {
    ...transaction,
    ...fields
  };
};

const getTransactionIndex = ({ transactions, transaction }) => {
  return _.findIndex(
    transactions,
    (t: any) => t.transactionId === transaction.transactionId
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    transactions: {
      data: transactions,
      count: transactionsCount,
      loading: isLoading,
      detail: transactionDetail,
      showTransactionDetail
    },
    comment: { data: comment }
  } = state;

  const { transactionFields, extraTransactionFields } = ownProps;
  const transactionFieldsKeys = _.union(
    transactionFields,
    extraTransactionFields
  );

  const transactionFieldsI18n = _(transactionFieldsKeys)
    .map(field => `${TransactionConstants.I18N_PREFIX}.${field}.label`)
    .value();

  const transactionFieldsWithI18n = _.zipWith(
    transactionFieldsKeys,
    transactionFieldsI18n,
    (field, field18n) => ({
      name: field,
      i18nKey: field18n
    })
  );

  let transaction = transactionDetail;

  transaction = computeCurrencySymbol({ transaction });
  transaction = computeDccCurrencySymbol({ transaction });
  transaction = computeDateTimeFields({ transaction, user });
  transaction = computeDateTimeUtcFields({ transaction, user });

  const transactionIndex = getTransactionIndex({ transactions, transaction });

  return {
    transaction,
    transactionsCount,
    transactions,
    transactionIndex,
    transactionFieldsWithI18n,
    user,
    form: {
      comment: (comment || { value: "" }).value
    },
    showModal: showTransactionDetail,
    isLoading
  };
};

const mapDispatchToProps = dispatch => ({
  getTransactionDetail: ({ id }) => dispatch(getTransactionDetail({ id })),
  findComment: ({ transactionId }) =>
    dispatch(getComment({ commentId: transactionId })),
  putComment: ({ transactionId, comment }) =>
    dispatch(editComment({ commentId: transactionId, comment })),
  clearTransactionDetail: () => dispatch(clearTransactionDetail()),
  hideTransactionDetail: () => dispatch(hideTransactionDetail()),
  resetCommentState: () => dispatch(resetCommentState()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionViewer);
