import React from "react";
import { withRouter } from "react-router";
import * as diff from "diff";
import { connect } from "react-redux";
import { compose } from "redux";

import TransactionUtils from "../transactions/Utils";
import TransactionPanel from "./TransactionPanel";
import I18nSpan from "../../i18n/components/I18nSpan";
import FormTitle from "../../ingenicoForm/components/FormTitle";

import {
  getTransactionDetail,
  mergeTransactionConflict,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface IProps {
  originalTransaction: any;
  transactionWithConflictData: any;
  getTransactionDetail: Function;
}

interface IState {
  isSourceSelected?: boolean;
  selectedSource: any;
}

class ResolveConflict extends React.Component<IProps, IState> {
  state = {
    isSourceSelected: false
  };

  componentDidMount() {
    const {
      match: {
        params: { transactionId: id }
      },
      getTransactionDetail
    } = this.props;

    getTransactionDetail({ id });
  }

  onSelect = () => selectedSource => {
    console.log("onSelect");
    this.setState({
      ...this.state,
      isSourceSelected: true,
      selectedSource
    });
  };

  goConflictList = () => {
    const { history } = this.props;

    history.push("/main/reporting/conflicts");
  };

  mergeConflict = () => {
    const { selectedSource: resolutionSource } = this.state;

    if (resolutionSource) {
      const {
        match: {
          params: { transactionId }
        },
        history,
        mergeTransactionConflict,
        addNotificationSuccess,
        addNotificationError
      } = this.props;

      mergeTransactionConflict({
        transactionId,
        resolutionSource
      }).then(
        () => {
          addNotificationSuccess("reporting.resolveConflict.success");
          history.push("/main/reporting/conflicts"); // Reload alerts
        },
        () => addNotificationError("reporting.resolveConflict.error")
      );
    }
  };

  render() {
    const { transactionWithConflictData } = this.props;
    const { selectedSource, isSourceSelected } = this.state;
    const originalTransaction = _.cloneDeep(this.props.originalTransaction);
    delete originalTransaction.intersectedData;
    const originalTransactionString = JSON.stringify(
      originalTransaction,
      null,
      4
    );
    const transactionConflict = JSON.stringify(
      transactionWithConflictData,
      null,
      4
    );

    const result = diff.diffLines(
      originalTransactionString,
      transactionConflict
    );
    const removedElements = result.filter(element => !element.added);
    const addedElements = result.filter(element => !element.removed);
    return (
      <div>
        <FormTitle
          titleKey="reporting.resolveConflict.title"
          actionKey="reporting.resolveConflict.msg"
        />
        <div id="transaction-panels">
          <div>
            <I18nSpan msgKey="reporting.resolveConflict.fromMsh" />
            <TransactionPanel
              elements={removedElements}
              onSelect={this.onSelect("msh")}
              selected={selectedSource === "msh"}
            />
          </div>
          <div>
            <I18nSpan msgKey="reporting.resolveConflict.fromDistant" />
            <TransactionPanel
              elements={addedElements}
              onSelect={this.onSelect("terminal")}
              selected={selectedSource === "terminal"}
            />
          </div>
        </div>
        <div className="pull-right">
          <button
            onClick={this.mergeConflict}
            className="btn btn-ingenico resolve-button"
            disabled={isSourceSelected === null}
          >
            <I18nSpan msgKey="button.label.resolve" />
          </button>
          <button onClick={this.goConflictList} className="btn btn-ingenico">
            <I18nSpan msgKey="button.label.exit" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    transactions: { detail: originalTransaction }
  } = state;

  const transactionWithConflictData = TransactionUtils.getTransactionWithConflictData(
    originalTransaction
  );

  return {
    originalTransaction,
    transactionWithConflictData
  };
};

const mapDispatchToProps = dispatch => ({
  getTransactionDetail: ({ id }) => dispatch(getTransactionDetail({ id })),
  mergeTransactionConflict: ({ transactionId, resolutionSource }) =>
    dispatch(mergeTransactionConflict({ transactionId, resolutionSource })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ResolveConflict);
