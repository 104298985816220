import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { requiredMinLength } from "../validations/MerchantValidations";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import Validators from "../../ingenicoForm/validation/Validators";
import I18nSpan from "../../i18n/components/I18nSpan";
import TagInput from "../../ingenicoForm/components/TagInput";
import TagUtil from "../../tag/model/TagUtil";
import SelectedTag from "../../tag/model/SelectedTag";

import {
  editMerchant,
  getMerchant,
  resetMerchantState,
  getTags,
  getPrograms,
  getCountries,
  getMerchantCategoryCodes,
  resetTagsState,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

import constants from "../../connection/constants/ConnectionConstant";

interface Props {
  history: any;
  editMerchant: Function;
  getMerchant: Function;
  resetMerchantState: Function;
  getPrograms: Function;
  getCountries: Function;
  getMerchantCategoryCodes: Function;
  getTags: Function;
  initForm: Function;
  match: any;
  form: any;
  options: any;
}

interface State {
  errors: any;
}

class EditMerchantView extends Component<Props, State> {
  state = {
    form: {
      name: null,
      programId: null,
      merchantReference: null,
      merchantCategoryCode: null,
      address: null,
      city: null,
      zipCode: null,
      state: null,
      country: null,
      countryOfEstablishment: null,
      commercialContact: null,
      supportContact: null,
      userLogin: null,
      userEmail: null,
      selectedTags: new Map<string, SelectedTag>(),
      applicationSettings: {}
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  async componentDidMount() {
    const {
      getMerchant,
      getTags,
      getPrograms,
      getCountries,
      getMerchantCategoryCodes,
      match: {
        params: { merchantId }
      }
    } = this.props;

    await Promise.all([
      getTags(),
      getMerchant(merchantId),
      getPrograms(),
      getCountries(),
      getMerchantCategoryCodes()
    ]);
  }

  componentWillReceiveProps(nextProps) {
    const { form, errors } = nextProps;

    return this.setState({
      form,
      errors
    });
  }

  componentWillUnmount() {
    const { resetMerchantState } = this.props;
    resetMerchantState();
  }

  _updateMerchant = e => {
    e.preventDefault();

    const {
      editMerchant,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const { form: merchant } = this.state;

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];

        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        try {
          const selectedTags = TagUtil.selectedTagsToObject(
            merchant.selectedTags
          );
          editMerchant({
            merchant: {
              ...merchant,
              selectedTags
            }
          });
          addNotificationSuccess("merchant.edit.success");

          this._goToList();
        } catch (error) {
          const { key: errorKey } = await error;
          const notificationErrorKey = `notification.merchant.error.${errorKey}`;

          addNotificationError(notificationErrorKey);
        }
      }
    });
  };

  _onChangeHandler = (name: string, value: string) => {
    const newForm = this.state.form;
    newForm[name] = value === "" ? null : value;
    this.setState(newForm, this._checkError(name));
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [_.noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _checkError(name: string) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  }

  _onExit = e => {
    e.preventDefault();
    this._goToList();
  };

  _goToList = () => {
    this.props.history.push("/main/settings/merchant");
  };

  render() {
    const { form, errors } = this.state;
    const { options, t } = this.props;

    return (
      <div>
        <FormTitle titleKey="merchant.title" actionKey="merchant.edit.action" />
        <form className="ingenico-form form-horizontal edit-merchant-form">
          <BootstrapInput
            validation={requiredMinLength(t(`merchant.form.name.label`))}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            errors={errors.name}
            required={true}
            descriptor={{
              type: "text",
              label: "merchant.form.name.label",
              placeholder: "merchant.form.name.placeholder"
            }}
            formValue={form.name}
          />
          <BootstrapInput
            validation={() => undefined}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("programId")}
            name="programId"
            errors={errors.programId}
            required={false}
            descriptor={{
              type: "singleautocomplete",
              label: "merchant.form.program.label",
              options: options.programs
            }}
            formValue={form.programId}
          />
          <BootstrapInput
            validation={() => undefined}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("merchantReference")}
            name="merchantReference"
            errors={errors.merchantReference}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.merchantReference.label"
            }}
            formValue={form.merchantReference}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("countryOfEstablishment")}
            name="countryOfEstablishment"
            errors={errors.countryOfEstablishment}
            required={false}
            descriptor={{
              type: "singleautocomplete",
              label: "merchant.form.countryOfEstablishment.label",
              options: options.countriesOfEstablishment
            }}
            formValue={form.countryOfEstablishment}
          />
          <BootstrapInput
            descriptor={{
              type: "singleautocomplete",
              label: "merchant.form.merchantCategoryCode.label",
              options: options.merchantCategoryCodes,
              allowFreeText: true
            }}
            inputRef={this.setInputRef("merchantCategoryCode")}
            name="merchantCategoryCode"
            onChange={this._onChangeHandler}
            validation={value => {
              if (!options.merchantCategoryCodes.find(i => i.value === value))
                return Validators.patternValidator(/^(\d){4}\b/)(value);
            }}
            formValue={form.merchantCategoryCode}
            errors={errors.merchantCategoryCode}
            required={false}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("address")}
            name="address"
            errors={errors.address}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.address.label"
            }}
            formValue={form.address}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("city")}
            name="city"
            errors={errors.city}
            required={false}
            descriptor={{ type: "text", label: "merchant.form.city.label" }}
            formValue={form.city}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("zipCode")}
            name="zipCode"
            errors={errors.zipCode}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.zipCode.label"
            }}
            formValue={form.zipCode}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("state")}
            name="state"
            errors={errors.state}
            required={false}
            descriptor={{ type: "text", label: "merchant.form.state.label" }}
            formValue={form.state}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("country")}
            name="country"
            errors={errors.country}
            required={false}
            descriptor={{
              type: "singleautocomplete",
              label: "merchant.form.country.label",
              options: options.countries
            }}
            formValue={form.country}
          />
          {!_.isEmpty(options.tags) && (
            <div name="selectedTags" className="form-group">
              <label className="tag-header-merchant control-label col-sm-3">
                Tags
              </label>
              <TagInput
                key={"selectedTags.Tag"}
                name="selectedTags"
                tags={options.tags}
                formValue={form.selectedTags}
                onChange={this._onChangeHandler}
              />
            </div>
          )}

          <BootstrapInput
            descriptor={{
              type: "jsonarea",
              label: "store.form.applicationSettings.label"
            }}
            inputRef={this.setInputRef("applicationSettings")}
            name="applicationSettings"
            onChange={this._onChangeHandler}
            formValue={form.applicationSettings}
            errors={errors.applicationSettings}
          />

          <div className="pull-right">
            <button
              onClick={this._updateMerchant}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._onExit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapCountries = ({ countries }) => {
  return _.map(countries, country => {
    const { display: label, code: value } = country;
    return {
      label,
      value
    };
  });
};

const mapCountriesOfEstablishment = ({ countries }) => {
  return _.map(countries, countryOfEstablishment => {
    const { display: label, code: value } = countryOfEstablishment;
    return {
      label,
      value
    };
  });
};

const mapMerchantCategoryCodes = ({ merchantCategoryCodes }) => {
  return _.map(merchantCategoryCodes, merchantCategoryCode => {
    const { mcc: label, mcc: value } = merchantCategoryCode;
    return {
      label,
      value
    };
  });
};

const mapPrograms = ({ programs }) => {
  return _.map(programs, program => {
    const { name: label, id: value } = program;
    return {
      label,
      value
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    tags: { data: tags },
    countries: { data: prevCountries },
    programs: { data: prevPrograms },
    merchantCategoryCodes: { data: prevMerchantCategoryCodes },
    merchant: { data: merchant }
  } = state;
  const {
    scope: {
      level: { type: levelType }
    }
  } = user;

  const countries = mapCountries({ countries: prevCountries });
  const programs = mapPrograms({ programs: prevPrograms });
  const countriesOfEstablishment = mapCountriesOfEstablishment({
    countries: prevCountries
  });
  const merchantCategoryCodes = mapMerchantCategoryCodes({
    merchantCategoryCodes: prevMerchantCategoryCodes
  });

  const computeTags = ({ data }) => {
    const userTags = TagUtil.getSelectedTags(user);
    _(data.selectedTags)
      .keys()
      .forEach(key => {
        if (!userTags.has(key)) {
          userTags.set(key, {
            name: key,
            value: data.selectedTags[key],
            fixed: false
          });
        }
      })
      .value();

    return {
      ...data,
      selectedTags: userTags
    };
  };

  if (merchant) {
    if (!merchant.applicationSettings) {
      merchant.applicationSettings = {};
    } else {
      delete merchant.applicationSettings.amazonPayToken;
    }
  }

  const form = computeTags({ data: merchant });

  const options = {
    countries,
    countriesOfEstablishment,
    merchantCategoryCodes,
    tags,
    programs
  };

  return {
    levelType,
    options,
    form,
    errors: {}
  };
};

const mapDispatchToProps = dispatch => ({
  editMerchant: ({ merchant }) => dispatch(editMerchant({ merchant })),
  getMerchant: id => dispatch(getMerchant(id)),
  resetMerchantState: () => dispatch(resetMerchantState()),
  getPrograms: () => dispatch(getPrograms()),
  getCountries: () => dispatch(getCountries()),
  getMerchantCategoryCodes: () => dispatch(getMerchantCategoryCodes()),
  getTags: ({ tagType }) => dispatch(getTags({ tagType })),
  resetTags: ({ tagType }) => dispatch(resetTagsState({ tagType })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { levelType } = stateProps;

  switch (levelType) {
    case constants.CUSTOMER: {
      const tagType = constants.CUSTOMER_NEW;
      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    case constants.MERCHANT: {
      const tagType = constants.MERCHANT;
      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    default:
      break;
  }
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(EditMerchantView);
