import { AggData, Aggregation, AggregationsByCriteria } from "../models/Widget";
import { HighchartsData } from "../../chart/models/HighchartData";

export const replaceIdByName = (
  propertyName: string,
  aggregationsByCriteria: AggregationsByCriteria
): Array<HighchartsData> => {
  const { criteria } = aggregationsByCriteria;

  const { [criteria]: aggregation } = aggregationsByCriteria;

  if (criteria && aggregation) {
    const aggForHighcharts = aggregation.buckets.map((agg: Aggregation) => {
      const aggData: AggData = agg[`${propertyName}-on-transaction-amount`];

      if (aggData) {
        const { key: id } = agg;
        const { value: y } = aggData;

        return {
          id,
          y,
          value: y
        };
      }

      return {};
    });

    return aggForHighcharts.map((shard: HighchartsData) => {
      return {
        ...shard,
        name: shard.id
      };
    });
  }

  return [];
};
