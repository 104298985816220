export abstract class CreatePredefinedAlert {
  constructor(public type: PredefinedAlertType) {
    this.type = type;
  }

  isValid(): Array<string> {
    return Array();
  }
}

export abstract class PredefinedAlert {
  abstract i18nKey(): string;

  abstract i18nArgs(locale: string): Array<string>;
}

export type PredefinedAlertType =
  | "PayThenRefund"
  | "MultipleUsage"
  | "RefundOverThreshold"
  | "RefundOutsidePolicy"
  | "CompleteRefundOutsidePolicy"
  | "RefundTooHigh"
  | "TransactionOnClosingHours";

export const languages = {
  he: {
    w: function() {
      return "weeks";
    },
    d: function() {
      return "days";
    },
    h: function() {
      return "hours";
    },
    m: function() {
      return "minutes";
    },
    s: function() {
      return "seconds";
    }
  },
  th: {
    w: function() {
      return "weeks";
    },
    d: function() {
      return "days";
    },
    h: function() {
      return "hours";
    },
    m: function() {
      return "minutes";
    },
    s: function() {
      return "seconds";
    }
  }
};
