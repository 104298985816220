import React from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import UserValidations from "../validations/UserValidations";
import Validators from "../../ingenicoForm/validation/Validators";

import {
  createMposUser,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface IState {
  errors: any;
  form: any;
}

export class CreateMposUserView extends React.Component<any, IState> {
  state: IState = {
    form: {
      email: "",
      login: "",
      permissions: ["mPosEnrollment"],
      scope: {
        access: {
          full: true
        }
      },
      services: null,
      applicationSettings: {
        mPos: {}
      }
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ ...this.state, errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _noop() {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  }

  goToUserList = () => {
    this.props.history.push("/main/settings/user");
  };

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), [], then);
  }

  saveMposUser = async e => {
    e.preventDefault();
    const {
      saveMposUser,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        try {
          const defaultApplicationSettings = { mPos: {} };
          const newMposUser = {
            ...this.state.form,
            applicationSettings: this.state.form.applicationSettings
              ? this.state.form.applicationSettings
              : defaultApplicationSettings
          };
          await saveMposUser(newMposUser);

          return Promise.all([
            addNotificationSuccess("user.create.success"),
            this.goToUserList()
          ]);
        } catch (error) {
          addNotificationError("notification.error");
        }
      }
    });
  };

  _checkError(name) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _onChangeHandler = (name, value) => {
    this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value
        }
      };
    }, this._checkError(name));
  };

  _loginValidation = value => {
    const { t } = this.props;
    const translation = t("user.form.login.label");

    switch (true) {
      case value.length === 0:
        return Validators.requiredValidator(value);
      case value.length < 2:
        return Validators.sizeOfStringValidator(2, translation)(value);
      case value.length > 20:
        return Validators.maxStringValidator(20, translation)(value);
      default: {
        return Promise.all([
          UserValidations.plainLogin(value),
          UserValidations.loginUnicity(value)
        ]);
      }
    }
  };

  _emailStyle = value =>
    Promise.all([
      Validators.requiredValidator(value),
      Validators.emailValidator(value)
    ]);

  render() {
    return (
      <div>
        <FormTitle
          titleKey="mposuser.title"
          actionKey="mposuser.create.action"
        />
        <form className="ingenico-form form-horizontal users-form">
          <BootstrapInput
            descriptor={{
              id: "loginUser",
              type: "text",
              label: "user.form.login.label",
              placeholder: "user.form.login.placeholder"
            }}
            inputRef={this.setInputRef("login")}
            name="login"
            onChange={this._onChangeHandler}
            required={true}
            formValue={this.state.form.login}
            validation={this._loginValidation}
            errors={this.state.errors.login}
          />
          <BootstrapInput
            descriptor={{
              id: "emailUser",
              type: "email",
              label: "user.form.email.label"
            }}
            inputRef={this.setInputRef("email")}
            name="email"
            onChange={this._onChangeHandler}
            required={true}
            formValue={this.state.form.email}
            validation={this._emailStyle}
            errors={this.state.errors.email}
          />
          <BootstrapInput
            descriptor={{
              type: "jsonarea",
              label: "user.form.applicationSettings.label"
            }}
            inputRef={this.setInputRef("applicationSettings")}
            name="applicationSettings"
            onChange={this._onChangeHandler}
            formValue={this.state.form.applicationSettings}
            errors={this.state.errors.applicationSettings}
          />
          <div className="pull-right">
            <button
              onClick={this.saveMposUser}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this.goToUserList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveMposUser: mposUser => dispatch(createMposUser(mposUser)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withTranslation(),
  connect(null, mapDispatchToProps)
)(CreateMposUserView);
