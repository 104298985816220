import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import { getScrollStart, getScrollFailure, getScrollSucceeded } from "./scroll";

import {
  PENDING_GET_TAX_AUDIT_LOG_ACTION,
  SUCCESS_GET_TAX_AUDIT_LOG_ACTION,
  FAILURE_GET_TAX_AUDIT_LOG_ACTION,
  RESET_TAX_AUDIT_LOG_ACTION,
  PENDING_GET_TAX_AUDIT_LOG_DETAILS_ACTION,
  SUCCESS_GET_TAX_AUDIT_LOG_DETAILS_ACTION,
  FAILURE_GET_TAX_AUDIT_LOG_DETAILS_ACTION,
  RESET_TAX_AUDIT_LOG_DETAILS_ACTION
} from "../actionsTypes";

export const getPayload = ({ id }) => async dispatch => {
  dispatch(getPayloadStart());
  try {
    const url = new URL("/pub/fiscal/blockchain/fsl", PORTAL_URL);

    url.searchParams.append("fslEntryId", id);

    const response = await F.get(`${url.pathname}${url.search}`);

    dispatch(getPayloadSucceeded());
    return response;
  } catch (errors) {
    dispatch(getPayloadFailure({ errors }));
    return null;
  }
};

export const getPayloadStart = () => ({
  type: PENDING_GET_TAX_AUDIT_LOG_DETAILS_ACTION
});

export const getPayloadSucceeded = () => ({
  type: SUCCESS_GET_TAX_AUDIT_LOG_DETAILS_ACTION
});

export const getPayloadFailure = errors => ({
  type: FAILURE_GET_TAX_AUDIT_LOG_DETAILS_ACTION,
  payload: {
    errors
  }
});

export const resetPayload = () => ({
  type: RESET_TAX_AUDIT_LOG_DETAILS_ACTION
});

export const getLogs = ({
  filters = [],
  fields,
  sort = { field: "eventCreateDate", order: "DESC" },
  tableCount = 50,
  scrollOffset = 0,
  timePeriod,
  merchantId
}) => async dispatch => {
  const initialRequest = scrollOffset === 0;

  dispatch(
    initialRequest ? getLogsStart() : getScrollStart({ type: "TAX_AUDIT_LOG" })
  );
  try {
    const url = new URL("/pub/fiscal/blockchain/fsl", PORTAL_URL);

    if (timePeriod) {
      filters = filters.filter(({ name }) => name !== "eventCreateDate");
      filters.push({
        name: "eventCreateDate",
        operator: "BETWEEN",
        value: [timePeriod.startTime.valueOf(), timePeriod.endTime.valueOf()]
      });
    }

    filters = filters.filter(({ name }) => name !== "ecrContext.merchantId");
    filters.push({
      name: "ecrContext.merchantId",
      operator: "EQUAL",
      value: [`${merchantId}`]
    });

    const response = await F.post(`${url.pathname}${url.search}`, {
      body: {
        filters,
        fields,
        sort,
        page: {
          number: scrollOffset,
          limit: tableCount
        }
      },
      contentType: ContentTypes.JSON
    });
    return dispatch(
      initialRequest
        ? getLogsSucceeded({
            count: response ? response.length : 0,
            scrollId: scrollOffset,
            data: response
          })
        : getScrollSucceeded({ type: "TAX_AUDIT_LOG", data: response })
    );
  } catch (errors) {
    dispatch(
      initialRequest
        ? getLogsFailure({ errors })
        : getScrollFailure({ type: "TAX_AUDIT_LOG", errors })
    );
  }
};

export const getLogsStart = () => ({
  type: PENDING_GET_TAX_AUDIT_LOG_ACTION
});

export const getLogsSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_TAX_AUDIT_LOG_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

export const getLogsFailure = errors => ({
  type: FAILURE_GET_TAX_AUDIT_LOG_ACTION,
  payload: {
    errors
  }
});

export const resetLogs = () => ({
  type: RESET_TAX_AUDIT_LOG_ACTION
});
