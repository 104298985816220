import React, { Component } from "react";
import Autocomplete from "../../components/Autocomplete";
import { OptionItem } from "../model/OptionItem";

interface Props {
  name: string;
  readOnly?: boolean;
  items: Array<OptionItem>;
  value?: string;
  onChange: (name: string, value: string, target) => void;
  validation?: (value: string) => boolean;
  allowFreeText?: boolean;
}

export default class SingleAutocomplete extends Component<Props, {}> {
  onChange = (selectedItems: Array<OptionItem>) => {
    const selectedValue =
      selectedItems.length > 0 ? selectedItems[0].value : null;
    this.props.onChange(this.props.name, selectedValue, this);
  };

  render() {
    const { items, value, name, readOnly, allowFreeText } = this.props;
    const lists = {
      beginBy: "properties",
      properties: {
        options: items
      }
    };
    const createdItems = value
      ? items.find(i => i.value === value)
        ? [items.find(i => i.value === value)]
        : allowFreeText
        ? [{ label: value, value }]
        : null
      : null;

    return (
      <div id={name}>
        <Autocomplete
          className="no-margin"
          lists={lists}
          nbItemsMax={1}
          disabled={readOnly}
          createdItems={createdItems}
          onChange={this.onChange}
          createdItemLabelFormatter={(item: OptionItem) =>
            item ? item.label : ""
          }
          allowFreeText={allowFreeText}
          single={true}
        />
      </div>
    );
  }
}
