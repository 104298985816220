import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_POIS_LIST_ACTION,
  SUCCESS_GET_POIS_LIST_ACTION,
  FAILURE_GET_POIS_LIST_ACTION,
  PENDING_GET_POIS_ACTION,
  SUCCESS_GET_POIS_ACTION,
  FAILURE_GET_POIS_ACTION,
  PENDING_DELETE_POI_ACTION,
  FAILURE_DELETE_POI_ACTION,
  RESET_POIS_ACTION
} from "../actionsTypes";

export const getPoisList = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getPoisListStart());

  const url = new URL("/poi/search", PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    ({ count, scrollId, searchResults: data }) => {
      dispatch(getPoisListSucceeded({ count, scrollId, data }));

      return data;
    },
    errors => {
      dispatch(getPoisListFailure({ errors }));

      throw errors;
    }
  );
};

const getPoisListStart = () => ({
  type: PENDING_GET_POIS_LIST_ACTION
});

const getPoisListSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_POIS_LIST_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getPoisListFailure = errors => ({
  type: FAILURE_GET_POIS_LIST_ACTION,
  payload: {
    errors
  }
});

export const getPois = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getPoisStart());

  const url = new URL("/poi/search", PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    ({ searchResults: data }) => {
      dispatch(getPoisSucceeded({ data }));

      return data;
    },
    errors => {
      dispatch(getPoisFailure({ errors }));

      throw errors;
    }
  );
};

const getPoisStart = () => ({
  type: PENDING_GET_POIS_ACTION
});

const getPoisSucceeded = ({ data }) => ({
  type: SUCCESS_GET_POIS_ACTION,
  payload: {
    data
  }
});

const getPoisFailure = errors => ({
  type: FAILURE_GET_POIS_ACTION,
  payload: {
    errors
  }
});

export const deletePois = ({ poiIds }) => async dispatch => {
  dispatch(deletePoisStart());

  try {
    return await F.delete(`/poi/batch`, {
      contentType: ContentTypes.JSON,
      body: poiIds
    });
  } catch (errors) {
    dispatch(deletePoisFailure({ errors }));
    throw errors;
  }
};

const deletePoisStart = () => ({
  type: PENDING_DELETE_POI_ACTION
});

const deletePoisFailure = errors => ({
  type: FAILURE_DELETE_POI_ACTION,
  payload: {
    errors
  }
});

export const resetPois = () => ({
  type: RESET_POIS_ACTION
});
