import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../i18n/components/I18nSpan";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import {
  unlink,
  link,
  addNotificationSuccess,
  getPois,
  setSearchContext
} from "../../redux/actions";

interface Props {
  unlink: Function;
  link: Function;
  getPois: Function;
  addNotificationSuccess: Function;
  linkedPos: any;
  poi: any;
  hasTerminalManagement: boolean;
  onUnlink: Function;
}

const advancedSearchKey = AdvancedSearchConstants.SETTINGS_TERMINAL_KEY;

class LinkPosToPoi extends Component<Props, {}> {
  state = {
    form: {
      posId: ""
    }
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm);
  };

  _onButtonClick = () => {
    return () => {
      const {
        linkedPos,
        poi,
        link,
        unlink,
        addNotificationSuccess,
        onUnlink
      } = this.props;
      if (linkedPos) {
        unlink({ poiId: poi["poi.id"] })
          .then(() => addNotificationSuccess("poi.unlink.success"))
          .then(() => onUnlink());
      } else {
        const {
          form: { posId }
        } = this.state;
        link({ poiId: poi["poi.id"], posId })
          .then(() => addNotificationSuccess("poi.link.success"))
          .then(() => onUnlink());
      }
    };
  };

  redirectToPos = linkedPos => {
    const { history, setSearchContext } = this.props;

    const filters = [
      {
        filterType: "array",
        name: "pos.name",
        operator: "=",
        value: [linkedPos.name]
      }
    ];
    const searchContext = {
      key: AdvancedSearchConstants.POS_KEY,
      filters
    };

    setSearchContext({
      context: searchContext,
      pathname: "pos",
      updateUrl: true
    });
    return history.push("/main/settings/pos");
  };

  render() {
    const { linkedPos, hasTerminalManagement } = this.props;

    let linkedClassName = "glyphicon ";
    let posInfo;

    if (linkedPos) {
      linkedClassName += "glyphicon-minus-sign";

      posInfo = (
        <div>
          <a onClick={() => this.redirectToPos(linkedPos)}>{linkedPos.name}</a>
          {hasTerminalManagement ? (
            <a onClick={this._onButtonClick()}>
              <span className={linkedClassName} aria-hidden="true" />
            </a>
          ) : (
            <a />
          )}
        </div>
      );
    } else {
      posInfo = (
        <span className="no-pos-found">
          <I18nSpan msgKey="poi.list.message.noposlinked" />
        </span>
      );
    }

    return <div> {posInfo} </div>;
  }
}

const mapStateToProps = state => {
  const {
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {}
      }
    }
  } = state;

  return {
    filters
  };
};

const mapDispatchToProps = dispatch => ({
  link: ({ poiId, posId }) => dispatch(link({ poiId, posId })),
  unlink: ({ poiId }) => dispatch(unlink({ poiId })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  getPois: ({ filters, fields, sort, tableCount }) =>
    dispatch(getPois({ filters, fields, sort, tableCount })),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LinkPosToPoi);
