import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";

import AmountApexChart from "./AmountApexChart";
import { UserCurrency } from "../../../home/components/Widget";
import NumberFormatter from "../../../formatters/NumberFormatter";
import Utils from "../../../sim/Utils";

interface Props {
  dataLabelsFormat: any;
  title: any;
  series: any;
  color: string;
  currency: UserCurrency;
}

class CardsChart extends Component<Props, {}> {
  _buildConfigFromProps() {
    const {
      dataLabelsFormat,
      title: text,
      series: rawSeries,
      color,
      currency,
      t,
      defaultPaletteId,
      palettes,
      palette
    } = this.props;

    const widgetPalette = palette ? palette : defaultPaletteId;
    const initialPalette = Utils.findPaletteById(widgetPalette, palettes);

    const [{ data }] = rawSeries;
    const [series, labels] = data.reduce(
      (prev, curr) => {
        return [
          [...prev[0], curr.y],
          [...prev[1], curr.name]
        ];
      },
      [[], []]
    );

    const format =
      dataLabelsFormat || "<b>{point.name}</b>: {point.percentage:.2f} %";

    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        margin: [0, 0, 0, 0],
        marginBottom: 15,
        toolbar: {
          show: true,
          tools: {
            download: true
          }
        }
      },
      dataLabels: {
        formatter: function(value, { seriesIndex, dataPointIndex, w }) {
          return `${value.toFixed(2)} %`;
        },
        style: {
          width: "110px",
          color: "#606060",
          fontFamily: "'Lato', sans-serif",
          fontWeight: "bold",
          lineHeight: "11px",
          fontSize: "12px",
          textOutline: "none"
        },
        padding: 5,
        borderRadius: 2,
        backgroundColor: "rgba(252, 255, 255, 0.7)",
        borderWidth: 1,
        borderColor: "#AAA",
        enabled: true,
        format,
        maxStaggerLines: 2,
        distance: -40
      },
      legend: {
        position: "bottom",
        itemMarginTop: 5,
        itemStyle: {
          font: "8pt Trebuchet MS, Verdana, sans-serif",
          color: "#000"
        }
      },
      noData: {
        text: t("apexCharts.noData")
      },
      title: {
        text,
        align: "center"
      },
      tooltip: {
        y: {
          formatter: function(value) {
            if (currency) {
              return NumberFormatter.formatAmount(
                value,
                currency.currencyCodeAlpha3 + currency.currency,
                currency.currencyDecimal
              );
            }

            return value;
          }
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          size: "75%",
          showInLegend: true,

          color
        }
      },

      series,
      labels,
      ...(initialPalette && { colors: [...initialPalette.colors] })
    };
  }

  render() {
    const { series, ...options } = this._buildConfigFromProps();

    return <AmountApexChart options={options} series={series} type="pie" />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: { defaultPaletteId }
    },
    palettes: { data: palettes = [] }
  } = state;

  return {
    palettes,
    defaultPaletteId
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, null)
)(CardsChart);
