import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Tabs from "../../../components/tabs/Tabs";
import Tab from "../../../components/tabs/Tab";

import FormTitle from "../../../ingenicoForm/components/FormTitle";
import ListEmailTemplateView from "./ListEmailTemplateView";
import ListSmsTemplateView from "./ListSmsTemplateView";

import { getTemplates, resetTemplatesState } from "../../../redux/actions";

interface Props {
  emailTemplates: any;
  smsTemplates: any;
  getTemplates: Function;
  resetTemplates: Function;
}

interface State {}

class TemplateView extends Component<Props, State> {
  componentDidMount() {
    const { getTemplates } = this.props;
    getTemplates();
  }

  componentWillUnmount() {
    const { resetTemplates } = this.props;
    resetTemplates();
  }

  render() {
    const { emailTemplates, smsTemplates } = this.props;

    const TabsContainer = () => {
      if (smsTemplates.length === 0) {
        return <ListEmailTemplateView templates={emailTemplates} />;
      }
      return (
        <Tabs target="/main/settings/templates/" urlParam="template">
          <Tab name="email" title="customer.settings.emailTemplates.button">
            <ListEmailTemplateView templates={emailTemplates} />
          </Tab>
          <Tab name="sms" title="customer.settings.smsTemplates.button">
            <ListSmsTemplateView templates={smsTemplates} />
          </Tab>
        </Tabs>
      );
    };

    return (
      <div className="data-table-wrapper">
        <FormTitle
          titleKey="customer.settings.title"
          actionKey="customer.settings.templates.action"
        />
        <TabsContainer />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { t } = ownProps;

  const {
    templates: { data: templates, loading }
  } = state;

  const $t = key => {
    return t(`customer.settings.templates.form.type.${key}`);
  };

  const translatedTemplate = _(templates).map(function(template) {
    const translatedId = $t(template.templateId);
    return {
      templateId: template.templateId,
      domain: template.domain,
      translatedId,
      subject: template.subject,
      type: template.type
    };
  });

  return {
    loading,
    emailTemplates: translatedTemplate
      .filter(template => template.type === "email")
      .value(),
    smsTemplates: translatedTemplate
      .filter(template => template.type === "sms")
      .valueOf()
  };
};

const mapDispatchToProps = dispatch => ({
  getTemplates: () => dispatch(getTemplates({ templateType: "sms_and_email" })),
  resetTemplates: () =>
    dispatch(resetTemplatesState({ templateType: "sms_and_email" }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TemplateView);
