import * as moment from "moment";
import {
  SettlementFileFormat,
  SettlementFileFormatConstants
} from "./SettlementFileFormat";

export default class SettlementSettings {
  private static createSettlementFileFormat(
    fileFormat: any
  ): SettlementFileFormat {
    switch (fileFormat.type) {
      case SettlementFileFormatConstants.BNPP:
        return { type: "BNPP" };
      case SettlementFileFormatConstants.APM:
        return { type: "APM" };
      case SettlementFileFormatConstants.CMCIC:
        return { type: "CMCIC" };
      case SettlementFileFormatConstants.CMCIC_V4:
        return { type: "CMCIC_V4" };
      case SettlementFileFormatConstants.CSV:
        return {
          type: "CSV",
          transactionFields: fileFormat.transactionFields,
          fieldSeparator: fileFormat.fieldSeparator,
          fieldQuoting: fileFormat.fieldQuoting
        };
    }
  }

  static buildFromForm(settlementSettingsForm) {
    const startOfDay = settlementSettingsForm.triggerTime
      .clone()
      .startOf("day");
    const durationFromStart = moment.duration(
      settlementSettingsForm.triggerTime.diff(startOfDay)
    );
    const transactionCriteria = settlementSettingsForm.reconciliatedTransactionsOnly
      ? "reconciledTransactions"
      : "allTransactions";

    const hostAndPort = settlementSettingsForm.host
      ? settlementSettingsForm.host.split(":")
      : [];
    if (hostAndPort.length > 1) {
      settlementSettingsForm.host = hostAndPort[0];
      settlementSettingsForm.port = parseInt(hostAndPort[1]);
    }

    return {
      acquirerIdentifier: settlementSettingsForm.acquirerIdentifier,
      fileFormat: SettlementSettings.createSettlementFileFormat(
        settlementSettingsForm.fileFormat
      ),
      transactionCriteria: transactionCriteria,
      triggerTime: durationFromStart,
      host: settlementSettingsForm.host,
      port: settlementSettingsForm.port,
      path: settlementSettingsForm.path,
      user: settlementSettingsForm.user,
      key: settlementSettingsForm.key
    };
  }

  static wrapFromServer(settlementSettings) {
    const durationSinceStartOfDay = moment.duration(
      settlementSettings.triggerTime
    );
    const triggerTime = moment()
      .startOf("day")
      .add(durationSinceStartOfDay);
    const transactionCriteria =
      settlementSettings.transactionCriteria == "reconciledTransactions";

    const hostAndPort = settlementSettings.port
      ? `${settlementSettings.host}:${settlementSettings.port}`
      : settlementSettings.host;

    return {
      settlementConfiguration: true,
      acquirerIdentifier: settlementSettings.acquirerIdentifier,
      fileFormat: settlementSettings.fileFormat,
      reconciliatedTransactionsOnly: transactionCriteria,
      triggerTime: triggerTime,
      host: hostAndPort,
      path: settlementSettings.path,
      user: settlementSettings.user,
      keyDefined: settlementSettings.keyDefined
    };
  }
}
