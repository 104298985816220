import { useEffect } from "react";
import { debounce } from "lodash";

export default function Div100vh({ children }) {
  useEffect(() => {
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

    function setVhCustomProperty() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;

      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    const debouncedSetVhCustomProperty = debounce(setVhCustomProperty, 100);

    setVhCustomProperty();

    // We listen to the resize event
    window.addEventListener("resize", debouncedSetVhCustomProperty);

    return () =>
      window.removeEventListener("resize", debouncedSetVhCustomProperty);
  }, []);

  return children;
}
