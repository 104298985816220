import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import MatchMobileContainer from "../../gridCharts/MatchMobileContainer";

import CreateTokenDesktopView from "./CreateTokenDesktopView";
import CreateTokenMobileView from "./CreateTokenMobileView";

import {
  addNotificationError,
  addNotificationSuccess,
  createToken
} from "../../redux/actions";

class CreateTokenView extends Component<Props, State> {
  state = {
    login: "",
    captcha: ""
  };

  _onInputChange = e => {
    const target = e.target;

    this.setState({
      [target.name]: target.value
    });
  };

  _resetPassword = event => {
    event.preventDefault();

    const {
      reCaptchaSiteKey,
      createToken,
      addNotificationSuccess,
      addNotificationError,
      history
    } = this.props;
    const { login: loginRaw } = this.state;
    const login = loginRaw.trim();

    if (login) {
      grecaptcha.ready(async () => {
        const captcha = await grecaptcha.execute(reCaptchaSiteKey, {
          action: "login"
        });

        return createToken({ login, captcha }).then(
          () => {
            addNotificationSuccess("createToken.success");
            history.push("/authenticate");
          },
          () => {
            addNotificationError("createToken.error");
          }
        );
      });
    }
  };

  render() {
    return (
      <form onSubmit={this._resetPassword}>
        <MatchMobileContainer
          renderMobile={() => (
            <CreateTokenMobileView
              onInputChange={this._onInputChange}
              {...this.state}
            />
          )}
          renderDesktop={() => (
            <CreateTokenDesktopView
              onInputChange={this._onInputChange}
              {...this.state}
            />
          )}
        />
      </form>
    );
  }
}

const mapStateToProps = state => {
  const {
    config: {
      data: { "reCaptchaSiteKey-v3": reCaptchaSiteKey }
    }
  } = state;

  return {
    reCaptchaSiteKey
  };
};

const mapDispatchToProps = dispatch => ({
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  createToken: ({ login, captcha }) => dispatch(createToken({ login, captcha }))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateTokenView);
