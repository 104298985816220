import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import * as $ from "jquery";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";

import {
  getCustomers,
  getOffers,
  editCustomerStatus,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../redux/actions";

import {
  EditAction,
  ToggleAction,
  CreateUserAction,
  ExportAction
} from "../../datatable/components/multipleActions";

import { generateUrl } from "../../commons/utils/url";

import DataTable from "../../datatable/components/DataTable";
import ExportPopin from "../../components/ExportPopin";
import I18nSpan from "../../i18n/components/I18nSpan";
import Formats from "../../commons/constants/ExportFormats";
import Permissions from "../../user/constants/Permissions";

import styles from "../styles/styles.css";

class ListCustomerView extends Component<Props, State> {
  columns = ["name", "selectedOffer", "statusi18n", "terminalCustomerId"];

  state = {
    showPopin: false,
    customerId: ""
  };

  async componentDidMount() {
    const { getCustomers, getOffers } = this.props;

    await Promise.all([getCustomers(), getOffers()]);
    $(document.body).on("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    $(document.body).off("keydown", this.handleKeyDown);
  }

  _updateStatus = async status => {
    const {
      selectedCustomerIds: ids,
      editCustomerStatus,
      addNotificationError
    } = this.props;

    const newStatus = status === "Suspended" ? "Activated" : "Suspended";
    const customerId = ids[0];
    try {
      await editCustomerStatus({ customerId, status: newStatus });
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      return addNotificationError(errorKey);
    }
  };

  handleKeyDown = event => {
    if (event.keyCode === 27 /*enter*/) {
      //eslint-disable-line no-magic-numbers
      this._closePopin();
    }
  };

  _closePopin = () => {
    this.setState({ showPopin: false, customerId: "" });
  };

  _openPopin = event => {
    event.preventDefault();
    const { selectedCustomerIds } = this.props;
    const [customerId] = selectedCustomerIds;

    return this.setState({ showPopin: true, customerId });
  };

  _closeTxPopin = () => {
    this.setState({ showTxPopin: false, customerId: "" });
  };

  _openTxPopin = event => {
    event.preventDefault();
    const { selectedCustomerIds } = this.props;
    const [customerId] = selectedCustomerIds;

    return this.setState({ showTxPopin: true, customerId });
  };

  onSelectRow = (rowId, value) => {
    const { selectedCustomerIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedCustomerIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const {
      customers,
      hasCustomerUserPermission,
      createAdminUserTitle,
      exportTerminalActivitiesTitle,
      exportTransactionsTitle,
      searchContext,
      selectedCustomerIds
    } = this.props;
    const { showTxPopin, customerId, showPopin } = this.state;

    const EditCustomer = () => {
      if (selectedCustomerIds.length === 1) {
        const [customerId] = selectedCustomerIds;

        const route = `/main/customer/edit-customer/${customerId}`;

        return <EditAction route={route} msgKey={"user.edit.action"} />;
      }

      return null;
    };

    const toggleActivationButton = () => {
      const [customerId] = selectedCustomerIds;
      const selectedCustomer = customers.filter(
        customer => customerId === customer.id
      );
      const [selected] = selectedCustomer;
      const status = selected ? selected.status : "";

      if (selectedCustomerIds.length === 1) {
        return (
          <ToggleAction
            onToggle={() => this._updateStatus(status)}
            msgKey={`customer.toggle.${status}`}
          />
        );
      }

      return null;
    };

    const CreateUser = () => {
      if (selectedCustomerIds.length === 1 && hasCustomerUserPermission) {
        const [customerId] = selectedCustomerIds;

        const route = `/main/user/new-user/customer/${customerId}`;

        return <CreateUserAction route={route} msgKey={createAdminUserTitle} />;
      }

      return null;
    };

    const exportCustomerTransactions = () => {
      if (selectedCustomerIds.length === 1) {
        return (
          <ExportAction
            onExport={this._openTxPopin}
            msgKey={exportTransactionsTitle}
          />
        );
      }

      return null;
    };

    const exportTerminalActivities = () => {
      if (selectedCustomerIds.length === 1) {
        return (
          <ExportAction
            onExport={this._openPopin}
            msgKey={exportTerminalActivitiesTitle}
          />
        );
      }

      return null;
    };

    const formats = [
      {
        url: generateUrl({
          url: `/reporting/devices/activities/:dataType?customerId=:id&begin=:begin&end=:end&format=${Formats.CSV}`
        }),
        label: "reporting.transaction.list.button.exportCSV"
      }
    ];
    const formatsExportTransactions = [
      {
        url: generateUrl({
          url: `/reporting/transactionByWallet?customerId=:id&begin=:begin&end=:end&format=${Formats.CSV}`
        }),
        label: "reporting.transaction.list.button.exportCSV"
      }
    ];

    return (
      <div className="data-table-wrapper">
        <ExportPopin
          searchContext={searchContext}
          showPopin={showTxPopin}
          onClosePopin={this._closeTxPopin}
          optId={customerId}
          urls={formatsExportTransactions}
          enableExport={true}
          hideDataTypeSelect={true}
          title={"monitoringPoiSim.transactionActivities.export.title"}
        />

        <ExportPopin
          searchContext={searchContext}
          showPopin={showPopin}
          hideDataTypeSelect={false}
          onClosePopin={this._closePopin}
          optId={customerId}
          urls={formats}
          enableExport={true}
          title={"monitoringPoiSim.terminalActivities.export.title"}
        />

        <div className={styles["title-container"]}>
          <FormTitle
            titleKey="customer.title"
            actionKey="customer.list.action"
          />

          <div className={styles.creation}>
            <Link
              className="btn btn-ingenico create-button"
              to={"/main/customer/create-customer"}
            >
              <span className="glyphicon glyphicon-plus" aria-hidden="true" />
              <I18nSpan msgKey="customer.list.button.create" />
            </Link>
          </div>
        </div>

        <DataTable
          data={customers}
          attributes={this.columns}
          i18nKey="customer.list"
          idAttribute="id"
          disableStatusValues={["Suspended"]}
          hasActions={false}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedCustomerIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[
            EditCustomer,
            toggleActivationButton,
            CreateUser,
            exportCustomerTransactions,
            exportTerminalActivities
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    customers: { data: customers, selection },
    offers: { data: offers },
    searchContext: { data: searchContext }
  } = state;
  const { t } = ownProps;
  const customersWithTranslation = _.map(customers, (rawCustomer: any) => {
    rawCustomer.statusi18n = t(`dataTable.state.${rawCustomer.status}`);

    return rawCustomer;
  });
  const offersById: any = _.indexBy(offers, "id");
  const customersWithOffer = offersById
    ? _.chain(customersWithTranslation)
        .map(a => {
          _.set(
            a,
            "selectedOffer",
            offersById[a.offerId] ? offersById[a.offerId].name : ""
          );
          return a;
        })
        .value()
    : customersWithTranslation;
  const hasCustomerUserPermission = user
    ? _.includes(user.permissions, Permissions.CUSTOMER_USER_MANAGEMENT)
    : false;

  const createAdminUserTitle = t("dataTable.createAdminUser");
  const exportTerminalActivitiesTitle = t("dataTable.download");
  const exportTransactionsTitle = t("dataTable.downloadTx");

  const selectedCustomerIds = _.keys(_.pick(selection, _.identity));

  return {
    customers: customersWithOffer,
    hasCustomerUserPermission,
    createAdminUserTitle,
    exportTerminalActivitiesTitle,
    exportTransactionsTitle,
    searchContext,
    selectedCustomerIds,
    selection
  };
};

const mapDispatchToProps = dispatch => ({
  getCustomers: () => dispatch(getCustomers()),
  getOffers: () => dispatch(getOffers()),
  editCustomerStatus: ({ customerId, status }) =>
    dispatch(editCustomerStatus({ customerId, status })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "CUSTOMERS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "CUSTOMERS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "CUSTOMERS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "CUSTOMERS" }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ListCustomerView);
