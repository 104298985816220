import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import LoginInput from "./LoginDesktopInput";

import styles from "../styles/styles.css";

class CreateTokenDesktopView extends Component<Props, {}> {
  render() {
    const { t, login, onInputChange } = this.props;

    return (
      <div className={`col-xs-12 ${styles["login-form-wrapper"]}`}>
        <LoginInput
          label={t("createToken.form.login")}
          onChange={onInputChange}
          value={login}
        />
        <div className={styles["submit-container"]}>
          <input
            type="submit"
            className={styles.buttonlink}
            value={t("createToken.form.reset")}
          />
          <div className={`${styles["small-link"]} text-right `}>
            <Link to="/authenticate" className={styles.link}>
              {t("createToken.form.back")}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CreateTokenDesktopView);
