import React, { Component } from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import Select from "./form/Select";
import Textarea from "./form/Textarea";
import Jsonarea from "./form/Jsonarea";
import Radios from "./form/Radios";
import Input from "./form/Input";
import Checkbox from "./form/Checkbox";
import Multiple from "./form/Multiple";
import MultipleSelect from "./form/MultipleSelect";
import MultipleSelectWindowed from "./form/MultipleSelectWindowed";
import I18nSpan from "../../i18n/components/I18nSpan";

import ErrorsList from "../validation/ErrorsList";
import SingleAutocomplete from "./SingleAutocomplete";

interface Props {
  inputClass: string;
  name: string;
  value: string;
  placeholder: string;
  accept: string;
  onChange: Function;
  onKeyPress: Function;
  validation: Function;
  formValue: any;
  hide: boolean;
  required: boolean;
  readOnly: boolean;
  descriptor: any;
  errors: any;
}

class BootstrapInput extends Component<Props, {}> {
  static defaultProps = {
    name: "",
    required: false,
    onKeyPress: () => undefined,
    validation: () => undefined
  };

  _onChange = (name, value, target) => {
    const { onChange } = this.props;

    return onChange(name, value, target);
  };

  _onKeyPress = (name, event) => {
    const { onKeyPress } = this.props;

    return onKeyPress(name, event);
  };

  _validate = value => {
    const { validation } = this.props;

    return validation(value);
  };

  render() {
    if (this.props.hide) {
      return <div />;
    }

    let input = "";
    const {
      descriptor: { type },
      t
    } = this.props;
    const isLabel =
      this.props.descriptor.label && this.props.descriptor.label.length > 0;

    if (type === "textarea") {
      input = (
        <Textarea
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          required={this.props.required}
          name={this.props.name}
          value={this.props.formValue || ""}
          onChange={this._onChange}
          onKeyPress={this._onKeyPress}
          validation={this._validate}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "jsonarea") {
      input = (
        <Jsonarea
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          required={this.props.required}
          name={this.props.name}
          value={this.props.formValue || ""}
          onChange={this._onChange}
          onKeyPress={this._onKeyPress}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "select") {
      input = (
        <Select
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          onChange={this._onChange}
          required={this.props.required}
          readOnly={this.props.descriptor.readOnly || false}
          value={this.props.formValue || ""}
          options={this.props.descriptor.options}
          name={this.props.name}
          validation={this._validate}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "radio") {
      input = (
        <Radios
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          onChange={this._onChange}
          required={this.props.required}
          readOnly={this.props.descriptor.readOnly || false}
          value={this.props.formValue}
          options={this.props.descriptor.options}
          name={this.props.name}
          validation={this._validate}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "checkbox") {
      const disabledControlLabel = !!this.props.descriptor.disabledControlLabel;
      input = (
        <Checkbox
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          disabledControlLabel={disabledControlLabel}
          onChange={this._onChange}
          required={this.props.required}
          disabled={this.props.descriptor.readOnly || false}
          value={this.props.value || ""}
          checked={this.props.formValue || false}
          name={this.props.name}
          validation={this._validate}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "float") {
      input = (
        <Input
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          name={this.props.name}
          required={this.props.required}
          type="text"
          pattern="^[-+]?([0-9]*\.[0-9]+|[0-9]+)$"
          min={this.props.descriptor.min}
          max={this.props.descriptor.max}
          value={this.props.formValue || ""}
          onChange={this._onChange}
          onKeyPress={this._onKeyPress}
          validation={this._validate}
          placeholder={t(this.props.descriptor.placeholder)}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "multiple") {
      input = (
        <Multiple
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          onChange={this._onChange}
          name={this.props.name}
          value={this.props.formValue || []}
          required={this.props.required}
          readOnly={this.props.descriptor.readOnly || false}
          validation={this._validate}
          placeholder={t(this.props.descriptor.placeholder)}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "multipleselect") {
      input = (
        <MultipleSelect
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          onChange={this._onChange}
          name={this.props.name}
          value={this.props.formValue}
          required={this.props.required}
          readOnly={this.props.descriptor.readOnly || false}
          validation={this._validate}
          options={this.props.descriptor.options}
          placeholder={t(this.props.descriptor.placeholder)}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "multipleselectwindowed") {
      input = (
        <MultipleSelectWindowed
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          onChange={this._onChange}
          name={this.props.name}
          value={this.props.formValue}
          required={this.props.required}
          isMulti={this.props.descriptor.isMulti || false}
          closeMenuOnSelect={this.props.descriptor.closeMenuOnSelect || true}
          isClearable={this.props.descriptor.isClearable}
          readOnly={this.props.descriptor.readOnly || false}
          validation={this._validate}
          options={this.props.descriptor.options}
          placeholder={t(this.props.descriptor.placeholder)}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "email") {
      input = (
        <Input
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          name={this.props.name}
          required={this.props.required}
          type={type}
          min={this.props.descriptor.min}
          max={this.props.descriptor.max}
          value={this.props.formValue}
          onChange={this._onChange}
          onKeyPress={this._onKeyPress}
          validation={this._validate}
          placeholder={t(this.props.descriptor.placeholder)}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "ip") {
      input = (
        <Input
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          name={this.props.name}
          required={this.props.required}
          readOnly={this.props.descriptor.readOnly || false}
          type="text"
          min={this.props.descriptor.min}
          max={this.props.descriptor.max}
          value={this.props.formValue}
          onChange={this._onChange}
          onKeyPress={this._onKeyPress}
          validation={this._validate}
          placeholder={t(this.props.descriptor.placeholder)}
          ref={this.props.inputRef}
        />
      );
    } else if (type === "singleautocomplete") {
      input = (
        <SingleAutocomplete
          className={this.props.descriptor.className || ""}
          name={this.props.name}
          readOnly={this.props.descriptor.readOnly}
          items={this.props.descriptor.options}
          value={this.props.formValue}
          validation={this._validate}
          ref={this.props.inputRef}
          onChange={this._onChange}
          allowFreeText={this.props.descriptor.allowFreeText}
        />
      );
    } else if (type === "custom") {
      const props = _.extend(
        {
          required: this.props.required,
          onChange: this._onChange,
          value: this.props.formValue
        },
        this.props.descriptor
      );
      input = React.createElement(this.props.descriptor.component, props);
    } else {
      input = (
        <Input
          id={this.props.descriptor.id || ""}
          className={this.props.descriptor.className || ""}
          name={this.props.name}
          required={this.props.required}
          readOnly={this.props.descriptor.readOnly || false}
          type={type}
          pattern={this.props.descriptor.pattern}
          min={this.props.descriptor.min}
          max={this.props.descriptor.max}
          value={this.props.formValue}
          onChange={this._onChange}
          onKeyPress={this._onKeyPress}
          accept={this.props.descriptor.accept}
          validation={this._validate}
          placeholder={t(this.props.descriptor.placeholder)}
          ref={this.props.inputRef}
          onPaste={this.props.descriptor.onPaste}
        />
      );
    }

    const errorMessages = _.isEmpty(this.props.errors) ? (
      ""
    ) : (
      <ErrorsList errors={this.props.errors} />
    );
    const labelClass =
      this.props.descriptor.labelClass || "control-label col-sm-3";
    const labelElement = isLabel ? (
      <label htmlFor={this.props.descriptor.id} className={labelClass}>
        <I18nSpan msgKey={this.props.descriptor.label} />
        <sup className="required-label">
          {this.props.descriptor.required ||
          this.props.required ||
          this.props.descriptor.requiredValidation
            ? "*"
            : ""}
        </sup>
      </label>
    ) : null;

    const inputClass =
      this.props.descriptor.inputClass || (isLabel ? "col-sm-6" : "col-sm-12");

    return (
      <div className="form-group">
        {labelElement}

        <div className={inputClass}>
          {input}
          {errorMessages}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withTranslation()(BootstrapInput);
