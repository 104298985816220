import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import * as DateTime from "react-datetime";
import _ from "lodash";
import * as moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import TransactionAdvancedSearch from "../../reporting/transactions/components/TransactionAdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import OfferUtils from "../../offer/util/OfferUtils";
import Permissions from "../../user/constants/Permissions";
import Validators from "../../ingenicoForm/validation/Validators";
import ErrorsList from "../../ingenicoForm/validation/ErrorsList";
import FormTitle from "../../ingenicoForm/components/FormTitle";

import {
  addNotificationError,
  addNotificationSuccess,
  getFields,
  getUsers,
  getFrequencies,
  getScheduledReport,
  updateScheduledReport,
  setSearchContext,
  cleanSearchContext
} from "../../redux/actions";

interface Props {
  addNotificationError: Function;
  addNotificationSuccess: Function;
  updateScheduledReport: Function;
  getScheduledReport: Function;
  getFields: Function;
  transactionFields: any;
  hasUserManagement: boolean;
  getUsers: Function;
  getFrequencies: Function;
  match: any;
}

interface State {}

const ReportAvailableServices = [
  "snapshotReporting",
  "tenderTypesReporting",
  "transactionReporting"
];

const listToObject = _.flow(_.map, _.zipObject);

const advancedSearchKey = AdvancedSearchConstants.REPORT_KEY;

class EditScheduledReport extends Component<Props, State> {
  state = {
    locale: window.navigator.userLanguage || window.navigator.language,
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillUnmount() {
    const { cleanSearchContext } = this.props;
    cleanSearchContext();
  }

  async componentDidMount() {
    const {
      getFields,
      hasUserManagement,
      getUsers,
      getFrequencies,
      getScheduledReport,
      setSearchContext,
      match: {
        params: { scheduledId: id }
      }
    } = this.props;

    getFrequencies();
    if (hasUserManagement) {
      getUsers();
    }
    getFields({ name: "transaction", version: "v2" });
    const scheduledReport = await getScheduledReport({ id });

    const durationSinceStartOfDay = moment.duration(
      scheduledReport.triggerTime
    );
    const triggerTime = moment()
      .startOf("day")
      .add(durationSinceStartOfDay);

    const form = {
      frequency: scheduledReport.frequency,
      name: scheduledReport.name,
      triggerTime,
      reportTypes: OfferUtils.servicesToFormValues(scheduledReport.reportTypes),
      recipients: scheduledReport.recipients.map(recipient => {
        return {
          label: recipient,
          value: recipient
        };
      }),
      sendMeCopy: scheduledReport.sendMeCopy
    };

    const filters = scheduledReport.filters;

    return this.setState(
      state => ({
        ...state,
        form: {
          ...state.form,
          ...form
        }
      }),
      () => {
        const context = {
          key: advancedSearchKey,
          filters
        };
        setSearchContext({ context, pathname: advancedSearchKey });
      }
    );
  }

  _onChangeHandler = (name, value) => {
    return this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value
        }
      };
    }, this._checkError(name));
  };

  _onChangeSendMeCopy = event => {
    return this._onChangeHandler("sendMeCopy", event.target.checked);
  };

  _onTimeChange = selectedTime => {
    let newErrors = this.state.errors;
    newErrors.triggerTime =
      selectedTime === ""
        ? [{ code: "form.error.required", args: [selectedTime] }]
        : [];

    let updatedCustomer = this.state.form;
    updatedCustomer.triggerTime = selectedTime;
    this.setState({
      form: updatedCustomer,
      errors: newErrors
    });
  };

  _saveScheduled = e => {
    e.preventDefault();

    const {
      updateScheduledReport,
      addNotificationSuccess,
      addNotificationError,
      match: {
        params: { scheduledId: id }
      },
      user,
      filters
    } = this.props;

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        try {
          const formData = _.clone(this.state.form);

          if (user) {
            formData.timezone = user.timezone || "";
          }

          if (formData.triggerTime === "") {
            return addNotificationError("notification.customer.triggerNotSet");
          }
          updateScheduledReport({ id, data: { ...formData, filters } })
            .then(
              () => {
                addNotificationSuccess("notification.success");
                return this._goToList();
              },
              () => addNotificationError("notification.error")
            )
            .catch(errorPromise =>
              errorPromise.then(errorData => addNotificationError(errorData))
            );
        } catch (error) {
          throw error;
        }
      }
    });
  };

  _goToList() {
    const { history } = this.props;

    history.push("/main/reporting/scheduled");
  }

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ ...this.state, errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });

    return Promise.all([requiredPromise]);
  };

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), [], then);
  }

  _checkError(name) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _required = value => {
    return Promise.resolve(Validators.requiredValidator(value));
  };

  _search = async ({ filters, searchContext }) => {
    const { setSearchContext } = this.props;

    setSearchContext({ context: searchContext, pathname: advancedSearchKey });
  };

  render() {
    const {
      color,
      frequencyOptions,
      transactionFields,
      recipientOptions,
      servicesOptions,
      searchContext
    } = this.props;

    return (
      <div>
        <FormTitle
          color={color}
          titleKey="scheduled.title"
          actionKey="scheduled.edit.action"
        />
        <form className="ingenico-form form-horizontal scheduled-form">
          <BootstrapInput
            validation={this._required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            errors={this.state.errors.name}
            required={true}
            descriptor={{ type: "text", label: "scheduled.form.name.label" }}
            formValue={this.state.form.name}
          />

          <BootstrapInput
            validation={() => undefined}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("frequency")}
            name="frequency"
            errors={this.state.errors.frequency}
            required={true}
            descriptor={{
              type: "select",
              label: "scheduled.form.frequency.label",
              options: frequencyOptions
            }}
            formValue={this.state.form.frequency}
          />

          <div className="form-group date">
            <label className="control-label col-sm-3">
              <I18nSpan msgKey="scheduled.form.triggerTime.label" />
              <sup className="required-label">{"*"}</sup>
            </label>
            <div className="col-sm-6">
              <DateTime
                locale={this.state.locale}
                value={this.state.form.triggerTime}
                onChange={this._onTimeChange}
                dateFormat={false}
                inputProps={{ required: true }}
              />
              <ErrorsList errors={this.state.errors.triggerTime} />
            </div>
          </div>

          <BootstrapInput
            descriptor={{
              type: "multipleselect",
              options: servicesOptions,
              label: "scheduled.form.reportTypes.label",
              className: "mtn reportTypes"
            }}
            name="reportTypes"
            validation={() => undefined}
            onChange={this._onChangeHandler}
            formValue={this.state.form.reportTypes}
          />

          <div className="form-group">
            <label className="control-label col-sm-3">
              <I18nSpan msgKey="scheduled.form.filters" />
            </label>
            <div className="col-sm-6">
              <TransactionAdvancedSearch
                className="mtn filters"
                name={advancedSearchKey}
                onChange={this._search}
                transactionFields={transactionFields}
                searchContext={searchContext}
              />
            </div>
          </div>

          <BootstrapInput
            descriptor={{
              type: "multipleselect",
              options: recipientOptions,
              label: "receipt.send.recipients",
              className: "mtn recipients"
            }}
            name="recipients"
            validation={() => undefined}
            onChange={this._onChangeHandler}
            formValue={this.state.form.recipients}
          />

          <div className="form-group">
            <label className="control-label col-sm-3" htmlFor="send-copy">
              <I18nSpan msgKey="scheduled.form.sendCopy" />
            </label>
            <div className="col-sm-6">
              <input
                id="send-copy"
                type="checkbox"
                name="sendMeCopy"
                checked={this.state.form.sendMeCopy}
                onChange={this._onChangeSendMeCopy}
              />
            </div>
          </div>

          <div className="pull-right">
            <button
              onClick={this._saveScheduled}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <Link
              to={"/main/reporting/scheduled"}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getScheduledReport: ({ id }) => dispatch(getScheduledReport({ id })),
  updateScheduledReport: ({ id, data }) =>
    dispatch(updateScheduledReport({ id, data })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  getUsers: () => dispatch(getUsers()),
  getFrequencies: () => dispatch(getFrequencies()),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname
      })
    ),
  cleanSearchContext: () =>
    dispatch(
      cleanSearchContext({
        key: advancedSearchKey,
        context: { key: advancedSearchKey },
        pathname: advancedSearchKey
      })
    )
});

const _mapRecipientOptions = ({ useLogins }) => {
  const recipientOptions = useLogins.map(login => {
    return {
      id: login,
      name: login
    };
  });
  return { recipientOptions };
};

const _mapFrequenciesOptions = ({ frequencies, getMessage }) => {
  const frequencyOptions = listToObject(frequencies, frequencyCode => [
    frequencyCode,
    getMessage(`scheduled.frequency.${frequencyCode}`)
  ]);
  return { frequencyOptions };
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    theme: {
      color: {
        data: { color }
      }
    },
    users: { data: users },
    services: { data: services },
    genericFields: { data: transactionFields },
    scheduledReports: {
      scheduledFrequencies: { data: frequencies = [] }
    },
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;
  const { permissions } = user;

  const hasUserManagement = _.includes(
    permissions,
    Permissions.USER_MANAGEMENT
  );

  const useLogins = users.map(u => u.login);
  const { recipientOptions } = _mapRecipientOptions({ useLogins });

  const { t: getMessage } = ownProps;

  const servicesOptions = OfferUtils.servicesToFormOptions(
    _.intersection(services, ReportAvailableServices)
  );

  const { frequencyOptions } = _mapFrequenciesOptions({
    frequencies,
    getMessage
  });

  return {
    user,
    searchContext,
    filters,
    color,
    transactionFields,
    hasUserManagement,
    users,
    recipientOptions,
    servicesOptions,
    frequencyOptions
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(EditScheduledReport);
