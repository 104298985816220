import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  createStyles,
  Input,
  InputLabel,
  makeStyles,
  Theme
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid/Grid";
import InputMobile from "./InputMobile";

const mapStateToProps = state => {
  const {
    auth: { user },

    theme: { darkMode }
  } = state;

  const { name, email, msisdn: phone } = user;

  return {
    name,
    email,
    phone,
    darkMode
  };
};

const AccountMobile = () => {
  const { t } = useTranslation();

  const { darkMode: prefersDarkMode, name, email, phone } = useSelector(state =>
    mapStateToProps(state)
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: prefersDarkMode ? "#303030" : "transparent"
      },
      formControl: {
        backgroundColor: prefersDarkMode ? "#3B3B3B" : "#E5E5E5",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        borderRadius: "6px",
        height: "calc(var(--vh, 1vh) * 5)",
        border: "initial",
        boxShadow: "initial",
        marginBottom: "5px"
      },
      container: {
        width: "100%",
        flex: "auto"
      },
      labelInput: {
        color: prefersDarkMode ? "#E5E5E5" : "#3B3B3B"
      }
    })
  );

  const classes = useStyles();

  return (
    <Grid item direction="column" className={classNames(classes.container)}>
      <Grid container direction={"column"} spacing={5}>
        <Grid item>
          <InputMobile value={name} label="name" id="name" />
        </Grid>
        <Grid item>
          <InputMobile value={email} label="email" id="email" />
        </Grid>
        <Grid item>
          <InputMobile value={phone} label="phone" id="phone" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountMobile;
