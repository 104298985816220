import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container/Container";

import ObjectivesCharts from "../ObjectivesCharts/ObjectivesCharts";
import NetRevenue from "../NetRevenue/NetRevenue";
import TransactionNumber from "../TransactionNumber/TransactionNumber";
import RevenuesChartsMobile from "../RevenuesCharts/RevenuesChartsMobile";
import RefundAmount from "../RefundAmount/RefundAmount";
import { WidgetType } from "../../home/models/WidgetType";

import styles from "../styles/styles.css";

const ChartsGrid = ({ widgets, user }) => {
  const matches = useMediaQuery(`(max-width: 768px)`);

  if (matches) {
    return (
      <Container disableGutters>
        <ObjectivesCharts />
        <RevenuesChartsMobile
          config={widgets[WidgetType.TransactionRevenueChart]}
        />
        <div className={styles["widget-indicator-wrapper"]}>
          <div className={styles["widget-indicator-sub-wrapper"]}>
            <NetRevenue config={{ ...widgets[WidgetType.Revenue], user }} />
            <TransactionNumber
              config={{ ...widgets[WidgetType.TransactionNumber], user }}
            />
          </div>
          <div className={styles["widget-refund"]}>
            <RefundAmount
              config={{ ...widgets[WidgetType.RefundAmount], user }}
            />
          </div>
        </div>
      </Container>
    );
  }

  return null;
};

export default ChartsGrid;
