import React, { Component } from "react";
import _ from "lodash";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import I18nSpan from "../../i18n/components/I18nSpan";

import {
  getTags,
  resetTagsState,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../redux/actions";

import { EditAction } from "../../datatable/components/multipleActions";

import styles from "../styles/styles.css";
import constants from "../../connection/constants/ConnectionConstant";
import { Refresh } from "../../datatable/components/Refresh";
import Counter from "../../datatable/components/Counter";

interface Props {
  history: any;
  getTags: Function;
  tags: any;
  color: any;
  count: any;
  loading: any;
  resetTags: Function;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  selectedTagIds: any;
}

interface State {
  color: string;
}

class TagListView extends Component<Props, State> {
  formatter = {
    values: tags => tags.join(", ")
  };

  componentDidMount() {
    const { getTags } = this.props;
    return getTags();
  }

  componentWillUnmount() {
    const { resetTags } = this.props;
    resetTags();
  }

  _refresh = () => {
    const { getTags } = this.props;

    return getTags();
  };

  _isRowEditable(element) {
    return !_.isEmpty(element.values);
  }

  onSelectRow = (rowId, value) => {
    const { selectedTagIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedTagIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const { tags, color, count, loading, selectedTagIds } = this.props;

    const EditTag = () => {
      if (selectedTagIds.length === 1) {
        const [tagName] = selectedTagIds;

        const route = `/main/settings/tags/edit-tag/${tagName}`;

        return <EditAction route={route} msgKey={"dataTable.edit"} />;
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle titleKey="tag.title" actionKey="tag.list.action" />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={count}
                loadingKey="tag.list.loading"
              />
              <Refresh onRefresh={this._refresh} />
            </div>
          </div>
          <div className={styles.creation}>
            <Link
              className={classNames("btn", "btn-ingenico", "create-button")}
              to={"/main/settings/tags/new-tag"}
            >
              <span className="glyphicon glyphicon-plus" aria-hidden="true" />
              <I18nSpan msgKey="tag.list.button.create" />
            </Link>
          </div>
        </div>

        <DataTable
          data={tags}
          formatter={this.formatter}
          attributes={["name", "values"]}
          key="tag.list"
          i18nKey="tag.list"
          idAttribute="name"
          color={color}
          hasActions={false}
          isRowEditable={this._isRowEditable}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedTagIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[EditTag]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: {
        scope: {
          level: { type: levelType }
        }
      }
    },
    tags: { data: tags, loading, selection },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  const selectedTagIds = _.keys(_.pick(selection, _.identity));

  return {
    loading,
    tags,
    color,
    count: tags.length,
    levelType,
    selectedTagIds,
    selection
  };
};

const mapDipatchToProps = dispatch => ({
  getTags: ({ tagType }) => dispatch(getTags({ tagType })),
  resetTags: ({ tagType }) => dispatch(resetTagsState({ tagType })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "TAGS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "TAGS" })),
  toggleSelectAll: () => dispatch(toggleSelectAll({ selectionType: "TAGS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "TAGS" }))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { levelType } = stateProps;

  switch (levelType) {
    case constants.CUSTOMER: {
      const tagType = constants.CUSTOMER_NEW;

      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    case constants.MERCHANT: {
      const tagType = constants.MERCHANT;

      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    default:
      break;
  }
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDipatchToProps, mergeProps)
)(TagListView);
