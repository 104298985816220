import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import styles from "./WhiteLabel.css";

export const WhiteLabel = ({}) => {
  let { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p className={styles["white-label"]}>{t("whiteLabel.brand")}</p>
    </div>
  );
};
