import React, { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import ConfirmationPopin from "./ConfirmationPopin";
import CreateVatRates from "./CreateVatRates";
import I18nSpan from "../../i18n/components/I18nSpan";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import { VatRates } from "../models/vatRates";
import {
  getVatRates,
  deleteVatRate,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

import styles from "./../styles/styles.css";

interface State {
  vatRates: VatRates;
  merchantId: string;
  vatRatesErrors: string;
  deleteConfirmation: {
    vatRate: VatRates;
    show: boolean;
  };
  updatedData: {
    form: {
      name: string;
      value: string;
    };
    deactivated: boolean;
  };
}

interface Props {
  getVatRates: Function;
  deleteVatRate: Function;
  vatRates: VatRates;
}

class VatRatesView extends Component<Props, State> {
  state = {
    vatRates: {},
    vatRatesErrors: "",
    deleteConfirmation: {
      vatRate: {
        name: ""
      },
      show: false
    }
  } as State;

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  async componentDidMount() {
    const { getVatRates, merchantId } = this.props;
    await Promise.all([getVatRates(merchantId)]);

    this.getVatRatesListe();
  }

  getVatRatesListe = () => {
    try {
      const { vatRates } = this.props;

      return this.setState({ vatRates } as any);
    } catch (error) {
      return this.setState({ vatRatesErrors: true } as any);
    }
  };

  componentWillReceiveProps(nextProps: Props) {
    const { vatRates } = nextProps;

    return this.setState(prevState => {
      return {
        ...prevState,
        vatRates
      };
    });
  }

  _onEditVatRate = (event: Event, name, value) => {
    event.preventDefault();

    return this.setState(prevState => {
      return {
        ...prevState,
        updatedData: {
          form: {
            name,
            value
          },
          deactivated: true
        }
      };
    });
  };

  _openPopinConfirmDelete = (event: Event, name: string) => {
    event.preventDefault();

    return this.setState(prevState => {
      return {
        ...prevState,
        deleteConfirmation: {
          vatRate: {
            name
          },
          show: true
        }
      };
    });
  };

  _close = () => {
    return this.setState({ deleteConfirmation: { show: false } });
  };

  _removeElement = (name: string) => {
    const { vatRates } = this.state as State;
    delete vatRates[name];

    return this.setState({ vatRates } as any);
  };

  _onRemoveVatRate = async (merchantId: string, name: string) => {
    const {
      deleteVatRate,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    this._close();

    try {
      await deleteVatRate(merchantId, name);
      this._removeElement(name);

      return addNotificationSuccess("vatRate.message.delete.success");
    } catch {
      return addNotificationError("vatRate.message.delete.error");
    }
  };

  render() {
    const {
      vatRates,
      vatRatesErrors,
      updatedData,
      deleteConfirmation: { vatRate, show }
    } = this.state as State;
    const { merchantId } = this.props;

    return (
      <div>
        <FormTitle titleKey="vatRate.title" actionKey="vatRate.list.action" />
        <div className="col-sm-12">
          <div className={classNames("row", styles.separator)}>
            <CreateVatRates merchantId={merchantId} updatedData={updatedData} />
          </div>
          <div className="row">
            {show && (
              <ConfirmationPopin
                onClose={this._close}
                onAccept={this._onRemoveVatRate}
                objectName={vatRate.name}
                merchantId={merchantId}
              />
            )}
            {vatRatesErrors && (
              <div className={classNames("col-sm-12", styles.errorList)}>
                <I18nSpan msgKey="vatRate.message.list.error" />
              </div>
            )}
            {vatRates &&
              !vatRatesErrors &&
              Object.entries(vatRates).length === 0 && (
                <div className={classNames("col-sm-12", styles.emptyList)}>
                  <I18nSpan msgKey="vatRate.noVatRates" />
                </div>
              )}
            {vatRates &&
              !vatRatesErrors &&
              Object.entries(vatRates).map((variable, index) => {
                const [name, value] = variable;

                return (
                  <div
                    className={classNames("row", styles.container)}
                    key={index}
                  >
                    <div className="col-sm-4">
                      <BootstrapInput
                        descriptor={{
                          id: name,
                          readOnly: true,
                          type: "text",
                          inputClass: "col-sm-12",
                          label: "vatRate.form.name.label"
                        }}
                        onChange={() => undefined}
                        formValue={name}
                        inputRef={this.setInputRef(name)}
                        name={name}
                      />
                    </div>
                    <div className="col-sm-4">
                      <BootstrapInput
                        descriptor={{
                          id: value,
                          type: "text",
                          readOnly: true,
                          label: "vatRate.form.value.label",
                          inputClass: "col-sm-12",
                          className: styles.valueContainer
                        }}
                        inputRef={this.setInputRef(value)}
                        name={value}
                        onChange={() => undefined}
                        formValue={value}
                      />
                    </div>
                    <div
                      className={classNames("col-sm-4", styles.deleteContainer)}
                    >
                      <a
                        onClick={event =>
                          this._openPopinConfirmDelete(event, name)
                        }
                        className="btn btn-circle"
                      >
                        <i className="glyphicon glyphicon-trash" />
                      </a>
                      <a
                        onClick={event =>
                          this._onEditVatRate(event, name, value)
                        }
                        className="btn btn-circle"
                      >
                        <i className="glyphicon glyphicon-pencil" />
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: {
        scope: {
          level: { id: merchantId }
        }
      }
    },
    vatRates: { data: vatRates },
    vatRate: { data: vatRate }
  } = state;
  return { vatRates, vatRate, merchantId };
};

const mapDispatchToProps = dispatch => ({
  getVatRates: (merchantId: string) => dispatch(getVatRates(merchantId)),
  deleteVatRate: (merchantId: string, name: string) =>
    dispatch(deleteVatRate(merchantId, name)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(VatRatesView);
