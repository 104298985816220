import {
    RESET_SIM_STATE_ACTION,
    PENDING_GET_SIM_ACTION,
    SUCCESS_GET_SIM_ACTION,
    FAILURE_GET_SIM_ACTION,
    SUCCESS_LOAD_PROVIDERS_ACTION,
    SUCCESS_LOAD_CONTRACTS_ACTION,
    SUCCESS_LOAD_OFFER_IDS_ACTION
  } from "../actionsTypes";
  
  const initialState = {
    data: null,
    loading: false,
    providers: null,
    contracts: null,
    offers: null,
    error: false,
    errors: {}
  };
  
  export default (state = initialState, action: any) => {
    switch (action.type) {
      case PENDING_GET_SIM_ACTION: {
        return {
          ...state,
          loading: true,
          error: false,
          errors: {}
        };
      }
      case SUCCESS_GET_SIM_ACTION: {
        const {
          payload: { sim }
        } = action;

        return {
          ...state,
          data: sim,
          loading: false,
          error: false,
          errors: {}
        };
      }
      case FAILURE_GET_SIM_ACTION: {
        const {
          payload: { errors }
        } = action;
  
        return {
          ...state,
          data: initialState.data,
          loading: false,
          error: true,
          ...errors
        };
      }
      case SUCCESS_LOAD_PROVIDERS_ACTION: {
        const {
          payload: { providers }
        } = action;

        return {
          ...state,
          providers
        };
      }
      case SUCCESS_LOAD_CONTRACTS_ACTION: {
        const {
          payload: { contracts }
        } = action;

        return {
          ...state,
          contracts: contracts
        };
      }
      case SUCCESS_LOAD_OFFER_IDS_ACTION: {
        const {
          payload: { offers }
        } = action;

        return {
          ...state,
          offers
        };
      }
      case RESET_SIM_STATE_ACTION:
        return initialState;
      default:
        return state;
    }
  };
  