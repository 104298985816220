import * as moment from "moment";
import { getTransactionsAgg } from "./widgetData";
import {
  PeriodSelection,
  toPeriodType
} from "../../reporting/models/TimePeriod";
import { constantCase } from "constant-case";
import F from "../../commons/HTTPFetcher";

const fetchNetRevenueTrends = ({ period, startTime, endTime }) => {
  if (startTime && endTime) {
    return F.get(`/trend/revenues/${period}/${startTime}/${endTime}`);
  }
  return F.get("/trend/revenues");
};

const upperBoundToMonth = (period: PeriodSelection) => {
  if (period === PeriodSelection.INTRADAY) {
    return "Day";
  } else if (period === PeriodSelection.WEEK_TO_DATE) {
    return "Week";
  }

  return "Month";
};

const upperBoundToMonthRanges = (period: PeriodSelection) => {
  switch (period) {
    case PeriodSelection.INTRADAY: {
      return [
        {
          key: "yesterday",
          from: "now-1d/d",
          to: "now-1d"
        },
        {
          key: "today",
          from: "now/d",
          to: "now"
        }
      ];
    }
    case PeriodSelection.WEEK_TO_DATE: {
      return [
        {
          key: "lastWeek",
          from: "now-1w/w",
          to: "now-1w"
        },
        {
          key: "thisWeek",
          from: "now/w",
          to: "now"
        }
      ];
    }
    default: {
      return [
        {
          key: "lastMonth",
          from: "now-1M/M",
          to: "now-1M"
        },
        {
          key: "thisMonth",
          from: "now/M",
          to: "now"
        }
      ];
    }
  }
};

export const loadTrendsTransactionCount = ({
  widgetType,
  widgetId,
  filters = [],
  timePeriod,
  user
}: any) => async dispatch => {
  const ranges = upperBoundToMonthRanges(timePeriod.type);

  const timezone = user ? moment.tz(user.timezone).format("Z") : "";

  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];
  dispatch(getTrendStart({ widgetType, widgetId }));

  try {
    const trendsTransactionCount = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "trendsTransactionCount",
        field: "mshTransactionTime",
        type: "date_range",
        timezone,
        ranges,
        subaggs: [
          {
            name: "transactionCount",
            type: "count",
            field: "transactionAmount"
          }
        ]
      }
    });
    const {
      trendsTransactionCount: {
        buckets: [
          {
            transactionCount: { value: transactionCountBefore }
          },
          {
            transactionCount: { value: transactionCountNow }
          }
        ]
      }
    } = trendsTransactionCount;

    const sales = parseInt(
      ((transactionCountNow - transactionCountBefore) /
        transactionCountBefore) *
        100,
      10
    );

    const trend = {
      count: transactionCountBefore,
      sales
    };

    dispatch(getTrendSucceeded({ widgetType, widgetId, trend }));
  } catch (errors) {
    dispatch(getTrendFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const loadTrendsCashback = ({
  widgetType,
  widgetId,
  filters = [],
  timePeriod,
  user
}: any) => async dispatch => {
  const ranges = upperBoundToMonthRanges(timePeriod.type);

  const timezone = user ? moment.tz(user.timezone).format("Z") : "";

  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const trendsCashback = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "trendsCashback",
        field: "mshTransactionTime",
        type: "date_range",
        timezone,
        ranges,
        subaggs: [
          {
            name: "cashbackSum",
            type: "sum",
            field: "cashbackAmount"
          }
        ]
      }
    });
    const {
      trendsCashback: {
        buckets: [
          {
            cashbackSum: { value: cashbackSumBefore }
          },
          {
            cashbackSum: { value: cashbackSumNow }
          }
        ]
      }
    } = trendsCashback;

    const cashback = parseInt(
      ((cashbackSumNow - cashbackSumBefore) / cashbackSumBefore) * 100,
      10
    );

    const trend = {
      cashback
    };

    dispatch(getTrendSucceeded({ widgetType, widgetId, trend }));
  } catch (errors) {
    dispatch(getTrendFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const loadTrendsAverageBasket = ({
  widgetType,
  widgetId,
  filters = [],
  timePeriod,
  user
}: any) => async dispatch => {
  const ranges = upperBoundToMonthRanges(timePeriod.type);

  const timezone = user ? moment.tz(user.timezone).format("Z") : "";

  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const trendsAverageBasket = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "trendsAverageBasket",
        field: "mshTransactionTime",
        type: "date_range",
        timezone,
        ranges,
        subaggs: [
          {
            name: "averageBasket",
            type: "avg",
            field: "transactionAmount"
          }
        ]
      }
    });
    const {
      trendsAverageBasket: {
        buckets: [
          {
            averageBasket: { value: averageBasketBefore }
          },
          {
            averageBasket: { value: averageBasketNow }
          }
        ]
      }
    } = trendsAverageBasket;

    const avg = parseInt(
      ((averageBasketNow - averageBasketBefore) / averageBasketBefore) * 100,
      10
    );

    const trend = {
      avg
    };

    dispatch(getTrendSucceeded({ widgetType, widgetId, trend }));
  } catch (errors) {
    dispatch(getTrendFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const loadTrendsRefundAmount = ({
  widgetType,
  widgetId,
  filters = [],
  timePeriod,
  user
}: any) => async dispatch => {
  const ranges = upperBoundToMonthRanges(timePeriod.type);

  const timezone = user ? moment.tz(user.timezone).format("Z") : "";

  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["REFUND"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const trendsRefundAmount = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "trendsRefund",
        field: "mshTransactionTime",
        type: "date_range",
        timezone,
        ranges,
        subaggs: [
          {
            name: "refundSum",
            type: "sum",
            field: "transactionAmount"
          }
        ]
      }
    });

    const {
      trendsRefund: {
        buckets: [
          {
            refundSum: { value: refundSumBefore }
          },
          {
            refundSum: { value: refundSumNow }
          }
        ]
      }
    } = trendsRefundAmount;

    const refund = parseInt(
      ((refundSumNow - refundSumBefore) / refundSumBefore) * 100,
      10
    );

    const trend = {
      refund
    };

    dispatch(getTrendSucceeded({ widgetType, widgetId, trend }));
  } catch (errors) {
    dispatch(getTrendFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const loadTrendsRevenues = ({
  widgetType,
  widgetId,
  filters = [],
  timePeriod,
  timestamps
}: any) => dispatch => {
  const { min, max } = timestamps;

  const boundedPeriod = upperBoundToMonth(timePeriod.type);

  dispatch(getTrendStart({ widgetType, widgetId }));

  return fetchNetRevenueTrends({
    period: boundedPeriod,
    startTime: min,
    endTime: max
  })
    .then(({ netRevenues: { trend: value } }) => {
      const trend = {
        netRevenues: value
      };

      dispatch(getTrendSucceeded({ widgetType, widgetId, trend }));
      return trend;
    })
    .catch(errors => {
      dispatch(getTrendFailure({ widgetType, widgetId, errors }));
      throw errors;
    });
};

const getTrendStart = ({ widgetType, widgetId }) => {
  const name = constantCase(widgetType);

  return {
    name,
    type: `PENDING_GET_TREND_ACTION_${name}`,
    payload: {
      widgetId
    }
  };
};

const getTrendSucceeded = ({ widgetType, widgetId, trend }) => {
  const name = constantCase(widgetType);

  return {
    name,
    type: `SUCCESS_GET_TREND_ACTION_${name}`,
    payload: {
      widgetId,
      trend
    }
  };
};

const getTrendFailure = ({ widgetType, widgetId, errors }) => {
  const name = constantCase(widgetType);

  return {
    name,
    type: `FAILURE_GET_TREND_ACTION_${name}`,
    payload: {
      widgetId,
      errors
    }
  };
};

export const resetWidgetsTrendState = () => ({
  type: `RESET_WIDGETS_TREND_STATE_ACTION`
});
