import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_SCHEDULER_JOBS_ACTION,
  SUCCESS_GET_SCHEDULER_JOBS_ACTION,
  FAILURE_GET_SCHEDULER_JOBS_ACTION
} from "../actionsTypes";

const _mapJobs = ({ jobs }) => {
  const schedulerJobs = Object.entries(jobs)
    .reduce((acc, current) => {
      const [, job] = current;
      acc.push({
        ...job
      });
      return acc;
    }, [])
    .sort(
      (curr, next) =>
        new Date(curr.timestamp).getTime() - new Date(next.timestamp).getTime()
    );
  return { schedulerJobs };
};

export const getSchedulerJobs = () => async dispatch => {
  dispatch(getSchedulerJobsStart());

  return F.get("/monitoring/scheduler/job")
    .then(jobs => {
      const { schedulerJobs } = _mapJobs({ jobs });
      dispatch(getSchedulerJobsSucceeded({ schedulerJobs }));
      return schedulerJobs;
    })
    .catch(errors => {
      dispatch(getSchedulerJobsFailure({ errors }));
      throw errors;
    });
};

const getSchedulerJobsStart = () => ({
  type: PENDING_GET_SCHEDULER_JOBS_ACTION
});

const getSchedulerJobsSucceeded = ({ schedulerJobs }) => ({
  type: SUCCESS_GET_SCHEDULER_JOBS_ACTION,
  payload: {
    schedulerJobs
  }
});

const getSchedulerJobsFailure = errors => ({
  type: FAILURE_GET_SCHEDULER_JOBS_ACTION,
  payload: {
    errors
  }
});
