import F from "../../commons/HTTPFetcher";
import {
  PENDING_GET_LOGO_ACTION,
  SUCCESS_GET_LOGO_ACTION,
  FAILURE_GET_LOGO_ACTION
} from "../actionsTypes";

export const uploadLogo = ({ data }) => dispatch => {
  dispatch(uploadLogoStart());

  return F.post("/customer/logo", {
    body: data
  }).then(
    ({ logo: { content: logo } }) => {
      dispatch(uploadLogoSucceeded({ logo }));
      return logo;
    },
    errors => {
      dispatch(uploadLogoFailure({ errors }));
      throw errors;
    }
  );
};

const uploadLogoStart = () => ({
  type: PENDING_GET_LOGO_ACTION
});

const uploadLogoSucceeded = ({ logo }) => ({
  type: SUCCESS_GET_LOGO_ACTION,
  payload: {
    logo
  }
});

const uploadLogoFailure = errors => ({
  type: FAILURE_GET_LOGO_ACTION,
  payload: {
    errors
  }
});
