import React from "react";

import styles from "../styles/styles.css";

export default function LoginInput({ label, onChange, value }) {
  return (
    <div className={styles["form-group"]}>
      <label htmlFor="login" className={styles["control-label"]}>
        {label}
      </label>
      <input
        id="login"
        name="login"
        className="form-control"
        onChange={onChange}
        required
        value={value}
        autoComplete="username"
      />
    </div>
  );
}
