import ky from "ky";

import {
  PENDING_GET_CONFIG_ACTION,
  SUCCESS_GET_CONFIG_ACTION,
  FAILURE_GET_CONFIG_ACTION
} from "../actionsTypes";

export const getConfig = () => dispatch => {
  dispatch(getConfigStart());

  return ky
    .get(`/conf`)
    .json()
    .then(
      config => {
        dispatch(getConfigSucceeded({ config }));

        return config;
      },
      errors => {
        dispatch(getConfigFailure(errors));

        throw errors;
      }
    );
};

const getConfigStart = () => ({
  type: PENDING_GET_CONFIG_ACTION
});

const getConfigSucceeded = ({ config }) => ({
  type: SUCCESS_GET_CONFIG_ACTION,
  payload: {
    config
  }
});

const getConfigFailure = errors => ({
  type: FAILURE_GET_CONFIG_ACTION,
  payload: {
    errors
  }
});
