import React, { Component } from "react";
import * as DateTime from "react-datetime";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import TimePeriods from "../reporting/models/TimePeriod";
import I18nSpan from "../i18n/components/I18nSpan";

import DateFormatter from "../formatters/DateFormatter";

import styles from "./styles/styles.css";

interface Props {
  onChange: Function;
  timePeriods: any;
  initialTimePeriod: any;
  disabled: boolean;
  user: any;
  locale: any;
  timePeriod: any;
  dateFormat: any;
  timePeriodsLabel: any;
}

interface State {}

class TimeSelector extends Component<Props, State> {
  _onChange = e => {
    const { searchContext } = this.props;
    const { timePeriod: previousTimePeriod = {} } = searchContext;
    const timePeriodType = e.target.value;
    const isBetween = timePeriodType === "BETWEEN";

    const timePeriod = this.props.timePeriods[timePeriodType];

    if (isBetween) {
      timePeriod.setBetweenFromTimePeriod(
        previousTimePeriod.startTime,
        previousTimePeriod.endTime
      );
    } else {
      timePeriod.refreshTime();
    }

    this.saveTimePeriod({ timePeriod });
  };

  _onStartTimeChanged = val => {
    this._onBetweenTimeChanged("from", val);
  };

  _onEndTimeChanged = val => {
    this._onBetweenTimeChanged("to", val);
  };

  _onBetweenTimeChanged(name, newDate) {
    const timePeriod = TimePeriods.BETWEEN;
    if (name === "from") {
      timePeriod.startTime = timePeriod.startTimeFunction(newDate.clone());
    } else {
      timePeriod.endTime = timePeriod.endTimeFunction(newDate.clone());
    }
    timePeriod.setBetweenFromTimePeriod(
      timePeriod.startTime,
      timePeriod.endTime
    );
    this.saveTimePeriod({ timePeriod });
  }

  _onExactTimeChanged = newDate => {
    if (newDate._isAMomentObject) {
      //Check validity
      const timePeriod = TimePeriods.EXACT;
      timePeriod.startTime = timePeriod.startTimeFunction(newDate.clone());
      timePeriod.endTime = timePeriod.endTimeFunction(newDate.clone());

      this.saveTimePeriod({ timePeriod });
    }
  };

  saveTimePeriod({ timePeriod }) {
    this.setState({ timePeriod }, () => {
      const { onChange, searchContext } = this.props;
      // TODO: remove after search context migration
      return onChange && onChange({ timePeriod, searchContext });
    });
  }

  render() {
    const timePeriod = this.props.initialTimePeriod || TimePeriods.INTRADAY;
    const {
      timePeriods,
      timePeriodsLabel,
      locale,
      disabled: timePeriodSelectorStatus,
      dateFormat
    } = this.props;

    interface TimePeriodProps {
      timePeriod: any;
      locale: any;
      dateFormat: any;
    }

    const TimePeriodBetween = ({
      timePeriod,
      locale,
      dateFormat
    }: TimePeriodProps) => {
      if (timePeriod === TimePeriods.BETWEEN) {
        return (
          <div className="datetime-selector">
            <div className="input-group date">
              <DateTime
                locale={locale}
                defaultValue={timePeriod.startTime}
                onBlur={this._onStartTimeChanged}
                dateFormat={dateFormat}
              />
            </div>
            <I18nSpan msgKey="timeSelector.AND" />
            <div className="input-group date">
              <DateTime
                locale={locale}
                defaultValue={timePeriod.endTime}
                onBlur={this._onEndTimeChanged}
                dateFormat={dateFormat}
              />
            </div>
          </div>
        );
      }
      return null;
    };

    const TimePeriodExact = ({
      timePeriod,
      locale,
      dateFormat
    }: TimePeriodProps) => {
      if (timePeriod === TimePeriods.EXACT) {
        const { startTime } = timePeriod;
        return (
          <div className="datetime-selector">
            <div className="input-group date">
              <DateTime
                locale={locale}
                defaultValue={startTime}
                onBlur={this._onExactTimeChanged}
                dateFormat={dateFormat}
              />
            </div>
          </div>
        );
      }
      return null;
    };

    interface PeriodSelectorProps {
      timePeriods: any;
      timePeriod: any;
    }

    const PeriodSelector = ({
      timePeriods,
      timePeriod
    }: PeriodSelectorProps) => {
      return (
        <select
          className="form-control period-selector"
          onChange={this._onChange}
          value={timePeriod.type}
        >
          {_.map(timePeriods, (period: any) => {
            const { type } = period;
            return (
              <option key={type} value={type}>
                {timePeriodsLabel[type]}
              </option>
            );
          })}
        </select>
      );
    };

    return (
      <div
        className={classNames(
          "timeperiod-selector",
          "pln",
          "form-inline",
          timePeriodSelectorStatus ? "disabled" : "",
          styles["timeperiod-selector"]
        )}
      >
        <PeriodSelector timePeriods={timePeriods} timePeriod={timePeriod} />
        <TimePeriodBetween
          timePeriod={timePeriod}
          locale={locale}
          dateFormat={dateFormat}
        />
        <TimePeriodExact
          timePeriod={timePeriod}
          locale={locale}
          dateFormat={dateFormat}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user }
  } = state;

  const { timePeriods = TimePeriods, t } = ownProps;

  const timePeriodsLabel = _.zipObject(
    _.map(timePeriods, (timePeriod: any) => [
      timePeriod.type,
      t(`timeSelector.${timePeriod.type}`)
    ])
  );

  return {
    user,
    timePeriodsLabel,
    betweenLabel: t("timeSelector.BETWEEN"),
    dateFormat: DateFormatter.getDateFormat(user, false),
    timePeriods,
    locale: window.navigator.userLanguage || window.navigator.language
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, null)
)(TimeSelector);
