import F from "../../commons/HTTPFetcher";

import {
  FAILURE_CREATE_STORE_ACTION,
  PENDING_CREATE_STORE_ACTION,
  SUCCESS_CREATE_STORE_ACTION,
  FAILURE_UPDATE_STORE_ACTION,
  PENDING_UPDATE_STORE_ACTION,
  SUCCESS_UPDATE_STORE_ACTION,
  PENDING_GET_STORE_ACTION,
  SUCCESS_GET_STORE_ACTION,
  FAILURE_GET_STORE_ACTION,
  RESET_STORE_STATE_ACTION
} from "../actionsTypes";

import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

export const createStore = ({ store, storeType }) => async dispatch => {
  dispatch(createStoreStart());

  const { merchantId = "" } = store;
  const url =
    storeType === "customer"
      ? new URL(`/store/${storeType}/${merchantId}`, PORTAL_URL)
      : new URL(`/store/${storeType}`, PORTAL_URL);

  return F.post(url.pathname, {
    body: store,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(createStoreSucceeded()),
    errors => {
      dispatch(createStoreFailure({ errors }));

      throw errors;
    }
  );
};

const createStoreStart = () => ({
  type: PENDING_CREATE_STORE_ACTION
});

const createStoreSucceeded = () => ({
  type: SUCCESS_CREATE_STORE_ACTION
});

const createStoreFailure = errors => ({
  type: FAILURE_CREATE_STORE_ACTION,
  payload: {
    errors
  }
});

export const updateStore = ({ store }) => async dispatch => {
  dispatch(editStoreStart());

  const { id } = store;
  const url = new URL(`/store/${id}`, PORTAL_URL);

  return F.put(url.pathname, {
    body: store,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(editStoreSucceeded()),
    errors => {
      dispatch(editStoreFailure({ errors }));

      throw errors;
    }
  );
};

const editStoreStart = () => ({
  type: PENDING_UPDATE_STORE_ACTION
});

const editStoreSucceeded = () => ({
  type: SUCCESS_UPDATE_STORE_ACTION
});

const editStoreFailure = errors => ({
  type: FAILURE_UPDATE_STORE_ACTION,
  payload: {
    errors
  }
});

export const getStore = ({ id }) => async dispatch => {
  dispatch(getStoreStart());

  return F.get(`/store/${id}`).then(
    store => {
      dispatch(getStoreSucceeded({ store }));
      return store;
    },
    errors => {
      dispatch(getStoreFailure(errors));
      throw errors;
    }
  );
};

const getStoreStart = () => ({
  type: PENDING_GET_STORE_ACTION
});

const getStoreSucceeded = ({ store }) => ({
  type: SUCCESS_GET_STORE_ACTION,
  payload: {
    store
  }
});

const getStoreFailure = errors => ({
  type: FAILURE_GET_STORE_ACTION,
  payload: {
    errors
  }
});

export const resetStoreState = () => ({
  type: RESET_STORE_STATE_ACTION
});
