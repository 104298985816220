import React from "react";
import { withTranslation } from "react-i18next";

import If from "./if";

const ObjectField = ({ hideEmpty, value = "", t, defaultName, i18nKey }) => {
  return (
    <If test={(hideEmpty && value && value.length > 0) || !hideEmpty}>
      <div className="form-group row">
        <label className="text-right-not-xs col-sm-4 col-xs-12 key">
          {t(i18nKey, defaultName)}
        </label>
        <span className="col-sm-8 col-xs-12">{value}</span>
      </div>
    </If>
  );
};

export default withTranslation()(ObjectField);
