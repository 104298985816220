import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import I18nSpan from "../../../i18n/components/I18nSpan";

import styles from "../../styles/styles.css";

interface Props {
  msgKey: String;
  onClick: Function;
  className?: String;
  block?: Boolean;
}

interface State {
  disabled: Boolean;
}
export class DownloadAction extends Component<Props, State> {
  state = {
    disabled: false
  };

  render() {
    const { onClick, msgKey, className, block = false } = this.props;
    return (
      <div
        className={classNames(
          "btn",
          "multiple-action",
          styles["multiple-action"],
          styles[`${className}`],
          {
            [styles["btn--disabled"]]: block && this.state.disabled
          }
        )}
        onClick={onClick}
      >
        <span
          className={classNames(
            "glyphicon",
            "glyphicon-download",
            styles.glyphicon
          )}
        />
        <I18nSpan msgKey={msgKey} />
      </div>
    );
  }
}
