import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../i18n/components/I18nSpan";
import BootstrapInput from "../ingenicoForm/components/BootstrapInput";
import FormTitle from "../ingenicoForm/components/FormTitle";

import Permissions from "../user/constants/Permissions";

import {
  resetMerchants,
  addNotificationSuccess,
  addNotificationError,
  getUsers,
  sendTransactionReceipt,
  sendTransactionsMultipleReceipt
} from "../redux/actions";

interface State {
  selectedRecipients: any;
  mailBody: any;
  sendMeCopy: any;
}

interface Props {
  resetMerchants: Function;
  history: any;
  getUsers: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  match: any;
  color: any;
  recipients: any;
  selectedIds: any;
  location: any;
  sendMerchantReceipt: Function;
  sendMultipleReceipt: Function;
  permissions: any;
}

class MerchantReceiptSendView extends Component<Props, State> {
  state = {
    sendMeCopy: true,
    mailBody: "",
    selectedRecipients: []
  };

  componentDidMount() {
    const { getUsers, permissions } = this.props;

    _.includes(permissions, Permissions.USER_MANAGEMENT) ? getUsers() : null;
  }

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  sendBtnHandler = e => {
    e.preventDefault();

    const {
      selectedIds: ids,
      location: { pathname },
      match: {
        params: { transactionId: id, receiptType: receiptActionType }
      },
      history,
      addNotificationSuccess,
      addNotificationError,
      sendMerchantReceipt,
      sendMultipleReceipt
    } = this.props;
    const { sendMeCopy: sendCopy, mailBody, selectedRecipients } = this.state;

    const isMultiple = _.includes(pathname, "multiple");
    const recipients = selectedRecipients.map(r => r.value);

    let sendReceiptAction;

    if (isMultiple) {
      sendReceiptAction = sendMultipleReceipt({
        ids,
        sendCopy,
        mailBody,
        recipients,
        receiptActionType
      });
    } else {
      sendReceiptAction = sendMerchantReceipt({
        id,
        sendCopy,
        mailBody,
        recipients,
        receiptActionType
      });
    }

    sendReceiptAction
      .then(() => {
        addNotificationSuccess("receipt.send.notification.success");
        history.push("/main/reporting/transaction");
      })
      .catch(error =>
        addNotificationError("receipt.send.notification.error", [
          error.responseText
        ])
      );
  };

  changeSendMeCopy = () => {
    this.setState({ sendMeCopy: !this.state.sendMeCopy });
  };

  onExit = e => {
    e.preventDefault();

    const { history } = this.props;

    return history.push("/main/reporting/transaction");
  };

  componentWillUnmount() {
    const { resetMerchants } = this.props;

    resetMerchants();
  }

  render() {
    const { selectedRecipients, mailBody, sendMeCopy } = this.state;
    const { color, recipients } = this.props;

    return (
      <div id="receipt-send">
        <FormTitle
          color={color}
          titleKey="receipt.title"
          actionKey="receipt.send.subtitle.merchant"
        />
        <form className="ingenico-form form-horizontal">
          <BootstrapInput
            descriptor={{
              type: "multipleselect",
              options: recipients,
              label: "receipt.send.recipients",
              className: "mtn"
            }}
            name="selectedRecipients"
            onChange={this.onChange}
            formValue={selectedRecipients}
          />

          <BootstrapInput
            descriptor={{
              type: "textarea",
              label: "receipt.send.mailBody"
            }}
            name="mailBody"
            onChange={this.onChange}
            formValue={mailBody}
          />

          <div className="form-group">
            <label className="control-label col-sm-3" htmlFor="send-copy">
              <I18nSpan msgKey="receipt.send.sendCopy" />
            </label>
            <div className="col-sm-6">
              <input
                id="send-copy"
                type="checkbox"
                checked={sendMeCopy}
                onChange={this.changeSendMeCopy}
              />
            </div>
          </div>
          <div className="pull-right">
            <button
              onClick={this.sendBtnHandler}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.send"} />
            </button>
            <button
              onClick={this.onExit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      user: { permissions = [] }
    },
    merchants: { receipts },
    theme: {
      color: { data: color }
    },
    users: { data: users }
  } = state;

  const selectedIds = _.keys(receipts);
  const userMails = _.uniq(users.map(u => u.email));

  return {
    selectedIds,
    recipients: userMails.map(mail => {
      return {
        id: mail,
        name: mail
      };
    }),
    color,
    permissions
  };
};

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(getUsers()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  resetMerchants: () => dispatch(resetMerchants()),
  sendMerchantReceipt: ({
    id,
    recipients,
    sendCopy,
    mailBody,
    receiptActionType
  }) =>
    dispatch(
      sendTransactionReceipt({
        id,
        recipients,
        sendCopy,
        mailBody,
        receiptType: "merchant",
        receiptActionType
      })
    ),
  sendMultipleReceipt: ({
    ids,
    recipients,
    sendCopy,
    mailBody,
    receiptActionType
  }) =>
    dispatch(
      sendTransactionsMultipleReceipt({
        ids,
        recipients,
        sendCopy,
        mailBody,
        receiptActionType
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MerchantReceiptSendView);
