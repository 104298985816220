import React from "react";
import classNames from "classnames";

import styles from "../styles/styles.css";

export const Refresh = ({ onRefresh }) => (
  <div className="pull-right">
    <button className={classNames("btn", styles.refresh)} onClick={onRefresh}>
      <span className="glyphicon glyphicon-refresh" />
    </button>
  </div>
);
