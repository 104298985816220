import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import * as download from "downloadjs";
import { connect } from "react-redux";
import { compose } from "redux";

import Iframe from "../components/Iframe";
import I18nSpan from "../i18n/components/I18nSpan";
import { Modal } from "react-bootstrap";

import {
  getTransactionsMultipleReceipt,
  downloadTransactionsMultipleReceipt,
  closeTransactionsReceipts,
  addNotificationSuccess,
  addNotificationError
} from "../redux/actions";

import styles from "./styles/styles.css";

interface Props {
  showReceiptsSelectionPopin: boolean;
  receipts: any;
  transactionIds: Array<string>;
  onClose: Function;
  isLoading: boolean;
  receiptActionType: string;
  getTransactionsMultipleReceipt: Function;
  downloadTransactionsMultipleReceipt: Function;
  closeTransactionsReceipts: Function;
  addNotificationError: Function;
}

class MultipleReceiptViewer extends Component<Props, State> {
  _closeSelection = () => {
    const { onClose } = this.props;

    return onClose();
  };

  _printReceipts = async () => {
    const { getTransactionsMultipleReceipt, transactionIds } = this.props;

    getTransactionsMultipleReceipt(transactionIds).then(html => {
      const printIframe = this.printIframeElement;

      printIframe.contentWindow.document.open();
      printIframe.contentWindow.document.write(html);
      printIframe.contentWindow.document.close();
      printIframe.contentWindow.focus();
      printIframe.contentWindow.print();
    });
  };

  _downloadReceipts = async () => {
    const {
      downloadTransactionsMultipleReceipt,
      transactionIds,
      addNotificationError
    } = this.props;

    try {
      const blob = await downloadTransactionsMultipleReceipt(transactionIds);

      return download(blob, `receipts.pdf`);
    } catch (errorText) {
      return addNotificationError("receipt.download.error", [errorText]);
    }
  };

  _sendReceipts = () => {
    const { receiptActionType, history } = this.props;

    return history.push(
      `/main/settings/merchantReceipt/${receiptActionType}/multiple/send`
    );
  };

  _closeReceipt = receiptId => {
    const { closeTransactionsReceipts } = this.props;

    closeTransactionsReceipts(receiptId);
  };

  render() {
    const {
      showReceiptsSelectionPopin,
      receipts,
      transactionIds,
      isLoading
    } = this.props;

    return (
      <Modal
        show={showReceiptsSelectionPopin}
        onHide={this._closeSelection}
        animation={false}
        dialogClassName="receiptsModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <I18nSpan msgKey={"reporting.transaction.receipts.modal.title"} />{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="receiptContainer">
            {transactionIds.map(key => (
              <div key={key} className="receipt">
                <div className="header">
                  <span
                    className="close"
                    onClick={() => this._closeReceipt(key)}
                  >
                    x
                  </span>
                </div>
                <div className="receiptBody">
                  <Iframe id={`${key}-iframe`} docContent={receipts[key]} />
                </div>
              </div>
            ))}
            {isLoading ? (
              <div className="receipt loading">
                <div className="loadingTxt">
                  <div className="spinner">
                    <div className="bounceBx bounce1" />
                    <div className="bounceBx bounce2" />
                    <div className="bounceBx bounce3" />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <iframe
            className={styles["print-iframe"]}
            ref={el => (this.printIframeElement = el)}
          />
        </Modal.Body>
        <Modal.Footer>
          <div role="toolbar">
            <button
              type="button"
              className="btn btn-icon btn-ingenico"
              onClick={this._printReceipts}
            >
              <span className="glyphicon glyphicon-print" aria-hidden="true" />
              <I18nSpan msgKey="reporting.transaction.receipts.modal.buttons.print" />
            </button>
            <button
              type="button"
              className="btn btn-icon btn-ingenico"
              onClick={this._downloadReceipts}
            >
              <span
                className="glyphicon glyphicon-download"
                aria-hidden="true"
              />
              <I18nSpan msgKey="reporting.transaction.receipts.modal.buttons.download" />
            </button>
            <button
              type="button"
              className="btn btn-icon btn-ingenico"
              onClick={this._sendReceipts}
            >
              <span className="glyphicon glyphicon-send" aria-hidden="true" />
              <I18nSpan msgKey="reporting.transaction.receipts.modal.buttons.send" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const {
    transactions: { loading: isLoading, receipts, showReceiptsSelectionPopin }
  } = state;

  const transactionIds = _.keys(receipts);

  return {
    isLoading,
    receipts,
    showReceiptsSelectionPopin,
    transactionIds
  };
};

const mapDispatchToProps = dispatch => ({
  getTransactionsMultipleReceipt: receiptIds =>
    dispatch(getTransactionsMultipleReceipt(receiptIds)),
  downloadTransactionsMultipleReceipt: receiptIds =>
    dispatch(downloadTransactionsMultipleReceipt(receiptIds)),
  closeTransactionsReceipts: receiptId =>
    dispatch(closeTransactionsReceipts(receiptId)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MultipleReceiptViewer);
