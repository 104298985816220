import React from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";
import I18nSpan from "../../i18n/components/I18nSpan";

class ErrorsList extends React.Component {
  render() {
    return (
      <div className="errors-messages">
        <ul>
          {_.map(this.props.errors, err => (
            <li key={err.code + err.args}>
              <span className="text-danger">
                <I18nSpan msgKey={err.code} args={err.args} />
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

ErrorsList.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      args: PropTypes.array
    })
  )
};

export default ErrorsList;
