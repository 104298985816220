import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { WidgetConfig } from "../Widget";
import NumberFormatter from "../../../formatters/NumberFormatter";
import BarChart from "../../../chart/components/chart/BarChart";
import LoadingChart from "./LoadingChart";
import { getData } from "../../utils/DataProvider";

interface AverageSales {
  singleTransactionsAverage: any;
  recurringTransactionsAverage: any;
}

interface Data {
  averageSales: AverageSales;
}

interface Props {
  getData: Function;
  data: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  config: WidgetConfig;
}

class CustomersAverageBasketBarChart extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ config });
    }
  }

  render() {
    const { data, color, loading, config, title, t } = this.props;

    const {
      averageSales: {
        singleTransactionsAverage,
        recurringTransactionsAverage
      } = { singleTransactionsAverage: 0, recurringTransactionsAverage: 0 }
    } = data;
    const { userCurrency = {}, defaultPaletteId } = config;
    const { currency, currencyDecimal } = userCurrency;

    const chartPrefix = "reporting.customers.averagebasketbarchart";

    const chartTitle = t(title);
    const barChartTitle = `${chartTitle} (${currency})`;

    const xAxisLegend = t(`${chartPrefix}.xAxis.legend`);
    const yAxisLegend = t(`${chartPrefix}.yAxis.legend`);

    const tooltip = {
      y: {
        formatter: function(value) {
          return NumberFormatter.formatAmount(value, currency, currencyDecimal);
        }
      }
    };

    const categories = [
      t("reporting.customers.categories.newCustomers"),
      t("reporting.customers.categories.returningCustomers")
    ];

    const series = [
      {
        name: "",
        showInLegend: false,
        colorByPoint: true,
        data: [singleTransactionsAverage, recurringTransactionsAverage]
      }
    ];

    return (
      <LoadingChart loading={loading} color={color}>
        <BarChart
          color={color}
          title={barChartTitle}
          xAxisLegend={xAxisLegend}
          yAxisLegend={yAxisLegend}
          tooltip={tooltip}
          categories={categories}
          series={series}
          currency={userCurrency}
          palette={defaultPaletteId}
        />
      </LoadingChart>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }

  return {
    data: {},
    loading: true
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CustomersAverageBasketBarChart);
