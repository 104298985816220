import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import { CreatePredefinedAlert } from "../model/PredefinedAlert";
import "./CreatePredefinedAlertView.less";
import { CreatePayThenRefundAlert } from "../model/PayThenRefundAlert";
import { CreateMultipleUsageAlert } from "../model/MultipleUsageAlert";
import { CreateRefundOverThresholdAlert } from "../model/RefundOverThresholdAlert";
import { CreateRefundOutsidePolicyAlert } from "../model/RefundOutsidePolicyAlert";
import { CreateCompleteRefundOutsidePolicyAlert } from "../model/CompleteRefundOutsidePolicyAlert";
import { CreateRefundTooHighAlert } from "../model/RefundTooHighAlert";
import PayThenRefund from "./alert/PayThenRefund";
import MultipleUsage from "./alert/MultipleUsage";
import RefundOverThreshold from "./alert/RefundOverThreshold";
import RefundTooHigh from "./alert/RefundTooHigh";
import CompleteRefundOutsidePolicy from "./alert/CompleteRefundOutsidePolicy";

import FormTitle from "../../../ingenicoForm/components/FormTitle";
import I18nSpan from "../../../i18n/components/I18nSpan";
import RefundOutsidePolicy from "./alert/RefundOutsidePolicy";
import { CreateTransactionOnClosingHoursAlert } from "../model/TransactionOnClosingHoursAlert";
import TransactionOnClosingHours from "./alert/TransactionOnClosingHours";

import {
  addNotificationError,
  addNotificationSuccess,
  createPredefinedAlert
} from "../../../redux/actions";

import styles from "../../styles/CreatePredefinedAlertView.css";
import AlertConstant from "../../constants/AlertConstant";

interface Props {
  createPredefinedAlert: Function;
  addNotificationError: Function;
  addNotificationSuccess: Function;
}

interface State {
  selectedIndex: number;
  alerts: any;
}

export class CreatePredefinedAlertView extends Component<Props, State> {
  state = {
    selectedIndex: 0,
    alerts: this.props.alerts
  };

  saveAlert = e => {
    e.preventDefault();
    const { alerts, selectedIndex } = this.state;
    const {
      createPredefinedAlert,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    let errors = alerts[selectedIndex].isValid();

    if (errors.length === 0) {
      createPredefinedAlert({ predefinedAlert: alerts[selectedIndex] })
        .then(() => {
          addNotificationSuccess("alert.create.success");
          this.goToList();
        })
        .catch(errorPromise =>
          errorPromise.then(errorData => addNotificationError(errorData))
        );
    } else {
      errors.forEach(e => addNotificationError(e));
    }
  };

  goToList() {
    const { history } = this.props;
    history.push(`/main/reporting/alerts/${AlertConstant.PREDEFINED_ALERT}`);
  }

  exit = e => {
    e.preventDefault();
    this.goToList();
  };

  onChangeHandler = (index: number, alert: CreatePredefinedAlert) => {
    const { alerts } = this.state;
    alerts[index] = alert;
    this.setState({
      alerts
    });
  };

  onSelectOption(index: number) {
    this.setState({ selectedIndex: index });
  }

  render() {
    const { selectedIndex } = this.state;
    const { currencySymbol, currencyExponent } = this.props;

    return (
      <div id="create-predefined-alert">
        <FormTitle titleKey="alert.title" actionKey="alert.create.action" />

        <div className="alert-wrapper" onClick={() => this.onSelectOption(0)}>
          <input
            type="radio"
            name="alerts"
            id="optionPayThenRefund"
            checked={selectedIndex === 0}
          />
          <PayThenRefund onChange={alert => this.onChangeHandler(0, alert)} />
        </div>

        <div className="alert-wrapper" onClick={() => this.onSelectOption(1)}>
          <input
            type="radio"
            name="alerts"
            id="optionMultipleUsage"
            checked={selectedIndex === 1}
          />
          <MultipleUsage onChange={alert => this.onChangeHandler(1, alert)} />
        </div>

        <div className="alert-wrapper" onClick={() => this.onSelectOption(2)}>
          <input
            type="radio"
            name="alerts"
            id="optionRefundOverThreshold"
            checked={selectedIndex === 2}
          />
          <RefundOverThreshold
            onChange={alert => this.onChangeHandler(2, alert)}
            currencySymbol={currencySymbol}
            currencyExponent={currencyExponent}
          />
        </div>

        <div className="alert-wrapper" onClick={() => this.onSelectOption(3)}>
          <input
            type="radio"
            name="alerts"
            id="optionRefundOutsidePolicy"
            checked={selectedIndex === 3}
          />
          <RefundOutsidePolicy
            onChange={alert => this.onChangeHandler(3, alert)}
          />
        </div>

        <div className="alert-wrapper" onClick={() => this.onSelectOption(4)}>
          <input
            type="radio"
            name="alerts"
            id="optionRefundTooHigh"
            checked={selectedIndex === 4}
          />
          <RefundTooHigh
            onChange={alert => this.onChangeHandler(4, alert)}
            currencySymbol={currencySymbol}
            currencyExponent={currencyExponent}
          />
        </div>

        <div
          className={`alert-wrapper ${styles.container}`}
          onClick={() => this.onSelectOption(5)}
        >
          <input
            type="radio"
            name="alerts"
            id="optionTransactionOnClosingHours"
            checked={selectedIndex === 5}
          />
          <TransactionOnClosingHours
            onChange={alert => this.onChangeHandler(5, alert)}
          />
        </div>
        <div className="alert-wrapper" onClick={() => this.onSelectOption(6)}>
          <input
            type="radio"
            name="alerts"
            id="optionCompleteRefundOutsidePolicy"
            checked={this.state.selectedIndex === 6}
          />
          <CompleteRefundOutsidePolicy
            onChange={alert => this.onChangeHandler(6, alert)}
          />
        </div>

        <form>
          <div className="pull-right">
            <button
              onClick={this.saveAlert}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this.exit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export const mapStateToProps = state => {
  const {
    auth: {
      levelSettings: {
        currency: {
          alpha3: currencyCodeAlpha3,
          symbol: currency,
          exponent: currencyDecimal
        }
      } = {
        currency: {
          alpha3: "",
          symbol: "?",
          exponent: 0
        }
      }
    }
  } = state;

  const currencySymbol = currencyCodeAlpha3 + currency;
  const currencyExponent = currency ? currencyDecimal : 2;

  const defaultPayThenRefundAlert = new CreatePayThenRefundAlert();
  const defaultMultipleUsageAlert = new CreateMultipleUsageAlert();

  const defaultRefundOverThresholdAlert = CreateRefundOverThresholdAlert.create(
    currencySymbol,
    currencyExponent
  );
  const defaultRefundOutsidePolicyAlert = new CreateRefundOutsidePolicyAlert();
  const defaultCompleteRefundOutsidePolicyAlert = new CreateCompleteRefundOutsidePolicyAlert();
  const defaultRefundTooHighAlert = CreateRefundTooHighAlert.create(
    currencySymbol,
    currencyExponent
  );

  const defaultTransactionOnClosingHoursAlert = new CreateTransactionOnClosingHoursAlert();
  return {
    currencySymbol: currencySymbol,
    currencyExponent: currencyExponent,
    alerts: [
      defaultPayThenRefundAlert,
      defaultMultipleUsageAlert,
      defaultRefundOverThresholdAlert,
      defaultRefundOutsidePolicyAlert,
      defaultRefundTooHighAlert,
      defaultTransactionOnClosingHoursAlert,
      defaultCompleteRefundOutsidePolicyAlert
    ]
  };
};

const mapDispatchToProps = dispatch => ({
  createPredefinedAlert: ({ predefinedAlert }) =>
    dispatch(createPredefinedAlert({ predefinedAlert })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreatePredefinedAlertView);
