import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import get from "get-value";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import Counter from "../../datatable/components/Counter";

import ExportPopin from "../../components/ExportPopin";
import I18nSpan from "../../i18n/components/I18nSpan";
import DateFormatter from "../../formatters/DateFormatter";

import SimAdvancedSearch from "./SimAdvancedSearch";
import advancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import Formats from "../../commons/constants/ExportFormats";
import { Refresh } from "../../datatable/components/Refresh";

import { generateUrl } from "../../commons/utils/url";

import {
  deleteUserColumnsSettings,
  getFields,
  getSimsActivities,
  updateUserColumnsSettings,
  resetSimsActivities,
  getScroll,
  setSearchContext,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../redux/actions";
import { ExportAction } from "../../datatable/components/multipleActions";
import { SimProviders } from "../../sim/constants/SimFieldConstants";
import { setUrlWithParams } from "../../searchContext/URLizer";

import styles from "../styles/styles.css";

interface Props {
  loading: boolean;
  getFields: Function;
  getSimsActivities: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  attributes: any;
  color: any;
  simActivitiesCount: any;
  activities: any;
  visibleAttributes: any;
  formatter: any;
  hasActivities: boolean;
  resetSimsActivities: Function;
  getSimsActivitiesScroll: Function;
  simActivitiesScrollId: string;
  sortDescription: any;
  selectedSimIds: any;
}

interface State {
  showPopinGrouped: boolean;
  showPopinSingle: boolean;
  id: string;
}

const advancedSearchKey = advancedSearchConstants.SIM_NEW_KEY;

class SimActivitiesView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      showPopinGrouped: false,
      showPopinSingle: false
    };
    this.setUrlParams({ searchContext });
  }

  componentWillUnmount() {
    const { resetSimsActivities } = this.props;
    return resetSimsActivities();
  }

  async componentDidMount() {
    const { getFields, searchContext } = this.props;
    await getFields({ name: advancedSearchKey });

    await this._onRefresh({ searchContext });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getSimsActivities } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getSimsActivities({ filters, sort, tableCount });
  };

  _search = ({ searchContext }) => {
    const {
      getSimsActivities,
      tableCount,
      setSearchContext,
      location: { pathname }
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    return getSimsActivities({ filters, sort, tableCount });
  };

  _openPopinGrouped = () => {
    this.setState({ showPopinGrouped: true });
  };

  _closePopinGrouped = () => {
    this.setState({ showPopinGrouped: false });
  };

  _openPopinSingle = () => {
    const { selectedSimIds } = this.props;
    const [id] = selectedSimIds;

    this.setState({ showPopinSingle: true, id });
  };

  _closePopinSingle = () => {
    this.setState({ showPopinSingle: false, id: "" });
  };

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);
      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = "simMonitoring";

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  _loadMoreRows = () => {
    const { getSimsActivitiesScroll, simActivitiesScrollId } = this.props;

    return getSimsActivitiesScroll({ scrollId: simActivitiesScrollId });
  };

  onSelectRow = (rowId, value) => {
    const { selectedSimIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedSimIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const { id, showPopinGrouped, showPopinSingle } = this.state;
    const {
      loading,
      attributes,
      color,
      simActivitiesCount,
      activities,
      visibleAttributes,
      formatter,
      hasActivities,
      sortDescription,
      searchContext,
      filters,
      t,
      selectedSimIds
    } = this.props;
    const formatsSingle = [
      {
        url: generateUrl({
          url: `/reporting/sim/activities?format=${Formats.CSV}&id=:id&begin=:begin&end=:end&filters=:filters`
        }),
        label: "monitoringPoiSim.simActivities.list.button.exportDetailedCSV"
      }
    ];

    const formatsGrouped = [
      {
        url: generateUrl({
          url: `/reporting/sim/activities?format=${Formats.CSV}&begin=:begin&end=:end&filters=:filters`
        }),
        label: "monitoringPoiSim.simActivities.list.button.exportCSV"
      }
    ];

    const exportSimActivities = () => {
      if (selectedSimIds.length === 1) {
        return (
          <ExportAction
            onExport={this._openPopinSingle}
            msgKey={"dataTable.download"}
          />
        );
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle
              color={color}
              titleKey="monitoringPoiSim.mainTitle"
              actionKey="monitoringPoiSim.simActivities.mainTitle"
            />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                count={simActivitiesCount}
                loadingKey="monitoringPoiSim.simActivities.loading"
                loading={loading}
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>
          <div className={styles.edition}>
            <div className={styles["export-action"]}>
              <button
                className="btn btn-ingenico openExport"
                onClick={this._openPopinGrouped}
              >
                <I18nSpan
                  msgKey={"monitoringPoiSim.simActivities.list.button.export"}
                />
              </button>
            </div>
            <div>
              <Link to={`/main/reporting/sim-connections`}>
                <button className="btn btn-ingenico mrn">
                  <span className="glyphicon glyphicon-phone" />
                  <I18nSpan
                    msgKey={
                      "monitoringPoiSim.simActivities.list.button.connections"
                    }
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>

        <ExportPopin
          searchContext={searchContext}
          filters={filters}
          showPopin={showPopinGrouped}
          onClosePopin={this._closePopinGrouped}
          urls={formatsGrouped}
          enableExport={hasActivities}
          hideDataTypeSelect={true}
          title={"monitoringPoiSim.simActivities.export.title"}
        />
        <ExportPopin
          searchContext={searchContext}
          filters={filters}
          showPopin={showPopinSingle}
          hideDataTypeSelect={true}
          onClosePopin={this._closePopinSingle}
          urls={formatsSingle}
          optId={id}
          enableExport={hasActivities}
          title={"monitoringPoiSim.simActivities.export.title"}
        />

        <div className="advanced-search-wrapper">
          <SimAdvancedSearch
            searchContext={searchContext}
            onChange={this._search}
          />
        </div>

        <DataTable
          data={activities}
          attributes={attributes}
          notSortableFields={["merchant.selectedTags"]}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          i18nKey="monitoringPoiSim.simActivities.list"
          idAttribute="sim.id"
          color={color}
          disableStatusValues={["Suspended"]}
          onRefresh={this._search}
          hideSearchBox={true}
          formatter={formatter}
          toggleable={true}
          onUpdateColumns={this._updateColumns}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={simActivitiesCount}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          version="new"
          searchContext={searchContext}
          hasActions={false}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedSimIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[exportSimActivities]}
        />

        <style>
          {`
                        .btn-ingenico,.btn-ingenico:focus {
                            color: ${color};
                            border-color: ${color};
                        }
                        .btn-ingenico:hover {
                            color: #FFF;
                            background-color: ${color};
                        }

                    `}
        </style>
      </div>
    );
  }
}

interface GetColumnsParams {
  type: string;
  fields: Array<string>;
}

const simActivitiesWithStatus = ({ getMessage, state }) => {
  const {
    simsActivities: { data, scrollId, count, loading },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const simActivities = data.map(simActivitie => {
    const { status } = simActivitie;
    const normalizedsimActivitie = fields.reduce((prevField, field) => {
      return {
        ...prevField,
        [field]: get(simActivitie, field)
      };
    }, {});

    return {
      ...normalizedsimActivitie,
      statusi18n: getMessage(`dataTable.state.${status}`)
    };
  });

  return {
    count,
    scrollId,
    simActivities,
    loading
  };
};

const _getColumns = ({ type, fields }: GetColumnsParams) => {
  return fields.reduce((prevField: Array<string>, field: any) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    genericFields: { data: fields },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount }
    },
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext },
    simsActivities: { selection }
  } = state;
  const { t } = ownProps;

  const {
    count: simActivitiesCount,
    scrollId: simActivitiesScrollId,
    simActivities: simActivitiesList,
    loading
  } = simActivitiesWithStatus({
    getMessage: t,
    state
  });

  const activities = simActivitiesList;

  const formatDate = val => DateFormatter.formatAndLocalizeDateTime(val, user);

  const columnSetting = user ? user.columnSetting : false;

  const visibleAttributes = getVisibleAttributes({
    columnSetting,
    fields,
    key: "simMonitoring"
  });
  const attributes = _getColumns({ fields });

  const contractFormatter = (val: any, row: any) => {
    if (row["sim.operator"] === SimProviders.SIERRA) {
      return row.offerName;
    } else {
      return val ? t(`sim.values.contract.${val}`) : "";
    }
  };

  const operatorFormatter = (val: any) => {
    return val ? t(`sim.values.sim.operator.${val}`) : val;
  };
  const selectedSimIds = _.keys(_.pick(selection, _.identity));

  return {
    simActivitiesScrollId,
    loading,
    color,
    tableCount,
    activities,
    simActivitiesCount,
    sortDescription,
    hasActivities: activities.length > 0,
    formatter: {
      importDate: formatDate,
      activationDate: formatDate,
      lastResumeDate: formatDate,
      cancellationDate: formatDate,
      firstConnection: formatDate,
      lastConnection: formatDate,
      "sim.contract": contractFormatter,
      "sim.operator": operatorFormatter,
      "merchant.selectedTags": selectedTags => _.values(selectedTags).join(", ")
    },
    visibleAttributes,
    attributes,
    searchContext,
    filters,
    selectedSimIds
  };
};

const getVisibleAttributes = ({ columnSetting = {}, fields, key }) => {
  const { [key]: visibleAttributes } = columnSetting;

  return columnSetting && visibleAttributes && visibleAttributes.length > 0
    ? visibleAttributes
    : _getColumns({ fields });
};

const mapDispatchToProps = dispatch => ({
  resetSimsActivities: () => dispatch(resetSimsActivities()),
  getSimsActivities: ({ filters, fields, sort, tableCount }) =>
    dispatch(getSimsActivities({ filters, fields, sort, tableCount })),
  getSimsActivitiesScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: advancedSearchKey, scrollId })),
  getFields: ({ name }) => dispatch(getFields({ name })),

  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    ),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "SIMS_ACTIVITIES" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "SIMS_ACTIVITIES" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "SIMS_ACTIVITIES" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "SIMS_ACTIVITIES" }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SimActivitiesView);
