import * as moment from "moment";
import _ from "lodash";

import F from "../../commons/HTTPFetcher";
import {
  PENDING_GET_PREDEFINED_ALERTS_ACTION,
  SUCCESS_GET_PREDEFINED_ALERTS_ACTION,
  FAILURE_GET_PREDEFINED_ALERTS_ACTION,
  PENDING_DELETE_PREDEFINED_ALERT_ACTION,
  FAILURE_DELETE_PREDEFINED_ALERT_ACTION,
  SUCCESS_DELETE_PREDEFINED_ALERT_ACTION
} from "../actionsTypes";

import { PayThenRefundAlert } from "../../alerts/predefined/model/PayThenRefundAlert";
import { MultipleUsageAlert } from "../../alerts/predefined/model/MultipleUsageAlert";
import { RefundOverThresholdAlert } from "../../alerts/predefined/model/RefundOverThresholdAlert";
import { RefundOutsidePolicyAlert } from "../../alerts/predefined/model/RefundOutsidePolicyAlert";
import { RefundTooHighAlert } from "../../alerts/predefined/model/RefundTooHighAlert";
import {
  ClosingHours,
  TransactionOnClosingHoursAlert
} from "../../alerts/predefined/model/TransactionOnClosingHoursAlert";
import { CompleteRefundOutsidePolicyAlert } from "../../alerts/predefined/model/CompleteRefundOutsidePolicyAlert";

export const getPredefinedAlerts = () => async dispatch => {
  dispatch(getPredefinedAlertsStart());

  const parseAlert = function(alert) {
    switch (alert.type) {
      case PayThenRefundAlert.TYPE:
        return new PayThenRefundAlert(
          alert.id,
          moment.duration(alert.duration)
        );
      case MultipleUsageAlert.TYPE:
        return new MultipleUsageAlert(
          alert.id,
          moment.duration(alert.duration),
          alert.nbUsages
        );
      case RefundOverThresholdAlert.TYPE:
        return new RefundOverThresholdAlert(
          alert.id,
          moment.duration(alert.duration),
          alert.valueThreshold,
          alert.valueThresholdCurrency,
          alert.valueThresholdExponent
        );
      case RefundOutsidePolicyAlert.TYPE:
        return new RefundOutsidePolicyAlert(
          alert.id,
          moment.duration(alert.duration)
        );
      case RefundTooHighAlert.TYPE:
        return new RefundTooHighAlert(
          alert.id,
          alert.valueThreshold,
          alert.valueThresholdCurrency,
          alert.valueThresholdExponent
        );
      case TransactionOnClosingHoursAlert.TYPE:
        const closingHours = new ClosingHours(
          alert.closingHours.fromTime,
          alert.closingHours.toTime,
          alert.closingHours.closingDays
        );
        return new TransactionOnClosingHoursAlert(alert.id, closingHours);
      case CompleteRefundOutsidePolicyAlert.TYPE:
        return new CompleteRefundOutsidePolicyAlert(
          alert.id,
          moment.duration(alert.duration)
        );
      default:
        throw new Error("Unknown alert type " + alert);
    }
  };

  F.get("/fraud-alerts").then(
    alerts => {
      const predefinedAlerts = _.map(alerts, parseAlert);
      return dispatch(getPredefinedAlertsSucceeded({ predefinedAlerts }));
    },
    errors => {
      dispatch(getPredefinedAlertsFailure({ errors }));

      throw errors;
    }
  );
};

export const getPredefinedAlertsStart = () => ({
  type: PENDING_GET_PREDEFINED_ALERTS_ACTION
});

export const getPredefinedAlertsSucceeded = ({ predefinedAlerts }) => ({
  type: SUCCESS_GET_PREDEFINED_ALERTS_ACTION,
  payload: {
    predefinedAlerts
  }
});

export const getPredefinedAlertsFailure = errors => ({
  type: FAILURE_GET_PREDEFINED_ALERTS_ACTION,
  payload: {
    errors
  }
});

export const deletePredefinedAlert = ({ id }) => async dispatch => {
  dispatch(deletePredefinedAlertStart());

  try {
    await F.delete(`/fraud-alerts/${id}`);
    return dispatch(deletePredefinedAlertSucceeded({ id }));
  } catch (errors) {
    dispatch(deletePredefinedAlertFailure({ errors }));
    throw errors;
  }
};

export const deletePredefinedAlertStart = () => ({
  type: PENDING_DELETE_PREDEFINED_ALERT_ACTION
});

export const deletePredefinedAlertSucceeded = ({ id }) => ({
  type: SUCCESS_DELETE_PREDEFINED_ALERT_ACTION,
  payload: {
    id
  }
});

export const deletePredefinedAlertFailure = errors => ({
  type: FAILURE_DELETE_PREDEFINED_ALERT_ACTION,
  errors
});
