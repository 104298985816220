import React, { Component } from "react";
import I18nSpan from "../i18n/components/I18nSpan";
import { capitalize } from "../commons/lang/StringUtils";

interface Props {
  objectName: any;
  onClickButton: Function;
  onClosePopin: Function;
  userLevel: any;
}

class CreationPopin extends Component<Props, {}> {
  render() {
    const { onClosePopin, objectName, onClickButton, userLevel } = this.props;
    const capitalizedUserLevel = capitalize(userLevel);

    return (
      <div>
        <div className="popin">
          <div className="popinContent">
            <a onClick={onClosePopin} className="closeButton" />
            <div className="popinTitle">
              <I18nSpan msgKey="popin.creation" />
            </div>

            <I18nSpan
              msgKey={`popin.message.create${capitalizedUserLevel}`}
              args={[objectName]}
            />

            <div className="button-list">
              <li>
                <a onClick={onClickButton} className="btn btn-ingenico">
                  <I18nSpan msgKey="popin.button.create" />
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreationPopin;
