import React from "react";
import classNames from "classnames";

import I18nSpan from "../../../i18n/components/I18nSpan";

import styles from "../../styles/styles.css";

export const PreviewCardholderTicketAction = ({ onOpenCardholderTicket }) => {
  return (
    <a
      onClick={onOpenCardholderTicket}
      className={classNames(
        "btn",
        "multiple-action",
        styles["multiple-action"]
      )}
    >
      <span
        className={classNames(
          "glyphicon",
          "glyphicon-credit-card",
          styles.glyphicon
        )}
        id="cardholderTicketPreview"
      />
      <I18nSpan msgKey="dataTable.cardholderReceipt" />
    </a>
  );
};
