import F from "../../commons/HTTPFetcher";

import {
  FAILURE_CREATE_POI_ACTION,
  PENDING_CREATE_POI_ACTION,
  SUCCESS_CREATE_POI_ACTION,
  RESET_POI_STATE_ACTION,
  PENDING_GET_POI_ACTION,
  SUCCESS_GET_POI_ACTION,
  FAILURE_GET_POI_ACTION,
  FAILURE_UPDATE_POI_ACTION,
  PENDING_UPDATE_POI_ACTION,
  SUCCESS_UPDATE_POI_ACTION,
  FAILURE_SWAP_POI_ACTION,
  PENDING_SWAP_POI_ACTION,
  SUCCESS_SWAP_POI_ACTION,
  PENDING_LINK_POI_ACTION,
  SUCCESS_LINK_POI_ACTION,
  FAILURE_LINK_POI_ACTION,
  PENDING_UNLINK_POI_ACTION,
  SUCCESS_UNLINK_POI_ACTION,
  FAILURE_UNLINK_POI_ACTION
} from "../actionsTypes";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import _ from "lodash";

export const createPoi = ({ poi }) => dispatch => {
  dispatch(createPoiStart());

  return F.post(
    "/poi",
    {
      body: poi,
      contentType: ContentTypes.JSON
    },
    "v1"
  ).then(
    poiCreated => {
      dispatch(createPoiSucceeded());
      return poiCreated;
    },
    errors => {
      dispatch(createPoiFailure(errors));

      throw errors;
    }
  );
};

const createPoiStart = () => ({
  type: PENDING_CREATE_POI_ACTION
});

const createPoiSucceeded = () => ({
  type: SUCCESS_CREATE_POI_ACTION
});

const createPoiFailure = errors => ({
  type: FAILURE_CREATE_POI_ACTION,
  payload: {
    errors
  }
});

export const swapPoi = ({ deprecatedPoiId, swappingPoi }) => async dispatch => {
  dispatch(swapPoiStart());

  return F.post(`/poi/${deprecatedPoiId}/swap`, {
    body: swappingPoi,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(swapPoiSucceeded()),
    errors => {
      dispatch(swapPoiFailure(errors));

      throw errors;
    }
  );
};

const swapPoiStart = () => ({
  type: PENDING_SWAP_POI_ACTION
});

const swapPoiSucceeded = () => ({
  type: SUCCESS_SWAP_POI_ACTION
});

const swapPoiFailure = errors => ({
  type: FAILURE_SWAP_POI_ACTION,
  payload: {
    errors
  }
});

export const getPoi = ({ id }) => async dispatch => {
  dispatch(getPoiStart());

  try {
    const poi = await F.get(`/poi/${id}`);

    return dispatch(getPoiSucceeded(poi));
  } catch (errors) {
    dispatch(getPoiFailure({ errors }));

    throw errors;
  }
};

const getPoiStart = () => ({
  type: PENDING_GET_POI_ACTION
});

const getPoiSucceeded = poi => ({
  type: SUCCESS_GET_POI_ACTION,
  payload: {
    poi
  }
});

const getPoiFailure = errors => ({
  type: FAILURE_GET_POI_ACTION,
  payload: {
    errors
  }
});

export const editPoi = ({ id, poi }) => async dispatch => {
  dispatch(editPoiStart());

  return F.put(`/poi/${id}`, {
    body: poi,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(editPoiSucceeded()),
    errors => {
      dispatch(editPoiFailure({ errors }));

      throw errors;
    }
  );
};

const editPoiStart = () => ({
  type: PENDING_UPDATE_POI_ACTION
});

const editPoiSucceeded = () => ({
  type: SUCCESS_UPDATE_POI_ACTION
});

const editPoiFailure = errors => ({
  type: FAILURE_UPDATE_POI_ACTION,
  payload: {
    errors
  }
});

export const link = ({ poiId, posId }) => async dispatch => {
  dispatch(linkPoiStart());

  return F.post(`/poi/${poiId}/link/${posId}`).then(
    () => dispatch(linkPoiSucceeded()),
    errors => {
      dispatch(linkPoiFailure({ errors }));

      throw errors;
    }
  );
};

const linkPoiStart = () => ({
  type: PENDING_LINK_POI_ACTION
});

const linkPoiSucceeded = () => ({
  type: SUCCESS_LINK_POI_ACTION
});

const linkPoiFailure = errors => ({
  type: FAILURE_LINK_POI_ACTION,
  payload: {
    errors
  }
});

export const unlink = ({ poiId }) => async dispatch => {
  dispatch(unlinkPoiStart());

  return F.post(`/poi/${poiId}/unlink`).then(
    () => dispatch(unlinkPoiSucceeded()),
    errors => {
      dispatch(unlinkPoiFailure({ errors }));

      throw errors;
    }
  );
};

const unlinkPoiStart = () => ({
  type: PENDING_UNLINK_POI_ACTION
});

const unlinkPoiSucceeded = () => ({
  type: SUCCESS_UNLINK_POI_ACTION
});

const unlinkPoiFailure = errors => ({
  type: FAILURE_UNLINK_POI_ACTION,
  payload: {
    errors
  }
});

export const resetPoi = () => ({
  type: RESET_POI_STATE_ACTION
});
