import {
  PENDING_GET_MENU_ACTION,
  SUCCESS_GET_MENU_ACTION,
  FAILURE_GET_MENU_ACTION,
  RESET_MENU_ACTION,
  COLLAPSE_MENU_ACTION,
  UN_COLLAPSE_MENU_ACTION
} from "../actionsTypes";

export const getMenu = () => async dispatch => {
  dispatch(getMenuStart());

  const { default: F } = await import("../../commons/HTTPFetcher");

  const url = `/menu`;

  return F.get(url)
    .then(data => {
      dispatch(getMenuSucceeded({ data }));
      return data;
    })
    .catch(errors => dispatch(getMenuFailure({ errors })));
};

const getMenuStart = () => ({
  type: PENDING_GET_MENU_ACTION
});

const getMenuSucceeded = ({ data }) => ({
  type: SUCCESS_GET_MENU_ACTION,
  payload: {
    data
  }
});

const getMenuFailure = errors => ({
  type: FAILURE_GET_MENU_ACTION,
  payload: {
    errors
  }
});

export const resetMenu = () => ({
  type: RESET_MENU_ACTION
});

export const collapseMenu = ({ collapsed }) => {
  const type = collapsed ? COLLAPSE_MENU_ACTION : UN_COLLAPSE_MENU_ACTION;

  return {
    type,
    payload: {
      collapsed
    }
  };
};
