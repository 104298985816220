import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import MatchMobileContainer from "../../gridCharts/MatchMobileContainer";

import AuthenticateVerifyMobileView from "./AuthenticateVerifyMobileView";
import AuthenticateVerifyDesktopView from "./AuthenticateVerifyDesktopView";

import {
  setAuthRedirectUriAndState,
  addNotificationError
} from "../../redux/actions";

interface Props {
  history: any;
  location: any;
  redirectUri: string;
  oauthState: string;
  hasError: boolean;
  hasExpired: boolean;
  setAuthRedirectUriAndState: Function;
  addNotificationError: Function;
}

interface State {
  otp: string;
}

class AuthenticateVerifyView extends Component<Props, State> {
  state = {
    otp: ""
  } as State;

  componentDidMount() {
    const {
      setAuthRedirectUriAndState,
      redirectUri,
      oauthState,
      hasError,
      hasExpired,
      addNotificationError
    } = this.props;

    if (redirectUri) {
      if (hasError) {
        addNotificationError("notification.error");
      } else if (hasExpired) {
        addNotificationError("login.form.2fa.verificationCode.expired");
      } else {
        setAuthRedirectUriAndState({ redirectUri, state: oauthState });
      }
    }
  }

  _onInputChange = e => {
    const { name, value } = e.target;

    return this.setState({
      [name]: value
    } as State);
  };

  _onCaptchaChange = event => {
    event.preventDefault();

    this.formElement.submit();
  };

  render() {
    const { authenticationUrl } = this.props;

    return (
      <form
        method="POST"
        action={authenticationUrl}
        onSubmit={this._onCaptchaChange}
        ref={el => (this.formElement = el)}
      >
        <MatchMobileContainer
          renderMobile={() => (
            <AuthenticateVerifyMobileView
              onInputChange={this._onInputChange}
              {...this.state}
            />
          )}
          renderDesktop={() => (
            <AuthenticateVerifyDesktopView
              onInputChange={this._onInputChange}
              {...this.state}
            />
          )}
        />
      </form>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      oauth: { clientId, redirectUri, oauthState }
    },
    config: {
      data: { authenticateEndpoint2FA }
    }
  } = state;
  const {
    location: { search }
  } = ownProps;

  const authenticationStateParam = oauthState
    ? `&state=${encodeURIComponent(oauthState)}`
    : "";
  const authenticationSearchParams =
    search ||
    `?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${authenticationStateParam}`;
  const authenticationUrl = `${authenticateEndpoint2FA}${authenticationSearchParams}`;
  const searchParams = new URLSearchParams(search);

  return {
    authenticationUrl,
    ...(search && { redirectUri: searchParams.get("redirect_uri") }),
    ...(search && { oauthState: searchParams.get("state") || "" }),
    ...(search && { hasError: searchParams.get("error") || false }),
    ...(search && { hasExpired: searchParams.get("expired") || false })
  };
};

const mapDispatchToProps = dispatch => ({
  setAuthRedirectUriAndState: ({ redirectUri, state }) =>
    dispatch(setAuthRedirectUriAndState({ redirectUri, state })),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AuthenticateVerifyView);
