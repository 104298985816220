import TransactionNumber from "./widgets/TransactionNumber";
import Cashback from "./widgets/Cashback";
import NetRevenue from "./widgets/NetRevenue";
import AverageBasket from "./widgets/AverageBasket";
import TransactionRevenueChart from "./widgets/TransactionRevenueChart";
import TerminalConnections from "./widgets/TerminalConnections";
import MerchantCount from "./widgets/MerchantCount";
import TerminalCount from "./widgets/TerminalCount";
import CustomerAverageRevenue from "./widgets/CustomerAverageRevenue";
import TerminalsOverQuotaCount from "./widgets/TerminalsOverQuotaCount";
import MaintainerTerminalsWithoutTelecollectCount from "./widgets/MaintainerTerminalsWithoutTelecollectCount";
import MaintainerActiveTerminalsCount from "./widgets/MaintainerActiveTerminalsCount";
import ActiveSimsCount from "./widgets/ActiveSimsCount";
import ComparisonAverageBasket from "./widgets/ComparisonAverageBasket";
import ComparisonCountChart from "./widgets/ComparisonCountChart";
import ComparisonAmountChart from "./widgets/ComparisonAmountChart";
import PaymentMethodsAmount from "./widgets/PaymentMethodsAmount";
import CardAverageBasket from "./widgets/CardAverageBasket";
import PaymentMethodsCount from "./widgets/PaymentMethodsCount";
import CardsCount from "./widgets/CardsCount";
import WalletsAmount from "./widgets/WalletsAmount";
import WalletsCount from "./widgets/WalletsCount";
import CardsAmount from "./widgets/CardsAmount";
import WalletAverageBasket from "./widgets/WalletAverageBasket";
import CustomersCountPieChart from "./widgets/CustomersCountPieChart";
import CustomersTransactionCountPieChart from "./widgets/CustomersTransactionCountPieChart";
import CustomersAverageVisitsBadge from "./widgets/CustomersAverageVisitsBadge";
import CustomersAverageBasketBarChart from "./widgets/CustomersAverageBasketBarChart";
import TopCustomersByAmountList from "./widgets/TopCustomersByAmountList";
import TopCustomersByCountList from "./widgets/TopCustomersByCountList";
import { WidgetType } from "../models/WidgetType";
import React, { Component } from "react";
import TerminalVolumesBanner from "./widgets/TerminalVolumesBanner";
import SimVolumesBanner from "./widgets/SimVolumesBanner";
import GrossRevenue from "./widgets/GrossRevenue";
import NetRevenuePerTerminal from "./widgets/NetRevenuePerTerminal";

class WidgetContent extends Component<any, any> {
  render() {
    const {
      id,
      type,
      color,
      config,
      data,
      formatter,
      hide,
      enableTrend
    } = this.props;

    switch (type) {
      case WidgetType.GrossRevenue: {
        return (
          <GrossRevenue
            enableTrend={enableTrend}
            id={id}
            title={"widget.type.grossRevenue"}
            color={color}
            config={config}
            formatter={formatter}
          />
        );
      }
      case WidgetType.NetRevenuePerTerminal: {
        return (
          <NetRevenuePerTerminal
            enableTrend={enableTrend}
            data={data}
            id={id}
            title={"widget.type.netRevenuePerTerminal"}
            color={color}
            config={config}
            formatter={formatter}
          />
        );
      }
      case WidgetType.TransactionNumber: {
        return (
          <TransactionNumber
            enableTrend={enableTrend}
            data={data}
            id={id}
            title={"widget.type.transactionNumber"}
            color={color}
            config={config}
            formatter={formatter}
          />
        );
      }
      case WidgetType.Cashback: {
        return (
          <Cashback
            enableTrend={enableTrend}
            id={id}
            title={"widget.type.cashback"}
            color={color}
            config={config}
            formatter={formatter}
            hide={hide}
          />
        );
      }
      case WidgetType.Revenue: {
        return (
          <NetRevenue
            enableTrend={enableTrend}
            id={id}
            title={"widget.type.revenue"}
            color={color}
            config={config}
            formatter={formatter}
          />
        );
      }
      case WidgetType.AverageBasket: {
        return (
          <AverageBasket
            enableTrend={enableTrend}
            formatter={formatter}
            id={id}
            title={"widget.type.averageBasket"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.TransactionRevenueChart: {
        return (
          <TransactionRevenueChart
            id={id}
            title={"reporting.wallet.sales.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.TerminalConnections: {
        return (
          <TerminalConnections
            id={id}
            title={"reporting.wallet.revenues.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CustomerMerchantCount: {
        return (
          <MerchantCount
            id={id}
            title={"widget.type.customerMerchantCount"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CustomerPoiCount: {
        return (
          <TerminalCount
            id={id}
            title={"widget.type.customerPoiCount"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CustomerAverageRevenue: {
        return (
          <CustomerAverageRevenue
            id={id}
            title={"widget.type.customerAverageRevenue"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.TerminalsOverQuota: {
        return (
          <TerminalsOverQuotaCount
            id={id}
            title={"widget.type.terminalsOverQuota"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.MaintainerTerminalsWithoutTelecollectCount: {
        return (
          <MaintainerTerminalsWithoutTelecollectCount
            id={id}
            title={"widget.type.maintainerTerminalsWithoutTelecollectCount"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.MaintainerActiveTerminalsCount: {
        return (
          <MaintainerActiveTerminalsCount
            id={id}
            title={"widget.type.maintainerActiveTerminalsCount"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.ActiveSimsCount: {
        return (
          <ActiveSimsCount
            id={id}
            title={"widget.type.activeSimsCount"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.ComparisonAverageBasket: {
        return (
          <ComparisonAverageBasket
            id={id}
            title={"reporting.tendertype.revenues.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.ComparisonCount: {
        return (
          <ComparisonCountChart
            id={id}
            title={"reporting.comparison.count"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.ComparisonAmount: {
        return (
          <ComparisonAmountChart
            id={id}
            title={"reporting.comparison.amount"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.PaymentMethodsAmount: {
        return (
          <PaymentMethodsAmount
            id={id}
            title={"reporting.tendertype.revenues.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CardAverageBasket: {
        return (
          <CardAverageBasket
            id={id}
            title={"reporting.card.averagebasket.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.PaymentMethodsCount: {
        return (
          <PaymentMethodsCount
            id={id}
            title={"reporting.tendertype.sales.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CardsCount: {
        return (
          <CardsCount
            id={id}
            title={"reporting.card.sales.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.WalletsAmount: {
        return (
          <WalletsAmount
            id={id}
            title={"reporting.wallet.revenues.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.WalletsCount: {
        return (
          <WalletsCount
            id={id}
            title={"reporting.wallet.sales.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CardsAmount: {
        return (
          <CardsAmount
            id={id}
            title={"reporting.card.revenues.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.WalletAverageBasket: {
        return (
          <WalletAverageBasket
            id={id}
            title={"reporting.wallet.sales.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CustomersCountPieChart: {
        return (
          <CustomersCountPieChart
            id={id}
            title={"reporting.customers.countpiechart.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CustomersTransactionCountPieChart: {
        return (
          <CustomersTransactionCountPieChart
            id={id}
            title={"reporting.customers.transactioncountpiechart.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CustomersAverageVisitsBadge: {
        return (
          <CustomersAverageVisitsBadge id={id} color={color} config={config} />
        );
      }
      case WidgetType.CustomersAverageBasketBarChart: {
        return (
          <CustomersAverageBasketBarChart
            id={id}
            title={"reporting.customers.averagebasketbarchart.title"}
            color={color}
            config={config}
          />
        );
      }
      case WidgetType.CustomersTopByAmount: {
        return (
          <TopCustomersByAmountList id={id} color={color} config={config} />
        );
      }
      case WidgetType.CustomersTopByCount: {
        return (
          <TopCustomersByCountList id={id} color={color} config={config} />
        );
      }
      case WidgetType.TerminalVolumesPer: {
        return <TerminalVolumesBanner id={id} color={color} config={config} />;
      }
      case WidgetType.SimVolumesPer: {
        return <SimVolumesBanner id={id} color={color} config={config} />;
      }
      default: {
        return null;
      }
    }
  }
}

export default WidgetContent;
