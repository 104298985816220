export default {
  NO_PRIVATE_PROPERTIES: "NO_PRIVATE_PROPERTIES",
  NO_EMPTY_PROPERTIES: "NO_EMPTY_PROPERTIES",
  ECR_EVENTS: [
    "CAISSE_SESSION_OPEN",
    "CAISSE_SESSION_CLOSE",
    "CAISSE_SESSION_CLOSE_FROM_PORTAL",
    "SALE",
    "SEND_RECEIPT"
  ],
  INSTALLATION_EVENTS: ["INSTALLATION", "UPGRADE"],
  CLOSE_PERIOD_EVENTS: ["CLOSE_PERIOD_SUCCESS", "CLOSE_PERIOD_FAIL"],
  USER_EVENTS: ["LOGIN", "LOGOUT", "TURNING_ONLINE", "TURNING_OFFLINE"],
  ALERT_EVENTS: ["ECR_SESSION_ALERT"]
};
