import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import * as moment from "moment";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import SessionClosePopin from "./SessionClosePopin";
import { Refresh } from "../../datatable/components/Refresh";
import Counter from "../../datatable/components/Counter";

import {
  getSessions,
  deleteSession,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

import styles from "../styles/styles.css";

const ATTRIBUTES = [
  "sessionId",
  "userId",
  "ecrId",
  "appInstallId",
  "createdDate",
  "name"
];

interface Props {
  loading: boolean;
  deleteSession: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  getSessions: Function;
  user: any;
  merchantId: String;
}

interface State {
  showDeleteConfirmation: boolean;
  sessionId: any;
}

class SessionsListView extends Component<Props, State> {
  state = {
    showDeleteConfirmation: false,
    sessionId: ""
  };

  componentDidMount() {
    const { getSessions, merchantId } = this.props;
    getSessions(merchantId);
  }

  _refresh = () => {
    const { getSessions, merchantId } = this.props;
    getSessions(merchantId);
  };

  _goToDeleteSession = async ({ reason, reasonMessage }) => {
    const { sessionId } = this.state;
    const {
      deleteSession,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    try {
      await deleteSession({ sessionId, reason, reasonMessage });
      this._refresh();
      addNotificationSuccess("taxAudit.sessions.delete.success");
      this._closeDeletePopin();
    } catch (promiseError) {
      addNotificationError("taxAudit.sessions.delete.error");
      this._closeDeletePopin();
    }
  };

  _showDeletePopin = (_, row) => () => {
    this.setState({
      showDeleteConfirmation: true,
      sessionId: row["sessionId"]
    });
  };

  _closeDeletePopin = () => {
    this.setState({
      showDeleteConfirmation: false,
      sessionId: ""
    });
  };

  _isRowEditable = () => {
    return false;
  };

  _formatter = {
    createdDate: date => moment(date).format("YY/MM/DD HH:mm:ss")
  };

  render() {
    const { showDeleteConfirmation } = this.state;
    const { color, sessions = [], loading } = this.props;
    const count = sessions.length;

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle
              color={color}
              titleKey="taxAudit.title"
              actionKey="taxAudit.sessions.title"
            />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={count}
                loadingKey="taxAudit.sessions.loading"
              />

              <Refresh onRefresh={this._refresh} />
            </div>
          </div>
        </div>

        {showDeleteConfirmation && (
          <SessionClosePopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={this._goToDeleteSession}
          />
        )}

        <DataTable
          data={sessions}
          hideSearchBox={true}
          attributes={ATTRIBUTES}
          formatter={this._formatter}
          key="sessionId"
          i18nKey="taxAudit.sessions"
          idAttribute="sessionId"
          color={color}
          onDeleteButtonClick={this._showDeletePopin}
          isRowEditable={this._isRowEditable}
          notSortableFields={ATTRIBUTES}
        />
      </div>
    );
  }
}

const mapSessions = sessions => {
  return _.map(sessions, session => {
    const { clientSpec, ...rest } = session;
    return {
      ...rest,
      appInstallId: clientSpec.appInstallId
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    sessions: { data: prevSessions, loading },
    theme: {
      color: {
        data: { color }
      }
    },
    auth: { user, isMerchant }
  } = state;

  const {
    match: {
      params: { merchantId: merchantIdParam }
    }
  } = ownProps;

  const sessions = mapSessions(prevSessions);

  let merchantId = isMerchant ? user.scope.level.id : merchantIdParam;

  return {
    loading,
    sessions,
    color,
    merchantId
  };
};

const mapDispatchToProps = dispatch => ({
  getSessions: merchantId => dispatch(getSessions(merchantId)),
  deleteSession: ({ sessionId, reason, reasonMessage }) =>
    dispatch(deleteSession({ sessionId, reason, reasonMessage })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SessionsListView);
