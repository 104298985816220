import React, { Component } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

class Checkbox extends Component {
  _onChange = e => {
    const { onChange, name, checked } = this.props;

    return onChange(name, !checked);
  };

  render() {
    const {
      disabledControlLabel,
      name,
      checked,
      className,
      id,
      value,
      required,
      disabled
    } = this.props;

    return (
      <div>
        <label
          key={name}
          className={disabledControlLabel ? "" : "control-label"}
        >
          <input
            type="checkbox"
            className={className ? className : ""}
            name={name}
            id={id}
            checked={checked}
            onChange={this._onChange}
            value={value}
            required={required || false}
            disabled={disabled}
          />
          {value}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  disabledControlLabel: PropTypes.bool
};

export default Checkbox;
