import {
  RESET_TREND_STATE_ACTION,
  RESET_WIDGETS_TREND_STATE_ACTION
} from "../actionsTypes";

const initialState = {
  widgets: {},
  transactions: {
    data: {},
    loading: false,
    error: false,
    errors: {}
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case `PENDING_GET_TREND_ACTION_${action.name}`: {
      const {
        payload: { widgetId }
      } = action;

      return {
        ...state,
        widgets: {
          ...state.widgets,
          [widgetId]: {
            data: {},
            loading: true,
            error: false,
            errors: {}
          }
        }
      };
    }
    case `SUCCESS_GET_TREND_ACTION_${action.name}`: {
      const {
        payload: { widgetId, trend }
      } = action;

      return {
        ...state,
        widgets: {
          ...state.widgets,
          [widgetId]: {
            data: { trend },
            loading: false,
            error: false
          }
        }
      };
    }
    case `FAILURE_GET_TREND_ACTION_${action.name}`: {
      const {
        payload: { widgetId, errors }
      } = action;

      return {
        ...state,
        widgets: {
          ...state.widgets,
          [widgetId]: {
            data: {},
            loading: false,
            error: true,
            errors
          }
        }
      };
    }
    case RESET_TREND_STATE_ACTION: {
      const {
        payload: { widgetId }
      } = action;

      return {
        ...state,
        widgets: {
          ...state.widgets,
          [widgetId]: {
            data: {},
            loading: false,
            error: false,
            errors: {}
          }
        }
      };
    }
    case RESET_WIDGETS_TREND_STATE_ACTION: {
      return {
        ...state,
        widgets: initialState.widgets
      };
    }
    default:
      return state;
  }
};
