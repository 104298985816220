import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import { compose } from "redux";

import { RadiusCreationFields, Sim } from "../constants/SimFieldConstants";
import SimValidation from "../validation/SimValidation";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import Utils from "../Utils";
import { simErrorNotification } from "../validation/SimValidation";

import {
  createSim,
  loadProviders,
  loadContracts,
  getCustomers,
  resetSim,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  createSim: Function;
  loadProviders: Function;
  loadContracts: Function;
  getCustomers: Function;
  resetSim: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  customers: any;
  providers: any;
  contracts: any;
  history: any;
}

interface State {
  form: any;
  errors: any;
}

export class CreateSimView extends Component<Props, State> {
  state = {
    form: {
      [Sim.PROVIDER]: null,
      [Sim.SSN]: null,
      [Sim.CUSTOMER_ID]: null,
      [Sim.IMSI]: null,
      [Sim.GGSN1]: null,
      [Sim.GGSN2]: null,
      [Sim.MSISDN]: null,
      [Sim.CONTRACT]: null
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentDidMount() {
    const { getCustomers, loadProviders, loadContracts } = this.props;

    // Get data
    getCustomers();
    loadProviders({ creatable: true });
    loadContracts();
  }

  componentWillReceiveProps(newProps: any) {
    const { form, errors } = newProps;

    return this.setState({
      errors
    });
  }

  componentWillUnmount() {
    const { resetSim } = this.props;

    resetSim();
  }

  _saveBtnHandler = e => {
    e.preventDefault();
    const {
      createSim,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    this._checkErrors(() => {
      const hasErrors = _.any(RadiusCreationFields, fieldName => {
        const error = this.state.errors[fieldName];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const { form } = this.state;
        createSim({ sim: form })
          .then(() => {
            addNotificationSuccess("sim.create.success");

            this._goToList();
          })
          .catch(error => {
            simErrorNotification({ error, addNotificationError, form });
          });
      }
    });
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this._checkError(name));
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(undefined);
    });
    return Promise.all([requiredPromise]);
  };

  _waitForValidation = (names: Array<string>, newErrors, then) => {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  };

  _checkError = name => {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  };

  _checkErrors = then => {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  };

  _goToList = e => {
    if (e) {
      e.preventDefault();
    }
    const { history } = this.props;
    history.push("/main/sim");
  };

  render() {
    const { customers, providers, contracts } = this.props;
    const { form, errors } = this.state;

    return (
      <div>
        <FormTitle titleKey="sim.title" actionKey="sim.create.action" />
        <form className="ingenico-form form-horizontal sim-form">
          <BootstrapInput
            validation={SimValidation.required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.PROVIDER)}
            name={Sim.PROVIDER}
            required={true}
            descriptor={{
              type: "select",
              label: "sim.form.provider",
              options: providers,
              readOnly: false
            }}
            formValue={form.operator}
          />
          <BootstrapInput
            validation={SimValidation.requiredAndUniqueSSN}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.SSN)}
            name={Sim.SSN}
            required={true}
            errors={errors.SSN}
            descriptor={{
              type: "text",
              label: "sim.form.SSN",
              readOnly: false
            }}
            formValue={form.SSN}
          />
          <BootstrapInput
            validation={SimValidation.noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.CUSTOMER_ID)}
            name={Sim.CUSTOMER_ID}
            errors={errors.customerId}
            required={false}
            descriptor={{
              type: "singleautocomplete",
              label: "sim.form.customerId",
              options: customers,
              readOnly: false
            }}
            formValue={form.customerId}
          />
          <BootstrapInput
            validation={SimValidation.requiredAndUniqueIMSI}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.IMSI)}
            name={Sim.IMSI}
            required={true}
            errors={errors.IMSI}
            descriptor={{
              type: "text",
              label: "sim.form.IMSI",
              readOnly: false
            }}
            formValue={form.IMSI}
          />
          <BootstrapInput
            validation={SimValidation.ipValidator}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.GGSN1)}
            name={Sim.GGSN1}
            errors={errors.GGSN1}
            descriptor={{
              type: "ip",
              label: "sim.form.GGSN1",
              readOnly: false
            }}
            formValue={form.GGSN1}
            required={false}
          />
          <BootstrapInput
            validation={SimValidation.ipValidator}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.GGSN2)}
            name={Sim.GGSN2}
            errors={errors.GGSN2}
            descriptor={{
              type: "ip",
              label: "sim.form.GGSN2",
              readOnly: false
            }}
            formValue={form.GGSN2}
            required={false}
          />
          <BootstrapInput
            validation={SimValidation.noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.MSISDN)}
            name={Sim.MSISDN}
            errors={errors.MSISDN}
            descriptor={{
              type: "text",
              label: "sim.form.MSISDN",
              readOnly: false
            }}
            formValue={form.MSISDN}
          />
          <BootstrapInput
            validation={SimValidation.noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.CONTRACT)}
            name={Sim.CONTRACT}
            descriptor={{
              type: "select",
              label: "sim.form.contract",
              options: contracts,
              readOnly: false
            }}
            formValue={form.contract}
            required={false}
          />
          <div className="pull-right">
            <button
              onClick={this._saveBtnHandler}
              name="save"
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    customers: { data: customers },
    sim: { providers, contracts }
  } = state;

  const customersList = Utils.mapCustomers({ customers });
  const providersList = providers
    ? Utils.makeDataZipObject({
        list: providers,
        key: "sim.provider"
      })
    : [];
  const contractsList = contracts
    ? Utils.makeDataZipObject({
        list: contracts,
        key: "sim.values.contract"
      })
    : [];

  return {
    errors: {},
    customers: customersList,
    providers: providersList,
    contracts: contractsList
  };
};

const mapDispatchToProps = dispatch => ({
  createSim: ({ sim }) => dispatch(createSim({ sim })),
  resetSim: () => dispatch(resetSim()),
  getCustomers: () => dispatch(getCustomers()),
  loadProviders: ({ creatable }) => dispatch(loadProviders({ creatable })),
  loadContracts: () => dispatch(loadContracts()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CreateSimView);
