export default class AlertAdapter {
  threshold: AlertThreshold;

  constructor(
    public id,
    public name,
    criteria,
    value,
    timeWindow,
    public active,
    public filters,
    operator,
    triggerScope,
    public sendWith
  ) {
    this.id = id;
    this.name = name;
    this.active = active;
    this.threshold = { criteria, operator, value, timeWindow, triggerScope };
    this.filters = filters;
    this.sendWith = sendWith;
  }

  toAlertForm() {
    let triggerScope;
    if (this.threshold.triggerScope === "onAnyMerchant") {
      triggerScope = "onAnyOf";
    } else if (this.threshold.triggerScope === "onAnyStore") {
      triggerScope = "onAnyOf";
    } else {
      triggerScope = "onAll";
    }

    return {
      id: this.id,
      name: this.name,
      active: this.active,
      threshold: {
        criteria: this.threshold.criteria,
        operator: this.threshold.operator ? this.threshold.operator : "=",
        value: this.threshold.value.toString(),
        timeWindow: this.threshold.timeWindow,
        triggerScope
      },
      filters: this.filters,
      sendWith: this.sendWith
    };
  }

  static wrapFromServer(alert): AlertAdapter {
    return new AlertAdapter(
      alert.id,
      alert.name,
      alert.threshold.criteria,
      alert.threshold.value,
      alert.threshold.timeWindow,
      !!alert.active,
      alert.filters,
      alert.threshold.operator,
      alert.threshold.triggerScope,
      alert.sendWith
    );
  }

  static buildFromForm(alertForm, isCustomer): AlertAdapter {
    let triggerScope;
    if (alertForm.triggerScope === "onAnyOf") {
      if (isCustomer) {
        triggerScope = "onAnyMerchant";
      } else {
        triggerScope = "onAnyStore";
      }
    } else {
      triggerScope = "onAll";
    }

    return new AlertAdapter(
      alertForm.id,
      alertForm.name,
      alertForm.criteria,
      parseFloat(alertForm.value),
      alertForm.timeWindow,
      !!alertForm.active,
      alertForm.filters,
      alertForm.operator,
      triggerScope,
      alertForm.sendWith
    );
  }
}

export interface Alert {
  id: string;
  name: string;
  threshold: AlertThreshold;
  active: boolean;
  filters: any;
  sendWith: any;
}

export interface AlertThreshold {
  criteria: any;
  value: any;
  timeWindow: any;
  operator: any;
  triggerScope: any;
}
