import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import MatchMobileContainer from "../../gridCharts/MatchMobileContainer";

import ResetPasswordMobileView from "./ResetPasswordMobileView";
import ResetPasswordDesktopView from "./ResetPasswordDesktopView";

import {
  addNotificationError,
  addNotificationSuccess,
  resetPassword
} from "../../redux/actions";

interface Props {
  addNotificationSuccess: Function;
  addNotificationError: Function;
  resetPassword: Function;
  history: any;
  token: any;
}

interface State {
  password: string;
  repeatPassword: string;
  showPassword: boolean;
}

class ResetPasswordView extends Component<Props, State> {
  state = {
    password: "",
    repeatPassword: "",
    showPassword: false
  };

  _onInputChange = e => {
    const target = e.target;

    return this.setState({
      [target.name]: target.value
    });
  };

  _resetPassword = e => {
    e.preventDefault();

    const {
      addNotificationSuccess,
      addNotificationError,
      resetPassword
    } = this.props;

    const {
      password: newPassword,
      repeatPassword: newRepeatPassword
    } = this.state;
    const { history, token } = this.props;

    const password = newPassword.trim();
    const repeatPassword = newRepeatPassword.trim();

    if (password !== repeatPassword) {
      return addNotificationError("resetPassword.form.validation");
    }
    const data = { token, password };
    resetPassword({ data })
      .then(() => {
        addNotificationSuccess("resetPassword.form.success");
        history.push("/authenticate");
      })
      .catch(error => {
        addNotificationError("resetPassword.form.error");

        throw error;
      });
  };

  _onShowPassword = () => {
    const { showPassword } = this.state;

    return this.setState(prevState => {
      return {
        ...prevState,
        showPassword: !showPassword
      };
    }) as any;
  };

  render() {
    return (
      <form noValidate onSubmit={this._resetPassword}>
        <MatchMobileContainer
          renderMobile={() => (
            <ResetPasswordMobileView
              onInputChange={this._onInputChange}
              onShowPassword={this._onShowPassword}
              {...this.state}
            />
          )}
          renderDesktop={() => (
            <ResetPasswordDesktopView
              onInputChange={this._onInputChange}
              onShowPassword={this._onShowPassword}
              {...this.state}
            />
          )}
        />
      </form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { token }
    }
  } = ownProps;

  return {
    token
  };
};

const mapDispatchToProps = dispatch => ({
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  resetPassword: ({ data }) => dispatch(resetPassword({ data }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPasswordView);
