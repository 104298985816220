// @secure

export default [
  "merchantName",
  "storeName",
  "posName",
  "acquirerIdentifier",
  "acquirerMerchantId",
  "acquirerPoiId",
  "attendantId",
  "terminalTransactionTime",
  "mshTransactionTime",
  "transactionType",
  "selectedService",
  "currencyCodeAlpha3",
  "transactionAmount",
  "supplementaryAmount",
  "amountAuthorised",
  "amountOther",
  "cashbackAmount",
  "dccCurrencyCodeAlpha3",
  "dccTransactionAmount",
  "dccSupplementaryAmount",
  "dccAmountAuthorised",
  "dccAmountOther",
  "technologySelected",
  "applicationIdentifier",
  "applicationLabel",
  "cardType",
  "maskedPan",
  "iin",
  "authorisationCode",
  "cvmResult",
  "transactionResult",
  "nokReason",
  "transactionSequenceCounter",
  "terminalVerificationResults",
  "merchantCategoryCode",
  "extras.trans_name",
  "extras.alipay_trans_id",
  "extras.biz_product",
  "extras.identity_code_type",
  "extras.alipay_seller_id",
  "extras.buyer_identity_code",
  "extras.alipay_buyer_user_id",
  "extras.partner_trans_id",
  "extras.alipay_pay_time",
  "extras.auth_id_response",
  "extras.ext_trans_name",
  "extras.ext_alipay_trans_id",
  "extras.ext_alipay_seller_id",
  "extras.ext_alipay_buyer_user_id",
  "extras.ext_alipay_buyer_login_id",
  "extras.ext_partner_trans_id",
  "extras.ext_alipay_pay_time",
  "extras.ext_error",
  "extras.message",
  "extras.invoice_num",
  "extras.msf",
  "extras.msf_rate",
  "extras.netAmount",
  "extras.requestType"
];
