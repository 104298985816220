import React, { Component } from "react";
import _ from "lodash";

import ObjectField from "../../../components/ObjectField";

interface Props {
  fields: any;
}

interface State {}

class TransactionDetailView extends Component<Props, State> {
  _getInnerValue = (transaction, fullFieldPath) => {
    //Used to access extras (cannot be accessed through transaction["a.b.c"])
    return fullFieldPath.split(".").reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, transaction);
  };

  render() {
    const { fields, transaction, hideEmpty } = this.props;

    return (
      <div>
        {fields.map(field => {
          return (
            <ObjectField
              key={field.i18nKey}
              i18nKey={field.i18nKey}
              defaultName={field.name}
              value={this._getInnerValue(transaction, field.name)}
              hideEmpty={hideEmpty}
            />
          );
        })}
      </div>
    );
  }
}

export default TransactionDetailView;
