import F from "../../commons/HTTPFetcher";

import { constantCase } from "constant-case";

export const getTemplates = ({ templateType }) => dispatch => {
  dispatch(getTemplatesStart({ templateType }));

  const url = `/customer/template`;
  return F.get(url)
    .then(templates =>
      dispatch(getTemplatesSucceeded({ templates, templateType }))
    )
    .catch(errors => {
      dispatch(getTemplatesFailure({ templateType, errors }));
      throw errors;
    });
};

const getTemplatesStart = ({ templateType }) => {
  const name = constantCase(templateType);
  return {
    name,
    type: `PENDING_GET_${name}_TEMPLATES_ACTION`
  };
};

const getTemplatesSucceeded = ({ templateType, templates }) => {
  const name = constantCase(templateType);
  return {
    name,
    type: `SUCCESS_GET_${name}_TEMPLATES_ACTION`,
    payload: {
      templates
    }
  };
};

const getTemplatesFailure = ({ templateType, errors }) => {
  const name = constantCase(templateType);
  return {
    type: `FAILURE_GET_${name}_TEMPLATES_ACTION`,
    payload: {
      errors
    }
  };
};

export const resetTemplatesState = ({ templateType }) => {
  const name = constantCase(templateType);
  return {
    type: `RESET_${name}_TEMPLATES_STATE_ACTION`
  };
};
