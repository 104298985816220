import _ from "lodash";
import i18next from "i18next";

import {
  SearchField,
  SearchFilter,
  SearchFilterType
} from "../models/SearchFilter";
import { TransactionFieldService } from "./TransactionFieldService";
import { capitalize } from "../../commons/lang/StringUtils";
import { Filter, FilterWithTag, SearchParams } from "../models/SearchParams";
import NumberFormatter from "../../formatters/NumberFormatter";
import ManufacturerUtils from "../../manufacturer/Utils";
import { SimProviders } from "../../sim/constants/SimFieldConstants";
import { store } from "../../redux/store";

const OPERATOR_INDEX = 1;
const VALUE_INDEX = 2;

/**
 * @secure
 */
export default class AdvancedSearchService {
  filtersTree = {};

  static buildDataTree({
    fields,
    i18nKey
  }: {
    fields: Array<SearchField>;
    i18nKey: string;
  }) {
    const instance = new AdvancedSearchService(fields, i18nKey);
    instance.buildDescendantTree();

    return instance.generateLists();
  }

  private constructor(
    private fields: Array<SearchField>,
    private i18nKey: String
  ) {
    this.fields = fields;
    this.i18nKey = i18nKey;
  }

  private buildDescendantTree() {
    return this.fields.forEach(field => {
      const operatorPropName = AdvancedSearchService.operatorProp(field.name);
      this.filtersTree[field.name] = {
        descendantList: operatorPropName,
        lists: {}
      };

      this.filtersTree[field.name].lists[operatorPropName] = {
        options: AdvancedSearchService.buildOptions(field.name, field.type)
      };

      this.buildCriteria(field.name, field.type);
    });
  }

  buildCriteria(fieldName, fieldType) {
    AdvancedSearchService.descendantListArray(fieldName).forEach(
      itemDescendant => {
        if (itemDescendant[fieldType]) {
          if (fieldType === "array") {
            this.filtersTree[fieldName].lists[
              itemDescendant[fieldType].list
            ] = {
              options: [],
              descendantList: fieldName
            };
            this.filtersTree[fieldName].lists[itemDescendant[fieldType].one] = {
              options: [],
              inputType: "text"
            };
          } else if (fieldType === "amount") {
            this.filtersTree[fieldName].lists[itemDescendant[fieldType]] = {
              options: [],
              inputType: "number"
            };
          } else if (fieldType === "string") {
            this.filtersTree[fieldName].lists[itemDescendant[fieldType]] = {
              options: [],
              inputType: "text"
            };
          }
        }
      }
    );
  }

  private static buildOptions(fieldName, fieldType) {
    if (fieldType === "array") {
      return [
        {
          label: i18next.t("advancedSearch.operator.in"),
          value: "in",
          descendantList: fieldName
        },
        {
          label: i18next.t("advancedSearch.operator.notEqual"),
          value: "notEqual",
          descendantList: fieldName
        },
        {
          label: i18next.t("advancedSearch.operator.contains"),
          value: "contains",
          descendantList: "stringValue"
        },
        {
          label: "=",
          value: "=",
          descendantList: AdvancedSearchService.singleValueProp(fieldName)
        },
        {
          label: i18next.t("advancedSearch.operator.exists"),
          value: "exists"
        },
        {
          label: i18next.t("advancedSearch.operator.notExists"),
          value: "notExists"
        }
      ];
    } else if (fieldType === "amount") {
      return [
        {
          label: "<",
          value: "<",
          descendantList: "amountValue"
        },
        {
          label: ">",
          value: ">",
          descendantList: "amountValue"
        },
        {
          label: "=",
          value: "=",
          descendantList: "amountValue"
        },
        {
          label: ">=",
          value: ">=",
          descendantList: "amountValue"
        },
        {
          label: "<=",
          value: "<=",
          descendantList: "amountValue"
        }
      ];
    } else if (fieldType === "string" || fieldType === "date") {
      return [
        {
          label: "=",
          value: "=",
          descendantList: "stringValue"
        },
        {
          label: i18next.t("advancedSearch.operator.notEqual"),
          value: "notEqual",
          descendantList: "stringValue"
        },
        {
          label: i18next.t("advancedSearch.operator.contains"),
          value: "contains",
          descendantList: "stringValue"
        },
        {
          label: i18next.t("advancedSearch.operator.exists"),
          value: "exists"
        },
        {
          label: i18next.t("advancedSearch.operator.notExists"),
          value: "notExists"
        }
      ];
    }
  }

  private static descendantListArray(fieldName) {
    return [
      { string: "stringValue" },
      { amount: "amountValue" },
      {
        array: {
          list: fieldName,
          one: AdvancedSearchService.singleValueProp(fieldName)
        }
      }
    ];
  }

  private generateLists() {
    const lists = {
      beginBy: "properties",
      properties: {
        options: this.fields.map(field => {
          return TransactionFieldService.setOptionLabel(field);
        })
      }
    };

    lists.properties.options.forEach(option => {
      const { name: fieldName, type } = option;
      const descendantLists = this.filtersTree[fieldName];

      option.descendantList = descendantLists.descendantList;

      if (type === "array") {
        const list = option.values || [];
        const oneItemListName = AdvancedSearchService.singleValueProp(
          fieldName
        );
        const itemToOption = (descendantList, fieldName) => item => {
          const { id, name } = item;

          return {
            value: id,
            label: i18next.t(
              `${this.i18nKey}.${fieldName}.${name}`.toString(),
              item.name
            ),
            descendantList
          };
        };
        const emptyDescendantListsName = "";
        descendantLists.lists[fieldName].options = list.map(
          itemToOption(fieldName, fieldName)
        );
        descendantLists.lists[oneItemListName].options = list.map(
          itemToOption(emptyDescendantListsName, fieldName)
        );
      }

      Object.assign(lists, descendantLists.lists);
    });

    return lists;
  }

  static operatorProp(prop) {
    return `${prop}Operators`;
  }

  static singleValueProp(prop) {
    return `one${capitalize(prop)}`;
  }

  static buildSearchParams(
    key: string,
    advancedSearchItems,
    formatters?
  ): SearchParams {
    const filters = AdvancedSearchService.convertItemsToFilters(
      advancedSearchItems,
      formatters
    );
    return { key, filters, advancedSearchItems };
  }

  static convertItemsToFilters(
    items: Array<SearchFilter>,
    formatter?
  ): Array<FilterWithTag> {
    return items.map((item: SearchFilter) => {
      if (_.isString(item)) {
        // fulltext filter
        return {
          filterType: "fulltext",
          name: "",
          operator: "=",
          value: item,
          tag: false
        } as FilterWithTag;
      } else if (_.isArray(item) && _.isObject(item[0])) {
        const propertyItem: any = item[0];
        const operatorItem: any = item[1];
        let filter: FilterWithTag = {
          filterType: propertyItem.type as SearchFilterType,
          name: propertyItem.name as String,
          operator: operatorItem.value as String,
          value: null,
          tag: false
        };

        if (propertyItem.type === "amount") {
          const valueItem = item[VALUE_INDEX];
          filter.value =
            formatter && formatter.formatNumber
              ? formatter.formatNumber(valueItem)
              : valueItem;
          filter.tag = false;
        } else if (propertyItem.type === "array") {
          if (item[OPERATOR_INDEX].descendantList === "stringValue") {
            filter.filterType = "string";
            filter.value =
              (formatter && formatter.formatString
                ? formatter.formatString(item[VALUE_INDEX])
                : item[VALUE_INDEX]) || "";
          } else {
            const valueItems = item.slice(VALUE_INDEX) as Array<any>;
            filter.value =
              (formatter && formatter.formatArray
                ? formatter.formatArray(valueItems)
                : valueItems.map(valueItem => valueItem.value)) || [];
          }
          filter.tag = propertyItem.tag;
        } else if (
          propertyItem.type === "string" ||
          propertyItem.type === "date"
        ) {
          filter.value =
            (formatter && formatter.formatString
              ? formatter.formatString(item[VALUE_INDEX])
              : item[VALUE_INDEX]) || "";
          filter.tag = propertyItem.tag;
        } else {
          throw new Error("Unrecognized type");
        }
        return filter;
      }
    });
  }

  static getSearchParamsFromFilters({
    i18nKey,
    fields,
    filters
  }: {
    i18nKey: string;
    fields: Array<SearchField>;
    filters: Array<Filter>;
  }): SearchParams {
    const lists = AdvancedSearchService.buildDataTree({
      fields,
      i18nKey
    });

    return AdvancedSearchService.fromFilter({
      key: i18nKey,
      filters,
      tree: lists
    });
  }

  // Translate "Domain Filters" to "Advanced Search Filters"
  private static fromFilter({
    key,
    filters,
    tree
  }: {
    key: string;
    filters: Array<Filter>;
    tree: any;
  }): SearchParams {
    const items = _.map(filters, (f: Filter) => {
      const { filterType, name, operator, value } = f;

      // Fulltext
      if (
        (filterType === "string" || filterType === "fulltext") &&
        _.isEmpty(name)
      ) {
        return value;
      } else {
        const nameObject = _.find(
          tree[tree.beginBy].options,
          (o: any) => o.name === name
        );
        if (!nameObject) return;
        const operatorObject = _.find(
          tree[nameObject.descendantList].options,
          (o: any) => o.value === operator
        );

        const countries = store.getState().countries.data;

        const findCountry = (item: string) => {
          const country = countries.find(
            (country: any) => country.code === item
          );
          return country.display;
        };

        const contractFormatter = (val: any) => {
          return val || val === SimProviders.SIERRA
            ? i18next.t(`sim.values.contract.${val}`)
            : "";
        };

        const operatorFormatter = (val: any) => {
          return val ? i18next.t(`sim.values.sim.operator.${val}`) : val;
        };

        const transactionFormatter = ({ field, val }) => {
          return i18next.t(`reporting.transaction.values.${field}.${val}`, val);
        };

        const computeRawField = ({ field, item }) => {
          switch (field) {
            case "sim.operator": {
              const rawValue = operatorFormatter(item);

              return { rawValue };
            }
            case "sim.contract": {
              const rawValue = contractFormatter(item);

              return { rawValue };
            }
            case "poi.manufacturerId": {
              const rawValue = ManufacturerUtils.manufacturerNameFromCode(item);

              return { rawValue };
            }
            case "store.country": {
              const rawValue = findCountry(item);

              return { rawValue };
            }
            case "selectedService":
            case "transactionType":
            case "transactionResult":
            case "technologySelected":
            case "status":
            case "nokReason":
            case "cvmResult":
            case "cardType": {
              const rawValue = transactionFormatter({ field, val: item });

              return { rawValue };
            }
            default:
              return {};
          }
        };

        const values =
          operator === "exists" || operator === "notExists"
            ? []
            : filterType === "array"
            ? _.map(value, (v: any) => {
                return {
                  value: v,
                  label: v,
                  ...computeRawField({ field: name, item: v }),
                  descendantList: name
                };
              })
            : [value.toString()];

        return [nameObject, operatorObject].concat(values);
      }
    });

    return {
      key,
      filters,
      // used to remove undefined values
      advancedSearchItems: items.filter(_.identity)
    };
  }

  static getCreatedItemsFromSearchContext({
    name,
    i18nKey,
    genericFields,
    searchContext
  }: {
    name: string;
    i18nKey: string;
    genericFields: any;
  }): Array<SearchFilter> {
    const {
      filtersByAdvancedSearchKey: { [name]: filters = [] }
    } = searchContext;

    let createdItems: Array<SearchFilter> = [];

    if (!_.isEmpty(filters)) {
      const fields = genericFields.reduce((prevField, field) => {
        const { key, type, searchable } = field;
        const tagsKey = /\b(selectedTags).([^.]+)/g.exec(key);

        if (searchable)
          prevField.push({
            i18nKey:
              name === "terminalActivities"
                ? `monitoringPoiSim.${name}.list.header.${key}`
                : i18nKey
                ? i18nKey(key)
                : `${name}.list.header.${key}`, // TODO after refacto sim page
            rawLabel: tagsKey ? tagsKey[2] : null,
            name: key,
            type
          });

        return prevField;
      }, []);
      const searchParams = AdvancedSearchService.getSearchParamsFromFilters({
        i18nKey,
        fields,
        filters
      });
      createdItems = AdvancedSearchService.formatAmountFilters(
        searchParams.advancedSearchItems
      );
    }

    return createdItems;
  }

  private static formatAmountFilters(
    filters: Array<SearchFilter>
  ): Array<SearchFilter> {
    const settings = store.getState().auth.levelSettings;
    const { currency = {} } = settings;
    const { symbol = "?", exponent = 0, alpha3 = "" } = currency;

    const userCurrency = {
      currency: symbol,
      currencyDecimal: exponent,
      currencyCodeAlpha3: alpha3
    };

    return filters.map((filter: SearchFilter) => {
      if (filter[0].type === "amount") {
        const filterAmountValue = filter[2];
        filter[2] = NumberFormatter.formatAmount(
          filterAmountValue,
          null,
          userCurrency.currencyDecimal
        );
      }

      return filter;
    });
  }
}
