import React, { Component } from "react";
import { connect } from "react-redux";

import KeyIndicator from "./KeyIndicator";
import { WidgetConfig } from "../Widget";
import { getData } from "../../utils/DataProvider";

interface Data {
  count: number;
}

interface Props {
  getData: Function;
  data: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  id: string;
  config: WidgetConfig;
}

class TerminalCount extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ config });
    }
  }

  render() {
    const {
      data: { count = 0 },
      title,
      color,
      loading,
      error
    } = this.props;

    return (
      <KeyIndicator
        color={color}
        titleKey={title}
        loading={loading}
        error={error}
      >
        <span>{count}</span>
      </KeyIndicator>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }
  return {
    data: {},
    loading: true
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminalCount);
