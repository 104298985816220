import { EventSourcePolyfill } from "event-source-polyfill";
import F from "./HTTPFetcher";

export const eventSourceListener = ({
  type,
  action,
  evtSourceContainer,
  callbackWithParams = false,
  eventGatewayURL = ""
}) => {
  const Authorization = `Bearer ${F.jwt}`;
  evtSourceContainer.evtSource = new EventSourcePolyfill(
    `${eventGatewayURL}/v2/event`,
    {
      headers: {
        Authorization
      }
    }
  );

  evtSourceContainer.evtSource.onmessage = e => {
    const data = JSON.parse(e.data);
    const { dataType } = data;

    if (type === dataType) {
      return callbackWithParams ? action(data) : action();
    }
  };
};
