import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";

import DataTable from "../../datatable/components/DataTable";
import OfferUtils from "../../offer/util/OfferUtils";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import I18nSpan from "../../i18n/components/I18nSpan";
import { Refresh } from "../../datatable/components/Refresh";
import Counter from "../../datatable/components/Counter";

import {
  getPrograms,
  deleteProgram,
  addNotificationSuccess,
  addNotificationError,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../redux/actions";

import {
  EditAction,
  DeleteAction
} from "../../datatable/components/multipleActions";

import { getNamesFromSelection } from "../../redux/reducers/selection";

import styles from "../styles/styles.css";

interface Props {
  loading: boolean;
  deleteProgram: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  getPrograms: Function;
}
interface State {
  color: any;
  showDeleteConfirmation: boolean;
  programNames: any;
  programs: any;
}

class ProgramListView extends Component<Props, State> {
  state = {
    showDeleteConfirmation: false
  };

  async componentDidMount() {
    const { getPrograms } = this.props;

    await getPrograms();
  }

  _refresh = () => {
    const { getPrograms } = this.props;

    getPrograms();
  };

  _gotToDeleteProgram = async () => {
    const {
      deleteProgram,
      addNotificationSuccess,
      addNotificationError,
      selectedProgramIds: ids
    } = this.props;
    const programId = ids[0];

    try {
      await deleteProgram({ programId });

      addNotificationSuccess("program.delete.success");
      return this._closeDeletePopin();
    } catch (promiseError) {
      const { errorKey } = await promiseError;
      const notificationErrorKey = `notification.program.error.${errorKey}`;

      addNotificationError(notificationErrorKey);
    }
  };

  _showDeletePopin = () => {
    const { selection } = this.props;
    const programNames = getNamesFromSelection({
      selection,
      keyName: "name"
    });

    return this.setState({
      showDeleteConfirmation: true,
      programNames
    });
  };

  _closeDeletePopin = () => {
    this.setState({
      showDeleteConfirmation: false
    });
  };

  _isRowEditable = () => {
    return true;
  };

  onSelectRow = (rowId, value) => {
    const { selectedProgramIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedProgramIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const { showDeleteConfirmation, programNames } = this.state;
    const { color, programs = [], loading, selectedProgramIds } = this.props;

    const count = programs.length;

    const EditProgram = () => {
      if (selectedProgramIds.length === 1) {
        const [programId] = selectedProgramIds;

        const route = `/main/settings/program/edit-program/${programId}`;

        return <EditAction route={route} msgKey={"dataTable.edit"} />;
      }

      return null;
    };

    const DeleteProgram = () => {
      if (selectedProgramIds.length === 1) {
        return <DeleteAction onDelete={this._showDeletePopin} />;
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle
              color={color}
              titleKey="program.title"
              actionKey="program.list.action"
            />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={count}
                loadingKey="program.list.loading"
              />

              <Refresh onRefresh={this._refresh} />
            </div>
          </div>

          <div className={styles.creation}>
            <Link
              className="btn btn-ingenico create-button"
              to={"/main/settings/program/new-program"}
            >
              <span className="glyphicon glyphicon-plus" aria-hidden="true" />
              <I18nSpan msgKey="program.list.button.create" />
            </Link>
          </div>
        </div>
        {showDeleteConfirmation && (
          <ConfirmationPopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={this._gotToDeleteProgram}
            objectName={programNames}
          />
        )}

        <DataTable
          data={programs}
          attributes={["name", "services"]}
          key="program.list"
          i18nKey="program.list"
          idAttribute="programId"
          color={color}
          isRowEditable={this._isRowEditable}
          hasActions={false}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedProgramIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[EditProgram, DeleteProgram]}
        />
      </div>
    );
  }
}

const mapPrograms = programs => {
  return _.map(programs, (program: any) => {
    const { id: programId, name, services } = program;

    return {
      programId,
      name,
      services: OfferUtils.i18nServices(services).join(", ")
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    programs: { data: prevPrograms, selection, loading },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  const programs = mapPrograms(prevPrograms);
  const selectedProgramIds = _.keys(_.pick(selection, _.identity));

  return {
    loading,
    programs,
    color,
    selectedProgramIds,
    selection
  };
};

const mapDispatchToProps = dispatch => ({
  getPrograms: () => dispatch(getPrograms()),
  deleteProgram: ({ programId }) => dispatch(deleteProgram({ programId })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "PROGRAMS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "PROGRAMS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "PROGRAMS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "PROGRAMS" }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ProgramListView);
