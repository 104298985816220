import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";

import AmountApexChart from "./AmountApexChart";
import Utils from "../../../sim/Utils";

// @vulnerabilities XSS CSRF

interface Series {
  name: string;
  data: Array<any>;
}

interface Props {
  color: any;
  title: any;
  xAxisLegend?: string;
  yAxisLegend?: string;
  tooltip: any;
  categories: Array<string> | null;
  series: Array<Series>;
  currency: any;
}

class BarChart extends Component<Props, {}> {
  _buildConfigFromProps(props) {
    const { t, defaultPaletteId, palettes, palette } = this.props;
    const widgetPalette = palette ? palette : defaultPaletteId;
    const initialPalette = Utils.findPaletteById(widgetPalette, palettes);

    return {
      title: {
        text: props.title,
        align: "center"
      },
      dataLabels: {
        formatter: props.tooltip.y.formatter,
        offsetY: -20,
        style: {
          colors: ["#000"]
        }
      },
      legend: {
        show: false
      },
      noData: {
        text: t("apexCharts.noData")
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true
          }
        }
      },
      xaxis: {
        title: {
          text: props.xAxisLegend
        },
        categories: props.categories
      },
      yaxis: {
        title: {
          text: props.yAxisLegend
        },
        labels: {
          formatter: props.tooltip.y.formatter
        }
      },

      tooltip: props.tooltip,

      plotOptions: {
        bar: {
          dataLabels: {
            position: "top"
          },
          distributed: true,
          pointPadding: 0.2,
          borderWidth: 0
        }
      },

      series: props.series,
      ...(initialPalette && { colors: [...initialPalette.colors] })
    };
  }

  render() {
    const { series, ...options } = this._buildConfigFromProps(this.props);

    return <AmountApexChart options={options} series={series} type="bar" />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: { defaultPaletteId }
    },
    palettes: { data: palettes = [] }
  } = state;

  return {
    palettes,
    defaultPaletteId
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, null)
)(BarChart);
