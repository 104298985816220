import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import styles from "./MobileHeader.css";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const mapStateToProps = state => {
  const {
    auth: { user },
    theme: {
      darkMode,
      logo: { data: logoContent }
    }
  } = state;

  const name = user ? user.name || user.login : "";

  return {
    name,
    darkMode,
    logoContent
  };
};

const MobileHeader = () => {
  const { logoContent } = useSelector(state => mapStateToProps(state));

  const useStylesLoader = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: "transparent",
        position: "absolute",
        right: "5vw"
      },
      circle: {
        stroke: "#c8c8c8"
      }
    })
  );

  const classesLoader = useStylesLoader();

  const matches = useMediaQuery(`(max-width: 768px)`);

  if (matches) {
    return (
      <Grid
        className={styles["header-container"]}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {logoContent === null ? (
            <CircularProgress
              classes={{
                circle: classesLoader.circle
              }}
              size={30}
            />
          ) : (
            <img src={logoContent} className={styles["logo-content"]} />
          )}
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default withRouter(MobileHeader);
