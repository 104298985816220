import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";

import { MainContainerMobile } from "../MainContainerMobile/MainContainerMobile";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  createStyles,
  Input,
  makeStyles,
  Theme
} from "@material-ui/core";
import classNames from "classnames";
import { createPoi } from "../../../redux/actions";
import Manufacturers from "../../../pos/constants/Manufacturers";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

const CreateTerminalForm = ({ manual, afterCreate, handleError }) => {
  const { t } = useTranslation();
  const { darkMode: prefersDarkMode } = useSelector(state =>
    mapStateToProps(state)
  );

  const dispatch = useDispatch();

  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);

  const createNewPoi = ({ poi }) => dispatch(createPoi({ poi }));

  const [serialNumberField, setSerialNumber] = useState();

  const [allowFormSubmission, setAllowFormSubmission] = useState(false);

  const useStylesLoader = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: "transparent",
        position: "absolute",
        right: "5vw"
      },
      circle: {
        stroke: "#c8c8c8"
      }
    })
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: prefersDarkMode ? "#303030" : "transparent",
        position: "relative"
      },
      target: {
        position: "absolute"
      },
      spacing: {
        marginTop: "3%"
      },
      formControl: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        borderRadius: "6px",
        height: "calc(var(--vh, 1vh) * 8)",
        border: "initial",
        boxShadow: "initial",
        marginBottom: "5px"
      }
    })
  );

  const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "uppercase",
        backgroundColor: prefersDarkMode ? "#3B3B3B" : "#E5E5E5",
        borderRadius: "5px",
        height: "calc(var(--vh, 1vh) * 8)",
        width: "100%",
        fontWeight: "bold",
        fontSize: "11px",
        color: prefersDarkMode ? "#E5E5E5" : "#3B3B3B",
        textAlign: "center",
        "&:hover": {
          color: prefersDarkMode ? "#404041" : "#404041",
          backgroundColor: "#E5E5E5"
        }
      },
      contained: {}
    })
  );

  const useStylesInput = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%"
      },
      inputCode: {
        color: "#404041",
        fontWeight: 700,
        fontSize: "12px",
        textAlign: "center"
      },
      underline: {
        borderBottom: "none",
        "&:before": {
          borderBottom: "none"
        },
        "&:hover:not($disabled):before": {
          borderBottom: "none"
        },
        "&:after": {
          borderBottom: "none"
        }
      },
      "Mui-error": {
        color: "red"
      },
      focused: {},
      disabled: {}
    })
  );

  const classes = useStyles();

  const classesButton = useStylesButton();

  const classesInput = useStylesInput();

  const classesLoader = useStylesLoader();

  const handleChange = event => {
    const value = event.target.value;
    setAllowFormSubmission(!!value);
    setSerialNumber(value);
  };

  const handleSubmit = () => {
    setError(false);
    setLoading(true);
    createNewPoi({
      poi: {
        serialNumber: serialNumberField,
        manufacturerId: Manufacturers.INGENICO_CODE
      }
    })
      .then(poi => {
        setError(false);
        return afterCreate && afterCreate();
      })
      .catch(errorPromise => {
        return errorPromise.then(errorData => {
          const { key } = errorData;
          setError(key);
          handleError && handleError(key);
        });
      })
      .finally(setLoading(false));
  };

  if (manual) {
    return (
      <>
        <div className={classNames("form-control", classes.formControl)}>
          <Input
            error={error}
            id="my-input"
            placeholder={t("poi.mobile.add.code")}
            type="text"
            classes={{
              root: classesInput.inputCode,
              disabled: classesInput.disabled,
              underline: classesInput.underline,
              input: classesInput.inputCode,
              error: classesInput["Mui-error"]
            }}
            onChange={handleChange}
          />
        </div>
        <div className={classNames(classes.spacing)}>
          <Button
            classes={{
              root: classesButton.root,
              contained: classesButton.contained
            }}
            disabled={!allowFormSubmission}
            onClick={() => {
              handleSubmit();
            }}
          >
            {t("poi.mobile.ok")}
            {loading && (
              <div className={classesLoader.root}>
                <CircularProgress
                  classes={{
                    circle: classesLoader.circle
                  }}
                  size={30}
                />
              </div>
            )}
          </Button>
        </div>
      </>
    );
  }
  return null;
};

export default withRouter(CreateTerminalForm);
