import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import I18nSpan from "../../i18n/components/I18nSpan";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import {
  uploadPhoto,
  getPhoto,
  resetCatalogItemState,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";
import catalogItem from "../../redux/reducers/catalogItem";

interface Props {
  timestamp: any;
  encType: any;
  okLabel: any;
  preview: any;
  uploadPhoto: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  getPhoto: Function;
  resetCatalogItemState: Function;
  match: any;
  history: any;
}

interface Form {
  photo: string;
}

interface State {
  preview: any;
  form: Form;
}

class UploadPhotoView extends Component<Props, State> {
  state = {
    form: {
      photo: ""
    },
    preview: this.props.preview !== "" ? this.props.preview : ""
  } as State;

  componentDidMount() {
    const {
      getPhoto,
      match: {
        params: { catalogItemId, edit }
      }
    } = this.props;

    if (edit) {
      getPhoto(catalogItemId);
    }
  }

  componentWillUnmount() {
    const { resetCatalogItemState } = this.props;

    resetCatalogItemState();
  }

  componentWillReceiveProps(newProps) {
    const { preview } = newProps;

    return this.setState({
      preview
    });
  }

  _PhotoChangeHandler = e => {
    e.preventDefault();

    const target = e.target;

    this.setState({
      preview: URL.createObjectURL(target.files[0]),
      form: {
        photo: target.value
      }
    });
  };

  _submitForm = async e => {
    e.preventDefault();

    const {
      uploadPhoto,
      addNotificationSuccess,
      addNotificationError,
      match: {
        params: { catalogItemId: id }
      }
    } = this.props;

    const {
      photo: { files = [] }
    } = e.target;

    if (files.length === 0) {
      return addNotificationError("notification.logo.error.missingFile");
    }

    const data = new FormData();
    data.append("photo", files[0]);

    try {
      await uploadPhoto({ id, data });

      addNotificationSuccess("catalogItem.photo.success");

      return this._goToList();
    } catch (promiseError) {
      addNotificationError("notification.error");
    }
  };

  _goToList = () => {
    const { history } = this.props;

    history.push("/main/settings/catalog");
  };

  render() {
    const { encType, okLabel } = this.props;
    const {
      preview,
      form: { photo }
    } = this.state;

    return (
      <div>
        <FormTitle
          titleKey="catalogItem.title"
          actionKey="catalogItem.photo.action"
        />

        <form
          onSubmit={this._submitForm}
          className={"ingenico-form customer-form upload-input"}
          noValidate="novalidate"
          encType={encType}
        >
          <div className="uploadBox form-inline">
            <h4>
              <I18nSpan msgKey="catalogItem.photo.photoPreview" />
            </h4>
            {preview && (
              <div className="form-group">
                <figure className="imagePreview">
                  <img src={preview} className="logo-type" />
                </figure>
              </div>
            )}
          </div>

          <div id="logoContainer">
            <input
              type="file"
              id="photo"
              name="photo"
              className="form-control input-type-file"
              value={photo}
              placeholder=""
              onChange={this._PhotoChangeHandler}
              accept="image/*"
            />
          </div>

          <div className="pull-right form-buttons">
            <button className="btn btn-ingenico save-button">
              <I18nSpan msgKey={okLabel || "button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    catalogItem: { photo }
  } = state;

  return {
    preview: photo
  };
};

const mapDispatchToProps = dispatch => ({
  uploadPhoto: ({ id, data }) => dispatch(uploadPhoto({ id, data })),
  getPhoto: (id: string) => dispatch(getPhoto(id)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  resetCatalogItemState: () => dispatch(resetCatalogItemState())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadPhotoView)
);
