import TimePeriods, {
  PeriodSelection,
  TimePeriod
} from "../../reporting/models/TimePeriod";
import { FilterWithTag } from "../../advancedSearch/models/SearchParams";
import { WidgetType } from "./WidgetType";
import { WidgetStyle } from "./WidgetStyle";
import { WidgetGroupKey } from "./WidgetGroup";

// @secure

export interface FromType {
  type: WidgetType;
  name: string;
  order?: number;
  id?: string;
  periodSelection?: PeriodSelection;
  filters?: Array<FilterWithTag>;
  criteria?: string;
}

export default abstract class Widget {
  readonly id: string;
  readonly type: WidgetType;
  name: string;
  order: number;
  timePeriod: TimePeriod;
  filters: Array<FilterWithTag>;
  style: WidgetStyle;
  groupKey: WidgetGroupKey;
  criteria?: string | null;

  abstract getData(user?: any): Promise<any>;

  constructor(
    theType: WidgetType,
    theName: string,
    theGroupKey: WidgetGroupKey,
    order?: number,
    theId?: string,
    theTimePeriod?: TimePeriod,
    theFilters?: Array<FilterWithTag>
  ) {
    this.type = theType;
    this.name = theName;
    this.order = order;
    this.timePeriod = theTimePeriod;
    this.id = theId;
    this.filters = theFilters || [];
    this.style = WidgetStyle.All;
    this.groupKey = theGroupKey;
  }

  static fromType({
    type,
    name,
    order,
    id,
    periodSelection,
    filters,
    criteria
  }: FromType) {
    const timePeriod = TimePeriod.fromPeriodSelection(periodSelection);
    switch (type) {
      case WidgetType.TransactionNumber:
        return new TransactionNumberWidget(
          name,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.RefundAmount:
        return new RefundWidget(name, order, id, timePeriod, filters);
      case WidgetType.GrossRevenue:
        return new GrossRevenueWidget(name, order, id, timePeriod, filters);
      case WidgetType.Revenue:
        return new RevenueWidget(name, order, id, timePeriod, filters);
      case WidgetType.NetRevenuePerTerminal:
        return new NetRevenuePerTerminalWidget(
          name,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.Cashback:
        return new CashbackWidget(name, order, id, timePeriod, filters);
      case WidgetType.AverageBasket:
        return new AverageBasketWidget(name, order, id, timePeriod, filters);
      case WidgetType.TerminalConnections:
        return new TerminalConnectionWidget(name, order, id, timePeriod);
      case WidgetType.CustomerMerchantCount:
        return new CustomerMerchantCountWidget(name, order, id);
      case WidgetType.CustomerPoiCount:
        return new CustomerPoiCountWidget(name, order, id);
      case WidgetType.CustomerAverageRevenue:
        return new CustomerAverageRevenueWidget(
          name,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.MaintainerActiveTerminalsCount:
        return new MaintainerActiveTerminalsCountWidget(name, order, id);
      case WidgetType.MaintainerTerminalsWithoutTelecollectCount:
        return new MaintainerTerminalsWithoutTelecollectCountWidget(
          name,
          order,
          id
        );
      case WidgetType.TerminalsOverQuota:
        return new TerminalsOverQuotaCountWidget(name, order, id);
      case WidgetType.ActiveSimsCount:
        return new ActiveSimsCountWidget(name, order, id);
      case WidgetType.TransactionRevenueChart:
        return new TransactionRevenueChartWidget(
          name,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.ComparisonCount:
        return new ComparisonCountChartWidget(
          name,
          criteria,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.ComparisonAmount:
        return new ComparisonAmountChartWidget(
          name,
          criteria,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.ComparisonAverageBasket:
        return new ComparisonAverageBasketWidget(
          name,
          criteria,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.CardsCount:
        return new CardsCount(name, order, id, timePeriod, filters);
      case WidgetType.CardsAmount:
        return new CardsAmount(name, order, id, timePeriod, filters);
      case WidgetType.WalletsCount:
        return new WalletsCount(name, order, id, timePeriod, filters);
      case WidgetType.WalletsAmount:
        return new WalletsAmount(name, order, id, timePeriod, filters);
      case WidgetType.PaymentMethodsCount:
        return new PaymentMethodsCount(name, order, id, timePeriod, filters);
      case WidgetType.PaymentMethodsAmount:
        return new PaymentMethodsAmount(name, order, id, timePeriod, filters);
      case WidgetType.CardAverageBasket:
        return new CardAverageBasket(name, order, id, timePeriod, filters);
      case WidgetType.WalletAverageBasket:
        return new WalletAverageBasket(name, order, id, timePeriod, filters);
      case WidgetType.BusinessActivity:
        return new BusinessActivity(name, order, id, timePeriod, filters);
      case WidgetType.CustomersCountPieChart:
        return new CustomersCountPieChart(name, order, id, timePeriod, filters);
      case WidgetType.CustomersTransactionCountPieChart:
        return new CustomersTransactionCountPieChart(
          name,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.CustomersAverageVisitsBadge:
        return new CustomersAverageVisitsBadge(
          name,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.CustomersAverageBasketBarChart:
        return new CustomersAverageBasketBarChart(
          name,
          order,
          id,
          timePeriod,
          filters
        );
      case WidgetType.CustomersTopByAmount:
        return new CustomersTopByAmount(name, order, id, timePeriod, filters);
      case WidgetType.CustomersTopByCount:
        return new CustomersTopByCount(name, order, id, timePeriod, filters);
      case WidgetType.TerminalVolumesPer:
        return new TerminalVolumesPer(name, order, id, timePeriod, filters);
      case WidgetType.SimVolumesPer:
        return new SimVolumesPer(name, order, id, timePeriod, filters);
      default:
        return null;
    }
  }
}

export class SimVolumesPer extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(WidgetType.SimVolumesPer, name, WidgetGroupKey.ACTIVITY, order, id);
    this.style = WidgetStyle.All;
  }
}

export class TerminalVolumesPer extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.TerminalVolumesPer,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.All;
  }
}

export class CustomersTopByCount extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.CustomersTopByCount,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.All;
  }
}

export class CustomersTopByAmount extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.CustomersTopByAmount,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.All;
  }
}

export class CustomersAverageBasketBarChart extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.CustomersAverageBasketBarChart,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.All;
  }
}

export class CustomersAverageVisitsBadge extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.CustomersAverageVisitsBadge,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.All;
  }
}

export class CustomersCountPieChart extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.CustomersCountPieChart,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.All;
  }
}

export class CustomersTransactionCountPieChart extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.CustomersTransactionCountPieChart,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.All;
  }
}

export class TransactionNumberWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.YEAR_TO_DATE,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.TransactionNumber,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class RefundWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.YEAR_TO_DATE,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.RefundAmount,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class GrossRevenueWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.YEAR_TO_DATE,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.GrossRevenue,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class RevenueWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.YEAR_TO_DATE,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.Revenue,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class NetRevenuePerTerminalWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.YEAR_TO_DATE,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.NetRevenuePerTerminal,
      name,
      WidgetGroupKey.TOTALS,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class CashbackWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.YEAR_TO_DATE,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.Cashback,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export interface AverageBasketData {
  currency: string;
  currencyCodeAlpha3: string;
  currencyDecimal: number;
  value: number;
}

export class AverageBasketWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.YEAR_TO_DATE,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.AverageBasket,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class TerminalConnectionWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.MONTH_TO_DATE
  ) {
    super(
      WidgetType.TerminalConnections,
      name,
      WidgetGroupKey.TERMINALS_PAYMENT,
      order,
      id,
      timePeriod
    );
    this.style = WidgetStyle.OnlyTimePeriod;
  }
}

export class CustomerMerchantCountWidget extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.CustomerMerchantCount,
      name,
      WidgetGroupKey.TOTALS,
      order,
      id
    );
    this.style = WidgetStyle.None;
  }
}

export class CustomerPoiCountWidget extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(WidgetType.CustomerPoiCount, name, WidgetGroupKey.TOTALS, order, id);
    this.style = WidgetStyle.None;
  }
}

export class CustomerAverageRevenueWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.CustomerAverageRevenue,
      name,
      WidgetGroupKey.TOTALS,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class MaintainerActiveTerminalsCountWidget extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.MaintainerActiveTerminalsCount,
      name,
      WidgetGroupKey.TERMINALS_ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.None;
  }
}

export class MaintainerTerminalsWithoutTelecollectCountWidget extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.MaintainerTerminalsWithoutTelecollectCount,
      name,
      WidgetGroupKey.TERMINALS_ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.None;
  }
}

export class ActiveSimsCountWidget extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.ActiveSimsCount,
      name,
      WidgetGroupKey.SIM_ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.None;
  }
}

export class TerminalsOverQuotaCountWidget extends Widget {
  constructor(name: string, order?: number, id?: string) {
    super(
      WidgetType.TerminalsOverQuota,
      name,
      WidgetGroupKey.TERMINALS_ACTIVITY,
      order,
      id
    );
    this.style = WidgetStyle.None;
  }
}

export class TransactionRevenueChartWidget extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.TransactionRevenueChart,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id,
      timePeriod,
      filters
    );
    this.style = WidgetStyle.All;
  }
}

export class BusinessActivity extends Widget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.BusinessActivity,
      name,
      WidgetGroupKey.ACTIVITY,
      order,
      id,
      timePeriod,
      filters
    );
    this.style = WidgetStyle.All;
  }
}

export interface AggData {
  avg: number;
  count: number;
  max: number;
  min: number;
  name: string;
  sum: number;
}

export interface Aggregation {
  doc_count: number;
  "stats-on-transaction-amount": AggData;
}

export interface AggregationsByCriteria {
  aggregations: Array<Aggregation>;
  criteria: string;
  type: string;
}

class ComparisonWidget extends Widget {
  constructor(
    type: WidgetType,
    name: string,
    criteria: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(
      type,
      name,
      WidgetGroupKey.COMPARISON,
      order,
      id,
      timePeriod,
      filters
    );
    this.style = WidgetStyle.AllWithCriteria;
    this.criteria = criteria || null;
  }
}

export class ComparisonCountChartWidget extends ComparisonWidget {
  constructor(
    name: string,
    criteria: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.ComparisonCount,
      name,
      criteria,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class ComparisonAmountChartWidget extends ComparisonWidget {
  constructor(
    name: string,
    criteria: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.ComparisonAmount,
      name,
      criteria,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class ComparisonAverageBasketWidget extends ComparisonWidget {
  constructor(
    name: string,
    criteria: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.ComparisonAverageBasket,
      name,
      criteria,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export interface PaymentMethodsData {
  cards: Array<AggData>;
  currency: string;
  currencyCodeAlpha3: string;
  currencyDecimal: number;
  transactionsCount: number;
  transactionsTypes: Array<AggData>;
  wallets: Array<AggData>;
}

class PaymentMethodsWidget extends Widget {
  constructor(
    type: WidgetType,
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(
      type,
      name,
      WidgetGroupKey.PAYMENT_METHODS,
      order,
      id,
      timePeriod,
      filters
    );
    this.style = WidgetStyle.All;
  }
}

export class CardsCount extends PaymentMethodsWidget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(WidgetType.CardsCount, name, order, id, timePeriod, filters);
  }
}

export class CardsAmount extends PaymentMethodsWidget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(WidgetType.CardsAmount, name, order, id, timePeriod, filters);
  }
}

export class WalletsCount extends PaymentMethodsWidget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(WidgetType.WalletsCount, name, order, id, timePeriod, filters);
  }
}

export class WalletsAmount extends PaymentMethodsWidget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(WidgetType.WalletsAmount, name, order, id, timePeriod, filters);
  }
}

export class PaymentMethodsCount extends PaymentMethodsWidget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(WidgetType.PaymentMethodsCount, name, order, id, timePeriod, filters);
  }
}

export class PaymentMethodsAmount extends PaymentMethodsWidget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(
      WidgetType.PaymentMethodsAmount,
      name,
      order,
      id,
      timePeriod,
      filters
    );
  }
}

export class CardAverageBasket extends PaymentMethodsWidget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(WidgetType.CardAverageBasket, name, order, id, timePeriod, filters);
  }
}

export class WalletAverageBasket extends PaymentMethodsWidget {
  constructor(
    name: string,
    order?: number,
    id?: string,
    timePeriod: TimePeriod = TimePeriods.INTRADAY,
    filters?: Array<FilterWithTag>
  ) {
    super(WidgetType.WalletAverageBasket, name, order, id, timePeriod, filters);
  }
}
