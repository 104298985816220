/**
 * @secure
 */
export default {
  TRANSACTION_FILTERS_LOADED: "TRANSACTION_FILTERS",
  POS_FILTERS_LOADED: "POS_FILTERS_LOADED",
  POI_FILTERS_LOADED: "POI_FILTERS_LOADED",
  SIM_FILTERS_LOADED: "SIM_FILTERS",
  AUDIT_FILTERS_LOADED: "AUDIT_FILTERS",
  RECONCILIATION_FILTERS_LOADED: "RECONCILIATION_FILTERS",
  MONITORING_TERMINAL_FILTERS_LOADED: "MONITORING_TERMINAL_FILTERS_LOADED",
  STORE_VALUES_LOADED: "STORE_VALUES_LOADED",
  MERCHANT_VALUES_LOADED: "MERCHANT_VALUES_LOADED",
  TRANSACTION_KEY: "transaction",
  REPORT_KEY: "report",
  ALERT_KEY: "alert",
  RECONCILIATION_KEY: "reconciliation",
  WIDGET_KEY: "widget",
  AUDIT_KEY: "audit",
  SIM_KEY: "sim",
  SETTINGS_TERMINAL_KEY: "settingsTerminal",
  MONITORING_TERMINAL_KEY: "terminalActivities",
  POS_KEY: "pos",
  POI_KEY: "poi",
  STORE_KEY: "store",
  MERCHANT_KEY: "merchant",
  CATALOG_KEY: "catalog",
  CATALOG_SSE_KEY: "catalogItem",
  SIM_NEW_KEY: "sim-monitoring",
  SALE_KEY: "sale",
  POI_MONITORING: "poi-monitoring",
  HOME_KEY: "home",
  TAX_AUDIT: "taxAudit",
  TAX_AUDIT_LOG_KEY: "logs"
};
