import React from "react";
import {
  CreateCompleteRefundOutsidePolicyAlert,
  CompleteRefundOutsidePolicyAlert
} from "../../model/CompleteRefundOutsidePolicyAlert";
import I18nSpan from "../../../../i18n/components/I18nSpan";

interface Props {
  onChange: (alert: CreateCompleteRefundOutsidePolicyAlert) => void;
}

interface State {
  maxDays?: number;
  minDays?: number;
  alert?: CreateCompleteRefundOutsidePolicyAlert;
}

export default class CompleteRefundOutsidePolicy extends React.Component<
  Props,
  State
> {
  completeRefundOutsidePolicyAlert = new CreateCompleteRefundOutsidePolicyAlert();

  state = {
    maxDays: 56, //As defined in the spec
    minDays: 1,
    alert: this.completeRefundOutsidePolicyAlert
  };

  private onChange = e => {
    const completeRefundOutsidePolicyAlert: CreateCompleteRefundOutsidePolicyAlert = this
      .state.alert;
    const days =
      e.target.value === "0" || e.target.value.trim() === ""
        ? this.state.minDays
        : e.target.value;
    completeRefundOutsidePolicyAlert.setDuration(parseInt(days));
    this.setState(
      {
        alert: completeRefundOutsidePolicyAlert
      },
      () => this.props.onChange(completeRefundOutsidePolicyAlert)
    );
  };

  private onNumberInputClick = (e: Event) => {
    const input = e.target as HTMLInputElement;
    input.select();
  };

  render() {
    return (
      <div id="alert-complete-refund-outside-policy" className="alert-item">
        <I18nSpan
          msgKey={`alert.predefined.${CompleteRefundOutsidePolicyAlert.I18N_KEY}.create-1`}
        />
        <input
          type="number"
          name="nbDays"
          value={this.state.alert.duration.asDays()}
          onChange={this.onChange}
          onClick={this.onNumberInputClick}
          min={this.state.minDays}
          max={this.state.maxDays}
        />
        <I18nSpan
          msgKey={`alert.predefined.${CompleteRefundOutsidePolicyAlert.I18N_KEY}.create-2`}
        />
      </div>
    );
  }
}
