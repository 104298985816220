import React from "react";
import classNames from "classnames";

import I18nSpan from "../../../i18n/components/I18nSpan";

import styles from "../../styles/styles.css";

export const ShowDetailAction = ({ onDetail, className = "" }) => {
  return (
    <a
      onClick={onDetail}
      className={`${classNames(
        "btn",
        "multiple-action",
        styles["multiple-action"]
      )} ${className}`}
    >
      <span
        className={classNames(
          "glyphicon",
          "glyphicon-eye-open",
          styles.glyphicon
        )}
      />
      <I18nSpan msgKey="dataTable.detail" />
    </a>
  );
};
