import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import TransactionsPie from "../../../chart/components/chart/TransactionsPie";
import NumberFormatter from "../../../formatters/NumberFormatter";
import { WidgetConfig } from "../Widget";
import LoadingChart from "./LoadingChart";
import { getData } from "../../utils/DataProvider";

interface Data {
  cards?: any;
  currencyCodeAlpha3?: any;
  currency?: any;
  currencyDecimal?: any;
}

interface Props {
  data?: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  id: string;
  config?: WidgetConfig;
}

class CardsAmount extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { user, desktopView } = config;
    if (desktopView) {
      return getData({ user, config });
    }
  }

  render() {
    const {
      data,
      title,
      color,
      loading,
      config: { userCurrency = {}, defaultPaletteId },
      t
    } = this.props;

    const titleWidget = t(title);

    const format =
      "<b>{point.name}</b>: {point.percentage:.2f}% ({point.value})";

    const { cardsAmount = [] } = data;
    const { currency, currencyDecimal, currencyCodeAlpha3 } = userCurrency;

    const series = [
      {
        data: cardsAmount.map(c => ({
          name: c.key,
          y: c.sum_cards.value,
          value: NumberFormatter.formatAmount(
            c.sum_cards.value,
            currencyCodeAlpha3 + currency,
            currencyDecimal
          )
        }))
      }
    ];

    return (
      <LoadingChart loading={loading} color={color}>
        <TransactionsPie
          currency={userCurrency}
          color={color}
          title={titleWidget}
          series={series}
          dataLabelsFormat={format}
          palette={defaultPaletteId}
        />
      </LoadingChart>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }

  return {
    data: {},
    loading: true
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CardsAmount);
