import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  FAILURE_CREATE_AUTOINIT_PROGRAM_ACTION,
  PENDING_CREATE_AUTOINIT_PROGRAM_ACTION,
  SUCCESS_CREATE_AUTOINIT_PROGRAM_ACTION,
  RESET_AUTOINIT_PROGRAM_STATE_ACTION,
  PENDING_GET_AUTOINIT_PROGRAM_ACTION,
  SUCCESS_GET_AUTOINIT_PROGRAM_ACTION,
  FAILURE_GET_AUTOINIT_PROGRAM_ACTION
} from "../actionsTypes";

export const createDefaultAutoinitProgram = defaultProgram => dispatch => {
  dispatch(createDefaultAutoinitProgramStart());

  return F.post("/autoinit/programs/default", {
    body: defaultProgram,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(createDefaultAutoinitProgramSucceeded()),
    errors => {
      dispatch(createDefaultAutoinitProgramFailure({ errors }));

      throw errors;
    }
  );
};

const createDefaultAutoinitProgramStart = () => ({
  type: PENDING_CREATE_AUTOINIT_PROGRAM_ACTION
});

const createDefaultAutoinitProgramSucceeded = () => ({
  type: SUCCESS_CREATE_AUTOINIT_PROGRAM_ACTION
});

const createDefaultAutoinitProgramFailure = (errors: any) => ({
  type: FAILURE_CREATE_AUTOINIT_PROGRAM_ACTION,
  payload: {
    errors
  }
});

export const getDefaultAutoinitProgram = () => dispatch => {
  dispatch(getDefaultAutoinitProgramStart());

  return F.get("/autoinit/programs/default").then(
    defaultProgram =>
      dispatch(getDefaultAutoinitProgramSucceeded(defaultProgram)),
    errors => {
      dispatch(getDefaultAutoinitProgramFailure({ errors }));

      throw errors;
    }
  );
};

const getDefaultAutoinitProgramStart = () => ({
  type: PENDING_GET_AUTOINIT_PROGRAM_ACTION
});

const getDefaultAutoinitProgramSucceeded = defaultProgram => ({
  type: SUCCESS_GET_AUTOINIT_PROGRAM_ACTION,
  payload: {
    defaultProgram
  }
});

const getDefaultAutoinitProgramFailure = errors => ({
  type: FAILURE_GET_AUTOINIT_PROGRAM_ACTION,
  payload: {
    errors
  }
});

export const resetDefaultAutoinitProgramState = () => ({
  type: RESET_AUTOINIT_PROGRAM_STATE_ACTION
});
