import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import TransactionReceiptViewer from "../../../receipt/TransactionReceiptViewer";
import DataTable from "../../../datatable/components/DataTable";
import Utils from "../../Utils";
import DateFormatter from "../../../formatters/DateFormatter";
import NumberFormatter from "../../../formatters/NumberFormatter";
import TransactionConstants from "../../constants/TransactionConstants";
import AdvancedSearchConstants from "../../../advancedSearch/constants/AdvancedSearchConstants";
import {
  PreviewCardholderTicketAction,
  FilterByOrderIdAction,
  ShowDetailAction,
  PreviewMerchantTicketAction,
  DisplayReceipsAction
} from "../../../datatable/components/multipleActions";

import {
  getScroll,
  getTransactionDetail,
  getComment,
  getTransactionReceipt,
  closeTransactionsReceipt,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  updateUserColumnsSettings,
  deleteUserColumnsSettings
} from "../../../redux/actions";

interface Props {
  onOrderIdSelected: Function;
}

interface State {
  transactionId: any;
}

const advancedSearchKey = AdvancedSearchConstants.TRANSACTION_KEY;

class TransactionDataTableView extends Component<Props, State> {
  _onDetail = (rowId: any) => {
    const { getTransactionDetail, getComment } = this.props;

    getTransactionDetail({ id: rowId });
    getComment({ transactionId: rowId });
  };

  _openTicket = ({ receiptId, receiptType }) => {
    return () => {
      const { getTransactionReceipt } = this.props;

      getTransactionReceipt({ receiptId, receiptType });
    };
  };

  _closeTicket = receiptType => {
    const { closeTransactionsReceipt } = this.props;

    return closeTransactionsReceipt(receiptType);
  };

  searchTransactionsWithOrderId(orderId) {
    const { onOrderIdSelected } = this.props;

    return onOrderIdSelected(orderId);
  }

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);
      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = AdvancedSearchConstants.TRANSACTION_KEY;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  _hasReceipt = (selectedTransactionIds, transactionIdsWithReceipts) => {
    let transactionHaveReceipts = 0;
    selectedTransactionIds.map(selectedId => {
      if (_.includes(transactionIdsWithReceipts, selectedId)) {
        transactionHaveReceipts = transactionHaveReceipts + 1;
      }
    });

    return transactionHaveReceipts;
  };

  _getRowById = (id, list) => {
    return list.filter(item => id === item.transactionId)[0];
  };

  _hasOrderId = row => {
    return row.order && row.order.orderId;
  };

  onSelectRow = (rowId, value) => {
    const { selectedTransactionIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedTransactionIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  _loadMoreRows = () => {
    const { getTransactionsScroll, transactionScrollId } = this.props;

    return getTransactionsScroll({ scrollId: transactionScrollId });
  };

  render() {
    const {
      transactions,
      transactionsCount,
      transactionFields,
      fields,
      extraTransactionFields,
      onSelectionButtonClick,
      sortDescription,
      onRefresh,
      selectedTransactionIds,
      showMerchantPopin,
      showCardholderPopin,
      receiptHtml,
      visibleAttributes,
      formatter,
      color,
      connectionStore,
      services,
      searchContext
    } = this.props;
    const [transactionId] = selectedTransactionIds;

    const merchantReceiptIsVisible = row => {
      return Utils.merchantReceiptIsVisible(row, connectionStore, services);
    };

    const cardholderReceiptIsVisible = row => {
      return Utils.cardholderReceiptIsVisible(row, connectionStore, services);
    };

    const PreviewMerchantTicket = () => {
      const [id] = selectedTransactionIds;
      const row = this._getRowById(id, transactions);

      if (
        selectedTransactionIds.length === 1 &&
        merchantReceiptIsVisible(row)
      ) {
        return (
          <PreviewMerchantTicketAction
            onOpenTicket={this._openTicket({
              receiptId: id,
              receiptType: "merchant"
            })}
          />
        );
      }

      return null;
    };

    const PreviewCardholderTicket = () => {
      const [id] = selectedTransactionIds;
      const row = this._getRowById(id, transactions);

      if (
        selectedTransactionIds.length === 1 &&
        cardholderReceiptIsVisible(row)
      ) {
        return (
          <PreviewCardholderTicketAction
            onOpenCardholderTicket={this._openTicket({
              receiptId: id,
              receiptType: "cardholder"
            })}
          />
        );
      }

      return null;
    };

    const FilterByOrderId = () => {
      const [id] = selectedTransactionIds;
      const row = this._getRowById(id, transactions);

      if (selectedTransactionIds.length === 1 && this._hasOrderId(row)) {
        return (
          <FilterByOrderIdAction
            searchTransactionsWithOrderId={() =>
              this.searchTransactionsWithOrderId(row.order.orderId)
            }
          />
        );
      }

      return null;
    };

    const ShowDetail = () => {
      const [id] = selectedTransactionIds;

      if (selectedTransactionIds.length === 1) {
        return <ShowDetailAction onDetail={() => this._onDetail(id)} />;
      }

      return null;
    };

    const DisplayReceipts = () => {
      const { transactionIdsWithReceipts } = this.props;
      const transactionHaveReceipts =
        this._hasReceipt(selectedTransactionIds, transactionIdsWithReceipts) !==
          0 && selectedTransactionIds.length > 1;

      if (transactionHaveReceipts) {
        return (
          <DisplayReceipsAction
            onSelectionButtonClick={onSelectionButtonClick}
          />
        );
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <DataTable
          fields={fields}
          data={transactions}
          attributes={transactionFields}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          extraAttributes={extraTransactionFields}
          i18nKey="reporting.transaction.list"
          i18nKeyResolver={key =>
            `${TransactionConstants.I18N_PREFIX}.${key}.label`
          }
          idAttribute="transactionId"
          color={color}
          formatter={formatter}
          selectionButtonKey={[
            PreviewCardholderTicket,
            FilterByOrderId,
            ShowDetail,
            PreviewMerchantTicket,
            DisplayReceipts
          ]}
          selectionCondition={merchantReceiptIsVisible}
          hasActions={false}
          hideSearchBox={true}
          toggleable={true}
          onRefresh={onRefresh}
          onUpdateColumns={this._updateColumns}
          version="new"
          selectableNew={true}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedTransactionIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={transactionsCount}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />
        <TransactionReceiptViewer
          showPopin={showMerchantPopin}
          transactionId={transactionId}
          popinContent={receiptHtml}
          onClose={() => this._closeTicket("merchant")}
          receiptActionType="transaction"
          receiptType="merchant"
        />
        <TransactionReceiptViewer
          showPopin={showCardholderPopin}
          transactionId={transactionId}
          popinContent={receiptHtml}
          onClose={() => this._closeTicket("cardholder")}
          receiptActionType="transaction"
          receiptType="cardholder"
        />
      </div>
    );
  }
}

const _getColumns = ({ type, fields: genericFields }) => {
  const fields = genericFields.reduce((prevField, field) => {
    const { key, required, visible, extras } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      case "extras": {
        if (extras) prevField.push(key);

        break;
      }
      case "visible": {
        if (visible) prevField.push(key);

        break;
      }
      default: {
        break;
      }
    }

    return prevField;
  }, []);

  return fields;
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting &&
    columnSetting.transaction &&
    columnSetting.transaction.length > 0
    ? columnSetting.transaction
    : _.take(fields, 10);
};

const toFormatCurrency = (val, row) => {
  const currencySymbol = row.currencyCodeAlpha3 + row.currencySymbol;
  const currencyExponent = row.currencyExponent;

  return NumberFormatter.formatAmount(val, currencySymbol, currencyExponent);
};

const toDccFormatCurrency = (val, row) => {
  const currencySymbol = row.dccCurrencyCodeAlpha3 + row.dccCurrencySymbol;
  const currencyExponent = row.dccCurrencyExponent;

  return NumberFormatter.formatAmount(val, currencySymbol, currencyExponent);
};

const _translateEnumValue = ({ field, rawValue, getMessage }) => {
  if (rawValue) {
    const key = `reporting.transaction.values.${field}.${rawValue}`;

    return getMessage(key, rawValue);
  }

  return null;
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: connectionStore,
    genericFields: { data: fields = [] },
    transactions: {
      data: transactions,
      count: transactionsCount,
      scrollId: transactionScrollId,
      selection,
      showMerchantPopin,
      showCardholderPopin,
      receiptHtml
    },
    theme: {
      color: {
        data: { color }
      }
    },
    services: { data: services = [] },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const { transactionFields, t: getMessage } = ownProps;

  const { user } = connectionStore;

  const extraTransactionFields = _getColumns({ fields, type: "extras" });

  const selectedTransactionIds = _.keys(_.pick(selection, _.identity));
  const transactionIdsWithReceipts = transactions.reduce(
    (prevTransaction, transaction) => {
      if (
        Utils.merchantReceiptIsVisible(transaction, connectionStore, services)
      ) {
        return [...prevTransaction, transaction.transactionId];
      }

      return prevTransaction;
    },
    []
  );

  const columnSetting = user ? user.columnSetting : false;
  const visibleAttributes = getVisibleAttributes({
    columnSetting,
    fields: transactionFields
  });

  return {
    transactions,
    transactionsCount,
    transactionScrollId,
    extraTransactionFields,
    fields,
    selectedTransactionIds,
    transactionIdsWithReceipts,
    showMerchantPopin,
    showCardholderPopin,
    receiptHtml,
    sortDescription,
    visibleAttributes,
    color,
    connectionStore,
    services,
    formatter: {
      transactionAmount: toFormatCurrency,
      chargeAmount: toFormatCurrency,
      supplementaryAmount: toFormatCurrency,
      amountAuthorised: toFormatCurrency,
      amountOther: toFormatCurrency,
      dccAmountOther: toDccFormatCurrency,
      dccSupplementaryAmount: toDccFormatCurrency,
      dccAmountAuthorised: toDccFormatCurrency,
      dccTransactionAmount: toDccFormatCurrency,
      cashbackAmount: toFormatCurrency,
      terminalTransactionTime(val) {
        return DateFormatter.formatDateTime(val, user);
      },
      mshTransactionTime(val) {
        return DateFormatter.formatDateTimeUtc(val, user);
      },
      selectedService: val =>
        _translateEnumValue({
          field: "selectedService",
          rawValue: val,
          getMessage
        }),
      transactionType: val =>
        _translateEnumValue({
          field: "transactionType",
          rawValue: val,
          getMessage
        }),
      transactionResult: val =>
        _translateEnumValue({
          field: "transactionResult",
          rawValue: val,
          getMessage
        }),
      cvmResult: val =>
        _translateEnumValue({
          field: "cvmResult",
          rawValue: val,
          getMessage
        }),
      technologySelected: val =>
        _translateEnumValue({
          field: "technologySelected",
          rawValue: val,
          getMessage
        }),
      cardType: val =>
        _translateEnumValue({
          field: "cardType",
          rawValue: val,
          getMessage
        })
    },
    searchContext
  };
};

const mapDispatchToProps = dispatch => ({
  getTransactionsScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "transaction", scrollId })),
  getTransactionDetail: ({ id }) => dispatch(getTransactionDetail({ id })),
  getComment: ({ transactionId }) =>
    dispatch(getComment({ commentId: transactionId })),
  getTransactionReceipt: ({ receiptId, receiptType }) =>
    dispatch(getTransactionReceipt({ receiptId, receiptType })),
  closeTransactionsReceipt: receiptType =>
    dispatch(closeTransactionsReceipt(receiptType)),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "TRANSACTIONS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "TRANSACTIONS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "TRANSACTIONS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "TRANSACTIONS" })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TransactionDataTableView);
