import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import {
  FAILURE_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  PENDING_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  SUCCESS_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  RESET_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  PENDING_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  FAILURE_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  SUCCESS_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  RESET_DELETE_USER_COLUMNS_SETTINGS_ACTION
} from "../actionsTypes";

export const updateUserColumnsSettings = ({
  columnSetting,
  category
}) => async dispatch => {
  dispatch(updateUserColumnsSettingsStart());

  try {
    const url = new URL(`/me/columns/${category}`, PORTAL_URL);

    await F.put(`${url.pathname}${url.search}`, {
      body: columnSetting,
      contentType: ContentTypes.JSON
    });

    return dispatch(updateUserColumnsSettingsSucceeded({ columnSetting }));
  } catch (errors) {
    dispatch(updateUserColumnsSettingsFailure({ errors }));

    throw errors;
  }
};

const updateUserColumnsSettingsStart = () => ({
  type: PENDING_UPDATE_USER_COLUMNS_SETTINGS_ACTION
});

const updateUserColumnsSettingsSucceeded = ({ columnSetting }) => ({
  type: SUCCESS_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  payload: {
    columnSetting
  }
});

const updateUserColumnsSettingsFailure = errors => ({
  type: FAILURE_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const resetUpdateUserColumnsSettings = () => ({
  type: RESET_UPDATE_USER_COLUMNS_SETTINGS_ACTION
});

export const deleteUserColumnsSettings = ({
  columnSetting,
  category
}) => async dispatch => {
  dispatch(deleteUserColumnsSettingsStart());

  try {
    const url = new URL(`/me/columns/${category}`, PORTAL_URL);

    await F.delete(`${url.pathname}${url.search}`);

    return dispatch(deleteUserColumnsSettingsSucceeded({ columnSetting }));
  } catch (errors) {
    dispatch(deleteUserColumnsSettingsFailure({ errors }));

    throw errors;
  }
};

const deleteUserColumnsSettingsStart = () => ({
  type: PENDING_DELETE_USER_COLUMNS_SETTINGS_ACTION
});

const deleteUserColumnsSettingsSucceeded = ({ columnSetting }) => ({
  type: SUCCESS_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  payload: {
    columnSetting
  }
});

const deleteUserColumnsSettingsFailure = errors => ({
  type: FAILURE_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const resetDeleteUserColumnsSettings = () => ({
  type: RESET_DELETE_USER_COLUMNS_SETTINGS_ACTION
});
