import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import I18nSpan from "../../i18n/components/I18nSpan";

interface Props {
  urlParam: string;
  target: string;
}

interface State {}

class Tabs extends Component<Props, State> {
  state = {
    selectedIndex: 0
  };

  getActiveTab() {
    const { urlParam, child = true } = this.props;
    const {
      match: {
        params: { [urlParam]: param }
      },
      children
    } = this.props;

    const names = Object.entries(children).reduce((acc, name) => {
      const [, child] = name;
      return [...acc, child.props.name];
    }, []);
    const index = names.indexOf(param);
    const active = index === -1 ? 0 : index;

    return {
      name: names[active],
      active
    };
  }

  render() {
    const { children = {}, target } = this.props;
    const { name, active } = this.getActiveTab();
    const content = children[active];

    return (
      <div className="data-table-wrapper">
        <div className="btn-group" role="group">
          {Object.entries(children).map(entry => {
            const [index, child] = entry;
            return (
              <Link
                key={index}
                type="button"
                title={child.props.name}
                className={`btn btn-ingenico ${
                  child.props.name === name ? "active" : ""
                }`}
                to={`${target}${child.props.name}`}
              >
                <I18nSpan msgKey={child.props.title} />
              </Link>
            );
          })}
        </div>
        <div className="clearFloat" />
        {content}
      </div>
    );
  }
}

export default withRouter(Tabs);
