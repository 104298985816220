import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { RadiusActivationFields, Sim } from "../constants/SimFieldConstants";
import SimValidation from "../validation/SimValidation";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import Utils from "../Utils";
import { Provider } from "../../sim/model/Provider";

import {
  assignSim,
  getSim,
  getCustomers,
  loadContracts,
  resetSim,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  assignSim: Function;
  loadContracts: Function;
  getCustomers: Function;
  resetSim: Function;
  getSim: Function;
  history: any;
  match: any;
  customers: any;
  contracts: any;
}

interface State {
  form: any;
  errors: any;
}

export class ActivateRadiusSimView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(newProps: any) {
    const { form, errors } = newProps;

    return this.setState({
      form,
      errors
    });
  }

  componentDidMount() {
    const {
      match: {
        params: { simId: id }
      },
      getCustomers,
      getSim,
      loadContracts
    } = this.props;

    getSim({ id });

    // Get data
    getCustomers();
    loadContracts();
  }

  componentWillUnmount() {
    const { resetSim } = this.props;

    resetSim();
  }

  _saveBtnHandler = e => {
    e.preventDefault();

    const { assignSim } = this.props;

    this._checkErrors(() => {
      const hasErrors = _.any(RadiusActivationFields, fieldName => {
        const error = this.state.errors[fieldName];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const activation = {
          ssn: this.state.form.SSN,
          customerId: this.state.form.customerId,
          contract: this.state.form.contract,
          GGSN1: this.state.form.GGSN1,
          GGSN2: this.state.form.GGSN2
        };
        assignSim({ activation, provider: Provider.Radius })
          .then(this._successCallback)
          .catch(() => this._errorCallback);
      }
    });
  };

  _successCallback = () => {
    const { addNotificationSuccess } = this.props;
    addNotificationSuccess("sim.activate.success");
    this._goToList();
  };

  _errorCallback = () => {
    const { addNotificationError } = this.props;
    addNotificationError("sim.activate.error");
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this._checkError(name));
  };

  _waitForValidation = (names: Array<string>, newErrors, then) => {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [SimValidation.noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  };

  _checkError = name => {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  };

  _checkErrors = then => {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  };

  _goToList = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.history.push("/main/sim");
  };

  render() {
    const { contracts, customers, t } = this.props;
    const { form, errors } = this.state;

    const providerName = t(`sim.provider.${form.operator}`);

    return (
      <div>
        <FormTitle titleKey="sim.title" actionKey="sim.activate.action" />
        <form className="ingenico-form form-horizontal sim-form">
          <BootstrapInput
            validation={SimValidation.noop}
            onChange={SimValidation.noop}
            inputRef={this.setInputRef(Sim.PROVIDER)}
            name={Sim.PROVIDER}
            required={false}
            descriptor={{
              type: "text",
              label: "sim.form.provider",
              readOnly: true
            }}
            formValue={providerName}
          />
          <BootstrapInput
            validation={SimValidation.noop}
            onChange={SimValidation.noop}
            inputRef={this.setInputRef(Sim.SSN)}
            name={Sim.SSN}
            required={false}
            descriptor={{
              type: "text",
              label: "sim.form.SSN",
              readOnly: true
            }}
            formValue={form.SSN}
          />
          <BootstrapInput
            validation={SimValidation.required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.CUSTOMER_ID)}
            name={Sim.CUSTOMER_ID}
            errors={errors.customerId}
            required={true}
            descriptor={{
              type: "singleautocomplete",
              label: "sim.form.customerId",
              options: customers,
              readOnly: false
            }}
            formValue={form.customerId}
          />
          <BootstrapInput
            validation={SimValidation.required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.CONTRACT)}
            name={Sim.CONTRACT}
            descriptor={{
              type: "select",
              label: "sim.form.contract",
              options: contracts,
              readOnly: false
            }}
            formValue={form.contract}
            required={true}
          />
          <BootstrapInput
            validation={SimValidation.requiredAndIpValidator}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.GGSN1)}
            name={Sim.GGSN1}
            errors={errors.GGSN1}
            descriptor={{
              type: "ip",
              label: "sim.form.GGSN1",
              readOnly: false
            }}
            formValue={form.GGSN1}
            required={true}
          />
          <BootstrapInput
            validation={SimValidation.requiredAndIpValidator}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.GGSN2)}
            name={Sim.GGSN2}
            errors={errors.GGSN2}
            descriptor={{
              type: "ip",
              label: "sim.form.GGSN2",
              readOnly: false
            }}
            formValue={form.GGSN2}
            required={true}
          />
          <div className="pull-right">
            <button
              onClick={this._saveBtnHandler}
              name="save"
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    customers: { data: customers },
    sim: { data: sim, contracts }
  } = state;

  const currentSim = _.clone(sim || {});
  const actualSim = _.merge(
    {
      [Sim.CUSTOMER_ID]: null,
      [Sim.CONTRACT]: null,
      [Sim.GGSN1]: null,
      [Sim.GGSN2]: null
    },
    currentSim
  );

  const contractsList = contracts
    ? Utils.makeDataZipObject({
        list: contracts,
        key: "sim.values.contract"
      })
    : {};
  const customersList = customers ? Utils.mapCustomers({ customers }) : [];

  return {
    form: _.clone(actualSim),
    originalSim: _.clone(actualSim),
    contracts: contractsList,
    customers: customersList,
    errors: {}
  };
};

const mapDispatchToProps = dispatch => ({
  assignSim: ({ activation, provider }) =>
    dispatch(assignSim({ activation, provider })),
  getSim: ({ id }) => dispatch(getSim({ id })),
  resetSim: () => dispatch(resetSim()),
  getCustomers: () => dispatch(getCustomers()),
  loadContracts: () => dispatch(loadContracts()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ActivateRadiusSimView);
