import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container/Container";

import Div100vh from "../Div100vh/Div100vh";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

const CreateTerminalMobileWrapper = ({ children }) => {
  const { darkMode: prefersDarkMode } = useSelector(state =>
    mapStateToProps(state)
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: prefersDarkMode ? "#252525" : "#ededed",
        height: "calc(var(--vh, 1vh) * 100)",
        overflow: "scroll"
      }
    })
  );

  const classes = useStyles();

  const matches = useMediaQuery(`(max-width: 768px)`);

  if (matches) {
    return (
      <Div100vh>
        <Container disableGutters className={classes.root}>
          {children}
        </Container>
      </Div100vh>
    );
  }

  return null;
};

export default withRouter(CreateTerminalMobileWrapper);
