import React, { Fragment } from "react";

import ChartsGrid from "../../components/ChartsGrid/ChartsGridWrapper";
import MobileHeader from "../../components/Header/MobileHeader";
import HomeMobileView from "../../components/HomeMobile/HomeMobileView";
import { MainContainerMobile } from "../../components/MainContainerMobile/MainContainerMobile";

export const HomeMobileViewWrapper = ({ user, logoContent }) => (
  <HomeMobileView>
    <MainContainerMobile>
      <Fragment>
        <MobileHeader user={user} logoContent={logoContent} />
        <ChartsGrid />
      </Fragment>
    </MainContainerMobile>
  </HomeMobileView>
);
