import F from "../../commons/HTTPFetcher";

import {
  PENDING_UPDATE_STATUS_SCHEDULED_REPORT_ACTION,
  SUCCESS_UPDATE_STATUS_SCHEDULED_REPORT_ACTION,
  FAILURE_UPDATE_STATUS_SCHEDULED_REPORT_ACTION,
  PENDING_DELETE_SCHEDULED_REPORT_ACTION,
  SUCCESS_DELETE_SCHEDULED_REPORT_ACTION,
  FAILURE_DELETE_SCHEDULED_REPORT_ACTION,
  PENDING_CREATE_SCHEDULED_REPORT_ACTION,
  SUCCESS_CREATE_SCHEDULED_REPORT_ACTION,
  FAILURE_CREATE_SCHEDULED_REPORT_ACTION,
  PENDING_GET_SCHEDULED_REPORT_ACTION,
  SUCCESS_GET_SCHEDULED_REPORT_ACTION,
  FAILURE_GET_SCHEDULED_REPORT_ACTION,
  PENDING_UPDATE_SCHEDULED_REPORT_ACTION,
  SUCCESS_UPDATE_SCHEDULED_REPORT_ACTION,
  FAILURE_UPDATE_SCHEDULED_REPORT_ACTION
} from "../actionsTypes";
import Scheduled from "../../scheduled/models/Scheduled";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

export const updateStatusScheduledReport = ({
  id,
  status
}) => async dispatch => {
  dispatch(updateStatusScheduledReportStart());

  return F.put(`/scheduled-reports/${id}/active/${status}`)
    .then(scheduleReport => {
      dispatch(updateStatusScheduledReportSucceeded({ id, active: status }));
      return scheduleReport;
    })
    .catch(errors => {
      dispatch(updateStatusScheduledReportFailure({ errors }));
      throw errors;
    });
};

const updateStatusScheduledReportStart = () => ({
  type: PENDING_UPDATE_STATUS_SCHEDULED_REPORT_ACTION
});

const updateStatusScheduledReportSucceeded = ({ id, active }) => ({
  type: SUCCESS_UPDATE_STATUS_SCHEDULED_REPORT_ACTION,
  payload: {
    id,
    active
  }
});

const updateStatusScheduledReportFailure = errors => ({
  type: FAILURE_UPDATE_STATUS_SCHEDULED_REPORT_ACTION,
  payload: {
    errors
  }
});

export const deleteScheduledReport = ({ id }) => async dispatch => {
  dispatch(deleteScheduledReportStart());

  return F.delete(`/scheduled-reports/${id}`)
    .then(scheduleReport => {
      dispatch(deleteScheduledReportSucceeded({ id }));
      return scheduleReport;
    })
    .catch(errors => {
      dispatch(deleteScheduledReportFailure({ errors }));
      throw errors;
    });
};

const deleteScheduledReportStart = () => ({
  type: PENDING_DELETE_SCHEDULED_REPORT_ACTION
});

const deleteScheduledReportSucceeded = ({ id }) => ({
  type: SUCCESS_DELETE_SCHEDULED_REPORT_ACTION,
  payload: {
    id
  }
});

const deleteScheduledReportFailure = errors => ({
  type: FAILURE_DELETE_SCHEDULED_REPORT_ACTION,
  payload: {
    errors
  }
});

export const createScheduledReport = ({ data }) => dispatch => {
  dispatch(createScheduledReportStart());

  const scheduled = Scheduled.buildFromForm(data);
  return F.post("/scheduled-reports", {
    body: scheduled,
    contentType: ContentTypes.JSON
  })
    .then(scheduleReport => {
      dispatch(createScheduledReportSucceeded());
      return scheduleReport;
    })
    .catch(errors => {
      dispatch(createScheduledReportFailure({ errors }));
      throw errors;
    });
};

const createScheduledReportStart = () => ({
  type: PENDING_CREATE_SCHEDULED_REPORT_ACTION
});

const createScheduledReportSucceeded = () => ({
  type: SUCCESS_CREATE_SCHEDULED_REPORT_ACTION
});

const createScheduledReportFailure = errors => ({
  type: FAILURE_CREATE_SCHEDULED_REPORT_ACTION,
  payload: {
    errors
  }
});

export const getScheduledReport = ({ id }) => dispatch => {
  dispatch(getScheduledReportStart());

  return F.get(`/scheduled-reports/${id}`)
    .then(scheduled => {
      const scheduleReport = Scheduled.wrapFromServer(scheduled);
      dispatch(getScheduledReportSucceeded({ scheduleReport }));
      return scheduleReport;
    })
    .catch(errors => {
      dispatch(getScheduledReportFailure({ errors }));
      throw errors;
    });
};

const getScheduledReportStart = () => ({
  type: PENDING_GET_SCHEDULED_REPORT_ACTION
});

const getScheduledReportSucceeded = ({ scheduleReport }) => ({
  type: SUCCESS_GET_SCHEDULED_REPORT_ACTION,
  payload: {
    scheduleReport
  }
});

const getScheduledReportFailure = errors => ({
  type: FAILURE_GET_SCHEDULED_REPORT_ACTION,
  payload: {
    errors
  }
});

export const updateScheduledReport = ({ id, data }) => dispatch => {
  dispatch(updateScheduledReportStart());

  const scheduled = Scheduled.buildFromForm(data);
  return F.put(`/scheduled-reports/${id}`, {
    body: scheduled,
    contentType: ContentTypes.JSON
  })
    .then(scheduleReport => {
      dispatch(updateScheduledReportSucceeded());
      return scheduleReport;
    })
    .catch(errors => {
      dispatch(updateScheduledReportFailure({ errors }));
      throw errors;
    });
};

const updateScheduledReportStart = () => ({
  type: PENDING_UPDATE_SCHEDULED_REPORT_ACTION
});

const updateScheduledReportSucceeded = () => ({
  type: SUCCESS_UPDATE_SCHEDULED_REPORT_ACTION
});

const updateScheduledReportFailure = errors => ({
  type: FAILURE_UPDATE_SCHEDULED_REPORT_ACTION,
  payload: {
    errors
  }
});
