import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import Merchant from "../../merchant/models/Merchant";

import {
  FAILURE_CREATE_MERCHANT_ACTION,
  PENDING_CREATE_MERCHANT_ACTION,
  SUCCESS_CREATE_MERCHANT_ACTION,
  FAILURE_UPDATE_MERCHANT_ACTION,
  PENDING_UPDATE_MERCHANT_ACTION,
  SUCCESS_UPDATE_MERCHANT_ACTION,
  PENDING_GET_MERCHANT_ACTION,
  SUCCESS_GET_MERCHANT_ACTION,
  FAILURE_GET_MERCHANT_ACTION,
  PENDING_UPDATE_STATUS_ACTION,
  FAILURE_UPDATE_STATUS_ACTION,
  RESET_MERCHANT_STATE_ACTION,
  FAILURE_UPDATE_SETTINGS_MERCHANT_ACTION,
  PENDING_UPDATE_SETTINGS_MERCHANT_ACTION,
  SUCCESS_UPDATE_SETTINGS_MERCHANT_ACTION
} from "../actionsTypes";

export const updateStatus = ({ merchantIds, newStatus }) => async dispatch => {
  dispatch(updateStatusStart());

  try {
    const url = new URL(`/merchant/batch/status/${newStatus}`, PORTAL_URL);

    return await F.put(`${url.pathname}${url.search}`, {
      contentType: ContentTypes.JSON,
      body: merchantIds
    });
  } catch (errors) {
    dispatch(updateStatusFailure({ errors }));
    throw errors;
  }
};

const updateStatusStart = () => ({
  type: PENDING_UPDATE_STATUS_ACTION
});

const updateStatusFailure = errors => ({
  type: FAILURE_UPDATE_STATUS_ACTION,
  payload: {
    errors
  }
});

export const createMerchant = ({ merchant }) => async dispatch => {
  dispatch(createMerchantStart());

  const merchantWrapper: any = Merchant.buildFromForm(merchant);

  return F.post("/merchant", {
    body: merchantWrapper,
    contentType: ContentTypes.JSON
  }).then(
    merchantCreated => {
      dispatch(createMerchantSucceeded());
      return merchantCreated;
    },
    errors => {
      dispatch(createMerchantFailure(errors));

      throw errors;
    }
  );
};

const createMerchantStart = () => ({
  type: PENDING_CREATE_MERCHANT_ACTION
});

const createMerchantSucceeded = () => ({
  type: SUCCESS_CREATE_MERCHANT_ACTION
});

const createMerchantFailure = errors => ({
  type: FAILURE_CREATE_MERCHANT_ACTION,
  payload: {
    errors
  }
});

export const editMerchant = ({ merchant }) => async dispatch => {
  dispatch(editMerchantStart());

  const { id, applicationSettings } = merchant;
  return F.put(`/merchant/${id}`, {
    body: {
      ...merchant,
      applicationSettings
    },
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(editMerchantSucceeded()),
    errors => {
      dispatch(editMerchantFailure({ errors }));

      throw errors;
    }
  );
};

const editMerchantStart = () => ({
  type: PENDING_UPDATE_MERCHANT_ACTION
});

const editMerchantSucceeded = () => ({
  type: SUCCESS_UPDATE_MERCHANT_ACTION
});

const editMerchantFailure = errors => ({
  type: FAILURE_UPDATE_MERCHANT_ACTION,
  payload: {
    errors
  }
});

export const getMerchant = id => dispatch => {
  dispatch(getMerchantStart());

  return F.get(`/merchant/${id}`).then(
    merchant => {
      dispatch(getMerchantSucceeded(merchant));
      return merchant;
    },
    errors => {
      dispatch(getMerchantFailure(errors));
      throw errors;
    }
  );
};

const getMerchantStart = () => ({
  type: PENDING_GET_MERCHANT_ACTION
});

const getMerchantSucceeded = merchant => ({
  type: SUCCESS_GET_MERCHANT_ACTION,
  payload: {
    merchant
  }
});

const getMerchantFailure = errors => ({
  type: FAILURE_GET_MERCHANT_ACTION,
  payload: {
    errors
  }
});

export const updateMerchantSettings = ({ merchant }) => async dispatch => {
  dispatch(updateMerchantSettingsStart());

  const merchantData = Merchant.buildFromForm(merchant);

  return F.put("/me/level-settings", {
    body: merchantData,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(updateMerchantSettingsSucceeded()),
    errors => {
      dispatch(updateMerchantSettingsFailure({ errors }));

      throw errors;
    }
  );
};

const updateMerchantSettingsStart = () => ({
  type: PENDING_UPDATE_SETTINGS_MERCHANT_ACTION
});

const updateMerchantSettingsSucceeded = () => ({
  type: SUCCESS_UPDATE_SETTINGS_MERCHANT_ACTION
});

const updateMerchantSettingsFailure = errors => ({
  type: FAILURE_UPDATE_SETTINGS_MERCHANT_ACTION,
  payload: {
    errors
  }
});

export const resetMerchantState = () => ({
  type: RESET_MERCHANT_STATE_ACTION
});
