import React, { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import I18nSpan from "../../i18n/components/I18nSpan";
import TimePeriod from "../models/TimePeriod";
import NumberFormatter from "../../formatters/NumberFormatter";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import { WidgetConfig } from "../../home/components/Widget";
import WidgetView from "../../home/components/Widget";

import {
  getTerminalConnectionsWidgets,
  resetWidgetsState,
  getTerminalActivitiesConnections,
  resetTerminalActivitiesConnections
} from "../../redux/actions";

import styles from "./styles/ListTerminalConnection.css";

interface Props {
  getWidgets: Function;
  history: any;
  resetWidgetsState: Function;
  getConnections: Function;
  begin: any;
  end: any;
  sortDescription: any;
  widgets: any;
  color: any;
  user: any;
  connections: any;
}

interface State {}

const advancedSearchKey = AdvancedSearchConstants.TRANSACTION_KEY;

class ListTerminalConnectionView extends Component<Props, State> {
  columnsFormatters = {
    ipVolumeFromTerminal: val =>
      val
        ? NumberFormatter.formatNumber((val.value || 0) / 1024, "0,0.00")
        : "", // eslint-disable-line no-magic-numbers
    ipVolumeToTerminal: val =>
      val
        ? NumberFormatter.formatNumber((val.value || 0) / 1024, "0,0.00")
        : "", // eslint-disable-line no-magic-numbers
    totalVolume: val =>
      val ? NumberFormatter.formatNumber((val || 0) / 1024, "0,0.00") : "" // eslint-disable-line no-magic-numbers
  };
  columns = [
    "posName",
    "poi.serialNumber",
    "merchant.merchantReference",
    "poi.terminalReference",
    "merchant.name",
    "contractName",
    "doc_count",
    "ipVolumeFromTerminal",
    "ipVolumeToTerminal",
    "totalVolume"
  ];

  async componentDidMount() {
    const { getWidgets, periodSelection } = this.props;
    await getWidgets({ filters: [], periodSelection });
    this._search();
  }

  async componentWillUnmount() {
    const { resetWidgetsState } = this.props;
    resetWidgetsState();
  }

  _search = () => {
    const { getConnections } = this.props;
    const timePeriod = TimePeriod.MONTH_TO_DATE;
    timePeriod.refreshTime();

    const begin = timePeriod.startTime.valueOf();
    const end = timePeriod.endTime.valueOf();

    return getConnections({ begin, end });
  };

  _backURl = async event => {
    event.preventDefault();

    const { history } = this.props;

    const { getWidgets, periodSelection, resetWidgetsState } = this.props;
    await resetWidgetsState();
    await getWidgets({ filters: [], periodSelection });

    return history.push("/main/reporting/terminals");
  };

  render() {
    const {
      sortDescription,
      searchContext,
      widgets,
      color,
      user,
      connections,
      t
    } = this.props;

    const widgetsHidden = {};
    const widgetsSize = {};
    const formatters = {};

    const title = t("poi.volumesStrips.title");

    return (
      <div className="data-table-wrapper reporting terminal-connection">
        <FormTitle
          titleKey="monitoringPoiSim.mainTitle"
          actionKey="monitoringPoiSim.terminalActivities.mainTitle"
        />

        <div className="flexButtons">
          <button className="btn btn-ingenico mrn mvl" onClick={this._backURl}>
            <span className="glyphicon glyphicon-circle-arrow-left" />
            <I18nSpan
              msgKey={"monitoringPoiSim.terminalConnections.button.back"}
            />
          </button>
        </div>

        <h3>{title}</h3>
        <div
          key={`group-widget`}
          className={classNames(styles.wrapper, "group-widget")}
          ref={container => (this.widgetContainer = container)}
        >
          {widgets.map((config: WidgetConfig) => {
            const { id, type } = config;

            const { [type]: formatter } = formatters;
            const { [type]: hide } = widgetsHidden;
            const { [type]: size } = widgetsSize;

            return (
              <WidgetView
                className={classNames(styles.widget)}
                enableTrend={true}
                color={color}
                user={user}
                key={id}
                config={{ ...config, desktopView: true }}
                formatter={formatter}
                hide={hide}
                header={false}
                size={size}
              />
            );
          })}
        </div>

        <DataTable
          data={connections}
          attributes={this.columns}
          i18nKey="monitoringPoiSim.terminalConnections.list"
          idAttribute="key"
          color={color}
          onSort={() => undefined}
          hideSearchBox={true}
          hasActions={false}
          formatter={this.columnsFormatters}
          actionsElement={[]}
          sortDescription={sortDescription}
        />
      </div>
    );
  }
}

const mapConnections = ({ rawConnections, $t }) => {
  const connections = rawConnections.map((connection: any) => {
    connection.totalVolume =
      (connection.ipVolumeFromTerminal
        ? connection.ipVolumeFromTerminal.value
        : 0) +
      (connection.ipVolumeToTerminal ? connection.ipVolumeToTerminal.value : 0);

    connection.posName = connection.pos ? connection.pos.name : "";
    connection.contractName = connection.pos
      ? $t(`poi.contract.${connection.pos.poiContract}`)
      : "";

    return connection;
  });
  return { connections };
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, levelSettings: _levelSettings },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;
  const { t: $t } = ownProps;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };
  const {
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const timePeriod = TimePeriod.MONTH_TO_DATE;
  timePeriod.refreshTime();

  const min = timePeriod.startTime.valueOf();
  const max = timePeriod.endTime.valueOf();

  const {
    widgets: { data = [], loading, error },
    terminalActivities: {
      connections: { data: rawConnections }
    }
  } = state;

  const widgets = data.map(widget => {
    const { name, id, type } = widget;

    return {
      user,
      type,
      filters: [],
      name,
      id,
      period: TimePeriod.MONTH_TO_DATE,
      userCurrency,
      timePeriod,
      timestamps: {
        min,
        max
      }
    };
  });

  const { connections } = mapConnections({ rawConnections, $t });

  return {
    searchContext,
    sortDescription,
    connections,
    periodSelection: TimePeriod.MONTH_TO_DATE,
    color,
    widgets,
    loading,
    error,
    begin: min,
    end: max
  };
};

const mapDipatchToProps = dispatch => ({
  resetWidgetsState: () => dispatch(resetWidgetsState()),
  getWidgets: ({ filters, periodSelection }) =>
    dispatch(getTerminalConnectionsWidgets({ filters, periodSelection })),
  getConnections: ({ begin, end }) =>
    dispatch(getTerminalActivitiesConnections({ begin, end })),
  resetConnections: () => dispatch(resetTerminalActivitiesConnections())
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDipatchToProps)
)(ListTerminalConnectionView);
