import React, { Component } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

class ExclusiveContainer extends Component {
  constructor(props) {
    super();
    this.state = this.propsToState(props);
    props.onChange(this.state.selectedName, "");
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.propsToState(nextProps));
  }

  propsToState(props) {
    var state = this.state || {};
    if (!_.isEmpty(props.selectedName)) {
      state.selectedName = props.selectedName;
    } else if (props.children && props.children.length >= 1) {
      state.selectedName = props.children[0].props.name;
    }
    return state;
  }

  handleChangePart = event => {
    var previousSelectedName = this.state.selectedName;
    var newSelectedName = event.currentTarget.value;
    this.setState({
      selectedName: newSelectedName
    });
    this.props.onChange(newSelectedName, previousSelectedName);
  };

  render() {
    return (
      <div className="form-group exclusive-container">
        <div className="col-md-9 col-md-offset-3 col-sm-9 col-sm-offset-3">
          {_.map(this.props.children, child => {
            const childrenName = child.props.name;
            const childrenLabel = child.props.label || child.props.name;
            const isSelected = this.state.selectedName === childrenName;

            return (
              <div key={childrenName} className={`part ${childrenName}`}>
                <input
                  id={`label-${childrenName}`}
                  className="radio-button"
                  name={this.props.groupName}
                  type="radio"
                  checked={isSelected}
                  disabled={this.props.disabled}
                  value={childrenName}
                  onChange={this.handleChangePart}
                />
                <label htmlFor={`label-${childrenName}`}>{childrenLabel}</label>

                <div className={`part-content ${isSelected ? "" : "disabled"}`}>
                  {child}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

ExclusiveContainer.propTypes = {
  selectedName: PropTypes.string,
  onChange: PropTypes.func
};

ExclusiveContainer.defaultProps = {
  selectedName: "",
  onChange: _.noop
};

export default ExclusiveContainer;
