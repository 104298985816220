import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_SIMS_ACTION,
  SUCCESS_GET_SIMS_ACTION,
  FAILURE_GET_SIMS_ACTION,
  RESET_SIMS_ACTION,
  PENDING_DELETE_SIMS_ACTION,
  FAILURE_DELETE_SIMS_ACTION,
  PENDING_UPDATE_STATUS_SIMS_ACTION,
  FAILURE_UPDATE_STATUS_SIMS_ACTION
} from "../actionsTypes";

export const getSims = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getSimsStart());

  const url = new URL("/sims/search", PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    ({ count, scrollId, searchResults: data }) => {
      dispatch(getSimsSucceeded({ count, scrollId, data }));

      return data;
    },
    errors => {
      dispatch(getSimsFailure({ errors }));

      throw errors;
    }
  );
};

const getSimsStart = () => ({
  type: PENDING_GET_SIMS_ACTION
});

const getSimsSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_SIMS_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getSimsFailure = errors => ({
  type: FAILURE_GET_SIMS_ACTION,
  payload: {
    errors
  }
});

export const resetSims = () => ({
  type: RESET_SIMS_ACTION
});

//////

export const deleteSims = ({ ids }) => async dispatch => {
  dispatch(deleteSimsStart());

  try {
    const url = new URL("/sims/batch", PORTAL_URL);

    return await F.delete(`${url.pathname}${url.search}`, {
      body: ids,
      contentType: ContentTypes.JSON
    });
  } catch (errors) {
    dispatch(deleteSimsFailure({ errors }));

    throw errors;
  }
};

const deleteSimsStart = () => ({
  type: PENDING_DELETE_SIMS_ACTION
});

const deleteSimsFailure = errors => ({
  type: FAILURE_DELETE_SIMS_ACTION,
  payload: {
    errors
  }
});

//////

export const updateSimsStatus = ({ ids, status }) => async dispatch => {
  dispatch(updateSimsStatusStart());

  try {
    const url = new URL(`/sims/batch/status/${status}`, PORTAL_URL);

    return await F.put(`${url.pathname}${url.search}`, {
      body: ids,
      contentType: ContentTypes.JSON
    });
  } catch (errors) {
    dispatch(updateSimsStatusFailure({ errors }));

    throw errors;
  }
};

const updateSimsStatusStart = () => ({
  type: PENDING_UPDATE_STATUS_SIMS_ACTION
});

const updateSimsStatusFailure = errors => ({
  type: FAILURE_UPDATE_STATUS_SIMS_ACTION,
  payload: {
    errors
  }
});
