import React, { Component } from "react";
import PropTypes from "prop-types";

import DataGrid from "../../../datagrid/components/DataGrid";
const withRouter = require("react-router").withRouter;

interface Props {
  history: any;
  templates: any;
}

interface State {}

class ListEmailTemplateView extends Component<Props, State> {
  goToEditPage = (rowIdx, row) => {
    const { history } = this.props;
    if (row && row.templateId) {
      history.push(
        `/main/settings/templates/edit-email-templates/${row.templateId}`
      );
    }
  };

  render() {
    const { templates } = this.props;
    return (
      <div className="data-grid-wrapper">
        <DataGrid
          rows={templates}
          groupBy="domain"
          columns={["translatedId", "subject"]}
          i18nKey="customer.settings.templates.list"
          onRowClick={this.goToEditPage}
        />
      </div>
    );
  }
}

ListEmailTemplateView.defaultProps = {
  templates: PropTypes.array.isRequired
};

export default withRouter(ListEmailTemplateView);
