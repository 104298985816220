import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Badge from "../../../chart/components/chart/Badge";
import LoadingChart from "./LoadingChart";
import { getData } from "../../utils/DataProvider";
import { WidgetConfig } from "../Widget";

import style from "./style/CustomersAverageVisitsBadge.css";

interface Data {
  visits: any;
}

interface Props {
  getData: Function;
  data: Data;
  loading: boolean;
  error?: boolean;
  title: string;
  color: string;
  id: string;
  config: WidgetConfig;
}

class CustomersAverageVisitsBadge extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { user, desktopView } = config;
    if (desktopView) {
      return getData({ user, config });
    }
  }

  render() {
    const { data, color, loading, t } = this.props;
    const { visits } = data;

    const chartPrefix = "reporting.customers.averagevisitsbadge";
    const begin = t(`${chartPrefix}.begin`);
    const end = t(`${chartPrefix}.end`);

    return (
      <LoadingChart loading={loading} color={color}>
        <div className={style.container}>
          <Badge
            className={style.container}
            content={visits}
            begin={begin}
            end={end}
            color={color}
          />
        </div>
      </LoadingChart>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }

  return {
    data: {},
    loading: true
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CustomersAverageVisitsBadge);
