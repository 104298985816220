import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import get from "get-value";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import Counter from "../../datatable/components/Counter";
import { Refresh } from "../../datatable/components/Refresh";
import { setUrlWithParams } from "../../searchContext/URLizer";
import { SearchContextRedux } from "../../searchContext/SearchContext";
import { ShowDetailAction } from "../../datatable/components/multipleActions";
import TimeSelector from "./TaxAuditLogTimeSelector";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import LogEventTypes from "../constants/LogEventTypes";
import LogEventFilters from "../constants/LogEventFilters";

import {
  getLogs,
  getCountries,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetLogs,
  addNotificationError,
  setSearchContext
} from "../../redux/actions";

import styles from "../styles/TaxAuditLog.css";

interface Props {
  loading: boolean;
  isMerchant: any;
  merchantId: string;
  color: any;
  storeNames: any;
  logCount: any;
  scrollOffset: string;
  logList: any;
  selectedLogIds: any;
  selection: any;
  getLogs: Function;
  activationButtonStatus: Function;
  getCountries: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  resetLogs: Function;
  setSearchContext: any;
  searchContext: SearchContextRedux;
  location: any;
  tableCount: any;
  history: any;
  criteriaTypes: any;
  attributes: any;
}
interface State {
  criteria: any;
}

const advancedSearchKey = AdvancedSearchConstants.TAX_AUDIT_LOG_KEY;

class TaxAuditLogView extends Component<Props, State> {
  state = { criteria: LogEventTypes.ALL_EVENTS };

  formatter = {
    "log.createDate": date =>
      moment(date)
        .format(`DD/MM/YYYY HH:mm:ss`)
        .toString(),
    "log.eventCreateDate": date =>
      moment(date)
        .format(`DD/MM/YYYY HH:mm:ss`)
        .toString()
  };

  async componentDidMount() {
    const { getCountries, searchContext } = this.props;
    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] }
    } = searchContext;
    const eventFilter = filters.find(f => f.name === "eventType");
    await getCountries();
    await this._onRefresh({ searchContext });
    this.setUrlParams({ searchContext });
    eventFilter &&
      this.setState({ criteria: this._getGroupEventFromFilter(eventFilter) });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  componentWillUnmount() {
    const { resetLogs } = this.props;
    resetLogs();
  }

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getLogs, scrollOffset, merchantId } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getLogs({
      filters,
      fields: [],
      sort,
      tableCount,
      scrollOffset,
      merchantId
    });
  };

  _search = ({ searchContext }) => {
    const {
      getLogs,
      scrollOffset,
      tableCount,
      setSearchContext,
      merchantId,
      location: { pathname }
    } = this.props;
    const { sort, filters, timePeriod } = searchContext;
    setSearchContext({ context: searchContext, pathname, updateUrl: true });
    getLogs({
      filters,
      sort,
      tableCount,
      timePeriod,
      scrollOffset,
      merchantId
    });
  };

  _showDetails = id => {
    const { history, merchantId } = this.props;
    history.push(`/main/monitoring/log-details/${merchantId}/${id}`);
  };

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  _loadMoreRows = async () => {
    const {
      getLogs,
      scrollOffset = 0,
      tableCount,
      searchContext,
      merchantId,
      loading
    } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort },
      timePeriod = {}
    } = searchContext;
    if (loading) return;
    await getLogs({
      filters,
      fields: [],
      sort,
      tableCount,
      scrollOffset: scrollOffset + 1,
      timePeriod,
      merchantId
    });
  };

  _getGroupEventFromFilter = ({ operator, value }) => {
    const {
      ECR_EVENTS,
      ALERT_EVENTS,
      USER_EVENTS,
      CLOSE_PERIOD_EVENTS,
      INSTALLATION_EVENTS
    } = LogEventTypes;

    if (operator === "EQUAL") return LogEventTypes.ALL_EVENTS;
    const event = [
      ECR_EVENTS,
      ALERT_EVENTS,
      USER_EVENTS,
      CLOSE_PERIOD_EVENTS,
      INSTALLATION_EVENTS
    ].find(type => {
      const intersection = _.intersection(LogEventFilters[type], value);
      if (intersection.length) return true;
    });
    return event || LogEventTypes.ALL_EVENTS;
  };

  _mapEventTypes = ev => {
    const {
      ECR_EVENTS,
      ALERT_EVENTS,
      USER_EVENTS,
      CLOSE_PERIOD_EVENTS,
      INSTALLATION_EVENTS
    } = LogEventTypes;

    switch (ev) {
      case ALERT_EVENTS:
        return { operator: "IN", value: LogEventFilters.ALERT_EVENTS };
      case USER_EVENTS:
        return {
          operator: "IN",
          value: LogEventFilters.USER_EVENTS
        };
      case CLOSE_PERIOD_EVENTS:
        return {
          operator: "IN",
          value: LogEventFilters.CLOSE_PERIOD_EVENTS
        };
      case INSTALLATION_EVENTS:
        return { operator: "IN", value: LogEventFilters.INSTALLATION_EVENTS };
      case ECR_EVENTS:
        return {
          operator: "IN",
          value: LogEventFilters.ECR_EVENTS
        };
      default:
        return { operator: "EQUAL", value: [ev] };
    }
  };

  _onCriteriaChange = (inputName: string, criteria: string) => {
    let newFilters: any[] = [];
    const {
      searchContext: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        timePeriod = {}
      }
    } = this.props;

    if (inputName === "log.eventType") {
      newFilters = filters.filter(el => el.name !== "eventType");
      if (criteria && criteria !== LogEventTypes.ALL_EVENTS) {
        newFilters.push({
          name: "eventType",
          ...this._mapEventTypes(criteria)
        });
      }
    }

    this.setState({ criteria }, () =>
      this._search({
        searchContext: {
          key: advancedSearchKey,
          filters: newFilters,
          timePeriod
        }
      })
    );
  };

  render() {
    const {
      color,
      logCount,
      logList,
      attributes,
      loading,
      sortDescription,
      tableCount,
      searchContext,
      criteriaTypes
    } = this.props;

    const LogDetails = ({ ["log.fslEntryId"]: id }) => (
      <ShowDetailAction
        className={styles["detail-action"]}
        onDetail={this._showDetails.bind(null, id)}
      />
    );

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle titleKey="logs.mainTitle" actionKey="logs.description" />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={logCount}
                loadingKey="logs.list.loading"
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>
        </div>
        <div className={styles["filters"]}>
          <div className={styles["type"]}>
            <BootstrapInput
              ref="type"
              id="taxAuditTypeSelector"
              name="log.eventType"
              onChange={this._onCriteriaChange}
              descriptor={{
                type: "select",
                labelClass: `control-label col-sm-1 ${styles.criteria}`,
                inputClass: "col-sm-12",
                options: criteriaTypes
              }}
              formValue={this.state.criteria}
            />
          </div>

          <div className={styles["time"]}>
            <TimeSelector
              onGetMessage={() => {}}
              onChange={({ searchContext }) => this._search({ searchContext })}
              searchContext={searchContext}
            />
          </div>
        </div>

        <DataTable
          data={logList}
          attributes={attributes}
          notSortableFields={attributes}
          formatter={this.formatter}
          visibleAttributes={attributes}
          isColumnSettable={false}
          i18nKey="logs.list"
          idAttribute="log.fslEntryId"
          hideSearchBox
          toggleable={false}
          color={color}
          hasActions
          actionsElement={[LogDetails]}
          userActions
          onRefresh={this._search}
          onUpdateColumns={this._updateColumns}
          selectableNew={false}
          version="new"
          useSelection={false}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={
            logList.length < tableCount
              ? logList.length
              : logList.length + tableCount
          }
          sortKey={advancedSearchKey}
          searchContext={searchContext}
          sortDescription={sortDescription}
        />
      </div>
    );
  }
}

const withStatus = ({ state }) => {
  const {
    logs: { data, count, scrollId }
  } = state;

  const logs = data.map(log => {
    const normalized = Object.keys(log).reduce((acc, key) => {
      return {
        ...acc,
        [`log.${key}`]: get(log, key)
      };
    }, {});
    return { ...normalized };
  });

  return {
    count,
    scrollId,
    logs
  };
};

const mapStateToProps = (state, props) => {
  const {
    auth: { user, isMerchant },
    logs: { selection, loading },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount = 50 }
    }
  } = state;
  const criteriaTypes = mapTypeOptions(props.t);
  const {
    match: { params }
  } = props;
  const {
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    }
  } = state;
  const {
    searchContext: { data: searchContext }
  } = state;

  const { logs: logList, count: logCount, scrollId: scrollOffset } = withStatus(
    {
      state
    }
  );
  const attributes = [
    "log.userId",
    "log.eventCreateDate",
    "log.createDate",
    "log.eventType",
    "log.originType",
    "log.status"
  ];
  const selectedLogIds = _.keys(_.pick(selection, _.identity));

  return {
    loading,
    attributes,
    isMerchant,
    color,
    logCount,
    scrollOffset,
    logList,
    selectedLogIds,
    selection,
    tableCount,
    sortDescription,
    searchContext,
    criteriaTypes,
    merchantId: isMerchant ? user.scope.level.id : params.merchantId
  };
};

const mapTypeOptions = $t => {
  return {
    [LogEventTypes.ALL_EVENTS]: $t("logs.events.allEvents"),
    [LogEventTypes.INSTALLATION_EVENTS]: $t("logs.events.installationEvents"),
    [LogEventTypes.USER_EVENTS]: $t("logs.events.userEvents"),
    [LogEventTypes.ECR_EVENTS]: $t("logs.events.ecrEvents"),
    [LogEventTypes.CLOSE_PERIOD_EVENTS]: $t("logs.events.closePeriodEvents"),
    [LogEventTypes.ALERT_EVENTS]: $t("logs.events.alertEvents")
  };
};

const mapDispatchToProps = dispatch => ({
  resetLogs: () => dispatch(resetLogs()),
  getLogs: props => dispatch(getLogs(props)),
  getCountries: () => dispatch(getCountries()),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TaxAuditLogView);
