import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { WidgetConfig } from "../Widget";
import NumberFormatter from "../../../formatters/NumberFormatter";
import BarChart from "../../../chart/components/chart/BarChart";
import LoadingChart from "./LoadingChart";
import { getData } from "../../utils/DataProvider";

interface Data {
  criteria?: any;
  wallets?: Array<any>;
  currencyCodeAlpha3?: string;
  currencyDecimal?: string;
  currency?: string;
  walletsAverage: Array<any>;
}

interface Props {
  getData: Function;
  data: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  config: WidgetConfig;
}

class WalletAverageBasket extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ config });
    }
  }

  render() {
    const { data, color, loading, config, t } = this.props;

    const { walletsAverage = [] } = data;
    const { userCurrency = {}, defaultPaletteId } = config;
    const { currency, currencyDecimal, currencyCodeAlpha3 } = userCurrency;

    const series = [
      {
        colorByPoint: true,
        data: walletsAverage.map(c => c.avg_basket.value),
        name: " ",
        showInLegend: false
      }
    ];
    const categories = walletsAverage ? walletsAverage.map(c => c.key) : null;
    const xAxisLegend = t(`reporting.card.averagebasket.xAxis.legend`);
    const yAxisLegend = t(`reporting.card.averagebasket.yAxis.legend`);
    const tooltip = {
      y: {
        formatter: function(value) {
          return NumberFormatter.formatAmount(
            value,
            currencyCodeAlpha3 + currency,
            currencyDecimal
          );
        }
      }
    };
    const barChartTitle = t("reporting.wallet.averagebasket.title");

    return (
      <LoadingChart loading={loading} color={color}>
        <BarChart
          color={color}
          title={`${barChartTitle} (${currency})`}
          xAxisLegend={xAxisLegend}
          yAxisLegend={yAxisLegend}
          tooltip={tooltip}
          series={series}
          categories={categories}
          currency={userCurrency}
          palette={defaultPaletteId}
        />
      </LoadingChart>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }

  return {
    data: {},
    loading: true
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(WalletAverageBasket);
