/*eslint-disable max-len*/

import { hot } from "react-hot-loader";
import React from "react";
import { Switch, Route, Redirect, Router } from "react-router-dom";
import { history } from "./history"; // https://github.com/gaearon/react-hot-loader/issues/298#issuecomment-500382141

import MainView from "./components/MainView";
import AuthenticateView from "./connection/components/AuthenticateView";
import AuthenticateVerifyView from "./connection/components/AuthenticateVerifyView";
import ToHomePage from "./connection/components/ToHomePage";
import ToOauthPage from "./connection/components/ToOauthPage";
import CreateTokenView from "./connection/components/CreateTokenView";
import ResetPasswordView from "./connection/components/ResetPasswordView";
import HomeView from "./home/components/HomeView";
import TerminalActivitiesView from "./reporting/components/TerminalActivitiesView";
import ListTerminalConnectionView from "./reporting/components/ListTerminalConnectionView";
import CreateCustomerView from "./customer/components/CreateCustomerView";
import ListCustomerView from "./customer/components/ListCustomerView";
import SettingsCustomerView from "./customer/components/SettingsCustomerView";
import CreateMerchantView from "./merchant/components/CreateMerchantView";
import ListMerchantView from "./merchant/components/ListMerchantView";
import ListStoreView from "./store/components/ListStoreView";
import EditPasswordView from "./user/components/EditPasswordView";
import ListSimView from "./sim/components/ListSimView";
import ListOfferView from "./offer/components/ListOfferView";
import CreateOfferView from "./offer/components/CreateOfferView";
import EditOfferView from "./offer/components/EditOfferView";
import CreateAutoinitDefaultProgramView from "./autoinitprogram/components/CreateAutoinitDefaultProgramView";
import EditProgramView from "./program/components/EditProgramView";

import ListAlertView from "./alerts/components/ListAlertView";
import ListAdvancedAlertView from "./alerts/components/ListAdvancedAlertView";
import CreateAdvancedAlert from "./alerts/components/CreateAdvancedAlert";
import CreatePredefinedAlertView from "./alerts/predefined/components/CreatePredefinedAlertView";
import EditAdvancedAlert from "./alerts/components/EditAdvancedAlert";

import CreateScheduledReport from "./scheduled/components/CreateScheduledReport";
import EditScheduledReport from "./scheduled/components/EditScheduledReport";
import ListScheduledView from "./scheduled/components/ListScheduledView";

import ListPoiView from "./poi/components/ListPoiView";
import CreateSwapPoiView from "./poi/components/CreateSwapPoiView";
import CreateTerminalMobile from "./components/TerminalMobile/CreateTerminalMobileView";
import EditPoiView from "./poi/components/EditPoiView";
import ListPosView from "./pos/components/ListPosView";
import EditUserView from "./user/components/EditUserView";
import ListUserView from "./user/components/ListUserView";
import LinkPoiView from "./poi/components/LinkPoiView";
import LinkPosView from "./pos/components/LinkPosView";
import UnLinkPoiView from "./pos/components/UnLinkPoiView";
import UnLinkPosView from "./poi/components/UnLinkPosView";
import SimActivitiesView from "./reporting/components/SimActivitiesView";
import MerchantReceiptSendView from "./receipt/MerchantReceiptSendView";
import CardholderReceiptSendView from "./receipt/CardholderReceiptSendView";
import EditCustomerView from "./customer/components/EditCustomerView";
import EditSimView from "./sim/components/EditSimView";
import CreateSimView from "./sim/components/CreateSimView";
import ActivateRadiusSimView from "./sim/components/ActivateRadiusSimView";
import ActivateSierraSimView from "./sim/components/ActivateSierraSimView";

import SnapshotView from "./snapshot/SnapshotView";
import ReturningCustomersView from "./reporting/components/returningCustomers/ReturningCustomersView";

import PaymentMethodsView from "./reporting/components/PaymentMethodsView";

import TransactionComparisonView from "./reporting/components/TransactionComparisonView";
import ListSimConnectionView from "./reporting/components/ListSimConnectionView";
import ListReconciliationView from "./reporting/reconciliation/components/ListReconciliationView";
import ListTerminalAlertView from "./terminalReporting/components/ListTerminalAlertView";
import TaxAuditView from "./reporting/components/TaxAuditView";

import ListSimAlertView from "./simReporting/components/ListSimAlertView";

import ListTransactionView from "./reporting/transactions/components/ListTransactionView";

import LogoCustomerView from "./customer/components/LogoCustomerView";
import TemplateView from "./customer/components/template/TemplateView";
import EditEmailTemplateView from "./customer/components/template/EditEmailTemplateView";
import EditSmsTemplateView from "./customer/components/template/EditSmsTemplateView";

import EditMerchantView from "./merchant/components/EditMerchantView";
import SettingsMerchantView from "./merchant/components/SettingsMerchantView";

import CreateStoreView from "./store/components/CreateStoreView";
import EditStoreView from "./store/components/EditStoreView";

import CreateUserView from "./user/components/CreateUserView";
import CreateMposUserView from "./user/components/CreateMposUserView";
import EditCurrentUserView from "./user/components/EditCurrentUserView";

import CreatePosView from "./pos/components/CreatePosView";
import EditPosView from "./pos/components/EditPosView";

import ImportSierraSimView from "./sim/components/ImportSierraSimView";

import ListTagView from "./tag/components/ListTagView";
import CreateTagView from "./tag/components/CreateTagView";
import EditTagView from "./tag/components/EditTagView";

import ProgramListView from "./program/components/ListProgramView";
import CreateProgramView from "./program/components/CreateProgramView";

import SchemeView from "./scheme/components/SchemeView";
import SchedulerMonitoringView from "./monitoring/components/SchedulerMonitoringView";
import AuditView from "./monitoring/components/AuditView";
import CreateScheduledJob from "./monitoring/components/CreateScheduledJob";

import LoginAs from "./monitoring/components/LoginAs";
import AddWidgetView from "./home/components/AddWidgetView";
import LoginWrapper from "./connection/components/LoginWrapper";
import ResolveConflict from "./reporting/components/ResolveConflict";
import ConflictList from "./reporting/components/ConflictList";

import ApplicationSettingsView from "./../jsx/applicationSettings/components/ApplicationSettingsView";

import CreateCatalogItemView from "./catalog/components/CreateCatalogItemView";
import ListCatalogItemView from "./catalog/components/ListCatalogItemView";
import EditCatalogItemView from "./catalog/components/EditCatalogItemView";
import UploadPhotoView from "./catalog/components/UploadPhotoView";

import VatRatesView from "./vatRate/components/VatRatesView";

import ListSaleView from "./reporting/sale/components/ListSaleView";
import ListPredefinedAlertView from "./alerts/predefined/components/ListPredefinedAlertView";

import TaxAuditLogView from "./taxAudit/components/TaxAuditLogView";
import TaxAuditLogDetailView from "./taxAudit/components/TaxAuditLogDetailView";
import ListSessionsView from "./taxAudit/components/ListSessionsView";
import ShowMerchantsView from "./taxAudit/components/ListMerchantView";

const loginPaths = "/(login|authenticate)";

const LoginRoutes = () => (
  <LoginWrapper>
    <Switch>
      <Route exact path="/authenticate" component={AuthenticateView} />
      <Route
        exact
        path="/login/2fa/:pair?"
        component={AuthenticateVerifyView}
      />
      <Route path="/login/forgot-password" component={CreateTokenView} />
      <Route
        path="/login/reset-password/:token"
        component={ResetPasswordView}
      />
    </Switch>
  </LoginWrapper>
);

const MainRoutes = () => (
  <MainView>
    <Switch>
      <Route exact path="/main" component={HomeView} />

      <Route path="/main/reporting/snapshot" component={SnapshotView} />
      <Route
        path="/main/reporting/transaction"
        component={ListTransactionView}
      />
      <Route path="/main/reporting/cards" component={PaymentMethodsView} />
      <Route
        path="/main/reporting/comparison"
        component={TransactionComparisonView}
      />
      <Route
        path="/main/reporting/terminals"
        component={TerminalActivitiesView}
      />
      <Route
        path="/main/reporting/terminal-connections"
        component={ListTerminalConnectionView}
      />
      <Route
        path="/main/reporting/returningCustomers"
        component={ReturningCustomersView}
      />
      <Route exact path="/main/reporting/alerts" component={ListAlertView} />
      <Route
        exact
        path="/main/reporting/alerts/advanced"
        component={ListAdvancedAlertView}
      />
      <Route
        exact
        path="/main/reporting/alerts/predefined"
        component={ListPredefinedAlertView}
      />
      <Route
        exact
        path="/main/reporting/alerts/advanced/create"
        component={CreateAdvancedAlert}
      />
      <Route
        exact
        path="/main/reporting/alerts/predefined/create"
        component={CreatePredefinedAlertView}
      />
      <Route
        path="/main/reporting/alerts/advanced/edit/:alertId"
        component={EditAdvancedAlert}
      />
      <Route
        exact
        path="/main/reporting/scheduled"
        component={ListScheduledView}
      />
      <Route
        path="/main/reporting/scheduled/create"
        component={CreateScheduledReport}
      />
      <Route
        path="/main/reporting/scheduled/edit/:scheduledId"
        component={EditScheduledReport}
      />
      <Route path="/main/reporting/sims-status" component={SimActivitiesView} />
      <Route
        path="/main/reporting/sim-connections"
        component={ListSimConnectionView}
      />
      <Route
        path="/main/reporting/reconciliation"
        component={ListReconciliationView}
      />
      <Route exact path="/main/reporting/sales" component={ListSaleView} />
      <Route path="/main/reporting/conflicts" component={ConflictList} />
      <Route
        path="/main/reporting/resolve-conflict/:transactionId"
        component={ResolveConflict}
      />
      <Route path="/main/reporting/tax-audit" component={TaxAuditView} />

      <Route
        exact
        path="/main/terminal-reporting"
        component={TerminalActivitiesView}
      />
      <Route
        path="/main/terminal-reporting/terminal-alerts"
        component={ListTerminalAlertView}
      />

      <Route path="/main/sim-alerts" component={ListSimAlertView} />

      <Route exact path="/main/customer" component={ListCustomerView} />
      <Route
        path="/main/customer/create-customer"
        component={CreateCustomerView}
      />
      <Route
        path="/main/customer/edit-customer/:customerId"
        component={EditCustomerView}
      />

      <Route
        exact
        path="/main/settings/catalog"
        component={ListCatalogItemView}
      />
      <Route
        path="/main/settings/catalog/new-catalogItem"
        component={CreateCatalogItemView}
      />
      <Route
        path="/main/settings/catalog/edit-catalogItem/:catalogItemId"
        component={EditCatalogItemView}
      />

      <Route
        path="/main/settings/catalog/upload-photo/:catalogItemId/:edit?"
        component={UploadPhotoView}
      />

      <Route exact path="/main/settings/vatrate" component={VatRatesView} />

      <Route
        exact
        path="/main/settings/merchant"
        component={ListMerchantView}
      />
      <Route
        path="/main/settings/merchant/new-merchant"
        component={CreateMerchantView}
      />
      <Route
        path="/main/settings/merchant/edit-merchant/:merchantId"
        component={EditMerchantView}
      />
      <Route
        path="/main/merchant/settings-merchant"
        component={SettingsMerchantView}
      />
      <Route
        path="/main/merchant/application-settings-merchant"
        component={ApplicationSettingsView}
      />

      <Route exact path="/main/settings/tags" component={ListTagView} />
      <Route path="/main/settings/tags/new-tag" component={CreateTagView} />
      <Route
        path="/main/settings/tags/edit-tag/:tagId"
        component={EditTagView}
      />

      <Route exact path="/main/offers" component={ListOfferView} />
      <Route path="/main/offers/new-offer" component={CreateOfferView} />
      <Route
        path="/main/offers/edit-offer/:offerId"
        component={EditOfferView}
      />

      <Route exact path="/main/settings/program" component={ProgramListView} />
      <Route
        path="/main/program/default"
        component={CreateAutoinitDefaultProgramView}
      />
      <Route
        path="/main/settings/program/new-program"
        component={CreateProgramView}
      />
      <Route
        path="/main/settings/program/edit-program/:programId"
        component={EditProgramView}
      />

      <Route exact path="/main/settings/store" component={ListStoreView} />
      <Route
        path="/main/settings/store/new-store/:level?/:id?"
        component={CreateStoreView}
      />
      <Route
        path="/main/settings/store/edit-store/:storeId"
        component={EditStoreView}
      />

      <Route exact path="/main/settings/pos" component={ListPosView} />
      <Route
        path="/main/settings/pos/new-pos/:poiId?/:merchantId?"
        component={CreatePosView}
      />
      <Route
        path="/main/settings/pos/edit-pos/:posId"
        component={EditPosView}
      />
      <Route
        path="/main/settings/pos/link-pos/:posId"
        component={LinkPosView}
      />

      <Route
        exact
        path="/main/monitoring/logs/:merchantId?"
        component={TaxAuditLogView}
      />

      <Route
        exact
        path="/main/monitoring/log-details/:merchantId/:logId"
        component={TaxAuditLogDetailView}
      />

      <Route
        path="/main/settings/pos/unlink-poi/:posId"
        component={UnLinkPoiView}
      />

      <Route
        path="/main/settings/poi/unlink-pos/:posId/:poiId"
        component={UnLinkPosView}
      />

      <Route exact path="/main/:settings?/sim" component={ListSimView} />
      <Route path="/main/sim/new-sim" component={CreateSimView} />
      <Route path="/main/sim/import-sierra" component={ImportSierraSimView} />
      <Route
        path="/main/sim/activate/radius/:simId"
        component={ActivateRadiusSimView}
      />
      <Route
        path="/main/sim/activate/sierra/:simId"
        component={ActivateSierraSimView}
      />
      <Route
        path="/main/:settings?/sim/edit-sim/:simId"
        component={EditSimView}
      />

      <Route exact path="/main/settings/poi" component={ListPoiView} />
      <Route path="/main/settings/poi/new-poi" component={CreateSwapPoiView} />
      <Route
        path="/main/settings/poi/mobile"
        component={CreateTerminalMobile}
      />
      <Route
        path="/main/settings/poi/edit-poi/:poiId/:linked?"
        component={EditPoiView}
      />
      <Route
        path="/main/settings/poi/link-poi/:poiId"
        component={LinkPoiView}
      />
      <Route
        path="/main/settings/poi/swap-poi/:poiId"
        component={CreateSwapPoiView}
      />

      <Route
        path="/main/:settings?/user/new-user/:level?/:entityId?"
        component={CreateUserView}
      />
      <Route
        path="/main/settings/user/new-mposuser"
        component={CreateMposUserView}
      />
      <Route path="/main/my-account" component={EditCurrentUserView} />
      <Route
        path="/main/:settings?/user/edit-user/:userId"
        component={EditUserView}
      />
      <Route path="/main/edit-password" component={EditPasswordView} />
      <Route
        exact
        path="/main/:settings?/user/:level?"
        component={ListUserView}
      />

      <Route path="/main/scheme" component={SchemeView} />
      <Route
        path="/main/settings/merchants-settings"
        component={SettingsMerchantView}
      />

      <Route path="/main/settings/general" component={SettingsCustomerView} />
      <Route path="/main/settings/logo-customer" component={LogoCustomerView} />
      <Route
        exact
        path="/main/settings/templates/:template?"
        component={TemplateView}
      />
      <Route
        path="/main/settings/templates/edit-email-templates/:templateId"
        component={EditEmailTemplateView}
      />
      <Route
        path="/main/settings/templates/edit-sms-templates/:templateId"
        component={EditSmsTemplateView}
      />
      <Route
        path="/main/monitoring/scheduler"
        component={SchedulerMonitoringView}
      />
      <Route path="/main/monitoring/audit" component={AuditView} />
      <Route
        path="/main/monitoring/new-scheduled-job"
        component={CreateScheduledJob}
      />
      <Route path="/main/monitoring/login-as" component={LoginAs} />

      <Route
        path="/main/settings/merchantReceipt/:receiptType/multiple/send"
        component={MerchantReceiptSendView}
      />
      <Route
        path="/main/settings/merchantReceipt/:receiptType/id/:transactionId/send"
        component={MerchantReceiptSendView}
      />
      <Route
        path="/main/cardholderReceipt/:receiptType/id/:transactionId/send"
        component={CardholderReceiptSendView}
      />

      <Route path="/main/widgets/add" component={AddWidgetView} />
      <Route
        path="/main/monitoring/open-sessions/:merchantId"
        component={ListSessionsView}
      />

      <Route
        path="/main/monitoring/open-sessions"
        component={ListSessionsView}
      />

      <Route path="/main/tax-audit" component={ShowMerchantsView} />

      <Redirect path="*" to="/main" />
    </Switch>
  </MainView>
);

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route path="/redirect/:tokenValue" component={ToHomePage} />
      <Route path="/redirectOauth/:codeValue" component={ToOauthPage} />
      <Route path={loginPaths} component={LoginRoutes} />
      <Route path="/main" component={MainRoutes} />
      <Redirect path="*" to="/main" />
    </Switch>
  </Router>
);

export default hot(module)(Routes);
/*eslint-enable max-len*/
