import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Button, Paper } from "@material-ui/core";

import AngleCirle from "./AngleCirle";
import Grid from "@material-ui/core/Grid";

import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import { getObjectivesRevenue, getObjective } from "./objectiveProvider";
import NumberFormatter from "../../formatters/NumberFormatter";

import Edit from "./Edit";
import Create from "./Create";
import Loader from "./Loader";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

const mapStateToProps = state => {
  const {
    auth: { user, levelSettings: _levelSettings },
    theme: {
      darkMode,
      color: {
        data: { color }
      }
    }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  return {
    darkMode,
    color,
    user,
    userCurrency
  };
};

const ObjectivesCharts = () => {
  const { t } = useTranslation();

  const {
    darkMode: prefersDarkMode,
    color,
    userCurrency,
    user
  } = useSelector(state => mapStateToProps(state));

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        height: "calc(var(--vh, 1vh) * 25.28)",
        backgroundColor: prefersDarkMode ? "#303030" : "#FFFFFF",
        boxShadow: "0 5px 20px 0 rgba(0,0,0,0.03)",
        borderRadius: "10px",
        margin: "calc(var(--vh, 1vh) * 2.78) 0",
        position: "relative"
      },
      rootInit: {
        height: "calc(var(--vh, 1vh) * 22.31)"
      },
      rootSetup: {
        height: "calc(var(--vh, 1vh) * 28.31)",
        padding: "20px 20px 0 20px"
      },
      formControl: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        borderRadius: "6px",
        height: "36px",
        border: "initial",
        boxShadow: "initial",
        marginBottom: "5px"
      },
      formControlDaily: {
        background: "rgba(250,202,12,0.10)"
      },
      formControlWeekly: {
        background: "rgba(229,75,75,0.10)"
      },
      formControlMonthly: {
        background: "rgba(0,195,215,0.10)"
      },
      label: {
        position: "absolute",
        left: "12px",
        top: "50%",
        marginTop: "-5px",
        opacity: "0.75",
        fontWeight: 700,
        fontSize: "10px"
      },
      labelDaily: {
        color: "#FACA0C"
      },
      labelWeekly: {
        color: "#E54B4B"
      },
      labelMonthly: {
        color: "#00C3D7"
      }
    })
  );

  const useStylesGrid = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "50%"
      },
      rootInit: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "33%"
      },
      rootSetup: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
      },
      container: {
        height: "100%"
      },
      containerSetup: {
        flexWrap: "nowrap",
        height: "calc(var(--vh, 1vh) * 14.36)"
      },
      setupText: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        fontWeight: 700,
        paddingRight: "4vw",
        color: prefersDarkMode ? "#FFFFFF" : "#404041"
      },
      containerSetupButton: {
        padding: "0 4vw calc(var(--vh, 1vh) * 2.07) 4vw"
      },
      containerSetupButtonSetGoals: {
        padding: "0"
      }
    })
  );

  const useStylesNumeric = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        borderRadius: "6px",
        position: "relative"
      },
      yellowCard: {
        background: "rgba(250,202,12,0.25)"
      },
      yellowCardText: {
        color: "#FACA0C",
        fontWeight: "bold",
        background: "transparent"
      },
      redCard: {
        background: "rgba(229,75,75,0.10)"
      },
      redCardText: {
        color: "#E54B4B",
        fontWeight: "bold",
        background: "transparent"
      },
      cyanCard: {
        background: "rgba(0,195,215,0.10)"
      },
      cyanCardText: {
        color: "#00C3D7",
        fontWeight: "bold",
        background: "transparent"
      },
      lightCard: {
        borderRadius: "4px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        width: "100%"
      },
      lightYellow: {
        color: "#FACA0C",
        background: "#FFF8DA",
        fontWeight: "bold",
        boxShadow: "0 5px 10px 0 rgba(250,202,12,0.25)"
      },
      lightRed: {
        color: "#E54B4B",
        fontWeight: "bold",
        background: "#FFECEC",
        boxShadow: "0 5px 10px 0 rgba(229,75,75,0.10)"
      },
      lightCyan: {
        color: "#00C3D7",
        fontWeight: "bold",
        background: "#DFF8FA",
        boxShadow: "0 5px 10px 0 rgba(0,195,215,0.10)"
      },
      lightCardCurrent: {
        background: "transparent",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        width: "100%",
        height: "calc(var(--vh, 1vh) * 2.8)",
        color: "#404041"
      },
      cardClosed: {
        borderRadius: "6px",
        fontWeight: "bold",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        margin: "calc(var(--vh, 1vh) * 2.78) 2.78vw 0 0",
        height: "calc(var(--vh, 1vh) * 3.8)"
      },
      cardOpened: {
        margin: "calc(var(--vh, 1vh) * 2.78) 2.78vw 0 0",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
        padding:
          "calc(var(--vh, 1vh) * 0.557) 1.06vw calc(var(--vh, 1vh) * 0.557) 1.06vw"
      },
      cardOpenedY: {
        backgroundImage:
          "linear-gradient(170deg, #FACA0C 0%, #FFDD57 100%, #FFDD57 100%)"
      },
      cardOpenedR: {
        backgroundImage:
          "linear-gradient(170deg, #E54B4B 0%, rgba(229,75,75,0.60) 100%)"
      },

      cardOpenedC: {
        backgroundImage:
          "linear-gradient(171deg, #00C3D7 0%, rgba(0,195,215,0.60) 100%)"
      },
      opened: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(var(--vh, 1vh) * 8)"
      },
      middle: {
        margin: "0 5.33vw 0 0"
      },
      first: {
        margin: "calc(var(--vh, 1vh) * 2.78) 5.33vw 0 0"
      },
      last: {
        margin: "0 5.33vw calc(var(--vh, 1vh) * 2.78) 0"
      }
    })
  );

  const useStylesAngleCircle = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "auto"
      }
    })
  );

  const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "uppercase",
        backgroundColor: prefersDarkMode ? "#3B3B3B" : "#E5E5E5",
        borderRadius: "5px",
        width: "100%",
        fontWeight: "bold",
        height: "calc(var(--vh, 1vh) * 5.8)",
        fontSize: "11px",
        color: prefersDarkMode ? "#FFFFFF" : "#404041",
        textAlign: "center",
        "&:hover": {
          backgroundColor: "#E5E5E5"
        }
      },
      edit: {
        position: "absolute",
        bottom: "calc(var(--vh, 1vh) * 1.38)",
        left: "2.67vw",
        height: "20px",
        width: "40px",
        padding: "2px 0 0 0",
        backgroundColor: prefersDarkMode ? "#3B3B3B" : "#F5F5F5",
        borderRadius: "5px",
        fontSize: "9px",
        color: prefersDarkMode ? "#FFFFFF" : "#C5C5C5"
      },
      contained: {}
    })
  );

  const chartOptions = {
    chart: {
      type: "radialBar"
    },
    colors: ["#00c3d7", "#E54B4B", "#FACA0C"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "20%"
        },
        startAngle: 0,
        endAngle: 270,
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: false
          },
          total: {
            show: false
          }
        },
        track: {
          background: prefersDarkMode ? "#404040" : "#F5F5F5",
          strokeWidth: "100%",
          margin: 4,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5
          }
        }
      }
    },
    stroke: {
      lineCap: "round"
    },
    labels: [
      t("widget.objectives.monthly"),
      t("widget.objectives.weekly"),
      t("widget.objectives.daily")
    ],
    legend: {
      show: true,
      floating: true,
      fontSize: "9px",
      position: "left",
      horizontalAlign: "left",
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: prefersDarkMode ? ["#666666"] : ["#D4D4D4"]
      },
      markers: {
        width: 0,
        height: 0
      },
      itemMargin: {
        horizontal: 0,
        vertical: 0
      }
    }
  };
  const classes = useStyles();

  const classesGrid = useStylesGrid();

  const numericStyles = useStylesNumeric();

  const classesButton = useStylesButton();

  const classesAngleCircle = useStylesAngleCircle();

  const yellowCard = clsx(numericStyles.root, numericStyles.yellowCard);

  const cardOpened = clsx(numericStyles.root, numericStyles.cardOpened);

  const cardClosed = clsx(numericStyles.root, numericStyles.cardClosed);

  const lightCard = clsx(numericStyles.root, numericStyles.lightCard);

  const lightCardCurrent = clsx(
    numericStyles.root,
    numericStyles.lightCardCurrent
  );

  const redCard = clsx(numericStyles.root, numericStyles.redCard);

  const cyanCard = clsx(numericStyles.root, numericStyles.cyanCard);

  const matches = useMediaQuery(`(max-width: 768px)`);

  const { ...options } = chartOptions;

  const optionsSetup = {
    ...options,
    plotOptions: {
      ...options.plotOptions,
      radialBar: {
        ...options.plotOptions.radialBar,
        hollow: {
          ...options.plotOptions.radialBar.hollow,
          size: "10%"
        },
        track: {
          ...options.plotOptions.radialBar.track,
          margin: 3
        }
      }
    },
    legend: {
      show: false
    }
  };

  const seriesSetup = [50, 80, 100];

  const [series, setSeries] = useState([0, 0, 0]);

  const [{ loading, error, objective }, setObjective] = useState({
    loading: true,
    error: false
  });

  const [first, setFirst] = useState(false);

  const [second, setSecond] = useState(false);

  const [third, setThird] = useState(false);

  const [setup, setSetup] = useState(false);

  const [revenues, setRevenues] = useState({
    daily: 0,
    weekly: 0,
    monthly: 0
  });

  const CalObjectivePercentage = ({ objectiveValue, revenue }) => {
    const currencyDecimal = Math.pow(10, userCurrency.currencyDecimal);
    const res =
      objectiveValue !== 0 ? revenue / currencyDecimal / objectiveValue : 0;

    return res * 100;
  };

  const [edit, setEdit] = useState(false);

  const create = async values => {
    try {
      setObjective(state => ({
        ...state,
        loading: true
      }));

      const objectives = await F.put("/me/objective", {
        body: { ...values },
        contentType: ContentTypes.JSON
      });

      setObjective(state => ({
        ...state,
        loading: false,
        objective: objectives
      }));

      const series = GenerateObjectivesPurcentages({
        objectives,
        revenues
      });

      setSeries(series);
    } catch (error) {
      return setObjective(state => ({
        ...state,
        loading: false,
        error: true
      }));
    }
  };

  const GenerateObjectivesPurcentages = ({ objectives, revenues }) => {
    return [
      CalObjectivePercentage({
        objectiveValue: objectives.monthly,
        revenue: revenues.monthly
      }),
      CalObjectivePercentage({
        objectiveValue: objectives.weekly,
        revenue: revenues.weekly
      }),
      CalObjectivePercentage({
        objectiveValue: objectives.daily,
        revenue: revenues.daily
      })
    ];
  };

  const FetchData = async () => {
    try {
      const revenues = await getObjectivesRevenue({ user });

      setRevenues(state => ({
        ...state,
        ...revenues
      }));

      const objectives = await getObjective();

      setObjective(state => ({
        ...state,
        loading: false,
        objective: objectives,
        error: false
      }));

      const series = GenerateObjectivesPurcentages({
        objectives,
        revenues
      });

      setSeries(series);
    } catch (error) {
      setObjective(state => ({
        ...state,
        loading: false
      }));
    }
  };

  useEffect(() => {
    FetchData();
  }, []);

  const Yellow = () => {
    if (first) {
      return (
        <Paper
          elevation={0}
          className={clsx(
            cardOpened,
            numericStyles.cardOpenedY,
            numericStyles.first
          )}
          onClick={() => UnFoldSelection({ first: false })}
        >
          <Paper
            elevation={0}
            className={clsx(lightCard, numericStyles.lightYellow)}
          >
            {NumberFormatter.formatAmount(
              objective.daily,
              userCurrency.currencyCodeAlpha3 + userCurrency.currency,
              0
            )}
          </Paper>
          <Paper elevation={0} className={lightCardCurrent}>
            {NumberFormatter.formatAmount(
              revenues.daily,
              userCurrency.currencyCodeAlpha3 + userCurrency.currency,
              userCurrency.currencyDecimal
            )}
          </Paper>
        </Paper>
      );
    }

    return (
      <Paper
        elevation={0}
        className={clsx(cardClosed, numericStyles.first, yellowCard)}
        onClick={() => UnFoldSelection({ first: true })}
      >
        <Paper elevation={0} className={numericStyles.yellowCardText}>
          {NumberFormatter.formatAmount(
            objective.daily,
            userCurrency.currencyCodeAlpha3 + userCurrency.currency,
            0
          )}
        </Paper>
      </Paper>
    );
  };

  const UnFoldSelection = ({
    first = false,
    second = false,
    third = false
  }) => {
    setFirst(first);
    setSecond(second);
    setThird(third);
  };

  const Red = () => {
    if (second) {
      return (
        <Paper
          elevation={0}
          className={clsx(
            cardOpened,
            numericStyles.cardOpenedR,
            numericStyles.middle
          )}
          onClick={() => UnFoldSelection({ second: false })}
        >
          <Paper
            elevation={0}
            className={clsx(lightCard, numericStyles.lightRed)}
          >
            {NumberFormatter.formatAmount(
              objective.weekly,
              userCurrency.currencyCodeAlpha3 + userCurrency.currency,
              0
            )}
          </Paper>
          <Paper elevation={0} className={lightCardCurrent}>
            {NumberFormatter.formatAmount(
              revenues.weekly,
              userCurrency.currencyCodeAlpha3 + userCurrency.currency,
              userCurrency.currencyDecimal
            )}
          </Paper>
        </Paper>
      );
    }

    return (
      <Paper
        elevation={0}
        className={clsx(cardClosed, numericStyles.middle, redCard)}
        onClick={() => UnFoldSelection({ second: true })}
      >
        <Paper elevation={0} className={numericStyles.redCardText}>
          {NumberFormatter.formatAmount(
            objective.weekly,
            userCurrency.currencyCodeAlpha3 + userCurrency.currency,
            0
          )}
        </Paper>
      </Paper>
    );
  };

  const Cyan = () => {
    if (third) {
      return (
        <Paper
          elevation={0}
          className={clsx(
            cardOpened,
            numericStyles.cardOpenedC,
            numericStyles.last
          )}
          onClick={() => UnFoldSelection({ third: false })}
        >
          <Paper
            elevation={0}
            className={clsx(lightCard, numericStyles.lightCyan)}
          >
            {NumberFormatter.formatAmount(
              objective.monthly,
              userCurrency.currencyCodeAlpha3 + userCurrency.currency,
              0
            )}
          </Paper>
          <Paper elevation={0} className={lightCardCurrent}>
            {NumberFormatter.formatAmount(
              revenues.monthly,
              userCurrency.currencyCodeAlpha3 + userCurrency.currency,
              userCurrency.currencyDecimal
            )}
          </Paper>
        </Paper>
      );
    }

    return (
      <Paper
        elevation={0}
        className={clsx(cardClosed, numericStyles.last, cyanCard)}
        onClick={() => UnFoldSelection({ third: true })}
      >
        <Paper elevation={0} className={numericStyles.cyanCardText}>
          {NumberFormatter.formatAmount(
            objective.monthly,
            userCurrency.currencyCodeAlpha3 + userCurrency.currency,
            0
          )}
        </Paper>
      </Paper>
    );
  };

  if (matches) {
    return (
      <div
        className={classNames(classes.root, {
          [classes.rootInit]: !loading && !setup && !edit && !objective,
          [classes.rootSetup]: setup || edit
        })}
      >
        {!loading && error && (
          <Grid
            alignItems="center"
            direction="column"
            justify="center"
            container
            className={classesGrid.container}
          >
            error on loading
          </Grid>
        )}

        {loading && <Loader loading={loading} color={color} />}

        {!loading && !setup && !edit && !objective && (
          <>
            <Grid container className={classesGrid.containerSetup}>
              <Grid item className={classesGrid.rootInit}>
                <AngleCirle
                  className={classesAngleCircle.root}
                  series={seriesSetup}
                  options={optionsSetup}
                  type="radialBar"
                />
              </Grid>
              <Grid item className={classesGrid.setupText}>
                {t("widget.objectives.setup")}
              </Grid>
            </Grid>
            <Grid container className={classesGrid.containerSetupButton}>
              <Button
                classes={{
                  root: classesButton.root,
                  contained: classesButton.contained
                }}
                onClick={() => {
                  setSetup(true);
                }}
              >
                {t("widget.objectives.setupButton.init")}
              </Button>
            </Grid>
          </>
        )}

        {!loading && !edit && objective && (
          <>
            <Grid container className={classesGrid.container}>
              <Grid item className={classesGrid.root}>
                <AngleCirle
                  series={series}
                  options={options}
                  type="radialBar"
                />
              </Grid>
              <Grid item className={classesGrid.root}>
                <Yellow />
                <Red />
                <Cyan />
              </Grid>
            </Grid>
            <Button
              classes={{
                root: classesButton.edit,
                contained: classesButton.contained
              }}
              onClick={() => {
                setEdit(true);
              }}
            >
              {t("widget.objectives.setupButton.edit")}
            </Button>
          </>
        )}

        {!loading && setup && !edit && (
          <Create
            onCreate={async values => {
              await create(values);
              setSetup(false);
            }}
          />
        )}

        {!loading && !setup && edit && (
          <Edit
            objective={objective}
            onEdit={async values => {
              await create(values);
              setSetup(false);
              setEdit(false);
            }}
          />
        )}
      </div>
    );
  }

  return null;
};

export default withRouter(ObjectivesCharts);
