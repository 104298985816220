import React, { Component } from "react";
import Iframe from "../components/Iframe";
import I18nSpan from "../i18n/components/I18nSpan";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const iframeId = "ticket-viewer";

interface Props {
  showPopin: boolean;
  transactionId: string;
  onClose: Function;
  popinContent: string;
  onDownload: Function;
  onSend: Function;
  i18nTitleKey: string;
}

class ReceiptViewer extends Component<Props, {}> {
  _closeReceipt = () => {
    const { onClose } = this.props;
    onClose();
  };

  _printReceipt = () => {
    const iframeElement = this.iframeElement.iframe;
    const iframeWindow = iframeElement.contentWindow;
    iframeWindow.focus();
    iframeWindow.print();
  };

  render() {
    const {
      showPopin,
      i18nTitleKey,
      popinContent,
      onDownload,
      onSend
    } = this.props;

    return (
      <Modal
        show={showPopin}
        onHide={this._closeReceipt}
        animation={false}
        className="receiptModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <I18nSpan msgKey={i18nTitleKey} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="receiptBody">
            <Iframe
              id={iframeId}
              docContent={popinContent}
              ref={el => (this.iframeElement = el)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div role="toolbar">
            <button
              type="button"
              className="btn btn-icon btn-ingenico"
              onClick={this._printReceipt}
            >
              <span className="glyphicon glyphicon-print" aria-hidden="true" />
              <I18nSpan msgKey="reporting.transaction.receipts.modal.buttons.print" />
            </button>
            <button
              type="button"
              className="btn btn-icon btn-ingenico"
              onClick={onDownload}
            >
              <span
                className="glyphicon glyphicon-download"
                aria-hidden="true"
              />
              <I18nSpan msgKey="reporting.transaction.receipts.modal.buttons.download" />
            </button>
            <button
              type="button"
              className="btn btn-icon btn-ingenico"
              onClick={onSend}
            >
              <span className="glyphicon glyphicon-send" aria-hidden="true" />
              <I18nSpan msgKey="reporting.transaction.receipts.modal.buttons.send" />
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(ReceiptViewer);
