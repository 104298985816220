import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";

import styles from "./LogoFooter.css";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

const LogoFooter = ({ fullHeight, sizeHeight = 20 }: any) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        height: fullHeight ? "100%" : `calc(var(--vh, 1vh) * ${sizeHeight})`
      }
    })
  );

  const classes = useStyles();

  const matches = useMediaQuery(`(max-width: 768px)`);

  if (matches) {
    return (
      <Grid
        className={classNames(styles["header-container"], classes.root)}
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <img src={"/images/mobile/logo_ingenico.svg"} />
        </Grid>
      </Grid>
    );
  }
  return null;
};

export default withRouter(LogoFooter);
