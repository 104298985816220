import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDataGrid from "react-data-grid";
import { Data } from "react-data-grid-addons";
import _ from "lodash";
import { withTranslation } from "react-i18next";

const { Selectors } = Data;

interface Props {}

interface State {}

class DataGrid extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { t } = this.props;
    const columnsWithNames = _.map(this.props.columns, (columnName: string) => {
      const key = `${this.props.i18nKey}.header.${columnName}`;
      let column: any = {
        cellClass: columnName,
        key: columnName,
        name: t(key),
        sortable: true
      };
      if (this.props.formatters) {
        const columnFormatter = this.props.formatters[columnName];
        if (columnFormatter) {
          column.formatter = ({ value }) => {
            return <span>{columnFormatter(value)}</span>;
          };
        }
      }
      return column;
    });

    this.state = {
      rows: this.props.rows,
      expandedRows: {},
      columnsWithNames,
      selectedRows: []
    };
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      rows: _.cloneDeep(nextProps.rows)
    });
  }

  handleGridSort = (sortColumn, sortDirection) => {
    const comparer = function(a, b) {
      /* eslint-disable no-magic-numbers */
      if (sortDirection === "ASC") {
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      } else if (sortDirection === "DESC") {
        return a[sortColumn] < b[sortColumn] ? 1 : -1;
      }
      /* eslint-enable no-magic-numbers */
    };
    const rows =
      sortDirection === "NONE"
        ? this.props.rows
        : this.state.rows.sort(comparer);
    this.setState({ rows });
  };

  getRows() {
    const rows = Selectors.getRows({
      rows: this.state.rows,
      groupBy: this.props.groupBy ? [this.props.groupBy] : [],
      expandedRows: this.state.expandedRows
    });
    return rows;
  }

  _rowGetter = i => {
    return this.getRows()[i];
  };

  onRowExpandToggle = ({ columnGroupName, name, shouldExpand }) => {
    let expandedRows = Object.assign({}, this.state.expandedRows);
    expandedRows[columnGroupName] = Object.assign(
      {},
      expandedRows[columnGroupName]
    );
    expandedRows[columnGroupName][name] = { isExpanded: shouldExpand };
    this.setState({ expandedRows: expandedRows });
  };

  renderRowGroup = props => {
    const { t } = this.props;
    let treeDepth = props.treeDepth || 0;
    let marginLeft = treeDepth * 20;
    const key = `${this.props.i18nKey}.category.${props.name}`;
    const i18nCategory = t(key);

    return (
      <div
        onClick={props.onRowExpandClick}
        style={{ height: "100%", outline: "none" }}
      >
        <span
          className="row-expand-icon"
          style={{ float: "left", marginLeft: marginLeft, cursor: "pointer" }}
        >
          {props.isExpanded
            ? String.fromCharCode(9660)
            : String.fromCharCode(9658)}
        </span>
        <strong>{i18nCategory}</strong>
      </div>
    );
  };

  render() {
    const { onRowClick } = this.props;
    const { columnsWithNames } = this.state;

    const rowsCount = this.getRows().length;

    const rowHeight = 35;
    const borderHeight = 2;
    const height = (rowsCount + 1) * rowHeight + borderHeight;

    return (
      <ReactDataGrid
        columns={columnsWithNames}
        onGridSort={this.handleGridSort}
        onRowExpandToggle={this.onRowExpandToggle}
        rowsCount={rowsCount}
        rowGetter={this._rowGetter}
        rowGroupRenderer={this.renderRowGroup}
        onRowClick={onRowClick}
        minHeight={height}
      />
    );
  }
}

DataGrid.defaultProps = {
  rows: [],
  columns: [],
  i18nKey: "",
  onRowClick: () => undefined
};

DataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  i18nKey: PropTypes.string.isRequired,
  formatters: PropTypes.object,
  onRowClick: PropTypes.func
};

export default withTranslation()(DataGrid);
