import React, { Component } from "react";
import { connect } from "react-redux";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import AdvancedSearch from "../../advancedSearch/components/AdvancedSearch";

interface Props {
  tableCount: any;
  getCatalogItems: Function;
  fields: Array<any>;
  searchFilters: any;
}

interface State {
  tableCount: any;
}

class CatalogItemAdvancedSearch extends Component<Props, State> {
  render() {
    const {
      searchFilters,
      catalogFields = [],
      searchContext,
      onChange
    } = this.props;

    return (
      <div>
        <AdvancedSearch
          name={AdvancedSearchConstants.CATALOG_KEY}
          searchFilters={searchFilters}
          onChange={onChange}
          genericFields={catalogFields}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    genericFields: { data: fields },
    config: {
      data: { tableCount }
    }
  } = state;
  const searchFilters = fields
    ? fields.reduce((prevField: any, field: any) => {
        const { key, type, searchable } = field;

        if (searchable) {
          const tagsKey = /\b(selectedTags).([^.]+)/g.exec(key);
          prevField.push({
            i18nKey: `catalogItem.list.header.${key}`,
            rawLabel: tagsKey ? tagsKey[2] : null,
            name: key,
            type
          });
        }

        return prevField;
      }, [])
    : [];

  return { searchFilters, catalogFields: fields, tableCount };
};

export default connect(mapStateToProps, null)(CatalogItemAdvancedSearch);
