import _ from "lodash";
import i18next from "i18next";

import { Service } from "../model/Service";

export default {
  servicesToFormOptions(services: Array<Service>) {
    return _.map(services, service => ({
      id: service,
      name: i18next.t(`service.${service}`)
    }));
  },

  servicesToFormValues(services: Array<Service>) {
    return _.map(services, service => ({
      value: service,
      label: i18next.t(`service.${service}`)
    }));
  },

  i18nServices(services: Array<Service>) {
    return _.map(services, service => i18next.t(`service.${service}`));
  }
};
