import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import TagUtil from "../../tag/model/TagUtil";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import I18nSpan from "../../i18n/components/I18nSpan";
import TagInput from "../../ingenicoForm/components/TagInput";
import Validators from "../../ingenicoForm/validation/Validators";
import NumberFormatter from "../../formatters/NumberFormatter";
import {
  requiredMinLength,
  requiredFields,
  priceValidation
} from "../validations/CatalogItemValidations";
import {
  getTags,
  createCatalogItem,
  resetCatalogItemState,
  getVatRates,
  resetTagsState,
  addNotificationSuccess,
  addNotificationError,
  getMeasures
} from "../../redux/actions";
import constants from "../../connection/constants/ConnectionConstant";

interface Props {
  history: any;
  createCatalogItem: Function;
  getTags: Function;
  resetCatalogItemState: Function;
  getVatRates: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  options: any;
  currencyDecimal: any;
  getMeasures: Function;
}

interface State {
  form: any;
  errors: any;
}

class CreateCatalogItemView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  _onExit = e => {
    e.preventDefault();

    const { history } = this.props;

    history.push("/main/settings/catalog");
  };

  _goToList = () => {
    const { history } = this.props;

    history.push("/main/settings/catalog");
  };

  async componentDidMount() {
    const { getTags, getVatRates, merchantId, getMeasures } = this.props;

    await Promise.all([getTags(), getVatRates(merchantId), getMeasures()]);
  }

  componentWillReceiveProps(newProps) {
    const { form, errors } = newProps;

    return this.setState({
      form,
      errors
    });
  }

  componentWillUnmount() {
    const { resetCatalogItemState } = this.props;
    return resetCatalogItemState();
  }

  _saveCatalogItem = e => {
    e.preventDefault();

    const {
      createCatalogItem,
      addNotificationSuccess,
      addNotificationError,
      currencyDecimal
    } = this.props;
    const { form } = this.state;

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        const unformatedPrice = NumberFormatter.unformatAmount(
          form.price,
          currencyDecimal
        );

        const { url } = form;
        if (url !== undefined && url === "") delete form.url;
        const data = { ...form, price: unformatedPrice };

        try {
          await createCatalogItem(data);

          addNotificationSuccess("catalogItem.create.success");
          this._goToList();
        } catch (error) {
          const { errorKey } = await error;
          const notificationErrorKey = `notification.catalogItem.error.${errorKey}`;

          addNotificationError(notificationErrorKey);
        }
      }
    });
  };

  _onChangeHandler = (name: string, value: string) => {
    const { form } = this.state;
    const newForm = form;
    newForm[name] = value;

    return this.setState(newForm, this._checkError(name));
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _checkErrors(then) {
    const { form } = this.state;
    this._waitForValidation(_.keys(form), {}, then);
  }

  _checkError(name: string) {
    const { errors } = this.state;
    return then => {
      this._waitForValidation([name], errors, then);
    };
  }

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  _checkUrl = value => {
    return Validators.patternValidator(
      "(https|http)://((\\w)*|([0-9]*)|([-|_])*)+([\\.|/]((\\w)*|([0-9]*)|([-|_])*))+",
      "url"
    )(value);
  };

  render() {
    const { form, errors } = this.state;
    const { options, t } = this.props;

    const SaveButton = ({ onSave }) => (
      <button onClick={onSave} className="btn btn-ingenico save-button">
        <I18nSpan msgKey={"button.label.ok"} />
      </button>
    );
    return (
      <div>
        <FormTitle
          titleKey="catalogItem.title"
          actionKey="catalogItem.create.action"
        />
        <form className="ingenico-form form-horizontal create-catalogItem-form">
          <BootstrapInput
            validation={requiredMinLength(t(`catalogItem.form.name.label`))}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            errors={errors.name}
            required={true}
            descriptor={{
              type: "text",
              label: "catalogItem.form.name.label",
              placeholder: "catalogItem.form.name.placeholder"
            }}
            formValue={form.name}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("description")}
            name="description"
            errors={errors.description}
            required={false}
            descriptor={{
              type: "text",
              label: "catalogItem.form.description.label"
            }}
            formValue={form.description}
          />
          <BootstrapInput
            validation={this._checkUrl}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("url")}
            name="url"
            errors={errors.url}
            required={false}
            descriptor={{
              type: "url",
              label: "catalogItem.form.url.label",
              placeholder: "catalogItem.form.url.placeholder"
            }}
            formValue={form.url}
          />
          <BootstrapInput
            validation={requiredFields()}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("vatRate")}
            name="vatRate"
            errors={errors.vatRate}
            required={true}
            descriptor={{
              type: "singleautocomplete",
              label: "catalogItem.form.vatRate.label",
              options: options.vatRatesList
            }}
            formValue={form.vatRate}
          />
          <BootstrapInput
            validation={requiredFields()}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("measure")}
            name="measure"
            errors={errors.measure}
            required={true}
            descriptor={{
              type: "singleautocomplete",
              label: "catalogItem.form.measure.label",
              options: options.measuresList
            }}
            formValue={form.measure}
          />
          <BootstrapInput
            validation={priceValidation()}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("price")}
            name="price"
            errors={errors.price}
            required={true}
            descriptor={{
              type: "text",
              label: "catalogItem.form.price.label"
            }}
            formValue={form.price}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("gtin")}
            name="gtin"
            errors={errors.gtin}
            required={false}
            descriptor={{
              type: "number",
              label: "catalogItem.form.gtin.label"
            }}
            formValue={form.gtin}
          />
          {!_.isEmpty(options.tags) && (
            <div className="form-group">
              <label className="control-label col-sm-3">
                <span>Tags</span>
              </label>
              <TagInput
                key={"selectedTags.Tag"}
                name="selectedTags"
                tags={options.tags}
                formValue={form.selectedTags}
                onChange={this._onChangeHandler}
              />
            </div>
          )}
          <div className="pull-right">
            <SaveButton onSave={this._saveCatalogItem} />
            <button
              onClick={this._onExit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    tags: { data: tags },
    vatRates: { data: vatRates },
    auth: {
      user,
      levelSettings: { currency: { exponent: currencyDecimal } } = {
        currency: {
          exponent: 0
        }
      }
    },
    catalogItem: { measures }
  } = state;

  const {
    scope: {
      level: { type: levelType, id: merchantId }
    }
  } = user;

  const vatRatesKeys = Object.keys(vatRates);
  const vatRatesList = _.map(vatRatesKeys, key => ({
    label: key,
    value: key
  }));

  const measuresList = _.map(measures, value => ({
    label: value,
    value: value
  }));

  return {
    levelType,
    merchantId,
    form: {
      name: null,
      description: null,
      url: null,
      vatRate: null,
      measure: null,
      price: null,
      gtin: null,
      selectedTags: TagUtil.getSelectedTags(user)
    },
    options: {
      tags,
      measuresList,
      vatRatesList
    },
    errors: {},
    currencyDecimal
  };
};

const mapDispatchToProps = dispatch => ({
  createCatalogItem: (catalogItem: any) =>
    dispatch(createCatalogItem(catalogItem)),
  resetCatalogItemState: () => dispatch(resetCatalogItemState()),
  getVatRates: (merchantId: any) => dispatch(getVatRates(merchantId)),
  getTags: ({ tagType }) => dispatch(getTags({ tagType })),
  resetTags: ({ tagType }) => dispatch(resetTagsState({ tagType })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getMeasures: () => dispatch(getMeasures())
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { levelType } = stateProps;

  switch (levelType) {
    case constants.MERCHANT: {
      const tagType = constants.MERCHANT;
      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    default:
      break;
  }

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(CreateCatalogItemView);
