import F from "../../commons/HTTPFetcher";

import { constantCase } from "constant-case";

export const getTags = ({ tagType }) => async dispatch => {
  dispatch(getTagStart({ tagType }));

  const url = `/${tagType}/tag`;
  return await F.get(url)
    .then(tags => dispatch(getTagsSucceeded({ tags, tagType })))
    .catch(errors => {
      dispatch(getTagsFailure({ tagType, errors }));
      throw errors;
    });
};

const getTagStart = ({ tagType }) => {
  const name = constantCase(tagType);
  return {
    name,
    type: `PENDING_GET_${name}_TAGS_ACTION`
  };
};

const getTagsSucceeded = ({ tagType, tags }) => {
  const name = constantCase(tagType);
  return {
    name,
    type: `SUCCESS_GET_${name}_TAGS_ACTION`,
    payload: {
      tags
    }
  };
};

const getTagsFailure = ({ tagType, errors }) => {
  const name = constantCase(tagType);
  return {
    type: `FAILURE_GET_${name}_TAGS_ACTION`,
    payload: {
      errors
    }
  };
};

export const resetTagsState = ({ tagType }) => {
  const name = constantCase(tagType);
  return {
    type: `RESET_${name}_TAGS_STATE_ACTION`
  };
};
