import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import BootstrapInput from "../ingenicoForm/components/BootstrapInput";
import TimePeriod from "../reporting/models/TimePeriod";
import TimeSelector from "./TimeSelector";
import I18nSpan from "../i18n/components/I18nSpan";
import dateFormatter from "../formatters/DateFormatter";
import F from "../commons/HTTPFetcher";
import { addNotificationError } from "../redux/actions";

interface Props {
  enableExport: any;
  urls: any;
  title: string;
  optId: string;
  hideTimeSelector: boolean;
  hideDataTypeSelect: boolean;
  showPopin: boolean;
  filters: any;
  user: any;
  onClosePopin: Function;
  addNotificationError: Function;
}

interface State {
  dataType: any;
  options: any;
}

class ExportPopin extends Component<Props, State> {
  state = {
    timePeriod: TimePeriod.MONTH_TO_DATE,
    dataType: "pos-status",
    options: {
      "pos-status": "Pos Status",
      "sim-status": "Sim Status",
      "poi-connections": "Terminal Connections",
      "poi-status": "Terminal Status"
    }
  };

  _onTimeSelectorChange = ({ timePeriod }) => {
    this.setState({ timePeriod });
  };

  _onTypeDataChange = (name, value) => {
    this.setState({ dataType: value });
  };

  _export = url => event => {
    const {
      enableExport,
      filters: filtersData = [],
      optId,
      onClosePopin,
      addNotificationError,
      user
    } = this.props;
    const { timePeriod, dataType } = this.state;

    if (enableExport) {
      const beginTime = dateFormatter.getTimezonedDate(
        timePeriod.startTime,
        user
      );
      const endTime = dateFormatter.getTimezonedDate(timePeriod.endTime, user);
      const filters = JSON.stringify(filtersData);

      let finalUrl = url
        .replace(/:begin/, beginTime)
        .replace(/:end/, endTime)
        .replace(/:filters/, filters);

      if (optId) {
        finalUrl = finalUrl.replace(/:id/, optId);
      }

      if (dataType) {
        finalUrl = finalUrl.replace(/:dataType/, dataType);
      }

      return F.export(finalUrl);
    } else {
      addNotificationError(
        "monitoringPoiSim.terminalActivities.notification.export"
      );
    }
    if (_.isFunction(onClosePopin)) {
      onClosePopin();
    }
  };

  render() {
    const {
      showPopin,
      onClosePopin,
      hideTimeSelector,
      hideDataTypeSelect,
      title,
      urls,
      searchContext
    } = this.props;
    const { timePeriod, dataType, options } = this.state;

    return (
      <div>
        <div className={showPopin ? "popin" : "popin hidden"}>
          <div className="popinContent">
            <a onClick={onClosePopin} className="closeButton" />
            <div className="popinTitle">
              <I18nSpan msgKey={title} />
            </div>

            {hideTimeSelector ? (
              ""
            ) : (
              <TimeSelector
                searchContext={searchContext}
                onChange={this._onTimeSelectorChange}
                initialTimePeriod={timePeriod}
              />
            )}
            {hideDataTypeSelect ? (
              ""
            ) : (
              <div className="export-dataType">
                <BootstrapInput
                  ref="dataType"
                  name="dataType"
                  formValue={dataType}
                  onChange={this._onTypeDataChange}
                  descriptor={{
                    type: "select",
                    className: "serviceSet mtn",
                    options: options
                  }}
                />
              </div>
            )}
            <ul className="button-list">
              {_.map(urls, (format: any) => (
                <li key={format.label}>
                  <a
                    onClick={this._export(format.url)}
                    className="btn btn-ingenico"
                  >
                    <I18nSpan msgKey={format.label} />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user }
  } = state;

  return {
    user
  };
};

const mapDispatchToProps = dispatch => ({
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportPopin);
