import * as moment from "moment";
import { PeriodSelection } from "../../reporting/models/TimePeriod";

import { Dateformats } from "../../formatters/DateFormatter";

export const upperBoundToMonth = (period: PeriodSelection) => {
  if (period === PeriodSelection.INTRADAY) {
    return "Day";
  } else if (period === PeriodSelection.WEEK_TO_DATE) {
    return "Week";
  }

  return "Month";
};

export const upperBoundToMonthRanges = (
  periodType: PeriodSelection,
  timestamps?
) => {
  switch (periodType) {
    case PeriodSelection.INTRADAY: {
      return [
        {
          key: "yesterday",
          from: "now-1d/d",
          to: "now-1d"
        },
        {
          key: "today",
          from: "now/d",
          to: "now"
        }
      ];
    }
    case PeriodSelection.PREVIOUS_DAY: {
      return [
        {
          key: "yesterday",
          from: "now-1d/d",
          to: "now-1d"
        },
        {
          key: "yesterday",
          from: "now-1d/d",
          to: "now-1d"
        }
      ];
    }
    case PeriodSelection.WEEK_TO_DATE: {
      return [
        {
          key: "lastWeek",
          from: "now-1w/w",
          to: "now-1w"
        },
        {
          key: "thisWeek",
          from: "now/w",
          to: "now"
        }
      ];
    }
    case PeriodSelection.MONTH_TO_DATE: {
      return [
        {
          key: "lastMonth",
          from: "now-1M/M",
          to: "now-1M"
        },
        {
          key: "thisMonth",
          from: "now/M",
          to: "now"
        }
      ];
    }
    case PeriodSelection.YEAR_TO_DATE: {
      return [
        {
          key: "lastYear",
          from: "now-1y/y",
          to: "now-1y"
        },
        {
          key: "thisYear",
          from: "now/y",
          to: "now"
        }
      ];
    }
    case PeriodSelection.LAST_WEEK:
    case PeriodSelection.LAST_MONTH:
    case PeriodSelection.LAST_YEAR:
    case PeriodSelection.BETWEEN:
    case PeriodSelection.EXACT: {
      const from = moment(timestamps.min).format(
        Dateformats.formats.range.dateTime
      );
      const to = moment(timestamps.max).format(
        Dateformats.formats.range.dateTime
      );
      return [
        {
          from,
          to
        },
        {
          from,
          to
        }
      ];
    }
    default: {
      const from = moment(timestamps.min).format(
        Dateformats.formats.range.dateTime
      );
      const to = moment(timestamps.max).format(
        Dateformats.formats.range.dateTime
      );
      return [
        {
          from,
          to
        },
        {
          from,
          to
        }
      ];
    }
  }
};
