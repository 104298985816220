import _ from "lodash";

export default {
  merchantReceiptIsVisible(row, connectionStore, services) {
    return (
      row.merchantReceipt && this.canExportReceipt(connectionStore, services)
    );
  },

  cardholderReceiptIsVisible(row, connectionStore, services) {
    return (
      row.cardholderReceipt && this.canExportReceipt(connectionStore, services)
    );
  },

  canExportReceipt(connectionStore, services) {
    const { isCustomer, isMerchant } = connectionStore;

    return (
      (isCustomer || isMerchant) && _.includes(services, "transactionReceipt")
    );
  }
};
