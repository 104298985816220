import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import get from "get-value";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";

import { eventSourceListener } from "../../commons/EventSourceListener";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import I18nSpan from "../../i18n/components/I18nSpan";
import DataTable from "../../datatable/components/DataTable";
import StoreAdvancedSearch from "./StoreAdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import Counter from "../../datatable/components/Counter";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import UploadFile from "../../components/upload/UploadFile";
import { Refresh } from "../../datatable/components/Refresh";
import { setUrlWithParams } from "../../searchContext/URLizer";

import { SearchContextRedux } from "../../searchContext/SearchContext";

import {
  EditAction,
  DeleteAction,
  ToggleAction
} from "../../datatable/components/multipleActions";

import { getNamesFromSelection } from "../../redux/reducers/selection";

import { generateUrl } from "../../commons/utils/url";

import {
  getFields,
  getStoresList,
  getScroll,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  getCountries,
  deleteStores,
  updateStoresStatus,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetStores,
  deleteEvent,
  updateStatusEvent,
  addNotificationError,
  setSearchContext
} from "../../redux/actions";

import styles from "../styles/ListStoreView.css";

interface Props {
  loading: boolean;
  hasRestrictionByStores: any;
  isMerchant: any;
  color: any;
  isCustomer: any;
  storeNames: any;
  visibleAttributes: any;
  storeCount: any;
  storeScrollId: string;
  storeList: any;
  selectedStoreIds: any;
  selection: any;
  getFields: Function;
  getStoresList: Function;
  attributes: any;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  activationButtonStatus: Function;
  getCountries: Function;
  deleteStores: Function;
  updateStoresStatus: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  resetStores: Function;
  getStoresScroll: Function;
  deleteStore: Function;
  searchContext: SearchContextRedux;
  location: any;
  tableCount: any;
}

interface State {
  storeNames?: any;
  showDeleteConfirmation?: boolean;
  setSearchContext: Function;
}

const advancedSearchKey = AdvancedSearchConstants.STORE_KEY;

class ListStoreView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      showDeleteConfirmation: false
    };
    this.setUrlParams({ searchContext });
  }

  formatter = {
    "store.selectedTags": selectedTags => _.values(selectedTags).join(", "),
    "merchant.selectedTags": selectedTags => _.values(selectedTags).join(", ")
  };

  evtSource = { close: () => undefined } as EventSource;
  async componentDidMount() {
    const {
      getFields,
      getCountries,
      searchContext,
      eventGatewayURL
    } = this.props;
    const name = "stores";

    await getFields({ name });
    await getCountries();

    await this._onRefresh({ searchContext });

    eventSourceListener({
      type: advancedSearchKey,
      action: this._handleUpdateStatus,
      evtSourceContainer: this,
      callbackWithParams: true,
      eventGatewayURL
    });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _changeStatus({ data, id }) {
    const { updateStoresStatusView } = this.props;
    const { status } = data;

    return updateStoresStatusView({ ids: [id], status, sectionType: "stores" });
  }

  _handleDelete({ id }) {
    const { deleteStore } = this.props;

    return deleteStore({ id, sectionType: "store" });
  }

  _handleUpdateStatus = event => {
    const { id, eventType, data } = event;

    switch (eventType) {
      case "statusUpdated": {
        return this._changeStatus({ data, id });
      }
      case "deleted": {
        return this._handleDelete({ id });
      }
    }
  };

  componentWillUnmount() {
    const { resetStores } = this.props;

    resetStores();
    this.evtSource.close();
  }

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getStoresList } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getStoresList({ filters, fields: [], sort, tableCount });
  };

  _search = ({ searchContext }) => {
    const {
      getStoresList,
      tableCount,
      setSearchContext,
      location: { pathname }
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    getStoresList({ filters, sort, tableCount });
  };

  _updateStatus = async ({ activationButtonStatus }) => {
    const {
      selectedStoreIds: ids,
      updateStoresStatus,
      addNotificationError
    } = this.props;
    const status =
      activationButtonStatus === "Suspended" ? "Activated" : "Suspended";

    try {
      await updateStoresStatus({ ids, status });
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      return addNotificationError(errorKey);
    }
  };

  _showDeletePopin = () => {
    const { selection } = this.props;
    const storeNames = getNamesFromSelection({
      selection,
      keyName: "store.name"
    });

    return this.setState({
      showDeleteConfirmation: true,
      storeNames
    });
  };

  _closeDeletePopin = () => {
    this.setState({
      showDeleteConfirmation: false
    });
  };

  _deleteStore = async () => {
    const {
      selectedStoreIds: ids,
      deleteStores,
      addNotificationError
    } = this.props;

    try {
      await deleteStores({ ids });

      return this._closeDeletePopin();
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      addNotificationError(errorKey);

      return this._closeDeletePopin();
    }
  };

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  onSelectRow = (rowId, value) => {
    const { selectedStoreIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedStoreIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  _loadMoreRows = () => {
    const { getStoresScroll, storeScrollId } = this.props;

    return getStoresScroll({ scrollId: storeScrollId });
  };

  render() {
    const {
      hasRestrictionByStores,
      isMerchant,
      color,
      isCustomer,
      visibleAttributes,
      storeCount,
      storeList,
      selectedStoreIds,
      attributes,
      loading,
      sortDescription,
      searchContext,
      hasMerchantManagement
    } = this.props;

    const { showDeleteConfirmation, storeNames } = this.state;

    const activationButtonStatus = handleToggleActivationButtonStatus({
      selectionIds: selectedStoreIds,
      stores: storeList
    });

    const EditStore = () => {
      if (
        selectedStoreIds.length === 1 &&
        !(isCustomer && !hasMerchantManagement)
      ) {
        const [storeId] = selectedStoreIds;
        const route = `/main/settings/store/edit-store/${storeId}`;

        return <EditAction route={route} msgKey={"store.edit.action"} />;
      }

      return null;
    };

    const DeleteStore = () => <DeleteAction onDelete={this._showDeletePopin} />;

    const ToggleActivationButton = () => (
      <ToggleAction
        onToggle={() => this._updateStatus({ activationButtonStatus })}
        msgKey={`store.toggle.${activationButtonStatus}`}
      />
    );

    const ImportStores = () => {
      if (isCustomer) {
        return (
          <UploadFile
            uploadUrl={"/store/import"}
            templateActions={[
              generateUrl({
                url: "/store/import/template"
              })
            ]}
            fileKey="stores"
            tooltip="store.import.template"
            buttonsTitles={{
              upload: "store.import.upload",
              templates: "store.import.template"
            }}
            notifications={{
              success: "store.import.uploadFile.success",
              error: "store.import.uploadFile.error"
            }}
          />
        );
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle titleKey="store.title" actionKey="store.list.action" />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={storeCount}
                loadingKey="store.list.loading"
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>

          <div className={styles.edition}>
            {(isMerchant && hasRestrictionByStores) ||
            (isCustomer && !hasMerchantManagement) ? null : (
              <div>
                <Link
                  className={classNames(
                    "btn",
                    "btn-ingenico",
                    "create-button",
                    styles["store-action"]
                  )}
                  to="/main/settings/store/new-store"
                >
                  <span
                    className="glyphicon glyphicon-plus"
                    aria-hidden="true"
                  />
                  <I18nSpan msgKey="store.list.button.create" />
                </Link>
              </div>
            )}

            <ImportStores />
          </div>
        </div>

        <StoreAdvancedSearch
          searchContext={searchContext}
          onChange={this._search}
        />

        {showDeleteConfirmation && (
          <ConfirmationPopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={this._deleteStore}
            objectName={storeNames}
          />
        )}

        <DataTable
          data={storeList}
          notSortableFields={["store.selectedTags", "merchant.selectedTags"]}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          formatter={this.formatter}
          i18nKey="store.list"
          idAttribute="store.id"
          hideSearchBox={true}
          toggleable={true}
          color={color}
          hasActions={false}
          disableStatusValues={["Suspended"]}
          onRefresh={this._search}
          onUpdateColumns={this._updateColumns}
          selectableNew={true}
          selectionButtonKey={[EditStore, ToggleActivationButton, DeleteStore]}
          version="new"
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedStoreIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={storeCount}
          sortKey={advancedSearchKey}
          searchContext={searchContext}
          sortDescription={sortDescription}
        />
      </div>
    );
  }
}

const handleToggleActivationButtonStatus = ({ selectionIds, stores }) => {
  const selection = stores.reduce((prevSelectedStore, selectedStore) => {
    const selectedStoreId = selectedStore["store.id"];

    if (selectionIds.includes(selectedStoreId)) {
      return {
        ...prevSelectedStore,
        [selectedStoreId]: selectedStore
      };
    }

    return prevSelectedStore;
  }, {});

  const isAllActivated = selectionIds.every(storeId => {
    const { [storeId]: selected } = selection;

    return selected && selection[storeId]["store.status"] === "Activated";
  });

  return isAllActivated ? "Activated" : "Suspended";
};

const storesWithStatus = ({ state }) => {
  const {
    countries: { data: countries },
    stores: { dataList, count, scrollId },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const stores = dataList.map(store => {
    const normalizedStore = fields.reduce((prevField, field) => {
      if (field === "store.country") {
        const storeCountry = countries.find(
          country => country.code === get(store, field)
        );

        return {
          ...prevField,
          [field]: storeCountry ? storeCountry.display : ""
        };
      }

      return {
        ...prevField,
        [field]: get(store, field)
      };
    }, {});

    return {
      ...normalizedStore
    };
  });

  return {
    count,
    scrollId,
    stores
  };
};

interface GetColumnsParams {
  type: string;
  fields: Array<string>;
}

const _getColumns = ({ type, fields }: GetColumnsParams) => {
  return fields.reduce((prevField: Array<string>, field: any) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isCustomer, isMerchant },
    genericFields: { data: fields },
    stores: { selection, loading },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount, eventGatewayURL }
    }
  } = state;

  const {
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    }
  } = state;
  const {
    searchContext: { data: searchContext }
  } = state;

  const {
    stores: storeList,
    count: storeCount,
    scrollId: storeScrollId
  } = storesWithStatus({
    state
  });

  const selectedStoreIds = _.keys(_.pick(selection, _.identity));

  let hasRestrictionByStores = false;

  if (user) {
    hasRestrictionByStores = "restrictedByIds" in user.scope.access || false;
  }

  const columnSetting = user ? user.columnSetting : false;

  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const attributes = _getColumns({ fields });

  const { permissions } = user;
  const hasMerchantManagement = _.includes(permissions, "merchantManagement");

  return {
    loading,
    attributes,
    hasRestrictionByStores,
    isMerchant,
    color,
    storeCount,
    storeScrollId,
    storeList,
    selectedStoreIds,
    selection,
    tableCount,
    isCustomer,
    visibleAttributes,
    sortDescription,
    eventGatewayURL,
    searchContext,
    hasMerchantManagement
  };
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting && columnSetting.store && columnSetting.store.length > 0
    ? columnSetting.store
    : _getColumns({ fields });
};

const mapDispatchToProps = dispatch => ({
  resetStores: () => dispatch(resetStores()),
  getStoresList: ({ filters, fields, sort, tableCount }) =>
    dispatch(getStoresList({ filters, fields, sort, tableCount })),
  getStoresScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "stores", scrollId })),
  getFields: ({ name }) => dispatch(getFields({ name })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "STORES" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "STORES" })),
  toggleSelectAll: () => dispatch(toggleSelectAll({ selectionType: "STORES" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "STORES" })),
  getCountries: () => dispatch(getCountries()),
  deleteStores: ({ ids }) => dispatch(deleteStores({ ids })),
  updateStoresStatus: ({ ids, status }) =>
    dispatch(updateStoresStatus({ ids, status })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  updateStoresStatusView: ({ ids, status, sectionType }) =>
    dispatch(updateStatusEvent({ ids, status, sectionType })),
  deleteStore: ({ id, sectionType }) =>
    dispatch(deleteEvent({ id, sectionType })),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ListStoreView);
