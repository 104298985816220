import { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import constants from "../../connection/constants/ConnectionConstant";

import {
  authenticate,
  getUserSettings,
  getLevelSettings
} from "../../redux/actions";

class ToOauthPage extends Component<any, any> {
  async componentDidMount() {
    try {
      const {
        authenticate,
        getUserSettings,
        getLevelSettings,
        match: {
          params: { codeValue: code }
        },
        redirectUri: redirect_uri,
        oauthState,
        clientId: client_id
      } = this.props;

      await authenticate({
        code,
        redirect_uri,
        client_id
      });
      const {
        scope: {
          level: { type: userLevelType }
        }
      } = await getUserSettings();
      const isIngenico = userLevelType === constants.INGENICO;

      if (!isIngenico) {
        await getLevelSettings();
      }

      window.location.assign(
        `${redirect_uri}${decodeURIComponent(oauthState)}`
      );
    } catch (error) {
      throw new Error(`to MSH homepage error: ${error}`);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      oauth: { redirectUri, oauthState, clientId }
    }
  } = state;

  return {
    redirectUri,
    oauthState,
    clientId
  };
};

const mapDispatchToProps = dispatch => ({
  authenticate: ({ code, redirect_uri, client_id }) =>
    dispatch(authenticate({ code, redirect_uri, client_id })),
  getUserSettings: () => dispatch(getUserSettings()),
  getLevelSettings: () => dispatch(getLevelSettings())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ToOauthPage)
);
