import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "get-value";
import _ from "lodash";
import { compose } from "redux";
import * as moment from "moment";
import { withTranslation } from "react-i18next";
import F from "./../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import TaxAuditTimeSelector from "./TaxAuditTimeSelector";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import DataTable from "../../datatable/components/DataTable";
import TaxAuditTypes from "../constants/TaxAuditTypes";
import {
  downloadSuccess,
  downloadFailure,
  downloadInfo
} from "./TaxAuditNotifications";
import TaxAuditAdvancedSearch from "./TaxAuditAdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import Counter from "../../datatable/components/Counter";
import { Refresh } from "../../datatable/components/Refresh";
import { setUrlWithParams } from "../../searchContext/URLizer";

import { SearchContextRedux } from "../../searchContext/SearchContext";
import { DownloadAction } from "../../datatable/components/multipleActions";

import {
  getFields,
  getScroll,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  addNotificationError,
  addNotificationInfo,
  addNotificationSuccess,
  setSearchContext,
  getTaxAudits,
  resetTaxAudits
} from "../../redux/actions";

import styles from "../styles/ListAuditView.css";
import selectorStyles from "./styles/TaxAudit.css";

interface Props {
  loading: boolean;
  hasRestrictionByStores: any;
  isMerchant: any;
  color: any;
  isCustomer: any;
  storeNames: any;
  visibleAttributes: any;
  auditCount: any;
  scrollId: string;
  auditList: any;
  selectedIds: any;
  selection: any;
  getFields: Function;
  attributes: any;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  activationButtonStatus: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  resetStores: Function;
  getScroll: Function;
  searchContext: SearchContextRedux;
  location: any;
  tableCount: any;
  getTaxAudits: Function;
  resetTaxAudits: Function;
  downloadBatchFile: Function;
  addNotificationInfo: Function;
  addNotificationError: Function;
  addNotificationSuccess: Function;
}

interface State {
  typeOptions: Array<String>;
  form: Object;
}

const advancedSearchKey = AdvancedSearchConstants.TAX_AUDIT;
const FIELDS_TAX_AUDIT_ENDPOINT_PREFIX: String = "tax-audit";
const REDUX_SELECTOR: String = "taxAudits";

class TaxAuditView extends Component<Props, State> {
  state = {
    typeOptions: [],
    form: {
      name: "",
      criteria: null,
      timePeriod: null,
      timestamps: {}
    }
  };

  formatter = {
    "report.selectedTags": selectedTags => _.values(selectedTags).join(", "),
    "merchant.selectedTags": selectedTags => _.values(selectedTags).join(", "),
    "report.tag": (tag, { ["report.periodType"]: type }) =>
      moment(tag, "YYYYMMDD")
        .format(
          `${
            type !== TaxAuditTypes.ANNUAL
              ? type === TaxAuditTypes.MONTHLY
                ? "MM/"
                : "MM/DD/"
              : ""
          }YYYY`
        )
        .toString(),
    "report.periodType": type => _.startCase(type.toLowerCase())
  };

  async componentDidMount() {
    const { getFields, searchContext, initSearchContextFilter } = this.props;
    await getFields({ name: FIELDS_TAX_AUDIT_ENDPOINT_PREFIX });
    await this._onRefresh({
      searchContext: {
        ...searchContext,
        filtersByAdvancedSearchKey: {
          [advancedSearchKey]: [
            initSearchContextFilter
              ? initSearchContextFilter
              : {
                  filterType: "string",
                  name: "report.periodType",
                  operator: "=",
                  value: TaxAuditTypes.DAILY
                }
          ]
        }
      }
    });
    this.setUrlParams({ searchContext });

    this.setState({
      form: {
        criteria: initSearchContextFilter
          ? initSearchContextFilter.value
          : TaxAuditTypes.DAILY
      }
    });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  componentWillUnmount() {
    const { resetTaxAudits } = this.props;
    resetTaxAudits();
  }

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getTaxAudits } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      timePeriod,
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getTaxAudits({ filters, fields: [], sort, tableCount, timePeriod });
  };

  _onCriteriaChange = (inputName: string, criteria: string) => {
    let newFilters = [];
    const {
      searchContext: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        timePeriod = {}
      }
    } = this.props;

    if (inputName === "report.periodType") {
      newFilters = filters.filter(ele => ele.name !== "report.periodType");
      if (criteria) {
        newFilters.push({
          filterType: "string",
          name: "report.periodType",
          operator: "=",
          value: criteria
        });
      }
    }

    this.setState(
      state => {
        return {
          form: {
            ...state.form,
            criteria
          }
        };
      },
      () =>
        this._search({
          searchContext: {
            key: advancedSearchKey,
            filters: newFilters,
            timePeriod
          }
        })
    );
  };

  _search = ({ searchContext }) => {
    const {
      getTaxAudits,
      tableCount,
      setSearchContext,
      location: { pathname }
    } = this.props;
    const { sort, filters, timePeriod } = searchContext;
    setSearchContext({ context: searchContext, pathname, updateUrl: true });
    getTaxAudits({ filters, sort, tableCount, timePeriod });
  };

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  onSelectRow = (rowId, value) => {
    const { selectedIds, toggleUnSelect, toggleSelect } = this.props;
    if (selectedIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }
    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;
    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  _loadMoreRows = () => {
    const { getScroll, scrollId } = this.props;

    return getScroll({ scrollId });
  };

  initBatchDownload = async (body, cb) => {
    const { isMerchant, entityId } = this.props;
    try {
      const url = new URL(
        `/pub/fiscal/report/bulk?${
          isMerchant ? "merchantId" : "customerId"
        }=${entityId}`,
        PORTAL_URL
      );
      const blob = await F.post(
        `${url.pathname}?${url.searchParams}`,
        {
          body,
          contentType: ContentTypes.JSON
        },
        "v1"
      );

      cb(null, blob);
    } catch (err) {
      cb(err);
    }
  };

  _initFileDownload = ({ link = "", blob, name = "data.zip" }) => {
    const linkEl = document.createElement("a");
    linkEl.setAttribute("href", blob ? blob : link);
    linkEl.setAttribute("download", name);
    document.body.append(linkEl);
    linkEl.click();
    linkEl.remove();
  };

  initFileDownload = async (
    {
      ["report.periodType"]: period,
      ["merchant.id"]: merchantId,
      ["report.tag"]: tag
    },
    cb
  ) => {
    try {
      const url = new URL(
        `/pub/fiscal/report/${period}/${merchantId}/${tag}`,
        PORTAL_URL
      );

      const blob = await F.get(`${url.pathname}`, "v1");

      cb(null, blob);
    } catch (err) {
      cb(err);
    }
  };

  render() {
    const {
      color,
      isCustomer,
      visibleAttributes,
      auditCount,
      auditList,
      selectedIds,
      attributes,
      loading,
      sortDescription,
      searchContext,
      addNotificationSuccess,
      addNotificationInfo,
      addNotificationError,
      typeOptions,
      getMessage
    } = this.props;

    const DownloadTaxAudits = ({ selectionLength }) => {
      return selectionLength > 1 ? (
        <DownloadAction
          onClick={() => {
            try {
              const { selection, auditList, isMerchant, entityId } = this.props;
              const reportList = Object.keys(selection)
                .filter(key => selection[key])
                .map(key => {
                  const [report] = auditList.filter(
                    ({ ["report.id"]: id }) => id === key
                  );
                  return {
                    merchantId: isMerchant ? entityId : report["merchant.id"],
                    periodType: report["report.periodType"],
                    tag: report["report.tag"]
                  };
                });
              addNotificationInfo(downloadInfo);
              this.initBatchDownload(
                { "requested-reports-list": reportList },
                (err, blob) => {
                  if (err) {
                    addNotificationError(downloadFailure);
                    return;
                  }
                  addNotificationSuccess(downloadSuccess);
                  this._initFileDownload({
                    blob,
                    name: `report-bulk.zip`
                  });
                }
              );
            } catch (err) {
              addNotificationError(downloadFailure);
              throw err;
            }
          }}
          msgKey={`reporting.taxAudit.download.action`}
        />
      ) : null;
    };

    const downloadAudit = audit => (
      <DownloadAction
        block={false}
        className="download--small"
        onClick={() => {
          const { isMerchant, entityId } = this.props;
          addNotificationInfo(downloadInfo);
          this.initFileDownload(
            { ...audit, ...(isMerchant && { ["merchant.id"]: entityId }) },
            (err, blob) => {
              if (err) {
                addNotificationError(downloadFailure);
                return;
              }
              let {
                ["report.periodType"]: period,
                ["merchant.id"]: merchantId,
                ["report.tag"]: tag
              } = audit;
              addNotificationSuccess(downloadSuccess);
              this._initFileDownload({
                blob,
                name: `report-${period}-${
                  isMerchant ? entityId : merchantId
                }-${tag}.zip`
              });
            }
          );
        }}
        msgKey={`reporting.taxAudit.download.action`}
      />
    );
    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle
              titleKey="reporting.mainTitle"
              actionKey="reporting.taxAudit.mainTitle"
            />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={auditCount || "0"}
                loadingKey="reporting.taxAudit.list.loading"
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>

          <div className={styles.edition}></div>
        </div>

        <div className={selectorStyles["filters"]}>
          <div className={selectorStyles["type"]}>
            <BootstrapInput
              ref="type"
              id="taxAuditTypeSelector"
              name="report.periodType"
              onChange={this._onCriteriaChange}
              descriptor={{
                type: "select",
                labelClass: `control-label col-sm-1 ${selectorStyles.criteria}`,
                inputClass: "col-sm-12",
                options: typeOptions
              }}
              formValue={this.state.form.criteria}
            />
          </div>

          <div className={selectorStyles["time"]}>
            <TaxAuditTimeSelector
              onGetMessage={getMessage}
              onChange={({ searchContext }) => this._search({ searchContext })}
              searchContext={searchContext}
            />
          </div>
        </div>

        {isCustomer && (
          <TaxAuditAdvancedSearch
            searchContext={searchContext}
            onChange={this._search}
          />
        )}
        <DataTable
          data={auditList}
          actionsElement={[downloadAudit]}
          notSortableFields={["report.selectedTags", "merchant.selectedTags"]}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          formatter={this.formatter}
          i18nKey="reporting.taxAudit.list"
          idAttribute="report.id"
          hideSearchBox={true}
          toggleable={true}
          color={color}
          hasActions={true}
          onRefresh={this._search}
          onUpdateColumns={this._updateColumns}
          selectableNew={true}
          selectionButtonKey={[DownloadTaxAudits]}
          version="new"
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={auditCount}
          sortKey={advancedSearchKey}
          searchContext={searchContext}
          sortDescription={sortDescription}
        />
      </div>
    );
  }
}

const storesWithStatus = ({ state }) => {
  const {
    taxAudits: { data, count, scrollId },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const audits = data.map(audit => {
    const normalized = fields.reduce((prevField, field) => {
      return {
        ...prevField,
        [field]: get(audit, field)
      };
    }, {});

    return {
      "report.id": `${get(audit, "report.id")}`,
      ...normalized
    };
  });

  return {
    count,
    scrollId,
    audits
  };
};

interface GetColumnsParams {
  type: string;
  fields: Array<string>;
}

const _getColumns = ({ type, fields }: GetColumnsParams) => {
  return fields.reduce((prevField: Array<string>, field: any) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);
};

const mapTypeOptions = ({ $t }) => {
  return {
    [TaxAuditTypes.DAILY]: $t("reporting.taxAudit.types.daily"),
    [TaxAuditTypes.MONTHLY]: $t("reporting.taxAudit.types.monthly"),
    [TaxAuditTypes.ANNUAL]: $t("reporting.taxAudit.types.annual")
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isCustomer, isMerchant },
    genericFields: { data: fields },
    taxAudits: { selection, loading },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount }
    }
  } = state;

  const {
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {},
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] }
      }
    }
  } = state;
  const {
    searchContext: { data: searchContext }
  } = state;

  const {
    audits: auditList,
    count: auditCount,
    scrollId: scrollId
  } = storesWithStatus({
    state
  });
  const selectedIds = _.keys(_.pick(selection, _.identity));

  let hasRestrictionByStores = false;

  if (user) {
    hasRestrictionByStores = "restrictedByIds" in user.scope.access || false;
  }

  let initSearchContextFilter;

  if (filters.length) {
    initSearchContextFilter = filters[0];
  }

  const columnSetting = {
    audit: ["merchant.name", "audit.name", "audit.type"].filter(column => {
      const blacklisted = ["merchant.name", "merchant.id"];
      if (isMerchant && blacklisted.includes(column)) return false;
      return true;
    })
  };

  const typeOptions = mapTypeOptions({
    $t: ownProps.t
  });

  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const attributes = _getColumns({ fields });

  return {
    loading,
    attributes,
    hasRestrictionByStores,
    isMerchant,
    color,
    auditCount,
    scrollId,
    auditList,
    selectedIds,
    selection,
    tableCount,
    isCustomer,
    visibleAttributes,
    sortDescription,
    searchContext,
    typeOptions,
    entityId: user.scope.level.id,
    initSearchContextFilter
    // getMessage
  };
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting && columnSetting.audit && columnSetting.audit.length > 0
    ? columnSetting.audit
    : _getColumns({ fields });
};

const mapDispatchToProps = dispatch => ({
  getTaxAudits: ({ filters, fields, sort, tableCount, timePeriod }) =>
    dispatch(getTaxAudits({ filters, fields, sort, tableCount, timePeriod })),
  resetTaxAudits: () => dispatch(resetTaxAudits()),
  getScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "tax-audit", scrollId })),
  getFields: ({ name }) => dispatch(getFields({ name, version: "v2" })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: REDUX_SELECTOR })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: REDUX_SELECTOR })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: REDUX_SELECTOR })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: REDUX_SELECTOR })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  addNotificationInfo: (notification, args) =>
    dispatch(addNotificationInfo(notification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  addNotificationSuccess: (notification, args) =>
    dispatch(addNotificationSuccess(notification, args)),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TaxAuditView);
