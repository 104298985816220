import React, { Component } from "react";
import * as moment from "moment";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";

import DataTable from "../../datatable/components/DataTable";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import I18nSpan from "../../i18n/components/I18nSpan";
import Permissions from "../../user/constants/Permissions";

import {
  addNotificationError,
  addNotificationSuccess,
  getScheduledReports,
  updateStatusScheduledReport,
  deleteScheduledReport,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../redux/actions";

import {
  EditAction,
  DeleteAction,
  ToggleAction
} from "../../datatable/components/multipleActions";
import { getNamesFromSelection } from "../../redux/reducers/selection";

import styles from "../styles/styles.css";

interface Props {
  getScheduledReports: Function;
  updateStatusScheduledReport: Function;
  deleteScheduledReport: Function;
  addNotificationError: Function;
  addNotificationSuccess: Function;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  color: any;
  history: any;
  scheduledList: Array<any>;
  selection: any;
  selectedScheduledIds: any;
}

interface State {
  scheduledId: any;
  showPopinSingle: boolean;
  scheduledName: any;
}

class ListScheduledView extends Component<Props, State> {
  state = {
    showPopinSingle: false
  };

  componentDidMount() {
    const { getScheduledReports } = this.props;

    return getScheduledReports();
  }

  _updateStatus = status => {
    const {
      updateStatusScheduledReport,
      addNotificationSuccess,
      addNotificationError,
      selectedScheduledIds
    } = this.props;
    const newStatus = status ? false : true;
    const [id] = selectedScheduledIds;

    const message = status
      ? "scheduled.edit.success"
      : "scheduled.cancel.success";

    return updateStatusScheduledReport({
      id,
      status: newStatus
    })
      .then(() => addNotificationSuccess(message))
      .catch(() => addNotificationError("notification.error"));
  };

  _cancelScheduled = () => {
    const {
      addNotificationSuccess,
      addNotificationError,
      deleteScheduledReport,
      selectedScheduledIds
    } = this.props;
    const [id] = selectedScheduledIds;
    if (id == "") return undefined;

    return deleteScheduledReport({
      id
    })
      .then(() => {
        this._closePopinSingle();
        addNotificationSuccess("scheduled.cancel.success");
      })
      .catch(() => {
        this._closePopinSingle();
        addNotificationError("scheduled.cancel.error");
      });
  };

  _openPopinConfirmDelete = () => {
    const { selection } = this.props;
    const scheduledName = getNamesFromSelection({
      selection,
      keyName: "name"
    });

    this.setState({
      showPopinSingle: true,
      scheduledName
    });
  };

  _closePopinSingle = () => {
    this.setState({
      showPopinSingle: false
    });
  };

  onSelectRow = (rowId, value) => {
    const { selectedScheduledIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedScheduledIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const {
      scheduledList,
      color,
      t,
      selectedScheduledIds,
      hasUserManagement
    } = this.props;
    const { scheduledName, showPopinSingle } = this.state;

    const formatter = {
      frequency: frequencyCode => t(`scheduled.frequency.${frequencyCode}`),
      triggerTime: triggerTime =>
        moment
          .utc(moment.duration(triggerTime).asMilliseconds())
          .format("HH:mm")
    };

    const EditScheduled = () => {
      if (selectedScheduledIds.length === 1 && hasUserManagement) {
        const [scheduledId] = selectedScheduledIds;

        const route = `/main/reporting/scheduled/edit/${scheduledId}`;

        return <EditAction route={route} msgKey={"scheduled.edit.action"} />;
      }

      return null;
    };

    const DeleteScheduled = () => {
      if (selectedScheduledIds.length === 1) {
        return <DeleteAction onDelete={this._openPopinConfirmDelete} />;
      }

      return null;
    };

    const toggleActivationButton = () => {
      const [scheduledId] = selectedScheduledIds;
      const selectedScheduled = scheduledList.filter(
        scheduled => scheduledId === scheduled.id
      );
      const [selected = {}] = selectedScheduled;
      const { active: status } = selected;

      if (selectedScheduledIds.length === 1) {
        return (
          <ToggleAction
            onToggle={() => this._updateStatus(status)}
            msgKey={`alert.list.${status ? "deactivate" : "activate"}`}
          />
        );
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <FormTitle
            color={color}
            titleKey="scheduled.title"
            actionKey="scheduled.list.action"
          />
          <div className={styles.creation}>
            <Link
              className={classNames("btn", "btn-ingenico", "create-button")}
              to={"/main/reporting/scheduled/create"}
            >
              <span className="glyphicon glyphicon-plus" aria-hidden="true" />
              <I18nSpan msgKey="scheduled.list.button.create" />
            </Link>
          </div>
        </div>

        {showPopinSingle && (
          <ConfirmationPopin
            onClosePopin={this._closePopinSingle}
            onClickButton={this._cancelScheduled}
            objectName={scheduledName}
          />
        )}

        <DataTable
          data={scheduledList}
          attributes={["name", "frequency", "triggerTime"]}
          formatter={formatter}
          i18nKey="scheduled.list"
          idAttribute="id"
          color={color}
          actionsElement={[]}
          isRowEditable={() => true}
          hasActions={false}
          disableStatusValues={[false]}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedScheduledIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[
            EditScheduled,
            DeleteScheduled,
            toggleActivationButton
          ]}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getScheduledReports: () => dispatch(getScheduledReports()),
  updateStatusScheduledReport: ({ id, status }) =>
    dispatch(updateStatusScheduledReport({ id, status })),
  deleteScheduledReport: ({ id }) => dispatch(deleteScheduledReport({ id })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "SCHEDULED_REPORTS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "SCHEDULED_REPORTS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "SCHEDULED_REPORTS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "SCHEDULED_REPORTS" }))
});

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    scheduledReports: { data: scheduledList, selection },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  const { permissions } = user;

  const hasUserManagement = _.includes(
    permissions,
    Permissions.USER_MANAGEMENT
  );

  const { t } = ownProps;
  const selectedScheduledIds = _.keys(_.pick(selection, _.identity));

  return {
    scheduledList,
    selectedScheduledIds,
    hasUserManagement,
    selection,
    color,
    labels: {
      activate: t("scheduled.list.activate"),
      deactivate: t("scheduled.list.deactivate")
    }
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ListScheduledView);
