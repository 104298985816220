import React, { Component } from "react";
import * as $ from "jquery";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import I18nSpan from "../../i18n/components/I18nSpan";

import {
  getAlerts,
  editAlert,
  updateAlertStatus,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../redux/actions";

import {
  EditAlertAction,
  ToggleAction
} from "../../datatable/components/multipleActions";

interface Props {
  getAlerts: Function;
  editAlert: Function;
  updateAlertStatus: Function;
  terminalAlerts: any;
  attributes: any;
}
interface State {
  currentAlert: any;
  showPopin: boolean;
}

class ListTerminalAlertView extends Component<Props, State> {
  state = {
    showPopin: false
  };

  async componentDidMount() {
    const { getAlerts } = this.props;
    await getAlerts();

    $(document.body).on("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    $(document.body).off("keydown", this.handleKeyDown);
  }

  handleKeyDown(event) {
    if (event.keyCode === 27 /*enter*/) {
      //eslint-disable-line no-magic-numbers
      this._closePopin();
    }
  }

  _updateStatus = async status => {
    const {
      selectedTerminalAlertIds,
      updateAlertStatus,
      addNotificationError
    } = this.props;
    const newStatus = status ? false : true;
    const [id] = selectedTerminalAlertIds;
    try {
      await updateAlertStatus({ id, status: newStatus });
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      return addNotificationError(errorKey);
    }
  };

  _openPopin = () => {
    const { selectedTerminalAlertIds, terminalAlerts } = this.props;
    const [alertId] = selectedTerminalAlertIds;
    const selectedTerminalAlert = terminalAlerts.filter(
      terminalAlert => alertId === terminalAlert.id
    );
    const [selected = {}] = selectedTerminalAlert;
    const { threshold } = selected;

    this.refs.threshold.value = threshold.value;
    this.setState({
      showPopin: true,
      currentAlert: selected
    });
  };

  _closePopin = () => {
    this.setState({ showPopin: false });
  };

  _updateThreshold = () => {
    const { editAlert, getAlerts } = this.props;
    const {
      currentAlert: { id }
    } = this.state;
    const {
      threshold: { value: threshold }
    } = this.refs;
    editAlert({ id, threshold }).then(() => {
      getAlerts();
    });

    this._closePopin();
  };

  _isRowEditable = row => {
    return row.threshold.value || false;
  };

  onSelectRow = (rowId, value) => {
    const {
      selectedTerminalAlertIds,
      toggleUnSelect,
      toggleSelect
    } = this.props;

    if (selectedTerminalAlertIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const {
      attributes,
      terminalAlerts,
      t,
      selectedTerminalAlertIds
    } = this.props;

    const formatter = {
      "threshold.type": type => t(`terminalAlert.threshold.${type}`),
      "threshold.value": value => {
        t(`alert.threshold.timeWindow.${value}`);
      }
    };

    const EditTerminalAlert = () => {
      if (selectedTerminalAlertIds.length === 1) {
        return <EditAlertAction onEdit={this._openPopin} />;
      }

      return null;
    };

    const toggleActivationButton = () => {
      const [alertId] = selectedTerminalAlertIds;
      const selectedAlert = terminalAlerts.filter(
        terminalAlert => alertId === terminalAlert.id
      );
      const [selected = {}] = selectedAlert;
      const { active: status } = selected;

      if (selectedTerminalAlertIds.length === 1) {
        return (
          <ToggleAction
            onToggle={() => this._updateStatus(status)}
            msgKey={`alert.list.${status ? "deactivate" : "activate"}`}
          />
        );
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <FormTitle
          titleKey="terminalAlert.title"
          actionKey="terminalAlert.list.action"
        />

        <div className={this.state.showPopin ? "popin" : "popin hidden"}>
          <div className="popinContent">
            <a onClick={this._closePopin} className="closeButton" />
            <div className="popinTitle">
              <I18nSpan msgKey={"terminalAlert.threshold.title"} />
            </div>

            <div className="col-sm-5">
              <input
                className="form-control input-type-text"
                ref="threshold"
                name="threshold"
                required="true"
                type="number"
                min="0"
              />
            </div>

            <div className="pull-right">
              <button
                className="btn btn-ingenico save-button"
                onClick={this._updateThreshold}
              >
                <I18nSpan msgKey={"terminalAlert.threshold.button.validate"} />
              </button>
            </div>
          </div>
        </div>

        <DataTable
          data={terminalAlerts}
          attributes={attributes}
          formatter={formatter}
          i18nKey="terminalAlert.list"
          idAttribute="id"
          isRowEditable={this._isRowEditable}
          hasActions={false}
          disableStatusValues={[false]}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedTerminalAlertIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[EditTerminalAlert, toggleActivationButton]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    terminalAlerts: { data: terminalAlerts = [], selection }
  } = state;
  const selectedTerminalAlertIds = _.keys(_.pick(selection, _.identity));

  return {
    terminalAlerts,
    selectedTerminalAlertIds,
    selection,
    attributes: ["threshold.type", "threshold.value"]
  };
};

const mapDispatchToProps = dispatch => ({
  getAlerts: () => dispatch(getAlerts({ sectionType: "terminal" })),
  editAlert: ({ id, threshold }) =>
    dispatch(editAlert({ sectionType: "terminal", id, threshold })),
  updateAlertStatus: ({ id, status }) =>
    dispatch(updateAlertStatus({ sectionType: "terminal", id, status })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "TERMINAL_ALERTS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "TERMINAL_ALERTS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "TERMINAL_ALERTS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "TERMINAL_ALERTS" }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ListTerminalAlertView);
