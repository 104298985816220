/**
 * @secure
 */
export default {
  TRANSACTIONS_LOADED: "TRANSACTIONS_LOADED",
  TRANSACTIONS_RESET: "TRANSACTIONS_RESET",
  EXTRA_FIELDS_LOADED: "EXTRA_FIELDS_LOADED",
  TRANSACTION_LOADED: "TRANSACTION_LOADED",
  TRANSACTION_FIELDS: "TRANSACTION_FIELDS",
  FILTERS_LOADED: "FILTERS_LOADED",
  ORDER_ID_SELECTED: "ORDER_ID_SELECTED",
  I18N_PREFIX: "transaction"
};
