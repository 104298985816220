export const Sim = {
  ID: "id",
  PROVIDER: "operator",
  ORDER_ID: "orderId",
  SSN: "SSN",
  CUSTOMER_ID: "customerId",
  IMSI: "IMSI",
  MSISDN: "MSISDN",
  GGSN1: "GGSN1",
  GGSN2: "GGSN2",
  CONTRACT: "contract",
  NAME: "name",
  MERCHANT_ID: "merchantId",
  OFFER_ID: "offerId",
  COMMENT: "comment"
};

export const SierraFields = [Sim.ORDER_ID];

export const RadiusCreationFields = [
  Sim.PROVIDER,
  Sim.SSN,
  Sim.CUSTOMER_ID,
  Sim.IMSI,
  Sim.MSISDN,
  Sim.GGSN1,
  Sim.GGSN2,
  Sim.CONTRACT
];
export const SierraActivationFields = [Sim.OFFER_ID, Sim.CUSTOMER_ID];
export const RadiusActivationFields = [
  Sim.CONTRACT,
  Sim.CUSTOMER_ID,
  Sim.GGSN1,
  Sim.GGSN2
];

export const SimStatus = {
  INVENTORY: "Inventory",
  ACTIVATED: "Activated",
  SUSPENDED: "Suspended"
};

export const SimProviders = {
  SIERRA: "sierraWireless"
};
