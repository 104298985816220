import React, { Component } from "react";
import classNames from "classnames";
import get from "get-value";
import _ from "lodash";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { eventSourceListener } from "../../commons/EventSourceListener";
import PosAdvancedSearch from "./PosAdvancedSearch";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import I18nSpan from "../../i18n/components/I18nSpan";
import Counter from "../../datatable/components/Counter";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import MultipleQrCodeViewer from "../../receipt/MultipleQrCodeViewer";
import { Refresh } from "../../datatable/components/Refresh";

import { generateUrl } from "../../commons/utils/url";

import {
  DisplayQrCode,
  EditAction,
  DeleteAction,
  ToggleAction,
  UnLinkAction
} from "../../datatable/components/multipleActions";

import {
  getFields,
  getPossList,
  getPois,
  getScroll,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  deletePoss,
  updatePossStatus,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetPoss,
  resetPois,
  openQrCodesSelectionPopin,
  deleteEvent,
  updateStatusEvent,
  addNotificationError,
  setSearchContext
} from "../../redux/actions";
import { getNamesFromSelection } from "../../redux/reducers/selection";

import styles from "../styles/ListPosView.css";
import { setUrlWithParams } from "../../searchContext/URLizer";
import UploadFile from "../../components/upload/UploadFile";

const advancedSearchKey = AdvancedSearchConstants.POS_KEY;

class ListPosView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      showDeleteConfirmation: false
    };
    this.setUrlParams({ searchContext });
  }
  formatter = {
    "store.selectedTags": selectedTags => _.values(selectedTags).join(", "),
    "merchant.selectedTags": selectedTags => _.values(selectedTags).join(", ")
  };

  evtSource = { close: () => undefined } as EventSource;

  async componentDidMount() {
    const { getFields, eventGatewayURL, searchContext } = this.props;

    await getFields({ name: "pos" });

    await this._onRefresh({ searchContext });

    eventSourceListener({
      type: advancedSearchKey,
      action: this._handleUpdateStatus,
      evtSourceContainer: this,
      callbackWithParams: true,
      eventGatewayURL
    });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _changeStatus({ data, id }) {
    const { status } = data;
    const { updatePossStatusView } = this.props;
    return updatePossStatusView({ ids: [id], status, sectionType: "poss" });
  }

  _handleDelete({ id }) {
    const { deletePos } = this.props;
    return deletePos({ id, sectionType: "pos" });
  }

  _handleUpdateStatus = event => {
    const { id, eventType, data } = event;

    switch (eventType) {
      case "statusUpdated": {
        return this._changeStatus({ data, id });
      }
      case "deleted": {
        return this._handleDelete({ id });
      }
    }
  };

  componentWillUnmount() {
    const { resetPoss, resetPois } = this.props;

    resetPoss();
    resetPois();
    this.evtSource.close();
  }

  _onRefresh = async ({ searchContext }) => {
    const { tableCount, getPossList, getPois } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    const posListSearchResults = await getPossList({
      filters,
      fields: [],
      sort,
      tableCount
    });

    return getPois({
      filters: [
        {
          filterType: "array",
          name: "pos.id",
          operator: "in",
          value: posListSearchResults.map(x => x.pos.id)
        }
      ],
      fields: [
        "poi.id",
        "poi.serialNumber",
        "poi.terminalReference",
        "poi.posId"
      ]
    });
  };

  _search = async ({ searchContext }) => {
    const {
      tableCount,
      setSearchContext,
      location: { pathname },
      getPossList,
      getPois
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    const posListSearchResults = await getPossList({
      filters,
      fields: [],
      sort,
      tableCount
    });

    return getPois({
      filters: [
        {
          filterType: "array",
          name: "pos.id",
          operator: "in",
          value: posListSearchResults.map(x => x.pos.id)
        }
      ],
      fields: [
        "poi.id",
        "poi.serialNumber",
        "poi.terminalReference",
        "poi.posId"
      ]
    });
  };

  _updateStatus = async ({ activationButtonStatus }) => {
    const {
      selectedPosIds: ids,
      updatePossStatus,
      addNotificationError
    } = this.props;
    const status =
      activationButtonStatus === "Suspended" ? "Activated" : "Suspended";

    try {
      await updatePossStatus({ ids, status });
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      return addNotificationError(errorKey);
    }
  };

  _showDeletePopin = () => {
    const { selection } = this.props;
    const posNames = getNamesFromSelection({
      selection,
      keyName: "pos.name"
    });

    return this.setState({
      showDeleteConfirmation: true,
      posNames
    });
  };

  _closeDeletePopin = () => {
    this.setState({
      showDeleteConfirmation: false
    });
  };

  _deletePos = async () => {
    const {
      selectedPosIds: ids,
      deletePoss,
      addNotificationError
    } = this.props;

    try {
      await deletePoss({ ids });

      return this._closeDeletePopin();
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      addNotificationError(errorKey);

      return this._closeDeletePopin();
    }
  };

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = AdvancedSearchConstants.STORE_KEY;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  onSelectRow = (rowId, value) => {
    const { selectedPosIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedPosIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  _openSelection = () => {
    const { openQrCodesSelectionPopin } = this.props;

    return openQrCodesSelectionPopin();
  };

  _loadMoreRows = async () => {
    const { getPossScroll, posScrollId, getPois } = this.props;

    const posListScrollResults = await getPossScroll({ scrollId: posScrollId });

    return getPois({
      filters: [
        {
          filterType: "array",
          name: "pos.id",
          operator: "in",
          value: posListScrollResults.map(x => x.pos.id)
        }
      ],
      fields: [
        "poi.id",
        "poi.serialNumber",
        "poi.terminalReference",
        "poi.posId"
      ]
    });
  };

  render() {
    const {
      loading,
      color,
      isCustomer,
      visibleAttributes,
      posCount,
      posList,
      selectedPosIds,
      selectionNotDisplayQrCode,
      attributes,
      qrCodes,
      showQrCodesSelectionPopin,
      sortDescription,
      searchContext,
      selection,
      hasTerminalManagement,
      hasStoreManagement,
      hasMerchantManagement,
      isMerchant
    } = this.props;

    const { showDeleteConfirmation, posNames } = this.state;

    const activationButtonStatus = handleToggleActivationButtonStatus({
      selectionIds: selectedPosIds,
      poss: posList
    });

    const DisplayQrCodeForPos = () => {
      if (selectionNotDisplayQrCode) {
        return null;
      }

      return <DisplayQrCode onDisplay={this._openSelection} />;
    };

    const EditPos = () => {
      if (hasStoreManagement && selectedPosIds.length === 1) {
        const [posId] = selectedPosIds;
        const route = `/main/settings/pos/edit-pos/${posId}`;

        return <EditAction route={route} msgKey={"pos.edit.action"} />;
      }

      return null;
    };

    const DeletePos = () => <DeleteAction onDelete={this._showDeletePopin} />;

    const LinkPoiButton = () => {
      if (hasTerminalManagement && selectedPosIds.length === 1) {
        const [posId] = selectedPosIds;
        const route = `/main/settings/pos/link-pos/${posId}`;

        return (
          <Link
            className={classNames(
              "btn",
              "multiple-action",
              styles["pos-multiple-action"]
            )}
            to={route}
          >
            <span
              className={classNames(
                "glyphicon",
                "glyphicon-link",
                styles.glyphicon
              )}
            />
            <I18nSpan msgKey="pos.link.action" />
          </Link>
        );
      }

      return null;
    };

    const UnLinkPoiButton = () => {
      const hasBeenLinked = () => {
        const [id] = selectedPosIds;
        const { [id]: selectedPos = { pois: [] } } = selection;
        const { pois = [] } = selectedPos;
        return pois.length > 0;
      };

      if (
        hasTerminalManagement &&
        selectedPosIds.length === 1 &&
        hasBeenLinked()
      ) {
        const [posId] = selectedPosIds;
        const route = `/main/settings/pos/unlink-poi/${posId}`;

        return <UnLinkAction route={route} msgKey={"pos.unlink.action"} />;
      }

      return null;
    };

    const ToggleActivationButton = () => (
      <ToggleAction
        onToggle={() => this._updateStatus({ activationButtonStatus })}
        msgKey={`pos.toggle.${activationButtonStatus}`}
      />
    );

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle
              titleKey="pos.title"
              ref="title"
              actionKey="pos.list.action"
            />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={posCount}
                loadingKey="pos.list.loading"
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>

          <div className={styles.edition}>
            {((isCustomer && hasMerchantManagement && hasStoreManagement) ||
              (isMerchant && hasStoreManagement)) && (
              <div>
                <Link
                  className={classNames(
                    "btn",
                    "btn-ingenico",
                    styles["pos-action"]
                  )}
                  to={"/main/settings/pos/new-pos"}
                >
                  <span
                    className="glyphicon glyphicon-plus"
                    aria-hidden="true"
                  />
                  <I18nSpan msgKey="pos.list.button.create" />
                </Link>
              </div>
            )}
            {isCustomer && (
              <UploadFile
                uploadUrl={"/poses/import"}
                templateActions={[
                  generateUrl({
                    url: "/poses/import/template"
                  })
                ]}
                fileKey="pos"
                tooltip="pos.import.template"
                buttonsTitles={{
                  upload: "pos.import.upload",
                  templates: "pos.import.template",
                  selectFile: "pos.import.selectFile"
                }}
                notifications={{
                  success: "pos.import.uploadFile.success",
                  error: "pos.import.uploadFile.error"
                }}
              />
            )}
          </div>
        </div>

        <PosAdvancedSearch
          searchContext={searchContext}
          onChange={this._search}
        />

        {showDeleteConfirmation && (
          <ConfirmationPopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={this._deletePos}
            objectName={posNames}
          />
        )}

        <DataTable
          formattersCellValue={["pos.status"]}
          data={posList}
          notSortableFields={["store.selectedTags", "merchant.selectedTags"]}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          i18nKey="pos.list"
          idAttribute="pos.id"
          formatter={this.formatter}
          color={color}
          hasActions={false}
          hideSearchBox={true}
          toggleable={true}
          disableStatusValues={["Suspended"]}
          onRefresh={this._search}
          onUpdateColumns={this._updateColumns}
          selectableNew={true}
          selectionButtonKey={[
            DisplayQrCodeForPos,
            EditPos,
            LinkPoiButton,
            UnLinkPoiButton,
            ToggleActivationButton,
            DeletePos
          ]}
          version="new"
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedPosIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={posCount}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />

        <MultipleQrCodeViewer
          qrCodes={qrCodes}
          showPopin={showQrCodesSelectionPopin}
        />
      </div>
    );
  }
}

const handleToggleActivationButtonStatus = ({ selectionIds, poss }) => {
  const selection = poss.reduce((prevSelectedPos, selectedPos) => {
    const selectedPosId = selectedPos["pos.id"];

    if (selectionIds.includes(selectedPosId)) {
      return {
        ...prevSelectedPos,
        [selectedPosId]: selectedPos
      };
    }

    return prevSelectedPos;
  }, {});

  const isAllActivated = selectionIds.every(posId => {
    const { [posId]: selected } = selection;

    return selected && selection[posId]["pos.status"] === "Activated";
  });

  return isAllActivated ? "Activated" : "Suspended";
};

const posWithStatus = ({ state }) => {
  const {
    poss: { dataList, count, scrollId },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const pos = dataList.map(pos => {
    const normalizedPos = fields.reduce((prevField, field) => {
      return {
        ...prevField,
        [field]: get(pos, field)
      };
    }, {});

    return {
      ...normalizedPos,
      pois: pos.pois
    };
  });

  return {
    count,
    scrollId,
    pos
  };
};

const _getColumns = ({ type, fields: genericFields }) => {
  const fields = genericFields.reduce((prevField, field) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);

  return fields;
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting && columnSetting.pos && columnSetting.pos.length > 0
    ? columnSetting.pos
    : _getColumns({ fields });
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isCustomer, isMerchant },
    genericFields: { data: fields },
    poss: { selection, loading, qrCodes, showQrCodesSelectionPopin },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount, eventGatewayURL }
    },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const {
    pos: posList,
    count: posCount,
    scrollId: posScrollId
  } = posWithStatus({
    state
  });
  const selectedPosIds = _.keys(_.pick(selection, _.identity));
  const selectionNotDisplayQrCode = Object.values(selection).every(
    selectedPos => {
      const acquirerTerminalCustomerId = get(
        selectedPos,
        "acquirer.terminalCustomerId"
      );
      const posAutoInit = get(selectedPos, "pos.autoInit");

      return !(acquirerTerminalCustomerId && posAutoInit);
    }
  );

  const { permissions } = user;
  const hasTerminalManagement = _.includes(permissions, "terminalManagement");
  const hasStoreManagement = _.includes(permissions, "storeManagement");
  const hasMerchantManagement = _.includes(permissions, "merchantManagement");

  const columnSetting = user ? user.columnSetting : false;
  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const attributes = _getColumns({ fields });

  return {
    loading,
    attributes,
    fields,
    color,
    posList,
    tableCount,
    posCount,
    posScrollId,
    selectedPosIds,
    selection,
    selectionNotDisplayQrCode,
    isCustomer,
    visibleAttributes,
    qrCodes,
    showQrCodesSelectionPopin,
    sortDescription,
    eventGatewayURL,
    searchContext,
    hasTerminalManagement,
    hasStoreManagement,
    hasMerchantManagement,
    isMerchant
  };
};

const mapDispatchToProps = dispatch => ({
  resetPoss: () => dispatch(resetPoss()),
  resetPois: () => dispatch(resetPois()),
  getPossList: ({ filters, fields, sort, tableCount }) =>
    dispatch(getPossList({ filters, fields, sort, tableCount })),
  getPois: ({ filters, fields, sort, tableCount }) =>
    dispatch(getPois({ filters, fields, sort, tableCount })),
  getPossScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "pos", scrollId })),
  getFields: ({ name }) => dispatch(getFields({ name })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "POSS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "POSS" })),
  toggleSelectAll: () => dispatch(toggleSelectAll({ selectionType: "POSS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "POSS" })),
  deletePoss: ({ ids }) => dispatch(deletePoss({ ids })),
  updatePossStatus: ({ ids, status }) =>
    dispatch(updatePossStatus({ ids, status })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  openQrCodesSelectionPopin: () => dispatch(openQrCodesSelectionPopin()),
  updatePossStatusView: ({ ids, status, sectionType }) =>
    dispatch(updateStatusEvent({ ids, status, sectionType })),
  deletePos: ({ id, sectionType }) =>
    dispatch(deleteEvent({ id, sectionType })),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ListPosView);
