import React, { Component } from "react";
import _ from "lodash";
import classNames from "classnames";

import { PeriodSelection } from "../../reporting/models/TimePeriod";

import I18nNumber from "../../i18n/components/I18nNumber";

import styles from "./Trend.css";

const SUPPORTED_STATES = [
  PeriodSelection.INTRADAY,
  PeriodSelection.WEEK_TO_DATE,
  PeriodSelection.MONTH_TO_DATE
];

interface Data {}

interface Props {
  data: Data;
}

interface State {}

const FormatValue = ({ value }: any) => {
  if (value === 0) {
    return <span>0</span>;
  }

  const digitsAfterComma = 2;
  const number = Number(value).toFixed(digitsAfterComma);
  const absoluteValue = Math.abs(number);

  return <I18nNumber number={absoluteValue} format="0.00" />;
};

class Trend extends Component<Props, State> {
  _isValidTimeSelector(selectionType) {
    return _.some(SUPPORTED_STATES, elem => elem === selectionType);
  }

  render() {
    const { field, type, data = {}, enableTrend = false } = this.props;

    const validTime = this._isValidTimeSelector(type);

    const { [field]: trend = 0 } = data;

    const up = trend > 0;
    const down = trend < 0;
    const equal = trend === 0;
    const hundred = trend >= 100;

    if (enableTrend && validTime) {
      return (
        <div
          className={classNames(styles.container, {
            [styles.up]: up,
            [styles.down]: down,
            [styles.equal]: equal,
            [styles.hundred]: hundred
          })}
        >
          <i
            className={classNames(styles.triangle, {
              [styles.triangleUp]: up,
              [styles.triangleDown]: down,
              [styles.triangleEqual]: equal
            })}
          />
          <div className={styles["container-value"]}>
            <FormatValue value={trend} />
          </div>
          <span>%</span>
        </div>
      );
    }
    return null;
  }
}

export default Trend;
