import React, { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import I18nSpan from "../../i18n/components/I18nSpan";
import TimePeriod from "../models/TimePeriod";
import NumberFormatter from "../../formatters/NumberFormatter";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import { WidgetConfig } from "../../home/components/Widget";
import WidgetView from "../../home/components/Widget";

import {
  getSimConnectionsWidgets,
  resetWidgetsState,
  getSimsActivitiesConnections,
  resetSimsActivitiesConnections
} from "../../redux/actions";

import styles from "./styles/ListSimConnection.css";

interface Props {
  getWidgets: Function;
  history: any;
  resetWidgetsState: Function;
  getConnections: Function;
  begin: any;
  end: any;
  sortDescription: any;
  widgets: any;
  color: any;
  user: any;
  connections: any;
}

interface State {}

const advancedSearchKey = AdvancedSearchConstants.TRANSACTION_KEY;

class ListSimConnectionView extends Component<Props, State> {
  columnsFormatters = {
    simVolumeFromTerminal: val =>
      val
        ? NumberFormatter.formatNumber((val.value || 0) / 1024, "0,0.00")
        : "",
    simVolumeToTerminal: val =>
      val
        ? NumberFormatter.formatNumber((val.value || 0) / 1024, "0,0.00")
        : "",
    totalVolume: val =>
      val ? NumberFormatter.formatNumber((val || 0) / 1024, "0,0.00") : ""
  };
  columns = [
    "SSN",
    "doc_count",
    "simVolumeFromTerminal",
    "simVolumeToTerminal",
    "totalVolume"
  ];

  async componentDidMount() {
    const { getWidgets, periodSelection } = this.props;
    await getWidgets({ filters: [], periodSelection });
    this._search();
  }

  async componentWillUnmount() {
    const { resetWidgetsState } = this.props;
    resetWidgetsState();
  }

  _search = () => {
    const { getConnections } = this.props;
    const timePeriod = TimePeriod.MONTH_TO_DATE;
    timePeriod.refreshTime();

    const begin = timePeriod.startTime.valueOf();
    const end = timePeriod.endTime.valueOf();

    return getConnections({ begin, end });
  };

  _backURl = async event => {
    event.preventDefault();

    const { history } = this.props;

    const { getWidgets, periodSelection, resetWidgetsState } = this.props;
    await resetWidgetsState();
    await getWidgets({ filters: [], periodSelection });

    return history.push("/main/reporting/sims-status");
  };

  render() {
    const {
      sortDescription,
      widgets,
      color,
      user,
      connections,
      t
    } = this.props;

    const widgetsHidden = {};
    const widgetsSize = {};
    const formatters = {};

    const title = t("sim.volumesStrips.title");

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle titleKey="sim.title" actionKey="sim.list.action" />
          </div>
          <div>
            <button className="btn btn-ingenico mrn" onClick={this._backURl}>
              <span className="glyphicon glyphicon-circle-arrow-left" />
              <I18nSpan
                msgKey={"monitoringPoiSim.simConnections.button.back"}
              />
            </button>
          </div>
        </div>

        <h3>{title}</h3>
        <div
          key={`group-widget`}
          className={classNames(styles.wrapper, "group-widget")}
          ref={container => (this.widgetContainer = container)}
        >
          {widgets.map((config: WidgetConfig) => {
            const { id, type } = config;

            const { [type]: formatter } = formatters;
            const { [type]: hide } = widgetsHidden;
            const { [type]: size } = widgetsSize;

            return (
              <WidgetView
                className={classNames(styles.widget)}
                enableTrend={true}
                color={color}
                user={user}
                key={id}
                config={{ ...config, desktopView: true }}
                formatter={formatter}
                hide={hide}
                header={false}
                size={size}
              />
            );
          })}
        </div>

        <DataTable
          data={connections}
          attributes={this.columns}
          i18nKey="monitoringPoiSim.simConnections.list"
          idAttribute="SSN"
          color={color}
          onSort={_.noop}
          hideSearchBox={true}
          hasActions={false}
          formatter={this.columnsFormatters}
          actionsElement={[]}
          sortDescription={sortDescription}
        />
      </div>
    );
  }
}

const mapConnections = ({ rawConnections }) => {
  const connections = rawConnections.map((connection: any) => {
    connection.totalVolume =
      connection.simVolumeFromTerminal.value +
      connection.simVolumeToTerminal.value;
    return connection;
  });
  return { connections };
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, levelSettings: _levelSettings },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const timePeriod = TimePeriod.MONTH_TO_DATE;
  timePeriod.refreshTime();

  const min = timePeriod.startTime.valueOf();
  const max = timePeriod.endTime.valueOf();

  const {
    widgets: { data = [], loading, error },
    simsActivities: {
      connections: { data: rawConnections }
    }
  } = state;

  const widgets = data.map(widget => {
    const { name, id, type } = widget;

    return {
      type,
      filters: [],
      name,
      id,
      period: TimePeriod.MONTH_TO_DATE,
      userCurrency,
      timePeriod,
      timestamps: {
        min,
        max
      }
    };
  });

  const { connections } = mapConnections({ rawConnections });

  return {
    user,
    connections,
    periodSelection: TimePeriod.MONTH_TO_DATE,
    color,
    widgets,
    loading,
    error,
    begin: min,
    end: max
  };
};

const mapDipatchToProps = dispatch => ({
  resetWidgetsState: () => dispatch(resetWidgetsState()),
  getWidgets: ({ filters, periodSelection }) =>
    dispatch(getSimConnectionsWidgets({ filters, periodSelection })),
  getConnections: ({ begin, end }) =>
    dispatch(getSimsActivitiesConnections({ begin, end })),
  resetConnections: () => dispatch(resetSimsActivitiesConnections())
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDipatchToProps)
)(ListSimConnectionView);
