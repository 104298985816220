import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FilteredById from "../../../components/FilteredById";
import FormTitle from "../../../ingenicoForm/components/FormTitle";
import TransactionTimeSelector from "../../components/TransactionTimeSelector";
import TransactionAdvancedSearch from "./TransactionAdvancedSearch";
import TransactionDataTableView from "./TransactionDataTableView";
import TransactionViewer from "./TransactionViewer";
import AdvancedSearchConstants from "../../../advancedSearch/constants/AdvancedSearchConstants";
import TransactionConstants from "../../constants/TransactionConstants";
import Utils from "../../Utils";
import Counter from "../../../datatable/components/Counter";
import ExportButton from "./ExportButton";
import MultipleReceiptViewer from "../../../receipt/MultipleReceiptViewer";
import { Refresh } from "../../../datatable/components/Refresh";
import DateFormatter from "../../../formatters/DateFormatter";

import {
  getFields,
  getTransactions,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetTransactions,
  openReceiptsSelectionPopin,
  closeReceiptsSelectionPopin,
  transactionBuildParams,
  setSearchContext
} from "../../../redux/actions";

import { generateUrl } from "../../../commons/utils/url";
import { setUrlWithParams } from "../../../searchContext/URLizer";

import styles from "../styles/styles.css";

const makeOrderIdFilter = orderId => {
  return {
    filterType: "string",
    name: "order.orderId",
    operator: "=",
    tag: false,
    value: orderId
  };
};

const canExportTransactions = (connectionStore, services) => {
  const { isCustomer, isMerchant } = connectionStore;

  return (isCustomer || isMerchant) && services.includes("transactionDownload");
};

interface Props {
  location: any;
  showExport: any;
  transactions: any;
  transactionFields: any;
  fields: any;
  extraTransactionFields: any;
  receipts: any;
  showReceiptsSelectionPopin: boolean;
  isLoading: boolean;
  transactionsCount: any;
  userLogin: string;
  userColumnSetting: any;
  selection: any;
  tableCount: any;
  timePeriod: any;
  getTransactions: Function;
  loadingTransactions: boolean;
  user: any;
}

interface State {
  orderId: any;
}

const advancedSearchKey = AdvancedSearchConstants.TRANSACTION_KEY;

export class ListTransactionView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {};
    this.setUrlParams({ searchContext });
  }

  async componentDidMount() {
    const { getFields, searchContext } = this.props;

    await getFields({ name: "transaction", version: "v2" });

    await this._onRefresh({ searchContext });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  componentWillUnmount() {
    const { resetTransactions } = this.props;

    resetTransactions();
  }

  _buildTimePeriodParams = ({ timePeriod }) => {
    const { user } = this.props;

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);
    return {
      begin,
      end
    };
  };

  _onRefresh = async ({ searchContext }) => {
    const { tableCount, getTransactions, user } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort } = {},
      timePeriod
    } = searchContext;

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);

    return getTransactions({ filters, sort, tableCount, begin, end });
  };

  _search = async ({ searchContext }) => {
    const {
      tableCount,
      setSearchContext,
      location: { pathname },
      getTransactions,
      user
    } = this.props;
    const { sort, filters, timePeriod } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);

    return getTransactions({ filters, sort, tableCount, begin, end });
  };

  _searchOrderId = (fixedTimePeriod: any, fixedFilters = [], sort?) => {
    const { getTransactions, user } = this.props;

    if (user) {
      const { tableCount } = this.props;
      const filters = fixedFilters;
      const begin = DateFormatter.getTimezonedDate(
        fixedTimePeriod.startTime,
        user
      );
      const end = DateFormatter.getTimezonedDate(fixedTimePeriod.endTime, user);

      return getTransactions({ filters, sort, tableCount, begin, end });
    }
  };

  _onOrderIdSelected = orderId => {
    this.setState({ orderId } as State, () => {
      const { searchContext } = this.props;
      const { timePeriod } = searchContext;

      return this._searchOrderId(timePeriod, [makeOrderIdFilter(orderId)]);
    });
  };

  _onOrderIdFilterClose = () => {
    const { searchContext } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort },
      timePeriod
    } = searchContext;

    const context = { filters, sort, timePeriod };

    this.setState({ orderId: null } as State, () =>
      this._search({ searchContext: context })
    );
  };

  _closeSelection = () => {
    const { closeReceiptsSelectionPopin } = this.props;

    return closeReceiptsSelectionPopin();
  };

  _openSelection = () => {
    const {
      openReceiptsSelectionPopin,
      selectedTransactionIds,
      transactionIdsWithReceipts
    } = this.props;

    const filteredSelection = selectedTransactionIds.filter(selectedId => {
      return _.includes(transactionIdsWithReceipts, selectedId);
    });

    return openReceiptsSelectionPopin(filteredSelection);
  };

  _onRefreshDataTable = ({ searchContext }) => {
    const { sort, timePeriod } = searchContext;

    const { orderId } = this.state;

    return orderId != null
      ? this._searchOrderId(timePeriod, [makeOrderIdFilter(orderId)], sort)
      : this._search({ searchContext });
  };

  _getStorageKey = () => {
    const { userLogin } = this.props;

    return `visibleAttributes#${userLogin}#reporting.transaction.list`;
  };

  render() {
    const {
      showExport,
      transactionFields,
      isLoading,
      transactionsCount,
      userColumnSetting,
      loadingTransactions,
      extraTransactionFields,
      searchContext,
      t
    } = this.props;
    const { orderId } = this.state;

    const ExportToCsv = ({ showExport, transactionsCount }: any) => {
      if (showExport) {
        const convertAttributesIntoMap = attributes =>
          attributes.reduce((prevAttribute, attribute) => {
            return {
              ...prevAttribute,
              [attribute]: true
            };
          }, {});

        const defaultVisibleAttributes =
          userColumnSetting && userColumnSetting.transaction
            ? convertAttributesIntoMap(userColumnSetting.transaction)
            : convertAttributesIntoMap(transactionFields.slice(0, 10));
        const visibleAttributesSaved =
          JSON.parse(localStorage.getItem(this._getStorageKey())) ||
          defaultVisibleAttributes;

        const columnsToExport = Object.entries(visibleAttributesSaved).reduce(
          (prevAttribute, attribute) => {
            if (attribute[1]) {
              const content = attribute[0];
              const name = t(
                `${TransactionConstants.I18N_PREFIX}.${content}.label`,
                content
              );

              return [
                ...prevAttribute,
                {
                  name,
                  content
                }
              ];
            }

            return prevAttribute;
          },
          []
        );

        return (
          <ExportButton
            exportUrl={generateUrl({
              url: "/transaction/export",
              version: "v2"
            })}
            filtersKey={advancedSearchKey}
            buildTimePeriodParams={this._buildTimePeriodParams}
            canExport={columnsToExport.length > 0 && transactionsCount > 0}
            columns={columnsToExport}
            formats={["CSV"]}
            version="new"
            searchContext={searchContext}
          />
        );
      }
      return null;
    };

    return (
      <div className="reporting reporting-transaction data-table-wrapper">
        <div className={styles["title-wrapper"]}>
          <FormTitle
            titleKey="reporting.transaction.title"
            actionKey="reporting.transaction.action"
          />

          <div className={styles["countAndRefresh-container"]}>
            <Counter
              loading={loadingTransactions}
              count={transactionsCount}
              loadingKey="reporting.transaction.loading"
            />
            <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
          </div>
        </div>
        <div className="flex-box">
          <TransactionTimeSelector
            disabled={orderId != null}
            onChange={({ searchContext: nextSearchContext }) =>
              this._onRefreshDataTable({ searchContext: nextSearchContext })
            }
            searchContext={searchContext}
          />

          <div className="no-flex">
            <ExportToCsv
              showExport={showExport}
              transactionsCount={transactionsCount}
            />
          </div>
        </div>
        <TransactionAdvancedSearch
          onChange={this._search}
          searchContext={searchContext}
          disabled={orderId != null}
        />
        <FilteredById
          id={orderId}
          i18nKey="reporting.transaction.orderId"
          onClose={this._onOrderIdFilterClose}
        />
        <TransactionDataTableView
          onRefresh={this._onRefreshDataTable}
          transactionFields={transactionFields}
          onSelectionButtonClick={this._openSelection}
          onOrderIdSelected={this._onOrderIdSelected}
          searchContext={searchContext}
        />
        <MultipleReceiptViewer
          onClose={this._closeSelection}
          isLoading={isLoading}
          receiptActionType="transaction"
        />
        <TransactionViewer
          transactionFields={transactionFields}
          extraTransactionFields={extraTransactionFields}
        />
      </div>
    );
  }
}

const _getColumns = ({ type, fields: genericFields }) => {
  const fields = genericFields.reduce((prevField, field) => {
    const { key, required, visible, extras } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      case "extras": {
        if (extras) prevField.push(key);

        break;
      }
      case "visible": {
        if (visible) prevField.push(key);

        break;
      }
      default: {
        break;
      }
    }

    return prevField;
  }, []);

  return fields;
};

export const mapStateToProps = (state, ownProps) => {
  const {
    auth: connectionStore,
    genericFields: { data: fields = [] },
    transactions: {
      data: transactions,
      count: transactionsCount,
      loading: loadingTransactions,
      showReceiptsSelectionPopin,
      selection
    },
    config: {
      data: { tableCount }
    },
    services: { data: services = [] },
    searchContext: {
      data: { timePeriod = {} }
    },
    searchContext: { data: searchContext }
  } = state;

  const { user, isCustomer } = connectionStore;
  const userLogin = user ? user.login : "";
  const userColumnSetting = user ? user.columnSetting : false;

  const transactionFields = _getColumns({ fields, type: "visible" });
  const extraTransactionFields = _getColumns({ fields, type: "extras" });

  const showExport = canExportTransactions(connectionStore, services);

  const selectedTransactionIds = _.keys(_.pick(selection, _.identity));
  const transactionIdsWithReceipts = transactions.reduce(
    (prevTransaction, transaction) => {
      if (
        Utils.merchantReceiptIsVisible(transaction, connectionStore, services)
      ) {
        return [...prevTransaction, transaction.transactionId];
      }

      return prevTransaction;
    },
    []
  );

  return {
    loadingTransactions,
    tableCount,
    transactionsCount,
    transactionFields,
    timePeriod,
    showExport,
    userLogin,
    userColumnSetting,
    isCustomer,
    showReceiptsSelectionPopin,
    transactionIdsWithReceipts,
    selectedTransactionIds,
    extraTransactionFields,
    user,
    searchContext
  };
};

const mapDispatchToProps = dispatch => ({
  resetTransactions: () => dispatch(resetTransactions()),
  getTransactions: ({ filters, fields, sort, tableCount, begin, end }) =>
    dispatch(
      getTransactions({ filters, fields, sort, tableCount, begin, end })
    ),
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "TRANSACTIONS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "TRANSACTIONS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "TRANSACTIONS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "TRANSACTIONS" })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  openReceiptsSelectionPopin: receiptsSelection =>
    dispatch(openReceiptsSelectionPopin(receiptsSelection)),
  closeReceiptsSelectionPopin: () => dispatch(closeReceiptsSelectionPopin()),
  buildParams: ({ timePeriod, filters }) =>
    dispatch(transactionBuildParams({ timePeriod, filters })),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl: true
      })
    )
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ListTransactionView);
