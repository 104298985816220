export const SettlementFileFormatConstants = {
  CSV: "CSV",
  BNPP: "BNPP",
  CMCIC: "CMCIC",
  CMCIC_V4: "CMCIC_V4",
  APM: "APM"
};

export interface BNPP {
  type: "BNPP";
}

export interface CMCIC {
  type: "CMCIC";
}

export interface CMCIC_V4 {
  type: "CMCIC_V4";
}

export interface APM {
  type: "APM";
}

export interface CSV {
  type: "CSV";
  transactionFields: Array<String>;
  fieldSeparator?: string;
  fieldQuoting?: string;
}

export type SettlementFileFormat = BNPP | CMCIC | CMCIC_V4 | CSV | APM;
