import {
  PENDING_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  SUCCESS_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  FAILURE_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  RESET_UPDATE_USER_COLUMNS_SETTINGS_ACTION,
  PENDING_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  SUCCESS_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  FAILURE_DELETE_USER_COLUMNS_SETTINGS_ACTION,
  RESET_DELETE_USER_COLUMNS_SETTINGS_ACTION
} from "../actionsTypes";

const initialState = {
  columnSetting: {
    data: {},
    loading: false,
    error: false,
    errors: {}
  }
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_DELETE_USER_COLUMNS_SETTINGS_ACTION:
    case PENDING_UPDATE_USER_COLUMNS_SETTINGS_ACTION: {
      return {
        ...state,
        columnSetting: {
          data: {},
          loading: true,
          error: false,
          errors: {}
        }
      };
    }
    case SUCCESS_DELETE_USER_COLUMNS_SETTINGS_ACTION:
    case SUCCESS_UPDATE_USER_COLUMNS_SETTINGS_ACTION: {
      const { columnSetting } = action.payload;
      return {
        ...state,
        columnSetting: {
          data: columnSetting,
          loading: false,
          error: false,
          errors: {}
        }
      };
    }
    case FAILURE_DELETE_USER_COLUMNS_SETTINGS_ACTION:
    case FAILURE_UPDATE_USER_COLUMNS_SETTINGS_ACTION: {
      const { errors } = action.payload;
      return {
        ...state,
        columnSetting: {
          data: {},
          loading: false,
          error: true,
          ...errors
        }
      };
    }
    case RESET_DELETE_USER_COLUMNS_SETTINGS_ACTION:
    case RESET_UPDATE_USER_COLUMNS_SETTINGS_ACTION: {
      return {
        ...state,
        columnSetting: {
          ...initialState.columnSetting
        }
      };
    }
    default:
      return state;
  }
};
