import Validators from "../../ingenicoForm/validation/Validators";

/* eslint-disable max-len */
const IP_PATTERN =
  "^([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])\\.([01]?\\d\\d?|2[0-4]\\d|25[0-5])$";
/* eslint-enable max-len */

const SimValidation = {
  ipValidator(ipAddress) {
    const validator = Validators.patternValidator(IP_PATTERN, "ip");
    return Promise.resolve(validator(ipAddress));
  },

  requiredAndIpValidator(ipAddress) {
    const requiredPromise = new Promise(resolve => {
      resolve(Validators.requiredValidator(ipAddress));
    });

    const ipPromise = new Promise(resolve => {
      resolve(Validators.patternValidator(IP_PATTERN, "ip")(ipAddress));
    });

    return Promise.all([requiredPromise, ipPromise]);
  },

  requiredAndUniqueSSN(ssn) {
    const requiredPromise = new Promise(resolve => {
      resolve(Validators.requiredValidator(ssn));
    });

    const asciiAndNumberPromise = new Promise(resolve => {
      resolve(Validators.asciiAndNumberValidator(ssn));
    });

    return Promise.all([requiredPromise, asciiAndNumberPromise]);
  },

  requiredAndUniqueIMSI(imsi) {
    const requiredPromise = new Promise(resolve => {
      resolve(Validators.requiredValidator(imsi));
    });

    const numberPromise = new Promise(resolve => {
      resolve(Validators.numberValidator(imsi));
    });

    return Promise.all([requiredPromise, numberPromise]);
  },

  required(value) {
    return Promise.resolve(Validators.requiredValidator(value));
  },

  noop() {
    return Promise.resolve(null);
  }
};

export default SimValidation;

export const simErrorNotification = async ({
  error,
  addNotificationError,
  form
}: any) => {
  const { errorKey } = await error;

  switch (errorKey) {
    case "ssn.create.conflict":
      return addNotificationError("form.error.ssn", [form.SSN]);
    case "imsi.create.conflict":
      return addNotificationError("form.error.imsi", [form.IMSI]);
    default:
      return addNotificationError(errorKey);
  }
};
