import F from "../../commons/HTTPFetcher";

import {
  PENDING_FIRE_SCHEDULER_JOB_ACTION,
  SUCCESS_FIRE_SCHEDULER_JOB_ACTION,
  FAILURE_FIRE_SCHEDULER_JOB_ACTION,
  PENDING_DELETE_SCHEDULER_JOB_ACTION,
  SUCCESS_DELETE_SCHEDULER_JOB_ACTION,
  FAILURE_DELETE_SCHEDULER_JOB_ACTION,
  PENDING_CREATE_SCHEDULER_JOB_ACTION,
  SUCCESS_CREATE_SCHEDULER_JOB_ACTION,
  FAILURE_CREATE_SCHEDULER_JOB_ACTION
} from "../actionsTypes";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

export const fireSchedulerJob = ({ id }) => dispatch => {
  dispatch(fireSchedulerJobStart());

  return F.put(`/monitoring/scheduler/job/${id}/fire`)
    .then(schedulerJob => {
      dispatch(fireSchedulerJobSucceeded({ id }));
      return schedulerJob;
    })
    .catch(errors => {
      dispatch(fireSchedulerJobFailure({ errors }));
      throw errors;
    });
};

const fireSchedulerJobStart = () => ({
  type: PENDING_FIRE_SCHEDULER_JOB_ACTION
});

const fireSchedulerJobSucceeded = ({ id }) => ({
  type: SUCCESS_FIRE_SCHEDULER_JOB_ACTION,
  payload: {
    id
  }
});

const fireSchedulerJobFailure = errors => ({
  type: FAILURE_FIRE_SCHEDULER_JOB_ACTION,
  payload: {
    errors
  }
});

export const deleteSchedulerJob = ({ id }) => dispatch => {
  dispatch(deleteSchedulerJobStart());

  return F.delete(`/monitoring/scheduler/job/${id}`)
    .then(schedulerJob => {
      dispatch(deleteSchedulerJobSucceeded({ id }));
      return schedulerJob;
    })
    .catch(errors => {
      dispatch(deleteSchedulerJobFailure({ errors }));
      throw errors;
    });
};

const deleteSchedulerJobStart = () => ({
  type: PENDING_DELETE_SCHEDULER_JOB_ACTION
});

const deleteSchedulerJobSucceeded = ({ id }) => ({
  type: SUCCESS_DELETE_SCHEDULER_JOB_ACTION,
  payload: {
    id
  }
});

const deleteSchedulerJobFailure = errors => ({
  type: FAILURE_DELETE_SCHEDULER_JOB_ACTION,
  payload: {
    errors
  }
});

export const createSchedulerJob = ({ data }) => dispatch => {
  dispatch(createSchedulerJobStart());

  return F.post("/monitoring/scheduler/job", {
    contentType: ContentTypes.JSON,
    body: data
  })
    .then(schedulerJob => {
      dispatch(createSchedulerJobSucceeded());
      return schedulerJob;
    })
    .catch(errors => {
      dispatch(createSchedulerJobFailure({ errors }));
      throw errors;
    });
};

const createSchedulerJobStart = () => ({
  type: PENDING_CREATE_SCHEDULER_JOB_ACTION
});

const createSchedulerJobSucceeded = () => ({
  type: SUCCESS_CREATE_SCHEDULER_JOB_ACTION
});

const createSchedulerJobFailure = errors => ({
  type: FAILURE_CREATE_SCHEDULER_JOB_ACTION,
  payload: {
    errors
  }
});
