import { Sortable, Plugins } from "@shopify/draggable";
import Widget from "../models/Widget";
import { store } from "../../redux/store";
import {
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

export const WidgetDrag = {
  orderWidgets: event => (widgets: Array<Widget>): Array<Widget> => {
    // https://medium.com/kevin-salters-blog/reordering-a-javascript-array-based-on-a-drag-and-drop-interface-e3ca39ca25c
    const remainingWidgets = widgets.filter(
      (item, index) => index !== event.data.oldIndex
    );
    const widgetsOrdered = [
      ...remainingWidgets.slice(0, event.data.newIndex),
      widgets[event.data.oldIndex],
      ...remainingWidgets.slice(event.data.newIndex)
    ];

    return widgetsOrdered;
  },

  reset(instance) {
    return instance.destroy();
  },

  sortableWidget(container) {
    const sortable = new Sortable(container.widgetContainer, {
      collidables: ".header, .sidebar",
      draggable: ".group-widget .widget-thumbnails",
      mirror: {
        constrainDimensions: true
      },
      plugins: [Plugins.Collidable],
      delay: 200 // https://github.com/Shopify/draggable/issues/68#issuecomment-336474279
    });

    // https://github.com/Shopify/draggable/issues/183#issuecomment-375684239
    sortable.on("sortable:start", event => {
      // element: HTMLElement<any>
      // classesToPrevent: Array<string>
      const isPrevented = (element, classesToPrevent) => {
        let currentElem = element;
        let isParent = false;

        while (currentElem) {
          const hasClass = Array.from(currentElem.classList).some(cls =>
            classesToPrevent.includes(cls)
          );
          if (hasClass) {
            isParent = true;
            currentElem = undefined;
          } else {
            currentElem = currentElem.parentElement;
          }
        }

        return isParent;
      };

      const currentTarget = event.data.dragEvent.originalEvent.target;

      if (isPrevented(currentTarget, ["widget-container"])) {
        event.cancel();
      }
    });

    sortable.on("sortable:stop", async ({ data }) => {
      const {
        props: { updateWidget, widgets }
      } = container;

      const remainingWidgets = widgets.filter(
        (item, index) => index !== data.oldIndex
      );

      const widgetsOrdered = [
        ...remainingWidgets.slice(0, data.newIndex),
        widgets[data.oldIndex],
        ...remainingWidgets.slice(data.newIndex)
      ].map((ob, index) => ({ ...ob, order: index }));

      try {
        await updateWidget({ widgets: widgetsOrdered });

        store.dispatch(addNotificationSuccess("widget.update.success"));
      } catch (error) {
        store.dispatch(addNotificationError("notification.error"));

        throw new Error(`updateWidget() error: ${error}`);
      }
    });
  }
};
