import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import DataTable from "../../datatable/components/DataTable";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import I18nSpan from "../../i18n/components/I18nSpan";

import {
  addNotificationError,
  addNotificationSuccess,
  getSchedulerJobs,
  deleteSchedulerJob,
  fireSchedulerJob
} from "../../redux/actions";

import styles from "../styles/styles.css";

interface Props {
  getSchedulerJobs: Function;
  deleteSchedulerJob: Function;
  fireSchedulerJob: Function;
  addNotificationError: Function;
  addNotificationSuccess: Function;
  jobs: Array<any>;
}

interface State {}

class SchedulerMonitoringView extends Component<Props, State> {
  componentDidMount() {
    const { getSchedulerJobs } = this.props;
    getSchedulerJobs();
  }

  _fireJob = jobId => () => {
    const {
      fireSchedulerJob,
      addNotificationSuccess,
      addNotificationError,
      getSchedulerJobs
    } = this.props;
    fireSchedulerJob({ id: jobId }).then(
      () => {
        getSchedulerJobs();
        addNotificationSuccess("monitoring.scheduler.fire.success");
      },
      () => addNotificationError("monitoring.scheduler.fire.error")
    );
  };

  _deleteJob = jobId => () => {
    const {
      deleteSchedulerJob,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    deleteSchedulerJob({ id: jobId }).then(
      () => addNotificationSuccess("monitoring.scheduler.delete.success"),
      () => addNotificationError("monitoring.scheduler.delete.error")
    );
  };

  render() {
    const { jobs } = this.props;

    const FireButton = row => (
      <a
        key={row.jobId}
        onClick={this._fireJob(row.jobId)}
        title="Fire"
        className="btn btn-circle"
      >
        <i className="glyphicon glyphicon-send" aria-hidden="true" />
      </a>
    );

    return (
      <div className="data-table-wrapper schedulerJobs">
        <div className={styles["title-container"]}>
          <FormTitle
            titleKey="monitoring.title"
            actionKey="monitoring.scheduler.title"
          />

          <div className={styles.creation}>
            <Link
              className="btn btn-ingenico create-button"
              to={"/main/monitoring/new-scheduled-job"}
            >
              <span className="glyphicon glyphicon-plus" aria-hidden="true" />
              <I18nSpan msgKey="monitoring.scheduler.list.button.create" />
            </Link>
          </div>
        </div>

        <DataTable
          data={jobs}
          attributes={["jobId", "service", "timestamp"]}
          idAttribute="jobId"
          i18nKey="monitoring.scheduler.list"
          actionsElement={[FireButton]}
          onDeleteButtonClick={this._deleteJob}
          isRowEditable={() => false}
          hasActions={true}
          hideSearchBox={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSchedulerJobs: () => dispatch(getSchedulerJobs()),
  deleteSchedulerJob: ({ id }) => dispatch(deleteSchedulerJob({ id })),
  fireSchedulerJob: ({ id }) => dispatch(fireSchedulerJob({ id })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

const mapStateToProps = state => {
  const {
    schedulerJobs: { data: jobs },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  return { jobs, color };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SchedulerMonitoringView)
);
