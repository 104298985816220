import { ConnectionStore } from "../../connection/stores/ConnectionStore";

import * as _ from "lodash";

const merchantReceiptIsVisible = function(
  row: Array<any>,
  connectionStore: ConnectionStore
): boolean {
  const { hasMerchantReceipt } = row as any;

  return hasMerchantReceipt && canExportReceipt(connectionStore);
};

const canExportReceipt = function(connectionStore: ConnectionStore): boolean {
  const { isCustomer, isMerchant } = connectionStore;

  return isCustomer || isMerchant;
};

const canExportReconciliations = function(
  connectionStore: ConnectionStore,
  services
): boolean {
  const { isCustomer, isMerchant } = connectionStore;

  return (
    (isCustomer || isMerchant) && _.includes(services, "reconciliationDownload")
  );
};

export default { merchantReceiptIsVisible, canExportReconciliations };
