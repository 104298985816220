import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  FAILURE_ADD_VATRATE_ACTION,
  PENDING_ADD_VATRATE_ACTION,
  SUCCESS_ADD_VATRATE_ACTION
} from "../actionsTypes";

export const createVatRate = (
  vatRate: any,
  merchantId: string
) => async dispatch => {
  dispatch(addVatRateStart());

  try {
    const { name, value: vatRateValue } = vatRate;
    const value = parseFloat(vatRateValue);
    const vatRateDispatched = { name, value };
    await F.put(`/merchant/${merchantId}/vatrate/${name}`, {
      body: value,
      contentType: ContentTypes.JSON
    });

    return dispatch(addVatRateSucceeded(vatRateDispatched));
  } catch (errors) {
    dispatch(addVatRateFailure({ errors }));
    throw errors;
  }
};

export const addVatRateStart = () => ({
  type: PENDING_ADD_VATRATE_ACTION
});

export const addVatRateSucceeded = (vatRate: any) => ({
  type: SUCCESS_ADD_VATRATE_ACTION,
  payload: {
    vatRate
  }
});

export const addVatRateFailure = (errors: any) => ({
  type: FAILURE_ADD_VATRATE_ACTION,
  payload: {
    errors
  }
});
