import React, { Component } from "react";
import { withRouter } from "react-router";
import classNames from "classnames";

import I18nSpan from "../../i18n/components/I18nSpan";

interface Props {
  subMenu: any;
  location: any;
  isIngenico: boolean;
  onChange: Function;
  descriptor: any;
  history: any;
}

interface State {}

class SubMenu extends Component<Props, State> {
  _selectMenu(descriptorLink) {
    return this.setState({ openMenu: descriptorLink }, () => {
      const { onChange, descriptor, history } = this.props;

      if (descriptorLink) {
        onChange(descriptor);

        return history.push(`/main/${descriptorLink}`);
      }
    });
  }

  render() {
    const { subMenu, location, isIngenico } = this.props;
    const open = this.props.open ? "open" : "";

    if (subMenu) {
      return (
        <ul className={`sub-menu ${open}`}>
          {subMenu.map((subMenuDescriptor: any) => {
            const link = subMenuDescriptor.link
              ? `/main/${subMenuDescriptor.link}`
              : "";
            const isMenuSelected = location.pathname.includes(link);

            return (
              <li
                key={subMenuDescriptor.name}
                className={isMenuSelected ? "selected" : ""}
              >
                <div
                  className={classNames(
                    "sub-menu-title",
                    `menu-entry-${subMenuDescriptor.name}`,
                    { new: !isIngenico }
                  )}
                  onClick={() => this._selectMenu(subMenuDescriptor.link)}
                >
                  <span className={classNames({ new: !isIngenico })}>
                    <I18nSpan msgKey={`menu.${subMenuDescriptor.name}`} />
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
    return null;
  }
}

export default withRouter(SubMenu);
