import React from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import classNames from "classnames";
import AlertConstants from "../constants/AlertConstant";
import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "../styles/ListAlertView.css";

const CreateAdvanced = () => {
  return (
    <div className={styles.creation}>
      <Link
        className={classNames("btn", "btn-ingenico", "create-button")}
        to={`/main/reporting/alerts/${AlertConstants.ADVANCED_ALERT}/create`}
      >
        <span className="glyphicon glyphicon-plus" aria-hidden="true" />
        <I18nSpan msgKey="alert.list.button.create" />
      </Link>
    </div>
  );
};

const CreatePredefined = () => {
  return (
    <div className={styles.creation}>
      <Link
        className={classNames("btn", "btn-ingenico", "create-button")}
        to={`/main/reporting/alerts/${AlertConstants.PREDEFINED_ALERT}/create`}
      >
        <span className="glyphicon glyphicon-plus" aria-hidden="true" />
        <I18nSpan msgKey="alert.predefined.list.button.create" />
      </Link>
    </div>
  );
};

export const CreateAlert = ({
  alertType,
  transactionAlert = false,
  fraudAlert = false
}) => {
  switch (alertType) {
    case AlertConstants.ADVANCED_ALERT: {
      return transactionAlert ? <CreateAdvanced /> : null;
    }
    case AlertConstants.PREDEFINED_ALERT: {
      return transactionAlert && fraudAlert ? <CreatePredefined /> : null;
    }
    default:
      return null;
  }
};
