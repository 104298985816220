import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import Validators from "../../ingenicoForm/validation/Validators";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import { Sim, SierraFields } from "../constants/SimFieldConstants";

import {
  importSierraSim,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  importSierraSim: Function;
}

interface State {
  form: any;
  errors: any;
}

export class ImportSierraSimView extends Component<Props, State> {
  state = {
    form: {
      [Sim.ORDER_ID]: null
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  _goToList = () => {
    const { history } = this.props;

    history.push("/main/sim");
  };

  _saveBtnHandler = e => {
    e.preventDefault();
    const {
      importSierraSim,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    this.checkErrors(() => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const { form: sim } = this.state;
        importSierraSim({ sim })
          .then(sims => {
            addNotificationSuccess("sim.import.success", [sims.length]);
            this._goToList();
          })
          .catch(() => addNotificationError("sim.import.error"));
      }
    });
  };

  _onChangeHandler = (name, value) => {
    var newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this.checkErrors);
  };

  checkErrors = then => {
    var waitForValidation = (nameList, newErrors) => {
      if (_.isEmpty(nameList)) {
        this.setState({ errors: newErrors }, then);
      } else {
        const name: any = _.first(nameList);
        const value = _.get(this.state.form, name);
        const refsValue = _.get(this.inputRefs, name);

        const errors = refsValue
          ? [refsValue.props.validation(value)]
          : [this._noop()];
        Promise.all(errors).then(errors => {
          newErrors[name] = _(errors)
            .flatten()
            .compact()
            .value();
          waitForValidation(_.tail(nameList), newErrors);
        });
      }
    };
    waitForValidation(SierraFields, {});
  };

  _required = value => {
    const requiredPromise = new Promise(resolve => {
      resolve(Validators.requiredValidator(value));
    });
    return Promise.all([requiredPromise]);
  };

  render() {
    return (
      <div>
        <FormTitle titleKey="sim.title" actionKey="sim.import.action" />
        <form className="ingenico-form form-horizontal">
          <BootstrapInput
            validation={this._required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.ORDER_ID)}
            name={Sim.ORDER_ID}
            errors={this.state.errors.orderId}
            required={true}
            descriptor={{ type: "text", label: "sim.form.orderId" }}
            formValue={this.state.form.orderId}
          />
          <div className="pull-right">
            <button
              onClick={this._saveBtnHandler}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  importSierraSim: ({ sim }) => dispatch(importSierraSim({ sim })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(ImportSierraSimView);
