import React, { Component } from "react";
import get from "get-value";
import _ from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";

import ManufacturerUtils from "../../manufacturer/Utils";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import PoiAdvancedSearch from "../../poi/components/PoiAdvancedSearch";

import {
  getFields,
  getPois,
  getScroll,
  link,
  addNotificationSuccess,
  setSearchContext
} from "../../redux/actions";

import { setUrlWithParams } from "../../searchContext/URLizer";

import styles from "../styles/LinkPos.css";
import Counter from "../../datatable/components/Counter";
import { Refresh } from "../../datatable/components/Refresh";

const advancedSearchKey = AdvancedSearchConstants.SETTINGS_TERMINAL_KEY;

interface Props {
  getFields: Function;
  getPois: Function;
  getScroll: Function;
  link: Function;
  match: any;
  history: any;
}

class LinkPos extends Component<Props, {}> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.setUrlParams({ searchContext });
  }

  formatter = {
    "merchant.selectedTags": selectedTags => _.values(selectedTags).join(", "),
    "store.selectedTags": selectedTags => _.values(selectedTags).join(", "),
    "poi.manufacturerId": manufacturerCode =>
      ManufacturerUtils.manufacturerNameFromCode(manufacturerCode)
  };

  async componentDidMount() {
    const { getFields, searchContext } = this.props;

    await getFields({ name: "poi" });

    await this._onRefresh({ searchContext });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getPois } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getPois({ filters, sort, tableCount });
  };

  _search = ({ searchContext }) => {
    const {
      getPois,
      tableCount,
      setSearchContext,
      location: { pathname }
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    return getPois({ filters, sort, tableCount });
  };

  _onRowClick = (rowIndex, row) => {
    if (rowIndex === -1) {
      return;
    }
    const {
      match: {
        params: { posId }
      },
      history,
      link,
      addNotificationSuccess
    } = this.props;

    const { ["poi.id"]: poiId } = row;

    return link({ poiId, posId })
      .then(() => addNotificationSuccess("pos.link.success"))
      .then(() => history.push("/main/settings/pos"));
  };

  _loadMoreRows = () => {
    const { getPoisScroll, poiScrollId } = this.props;

    return getPoisScroll({ scrollId: poiScrollId });
  };

  render() {
    const {
      poiList,
      poiCount,
      attributes,
      sortDescription,
      searchContext,
      loading
    } = this.props;

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle titleKey="pos.title" actionKey="pos.link.action" />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={poiCount}
                loadingKey="poi.list.loading"
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>
        </div>
        <PoiAdvancedSearch
          searchContext={searchContext}
          onChange={this._search}
          name={advancedSearchKey}
        />

        <DataTable
          formatter={this.formatter}
          i18nKey="poi.list"
          idAttribute="poi.id"
          attributes={attributes}
          data={poiList}
          version="new"
          onRefresh={this._search}
          onRowClick={this._onRowClick}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={poiCount}
          hideSearchBox={true}
          hasActions={false}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const poisWithStatus = ({ state }) => {
  const {
    pois: { data, count, scrollId },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const pois = data.map(poi => {
    const normalizedPoi = fields.reduce((prevField, field) => {
      return {
        ...prevField,
        [field]: get(poi, field)
      };
    }, {});

    return normalizedPoi;
  });

  return {
    count,
    scrollId,
    pois
  };
};

const _getColumns = ({ type, fields }: GetColumnsParams) => {
  return fields.reduce((prevField: Array<string>, field: any) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);
};

const mapStateToProps = state => {
  const {
    pois: { loading },
    genericFields: { data: fields },
    config: {
      data: { tableCount }
    },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const {
    count: poiCount,
    pois: poiList,
    scrollId: poiScrollId
  } = poisWithStatus({
    state
  });
  const attributes = _getColumns({ fields });

  return {
    poiList,
    tableCount,
    poiCount,
    poiScrollId,
    attributes,
    sortDescription,
    searchContext,
    loading
  };
};

const mapDispatchToProps = dispatch => ({
  getFields: ({ name }) => dispatch(getFields({ name })),
  getPois: ({ filters, fields, sort, tableCount }) =>
    dispatch(getPois({ filters, fields, sort, tableCount })),
  getPoisScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "poi", scrollId })),
  link: ({ poiId, posId }) => dispatch(link({ poiId, posId })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LinkPos)
);
