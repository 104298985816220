import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import LoginInput from "./LoginMobileInput";
import PasswordInput from "./PasswordMobileInput";
import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "../styles/styles.mobile.css";

class AuthenticateMobileView extends Component<Props, {}> {
  render() {
    const {
      t,
      login,
      password,
      captcha,
      showPassword,
      onInputChange,
      onShowPassword
    } = this.props;

    return (
      <div className={styles["login-form-wrapper"]}>
        <LoginInput
          label={t("login.form.login")}
          onChange={onInputChange}
          value={login}
        />
        <PasswordInput
          label={t("login.form.password")}
          showPassword={showPassword}
          onShowPassword={onShowPassword}
          onChange={onInputChange}
          value={password}
        />
        <input type="hidden" id="captcha" name="captcha" value={captcha} />

        <div className={styles["submit-container"]}>
          <input
            type="submit"
            className={styles.buttonlink}
            value={t("login.form.signIn")}
          />
        </div>
        <div className={styles["small-link"]}>
          <Link to="/login/forgot-password" className={styles.link}>
            <I18nSpan msgKey="login.form.passwordForgot" />
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AuthenticateMobileView);
