import React, { Component } from "react";

import I18nSpan from "../i18n/components/I18nSpan";

interface Props {
  id?: string;
  i18nKey: string;
  onClose: Function;
}

class FilteredById extends Component<Props, {}> {
  render() {
    const { id, i18nKey, onClose } = this.props;

    if (id) {
      return (
        <div className="filterByOrderId">
          <I18nSpan msgKey={i18nKey} />
          <b>{id}</b>
          <a className="btn btn-sm btn-close" onClick={onClose}>
            <i className="glyphicon glyphicon-remove" />
          </a>
        </div>
      );
    }

    return null;
  }
}

export default FilteredById;
