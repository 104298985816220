import React, { Component } from "react";
import _ from "lodash";
import * as moment from "moment";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { CirclePicker } from "react-color";

import I18nSpan from "../../i18n/components/I18nSpan";
import FormTitle from "../../ingenicoForm/components/FormTitle";

import DateFormatter, { Dateformats } from "../../formatters/DateFormatter";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import UserValidations from "../validations/UserValidations";
import constants from "../../connection/constants/ConnectionConstant";
import Utils from "../../sim/Utils";

import {
  updateUserSettings,
  updateUserLanguage,
  addNotificationSuccess,
  addNotificationError,
  getPalettes
} from "../../redux/actions";

interface Props {
  history: any;
  color: any;
  loading: any;
}

interface State {
  color: string;
}

class EditCurrentUserView extends Component<Props, State> {
  state = {
    isMailValid: true,
    isTimezoneValid: true,
    form: {
      name: null,
      email: null,
      language: null
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(newProps: any) {
    const {
      form,
      errors,
      dateFormats,
      timezoneOptions,
      languages,
      palettes,
      palettesOptions,
      colors
    } = newProps;

    return this.setState(state => {
      return {
        ...state,
        form,
        errors,
        dateFormats,
        timezoneOptions,
        languages,
        palettes,
        colors,
        palettesOptions
      };
    });
  }

  async componentDidMount() {
    const {
      form,
      errors,
      dateFormats,
      timezoneOptions,
      languages,
      getPalettes
    } = this.props;

    getPalettes();
    await this.setState(state => {
      return {
        ...state,
        form,
        errors,
        dateFormats,
        timezoneOptions,
        languages
      };
    });
  }

  _onSave = event => {
    event.preventDefault();

    const {
      updateSettings,
      changeLocale,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    const { form } = this.state;

    this.checkErrors(() => {
      const hasErrors = _.any(form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const dateFormatPattern = DateFormatter.getDateFormatPattern(form)
          .replace("DD", "dd")
          .replace("YYYY", "yyyy");

        const user = {
          ...form,
          dateFormatPattern
        };

        Promise.all([
          updateSettings({
            data: user
          }),
          changeLocale(user.language)
        ]).then(
          () => {
            addNotificationSuccess("user.updateSettings.success");
            this._goToHome();
          },
          () => addNotificationError("notification.error")
        );
      }
    });
  };

  _goToHome() {
    const { history } = this.props;

    history.push("/main", { reload: true });
  }

  _goBack = event => {
    event.preventDefault();
    const { history } = this.props;

    history.push("/main");
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    const { palettes } = this.state;
    if (name === "defaultPaletteId") {
      const palette = Utils.findPaletteById(value, palettes);
      const colors = palette ? palette.colors : [];
      this.setState({ colors });
    }
    newForm[name] = value;
    this.setState(newForm, this.checkErrors);
  };

  _validateTimezone = timezone => {
    return Promise.all([UserValidations.required(timezone)]);
  };

  _validateEmail = email => {
    return Promise.all([
      UserValidations.required(email),
      UserValidations.email(email)
    ]);
  };

  _validateLanguage = language => {
    return Promise.all([UserValidations.required(language)]);
  };

  checkErrors(then) {
    const waitForValidation = (nameList: Array<string>, newErrors) => {
      if (_.isEmpty(nameList)) {
        this.setState({ errors: newErrors }, then);
      } else {
        const name = _.first(nameList);
        const value = _.get(this.state.form, name);
        const refsValue = _.get(this.inputRefs, name);

        const errors = refsValue
          ? [refsValue.props.validation(value)]
          : [this._noop()];
        Promise.all(errors).then(errors => {
          newErrors[name] = _.chain(errors)
            .flatten()
            .compact()
            .value();
          waitForValidation(_.tail(nameList), newErrors);
        });
      }
    };
    waitForValidation(_.keys(this.state.form), {});
  }

  render() {
    const { t: $t } = this.props;

    const {
      form: {
        name,
        email,
        language,
        timezone,
        msisdn,
        dateFormat,
        defaultPaletteId
      },
      dateFormats,
      timezoneOptions,
      languages,
      errors,
      palettesOptions,
      colors
    } = this.state;

    return (
      <div className="user-settings-container">
        <FormTitle
          titleKey="user.updateSettings.title"
          actionKey="user.updateSettings.action"
        />
        <form className="ingenico-form form-horizontal">
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            placeholder={$t(`user.form.name.placeholder`)}
            descriptor={{
              type: "text",
              label: "user.form.name.label"
            }}
            formValue={name}
          />
          <BootstrapInput
            validation={this._validateEmail}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("email")}
            name="email"
            placeholder={$t(`user.form.email.placeholder`)}
            descriptor={{
              type: "email",
              label: "user.form.email.label"
            }}
            formValue={email}
            required={true}
            errors={errors.email}
          />

          <BootstrapInput
            validation={this._validateLanguage}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("language")}
            name="language"
            placeholder={$t(`user.form.language.placeholder`)}
            descriptor={{
              type: "select",
              label: "user.form.language.label",
              options: languages
            }}
            formValue={language}
            required={true}
            errors={errors.language}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("msisdn")}
            name="msisdn"
            placeholder={$t(`user.form.name.placeholder`)}
            descriptor={{
              type: "text",
              label: "user.form.msisdn.label"
            }}
            formValue={msisdn}
            errors={errors.msisdn}
          />

          <BootstrapInput
            validation={this._validateTimezone}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("timezone")}
            name="timezone"
            placeholder={$t(`user.form.timezone.label`)}
            descriptor={{
              type: "singleautocomplete",
              label: "user.form.timezone.label",
              options: timezoneOptions
            }}
            formValue={timezone}
            errors={errors.timezone}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("dateFormat")}
            name="dateFormat"
            placeholder={$t(`user.form.dateFormat.label`)}
            descriptor={{
              type: "select",
              label: "user.form.dateFormat.label",
              options: dateFormats
            }}
            formValue={dateFormat}
            errors={errors.dateFormat}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("defaultPaletteId")}
            name="defaultPaletteId"
            placeholder={$t(`user.form.palette.label`)}
            descriptor={{
              type: "select",
              label: "user.form.palette.label",
              options: palettesOptions
            }}
            formValue={defaultPaletteId}
            errors={errors.defaultPaletteId}
          />
          <div className="form-group">
            {colors && colors.length !== 0 && (
              <div>
                <label className="control-label col-sm-3">
                  <I18nSpan msgKey="user.form.colorsPreview.label" />
                </label>
                <div className="col-sm-6 preview-palette">
                  <CirclePicker colors={colors} />
                </div>
              </div>
            )}
          </div>

          <div className="pull-right">
            <button
              className="btn btn-ingenico save-button"
              type="submit"
              onClick={this._onSave}
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goBack}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const generateDateFormats = ({ $t }) => {
  const currentDate = moment();
  const { formats } = Dateformats;
  const dateFormats = Object.entries(formats).reduce((acc, current) => {
    const [key, value] = current;
    const message = $t(`user.form.dateFormat.${key}`);
    const dateExample = currentDate.format(value.dateTime);
    return {
      ...acc,
      [key]: `${message} (${dateExample})`
    };
  }, {});
  return { dateFormats };
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, levelSettings },
    timezones: { data: timezones },
    palettes: { data: palettes = [] }
  } = state;
  const { t: $t } = ownProps;

  const {
    name,
    email,
    language,
    timezone,
    msisdn,
    dateFormat,
    defaultPaletteId
  } = user;

  const {
    scope: {
      level: { type: levelType }
    }
  } = user;

  const timezoneOptions = timezones.map(timezone => ({
    label: timezone,
    value: timezone
  }));

  const { dateFormats } = generateDateFormats({ $t });
  const palettesOptions = _.zipObject(
    palettes.map(item => [item.id, item.name])
  );

  const initialPalette = Utils.findPaletteById(defaultPaletteId, palettes);
  return {
    form: {
      name,
      email,
      language,
      timezone,
      msisdn,
      dateFormat,
      defaultPaletteId
    },
    errors: {},
    timezoneOptions,
    dateFormats,
    levelType,
    levelSettings,
    palettes,
    palettesOptions,
    colors: initialPalette ? initialPalette.colors : []
  };
};

const mapDispatchToProps = dispatch => ({
  updateSettings: ({ data }) => dispatch(updateUserSettings({ data })),
  changeLocale: newLocale => dispatch(updateUserLanguage(newLocale)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getPalettes: () => dispatch(getPalettes())
});

const mapUserLanguages = ({ languages, supportedLanguages, t }) => {
  const userLanguages = languages.reduce((acc, current) => {
    if (supportedLanguages.includes(current)) {
      return {
        ...acc,
        [current]: t(`language.${current}`)
      };
    }

    return acc;
  }, []);

  return { userLanguages };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { i18n, t } = ownProps;

  switch (stateProps.levelType) {
    case constants.CUSTOMER: {
      const settings =
        stateProps.levelSettings === null ? {} : stateProps.levelSettings;
      const { languages: customerLanguages = [] } = settings;
      const all = i18n.languages;

      const { userLanguages: languages } = mapUserLanguages({
        languages: all,
        supportedLanguages: customerLanguages,
        t
      });

      return {
        ...stateProps,
        ...dispatchProps,
        languages,
        ...ownProps
      };
    }
    case constants.MERCHANT: {
      const settings =
        stateProps.levelSettings === null ? {} : stateProps.levelSettings;
      const { languages: merchantLanguages = [] } = settings;
      const all = i18n.languages;

      const { userLanguages: languages } = mapUserLanguages({
        languages: all,
        supportedLanguages: merchantLanguages,
        t
      });

      return {
        ...stateProps,
        ...dispatchProps,
        languages,
        ...ownProps
      };
    }
    case constants.INGENICO:
      const all = i18n.languages;

      const { userLanguages: languages } = mapUserLanguages({
        languages: all,
        supportedLanguages: all,
        t
      });

      return {
        ...stateProps,
        ...dispatchProps,
        languages,
        ...ownProps
      };
    default:
      break;
  }
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(EditCurrentUserView);
