import _ from "lodash";
import DateFormatter from "../../formatters/DateFormatter";

export const buildParams = (searchParams, user?) => {
  const filledParams: any = _.pick(searchParams, _.identity);

  const begin = DateFormatter.getTimezonedDate(
    filledParams.timePeriod.startTime,
    user
  );
  const end = DateFormatter.getTimezonedDate(
    filledParams.timePeriod.endTime,
    user
  );

  const res: any = {
    begin,
    end
  };

  if (filledParams.filters && !_.isEmpty(filledParams.filters)) {
    res.filters = JSON.stringify(filledParams.filters);
  }
  if (filledParams.aggregation) {
    res.aggregation = filledParams.aggregation;
  }
  if (filledParams.alias) {
    res.alias = filledParams.alias;
  }

  return res;
};
