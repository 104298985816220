import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import MatchMobileContainer from "../../gridCharts/MatchMobileContainer";
import { LoginDesktopWrapper } from "./LoginDesktopWrapper";
import { LoginMobileWrapper } from "./LoginMobileWrapper";

import { loadRecaptcha } from "../utils/recaptcha";
import { getCscCookie } from "../../../helpers/cookies/Cookies";

import {
  addNotificationError,
  addNotificationSuccess,
  getCustomCss,
  getConfig
} from "../../redux/actions";

interface Props {
  history: any;
  customCss: any;
  apiGatewayCscURL: any;
  getCustomCss: Function;
  getConfig: Function;
}

interface State {}

class LoginWrapper extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    const { getCustomCss, getConfig } = this.props;

    const [{ "reCaptchaSiteKey-v3": reCaptchaSiteKey }] = await Promise.all([
      getConfig(),
      getCustomCss({ domain: location.hostname })
    ]);
    loadRecaptcha({ reCaptchaSiteKey });
  }

  render() {
    const { children, customCss } = this.props;

    // TODO : fix expired token for CSC users
    /*  const { apiGatewayCscURL } = this.props;
    const flux = this.getFlux();
    const { belongsToCscUnauthenticated, removeUserCscKey } = flux.store(
      "connectionStore"
    );

    if (belongsToCscUnauthenticated()) {
      if (apiGatewayCscURL) {
        const cscToken = getCscCookie();

        removeUserCscKey();

        return location.assign(
          `${apiGatewayCscURL}/logoutAndRedirect?cscId=${cscToken}`
        );
      }

      return null;
    } */

    return (
      <Fragment>
        <MatchMobileContainer
          renderMobile={() => <LoginMobileWrapper children={children} />}
          renderDesktop={() => <LoginDesktopWrapper children={children} />}
        />
        {customCss && <style>{customCss}</style>}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    config: {
      data: { apiGatewayCscURL }
    },
    theme: {
      customCss: {
        data: { css: customCss }
      }
    }
  } = state;

  return {
    customCss,
    apiGatewayCscURL
  };
};

const mapDispatchToProps = dispatch => ({
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getCustomCss: ({ domain }) => dispatch(getCustomCss({ domain })),
  getConfig: () => dispatch(getConfig())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginWrapper)
);
