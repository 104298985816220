import React from "react";

import KeyIndicator from "../KeyIndicator/KeyIndicator";
import I18nNumber from "../../i18n/components/I18nNumber";
import { withTranslation } from "react-i18next";

interface Props {
  loading: boolean;
  error?: boolean;
  title: string;
  color: string;
  count: string;
  renderTrend: Function;
}

const TransactionNumber = ({
  title,
  color,
  error,
  loading,
  count,
  renderTrend
}: Props) => {
  return (
    <KeyIndicator
      color={color}
      titleKey={title}
      loading={loading}
      error={error}
      renderTrend={() => renderTrend()}
    >
      <I18nNumber number={count} format="0,0" />
    </KeyIndicator>
  );
};

export default withTranslation()(TransactionNumber);
