import {
  FAILURE_GET_PERMISSIONS_FORM_ACTION,
  PENDING_GET_PERMISSIONS_FORM_ACTION,
  SUCCESS_GET_PERMISSIONS_FORM_ACTION
} from "../actionsTypes";

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_PERMISSIONS_FORM_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_PERMISSIONS_FORM_ACTION:
      const {
        payload: { permissionsForm }
      } = action;

      return {
        ...state,
        data: permissionsForm
      };
    case FAILURE_GET_PERMISSIONS_FORM_ACTION:
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    default:
      return state;
  }
};
