import React from "react";
import classNames from "classnames";
import SwitchSelector from "react-switch-selector";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container/Container";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Div100vh from "../Div100vh/Div100vh";

import * as actions from "../../redux/actions";

import styles from "./EditCurrentUserMobile.css";
import LogoFooter from "../TerminalMobile/LogoFooter/LogoFooter";
import Grid from "@material-ui/core/Grid/Grid";
import LogoutMobile from "./Logout/LogoutMobile";
import DividerMobile from "./DividerMobile";
import MobileHeader from "../TerminalMobile/Header/MobileHeader";
import AccountMobile from "./Account/AccountMobile";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

const EditCurrentUserMobileView = () => {
  const { t } = useTranslation();

  const { darkMode: prefersDarkMode } = useSelector(state =>
    mapStateToProps(state)
  );

  const dispatch = useDispatch();

  const setDarkMode = mode => dispatch(actions.setDarkMode(mode));

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: prefersDarkMode ? "#252525" : "#f2f2f2",
        height: "calc(var(--vh, 1vh) * 100)",
        overflow: "scroll"
      },
      accountContainer: {
        padding: "0 10vw 0 10vw"
      },
      divider: {
        width: "100%",
        padding: "calc(var(--vh, 1vh) * 2) 0 calc(var(--vh, 1vh) * 2) 0"
      },
      gridAccount: {
        width: "100%"
      }
    })
  );

  const classes = useStyles();

  const matches = useMediaQuery(`(max-width: 768px)`);

  const options = [
    {
      label: "light",
      value: "light",
      selectedBackgroundColor: prefersDarkMode ? "#353535" : "#FFFFFF"
    },
    {
      label: "dark",
      value: "dark",
      selectedBackgroundColor: prefersDarkMode ? "#353535" : "#FFFFFF"
    }
  ];

  const onChange = newMode => setDarkMode(newMode);

  const initialSelectedIndex = options.findIndex(
    ({ value }) => value === (prefersDarkMode ? "dark" : "light")
  );

  if (matches) {
    return (
      <Div100vh>
        <Container disableGutters className={classes.root}>
          <MobileHeader />
          <Grid
            container
            className={classes.accountContainer}
            direction="column"
          >
            <AccountMobile />
            <DividerMobile />
            <Grid item direction="column" className={classes.gridAccount}>
              <div className={styles["appearance-wrapper"]}>
                <span
                  className={classNames(styles["appearance-label"], {
                    [styles["appearance-label--dark"]]: prefersDarkMode,
                    [styles["appearance-label--light"]]: !prefersDarkMode
                  })}
                >
                  APPEARANCE
                </span>
                <div className={styles["appearance-switch-selector-wrapper"]}>
                  <SwitchSelector
                    onChange={onChange}
                    options={options}
                    initialSelectedIndex={initialSelectedIndex}
                    backgroundColor={prefersDarkMode ? "#2A2A2A" : "#E9E9E9"}
                    fontSize={11}
                    fontColor={prefersDarkMode ? "#505050" : "#C5C5C5"}
                    selectedFontColor={prefersDarkMode ? "#FFFFFF" : "#111111"}
                    wrapperBorderRadius={30}
                    optionBorderRadius={30}
                  />
                </div>
              </div>
            </Grid>
            <DividerMobile />
            <LogoutMobile />
          </Grid>
          <LogoFooter />
        </Container>
      </Div100vh>
    );
  }

  return null;
};

export default EditCurrentUserMobileView;
