import React, { useState } from "react";
import { useSelector } from "react-redux";

import LogoSidebar from "../../../assets/images/logo_sidebar.svg";

import styles from "./LogoMarkup.css";

const mapStateToProps = state => {
  const {
    auth: { isMerchant, isIngenico },
    theme: {
      logo: { data: logoContent }
    }
  } = state;

  return { logoContent, isMerchant, isIngenico };
};

export const LogoMarkup = () => {
  const { logoContent, isIngenico } = useSelector(state =>
    mapStateToProps(state)
  );

  return (
    <div className={styles["logo-ingenico"]}>
      <div className={styles.logo}>
        {isIngenico || logoContent === null ? (
          <LogoSidebar />
        ) : (
          <img src={logoContent} className={styles["logo-type"]} />
        )}
      </div>
    </div>
  );
};
