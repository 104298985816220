import React, { Component } from "react";
import { connect } from "react-redux";

import KeyIndicator, { Loading } from "./KeyIndicator";
import { WidgetConfig } from "../Widget";
import { getData } from "../../utils/DataProvider";

import styles from "./style/SimVolumesBanner.css";
import numeral from "numeral";

interface Data {
  volumes: Array<any>;
}

interface Props {
  getData: Function;
  data: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  id: string;
  config: WidgetConfig;
}

class SimVolumesBanner extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ config });
    }
  }

  render() {
    const {
      data: { volumes = [] },
      color,
      loading,
      error
    } = this.props;

    return (
      <div className={styles["volumes-banner"]}>
        <Loading loading={loading} color={color}>
          <div className={styles.container}>
            {volumes.map((aggregation, index) => {
              const simVolumeValue =
                aggregation.simVolumeToTerminal.value +
                aggregation.simVolumeFromTerminal.value;

              return (
                <div key={`${index}-${aggregation.contract}`}>
                  <KeyIndicator
                    color={color}
                    titleKey={`sim.values.contract.${aggregation.contract}`}
                    loading={loading}
                    error={error}
                  >
                    <span>{numeral(simVolumeValue).format("0.0b")}</span>
                    <span> / </span>
                    <span>
                      {numeral(aggregation.total * aggregation.total).format(
                        "0b"
                      )}
                    </span>
                  </KeyIndicator>
                </div>
              );
            })}
          </div>
        </Loading>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }
  return {
    data: {},
    loading: true
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SimVolumesBanner);
