import { constantCase } from "constant-case";
import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_USERS_ACTION,
  SUCCESS_GET_USERS_ACTION,
  FAILURE_GET_USERS_ACTION,
  PENDING_GET_USERS_HIERARCHY_ACTION,
  SUCCESS_GET_USERS_HIERARCHY_ACTION,
  FAILURE_GET_USERS_HIERARCHY_ACTION,
  PENDING_UPDATE_STATUS_USER_ACTION,
  SUCCESS_UPDATE_STATUS_USER_ACTION,
  FAILURE_UPDATE_STATUS_USER_ACTION,
  PENDING_DELETE_USER_ACTION,
  SUCCESS_DELETE_USER_ACTION,
  FAILURE_DELETE_USER_ACTION,
  PENDING_RESET_USER_PASSWORD_ACTION,
  SUCCESS_RESET_USER_PASSWORD_ACTION,
  FAILURE_RESET_USER_PASSWORD_ACTION,
  CHANGE_USER_MODE_ACTION,
  RESET_USER_MODE_ACTION
} from "../actionsTypes";

export const getUsers = () => dispatch => {
  dispatch(getUsersStart());

  return F.get("/user").then(
    users => {
      dispatch(getUsersSucceeded(users));
      return users;
    },
    errors => {
      dispatch(getUsersFailure({ errors }));

      throw errors;
    }
  );
};

const getUsersStart = () => ({
  type: PENDING_GET_USERS_ACTION
});

const getUsersSucceeded = users => ({
  type: SUCCESS_GET_USERS_ACTION,
  payload: {
    users
  }
});

const getUsersFailure = errors => ({
  type: FAILURE_GET_USERS_ACTION,
  payload: {
    errors
  }
});

export const getUsersHierarchy = ({ urlLevel }) => dispatch => {
  dispatch(getUsersHierarchyStart());
  const url = urlLevel ? `/user/level/merchant` : `/user/level/customer`;

  return F.get(url, "v2").then(
    users => {
      dispatch(getUsersHierarchySucceeded(users));
      return users;
    },
    errors => {
      dispatch(getUsersHierarchyFailure({ errors }));

      throw errors;
    }
  );
};

const getUsersHierarchyStart = () => ({
  type: PENDING_GET_USERS_HIERARCHY_ACTION
});

const getUsersHierarchySucceeded = users => ({
  type: SUCCESS_GET_USERS_HIERARCHY_ACTION,
  payload: {
    users
  }
});

const getUsersHierarchyFailure = errors => ({
  type: FAILURE_GET_USERS_HIERARCHY_ACTION,
  payload: {
    errors
  }
});

export const deleteUser = ({ userId }) => async dispatch => {
  dispatch(deleteUserStart());

  try {
    await F.delete(`/user/${userId}`);
    return dispatch(deleteUserSucceeded(userId));
  } catch (errors) {
    dispatch(deleteUserFailure({ errors }));

    throw errors;
  }
};

const deleteUserStart = () => ({
  type: PENDING_DELETE_USER_ACTION
});

const deleteUserSucceeded = userId => ({
  type: SUCCESS_DELETE_USER_ACTION,
  payload: {
    userId
  }
});

const deleteUserFailure = ({ errors }) => ({
  type: FAILURE_DELETE_USER_ACTION,
  payload: {
    errors
  }
});

export const updateUserStatus = ({
  id: login,
  status: newStatus
}) => dispatch => {
  dispatch(updateUserStatusStart());

  try {
    F.put(`/user/${login}/status/${newStatus}`);
    return dispatch(
      updateUserStatusSucceeded({
        id: login,
        status: newStatus
      })
    );
  } catch (errors) {
    dispatch(updateUserStatusFailure({ errors }));

    throw errors;
  }
};

const updateUserStatusStart = () => ({
  type: PENDING_UPDATE_STATUS_USER_ACTION
});

const updateUserStatusSucceeded = ({ id, status }) => ({
  type: SUCCESS_UPDATE_STATUS_USER_ACTION,
  payload: {
    id,
    status
  }
});

const updateUserStatusFailure = ({ errors }) => ({
  type: FAILURE_UPDATE_STATUS_USER_ACTION,
  payload: {
    errors
  }
});

export const resetUserPassword = userId => dispatch => {
  dispatch(resetUserPasswordStart());

  return F.post(`/users/${userId}/token`).then(
    () => dispatch(resetUserPasswordSucceeded()),
    errors => {
      dispatch(resetUserPasswordFailure({ errors }));

      throw errors;
    }
  );
};

const resetUserPasswordStart = () => ({
  type: PENDING_RESET_USER_PASSWORD_ACTION
});

const resetUserPasswordSucceeded = () => ({
  type: SUCCESS_RESET_USER_PASSWORD_ACTION
});

const resetUserPasswordFailure = ({ errors }) => ({
  type: FAILURE_RESET_USER_PASSWORD_ACTION,
  payload: {
    errors
  }
});

export const revokeToken = ({ type, userId }) => dispatch => {
  dispatch(revokeTokenStart(type));

  return F.post(`/revoke/${type}/${userId}`).then(
    () => dispatch(revokeTokenSucceeded(type)),
    errors => {
      dispatch(revokeTokenFailure({ type, errors }));

      throw errors;
    }
  );
};

const revokeTokenStart = revokeType => {
  const revokeTypeName = constantCase(revokeType);
  const type = `PENDING_REVOKE_${revokeTypeName}_ACTION`;

  return {
    type
  };
};

const revokeTokenSucceeded = revokeType => {
  const revokeTypeName = constantCase(revokeType);
  const type = `SUCCESS_REVOKE_${revokeTypeName}_ACTION`;

  return {
    type
  };
};

const revokeTokenFailure = ({ type: revokeType, errors }) => {
  const revokeTypeName = constantCase(revokeType);
  const type = `FAILRE_REVOKE_${revokeTypeName}_ACTION`;

  return {
    type,
    payload: {
      errors
    }
  };
};

export const changeUserMode = userMode => ({
  type: CHANGE_USER_MODE_ACTION,
  payload: {
    userMode
  }
});

export const resetUserMode = () => ({
  type: RESET_USER_MODE_ACTION
});
