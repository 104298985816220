import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_CURRENCIES_ACTION,
  SUCCESS_GET_CURRENCIES_ACTION,
  FAILURE_GET_CURRENCIES_ACTION
} from "../actionsTypes";

const getCurrenciesStart = () => ({
  type: PENDING_GET_CURRENCIES_ACTION
});

const getCurrenciesSucceeded = currencies => ({
  type: SUCCESS_GET_CURRENCIES_ACTION,
  payload: {
    currencies
  }
});

const getCurrenciesFailure = errors => ({
  type: FAILURE_GET_CURRENCIES_ACTION,
  payload: {
    errors
  }
});

export const getCurrencies = () => dispatch => {
  dispatch(getCurrenciesStart());

  return F.get("/currency").then(
    currencies => dispatch(getCurrenciesSucceeded(currencies)),
    errors => {
      dispatch(getCurrenciesFailure(errors));

      throw errors;
    }
  );
};
