import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import * as $ from "jquery";
import get from "get-value";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";

import DateFormatter from "../../formatters/DateFormatter";
import advancedSearchConstants, {
  default as AdvancedSearchConstants
} from "../../advancedSearch/constants/AdvancedSearchConstants";
import I18nSpan from "../../i18n/components/I18nSpan";
import TimePeriod from "../models/TimePeriod";
import Formats from "../../commons/constants/ExportFormats";
import Counter from "../../datatable/components/Counter";
import TerminalAdvancedSearch from "../../poi/components/TerminalAdvancedSearch";
import ExportPopin from "../../components/ExportPopin";
import ManufacturerUtils from "../../manufacturer/Utils";

import { generateUrl } from "../../commons/utils/url";

import {
  getFields,
  getTerminalActivities,
  resetTerminalActivities,
  getScroll,
  getTerminalActivitiesConnections,
  resetTerminalActivitiesConnections,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  setSearchContext,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../redux/actions";
import { ExportAction } from "../../datatable/components/multipleActions";
import { Refresh } from "../../datatable/components/Refresh";
import { setUrlWithParams } from "../../searchContext/URLizer";

import styles from "../styles/styles.css";

interface Props {
  getFields: Function;
  getTerminalActivities: Function;
  attributes: any;
  color: string;
  count: any;
  terminals: any;
  fields: any;
  visibleAttributes: any;
  formatter: any;
  hasActivities: any;
  sortDescription: any;
  services: any;
  filters: any;
  getTerminalActivitiesScroll: Function;
  terminalActivitiesScrollId: any;
  getConnections: Function;
}

interface State {
  showPopinSimple: boolean;
  showPopinDetailed: boolean;
  poiId?: any;
}

const advancedSearchKey = AdvancedSearchConstants.MONITORING_TERMINAL_KEY;

class TerminalActivitiesView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      showPopinSimple: false,
      showPopinDetailed: false
    };
    this.setUrlParams({ searchContext });
  }

  componentWillUnmount() {
    $(document.body).off("keydown", this.handleKeyDown);
  }

  async componentDidMount() {
    const { getFields, getConnections, searchContext } = this.props;
    const name = advancedSearchConstants.POI_MONITORING;
    await getFields({ name });

    $(document.body).on("keydown", this.handleKeyDown);

    const timePeriod = TimePeriod.MONTH_TO_DATE;
    timePeriod.refreshTime();

    const begin = timePeriod.startTime.valueOf();
    const end = timePeriod.endTime.valueOf();

    getConnections({ begin, end });

    this._onRefresh({ searchContext });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getTerminalActivities } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getTerminalActivities({ filters, sort, tableCount });
  };

  _search = ({ searchContext }) => {
    const {
      getTerminalActivities,
      tableCount,
      setSearchContext,
      location: { pathname }
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    getTerminalActivities({ filters, sort, tableCount });
  };

  handleKeyDown = event => {
    const { showPopinSimple, showPopinDetailed } = this.state;

    const atLeastOnePopinOpen = showPopinSimple || showPopinDetailed;
    const escapeKeyCode = 27;

    if (event.keyCode === escapeKeyCode && atLeastOnePopinOpen) {
      this._closePopinDetailed();
      this._closePopinSimple();
    }
  };

  _openPopinSimple = () => {
    this.setState({ showPopinSimple: true });
  };

  _closePopinSimple = () => {
    this.setState({ showPopinSimple: false });
  };

  _openPopinDetailed = () => {
    const { selectedTerminalIds } = this.props;
    const [poiId] = selectedTerminalIds;

    this.setState({ showPopinDetailed: true, poiId });
  };

  _closePopinDetailed = () => {
    this.setState({ showPopinDetailed: false, poiId: "" });
  };

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = AdvancedSearchConstants.MONITORING_TERMINAL_KEY;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  _loadMoreRows = () => {
    const {
      getTerminalActivitiesScroll,
      terminalActivitiesScrollId
    } = this.props;

    return getTerminalActivitiesScroll({
      scrollId: terminalActivitiesScrollId
    });
  };

  onSelectRow = (rowId, value) => {
    const { selectedTerminalIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedTerminalIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const { showPopinSimple, showPopinDetailed, poiId } = this.state;
    const {
      attributes,
      color,
      count,
      terminals,
      fields,
      visibleAttributes,
      formatter,
      hasActivities,
      sortDescription,
      services,
      filters,
      loading,
      isCustomer,
      searchContext,
      t,
      selectedTerminalIds
    } = this.props;

    const formatsSimple = [
      {
        url: generateUrl({
          url: `/reporting/terminal/activities?begin=:begin&end=:end&filters=:filters&format=${Formats.CSV}`
        }),
        label: "monitoringPoiSim.terminalActivities.list.button.exportCSV"
      }
    ];

    const formatsDetailed = [
      {
        url: generateUrl({
          url: `/reporting/terminal/activities?begin=:begin&end=:end&poiId=:id&format=${Formats.CSV}`
        }),
        label:
          "monitoringPoiSim.terminalActivities.list.button.exportDetailedCSV"
      }
    ];

    const exportTerminalActivities = () => {
      if (isCustomer && selectedTerminalIds.length === 1) {
        return (
          <ExportAction
            onExport={this._openPopinDetailed}
            msgKey={"dataTable.download"}
          />
        );
      }

      return null;
    };

    interface TerminalConnectionsType {
      services: Array<string>;
    }

    const TerminalConnections = ({ services }: TerminalConnectionsType) => {
      const hasMonitoringPayment = _.includes(
        services,
        "terminalMonitoringPayment"
      );

      if (hasMonitoringPayment) {
        return (
          <Link to={`/main/reporting/terminal-connections`}>
            <button className="btn btn-ingenico mrn mvl">
              <span className="glyphicon glyphicon-phone" />
              <I18nSpan
                msgKey={
                  "monitoringPoiSim.terminalActivities.list.button.connections"
                }
              />
            </button>
          </Link>
        );
      }

      return null;
    };

    return (
      <div className="data-table-wrapper reporting">
        <div className={styles["title-wrapper"]}>
          <FormTitle
            color={color}
            titleKey="monitoringPoiSim.mainTitle"
            actionKey="monitoringPoiSim.terminalActivities.mainTitle"
          />

          <div className={styles["countAndRefresh-container"]}>
            <Counter
              count={count}
              loadingKey="poi.list.loading"
              loading={loading}
            />
            <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
          </div>
        </div>
        <ExportPopin
          searchContext={searchContext}
          showPopin={showPopinSimple}
          onClosePopin={this._closePopinSimple}
          urls={formatsSimple}
          enableExport={hasActivities}
          filters={filters}
          hideDataTypeSelect={true}
          title="monitoringPoiSim.terminalActivities.export.title"
        />

        <div className="flexButtons">
          <button
            className="btn btn-ingenico pull-right mrn mvl"
            onClick={this._openPopinSimple}
          >
            <span className="glyphicon glyphicon-download" />
            <I18nSpan msgKey={"reporting.transaction.list.button.export"} />
          </button>

          <TerminalConnections services={services} />
        </div>

        <ExportPopin
          searchContext={searchContext}
          showPopin={showPopinDetailed}
          hideDataTypeSelect={true}
          onClosePopin={this._closePopinDetailed}
          urls={formatsDetailed}
          optId={poiId}
          filters={filters}
          enableExport={hasActivities}
          title="monitoringPoiSim.terminalActivities.export.title"
        />

        <div className="advanced-search-wrapper">
          <TerminalAdvancedSearch
            searchContext={searchContext}
            onChange={this._search}
          />
        </div>

        <DataTable
          fields={fields}
          data={terminals}
          notSortableFields={["merchant.selectedTags", "store.selectedTags"]}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          i18nKey="monitoringPoiSim.terminalActivities.list"
          idAttribute="poi.id"
          color={color}
          hideSearchBox={true}
          toggleable={true}
          formatter={formatter}
          sortKey={advancedSearchKey}
          onUpdateColumns={this._updateColumns}
          version="new"
          onRefresh={this._search}
          remoteRowCount={count}
          loadMoreRowsAction={this._loadMoreRows}
          searchContext={searchContext}
          sortDescription={sortDescription}
          hasActions={false}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedTerminalIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[exportTerminalActivities]}
        />

        <style>
          {`
                        .btn-ingenico,.btn-ingenico:focus {
                            color: ${color};
                            border-color: ${color};
                        }

                        .btn-ingenico:hover {
                            color: #FFF;
                            background-color: ${color};
                        }

                        .flexButtons {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: row-reverse;
                        }
                    `}
        </style>
      </div>
    );
  }
}

const terminalWithStatus = ({ getMessage, state }) => {
  const {
    terminalActivities: { data, scrollId, count, loading },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const terminals = data.map(terminal => {
    const {
      poi: { status }
    } = terminal;
    const normalized = fields.reduce((prevField, field) => {
      return {
        ...prevField,
        [field]: get(terminal, field)
      };
    }, {});

    return {
      ...normalized,
      statusi18n: getMessage(`dataTable.state.${status}`)
    };
  });

  return {
    count,
    scrollId,
    terminals,
    loading
  };
};

interface GetColumnsParams {
  type: string;
  fields: Array<string>;
}

const _getColumns = ({ type, fields }: GetColumnsParams) => {
  return fields.reduce((prevField: Array<string>, field: any) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);
};

const getVisibleAttributes = ({ columnSetting = {}, fields, key }) => {
  const { [key]: visibleAttributes } = columnSetting;

  return columnSetting && visibleAttributes && visibleAttributes.length > 0
    ? visibleAttributes
    : _getColumns({ fields });
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isCustomer },
    genericFields: { data: fields },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount }
    },
    services: { data: services = [] },
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext },
    terminalActivities: { selection }
  } = state;
  const { t: getMessage } = ownProps;

  const {
    count,
    scrollId: terminalActivitiesScrollId,
    terminals,
    loading
  } = terminalWithStatus({
    getMessage,
    state
  });

  const formatDate = val => DateFormatter.formatAndLocalizeDateTime(val, user);

  const datesColumns = fields.reduce((prevField, field) => {
    const { key, searchable, type } = field;
    if (!searchable && type === "date") prevField.push(key);
    return prevField;
  }, []);

  const formatters: any = _.zipObject(
    datesColumns,
    datesColumns.map(() => formatDate)
  );

  formatters["merchant.selectedTags"] = selectedTags =>
    _.values(selectedTags).join(", ");
  formatters["store.selectedTags"] = selectedTags =>
    _.values(selectedTags).join(", ");
  formatters["poi.manufacturerId"] = manufacturerCode =>
    ManufacturerUtils.manufacturerNameFromCode(manufacturerCode);

  const columnSetting = user ? user.columnSetting : false;

  const visibleAttributes = getVisibleAttributes({
    columnSetting,
    fields,
    key: "simMonitoring"
  });
  const attributes = _getColumns({ fields });
  const selectedTerminalIds = _.keys(_.pick(selection, _.identity));

  return {
    searchContext,
    loading,
    color,
    count,
    tableCount,
    terminals,
    fields,
    services,
    terminalActivitiesScrollId,
    hasActivities: terminals.length > 0,
    formatter: formatters,
    visibleAttributes,
    attributes,
    sortDescription,
    isCustomer,
    filters,
    selectedTerminalIds
  };
};

const mapDispatchToProps = dispatch => ({
  resetTerminalActivities: () => dispatch(resetTerminalActivities()),
  getTerminalActivities: ({ filters, fields, sort, tableCount }) =>
    dispatch(getTerminalActivities({ filters, fields, sort, tableCount })),
  getTerminalActivitiesScroll: ({ scrollId }) => {
    dispatch(
      getScroll({ type: advancedSearchConstants.SIM_NEW_KEY, scrollId })
    );
  },
  getFields: ({ name }) => dispatch(getFields({ name })),
  getConnections: ({ begin, end }) =>
    dispatch(getTerminalActivitiesConnections({ begin, end })),
  resetConnections: () => dispatch(resetTerminalActivitiesConnections()),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    ),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "TERMINAL_ACTIVITIES" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "TERMINAL_ACTIVITIES" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "TERMINAL_ACTIVITIES" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "TERMINAL_ACTIVITIES" }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TerminalActivitiesView);
