import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { connect } from "react-redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import Validators from "../../ingenicoForm/validation/Validators";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";

import {
  addNotificationError,
  addNotificationSuccess,
  createSchedulerJob
} from "../../redux/actions";

interface Props {
  createSchedulerJob: Function;
  addNotificationError: Function;
  addNotificationSuccess: Function;
  jobs: Array<any>;
  history: any;
}

interface State {}

const scheduleJobServices = ["alipay-reconciliation", "acquirer-settlement"];

class CreateScheduledJob extends Component<Props, State> {
  state = {
    form: {
      jobId: null,
      timestamp: null,
      service: null
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  _goToList = e => {
    e.preventDefault();
    const { history } = this.props;

    history.push("/main/monitoring/scheduler");
  };

  _saveBtnHandler = e => {
    e.preventDefault();
    const {
      createSchedulerJob,
      addNotificationSuccess,
      addNotificationError,
      history
    } = this.props;

    this.checkErrors(() => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        createSchedulerJob({ data: this.state.form }).then(
          () => {
            addNotificationSuccess("monitoring.scheduler.create.success");

            history.push("/main/monitoring/scheduler");
          },
          () => addNotificationError("notification.error")
        );
      }
    });
  };

  _onChangeHandler = (name, value) =>
    this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value
        }
      };
    });

  checkErrors(then) {
    var waitForValidation = (nameList, newErrors) => {
      if (_.isEmpty(nameList)) {
        this.setState({ errors: newErrors }, then);
      } else {
        const name = _.first(nameList);
        const value = _.get(this.state.form, name);
        const refsValue = _.get(this.inputRefs, name);

        const errors = refsValue
          ? [refsValue.props.validation(value)]
          : [this._noop()];
        Promise.all(errors).then(errors => {
          newErrors[name] = _.chain(errors)
            .flatten()
            .compact()
            .value();
          waitForValidation(_.tail(nameList), newErrors);
        });
      }
    };
    waitForValidation(_.keys(this.state.form), {});
  }

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  _required = value => {
    const requiredPromise = new Promise(resolve => {
      resolve(Validators.requiredValidator(value));
    });

    return Promise.all([requiredPromise]);
  };

  render() {
    const { color } = this.props;
    return (
      <div>
        <FormTitle
          color={color}
          titleKey="monitoring.scheduler.create.title"
          actionKey="monitoring.scheduler.create.action"
        />
        <form className="ingenico-form form-horizontal create-scheduled-job-form">
          <BootstrapInput
            validation={this._required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("jobId")}
            name="jobId"
            errors={this.state.errors.jobId}
            required={true}
            descriptor={{
              type: "text",
              label: "monitoring.scheduler.create.form.jobId.label"
            }}
            formValue={this.state.form.jobId}
          />
          <BootstrapInput
            validation={this._required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("timestamp")}
            name="timestamp"
            errors={this.state.errors.timestamp}
            required={true}
            descriptor={{
              type: "text",
              label: "monitoring.scheduler.create.form.timestamp.label"
            }}
            formValue={this.state.form.timestamp}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            name="service"
            required={false}
            descriptor={{
              type: "select",
              options: scheduleJobServices,
              label: "monitoring.scheduler.create.form.service.label"
            }}
            formValue={this.state.form.service}
          />
          <div className="pull-right">
            <button
              onClick={this._saveBtnHandler}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey="button.label.ok" />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createSchedulerJob: ({ data }) => dispatch(createSchedulerJob({ data })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

const mapStateToProps = state => {
  const {
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  return { color };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateScheduledJob)
);
