import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

export interface SecuredVariable {
  [key: string]: string;
}

export default {
  list(userlevel: string, id: string) {
    return F.get(`/${userlevel}/${id}/secvars`).then(securedVariables => {
      return { securedVariables };
    });
  },

  create(userlevel: string, id: string, securedVariable: SecuredVariable) {
    const { name, value } = securedVariable;
    return F.put(`/${userlevel}/${id}/secvars/${name}`, {
      body: value,
      contentType: ContentTypes.JSON
    });
  },

  delete(userlevel: string, id: string, name: string) {
    return F.delete(`/${userlevel}/${id}/secvars/${name}`);
  }
};
