import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import WidgetView, { WidgetAdd } from "./Widget";
import I18nSpan from "../../i18n/components/I18nSpan";

import { WidgetDrag } from "../utils/WidgetDrag";
import { WidgetConfig } from "./Widget";
import { WidgetsGrid } from "./WidgetsGrid";
import DateFormatter from "../../formatters/DateFormatter";
import { getData, getTrend, getDataWithTrend } from "../utils/DataProvider";
import { TimePeriod } from "../../reporting/models/TimePeriod";

import {
  getWidgets,
  deleteWidget,
  updateWidget,
  resetWidgetsState,
  resetWidgetsStatsState,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

import styles from "../styles/home.css";

class HomeDesktopView extends Component<Props, State> {
  async componentDidMount() {
    const { getWidgets, canSeeWidget } = this.props;

    if (canSeeWidget) {
      WidgetDrag.sortableWidget(this);

      getWidgets();
    }
  }

  componentWillUnmount() {
    const { resetWidgetsState, resetWidgetsStatsState } = this.props;

    resetWidgetsState();
    resetWidgetsStatsState();
  }

  onDeleteWidget = async ({ id }) => {
    const {
      deleteWidget,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    try {
      await deleteWidget({ id });

      return addNotificationSuccess("widget.remove.success");
    } catch (error) {
      addNotificationError("notification.error");

      throw new Error(`onDeleteWidget() error: ${error}`);
    }
  };

  loadData = widget => {
    const {
      getData,
      getTrend,
      widgetsData: { [widget.id]: dataLoaded } = {}
    } = this.props;
    if (dataLoaded) {
      return false;
    }

    return getDataWithTrend({
      config: widget,
      getData,
      getTrend,
      enableTrend: false
    });
  };

  render() {
    const { widgets, user, color, loading, userType, t } = this.props;

    const addWidget = t("widget.add.action");

    // WidgetsGrid

    return (
      <div>
        <div className={styles["title-container"]}>
          <FormTitle color={color} titleKey="widget.dashboard" />
          <div className={styles["container-toolbar"]}>
            <div>
              <Link
                className={classNames("btn", "btn-ingenico", "create-button")}
                to="/main/widgets/add"
              >
                <span className="glyphicon glyphicon-plus" aria-hidden="true" />
                <I18nSpan msgKey="widget.add.action" />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles["widget-container"]}>
          <div
            key={`group-widget`}
            className={classNames(styles.wrapper, "group-widget")}
            ref={container => (this.widgetContainer = container)}
          >
            {widgets.map((config: WidgetConfig) => {
              const { id } = config;

              return (
                <WidgetView
                  className={classNames(styles.widget)}
                  enableTrend={false}
                  draggable={true}
                  color={color}
                  user={user}
                  key={id}
                  config={{ ...config, desktopView: true }}
                  onDelete={this.onDeleteWidget}
                />
              );
            })}
            <WidgetAdd title={addWidget} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      user: currentUser,
      isCustomer,
      isMerchant,
      levelSettings: _levelSettings
    },
    theme: {
      color: {
        data: { color }
      }
    },
    services: { data: services = [] } = { data: [] },
    widgetData: { widgets: widgetsData }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const canSeeWidget = isCustomer || isMerchant;

  const {
    scope: {
      level: { type: userType, id: levelId }
    }
  } = currentUser;

  const {
    widgets: { data = [] },
    loading,
    error
  } = state;

  const widgets = data.map(widget => {
    const { period } = widget;

    const timePeriod = TimePeriod.fromPeriodSelection(period);

    const min = DateFormatter.getTimezonedDate(
      timePeriod.startTime,
      currentUser
    );
    const max = DateFormatter.getTimezonedDate(timePeriod.endTime, currentUser);

    return {
      ...widget,
      userCurrency,
      timePeriod,
      timestamps: {
        min,
        max
      }
    };
  });

  return {
    services,
    color,
    user: currentUser,
    userType,
    levelId,
    canSeeWidget,
    widgets,
    loading,
    error,
    widgetsData
  };
};

const mapDipatchToProps = dispatch => ({
  getWidgets: () => dispatch(getWidgets()),
  deleteWidget: ({ id }: any) => dispatch(deleteWidget({ id })),
  updateWidget: ({ widgets }: any) => dispatch(updateWidget({ widgets })),
  resetWidgetsState: () => dispatch(resetWidgetsState()),
  resetWidgetsStatsState: () => dispatch(resetWidgetsStatsState()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getData: ({ config }) => dispatch(getData({ config })),
  getTrend: ({ config }) => dispatch(getTrend({ config }))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDipatchToProps)
)(HomeDesktopView);
