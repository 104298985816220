import React from "react";
import classNames from "classnames";

import Loader from "../Loader/Loader";

import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "./Indicator.css";

export default ({ children, loading, color }: any) => {
  return (
    <div className={classNames(styles.indicator)}>
      <Loader loading={loading} color={color}>
        {children}
      </Loader>
    </div>
  );
};

export const TrendIndicator = ({ renderTrend }) => {
  if (renderTrend) {
    return <div className={styles["trend-indicator"]}>{renderTrend()}</div>;
  }
  return null;
};

export const IndicatorTitle = ({ titleKey, args }) => (
  <div className={classNames(styles["indicator-title"])}>
    <I18nSpan msgKey={titleKey} args={args} />
  </div>
);

export const IndicatorValue = ({ children }) => (
  <div className={classNames(styles["indicator-value"])}>{children}</div>
);
