const getCurrencyWithoutDecimal = (value, currencyDecimal) => {
  // Remove decimal
  if (value && value >= 100000 && currencyDecimal !== 0) {
    const valueFormatted: any =
      Math.round(value * Math.pow(10, currencyDecimal)) /
      Math.pow(10, currencyDecimal);

    const valueString = valueFormatted.toFixed(0);

    const amount = valueString.substring(
      0,
      valueString.length - currencyDecimal
    );
    return { value: amount, currencyDecimal: 0 };
  }
  return { value, currencyDecimal };
};

export const formatCurrency = ({ userCurrency, value }: any) => {
  const { value: newValue, currencyDecimal } = getCurrencyWithoutDecimal(
    value,
    userCurrency.currencyDecimal
  );

  return {
    ...userCurrency,
    currencyDecimal,
    value: newValue
  };
};
