import React, { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import * as openpgp from "openpgp/dist/compat/openpgp"; // https://github.com/openpgpjs/openpgpjs/issues/408#issuecomment-200559285
import OpenPGPWorker from "worker-loader!openpgp/dist/compat/openpgp.worker";
import I18nSpan from "../i18n/components/I18nSpan";
import { connect } from "react-redux";

interface ISecureVariableProps {
  title: string;
  type: string;
  id: string;
  onClick: (generated: string) => {};
  onClose: () => {};
}

interface ISecureVariableState {
  text: string;
  mshPublicKeyGpg: string;
}

class SecureVariable extends Component<
  ISecureVariableProps,
  ISecureVariableState
> {
  state = {
    text: ""
  };

  _handleClick = async () => {
    const { id, type, onClick, mshPublicKeyGpg: pubkey = "" } = this.props;
    const { text } = this.state;

    if (pubkey) {
      new OpenPGPWorker(); // set the relative web worker path

      const payload = JSON.stringify({
        id,
        type,
        value: text,
        random: uuidv4()
      });

      const openpgpEncrypt = async (plaintext: string) => {
        const encOpt = async t => ({
          message: openpgp.message.fromText(t),
          publicKeys: (await openpgp.key.readArmored(pubkey)).keys
        });

        const readableStream = new ReadableStream({
          start(controller) {
            controller.enqueue(plaintext);
            controller.close();
          }
        });
        const options = await encOpt(readableStream);
        const { data } = await openpgp.encrypt(options);

        return await openpgp.stream.readToEnd(data);
      };

      const encrypted = await openpgpEncrypt(payload);

      return this.setState({ text: "" }, () => onClick(encrypted));
    } else {
      console.error("No public key set");

      return onClick(null);
    }
  };

  _onChangeHandler = event => {
    this.setState({ text: event.target.value });
  };

  render() {
    const { onClose, title } = this.props;
    const { text } = this.state;

    return (
      <div>
        <div className="popin">
          <div className="popinContent">
            <a onClick={onClose} className="closeButton" />
            <div className="popinTitle">
              <I18nSpan msgKey={title} />
            </div>
            <div>
              <textarea
                value={text}
                onChange={this._onChangeHandler}
                name="variable"
              />
            </div>
            <button
              className="btn btn-default"
              type="button"
              onClick={this._handleClick}
            >
              <I18nSpan msgKey="button.label.generate" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    config: {
      data: { mshPublicKeyGpg }
    }
  } = state;

  return { mshPublicKeyGpg };
};

export default connect(mapStateToProps, null)(SecureVariable);
