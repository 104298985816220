import {
  PENDING_GET_SIM_ALERTS_ACTION,
  SUCCESS_GET_SIM_ALERTS_ACTION,
  FAILURE_GET_SIM_ALERTS_ACTION,
  SUCCESS_UPDATE_STATUS_SIM_ALERT_ACTION
} from "../actionsTypes";
import {
  initSelection,
  onToggleSelectAll,
  onToggleUnSelectAll
} from "./selection";
import get from "get-value";

const initialState = {
  data: [],
  selection: {},
  loading: false,
  error: false,
  errors: {}
};

const _onStatusUpdated = (id, newStatus, simAlertsList) => {
  return simAlertsList.map(currAlert => {
    if (currAlert.id === id) {
      return {
        ...currAlert,
        active: newStatus
      };
    }

    return currAlert;
  });
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_SIM_ALERTS_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_SIM_ALERTS_ACTION:
      const { alerts: simAlerts } = action.payload;
      const selection = initSelection({ data: simAlerts, keyId: "id" });

      return {
        ...state,
        selection,
        data: simAlerts
      };
    case FAILURE_GET_SIM_ALERTS_ACTION:
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    case SUCCESS_UPDATE_STATUS_SIM_ALERT_ACTION: {
      const {
        payload: { status, id }
      } = action;
      const { data } = state;
      const nextData = _onStatusUpdated(id, status, data);

      return {
        ...state,
        data: nextData
      };
    }
    case "TOGGLE_SELECT_SIM_ALERTS_ACTION":
    case "TOGGLE_UNSELECT_SIM_ALERTS_ACTION": {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value
            ? data.find(terminalAlert => get(terminalAlert, "id") === id)
            : false
        }
      };
    }
    case "TOGGLE_SELECT_ALL_SIM_ALERTS_ACTION": {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: "id"
      });

      return {
        ...state,
        selection
      };
    }
    case "TOGGLE_UNSELECT_ALL_SIM_ALERTS_ACTION": {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    default:
      return state;
  }
};
