import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import { requiredMinLength } from "../validations/MerchantValidations";
import I18nSpan from "../../i18n/components/I18nSpan";

import TagUtil from "../../tag/model/TagUtil";
import {
  updateMerchantSettings,
  getMerchant,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  getCountries: Function;
  getMerchant: Function;
  updateMerchantSettings: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  form: any;
  color: any;
  options: any;
  merchantId: string;
}

interface State {
  form: any;
  errors: any;
}

class SettingsMerchantView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentDidMount() {
    const { getMerchant, merchantId: currentMerchantId } = this.props;

    getMerchant(currentMerchantId);
  }

  componentWillReceiveProps(nextProps) {
    const { form, errors } = nextProps;

    return this.setState({
      form,
      errors
    });
  }

  _updateMerchant = e => {
    e.preventDefault();

    const {
      updateMerchantSettings,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const { form } = this.state;

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        try {
          await updateMerchantSettings({ merchant: form });

          addNotificationSuccess("merchant.settings.success");
          return this._goToHome();
        } catch (error) {
          const { key: errorKey } = await error;
          const notificationErrorKey = `notification.merchant.error.${errorKey}`;

          addNotificationError(notificationErrorKey);
        }
      }
    });
  };

  _goToHome = () => {
    const { history } = this.props;
    return history.push("/main");
  };

  _onChangeHandler = (name: string, value: string) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this._checkError(name));
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [_.noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _checkError(name: string) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  }

  _goToHome = () => {
    const { history } = this.props;
    history.push("/main");
  };

  render() {
    const { color, options, t } = this.props;
    const { form, errors } = this.state;

    return (
      <div>
        <FormTitle
          color={color}
          titleKey="merchant.settings.title"
          actionKey="merchant.settings.action"
        />
        <form className="ingenico-form form-horizontal edit-merchant-form">
          <BootstrapInput
            validation={requiredMinLength(t(`merchant.form.name.label`))}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            errors={errors.name}
            required={true}
            descriptor={{
              type: "text",
              label: "merchant.form.name.label",
              placeholder: "merchant.form.name.placeholder",
              readOnly: true
            }}
            formValue={form.name}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("address")}
            name="address"
            errors={errors.address}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.address.label"
            }}
            formValue={form.address}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("city")}
            name="city"
            errors={errors.city}
            required={false}
            descriptor={{ type: "text", label: "merchant.form.city.label" }}
            formValue={form.city}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("commercialContact")}
            name="commercialContact"
            errors={errors.commercialContact}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.commercialContact.label"
            }}
            formValue={form.commercialContact}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("supportContact")}
            name="supportContact"
            errors={errors.supportContact}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.supportContact.label"
            }}
            formValue={form.supportContact}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("zipCode")}
            name="zipCode"
            errors={errors.zipCode}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.zipCode.label"
            }}
            formValue={form.zipCode}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("state")}
            name="state"
            errors={errors.state}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.state.label"
            }}
            formValue={form.state}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("country")}
            name="country"
            errors={errors.country}
            required={false}
            descriptor={{
              type: "singleautocomplete",
              label: "merchant.form.country.label",
              options: options.countries
            }}
            formValue={form.country}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("brandName")}
            name="brandName"
            errors={errors.state}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.brandName.label"
            }}
            formValue={form.brandName}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("website")}
            name="website"
            errors={errors.website}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.website.label"
            }}
            formValue={form.website}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("vatNumber")}
            name="vatNumber"
            errors={errors.vatNumber}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.vatNumber.label"
            }}
            formValue={form.vatNumber}
          />
          <div className="pull-right">
            <button
              onClick={this._updateMerchant}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToHome}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      user: {
        scope: {
          level: { id: merchantId }
        }
      }
    },
    merchant: { data: merchant = [] },
    countries: { data: prevCountries },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  if (merchant) {
    merchant.selectedTags = TagUtil.objectToSelectedTags(merchant.selectedTags);
  }

  const countryOptions = prevCountries.map((country: any) => ({
    label: country.display,
    value: country.code
  }));

  const options = {
    countries: countryOptions
  };

  return {
    color,
    merchantId,
    options,
    form: merchant,
    errors: {}
  };
};

const mapDispatchToProps = dispatch => ({
  updateMerchantSettings: ({ merchant }) =>
    dispatch(updateMerchantSettings({ merchant })),
  getMerchant: id => dispatch(getMerchant(id)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsMerchantView);
