import React, { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import TransactionTimeSelector from "./../reporting/components/TransactionTimeSelector";
import FormTitle from "../ingenicoForm/components/FormTitle";
import TransactionAdvancedSearch from "../reporting/transactions/components/TransactionAdvancedSearch";
import AdvancedSearchConstants from "../advancedSearch/constants/AdvancedSearchConstants";
import Counter from "../datatable/components/Counter";

import { formatCurrency } from "../home/utils/currencyFormatter";

import DateFormatter from "../formatters/DateFormatter";

import Thumbnail from "./Thumbnail/Thumbnail";
import TransactionNumber from "./TransactionNumber/TransactionNumber";
import Revenue from "./Revenue/Revenue";

import { WidgetType } from "../home/models/WidgetType";
import { Refresh } from "../datatable/components/Refresh";
import { setUrlWithParams } from "../searchContext/URLizer";

import { getFields, setSearchContext } from "../redux/actions";

import { loadTrendsTransactionCount } from "./TransactionNumber/DataProvider";
import { getTransactionRevenues } from "./Revenue/DataProvider";
import { loadTrendsCashback } from "./Cashback/DataProvider";
import { loadTrendsAverageBasket } from "./AverageBasket/DataProvider";

import styles from "./styles/styles.css";
import Trend from "./Trend/Trend";
import Cashback from "./Cashback/Cashback";
import AverageBasket from "./AverageBasket/AverageBasket";
import TransactionRevenueChart from "./TransactionRevenue/TransactionRevenue";

interface Props {
  getSnapshotWidgets: Function;
  getFields: Function;
  filters: any;
}

interface State {
  transactionNumber: any;
  revenue: any;
  cashback: any;
  timestamps: any;
  averageBasket: any;
  transactionRevenue: any;
}

const advancedSearchKey = AdvancedSearchConstants.TRANSACTION_KEY;

const DefaultTransactionNumber = {
  count: 0,
  loading: true,
  trend: {},
  error: false
};

const DefaultRevenue = {
  value: 0,
  loading: true,
  trend: {},
  error: false
};

const DefaultCashback = {
  value: 0,
  loading: true,
  trend: {},
  error: false
};

const DefaultAverageBasket = {
  value: 0,
  loading: true,
  trend: {},
  error: false
};

const DefaultTransactionRevenue = {
  values: [],
  loading: true,
  error: false
};

const DefaultTimestamp = {
  min: 0,
  max: 0
};

const DefaultState = {
  transactionNumber: DefaultTransactionNumber,
  revenue: DefaultRevenue,
  cashback: DefaultCashback,
  timestamps: DefaultTimestamp,
  averageBasket: DefaultAverageBasket,
  transactionRevenue: DefaultTransactionRevenue
};

class SnapshotView extends Component<Props, State> {
  state = {
    ...DefaultState
  };

  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.setUrlParams({ searchContext });
  }

  timestampGenerator(timePeriod, callback) {
    const { user } = this.props;
    const min = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const max = DateFormatter.getTimezonedDate(timePeriod.endTime, user);
    return this.setState(
      state => {
        return {
          ...state,
          timestamps: {
            min,
            max
          }
        };
      },
      () => callback()
    );
  }

  loadData({ filters, periodSelection }) {
    const { user } = this.props;
    const { timestamps } = this.state;
    loadTrendsTransactionCount({
      filters,
      timePeriod: periodSelection,
      user,
      timestamps
    }).then(
      transaction => {
        this.setState(state => ({
          ...state,
          transactionNumber: {
            ...state.transactionNumber,
            count: transaction.count,
            trend: { sales: transaction.sales },
            loading: false
          }
        }));
      },
      () => {
        this.setState(state => {
          return {
            ...state,
            transactionNumber: {
              ...state.transactionNumber,
              error: true,
              loading: false
            }
          };
        });
      }
    );
    getTransactionRevenues({
      filters,
      timePeriod: periodSelection,
      timestamps
    }).then(
      revenue => {
        this.setState(state => ({
          ...state,
          revenue: {
            ...state.revenue,
            value: revenue.value,
            trend: { netRevenues: revenue.netRevenues },
            loading: false
          },
          transactionRevenue: {
            ...state.transactionRevenue,
            values: revenue.values,
            loading: false
          }
        }));
      },
      () => {
        this.setState(state => {
          return {
            ...state,
            transactionNumber: {
              ...state.transactionNumber,
              error: true,
              loading: false
            },
            transactionRevenue: {
              ...state.transactionRevenue,
              error: true,
              loading: false
            }
          };
        });
      }
    );
    loadTrendsCashback({
      filters,
      timePeriod: periodSelection,
      user,
      timestamps
    }).then(
      cashback => {
        this.setState(state => ({
          ...state,
          cashback: {
            value: cashback.value,
            trend: { cashback: cashback.cashback },
            loading: false
          }
        }));
      },
      () => {
        this.setState(state => {
          return {
            ...state,
            cashback: {
              ...state.cashback,
              error: true,
              loading: false
            }
          };
        });
      }
    );
    loadTrendsAverageBasket({
      filters,
      timePeriod: periodSelection,
      user,
      timestamps
    }).then(
      averageBasket => {
        this.setState(state => ({
          ...state,
          averageBasket: {
            value: averageBasket.value,
            trend: { avg: averageBasket.avg },
            loading: false
          }
        }));
      },
      () => {
        this.setState(state => {
          return {
            ...state,
            averageBasket: {
              ...state.averageBasket,
              error: true,
              loading: false
            }
          };
        });
      }
    );
  }

  fetchData({ filters, periodSelection }) {
    this.timestampGenerator(periodSelection, () =>
      this.loadData({ filters, periodSelection })
    );
  }

  componentDidMount() {
    const { getFields, searchContext } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
      timePeriod: periodSelection = {}
    } = searchContext;

    getFields({ name: "transaction", version: "v2" });

    this.fetchData({ filters, periodSelection });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _onRefresh = async ({ searchContext }) => {
    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      timePeriod: periodSelection
    } = searchContext;

    this.setState(
      state => ({
        ...state,
        ...DefaultState
      }),
      () => this.fetchData({ filters, periodSelection })
    );
  };

  _search = ({ searchContext }) => {
    const { setSearchContext } = this.props;

    const { filters, timePeriod: periodSelection } = searchContext;

    setSearchContext({ context: searchContext, pathname: "snapshot" });

    this.setState(
      state => {
        return {
          ...state,
          ...DefaultState
        };
      },
      () => {
        this.fetchData({ filters, periodSelection });
      }
    );
  };

  render() {
    const {
      color,
      userCurrency = {},
      searchContext,
      palettes,
      user
    } = this.props;

    const { defaultPaletteId, timezone } = user;

    const { timePeriod } = searchContext;

    return (
      <div className="reporting reporting-activity">
        <div className={styles["title-wrapper"]}>
          <FormTitle
            titleKey="reporting.mainTitle"
            actionKey="reporting.activity.mainTitle"
          />

          <div className={styles["countAndRefresh-container"]}>
            <Counter
              count={
                this.state.transactionNumber.count
                  ? this.state.transactionNumber.count.toString()
                  : this.state.transactionNumber.count
              }
              loading={this.state.transactionNumber.loading}
              loadingKey="reporting.transaction.loading"
              preventDisplayLoading={false}
            />
            <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
          </div>
        </div>

        <div className="flex-box">
          <TransactionTimeSelector
            onChange={({ searchContext: nextSearchContext }) =>
              this._search({ searchContext: nextSearchContext })
            }
            searchContext={searchContext}
          />
        </div>

        <TransactionAdvancedSearch
          onChange={this._search}
          searchContext={searchContext}
        />
        <br />

        <div
          key={`group-widget`}
          className={classNames(styles.wrapper, "group-widget")}
          ref={container => (this.widgetContainer = container)}
        >
          <Thumbnail type={WidgetType.TransactionNumber}>
            <TransactionNumber
              color={color}
              error={this.state.transactionNumber.error}
              loading={this.state.transactionNumber.loading}
              count={this.state.transactionNumber.count}
              title={"widget.type.transactionNumber"}
              renderTrend={() => (
                <Trend
                  enableTrend={true}
                  data={this.state.transactionNumber.trend}
                  type={timePeriod.type}
                  startTime={this.state.timestamps.min}
                  endTime={this.state.timestamps.max}
                  field="sales"
                />
              )}
            />
          </Thumbnail>
          <Thumbnail type={WidgetType.Revenue}>
            <Revenue
              color={color}
              error={this.state.revenue.error}
              loading={this.state.revenue.loading}
              value={this.state.revenue.value}
              formatter={formatCurrency}
              currency={userCurrency}
              title={"widget.type.revenue"}
              renderTrend={() => (
                <Trend
                  enableTrend={true}
                  data={this.state.revenue.trend}
                  type={timePeriod.type}
                  startTime={this.state.timestamps.min}
                  endTime={this.state.timestamps.max}
                  field="netRevenues"
                />
              )}
            />
          </Thumbnail>
          <Thumbnail type={WidgetType.Cashback}>
            <Cashback
              color={color}
              error={this.state.cashback.error}
              loading={this.state.cashback.loading}
              value={this.state.cashback.value}
              formatter={formatCurrency}
              currency={userCurrency}
              title={"widget.type.cashback"}
              renderTrend={() => (
                <Trend
                  enableTrend={true}
                  data={this.state.cashback.trend}
                  type={timePeriod.type}
                  startTime={this.state.timestamps.min}
                  endTime={this.state.timestamps.max}
                  field="cashback"
                />
              )}
            />
          </Thumbnail>
          <Thumbnail type={WidgetType.AverageBasket}>
            <AverageBasket
              color={color}
              error={this.state.averageBasket.error}
              loading={this.state.averageBasket.loading}
              value={this.state.averageBasket.value}
              formatter={formatCurrency}
              currency={userCurrency}
              title={"widget.type.averageBasket"}
              renderTrend={() => (
                <Trend
                  enableTrend={true}
                  data={this.state.averageBasket.trend}
                  type={timePeriod.type}
                  startTime={this.state.timestamps.min}
                  endTime={this.state.timestamps.max}
                  field="avg"
                />
              )}
            />
          </Thumbnail>
          <Thumbnail
            type={WidgetType.TransactionRevenueChart}
            size={styles["col_1-4"]}
          >
            <TransactionRevenueChart
              color={color}
              error={this.state.transactionRevenue.error}
              loading={this.state.transactionRevenue.loading}
              values={this.state.transactionRevenue.values}
              timestamps={this.state.timestamps}
              timePeriod={timePeriod}
              currency={userCurrency}
              palettes={palettes}
              palette={defaultPaletteId}
              title={"reporting.wallet.sales.title"}
              timezone={timezone}
              renderTrend={() => (
                <Trend
                  enableTrend={true}
                  data={this.state.transactionRevenue.trend}
                  type={timePeriod.type}
                  startTime={this.state.timestamps.min}
                  endTime={this.state.timestamps.max}
                  field="avg"
                />
              )}
            />
          </Thumbnail>
        </div>

        <style>
          {`
                        .btn-ingenico,.btn-ingenico:focus{
                            color: ${color};
                            border-color: ${color};
                        }
                        .btn-ingenico:hover {
                            color: #FFF;
                            background-color: ${color};
                        }
                    `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      user: currentUser,
      isCustomer,
      isMerchant,
      levelSettings: _levelSettings
    },
    widgets: { data = [], loading, error },
    theme: {
      color: {
        data: { color }
      }
    },
    palettes: { data: palettes = [] },
    services: { data: services = [] }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const {
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        timePeriod = {}
      }
    }
  } = state;
  const {
    searchContext: { data: searchContext }
  } = state;

  const canSeeWidget = isCustomer || isMerchant;

  const {
    scope: {
      level: { type: userType, id: levelId }
    }
  } = currentUser;

  const widgets = data.map(widget => {
    const { name, id, type } = widget;

    const min = DateFormatter.getTimezonedDate(
      timePeriod.startTime,
      currentUser
    );
    const max = DateFormatter.getTimezonedDate(timePeriod.endTime, currentUser);

    return {
      type,
      filters,
      name,
      id,
      period: timePeriod.type,
      userCurrency,
      timePeriod,
      timestamps: {
        min,
        max
      }
    };
  });

  return {
    userCurrency,
    services,
    color,
    user: currentUser,
    userType,
    levelId,
    canSeeWidget,
    widgets,
    loading,
    error,
    searchContext,
    palettes
  };
};

const mapDipatchToProps = dispatch => ({
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl: true
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDipatchToProps)
)(SnapshotView);
