import _ from "lodash";
import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

export const getReturningCustomersAgg = ({
  restriction,
  filters,
  aggregation
}) => {
  return F.post("/returning-customer/aggregate", {
    body: {
      restriction,
      filters,
      aggregation
    },
    contentType: ContentTypes.JSON
  });
};

export const getReturningCustomersSearch = ({ filters, sort, max }) => {
  const url = new URL("/returning-customer/search", PORTAL_URL);

  Object.entries({ max }).forEach(param => {
    const [paramKey, paramValue] = param;

    if (paramValue) {
      return url.searchParams.append(paramKey, paramValue);
    }
  });

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      sort
    },
    contentType: ContentTypes.JSON
  });
};
