import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import get from "get-value";
import { compose } from "redux";

import { eventSourceListener } from "../../commons/EventSourceListener";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import I18nSpan from "../../i18n/components/I18nSpan";
import DataTable from "../../datatable/components/DataTable";
import MerchantAdvancedSearch from "./MerchantAdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import Permissions from "../../user/constants/Permissions";
import Counter from "../../datatable/components/Counter";
import { Refresh } from "../../datatable/components/Refresh";

import { setUrlWithParams } from "../../searchContext/URLizer";
import UploadFile from "../../components/upload/UploadFile";

import {
  ToggleAction,
  DeleteAction,
  EditAction,
  CreateStoreAction,
  CreateUserAction
} from "../../datatable/components/multipleActions";

import {
  getMerchantsList,
  getScroll,
  updateStatus,
  deleteMerchants,
  getFields,
  getCountries,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetMerchants,
  addNotificationError,
  deleteEvent,
  updateStatusEvent,
  setSearchContext
} from "../../redux/actions";

import { getNamesFromSelection } from "../../redux/reducers/selection";
import { generateUrl } from "../../commons/utils/url";

import styles from "../styles/ListMerchantView.css";

interface Props {
  history: any;
  loading: boolean;
  getMerchantsList: Function;
  getMerchantsScroll: Function;
  updateStatus: Function;
  deleteMerchants: Function;
  resetMerchants: Function;
  attributes: any;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  activationButtonStatus: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  getCountries: Function;
  getFields: Function;
  merchantsList: any;
  merchantCount: any;
  merchantScrollId: string;
  merchantFields: Array<any>;
  visibleAttributes: any;
  selectedMerchantIds: any;
  selection: any;
  isIngenico: any;
  color: any;
  isCustomer: any;
  hasRestrictionByMerchant: boolean;
  hasMerchantUserPermission: boolean;
  hasStoreManagementPermission: boolean;
  updateStatusEvent: Function;
  deleteEvent: Function;
}

interface State {
  merchantNames: Array<string>;
  showDeleteConfirmation: boolean;
}

const advancedSearchKey = AdvancedSearchConstants.MERCHANT_KEY;

class ListMerchantView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      showDeleteConfirmation: false
    };
    this.setUrlParams({ searchContext });
  }

  formatter = {
    "merchant.selectedTags": (selectedTags: any) =>
      _.values(selectedTags).join(", ")
  };

  evtSource = { close: () => undefined } as EventSource;

  async componentDidMount() {
    const {
      getFields,
      getCountries,
      eventGatewayURL,
      searchContext
    } = this.props;
    const name = "merchants";

    await getFields({ name });
    await getCountries();

    await this._onRefresh({ searchContext });

    eventSourceListener({
      type: advancedSearchKey,
      action: this._handleUpdateStatus,
      evtSourceContainer: this,
      callbackWithParams: true,
      eventGatewayURL
    });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _changeStatus({ data, id }) {
    const { status } = data;
    const { updateMerchantsStatusView } = this.props;
    return updateMerchantsStatusView({
      ids: [id],
      status,
      sectionType: "merchants"
    });
  }

  _handleDelete({ id }) {
    const { deleteMerchant } = this.props;
    return deleteMerchant({ id, sectionType: "merchant" });
  }

  _handleUpdateStatus = event => {
    const { id, eventType, data } = event;

    switch (eventType) {
      case "statusUpdated": {
        return this._changeStatus({ data, id });
      }
      case "deleted": {
        return this._handleDelete({ id });
      }
    }
  };

  componentWillUnmount() {
    const { resetMerchants } = this.props;

    resetMerchants();
    this.evtSource.close();
  }

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getMerchantsList } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getMerchantsList({ filters, fields: [], sort, tableCount });
  };

  _search = ({ searchContext }) => {
    const {
      getMerchantsList,
      tableCount,
      setSearchContext,
      location: { pathname }
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });
    return getMerchantsList({ filters, fields: [], sort, tableCount });
  };

  _updateStatus = async ({ activationButtonStatus }) => {
    const {
      selectedMerchantIds: merchantIds,
      updateStatus,
      addNotificationError
    } = this.props;
    const newStatus =
      activationButtonStatus === "Suspended" ? "Activated" : "Suspended";

    try {
      await updateStatus({ merchantIds, newStatus });
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      return addNotificationError(errorKey);
    }
  };

  _showDeletePopin = () => {
    const { selection } = this.props;
    const merchantNames = getNamesFromSelection({
      selection,
      keyName: "merchant.name"
    });

    return this.setState({
      showDeleteConfirmation: true,
      merchantNames
    });
  };

  _closeDeletePopin = () => {
    return this.setState({
      showDeleteConfirmation: false,
      deleteTarget: undefined
    });
  };

  _deleteMerchant = async () => {
    const {
      selectedMerchantIds: merchantIds,
      addNotificationError
    } = this.props;

    const { deleteMerchants } = this.props;

    try {
      await deleteMerchants({ merchantIds });

      return this._closeDeletePopin();
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      addNotificationError(errorKey);

      return this._closeDeletePopin();
    }
  };

  _updateColumns = (columns: any) => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }

    return updateUserColumnsSettings({ columnSetting, category });
  };

  onSelectRow = (rowId, value) => {
    const { selectedMerchantIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedMerchantIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  _loadMoreRows = () => {
    const { getMerchantsScroll, merchantScrollId } = this.props;

    return getMerchantsScroll({ scrollId: merchantScrollId });
  };

  render() {
    const { showDeleteConfirmation, merchantNames } = this.state;
    const {
      hasRestrictionByMerchant,
      hasMerchantUserPermission,
      hasStoreManagementPermission,
      color,
      visibleAttributes,
      merchantsList,
      merchantCount,
      selectedMerchantIds,
      attributes,
      loading,
      sortDescription,
      searchContext
    } = this.props;

    const activationButtonStatus = handleToggleActivationButtonStatus({
      selectionIds: selectedMerchantIds,
      merchants: merchantsList
    });

    const ToggleActivationButton = () => (
      <ToggleAction
        onToggle={() => this._updateStatus({ activationButtonStatus })}
        msgKey={`merchant.toggle.${activationButtonStatus}`}
      />
    );

    const DeleteMerchant = () => (
      <DeleteAction onDelete={this._showDeletePopin} />
    );

    const EditMerchant = () => {
      if (selectedMerchantIds.length === 1) {
        const [merchantId] = selectedMerchantIds;
        const route = `/main/settings/merchant/edit-merchant/${merchantId}`;

        return <EditAction route={route} msgKey={"merchant.edit.action"} />;
      }

      return null;
    };

    const CreateUser = () => {
      if (selectedMerchantIds.length === 1 && hasMerchantUserPermission) {
        const [merchantId] = selectedMerchantIds;
        const route = `/main/settings/user/new-user/merchant/${merchantId}`;

        return (
          <CreateUserAction
            route={route}
            msgKey={"dataTable.createAdminUser"}
          />
        );
      }

      return null;
    };

    const CreateStore = () => {
      if (selectedMerchantIds.length === 1 && hasStoreManagementPermission) {
        const [merchantId] = selectedMerchantIds;
        const route = `/main/settings/store/new-store/merchant/${merchantId}`;

        return (
          <CreateStoreAction route={route} msgKey={"store.create.action"} />
        );
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle
              titleKey="merchant.title"
              actionKey="merchant.list.action"
            />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={merchantCount}
                loadingKey="merchant.list.loading"
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>

          {!hasRestrictionByMerchant && (
            <div className={styles.edition}>
              <div>
                <Link
                  className={classNames(
                    "btn",
                    "btn-ingenico",
                    "create-button",
                    styles["merchant-action"]
                  )}
                  to="/main/settings/merchant/new-merchant"
                >
                  <span
                    className="glyphicon glyphicon-plus"
                    aria-hidden="true"
                  />
                  <I18nSpan msgKey="merchant.list.button.create" />
                </Link>
              </div>

              <UploadFile
                uploadUrl={"/merchants/import"}
                templateActions={[
                  generateUrl({
                    url: "/merchants/import/template"
                  })
                ]}
                fileKey="merchants"
                tooltip="merchant.import.template"
                buttonsTitles={{
                  upload: "merchant.import.upload",
                  templates: "merchant.import.template"
                }}
                notifications={{
                  success: "merchant.import.uploadFile.success",
                  error: "merchant.import.uploadFile.error"
                }}
              />
            </div>
          )}
        </div>

        <MerchantAdvancedSearch
          searchContext={searchContext}
          onChange={this._search}
        />

        {showDeleteConfirmation && (
          <ConfirmationPopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={this._deleteMerchant}
            objectName={merchantNames}
          />
        )}
        <DataTable
          formattersCellValue={["merchant.status"]}
          data={merchantsList}
          notSortableFields={["merchant.selectedTags"]}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          formatter={this.formatter}
          i18nKey="merchant.list"
          idAttribute="merchant.id"
          hideSearchBox={true}
          toggleable={true}
          color={color}
          hasActions={false}
          onRefresh={this._search}
          disableStatusValues={["Suspended"]}
          onUpdateColumns={this._updateColumns}
          version="new"
          selectableNew={true}
          selectionButtonKey={[
            ToggleActivationButton,
            DeleteMerchant,
            EditMerchant,
            CreateUser,
            CreateStore
          ]}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedMerchantIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={merchantCount}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const _getColumns = ({ type, fields }) => {
  const merchantFields = fields.reduce(
    (prevField: Array<string>, field: any) => {
      const { key, required, visible } = field;

      switch (type) {
        case "required": {
          if (visible || required) prevField.push(key);

          break;
        }
        default: {
          if (visible) prevField.push(key);

          break;
        }
      }

      return prevField;
    },
    []
  );

  return merchantFields;
};

const merchantsWithStatus = ({ state }) => {
  const {
    countries: { data: countries },
    merchants: { dataList, count, scrollId },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const merchantsList = dataList.map((merchant: any) => {
    const normalizedMerchant = fields.reduce(
      (prevField: Array<string>, field: any) => {
        if (field === "merchant.country") {
          const merchantCountry = countries.find(
            country => country.code === get(merchant, field)
          );

          return {
            ...prevField,
            [field]: merchantCountry ? merchantCountry.display : ""
          };
        }

        return {
          ...prevField,
          [field]: get(merchant, field)
        };
      },
      {}
    );

    return {
      ...normalizedMerchant
    };
  });

  return {
    merchantCount: count,
    scrollId,
    merchants: merchantsList
  };
};

const handleToggleActivationButtonStatus = ({ selectionIds, merchants }) => {
  const selection = merchants.reduce(
    (prevSelectedMerchant, selectedMerchant) => {
      const selectedMerchantId = selectedMerchant["merchant.id"];

      if (selectionIds.includes(selectedMerchantId)) {
        return {
          ...prevSelectedMerchant,
          [selectedMerchantId]: selectedMerchant
        };
      }

      return prevSelectedMerchant;
    },
    {}
  );

  const isAllActivated = selectionIds.every(
    merchantId =>
      Object.keys(selection).length !== 0 &&
      selection[merchantId]["merchant.status"] === "Activated"
  );

  return isAllActivated ? "Activated" : "Suspended";
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isCustomer, isIngenico, isRestrictedByIds },
    genericFields: { data: fields },
    merchants: { selection, loading },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount, eventGatewayURL }
    },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const {
    merchants: merchantsList,
    merchantCount,
    scrollId: merchantScrollId
  } = merchantsWithStatus({
    state
  });

  const { permissions } = user;

  const hasRestrictionByMerchant = (isCustomer && isRestrictedByIds) || false;
  const hasMerchantUserPermission =
    !isIngenico && permissions.includes(Permissions.MERCHANT_USER_MANAGEMENT);
  const hasStoreManagementPermission = permissions.includes(
    Permissions.STORE_MANAGEMENT
  );

  const selectedMerchantIds = _.keys(_.pick(selection, _.identity));
  const columnSetting = user ? user.columnSetting : false;

  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const attributes = _getColumns({ fields });

  return {
    loading,
    attributes,
    hasRestrictionByMerchant,
    color,
    merchantCount,
    merchantScrollId,
    merchantsList,
    hasMerchantUserPermission,
    hasStoreManagementPermission,
    selectedMerchantIds,
    selection,
    tableCount,
    isCustomer,
    visibleAttributes,
    sortDescription,
    eventGatewayURL,
    searchContext
  };
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting &&
    columnSetting.merchant &&
    columnSetting.merchant.length > 0
    ? columnSetting.merchant
    : _getColumns({ fields });
};

const mapDispatchToProps = dispatch => ({
  getMerchantsList: ({ filters, fields, sort, tableCount }) =>
    dispatch(getMerchantsList({ filters, fields, sort, tableCount })),
  getMerchantsScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "merchants", scrollId })),
  updateStatus: ({ merchantIds, newStatus }) =>
    dispatch(updateStatus({ merchantIds, newStatus })),
  deleteMerchants: ({ merchantIds }) =>
    dispatch(deleteMerchants({ merchantIds })),
  getFields: ({ name }) => dispatch(getFields({ name })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "MERCHANTS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "MERCHANTS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "MERCHANTS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "MERCHANTS" })),
  getCountries: () => dispatch(getCountries()),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  resetMerchants: () => dispatch(resetMerchants()),
  updateMerchantsStatusView: ({ ids, status, sectionType }) =>
    dispatch(updateStatusEvent({ ids, status, sectionType })),
  deleteMerchant: ({ id, sectionType }) =>
    dispatch(deleteEvent({ id, sectionType })),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ListMerchantView);
