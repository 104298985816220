import posClient from "../clients/PosClient";
import Validators from "../../ingenicoForm/validation/Validators";

export default {
  async autoInitIdUnicity(value = "") {
    const res = await posClient.isAutoInitIdExisting(value);

    if (res) {
      return { code: "form.error.autoInit", args: [value] };
    }

    return null;
  },

  autoInitIdUnicityForEdit(value, autoInit) {
    return value === autoInit
      ? Promise.resolve(null)
      : this.autoInitIdUnicity(value);
  }
};

export function requiredMinLength(minLengthTranslation) {
  return value => {
    const requiredPromise = Promise.resolve(
      Validators.requiredValidator(value)
    );

    const minNameSize = 2;
    const sizePromise = Promise.resolve(
      Validators.sizeOfStringValidator(minNameSize, minLengthTranslation)(value)
    );

    const maxNameSize = 40;
    const maxSizePromise = Promise.resolve(
      Validators.maxStringValidator(maxNameSize, minLengthTranslation)(value)
    );

    return Promise.all([requiredPromise, sizePromise, maxSizePromise]);
  };
}

export const posErrorNotification = async ({
  error,
  addNotificationError,
  form
}: any) => {
  const { errorKey } = await error;

  switch (errorKey) {
    case "access.denied":
      return addNotificationError("pos.error.restriction");
    case "restriction.error":
      return addNotificationError("pos.error.restriction");
    case "server.error":
      return addNotificationError("notification.error");
    case "user.notFound":
      return addNotificationError("user.error.not-found");
    case "pos.notFound":
      return addNotificationError("pos.error.not-found");
    case "store.notFound":
      return addNotificationError("store.error.not-found");
    case "merchant.notFound":
      return addNotificationError("merchant.error.not-found");
    case "custoemr.notFound":
      return addNotificationError("customer.error.not-found");
    case "pos.error.attached-to-poi":
      return addNotificationError("pos.error.attached-to-poi");
    case "pos.error.attached-to-sim":
      return addNotificationError("pos.error.attached-to-sim");
    case "autoInitId.create.conflict":
      return addNotificationError("form.error.autoInit", [form.autoInit]);
    case "autoInitId.invalid":
      return addNotificationError("pos.error.invalid", [form.autoInit]);
    case "badRequest":
      return addNotificationError("notification.error");
    case "parsing.error":
      return addNotificationError("notification.error");
    case "pos.list.notFound":
      return addNotificationError("pos.error.not-found");
    default:
      addNotificationError(errorKey);
  }
};
