import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "../styles/styles.css";

interface Props {
  addNotificationSuccess: Function;
  addNotificationError: Function;
  resetPassword: Function;
  history: any;
  token: any;
}

class ResetPasswordDesktopView extends Component<Props, {}> {
  render() {
    const { t, showPassword, onInputChange, onShowPassword } = this.props;

    return (
      <div
        className={`col-xs-12 col-md-7 col-sm-7 ${styles["login-form-wrapper"]}`}
      >
        <div className={styles["login-form"]}>
          <div className={styles["form-group"]}>
            <label htmlFor="login" className={styles["control-label"]}>
              {t("resetPassword.form.new")}
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              className="form-control"
              onChange={onInputChange}
              required
            />
            <span
              data-tip
              data-for="password-help"
              className="react-tooltip glyphicon glyphicon-question-sign"
            />
            <ReactTooltip
              id="password-help"
              delayHide={1000}
              place="left"
              type="info"
              effect="solid"
            >
              <I18nSpan msgKey="resetPassword.help.title" />
              <ul style={{ listStyle: "disc", paddingLeft: "25px" }}>
                <li>
                  <I18nSpan msgKey="resetPassword.help.compliance1" />
                </li>
                <li>
                  <I18nSpan msgKey="resetPassword.help.compliance2" />
                </li>
                <ul style={{ listStyle: "circle", paddingLeft: "25px" }}>
                  <li>
                    <I18nSpan msgKey="resetPassword.help.category1" />
                  </li>
                  <li>
                    <I18nSpan msgKey="resetPassword.help.category2" />
                  </li>
                  <li>
                    <I18nSpan msgKey="resetPassword.help.category3" />
                  </li>
                  <li>
                    <I18nSpan msgKey="resetPassword.help.category4" />
                  </li>
                </ul>
              </ul>
            </ReactTooltip>
          </div>
          <div className={styles["form-group"]}>
            <label htmlFor="password" className={styles["control-label"]}>
              {t("resetPassword.form.repeatNew")}
            </label>
            <div className="input-group-field">
              <div
                className={`${styles["input-group-addon"]} icon-visibility-${
                  showPassword ? "on" : "off"
                }`}
                onClick={onShowPassword}
              />
              <input
                type={showPassword ? "text" : "password"}
                id="repeatPassword"
                name="repeatPassword"
                className="form-control"
                onChange={onInputChange}
                required
              />
            </div>
          </div>
          <div className={styles["small-link"]}>
            <Link to="/authenticate" className={styles.link}>
              <I18nSpan msgKey="resetPassword.form.back" />
            </Link>
          </div>
          <div className={styles["submit-container"]}>
            <input
              type="submit"
              className={styles.buttonlink}
              value={t("resetPassword.form.submit")}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ResetPasswordDesktopView);
