import React from "react";
import { withTranslation } from "react-i18next";

interface Props {
  msgKey: string;
  args?: any[];
  class?: string;
  t: Function;
}

const I18nSpan = ({ msgKey, args, class: className, t }: Props) => {
  return <span className={className}>{t(msgKey, args)}</span>;
};

export default withTranslation()(I18nSpan);
