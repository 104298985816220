import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../i18n/components/I18nSpan";
import VerificationCodeInput from "./VerificationCodeDesktopInput";

import { get2FAPair } from "../../redux/actions";

import styles from "../styles/styles.css";

class AuthenticateVerifyDesktopView extends Component<Props, {}> {
  componentDidMount() {
    const {
      get2FAPair,
      match: {
        params: { pair }
      }
    } = this.props;

    if (pair) {
      get2FAPair();
    }
  }

  render() {
    const {
      t,
      otp,
      onInputChange,
      twoFAPair: svgString,
      match: {
        params: { pair }
      }
    } = this.props;

    if (pair) {
      return (
        <div
          className={`col-xs-12 col-md-7 col-sm-7 ${styles["login-form-wrapper"]}`}
        >
          <div className={styles["login-form"]}>
            <div className={styles["qrCode-wrapper"]}>
              <I18nSpan
                msgKey="login.form.2fa.pair"
                class={styles["twofa-pair"]}
              />
              <div className={styles["qrCode-container"]}>
                <img
                  src={`data:image/svg+xml;base64,${btoa(svgString)}`}
                  width="150"
                  height="150"
                />
                <VerificationCodeInput
                  onChange={onInputChange}
                  value={otp}
                  pair={pair}
                />
              </div>
            </div>
            <div className={styles["submit-container"]}>
              <input
                type="submit"
                className={styles.buttonlink}
                value={t("login.form.2fa.validate")}
              />
              <div className={`${styles["small-link"]} text-right `}>
                <Link to="/authenticate" className={styles.link}>
                  {t("createToken.form.back")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`col-xs-12 col-md-7 col-sm-7 ${styles["login-form-wrapper"]}`}
      >
        <div className={styles["login-form"]}>
          <VerificationCodeInput
            label={t("login.form.2fa.verificationCode.label")}
            onChange={onInputChange}
            value={otp}
          />
          <div className={styles["submit-container"]}>
            <input
              type="submit"
              className={styles.buttonlink}
              value={t("login.form.signIn")}
            />
            <div className={`${styles["small-link"]} text-right `}>
              <Link to="/authenticate" className={styles.link}>
                {t("createToken.form.back")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      "2fa": { pair: twoFAPair }
    }
  } = state;

  return {
    twoFAPair
  };
};

const mapDispatchToProps = dispatch => ({
  get2FAPair: () => dispatch(get2FAPair())
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AuthenticateVerifyDesktopView);
