import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { WidgetConfig } from "../Widget";
import { replaceIdByName } from "../../services/AggregationDataHandler";
import TransactionsPie from "../../../chart/components/chart/TransactionsPie";
import { HighchartsData } from "../../../chart/models/HighchartData";
import NumberFormatter from "../../../formatters/NumberFormatter";
import LoadingChart from "./LoadingChart";
import { getData } from "../../utils/DataProvider";

interface Props {
  getData: Function;
  data?: number;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  config: WidgetConfig;
  loadingFields: boolean;
  customerSettings: any;
}

class ComparisonAmountChart extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ config });
    }
  }

  generateSeries(data) {
    const { t, customerSettings } = this.props;

    if (
      data.criteria === "selectedService" ||
      data.criteria === "transactionType" ||
      data.criteria === "nokReason" ||
      data.criteria === "transactionResult" ||
      data.criteria === "technologySelected"
    ) {
      return [
        {
          data: replaceIdByName("sum", data)
            .map(formatAmount(customerSettings))
            .map(c => {
              return {
                id: c.id,
                y: c.y,
                value: c.value,
                name: t(`reporting.comparison.${c.name.toLowerCase()}`)
              };
            })
        }
      ];
    } else {
      return [
        {
          data: replaceIdByName("sum", data).map(formatAmount(customerSettings))
        }
      ];
    }
  }

  render() {
    const {
      data,
      title,
      color,
      loading,
      loadingFields,
      config: { userCurrency = {}, defaultPaletteId },
      t
    } = this.props;

    const titleWidget = t(title);
    const format =
      "<b>{point.name}</b>: {point.percentage:.2f}% ({point.value})";
    const series = this.generateSeries(data);
    const isLoading = loading || loadingFields;

    return (
      <LoadingChart loading={isLoading} color={color}>
        <TransactionsPie
          currency={userCurrency}
          color={color}
          title={titleWidget}
          series={series}
          dataLabelsFormat={format}
          palette={defaultPaletteId}
        />
      </LoadingChart>
    );
  }
}

const formatAmount = customerSettings => (data: HighchartsData) => {
  const isExistingCurrency = customerSettings && customerSettings.currency;
  const currencySymbol = isExistingCurrency
    ? customerSettings.currency.alpha3 + customerSettings.currency.symbol
    : "?";
  const currencyExponent = isExistingCurrency
    ? customerSettings.currency.exponent
    : 0;

  return {
    ...data,
    value: NumberFormatter.formatAmount(
      data.y,
      currencySymbol,
      currencyExponent
    )
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { levelSettings: customerSettings }
  } = state;
  const { id, t } = ownProps;
  const {
    widgets: { [id]: widget },
    transactions: { loading: loadingFields, errorFields }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    const chartTitle = t(ownProps.title);

    return {
      customerSettings,
      loadingFields,
      errorFields,
      chartTitle,
      data: stats,
      loading,
      error
    };
  }

  return {
    customerSettings,
    data: {},
    loadingFields,
    errorFields,
    loading: true
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ComparisonAmountChart);
