import React from "react";

interface IProps {
  elements: any;
  onSelect: () => void;
  selected: boolean;
}

class TransactionPanel extends React.Component<IProps, any> {
  highlightConflict(value, highlightCondition, index: number) {
    if (highlightCondition) {
      return (
        <b style={{ color: "red" }} key={index}>
          {value}
        </b>
      );
    } else {
      return <span key={index}>{value}</span>;
    }
  }

  onClick = () => {
    this.props.onSelect();
  };

  render() {
    return (
      <pre
        onClick={this.onClick}
        className={this.props.selected ? "selected" : ""}
      >
        {this.props.elements.map((element, idx) => {
          return this.highlightConflict(
            element.value,
            element.removed || element.added,
            idx
          );
        })}
      </pre>
    );
  }
}

export default TransactionPanel;
