import React, { Component } from "react";
import I18nSpan from "../../i18n/components/I18nSpan";

class ConfirmationPopIn extends Component<any, any> {
  accept(e: Event) {
    e.preventDefault();
    const { objectName, onAccept, merchantId } = this.props;
    return onAccept(merchantId, objectName);
  }

  cancel(e: Event) {
    e.preventDefault();
    const { objectName, onClose } = this.props;
    return onClose(objectName);
  }

  close(e: Event) {
    e.preventDefault();
    const { objectName, onClose } = this.props;
    return onClose(objectName);
  }

  render() {
    const { objectName } = this.props;
    return (
      <div>
        <div className="popin">
          <div className="popinContent">
            <a onClick={e => this.close(e)} className="closeButton" />
            <div className="popinTitle">
              <I18nSpan msgKey="popin.confirmation" />
            </div>
            <I18nSpan msgKey="popin.message.delete" args={[objectName]} />

            <div className="button-list">
              <li>
                <a onClick={e => this.cancel(e)} className="btn btn-ingenico">
                  <I18nSpan msgKey="popin.button.cancel" />
                </a>
              </li>
              <li>
                <a onClick={e => this.accept(e)} className="btn btn-ingenico">
                  <I18nSpan msgKey="popin.button.delete" />
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationPopIn;
