import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import ConfirmationPopin from "../../../components/ConfirmationPopin";

import { PredefinedAlert } from "../model/PredefinedAlert";

import DataTable from "../../../datatable/components/DataTable";
import {
  getPredefinedAlerts,
  deletePredefinedAlert,
  addNotificationSuccess,
  addNotificationError,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../../redux/actions";
import { CreateAlert } from "../../components/CreateButtons";
import AlertConstants from "../../constants/AlertConstant";
import FormTitle from "../../../ingenicoForm/components/FormTitle";
import { Tabs } from "../../components/TabsContainer";
import constants from "../../../connection/constants/ConnectionConstant";

import { DeleteAction } from "../../../datatable/components/multipleActions";

import styles from "../../styles/ListAlertView.css";

interface Props {
  getPredefinedAlerts: Function;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  alerts: any;
  currentLang: any;
  attributes: any;
}

interface State {
  showPopinSingle: boolean;
  alertId: any;
}

class ListPredefinedAlertView extends Component<State, Props> {
  state = {
    showPopinSingle: false,
    alertId: ""
  };

  componentDidMount() {
    const { getPredefinedAlerts } = this.props;

    getPredefinedAlerts();
  }

  _deleteAlert = () => {
    const {
      deletePredefinedAlert,
      addNotificationSuccess,
      addNotificationError,
      selectedAlertIds
    } = this.props;
    const [alertId] = selectedAlertIds;

    if (alertId == "") return undefined;

    return deletePredefinedAlert({ id: alertId }).then(
      () => {
        this._closePopinSingle();
        addNotificationSuccess("alert.cancel.success");
      },
      () => {
        this._closePopinSingle();
        addNotificationError("alert.cancel.error");
      }
    );
  };

  _openPopinConfirmDelete = () => {
    this.setState({
      showPopinSingle: true
    });
  };

  _closePopinSingle = () => {
    this.setState({
      showPopinSingle: false
    });
  };

  onSelectRow = (rowId, value) => {
    const { selectedAlertIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedAlertIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const {
      currentLang,
      alerts,
      attributes,
      renderTransactionAlert,
      renderFraudAlert,
      t,
      selectedAlertIds
    } = this.props;
    const { showPopinSingle } = this.state;

    const formatter = {
      description: (_, alert: PredefinedAlert) => {
        return t(
          `alert.predefined.${alert.i18nKey()}.display`,
          alert.i18nArgs(currentLang)
        );
      }
    };

    const DeleteAlert = () => {
      if (selectedAlertIds.length === 1) {
        return <DeleteAction onDelete={this._openPopinConfirmDelete} />;
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <FormTitle titleKey="alert.title" actionKey="alert.list.action" />
          <CreateAlert
            alertType={AlertConstants.PREDEFINED_ALERT}
            transactionAlert={renderTransactionAlert}
            fraudAlert={renderFraudAlert}
          />
        </div>
        <Tabs
          target="/main/reporting/alerts/"
          active={AlertConstants.PREDEFINED_ALERT}
          transactionAlert={renderTransactionAlert}
          fraudAlert={renderFraudAlert}
        />
        {showPopinSingle && (
          <ConfirmationPopin
            onClosePopin={this._closePopinSingle}
            onClickButton={this._deleteAlert}
            objectName={""}
          />
        )}
        <DataTable
          data={alerts}
          attributes={attributes}
          formatter={formatter}
          i18nKey="alert.predefined.list"
          idAttribute="id"
          hasActions={false}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedAlertIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[DeleteAlert]}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: {
        scope: {
          level: { type: userType }
        }
      }
    },
    services: { data: services = [] } = { data: [] },
    predefinedAlerts: { data: alerts, selection }
  } = state;

  const { i18n } = ownProps;

  const currentLang = i18n.languages[1]; // https://www.i18next.com/principles/fallback#language-fallback

  const checkPermissions = () => {
    const hasTransactionAlertService = services.includes("reportingAlert");
    const hasTransactionAlertSmsService = services.includes(
      "reportingAlertSms"
    );

    const renderTransactionAlert =
      hasTransactionAlertService || hasTransactionAlertSmsService;

    switch (userType) {
      case constants.CUSTOMER: {
        return {
          renderTransactionAlert,
          renderFraudAlert: false
        };
      }
      case constants.MERCHANT: {
        // hasTransactionAlertService is in fact for E-mail alert reportingAlert service
        const renderFraudAlert = services.includes("fraudAlert");
        return {
          renderTransactionAlert,
          renderFraudAlert
        };
      }
      default:
        break;
    }
    return {
      renderFraudAlert: false,
      renderTransactionAlert: false
    };
  };
  const selectedAlertIds = _.keys(_.pick(selection, _.identity));

  return {
    alerts,
    selectedAlertIds,
    currentLang,
    attributes: ["description"],
    ...checkPermissions()
  };
};

const mapDispatchToProps = dispatch => ({
  getPredefinedAlerts: () => dispatch(getPredefinedAlerts()),
  deletePredefinedAlert: ({ id }) => dispatch(deletePredefinedAlert({ id })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "PREDEFINED_ALERTS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "PREDEFINED_ALERTS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "PREDEFINED_ALERTS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "PREDEFINED_ALERTS" }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ListPredefinedAlertView);
