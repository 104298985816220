import React, { Component } from "react";
import { connect } from "react-redux";

import AdvancedSearch from "../../advancedSearch/components/AdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";

interface Props {
  fields: Array<any>;
  searchFilters: any;
}

interface State {}

class AuditAdvancedSearch extends Component<Props, State> {
  render() {
    const {
      searchFilters,
      auditFields = [],
      searchContext,
      onChange,
      disabled
    } = this.props;

    return (
      <div>
        <AdvancedSearch
          name={AdvancedSearchConstants.AUDIT_KEY}
          disabled={disabled}
          searchFilters={searchFilters}
          onChange={onChange}
          genericFields={auditFields}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  let {
    genericFields: { data: fields = [] }
  } = state;
  const searchFilters = fields.reduce((prevField: any, field: any) => {
    const { key, type, searchable } = field;

    if (searchable) {
      prevField.push({
        i18nKey: `audit.list.header.${key}`,
        name: key,
        type
      });
    }

    return prevField;
  }, []);

  return { searchFilters, auditFields: fields };
};

export default connect(mapStateToProps, null)(AuditAdvancedSearch);
