import { Component } from "react";
import { generateUrl } from "../../commons/utils/url";

export class AuthorizationRedirect extends Component<
  AuthorizationRedirectProps,
  {}
> {
  componentDidMount() {
    const { clientId, locationPathname, locationSearch } = this.props;
    const redirectUri = `${window.location.origin}${locationPathname}`;
    const state = encodeURIComponent(locationSearch);
    const authorizationStateParam = state ? `&state=${state}` : "";

    const url = generateUrl({
      url: `/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${authorizationStateParam}`,
      version: "v2"
    });

    window.location.assign(url);
  }

  render() {
    return null;
  }
}
