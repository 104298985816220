import _ from "lodash";

export default {
  extractFieldKeys({ transactionFieldGroups, type }) {
    return transactionFieldGroups.reduce((prevField, field) => {
      const { key, visible, extras } = field;

      switch (type) {
        case "visibleAndExtras": {
          if (visible || extras) prevField.push(key);

          break;
        }
        case "extras": {
          if (extras) prevField.push(key);

          break;
        }
        case "visible": {
          if (visible) prevField.push(key);

          break;
        }
        default: {
          break;
        }
      }

      return prevField;
    }, []);
  },

  i18nFields(fieldsAndExtras, formatter) {
    return _(fieldsAndExtras)
      .map(field =>
        Object.create({
          key: field,
          value: field,
          label: formatter(field)
        })
      )
      .value();
  },
  getTransactionWithConflictData(transactionInConflict) {
    if (transactionInConflict) {
      const fieldsInConflict = _.cloneDeep(
        transactionInConflict.intersectedData
      );
      const transactionCopy = _.cloneDeep(transactionInConflict);
      const res = _.merge(transactionCopy, fieldsInConflict);
      delete res.intersectedData;

      return res;
    } else {
      return null;
    }
  }
};
