import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  FAILURE_GET_COMMENT_ACTION,
  PENDING_GET_COMMENT_ACTION,
  SUCCESS_GET_COMMENT_ACTION,
  FAILURE_UPDATE_COMMENT_ACTION,
  PENDING_UPDATE_COMMENT_ACTION,
  SUCCESS_UPDATE_COMMENT_ACTION,
  RESET_COMMENT_STATE_ACTION
} from "../actionsTypes";

const getCommentStart = () => ({
  type: PENDING_GET_COMMENT_ACTION
});

const getCommentSucceeded = comment => ({
  type: SUCCESS_GET_COMMENT_ACTION,
  payload: {
    comment
  }
});

const getCommentFailure = errors => ({
  type: FAILURE_GET_COMMENT_ACTION,
  payload: {
    errors
  }
});

export const getComment = ({ commentId }) => dispatch => {
  dispatch(getCommentStart());

  return F.get(`/transaction/comment/${commentId}`, "v2").then(
    comment => dispatch(getCommentSucceeded(comment)),
    errors => {
      dispatch(getCommentFailure(errors));

      throw errors;
    }
  );
};

const editCommentStart = () => ({
  type: PENDING_UPDATE_COMMENT_ACTION
});

const editCommentSucceeded = comment => ({
  type: SUCCESS_UPDATE_COMMENT_ACTION,
  payload: {
    comment
  }
});

const editCommentFailure = errors => ({
  type: FAILURE_UPDATE_COMMENT_ACTION,
  payload: {
    errors
  }
});

export const editComment = ({ commentId, comment }) => dispatch => {
  dispatch(editCommentStart());

  return F.put(
    `/transaction/comment/${commentId}`,
    {
      body: {
        value: comment
      },
      contentType: ContentTypes.JSON
    },
    "v2"
  ).then(
    () => dispatch(editCommentSucceeded(comment)),
    errors => {
      dispatch(editCommentFailure(errors));

      throw errors;
    }
  );
};

export const resetCommentState = () => ({
  type: RESET_COMMENT_STATE_ACTION
});
