const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case `PENDING_GET_${action.name}_TEMPLATE_ACTION`: {
      return {
        ...state,
        data: initialState.data,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case `SUCCESS_GET_${action.name}_TEMPLATE_ACTION`: {
      const { template: data } = action.payload;
      return {
        ...state,
        data,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case `FAILURE_GET_${action.name}_TEMPLATE_ACTION`: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case `PENDING_UPDATE_${action.name}_TEMPLATE_ACTION`: {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case `SUCCESS_UPDATE_${action.name}_TEMPLATE_ACTION`: {
      return state;
    }
    case `FAILURE_UPDATE_${action.name}_TEMPLATE_ACTION`: {
      const errors = action.payload;
      return {
        ...state,
        loading: false,
        error: true,
        ...errors
      };
    }
    case `RESET_${action.name}_TEMPLATE_STATE_ACTION`: {
      return initialState;
    }
    default:
      return state;
  }
};
