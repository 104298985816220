import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_SALES_ACTION,
  SUCCESS_GET_SALES_ACTION,
  FAILURE_GET_SALES_ACTION,
  RESET_SALES_ACTION,
  PENDING_GET_SALE_DETAIL_ACTION,
  SUCCESS_GET_SALE_DETAIL_ACTION,
  FAILURE_GET_SALE_DETAIL_ACTION,
  SUCCESS_SET_SALE_DETAIL_ACTION,
  SHOW_SALE_DETAIL_ACTION,
  HIDE_SALE_DETAIL_ACTION
} from "../actionsTypes";

export const getSales = ({
  filters,
  fields,
  sort = { field: "createSaleTime", order: "DESC" },
  tableCount: max,
  begin,
  end
}) => async dispatch => {
  dispatch(getSalesStart());

  try {
    const url = new URL("/sale/search", PORTAL_URL);

    Object.entries({ max, begin, end }).forEach(param => {
      const [paramKey, paramValue] = param;

      if (paramValue) {
        return url.searchParams.append(paramKey, paramValue);
      }
    });

    const { count, scrollId, searchResults: data } = await F.post(
      `${url.pathname}${url.search}`,
      {
        body: {
          filters,
          fields,
          sort
        },
        contentType: ContentTypes.JSON
      }
    );

    return dispatch(getSalesSucceeded({ count, scrollId, data }));
  } catch (errors) {
    dispatch(getSalesFailure({ errors }));

    throw errors;
  }
};

const getSalesStart = () => ({
  type: PENDING_GET_SALES_ACTION
});

const getSalesSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_SALES_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getSalesFailure = ({ errors }) => ({
  type: FAILURE_GET_SALES_ACTION,
  payload: {
    errors
  }
});

export const resetSales = () => ({
  type: RESET_SALES_ACTION
});

/////

export const getSaleDetail = ({ id }) => async dispatch => {
  dispatch(getSaleDetailStart());

  try {
    const data = await F.get(`/sale/${id}`);

    dispatch(getSaleDetailSucceeded(data));
    dispatch(showSaleDetail());
  } catch (errors) {
    dispatch(getSaleDetailFailure(errors));
    throw errors;
  }
};

export const getSaleDetailStart = () => ({
  type: PENDING_GET_SALE_DETAIL_ACTION
});

export const getSaleDetailSucceeded = detail => ({
  type: SUCCESS_GET_SALE_DETAIL_ACTION,
  payload: {
    detail
  }
});

export const getSaleDetailFailure = errors => ({
  type: FAILURE_GET_SALE_DETAIL_ACTION,
  payload: {
    errors
  }
});

export const setSaleDetail = ({ detail }) => ({
  type: SUCCESS_SET_SALE_DETAIL_ACTION,
  payload: {
    detail
  }
});

const showSaleDetail = () => ({
  type: SHOW_SALE_DETAIL_ACTION
});

export const hideSaleDetail = () => ({
  type: HIDE_SALE_DETAIL_ACTION
});
