import React, { Component } from "react";
import PropTypes from "prop-types";
const Ref = "Input";
import * as _ from "lodash";

class Input extends Component {
  _onChange = e => {
    const { onChange, name, validation } = this.props;

    onChange(name, e.target.value, e.target);
    validation(e.target.value);
  };

  _validate = () => {
    const { value, validation } = this.props;

    validation(value);
  };

  _onKeyPress = event => {
    const { onKeyPress, name } = this.props;

    onKeyPress(name, event);
  };

  render() {
    const {
      id,
      className,
      name,
      required,
      readOnly,
      pattern,
      min,
      max,
      value,
      placeholder,
      accept,
      onPaste
    } = this.props;

    //Chrome won't get the value if the type is number, so we have to force it to "text"
    const propType = this.props.type;
    const type =
      propType === "number" || propType === "email" ? "text" : propType;

    return (
      <input
        className={
          "form-control input-type-" + type + (className ? " " + className : "")
        }
        id={id ? id : ""}
        name={name}
        required={required || false}
        disabled={readOnly || false}
        type={type}
        pattern={pattern}
        min={min}
        max={max}
        value={value || ""}
        onChange={this._onChange}
        onKeyPress={this._onKeyPress}
        placeholder={placeholder}
        onBlur={this._validate}
        accept={accept}
        ref={Ref}
        onPaste={onPaste}
      />
    );
  }
}

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  pattern: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  accept: PropTypes.string
};

Input.defaultProps = {
  onKeyPress: _.noop
};

export default Input;
