import React, { Component } from "react";
import WindowedSelect, { createFilter } from "react-windowed-select";

interface Props {
  id: string;
  className: string;
  name: string;
  value: any;
  options: OptionType;
  onChange: Function;
  readOnly: boolean;
}

interface State {
  tags: any;
}

interface OptionType {
  name: string;
  id: string;
}

class MultipleSelect extends Component<Props, State> {
  state = {
    value: {}
  };

  _onChange = values => {
    const { onChange, name } = this.props;

    onChange(name, values, this);
  };

  render() {
    const {
      options,
      id,
      className,
      value,
      readOnly,
      isMulti,
      closeMenuOnSelect,
      isClearable
    } = this.props;

    const lists = options.map(l => {
      return {
        label: l.name,
        value: l.id
      };
    });

    return (
      <WindowedSelect
        id={id || ""}
        className={className || ""}
        options={lists}
        onChange={this._onChange}
        value={value}
        isMulti={isMulti}
        isDisabled={readOnly}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={isClearable}
        filterOption={createFilter({
          matchFrom: "any",
          stringify: option => `${option.label}`
        })}
      />
    );
  }
}

WindowedSelect.displayName = "WindowedSelect";

export default MultipleSelect;
