import {
  PENDING_GET_SCHEDULER_JOBS_ACTION,
  SUCCESS_GET_SCHEDULER_JOBS_ACTION,
  FAILURE_GET_SCHEDULER_JOBS_ACTION,
  SUCCESS_DELETE_SCHEDULER_JOB_ACTION
} from "../actionsTypes";

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

const _deleteJob = ({ id, jobs }) => {
  return jobs.filter(job => {
    const { jobId } = job;

    return jobId !== id;
  });
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_SCHEDULER_JOBS_ACTION: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case SUCCESS_GET_SCHEDULER_JOBS_ACTION: {
      const {
        payload: { schedulerJobs: data }
      } = action;

      return {
        ...state,
        ...initialState,
        data
      };
    }
    case FAILURE_GET_SCHEDULER_JOBS_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        ...initialState,
        loading: false,
        error: true,
        ...errors
      };
    }
    case SUCCESS_DELETE_SCHEDULER_JOB_ACTION: {
      const {
        payload: { id }
      } = action;

      const data = _deleteJob({
        id,
        jobs: state.data
      });
      return {
        ...state,
        ...initialState,
        data
      };
    }
    default:
      return state;
  }
};
