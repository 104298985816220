import { constantCase } from "constant-case";

export const toggleSelect = ({ id, value, selectionType }) => {
  const name = constantCase(selectionType);

  return {
    type: `TOGGLE_SELECT_${name}_ACTION`,
    payload: {
      id,
      value
    }
  };
};

export const toggleUnSelect = ({ id, selectionType }) => {
  const name = constantCase(selectionType);

  return {
    type: `TOGGLE_UNSELECT_${name}_ACTION`,
    name,
    payload: {
      id
    }
  };
};

export const toggleSelectAll = ({ selectionType, selectionDisabled }) => {
  const name = constantCase(selectionType);

  return {
    type: `TOGGLE_SELECT_ALL_${name}_ACTION`,
    payload: {
      selectionDisabled
    }
  };
};

export const toggleUnSelectAll = ({ selectionType }) => {
  const name = constantCase(selectionType);

  return {
    type: `TOGGLE_UNSELECT_ALL_${name}_ACTION`
  };
};
