import F from "../../commons/HTTPFetcher";
import AlertAdapter from "../../alerts/models/Alert";
import {
  PENDING_GET_TRANSACTION_ALERTS_ACTION,
  SUCCESS_GET_TRANSACTION_ALERTS_ACTION,
  FAILURE_GET_TRANSACTION_ALERTS_ACTION,
  PENDING_DELETE_TRANSACTION_ALERT_ACTION,
  FAILURE_DELETE_TRANSACTION_ALERT_ACTION,
  SUCCESS_DELETE_TRANSACTION_ALERT_ACTION
} from "../actionsTypes";

export const getTransactionAlerts = () => async dispatch => {
  dispatch(getTransactionAlertsStart());

  return F.get("/alert").then(
    alerts => {
      // const  alerts.map(AlertAdapter.wrapFromServer);

      const transactionAlerts = alerts.map(alert => {
        const {
          id,
          name,
          threshold: { criteria, value, timeWindow, operator, triggerScope },
          active,
          filters,
          sendWith
        } = alert;

        return {
          id,
          name,
          active: !!active,
          threshold: { criteria, operator, value, timeWindow, triggerScope },
          filters,
          sendWith
        };
      });

      return dispatch(getTransactionAlertsSucceeded({ transactionAlerts }));
    },
    errors => {
      dispatch(getTransactionAlertsFailure({ errors }));

      throw errors;
    }
  );
};

export const getTransactionAlertsStart = () => ({
  type: PENDING_GET_TRANSACTION_ALERTS_ACTION
});

export const getTransactionAlertsSucceeded = ({ transactionAlerts }) => ({
  type: SUCCESS_GET_TRANSACTION_ALERTS_ACTION,
  payload: {
    transactionAlerts
  }
});

export const getTransactionAlertsFailure = errors => ({
  type: FAILURE_GET_TRANSACTION_ALERTS_ACTION,
  payload: {
    errors
  }
});

export const deleteTransactionAlert = ({ id }) => async dispatch => {
  dispatch(deleteTransactionAlertStart());

  try {
    await F.delete(`/alert/${id}`);
    return dispatch(deleteTransactionAlertSucceeded({ id }));
  } catch (errors) {
    dispatch(deleteTransactionAlertFailure({ errors }));
    throw errors;
  }
};

export const deleteTransactionAlertStart = () => ({
  type: PENDING_DELETE_TRANSACTION_ALERT_ACTION
});

export const deleteTransactionAlertSucceeded = ({ id }) => ({
  type: SUCCESS_DELETE_TRANSACTION_ALERT_ACTION,
  payload: {
    id
  }
});

export const deleteTransactionAlertFailure = errors => ({
  type: FAILURE_DELETE_TRANSACTION_ALERT_ACTION,
  errors
});
