import React, { Component } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import WidgetContent from "./WidgetContent";
import { TimePeriod } from "../../reporting/models/TimePeriod";
import { TimestampsParams } from "../../redux/actions/widgetData";

import styles from "./styles/styles.css";

export interface UserCurrency {
  currency: string;
  currencyCodeAlpha3: string;
  currencyDecimal: number;
}

export interface WidgetConfig {
  user?: any;
  id: string;
  name: string;
  order: number;
  filters: Array<string>;
  type: string;
  criteria: string;
  timePeriod?: TimePeriod;
  timestamps: TimestampsParams;
  userCurrency: UserCurrency;
  desktopView: boolean;
}

interface Props {
  hide: boolean;
  enableTrend?: boolean;
  formatter?: Function;
  draggable: boolean;
  loading: boolean;
  error: boolean;
  user: any;
  data?: any;
  config: WidgetConfig;
  onDelete?: Function;
  color: string;
  header?: boolean;
  size?: any;
  hideStyle: any;
}

interface State {}

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => (
  <p className={classNames(styles.title, styles.ellipsis)}>
    {title}
    &nbsp;
  </p>
);

export const WidgetAdd = ({ title }) => {
  return (
    <Link title={title} to="/main/widgets/add" className={styles["add-link"]}>
      <div
        className={classNames(
          "widget-thumbnails",
          styles.widget,
          styles.empty,
          { [`draggable-source`]: false }
        )}
      >
        <div className={classNames(styles["widget-container"])}>
          <div className={styles["add-container"]}>
            <span className={classNames(styles["top-bar"])} />
            <span className={classNames(styles["middle-bar"])} />
            <span className={classNames(styles["bottom-bar"])} />
          </div>
        </div>
      </div>
    </Link>
  );
};

class Widget extends Component<Props, State> {
  render() {
    const {
      enableTrend,
      onDelete,
      color,
      config,
      loading,
      error,
      data,
      user,
      draggable = false,
      formatter,
      hide,
      header = true,
      size = ""
    } = this.props;
    const { type, id, name: title } = config;

    return (
      <div
        className={classNames(
          "widget-thumbnails",
          styles.widget,
          styles.thumbnail,
          size,
          { [`draggable-source`]: draggable },
          type
        )}
      >
        {header && (
          <div className={styles.header}>
            <div className={styles.toolbar}>
              <div className={classNames(styles["title-container"])}>
                <Title title={title} />
              </div>
              <div className={styles["remove-container"]}>
                <i className={styles.drag} aria-label="Drag" />
                <div className={styles.vl} />
                {onDelete && (
                  <Link
                    className={styles.delete}
                    onClick={() => onDelete({ id })}
                  >
                    <i className={styles.close} aria-label="Close" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames(
            "widget-container",
            styles["widget-container"],
            {
              [styles["widget-container-header"]]: header
            }
          )}
        >
          <WidgetContent
            enableTrend={enableTrend}
            hide={hide}
            data={data}
            formatter={formatter}
            id={id}
            type={type}
            color={color}
            config={{ ...config, user }}
            loading={loading}
            error={error}
          />
        </div>
      </div>
    );
  }
}

export default Widget;
