import React, { Component } from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";

import NumberFormatter from "../../../formatters/NumberFormatter";

import styles from "./style/TopCustomersList.css";

interface Props {
  chartPrefix: string;
  customersTop: Array<any>;
}

interface State {}

class TopCustomersList extends Component<Props, State> {
  toCustomerTop(customersTop) {
    return customersTop.map(i => {
      const currency = i.currencyCodeAlpha3 + i.currency;

      if (i.cardId === "" || i.cardId === undefined) {
        return {
          cardId: "Unknown customer",
          count: i.count,
          sum: NumberFormatter.formatAmount(i.sum, currency, i.currencyDecimal)
        };
      } else {
        return {
          cardId: `Customer : ${i.cardId.substring(0, 10)}`, //eslint-disable-line no-magic-numbers
          count: i.count,
          sum: NumberFormatter.formatAmount(i.sum, currency, i.currencyDecimal)
        };
      }
    });
  }

  render() {
    const { chartPrefix, customersTop = [], t } = this.props;

    const title = t(`${chartPrefix}.title`);
    const cardIdLabel = t(`${chartPrefix}.cardId`);
    const countLabel = t(`${chartPrefix}.count`);
    const sumLabel = t(`${chartPrefix}.sum`);

    const items = this.toCustomerTop(customersTop);

    return (
      <div>
        <h3>{title}</h3>
        <table className={classNames("table", styles.table)}>
          <thead>
            <tr>
              <th>{cardIdLabel}</th>
              <th>{countLabel}</th>
              <th>{sumLabel}</th>
            </tr>
          </thead>
          <tbody>
            {items.map((i: any) => (
              <tr key={i.cardId}>
                <td className="cardIdEllipsis">{i.cardId}</td>
                <td>{i.count}</td>
                <td className="countCustomersReporting">{i.sum}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation()(TopCustomersList);
