import React, { Component } from "react";

interface Props {
  id: string;
  className: string;
  name: string;
  value: string;
  onChange: Function;
  validation: Function;
  required: boolean;
}

class Textarea extends Component<Props, {}> {
  _onChange = ({ target }) => {
    const { value } = target;
    const { name, validation, onChange } = this.props;
    validation(value);
    return onChange(name, value);
  };

  render() {
    const { className, id, name, required, value } = this.props;

    return (
      <textarea
        className={"form-control" + (className ? " " + className : "")}
        id={id ? id : ""}
        name={name}
        required={required || false}
        value={value}
        onChange={this._onChange}
      />
    );
  }
}

export default Textarea;
