import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_CATALOGITEMS_ACTION,
  SUCCESS_GET_CATALOGITEMS_ACTION,
  FAILURE_GET_CATALOGITEMS_ACTION,
  PENDING_DELETE_CATALOGITEM_ACTION,
  FAILURE_DELETE_CATALOGITEM_ACTION,
  RESET_CATALOGITEMS_ACTION
} from "../actionsTypes";

export const getCatalogItems = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => async dispatch => {
  dispatch(getCatalogItemsStart());

  try {
    const url = new URL("/catalog/search", PORTAL_URL);

    if (tableCount) {
      url.searchParams.append("max", tableCount);
    }

    const { count, scrollId, searchResults: data } = await F.post(
      `${url.pathname}${url.search}`,
      {
        body: {
          filters,
          fields,
          sort
        },
        contentType: ContentTypes.JSON
      }
    );

    return dispatch(getCatalogItemsSucceeded({ count, scrollId, data }));
  } catch (errors) {
    dispatch(getCatalogItemsFailure({ errors }));

    throw errors;
  }
};

const getCatalogItemsStart = () => ({
  type: PENDING_GET_CATALOGITEMS_ACTION
});

const getCatalogItemsSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_CATALOGITEMS_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getCatalogItemsFailure = errors => ({
  type: FAILURE_GET_CATALOGITEMS_ACTION,
  errors
});

export const resetCatalogItems = () => ({
  type: RESET_CATALOGITEMS_ACTION
});

///////////

export const deleteCatalogItems = ({ ids }) => async dispatch => {
  dispatch(deleteCatalogItemStart());

  try {
    const url = new URL("/catalog/item/batch", PORTAL_URL);

    return await F.delete(`${url.pathname}${url.search}`, {
      body: ids,
      contentType: ContentTypes.JSON
    });
  } catch (errors) {
    dispatch(deleteCatalogItemFailure({ errors }));
    throw errors;
  }
};

const deleteCatalogItemStart = () => ({
  type: PENDING_DELETE_CATALOGITEM_ACTION
});

const deleteCatalogItemFailure = errors => ({
  type: FAILURE_DELETE_CATALOGITEM_ACTION,
  errors
});
