import _ from "lodash";
import i18next from "i18next";
import TagUtil from "../tag/model/TagUtil";
import AuthConstants from "../connection/constants/ConnectionConstant";

const EXCLUSIVE_CONTAINER_KEY = "tag_or_scope";
const FULL = "full";
const BY_TAGS = "restrictedByTags";
const BY_IDS = "restrictedByIds";
const BY_TRANSACTION_FIELD = "restrictedByTransactionField";

export default class UserUtil {
  static buildFromForm(formModel, level) {
    const EXCLUSIVE_CONTAINER_VALUE = formModel[EXCLUSIVE_CONTAINER_KEY];
    let access = null;

    if (EXCLUSIVE_CONTAINER_VALUE === FULL) {
      access = {
        full: true
      };
    } else if (EXCLUSIVE_CONTAINER_VALUE === BY_TAGS) {
      access = {
        full: false,
        restrictedByTags: TagUtil.selectedTagsToObject(
          formModel.restrictedByTags
        )
      };
    } else if (EXCLUSIVE_CONTAINER_VALUE === BY_IDS) {
      const { restrictedByIds = [] } = formModel;
      access = {
        full: false,
        restrictedByIds: restrictedByIds.map(id => id.value)
      };
    } else if (EXCLUSIVE_CONTAINER_VALUE === BY_TRANSACTION_FIELD) {
      const newConstraint = {};
      newConstraint[formModel.restrictedByTransactionField.name] =
        formModel.restrictedByTransactionField.value || "";
      access = {
        full: false,
        restrictedByTransactionField: newConstraint
      };
    }

    let services = null;
    if (formModel.restrictedByServices) {
      services = formModel.serviceSet.map(service => service.value);
    }

    return {
      email: formModel.email,
      login: formModel.login,
      permissions: formModel.permissions.map(permission => permission.value),
      scope: { level, access },
      singleSession: formModel.singleSession,
      services
    };
  }

  static toMultipleSelectFormat(perm) {
    return { id: perm, name: i18next.t(`user.permissions.${perm}`) };
  }

  static toSelectOption(permissions) {
    return permissions.map(perm => {
      return {
        value: perm,
        label: i18next.t(`user.permissions.${perm}`)
      };
    });
  }

  static buildToForm(user, accessibleScopes) {
    const formModel: any = {};
    if (user) {
      formModel.email = user.email;
      formModel.login = user.login;
      formModel.permissions = this.toSelectOption(user.permissions);
      formModel.status = user.status;
      // single hidden only for merchant user
      if (
        user.scope.level &&
        user.scope.level.type !== AuthConstants.MERCHANT
      ) {
        formModel.singleSession = user.singleSession;
      }

      if (user.scope.access.full) {
        formModel.tag_or_scope = FULL;
        formModel.restrictedByIds = undefined;
        formModel.restrictedByTags = undefined;
      } else if (_.has(user.scope.access, BY_IDS)) {
        formModel.tag_or_scope = BY_IDS;
        formModel.restrictedByIds = _(accessibleScopes)
          .filter(scope =>
            _.includes(user.scope.access.restrictedByIds, scope.id)
          )
          .map(s => ({ label: s.name, value: s.id }))
          .value();

        formModel.restrictedByTags = undefined;
      } else if (_.has(user.scope.access, BY_TAGS)) {
        formModel.tag_or_scope = BY_TAGS;
        formModel.restrictedByTags = TagUtil.objectToSelectedTags(
          user.scope.access.restrictedByTags
        );
        formModel.restrictedByIds = undefined;
      } else {
        formModel.tag_or_scope = BY_TRANSACTION_FIELD;
        const constraint = _.chain(
          user.scope.access.restrictedByTransactionField
        )
          .map(function(attrValue, key) {
            return { name: key, value: attrValue };
          })
          .head()
          .value();
        formModel.restrictedByTransactionField = constraint;
        formModel.restrictedByTags = undefined;
        formModel.restrictedByIds = undefined;
      }

      if (user.services) {
        formModel.restrictedByServices = true;
        formModel.serviceSet = user.services.map(service => {
          return {
            value: service,
            label: i18next.t(`service.${service}`)
          };
        });
      } else {
        formModel.restrictedByServices = false;
        formModel.serviceSet = [];
      }
    }

    return formModel;
  }
}
