import {
  PENDING_GET_SECOND_FACTORS_ACTION,
  SUCCESS_GET_SECOND_FACTORS_ACTION,
  FAILURE_GET_SECOND_FACTORS_ACTION
} from "../actionsTypes";

export const getSecondFactors = () => async dispatch => {
  dispatch(getSecondFactorsStart());

  const { default: F } = await import("../../commons/HTTPFetcher");

  return F.get("/authenticate/two-factor", "v2").then(
    secondFactors => {
      dispatch(getSecondFactorsSucceeded(secondFactors));
      return secondFactors;
    },
    errors => {
      dispatch(getSecondFactorsFailure({ errors }));

      throw errors;
    }
  );
};

const getSecondFactorsStart = () => ({
  type: PENDING_GET_SECOND_FACTORS_ACTION
});

const getSecondFactorsSucceeded = secondFactors => ({
  type: SUCCESS_GET_SECOND_FACTORS_ACTION,
  payload: {
    secondFactors
  }
});

const getSecondFactorsFailure = errors => ({
  type: FAILURE_GET_SECOND_FACTORS_ACTION,
  payload: {
    errors
  }
});
