import {
  PENDING_GET_COUNTRIES_ACTION,
  SUCCESS_GET_COUNTRIES_ACTION,
  FAILURE_GET_COUNTRIES_ACTION,
  RESET_COUNTRIES_STATE_ACTION
} from "../actionsTypes";
import F from "../../commons/HTTPFetcher";

const getCountriesStart = () => ({
  type: PENDING_GET_COUNTRIES_ACTION
});

const getCountriesSucceeded = countries => ({
  type: SUCCESS_GET_COUNTRIES_ACTION,
  payload: {
    countries
  }
});

const getCountriesFailure = errors => ({
  type: FAILURE_GET_COUNTRIES_ACTION,
  payload: {
    errors
  }
});

export const getCountries = () => dispatch => {
  dispatch(getCountriesStart());

  return F.get(`/country`).then(
    countries => dispatch(getCountriesSucceeded(countries)),
    errors => {
      dispatch(getCountriesFailure({ errors }));

      throw errors;
    }
  );
};

export const resetCountriesSate = () => ({
  type: RESET_COUNTRIES_STATE_ACTION
});
