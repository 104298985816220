export default class SmsSettings {
  static buildFromForm(smsSettingsForm) {
    const { smsProvider: provider } = smsSettingsForm;

    return {
      provider
    };
  }

  static wrapFromServer(smsSettings) {
    const { provider: smsProvider } = smsSettings;

    return {
      smsProvider
    };
  }
}
