import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../i18n/components/I18nSpan";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import If from "../../components/if";
import NumberFormatter from "../../formatters/NumberFormatter";
import AlertConstants from "../constants/AlertConstant";
import TransactionAdvancedSearch from "../../reporting/transactions/components/TransactionAdvancedSearch";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import Select from "../../ingenicoForm/components/form/Select";
import Checkbox from "../../ingenicoForm/components/form/Checkbox";
import Input from "../../ingenicoForm/components/form/Input";
import constants from "../../connection/constants/ConnectionConstant";
import FormTitle from "../../ingenicoForm/components/FormTitle";

import {
  createTransactionAlert,
  addNotificationSuccess,
  addNotificationError,
  getFields,
  setSearchContext,
  cleanSearchContext
} from "../../redux/actions";

interface Props {
  addNotificationError: Function;
  addNotificationSuccess: Function;
  getFields: Function;
  hasTransactionAlertService: boolean;
  hasTransactionAlertSmsService: boolean;
  history: any;
  hasPhone: boolean;
  isCustomer: boolean;
  createTransactionAlert: Function;
  currencyDecimal: any;
  timeWindows: any;
  triggerScopes: any;
  criterias: any;
  operators: any;
  transactionFields: any;
  triggerScopeI18NKey: any;
}

const advancedSearchKey = AdvancedSearchConstants.ALERT_KEY;

class CreateAdvancedAlert extends Component<Props, any> {
  state = {
    form: {
      criteria: "Count",
      operator: "=",
      timeWindow: "Immediate",
      value: null,
      triggerScope: "onAll",
      sms: false,
      email: false
    }
  };

  componentDidMount() {
    const { getFields, cleanSearchContext } = this.props;
    cleanSearchContext();

    return getFields({ name: "transaction", version: "v2" });
  }

  _onChangeHandler = (name, value) =>
    this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value
        }
      };
    });

  _saveAlert = async e => {
    e.preventDefault();

    const {
      history,
      hasPhone,
      isCustomer,
      createTransactionAlert,
      currencyDecimal,
      addNotificationError,
      addNotificationSuccess,
      hasTransactionAlertService,
      hasTransactionAlertSmsService,
      filters
    } = this.props;
    const { form } = this.state;

    let sendWith = [];
    const hasAllAlertServices =
      hasTransactionAlertService && hasTransactionAlertSmsService;

    if (form["email"]) {
      sendWith.push(AlertConstants.SEND_WITH_EMAIL);
    }
    if (form["sms"]) {
      sendWith.push(AlertConstants.SEND_WITH_SMS);
    }

    if (hasAllAlertServices && sendWith.length == 0) {
      return addNotificationError("notification.error");
    }
    if (form["sms"] && !hasPhone) {
      return addNotificationError("alert.form.errors.msisdn-missing");
    }
    if (sendWith.length === 0) {
      if (hasTransactionAlertService) {
        sendWith.push(AlertConstants.SEND_WITH_EMAIL);
      }
      if (hasTransactionAlertSmsService) {
        sendWith.push(AlertConstants.SEND_WITH_SMS);
      }
    }

    const newForm = {
      ...form,
      filters,
      value:
        form.criteria === AlertConstants.SUM
          ? NumberFormatter.unformatAmount(form.value, currencyDecimal)
          : form.value,
      sendWith,
      active: true
    };

    return createTransactionAlert({ alert: newForm, isCustomer }).then(
      () => {
        addNotificationSuccess("notification.success");
        return history.push(
          `/main/reporting/alerts/${AlertConstants.ADVANCED_ALERT}`
        );
      },
      () => addNotificationError("notification.error")
    );
  };

  goToList = e => {
    e.preventDefault();
    const { history } = this.props;
    return history.push(
      `/main/reporting/alerts/${AlertConstants.ADVANCED_ALERT}`
    );
  };

  _search = async ({ filters, searchContext }) => {
    const { setSearchContext } = this.props;

    setSearchContext({ context: searchContext, pathname: advancedSearchKey });
  };

  render() {
    const {
      timeWindows,
      triggerScopes,
      criterias,
      operators,
      transactionFields,
      triggerScopeI18NKey,
      hasTransactionAlertService,
      hasTransactionAlertSmsService,
      searchContext,
      t
    } = this.props;

    return (
      <div>
        <FormTitle titleKey="alert.title" actionKey="alert.create.action" />
        <form className="ingenico-form form-horizontal options-search-form clearfix">
          <BootstrapInput
            ref="name"
            name="name"
            validation={() => undefined}
            onChange={this._onChangeHandler}
            required={true}
            descriptor={{ type: "text", label: "alert.form.name.label" }}
            formValue={this.state.form.name}
          />

          <BootstrapInput
            ref="timeWindow"
            name="timeWindow"
            validation={() => undefined}
            onChange={this._onChangeHandler}
            required={true}
            descriptor={{
              type: "select",
              label: "alert.form.timeWindow.label",
              options: timeWindows || {}
            }}
            formValue={this.state.form.timeWindow}
          />

          <div className="form-group">
            <label className="control-label col-sm-3">
              <I18nSpan msgKey="alert.form.triggerScope.label" />
              <sup className="required-label">*</sup>
            </label>
            <div className="col-sm-6 form-inline">
              <Select
                onChange={this._onChangeHandler}
                required={true}
                readOnly={false}
                value={this.state.form.triggerScope}
                options={triggerScopes || []}
                name="triggerScope"
                className="inline-element"
                validation={() => undefined}
              />
              <span className="control-label">
                <I18nSpan msgKey={triggerScopeI18NKey} />
              </span>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-sm-3">
              <I18nSpan msgKey="alert.form.quantities.label" />
              <sup className="required-label">*</sup>
            </label>
            <div className="col-sm-6 form-inline">
              <Select
                onChange={this._onChangeHandler}
                required={true}
                readOnly={false}
                value={this.state.form.criteria}
                options={criterias || []}
                name="criteria"
                className="inline-element"
                validation={() => undefined}
              />
              <Select
                onChange={this._onChangeHandler}
                required={true}
                readOnly={false}
                value={this.state.form.operator}
                options={operators || []}
                name="operator"
                className="inline-element"
                validation={() => undefined}
              />
              <Input
                onChange={this._onChangeHandler}
                required={true}
                readOnly={false}
                value={this.state.form.value}
                type="text"
                name="value"
                validation={() => undefined}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-sm-3" htmlFor="send-copy">
              <I18nSpan msgKey="scheduled.form.filters" />
            </label>
            <div className="col-sm-6">
              <TransactionAdvancedSearch
                className="mtn"
                onChange={this._search}
                name={advancedSearchKey}
                transactionFields={transactionFields}
                searchContext={searchContext}
              />
            </div>
          </div>

          <If
            test={hasTransactionAlertService && hasTransactionAlertSmsService}
          >
            <div className="form-group">
              <label className="control-label col-sm-3">
                <I18nSpan msgKey="alert.form.sendWith.label" />
                <sup className="required-label">*</sup>
              </label>
              <div className="col-sm-6">
                <div>
                  <Checkbox
                    name="email"
                    onChange={this._onChangeHandler}
                    value={t("alert.form.sendWith.email.label")}
                    checked={this.state.form.email}
                  />
                  {/* need to check Phone number*/}
                  <Checkbox
                    name="sms"
                    onChange={this._onChangeHandler}
                    value={t("alert.form.sendWith.sms.label")}
                    checked={this.state.form.sms}
                  />
                </div>
              </div>
            </div>
          </If>

          <div className="pull-right">
            <button
              onClick={this._saveAlert}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <Link
              onClick={this.goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

const mapTimeWindows = ({ $t }) => {
  return {
    timeWindows: {
      Immediate: $t("alert.threshold.timeWindow.Immediate"),
      Hour: $t("alert.threshold.timeWindow.Hour"),
      Day: $t("alert.threshold.timeWindow.Day"),
      Week: $t("alert.threshold.timeWindow.Week"),
      Month: $t("alert.threshold.timeWindow.Month")
    }
  };
};

const mapTriggerScopes = ({ $t }) => {
  return {
    triggerScopes: {
      onAll: $t("alert.threshold.triggerScope.onAll"),
      onAnyOf: $t("alert.threshold.triggerScope.onAnyOf")
    }
  };
};

const mapCriterias = ({ $t }) => {
  return {
    criterias: {
      Count: $t("alert.threshold.criteria.Count"),
      Sum: $t("alert.threshold.criteria.Sum")
    }
  };
};

const mapOperators = ({ $t }) => {
  return {
    operators: {
      "=": $t("alert.threshold.criteria.equalTo"),
      ">=": $t("alert.threshold.criteria.moreThan"),
      "<=": $t("alert.threshold.criteria.lessThan")
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: {
        scope: {
          level: { type: userType }
        },
        msisdn
      },
      levelSettings: _levelSettings
    } = {
      user: {
        scope: {
          level: {
            type: ""
          }
        },
        msisdn: ""
      }
    },
    services: { data: services = [] } = { data: [] },
    genericFields: { data: transactionFields = [] },
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const hasPhone = !!msisdn;

  const { t: $t } = ownProps;

  const hasTransactionAlertService = services.includes("reportingAlert");
  const hasTransactionAlertSmsService = services.includes("reportingAlertSms");

  const isCustomer = userType === constants.CUSTOMER;

  const triggerScopeI18NKey =
    userType === constants.CUSTOMER
      ? "alert.form.triggerScope.merchant"
      : "alert.form.triggerScope.store";

  return {
    filters,
    searchContext,
    hasPhone,
    isCustomer,
    ...mapTimeWindows({ $t }),
    ...mapTriggerScopes({ $t }),
    ...mapCriterias({ $t }),
    ...mapOperators({ $t }),
    transactionFields,
    hasTransactionAlertSmsService,
    hasTransactionAlertService,
    triggerScopeI18NKey,
    currencyDecimal: userCurrency.currencyDecimal,
    keyCreateFilter: "searchbar.placeholder"
  };
};

const mapDispatchToProps = dispatch => ({
  createTransactionAlert: ({ alert, isCustomer }) =>
    dispatch(createTransactionAlert({ alert, isCustomer })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname
      })
    ),
  cleanSearchContext: () =>
    dispatch(
      cleanSearchContext({
        key: advancedSearchKey,
        context: { key: advancedSearchKey },
        pathname: advancedSearchKey
      })
    )
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateAdvancedAlert);
