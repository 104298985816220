import {
  FAILURE_ADD_SECOND_FACTOR_ACTION,
  PENDING_ADD_SECOND_FACTOR_ACTION,
  SUCCESS_ADD_SECOND_FACTOR_ACTION,
  FAILURE_DELETE_SECOND_FACTOR_ACTION,
  PENDING_DELETE_SECOND_FACTOR_ACTION,
  SUCCESS_DELETE_SECOND_FACTOR_ACTION,
  SUCCESS_REVOKE_SECOND_FACTOR_ACTION,
  PENDING_REVOKE_SECOND_FACTOR_ACTION,
  FAILURE_REVOKE_SECOND_FACTOR_ACTION
} from "../actionsTypes";

export const addSecondFactor = ({
  userId,
  supportedFactor
}) => async dispatch => {
  dispatch(addSecondFactorStart());

  const { default: F } = await import("../../commons/HTTPFetcher");

  return F.post(
    `/authenticate/${userId}/two-factor/${supportedFactor}`,
    null,
    "v2"
  ).then(
    () => dispatch(addSecondFactorSucceeded()),
    errors => {
      dispatch(addSecondFactorFailure({ errors }));

      throw errors;
    }
  );
};

const addSecondFactorStart = () => ({
  type: PENDING_ADD_SECOND_FACTOR_ACTION
});

const addSecondFactorSucceeded = () => ({
  type: SUCCESS_ADD_SECOND_FACTOR_ACTION
});

const addSecondFactorFailure = (errors: any) => ({
  type: FAILURE_ADD_SECOND_FACTOR_ACTION,
  payload: {
    errors
  }
});

export const deleteSecondFactor = ({ userId }) => async dispatch => {
  dispatch(deleteSecondFactorStart());

  const { default: F } = await import("../../commons/HTTPFetcher");

  return F.delete(`/authenticate/${userId}/two-factor`, null, "v2").then(
    () => {
      dispatch(deleteSecondFactorSucceeded());
    },
    errors => {
      dispatch(deleteSecondFactorFailure({ errors }));

      throw errors;
    }
  );
};

const deleteSecondFactorStart = () => ({
  type: PENDING_DELETE_SECOND_FACTOR_ACTION
});

const deleteSecondFactorSucceeded = () => ({
  type: SUCCESS_DELETE_SECOND_FACTOR_ACTION
});

const deleteSecondFactorFailure = (errors: any) => ({
  type: FAILURE_DELETE_SECOND_FACTOR_ACTION,
  payload: {
    errors
  }
});

export const revokeSecondFactor = ({ userId }) => async dispatch => {
  dispatch(revokeSecondFactorStart());

  const { default: F } = await import("../../commons/HTTPFetcher");

  return F.post(`/authenticate/${userId}/two-factor/revoke`, null, "v2").then(
    () => dispatch(revokeSecondFactorSucceeded()),
    errors => {
      dispatch(revokeSecondFactorFailure({ errors }));

      throw errors;
    }
  );
};

const revokeSecondFactorStart = () => ({
  type: PENDING_REVOKE_SECOND_FACTOR_ACTION
});

const revokeSecondFactorSucceeded = () => ({
  type: SUCCESS_REVOKE_SECOND_FACTOR_ACTION
});

const revokeSecondFactorFailure = (errors: any) => ({
  type: FAILURE_REVOKE_SECOND_FACTOR_ACTION,
  payload: {
    errors
  }
});
