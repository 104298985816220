import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import OfferUtils from "../util/OfferUtils";
import { Offer } from "../model/Offer";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "../styles/styles.css";

import {
  getOffers,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../redux/actions";

import { EditAction } from "../../datatable/components/multipleActions";

interface Props {
  getOffers: Function;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  offers: any;
  color: any;
  selectedOfferIds: any;
}

class ListOfferView extends Component<Props, {}> {
  componentDidMount() {
    const { getOffers } = this.props;

    getOffers();
  }

  _isRowEditable = element => {
    return !_.isEmpty(element.name);
  };

  onSelectRow = (rowId, value) => {
    const { selectedOfferIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedOfferIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const { offers, color, selectedOfferIds } = this.props;

    const EditOffer = () => {
      if (selectedOfferIds.length === 1) {
        const [offerId] = selectedOfferIds;

        const route = `/main/offers/edit-offer/${offerId}`;

        return <EditAction route={route} msgKey={"dataTable.edit"} />;
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <FormTitle
            color={color}
            titleKey="offer.title"
            actionKey="offer.list.action"
          />

          <div className={styles.creation}>
            <Link
              className="btn btn-ingenico create-button"
              to="/main/offers/new-offer"
            >
              <span className="glyphicon glyphicon-plus" aria-hidden="true" />
              <I18nSpan msgKey="offer.list.button.create" />
            </Link>
          </div>
        </div>

        <DataTable
          data={offers}
          attributes={["name", "services"]}
          key="offer.list"
          i18nKey="offer.list"
          idAttribute="offerId"
          color={color}
          isRowEditable={this._isRowEditable}
          hasActions={false}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedOfferIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[EditOffer]}
        />
      </div>
    );
  }
}

const mapOffers = offers => {
  const offersList = _.map(offers, (offer: Offer) => {
    const { id: offerId, name, services } = offer;

    return {
      offerId,
      name,
      services: OfferUtils.i18nServices(services).join(", ")
    };
  });

  return offersList;
};

const mapStateToProps = (state, ownProps) => {
  const {
    offers: { data: prevOffers, selection },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  const offers = mapOffers(prevOffers);
  const selectedOfferIds = _.keys(_.pick(selection, _.identity));

  return {
    color,
    offers,
    selectedOfferIds,
    selection
  };
};

const mapDispatchToProps = dispatch => ({
  getOffers: () => dispatch(getOffers()),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "OFFERS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "OFFERS" })),
  toggleSelectAll: () => dispatch(toggleSelectAll({ selectionType: "OFFERS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "OFFERS" }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ListOfferView);
