export function extractFieldKeys(fields) {
  return fields.map(f => f.key);
}

export function extractObjectKeys(object, level) {
  let keys = [];
  let nextLevel = function(obj, key) {
    key = key || "";
    for (let n in obj) {
      if (obj.hasOwnProperty(n) && key.split(".").length < level) {
        if (obj[n] instanceof Object && !(obj[n] instanceof Array)) {
          nextLevel(obj[n], key != "" ? key + "." + n : n);
        } else {
          keys.push(key != "" ? key + "." + n : n);
        }
      }
    }
  };
  nextLevel(object, "");
  return keys;
}
