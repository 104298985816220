import React, { Component } from "react";
import NotificationSystem from "react-notification-system";
import { connect } from "react-redux";

export class NotificationContainer extends Component {
  componentWillReceiveProps(newProps) {
    const { notification } = newProps;

    if (notification.level) {
      this.notificationSystem.addNotification({
        ...notification
      });
    }
  }

  render() {
    const style = {
      //See this file : https://github.com/igorprado/react-notification-system/blob/master/src/styles.js
      Containers: {
        // Override the notification item
        tr: {
          // Applied to every notification, regardless of the notification level
          top: "40px"
        }
      }
    };

    return (
      <NotificationSystem
        ref={el => (this.notificationSystem = el)}
        style={style}
      />
    );
  }
}

export const mapStateToProps = state => {
  const { notification } = state;

  return {
    notification
  };
};

export default connect(mapStateToProps, null)(NotificationContainer);
