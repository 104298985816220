import Validators from "../../ingenicoForm/validation/Validators";

export function requiredValidation() {
  return value => {
    const requiredPromise = Promise.resolve(
      Validators.requiredValidator(value)
    );

    return Promise.all([requiredPromise]);
  };
}
