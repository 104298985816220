import React from "react";
import classNames from "classnames";

import style from "./style/LoadingChart.css";

interface Props {
  loading?: boolean;
  color?: string;
  children?: any;
}

const LoadingChart = ({ loading, color, children }: Props) => {
  if (loading) {
    return (
      <div className={style.container}>
        <div className={classNames(style.loading)}>
          <div className={classNames("loadingTxt-custom", style.loadingTxt)}>
            <div className={classNames("spinner-custom", style.spinner)}>
              <div className={classNames("bounce1-custom", style.bounce1)} />
              <div className={classNames("bounce2-custom", style.bounce2)} />
              <div className={classNames("bounce3-custom", style.bounce3)} />
            </div>
          </div>
        </div>
        <style>
          {`
                    /* Style loader */
               .loadingTxt-custom {color: ${color}!important;}
              .load-bar,
              .spinner-custom .bounce1-custom,
              .spinner-custom .bounce2-custom,
              .spinner-custom .bounce3-custom,
              .load-bar .bar2 {background-color:${color}!important;}
              .spinner .bounce2{background-color:#FFFFFF!important;}
              .load-bar .bar1 {background-color:#BDC3C7;}
              .load-bar .bar3 {background-color:#D2D7D3;}

            `}
        </style>
      </div>
    );
  }

  return children;
};

export default LoadingChart;
