import React, { Component } from "react";

class Iframe extends Component {
  iframe = null;

  componentDidMount() {
    const { docContent } = this.props;

    this.iframe.addEventListener("load", this.resizeIframe);
    this.iframe.contentDocument.write(docContent);
    this.iframe.contentDocument.close();
  }

  resizeIframe = () => {
    const element = this.iframe.contentWindow.document.body;
    this.iframe.height = `${element.scrollHeight}px`;
    this.iframe.width = `${element.scrollWidth}px`;
  };

  render() {
    const { id } = this.props;

    return (
      <iframe
        id={id}
        className="iframe"
        sandbox="allow-modals allow-same-origin"
        src="about:blank"
        ref={iframe => {
          this.iframe = iframe;
        }}
      />
    );
  }
}

export default Iframe;
