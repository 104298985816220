import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { WidgetConfig } from "../Widget";
import { replaceIdByName } from "../../services/AggregationDataHandler";
import NumberFormatter from "../../../formatters/NumberFormatter";
import BarChart from "../../../chart/components/chart/BarChart";
import LoadingChart from "./LoadingChart";
import { getData } from "../../utils/DataProvider";

interface Data {
  criteria?: any;
}

interface Props {
  data?: Data;
  loadingFields?: boolean;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  config: WidgetConfig;
  customerSettings: any;
}

class ComparisonAverageBasket extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { user, desktopView } = config;
    if (desktopView) {
      return getData({ user, config });
    }
  }

  render() {
    const {
      data,
      loading,
      loadingFields,
      color,
      config,
      customerSettings,
      t
    } = this.props;
    const { userCurrency = {}, defaultPaletteId } = config;

    const chartTitle = t("reporting.comparison.average");

    const isExistingCurrency = customerSettings && customerSettings.currency;
    const currency = isExistingCurrency
      ? customerSettings.currency.alpha3 + customerSettings.currency.symbol
      : "?";
    const currencyExponent = isExistingCurrency
      ? customerSettings.currency.exponent
      : 0;
    const averageByAggregations = replaceIdByName("avg", data);

    let barChartCategories = [];
    data.criteria === "selectedService" ||
    data.criteria === "transactionType" ||
    data.criteria === "nokReason" ||
    data.criteria === "transactionResult" ||
    data.criteria === "technologySelected"
      ? (barChartCategories = _.pluck(averageByAggregations, "name").map(c =>
          t(`reporting.comparison.${c.toLowerCase()}`)
        ))
      : (barChartCategories = _.pluck(averageByAggregations, "name"));
    const barChartData = _.pluck(averageByAggregations, "value");
    const barChartSeries = [
      {
        name: "",
        showInLegend: false,
        colorByPoint: true,
        data: barChartData
      }
    ];

    const tooltip = {
      y: {
        formatter: function(value) {
          return NumberFormatter.formatAmount(
            value,
            currency,
            currencyExponent
          );
        }
      }
    };

    const isLoading = loading || loadingFields;

    return (
      <LoadingChart loading={isLoading} color={color}>
        <BarChart
          color={color}
          title={`${chartTitle} (${NumberFormatter.formatCurrency(currency)})`}
          yAxisLegend={t("reporting.comparison.amount")}
          tooltip={tooltip}
          categories={barChartCategories}
          series={barChartSeries}
          currency={userCurrency}
          palette={defaultPaletteId}
        />
      </LoadingChart>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { levelSettings: customerSettings }
  } = state;
  const { id } = ownProps;
  const {
    widgets: { [id]: widget },
    transactions: { loading: loadingFields, errorFields }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      customerSettings,
      loadingFields,
      errorFields,
      data: stats,
      loading,
      error
    };
  }

  return {
    customerSettings,
    loadingFields,
    errorFields,
    data: {},
    loading: true
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ComparisonAverageBasket);
