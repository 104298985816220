import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import KeyIndicator from "../../home/components/widgets/KeyIndicatorMobile";

import { WidgetConfig } from "../../home/components/Widget";
import I18nNumber from "../../i18n/components/I18nNumber";
import {
  getData,
  getDataWithTrend,
  getTrend
} from "../../home/utils/DataProvider";
import TrendMobile from "../../home/components/widgets/TrendMobile";

import styles from "../styles/styles.css";

interface Data {
  value?: number;
}

interface Props {
  enableTrend: boolean;
  getTrend: Function;
  trend: any;
  formatter?: Function;
  currencyFormatter: boolean;
  getData: Function;
  data: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  icon: string;
  color?: string;
  prefersDarkMode: boolean;
  config: WidgetConfig;
}

class TransactionNumber extends Component<Props, any> {
  componentDidMount() {
    const { config, getData, getTrend, enableTrend = false } = this.props;

    getDataWithTrend({ config, getData, getTrend, enableTrend });
  }

  render() {
    const {
      data,
      title,
      icon,
      color,
      prefersDarkMode,
      loading,
      error,
      config,
      trend: { data: { trend: trendData } } = { data: { trend: {} } }
    } = this.props;

    const {
      timePeriod: { type: periodType },
      timestamps
    } = config;

    const { min, max } = timestamps;

    const { transactionCount } = data;

    return (
      <div
        className={classNames(
          "widget-thumbnails",
          styles.widget,
          styles.thumbnail,
          {
            [styles["thumbnail--dark"]]: prefersDarkMode,
            [styles["thumbnail--light"]]: !prefersDarkMode
          }
        )}
      >
        <div className={classNames(styles["widget-container"])}>
          <KeyIndicator
            color={color}
            titleKey={title}
            iconKey={icon}
            loading={loading}
            error={error}
            renderTrend={() => (
              <TrendMobile
                enableTrend={true}
                data={trendData}
                type={periodType}
                startTime={min}
                endTime={max}
                field="sales"
              />
            )}
          >
            <div>
              <I18nNumber number={transactionCount} format="0,0" />
            </div>
          </KeyIndicator>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { config = {} } = ownProps;
  const { type } = config;
  const {
    widgetData: {
      widgets: { [type]: widget }
    },
    widgetTrend: { widgets: { [type]: trendData } } = { widgets: {} },
    theme: {
      color: {
        data: { color }
      },
      darkMode: prefersDarkMode
    }
  } = state;

  const wrapTrend = () => {
    if (trendData) {
      const { data, loading, error } = trendData;

      return {
        data,
        loading,
        error
      };
    }
    return {
      data: {},
      loading: true
    };
  };

  const trend = wrapTrend();

  const widgetProps = {
    enableTrend: true,
    title: "widget.type.transactionNumber",
    icon: "icon-transactions",
    color
  };

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      trend,
      data: stats,
      prefersDarkMode,
      loading,
      error,
      ...widgetProps
    };
  }

  return {
    trend,
    data: {},
    prefersDarkMode,
    loading: true,
    ...widgetProps
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config })),
  getTrend: ({ config }) => dispatch(getTrend({ config }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionNumber);
