import React from "react";
import Chart from "react-apexcharts";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import classNames from "classnames";

interface Props {
  options: any;
  series: any;
  type: string;
  className: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "44vw",
      height: "100%",
      display: "flex",
      alignItems: "center",
      textAlign: "right"
    }
  })
);

const AngleCirle = ({ className, options, series, type }: Props) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)}>
      <Chart options={options} series={series} type={type} height="100%" />
    </div>
  );
};

export default AngleCirle;
