import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import ReceiptViewer from "./ReceiptViewer";

import {
  downloadTransactionsReceipt,
  downloadReconciliationReceipt,
  addNotificationSuccess,
  addNotificationError
} from "../redux/actions";

interface Props {
  history: any;
  showPopin: boolean;
  transactionId: string;
  onClose: Function;
  popinContent: string;
  receiptActionType: string;
  receiptType: string;
  downloadReceipt: Function;
  addNotificationError: Function;
}

class TransactionReceiptViewer extends Component<Props, {}> {
  _downloadReceipt = async () => {
    const {
      downloadReceipt,
      transactionId,
      receiptType,
      addNotificationError
    } = this.props;

    try {
      return await downloadReceipt({
        receiptId: transactionId,
        receiptType
      });
    } catch (errors) {
      const text = await errors;

      return addNotificationError("receipt.download.error", [text]);
    }
  };

  _sendReceipt = () => {
    const {
      transactionId,
      history,
      receiptType,
      receiptActionType
    } = this.props;

    switch (receiptType) {
      case "merchant": {
        return history.push(
          `/main/settings/merchantReceipt/${receiptActionType}/id/${transactionId}/send`
        );
      }
      case "cardholder": {
        return history.push(
          `/main/cardholderReceipt/${receiptActionType}/id/${transactionId}/send`
        );
      }
      default: {
        break;
      }
    }
  };

  render() {
    const {
      showPopin,
      transactionId,
      popinContent,
      onClose,
      receiptType
    } = this.props;

    return (
      <ReceiptViewer
        showPopin={showPopin}
        transactionId={transactionId}
        popinContent={popinContent}
        onClose={onClose}
        onDownload={this._downloadReceipt}
        onSend={this._sendReceipt}
        i18nTitleKey={`reporting.transaction.${receiptType}Ticket.title`}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  downloadTransactionsReceipt: ({ receiptId, receiptType }) =>
    dispatch(downloadTransactionsReceipt({ receiptId, receiptType })),
  downloadReconciliationsReceipt: ({ receiptId, receiptType }) =>
    dispatch(downloadReconciliationReceipt({ receiptId, receiptType })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { receiptActionType } = ownProps;

  switch (receiptActionType) {
    case "transaction": {
      return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        downloadReceipt: ({ receiptId, receiptType }) =>
          dispatchProps.downloadTransactionsReceipt({ receiptId, receiptType })
      };
    }
    case "reconciliation": {
      return {
        ...ownProps,
        downloadReceipt: ({ receiptId }) =>
          dispatchProps.downloadReconciliationsReceipt({ receiptId })
      };
    }
    default: {
      break;
    }
  }
};

export default compose(
  withRouter,
  connect(null, mapDispatchToProps, mergeProps)
)(TransactionReceiptViewer);
