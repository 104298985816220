import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_VATRATES_ACTION,
  SUCCESS_GET_VATRATES_ACTION,
  FAILURE_GET_VATRATES_ACTION,
  PENDING_DELETE_VATRATE_ACTION,
  SUCCESS_DELETE_VATRATE_ACTION,
  FAILURE_DELETE_VATRATE_ACTION
} from "../actionsTypes";

export const getVatRates = merchantId => async dispatch => {
  dispatch(getVatRatesStart());

  try {
    const data = await F.get(`/merchant/${merchantId}/vatrate`);

    return dispatch(getVatRatesSucceeded(data));
  } catch (errors) {
    dispatch(getVatRatesFailure({ errors }));

    throw errors;
  }
};

export const getVatRatesStart = () => ({
  type: PENDING_GET_VATRATES_ACTION
});

export const getVatRatesSucceeded = vatRates => ({
  type: SUCCESS_GET_VATRATES_ACTION,
  payload: {
    vatRates
  }
});

export const getVatRatesFailure = errors => ({
  type: FAILURE_GET_VATRATES_ACTION,
  errors
});

export const deleteVatRate = (merchantId, vatRatName) => async dispatch => {
  dispatch(deleteVatRateStart());

  try {
    await F.delete(`/merchant/${merchantId}/vatrate/${vatRatName}`);

    return dispatch(deleteVatRateSucceeded(vatRatName));
  } catch (errors) {
    dispatch(deleteVatRateFailure({ errors }));
    throw errors;
  }
};

export const deleteVatRateStart = () => ({
  type: PENDING_DELETE_VATRATE_ACTION
});

export const deleteVatRateSucceeded = vatRatName => ({
  type: SUCCESS_DELETE_VATRATE_ACTION,
  payload: {
    vatRatName
  }
});

export const deleteVatRateFailure = errors => ({
  type: FAILURE_DELETE_VATRATE_ACTION,
  errors
});
