import {
  PENDING_GET_MENU_ACTION,
  SUCCESS_GET_MENU_ACTION,
  FAILURE_GET_MENU_ACTION,
  RESET_MENU_ACTION,
  COLLAPSE_MENU_ACTION,
  UN_COLLAPSE_MENU_ACTION
} from "../actionsTypes";

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {},
  collapsed: false
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_MENU_ACTION:
      return {
        ...state,
        data: initialState.data,
        loading: true,
        error: false,
        errors: {}
      };
    case SUCCESS_GET_MENU_ACTION: {
      const {
        payload: { data }
      } = action;
      return {
        ...state,
        data,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_MENU_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_MENU_ACTION: {
      return {
        ...state,
        ...initialState
      };
    }
    case COLLAPSE_MENU_ACTION:
    case UN_COLLAPSE_MENU_ACTION: {
      const {
        payload: { collapsed }
      } = action;
      return {
        ...state,
        collapsed
      };
    }
    default:
      return state;
  }
};
