import F from "../../commons/HTTPFetcher";
import { generateUrl } from "../../commons/utils/url";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_RECONCILIATIONS_ACTION,
  SUCCESS_GET_RECONCILIATIONS_ACTION,
  FAILURE_GET_RECONCILIATIONS_ACTION,
  RESET_RECONCILIATIONS_ACTION,
  PENDING_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION,
  SUCCESS_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION,
  FAILURE_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION
} from "../actionsTypes";

export const getReconciliations = ({
  filters,
  fields,
  sort = { field: "mshTransactionTime", order: "DESC" },
  tableCount: max,
  begin,
  end
}) => async dispatch => {
  dispatch(getReconciliationsStart());

  try {
    const url = new URL("/reconciliation/search", PORTAL_URL);

    Object.entries({ max, begin, end }).forEach(param => {
      const [paramKey, paramValue] = param;

      if (paramValue) {
        return url.searchParams.append(paramKey, paramValue);
      }
    });

    const { count, scrollId, searchResults: data } = await F.post(
      `${url.pathname}${url.search}`,
      {
        body: {
          filters,
          fields,
          sort
        },
        contentType: ContentTypes.JSON
      },
      "v2"
    );

    return dispatch(getReconciliationsSucceeded({ count, scrollId, data }));
  } catch (errors) {
    dispatch(getReconciliationsFailure({ errors }));

    throw errors;
  }
};

const getReconciliationsStart = () => ({
  type: PENDING_GET_RECONCILIATIONS_ACTION
});

const getReconciliationsSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_RECONCILIATIONS_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getReconciliationsFailure = ({ errors }) => ({
  type: FAILURE_GET_RECONCILIATIONS_ACTION,
  payload: {
    errors
  }
});

export const resetReconciliations = () => ({
  type: RESET_RECONCILIATIONS_ACTION
});

///////

export const getReconciliationReceipt = ({ id }) => async dispatch => {
  dispatch(getReconciliationReceiptStart());

  try {
    const receiptHtml = await F.get(
      `/reconciliation/${id}/merchantreceipt`,
      "v2"
    );

    dispatch(getReconciliationReceiptSucceeded({ receiptHtml }));
    dispatch(openTicket({ receiptHtml, id }));
  } catch (errors) {
    dispatch(getReconciliationReceiptFailure({ errors }));

    throw errors;
  }
};

const getReconciliationReceiptStart = () => {
  return {
    type: `PENDING_GET_RECONCILIATION_RECEIPT_ACTION`
  };
};

const getReconciliationReceiptSucceeded = ({ receiptHtml }) => {
  return {
    type: `SUCCESS_GET_RECONCILIATION_RECEIPT_ACTION`,
    payload: {
      receiptHtml
    }
  };
};

const getReconciliationReceiptFailure = ({ errors }) => {
  return {
    type: `FAILURE_GET_RECONCILIATION_RECEIPT_ACTION`,
    payload: {
      errors
    }
  };
};

///////

export const downloadReconciliationReceipt = ({
  receiptId
}) => async dispatch => {
  dispatch(downloadReconciliationReceiptStart());

  const url = generateUrl({
    url: `/reconciliation/${receiptId}/merchantreceipt/download`,
    version: "v2"
  });

  try {
    await F.download(url, `merchant-receipt-${receiptId}.pdf`);

    return dispatch(downloadReconciliationReceiptSucceeded());
  } catch (errors) {
    dispatch(downloadReconciliationReceiptFailure({ errors }));

    throw errors;
  }
};

const downloadReconciliationReceiptStart = () => ({
  type: PENDING_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION
});

const downloadReconciliationReceiptSucceeded = () => ({
  type: SUCCESS_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION
});

const downloadReconciliationReceiptFailure = ({ errors }) => ({
  type: FAILURE_DOWNLOAD_RECONCILIATION_RECEIPT_ACTION,
  payload: {
    errors
  }
});

//////

export const openTicket = ({ id }) => {
  return {
    type: `OPEN_MERCHANT_RECEIPT_ACTION`,
    payload: {
      id
    }
  };
};

export const closeTicket = () => {
  return {
    type: `CLOSE_MERCHANT_RECEIPT_ACTION`
  };
};
