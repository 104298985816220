import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import VerificationCodeInput from "./VerificationCodeMobileInput";

import styles from "../styles/styles.mobile.css";

class AuthenticateVerifyMobileView extends Component<Props, {}> {
  render() {
    const { t, otp, onInputChange } = this.props;

    return (
      <div className={styles["login-form-wrapper"]}>
        <VerificationCodeInput
          label={t("login.form.verificationCode.label")}
          onChange={onInputChange}
          value={otp}
        />

        <div className={styles["submit-container"]}>
          <input
            type="submit"
            className={styles.buttonlink}
            value={t("login.form.signIn")}
          />
        </div>
        <div className={`${styles["small-link"]} text-right `}>
          <Link to="/authenticate" className={styles.link}>
            {t("createToken.form.back")}
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AuthenticateVerifyMobileView);
