import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import Routes from "./routes";
import { numeralLanguages } from "../assets/languages/languages.json";
import "./apexcharts";
import "./i18n";
import "./service-worker";

import { store } from "./redux/store";

//Load all locales for numeral then render
(async () => {
  await Promise.all(
    numeralLanguages.map(lng => {
      const lang = lng === "en" ? "en-gb" : lng;

      return import(`numeral/locales/${lang}`);
    })
  );

  // loading component for suspense fallback
  const Loader = () => null;

  return render(
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <Routes />
      </Suspense>
    </Provider>,
    document.getElementById("root")
  );
})();
