/**
 * @secure
 */
import i18next from "i18next";

export const TransactionFieldService = {
  setOptionLabel(field) {
    const { label: fieldLabel, name, i18nKey } = field;
    const defautLabel = fieldLabel || name;
    const label = i18next.t(i18nKey, defautLabel);
    const placeholder = i18next.t(i18nKey, "");

    return {
      ...field,
      label,
      placeholder
    };
  }
};
