import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import { v4 as uuid4 } from "uuid";

import {
  PENDING_GET_TAXAUDITS_ACTION,
  SUCCESS_GET_TAXAUDITS_ACTION,
  FAILURE_GET_TAXAUDITS_ACTION,
  RESET_TAXAUDITS_ACTION
} from "../actionsTypes";

export const getTaxAudits = ({
  filters,
  timePeriod,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getTaxAuditsStart());
  const url = new URL(`/tax-audit/search`, PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }
  if (timePeriod) {
    url.searchParams.append("begin", timePeriod.startTime.valueOf());
    url.searchParams.append("end", timePeriod.endTime.valueOf());
  }
  return F.post(
    `${url.pathname}${url.search}`,
    {
      body: {
        filters,
        fields,
        sort
      },
      contentType: ContentTypes.JSON
    },
    "v2"
  )
    .then(({ count, scrollId, searchResults: data }) => {
      data.forEach(item => {
        const id = uuid4();
        item.report.id = id;
      });
      return dispatch(getTaxAuditsSucceeded({ count, scrollId, data }));
    })
    .catch(errors => {
      dispatch(getTaxAuditsFailure({ errors }));
      throw errors;
    });
};

export const getTaxAuditsStart = () => ({
  type: PENDING_GET_TAXAUDITS_ACTION
});

export const getTaxAuditsSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_TAXAUDITS_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

export const getTaxAuditsFailure = errors => ({
  type: FAILURE_GET_TAXAUDITS_ACTION,
  payload: {
    errors
  }
});

export const resetTaxAudits = () => ({
  type: RESET_TAXAUDITS_ACTION
});
