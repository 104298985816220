import _ from "lodash";
import numeral from "numeral";
import * as loglevel from "loglevel";

export default class NumberFormatter {
  private static BASE = 10;
  private static UNBREAKABLE_SPACE_CODE = 160;
  private static CURRENCIES_WITH_CODE_ALPHA3 = ["$", "F", "£"];

  static formatNumber(number: any, format?: string): string {
    return numeral(number).format(format);
  }

  static roundNumberSup(number: any, round: boolean) {
    return round ? Math.round(number) : number;
  }

  static convertToDecimal(currencyExponent: any) {
    return Math.pow(NumberFormatter.BASE, currencyExponent);
  }

  static formatAmount(
    number: number,
    currencyWithAlpha3: string | null,
    currencyExponent: number,
    round = false
  ): string {
    let format = "0,0";
    switch (true) {
      case !currencyWithAlpha3 && currencyExponent <= 0: {
        const roundNumber = NumberFormatter.roundNumberSup(number, round);
        const formattedNumber = NumberFormatter.formatNumber(
          roundNumber,
          format
        );
        return formattedNumber.toString();
      }
      case !currencyWithAlpha3 && currencyExponent > 0: {
        const roundNumber = NumberFormatter.roundNumberSup(number, round);
        const converted =
          roundNumber / NumberFormatter.convertToDecimal(currencyExponent);
        return converted.toString();
      }
      case currencyWithAlpha3 && currencyExponent > 0:
        const paddedZero = _.times(currencyExponent, () => "0").join("");
        format += `.${paddedZero}`;
        break;
      default:
        break;
    }

    const converted =
      number / NumberFormatter.convertToDecimal(currencyExponent);

    const roundNumber = NumberFormatter.roundNumberSup(converted, round);

    const currencyFormatted = currencyWithAlpha3
      ? NumberFormatter.formatCurrency(currencyWithAlpha3)
      : ("" as any);
    return (
      currencyFormatted +
      String.fromCharCode(NumberFormatter.UNBREAKABLE_SPACE_CODE) +
      NumberFormatter.formatNumber(roundNumber, format)
    );
  }

  static unformatAmount(value: string, currencyExponent: number): number {
    if (!value) {
      return 0;
    }

    if (!currencyExponent) {
      loglevel.error(
        "Decimal not available. The currency computation should be wrong"
      );
      return parseFloat(value);
    } else {
      const formattedValue = value.replace(/,/g, ".");
      return Math.round(
        parseFloat(formattedValue) *
          Math.pow(NumberFormatter.BASE, currencyExponent)
      );
    }
  }

  static formatCurrency(currencyWithAlpha3: string) {
    const SYMBOL_INDEX = 3;
    return this.CURRENCIES_WITH_CODE_ALPHA3.some(
      item => item === currencyWithAlpha3[SYMBOL_INDEX]
    )
      ? currencyWithAlpha3.slice(0, SYMBOL_INDEX)
      : currencyWithAlpha3.slice(SYMBOL_INDEX, currencyWithAlpha3.length);
  }
}
