import TimePeriods from "../../reporting/models/TimePeriod";

import F from "../../commons/HTTPFetcher";
import { getTransactionsAgg } from "../../redux/actions/widgetData";

import DateFormatter from "../../formatters/DateFormatter";

export const getObjective = async () => {
  try {
    const response = await F.get("/me/objective");

    return response;
  } catch (error) {
    const errors = await error;

    if (
      errors instanceof Object &&
      errors.errorKey === "objectives.not.found"
    ) {
      return;
    }

    throw errors;
  }
};

export const getObjectivesRevenue = async ({ user, filters = [] }: any) => {
  const Timestamps = () => {
    return {
      month: {
        min: DateFormatter.getTimezonedDate(
          TimePeriods.MONTH_TO_DATE.startTime,
          user
        ),
        max: DateFormatter.getTimezonedDate(
          TimePeriods.MONTH_TO_DATE.endTime,
          user
        )
      },
      week: {
        min: DateFormatter.getTimezonedDate(
          TimePeriods.WEEK_TO_DATE.startTime,
          user
        ),
        max: DateFormatter.getTimezonedDate(
          TimePeriods.WEEK_TO_DATE.endTime,
          user
        )
      },
      day: {
        min: DateFormatter.getTimezonedDate(
          TimePeriods.INTRADAY.startTime,
          user
        ),
        max: DateFormatter.getTimezonedDate(TimePeriods.INTRADAY.endTime, user)
      }
    };
  };

  const timestamps = Timestamps();

  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  const [
    { sum_amount: { value: daily } = { value: 0 } },
    { sum_amount: { value: weekly } = { value: 0 } },
    { sum_amount: { value: monthly } = { value: 0 } }
  ] = await Promise.all([
    getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "sum_amount",
        type: "sum",
        field: "transactionAmount"
      },
      begin: timestamps.day.min,
      end: timestamps.day.max
    }),
    getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "sum_amount",
        type: "sum",
        field: "transactionAmount"
      },
      begin: timestamps.week.min,
      end: timestamps.week.max
    }),
    getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "sum_amount",
        type: "sum",
        field: "transactionAmount"
      },
      begin: timestamps.month.min,
      end: timestamps.month.max
    })
  ]);

  return {
    monthly,
    weekly,
    daily
  };
};
