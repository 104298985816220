import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import AlertAdapter from "../../alerts/models/Alert";
import {
  PENDING_UPDATE_STATUS_TRANSACTION_ALERT_ACTION,
  SUCCESS_UPDATE_STATUS_TRANSACTION_ALERT_ACTION,
  FAILURE_UPDATE_STATUS_TRANSACTION_ALERT_ACTION,
  FAILURE_CREATE_TRANSACTION_ALERT_ACTION,
  PENDING_CREATE_TRANSACTION_ALERT_ACTION,
  SUCCESS_CREATE_TRANSACTION_ALERT_ACTION,
  PENDING_GET_TRANSACTION_ALERT_ACTION,
  SUCCESS_GET_TRANSACTION_ALERT_ACTION,
  FAILURE_GET_TRANSACTION_ALERT_ACTION,
  FAILURE_UPDATE_TRANSACTION_ALERT_ACTION,
  PENDING_UPDATE_TRANSACTION_ALERT_ACTION,
  SUCCESS_UPDATE_TRANSACTION_ALERT_ACTION,
  RESET_TRANSACTION_ALERT_STATE_ACTION
} from "../actionsTypes";

export const updateTransactionAlertStatus = ({
  id,
  status
}) => async dispatch => {
  dispatch(updateTransactionAlertStatusStart());

  return F.put(`/alert/${id}/active/${status}`).then(
    () => dispatch(updateTransactionAlertStatusSucceeded({ id, status })),
    errors => {
      dispatch(updateTransactionAlertStatusFailure({ errors }));

      throw errors;
    }
  );
};

export const updateTransactionAlertStatusStart = () => ({
  type: PENDING_UPDATE_STATUS_TRANSACTION_ALERT_ACTION
});

export const updateTransactionAlertStatusSucceeded = ({ id, status }) => ({
  type: SUCCESS_UPDATE_STATUS_TRANSACTION_ALERT_ACTION,
  payload: {
    id,
    status
  }
});

export const updateTransactionAlertStatusFailure = ({ errors }) => ({
  type: FAILURE_UPDATE_STATUS_TRANSACTION_ALERT_ACTION,
  payload: {
    errors
  }
});

export const createTransactionAlert = ({ alert, isCustomer }) => dispatch => {
  dispatch(createTransactionAlertStart());

  const transactionAlert = AlertAdapter.buildFromForm(alert, isCustomer);
  return F.post("/alert", {
    body: transactionAlert,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(createTransactionAlertSucceeded()),
    errors => {
      dispatch(createTransactionAlertFailure({ errors }));

      throw errors;
    }
  );
};

const createTransactionAlertStart = () => ({
  type: PENDING_CREATE_TRANSACTION_ALERT_ACTION
});

const createTransactionAlertSucceeded = () => ({
  type: SUCCESS_CREATE_TRANSACTION_ALERT_ACTION
});

const createTransactionAlertFailure = (errors: any) => ({
  type: FAILURE_CREATE_TRANSACTION_ALERT_ACTION,
  payload: {
    errors
  }
});

export const editTransactionAlert = ({ id, alert, isCustomer }) => dispatch => {
  dispatch(editTransactionAlertStart());

  const transactionAlert = AlertAdapter.buildFromForm(alert, isCustomer);
  return F.put(`/alert/${id}`, {
    body: transactionAlert,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(editTransactionAlertSucceeded()),
    errors => {
      dispatch(editTransactionAlertFailure({ errors }));

      throw errors;
    }
  );
};

const editTransactionAlertStart = () => ({
  type: PENDING_UPDATE_TRANSACTION_ALERT_ACTION
});

const editTransactionAlertSucceeded = () => ({
  type: SUCCESS_UPDATE_TRANSACTION_ALERT_ACTION
});

const editTransactionAlertFailure = errors => ({
  type: FAILURE_UPDATE_TRANSACTION_ALERT_ACTION,
  payload: {
    errors
  }
});

export const getTransactionAlert = ({ id }) => dispatch => {
  dispatch(getTransactionAlertStart());

  return F.get(`/alert/${id}`).then(
    data => {
      const transactionAlert = AlertAdapter.wrapFromServer(data).toAlertForm();
      dispatch(getTransactionAlertSucceeded(transactionAlert));
      return transactionAlert;
    },
    errors => {
      dispatch(getTransactionAlertFailure(errors));

      throw errors;
    }
  );
};

const getTransactionAlertStart = () => ({
  type: PENDING_GET_TRANSACTION_ALERT_ACTION
});

const getTransactionAlertSucceeded = transactionAlert => ({
  type: SUCCESS_GET_TRANSACTION_ALERT_ACTION,
  payload: {
    transactionAlert
  }
});

const getTransactionAlertFailure = errors => ({
  type: FAILURE_GET_TRANSACTION_ALERT_ACTION,
  payload: {
    errors
  }
});

export const resetTransactionAlertState = () => ({
  type: RESET_TRANSACTION_ALERT_STATE_ACTION
});
