import * as _ from "lodash";

const wordsLimit = 2;
const notFound = -1;

const patternValidator = (pattern: String, errorCode?: String) => value => {
  if (!errorCode) {
    errorCode = "pattern";
  }
  let code = null;
  if (value && !_.isNumber(value) && !value.match(pattern)) {
    code = { code: `form.error.${errorCode}`, args: [value, pattern] };
  }
  return code;
};

export default {
  notDotValidator: (value: string, code = "form.error.dot") => {
    if (value && !value.match(/^[^.]*$/)) {
      return { code, args: [value, "."] };
    }
    return null;
  },

  minValidator: min => value => {
    let code = null;
    if (min !== undefined && value && min > parseInt(value)) {
      code = { code: "form.error.min", args: [value, min] };
    }
    return code;
  },

  maxValidator: max => value => {
    let code = null;
    if (max !== undefined && value && max < parseInt(value)) {
      code = { code: "form.error.max", args: [value, max] };
    }
    return code;
  },

  numberValidator: value => {
    let code = null;
    if (value && !_.isNumber(value) && !value.match(/^-?\d+$/)) {
      code = { code: "form.error.number", args: [value] };
    }
    return code;
  },

  isFloatValidator: value => {
    let code = null;
    if (value && !value.match(/^\d+(\.\d+)?$/)) {
      code = { code: "form.error.number", args: [value] };
    }
    return code;
  },

  asciiAndNumberValidator: (
    value: string,
    code = "form.error.asciiCharacters"
  ) => {
    if (value && !value.match(/^[a-zA-Z0-9]+$/)) {
      return { code, args: [value] };
    }
    return null;
  },

  asciiUppercaseAndNumberValidator: (
    value: string,
    code = "form.error.asciiCharacters"
  ) => {
    if (value && !value.match(/^[A-Z0-9]+$/)) {
      return { code, args: [value] };
    }
    return null;
  },

  asciiUpperAndLowerAndNumberValidator: (
    value: string,
    code = "form.error.asciiCharacters"
  ) => {
    if (value && !value.match(/^[A-Za-z0-9]+$/)) {
      return { code, args: [value] };
    }
    return null;
  },

  patternValidator: (pattern: string, errorCode?: string) =>
    patternValidator(pattern, errorCode),

  containsCharValidator: (value: string, char: string) => {
    let code = null;
    if (value && value.indexOf(char) !== notFound) {
      code = { code: `form.error.forbiddenChar`, args: [char] };
    }
    return code;
  },

  emailValidator: value =>
    patternValidator(
      "[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\\.[a-zA-Z]{2,4}",
      "email"
    )(value),

  urlValidator: value =>
    patternValidator(
      "(https)://((\\w)*|([0-9]*)|([-|_])*)+([\\.|/]((\\w)*|([0-9]*)|([-|_])*))+",
      "url"
    )(value),

  requiredValidator: (value, code = "form.error.required") => {
    if (!value || (value instanceof Array && _.isEmpty(value))) {
      return { code, args: [value] };
    }
    return null;
  },

  sizeOfStringValidator: (min, field) => value => {
    let code = null;
    if (min !== undefined && value && min > parseInt(value.length)) {
      code = { code: "form.error.minSize", args: [field, min] };
    }
    return code;
  },

  maxStringValidator: (max, field) => value => {
    let code = null;
    if (max !== undefined && value && max < parseInt(value.length)) {
      code = { code: "form.error.maxSize", args: [field, max] };
    }
    return code;
  },

  hasSpaceChar(value) {
    if (value === null || typeof value === "undefined" || value === "") {
      return false;
    } else {
      return (
        _.trim(value) !== value ||
        _.words(value, /[^\s]+/g).length >= wordsLimit
      );
    }
  },

  jsonValidator: value => {
    if (!value) {
      return null;
    }
    try {
      JSON.parse(value);
      return null;
    } catch {
      return { code: "form.error.format.json", args: [] };
    }
  }
};
