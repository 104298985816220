import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_POSS_LIST_ACTION,
  SUCCESS_GET_POSS_LIST_ACTION,
  FAILURE_GET_POSS_LIST_ACTION,
  PENDING_GET_POSS_ACTION,
  SUCCESS_GET_POSS_ACTION,
  FAILURE_GET_POSS_ACTION,
  RESET_POSS_ACTION,
  PENDING_DELETE_POSS_ACTION,
  FAILURE_DELETE_POSS_ACTION,
  PENDING_UPDATE_STATUS_POSS_ACTION,
  FAILURE_UPDATE_STATUS_POSS_ACTION,
  DISPLAY_POSS_QR_CODES_ACTION,
  HIDE_POSS_QR_CODES_ACTION,
  CLOSE_POSS_QR_CODE_ACTION
} from "../actionsTypes";

export const getPossList = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getPossListStart());

  const url = new URL("/pos/search", PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    ({ count, scrollId, searchResults: data }) => {
      dispatch(getPossListSucceeded({ count, scrollId, data }));

      return data;
    },
    errors => {
      dispatch(getPossListFailure({ errors }));

      throw errors;
    }
  );
};

const getPossListStart = () => ({
  type: PENDING_GET_POSS_LIST_ACTION
});

const getPossListSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_POSS_LIST_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getPossListFailure = ({ errors }) => ({
  type: FAILURE_GET_POSS_LIST_ACTION,
  payload: {
    errors
  }
});

export const getPoss = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getPossStart());

  const url = new URL("/pos/search", PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    ({ searchResults: data }) => {
      dispatch(getPossSucceeded({ data }));

      return data;
    },
    errors => {
      dispatch(getPossFailure({ errors }));

      throw errors;
    }
  );
};

const getPossStart = () => ({
  type: PENDING_GET_POSS_ACTION
});

const getPossSucceeded = ({ data }) => ({
  type: SUCCESS_GET_POSS_ACTION,
  payload: {
    data
  }
});

const getPossFailure = ({ errors }) => ({
  type: FAILURE_GET_POSS_ACTION,
  payload: {
    errors
  }
});

export const resetPoss = () => ({
  type: RESET_POSS_ACTION
});

//////

export const deletePoss = ({ ids }) => async dispatch => {
  dispatch(deletePossStart());

  try {
    return await F.delete("/pos/batch", {
      body: ids,
      contentType: ContentTypes.JSON
    });
  } catch (errors) {
    dispatch(deletePossFailure({ errors }));

    throw errors;
  }
};

const deletePossStart = () => ({
  type: PENDING_DELETE_POSS_ACTION
});

const deletePossFailure = ({ errors }) => ({
  type: FAILURE_DELETE_POSS_ACTION,
  payload: {
    errors
  }
});

//////

export const updatePossStatus = ({ ids, status }) => async dispatch => {
  dispatch(updatePossStatusStart());

  try {
    return await F.put(`/pos/batch/status/${status}`, {
      body: ids,
      contentType: ContentTypes.JSON
    });
  } catch (errors) {
    dispatch(updatePossStatusFailure({ errors }));

    throw errors;
  }
};

const updatePossStatusStart = () => ({
  type: PENDING_UPDATE_STATUS_POSS_ACTION
});

const updatePossStatusFailure = ({ errors }) => ({
  type: FAILURE_UPDATE_STATUS_POSS_ACTION,
  payload: {
    errors
  }
});

//////

export const openQrCodesSelectionPopin = () => {
  return {
    type: DISPLAY_POSS_QR_CODES_ACTION
  };
};

export const closeQrCodesSelectionPopin = () => {
  return {
    type: HIDE_POSS_QR_CODES_ACTION
  };
};

export const closeQrCode = posId => {
  return {
    type: CLOSE_POSS_QR_CODE_ACTION,
    payload: {
      id: posId
    }
  };
};
