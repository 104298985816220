import React, { Component } from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";

import { changeLang } from "../../i18n";

import {
  impersonate,
  getUserSettingsFetcher,
  getLevelSettingsFetcher,
  getMenu,
  addNotificationSuccess,
  addNotificationError,
  getColor,
  getLogo,
  getServices
} from "../../redux/actions";

interface Props {
  getServices: Function;
}

interface State {}

class LoginAs extends Component<Props, State> {
  state = {
    form: {
      login: ""
    },
    errors: {}
  };

  componentWillReceiveProps(nextProps) {
    const { form, errors } = nextProps;

    return this.setState({
      form,
      errors
    });
  }

  _goToList = e => {
    e.preventDefault();

    const { history } = this.props;

    history.push("/main/customer");
  };

  _loginBtnHandler = async e => {
    e.preventDefault();

    const {
      impersonate,
      history,
      getUserSettings,
      getLevelSettings,
      getMenu,
      addNotificationSuccess,
      addNotificationError,
      getColor,
      getLogo,
      getServices,
      i18n
    } = this.props;
    const {
      form: { login }
    } = this.state;

    try {
      await impersonate(login);
      const { language } = await getUserSettings();
      await getLevelSettings();

      changeLang(language);

      await Promise.all([getColor(), getLogo(), getMenu(), getServices()]);

      addNotificationSuccess("monitoring.connect-as.form.success");

      history.push("/main");
    } catch {
      addNotificationError("notification.error");
    }
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm);
  };

  render() {
    const {
      form: { login }
    } = this.state;

    return (
      <div>
        <FormTitle
          titleKey="monitoring.title"
          actionKey="monitoring.connect-as.header.title"
        />

        <form className="ingenico-form form-horizontal">
          <BootstrapInput
            onChange={this._onChangeHandler}
            name="login"
            ref="login"
            descriptor={{
              type: "text",
              label: "monitoring.connect-as.form.login"
            }}
            formValue={login}
          />

          <div className="pull-right">
            <button
              onClick={this._loginBtnHandler}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.login"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export const LoginAsWithRouter = withRouter(LoginAs);

const mapStateToProps = () => {
  return {
    form: {
      login: ""
    },
    errors: {}
  };
};

const mapDispatchToProps = dispatch => ({
  impersonate: userId => dispatch(impersonate(userId)),
  getUserSettings: () => dispatch(getUserSettingsFetcher()),
  getLevelSettings: () => dispatch(getLevelSettingsFetcher()),
  getMenu: () => dispatch(getMenu()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getColor: () => dispatch(getColor()),
  getLogo: () => dispatch(getLogo()),
  getServices: () => dispatch(getServices())
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(LoginAsWithRouter);
