import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import classNames from "classnames";
import * as moment from "moment";

import { WidgetConfig } from "../../home/components/Widget";
import NumberFormatter from "../../formatters/NumberFormatter";
import AmountApexChart from "../../chart/components/chart/AmountApexChart";

import { getData } from "../../home/utils/DataProvider";

import styles from "./RevenuesChartsMobile.css";
import LoadingChart from "../../home/components/widgets/LoadingChart";
import {
  MatchPreviousPeriod,
  PeriodSelection
} from "../../reporting/models/TimePeriod";

interface Data {
  stats?: any;
  currency: any;
  currencyDecimal: any;
}

interface Props {
  getData: Function;
  data: Data;
  loading: boolean;
  error?: boolean;
  color: string;
  config: WidgetConfig;
  user: any;
  customerSettings: any;
}

class RevenuesChartsMobile extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;

    getData({ config });
  }

  _buildConfigFromProps({
    timePeriod,
    currencyDecimal,
    prevTransactions,
    transactions,
    colors,
    prefersDarkMode
  }) {
    const { customerSettings, t } = this.props;
    const isExistingCurrency = customerSettings && customerSettings.currency;
    const currency = isExistingCurrency
      ? customerSettings.currency.alpha3 + customerSettings.currency.symbol
      : "?";

    const timePeriodType = MatchPreviousPeriod({ timePeriod });

    return {
      chart: {
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: true
        }
      },
      grid: {
        padding: {
          left: 0,
          right: 0
        }
      },
      title: {
        text: ""
      },
      fill: {
        type: "solid",
        opacity: 0.2,
        colors: [prefersDarkMode ? "#303030" : "#ffffff", "#E54B4B"]
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      dataLabels: {
        enabled: false
      },

      stroke: {
        colors: ["#E54B4B", "#E54B4B"],
        width: [2, 0],
        dashArray: [1, 0]
      },

      markers: {
        ...colors.markers,
        size: 0,
        hover: {
          size: 6,
          sizeOffset: 3
        }
      },

      xaxis: {
        type: "datetime",
        floating: true,
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        floating: true,
        axisBorder: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(value) {
            return NumberFormatter.formatAmount(
              value,
              currency,
              currencyDecimal
            );
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        custom: function(opts) {
          const {
            series,
            seriesIndex,
            dataPointIndex,
            seriesName,
            w,
            ctx: {
              w: {
                config: { series: seriesValues }
              }
            }
          } = opts;
          const [timestamp] = seriesValues[seriesIndex].data[dataPointIndex];

          const time = colors.tooltip.translate(t, moment(timestamp));
          const value = series[seriesIndex][dataPointIndex];
          const formatted = NumberFormatter.formatAmount(
            value,
            currency,
            currencyDecimal
          );
          return `<div class="${styles.tooltip}">
                      <span class="${styles["tooltip-time"]}">${time}</span>
                      <div class="${styles["tooltip-divider"]}"></div>
                     <span>${formatted}</span>
                      </div>`;
        },
        marker: {
          show: false
        },
        x: {
          show: false
        }
      },
      series: [
        {
          type: "area",
          name: t(`timeSelector.${timePeriodType}`),
          data: prevTransactions
        },
        {
          type: "area",
          name: t("widget.type.revenue"),
          data: transactions
        }
      ],
      ...colors.curve
    };
  }

  render() {
    const {
      data: { previous = {}, current = {} } = { previous: {}, current: {} },
      color,
      loading,
      config,
      t,
      prefersDarkMode
    } = this.props;
    const { timePeriod, secondTimePeriod } = config;

    const colorsByPeriod = {
      daily: {
        curve: {
          fill: {
            type: "solid",
            opacity: 0.3,
            colors: [prefersDarkMode ? "#303030" : "#ffffff", "#FACA0C"]
          },
          stroke: {
            colors: ["#FACA0C", "#FACA0C"],
            width: [2, 0],
            dashArray: [2, 0]
          }
        },
        markers: {
          colors: ["#FACA0C"]
        },
        tooltip: {
          format: "LT A",
          translate: (t, value) => {
            return value.format(colorsByPeriod.daily.tooltip.format);
          }
        }
      },
      weekly: {
        curve: {
          fill: {
            type: "solid",
            opacity: 0.2,
            colors: [prefersDarkMode ? "#303030" : "#ffffff", "#E54B4B"]
          },
          stroke: {
            colors: ["#E54B4B", "#E54B4B"],
            width: [2, 0],
            dashArray: [2, 0]
          }
        },
        markers: {
          colors: ["#E54B4B"]
        },
        tooltip: {
          format: "dddd",
          translate: (t, value) => {
            const format = value
              .locale("en")
              .format(colorsByPeriod.weekly.tooltip.format)
              .toUpperCase();
            return t(`scheduled.closingWeekDay.${format}`);
          }
        }
      },
      monthly: {
        curve: {
          fill: {
            type: "solid",
            opacity: 0.2,
            colors: [prefersDarkMode ? "#303030" : "#ffffff", "#00C3D7"]
          },
          stroke: {
            colors: ["#00C3D7", "#00C3D7"],
            width: [2, 0],
            dashArray: [2, 0]
          }
        },
        markers: {
          colors: ["#00C3D7"]
        },
        tooltip: {
          format: "dddd MMMM",
          translate: (t, value) => {
            return value;
          }
        }
      }
    };

    const {
      stats: dataPreviousPeriod = [],
      currencyDecimal
    } = previous as Data;

    const { stats: dataCurrentPeriod = [] } = current as Data;

    const transactionsPreviousPeriod = dataPreviousPeriod.map(point => [
      point.timestamp,
      point.value
    ]);

    const transactionsCurrentPeriod = dataCurrentPeriod.map(point => {
      const OverlapCurve = () => {
        switch (timePeriod.type) {
          case PeriodSelection.INTRADAY: {
            return moment(point.timestamp)
              .subtract(1, "days")
              .valueOf();
          }
          case PeriodSelection.WEEK_TO_DATE: {
            return moment(point.timestamp)
              .subtract(1, "weeks")
              .valueOf();
          }
          case PeriodSelection.MONTH_TO_DATE: {
            return moment(point.timestamp)
              .subtract(1, "months")
              .valueOf();
          }
          default:
            break;
        }
      };

      const timestamp = OverlapCurve();
      return [timestamp, point.value];
    });

    const timePeriodType = MatchPreviousPeriod({ timePeriod });

    const matchColors = () => {
      switch (timePeriod.type) {
        case PeriodSelection.INTRADAY: {
          return colorsByPeriod.daily;
        }
        case PeriodSelection.WEEK_TO_DATE: {
          return colorsByPeriod.weekly;
        }
        case PeriodSelection.MONTH_TO_DATE: {
          return colorsByPeriod.monthly;
        }
        default:
          break;
      }
    };

    const colors = matchColors();

    const { series: series, ...options } = this._buildConfigFromProps({
      timePeriod,
      prevTimePeriod: secondTimePeriod,
      currencyDecimal,
      prevTransactions: transactionsPreviousPeriod,
      transactions: transactionsCurrentPeriod,
      color,
      colors,
      prefersDarkMode
    });

    const legendColors = {
      [PeriodSelection.INTRADAY]: styles.day,
      [PeriodSelection.WEEK_TO_DATE]: styles.week,
      [PeriodSelection.MONTH_TO_DATE]: styles.month
    };

    return (
      <div
        className={classNames(styles["widget-container"], {
          [styles["widget-container--dark"]]: prefersDarkMode,
          [styles["widget-container--light"]]: !prefersDarkMode
        })}
      >
        <div className={styles["legend"]}>
          <div
            className={classNames(styles["type"], {
              [styles["type--dark"]]: prefersDarkMode,
              [styles["type--light"]]: !prefersDarkMode
            })}
          >
            {t("widget.type.revenue")}
          </div>
          <div
            className={classNames(styles["dashed-curve"], {
              [styles["dashed-curve--dark"]]: prefersDarkMode,
              [styles["dashed-curve--light"]]: !prefersDarkMode
            })}
          >
            <div
              className={classNames(
                styles.period,
                legendColors[timePeriod.type],
                {
                  [styles["period--dark"]]: prefersDarkMode,
                  [styles["period--light"]]: !prefersDarkMode
                }
              )}
            >
              {t(`timeSelector.${timePeriodType}`)}
            </div>
          </div>
        </div>
        <div className={styles["widget"]}>
          <LoadingChart loading={loading} color={color}>
            <AmountApexChart options={options} series={series} type="area" />
          </LoadingChart>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, levelSettings: customerSettings },
    theme: {
      color: {
        data: { color }
      },
      darkMode: prefersDarkMode
    }
  } = state;
  const { config = {} } = ownProps;
  const { type } = config;
  const {
    widgets: { [type]: widget }
  } = state.widgetData;

  const widgetProps = {
    enableTrend: false,
    color
  };

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      customerSettings,
      data: stats,
      loading,
      error,
      prefersDarkMode,
      ...widgetProps
    };
  }

  return {
    user,
    customerSettings,
    data: {},
    loading: true,
    prefersDarkMode,
    ...widgetProps
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(RevenuesChartsMobile);
