import React, { Component } from "react";
import { connect } from "react-redux";

import KeyIndicator, { Loading } from "./KeyIndicator";
import { WidgetConfig } from "../Widget";
import { getData } from "../../utils/DataProvider";
import I18nNumber from "../../../i18n/components/I18nNumber";

import styles from "./style/TerminalVolumesBanner.css";

interface Data {
  volumes: Array<any>;
}

interface Props {
  getData: Function;
  data: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  id: string;
  config: WidgetConfig;
}

class TerminalVolumesBanner extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ config });
    }
  }

  render() {
    const {
      data: { volumes = [] },
      color,
      loading,
      error
    } = this.props;

    return (
      <div className={styles["terminal-volumes-banner"]}>
        <Loading loading={loading} color={color}>
          <div className={styles.container}>
            {volumes.map(aggregation => {
              const {
                key: contract,
                doc_count: count,
                pois: { value: poisValue }
              } = aggregation;
              const total = poisValue * contract;

              return (
                <div key={contract}>
                  <KeyIndicator
                    color={color}
                    titleKey={`poi.contract.${contract}`}
                    loading={loading}
                    error={error}
                  >
                    <I18nNumber number={count} format="0,0" />
                    <span> / </span>
                    <I18nNumber number={total} format="0,0" />
                  </KeyIndicator>
                </div>
              );
            })}
          </div>
        </Loading>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }
  return {
    data: {},
    loading: true
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TerminalVolumesBanner);
