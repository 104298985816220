import React, { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import TransactionTimeSelector from "./TransactionTimeSelector";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import TransactionAdvancedSearch from "../transactions/components/TransactionAdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import I18nSpan from "../../i18n/components/I18nSpan";
import TransactionConstants from "../constants/TransactionConstants";

import Counter from "../../datatable/components/Counter";

import styles from "./styles/TransactionComparison.css";
import {
  getFields,
  getComparaisonWidgets,
  resetWidgetsState,
  resetWidgetsStatsState,
  getTransactionCountComparaison,
  setSearchContext
} from "../../redux/actions";
import DateFormatter from "../../formatters/DateFormatter";
import { WidgetConfig } from "../../home/components/Widget";
import WidgetView from "../../home/components/Widget";
import { Refresh } from "../../datatable/components/Refresh";
import { WidgetType } from "../../home/models/WidgetType";
import { setUrlWithParams } from "../../searchContext/URLizer";

// @vulnerabilities XSS CSRF

interface Props {
  getFields: Function;
  getComparaisonWidgets: Function;
  periodSelection: any;
  criteriaOptions: any;
  color: string;
  widgets: any;
  resetWidgetsState: Function;
  transactionsCounter: any;
  resetWidgetsStatsState: Function;
  user: any;
  form: any;
  initForm: Function;
  timePeriod: any;
  getTransactionCountComparison: Function;
}

interface State {}

const COUNTER_KEY = "transactionCounter";

const advancedSearchKey = AdvancedSearchConstants.TRANSACTION_KEY;

class TransactionComparisonView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      form: {
        name: "",
        criteria: null,
        timePeriod: null,
        timestamps: {}
      }
    };
    this.setUrlParams({ searchContext });
  }

  async componentDidMount() {
    const { getFields } = this.props;
    const name = "transaction";
    const version = "v2";

    await getFields({ name, version });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  async componentWillUnmount() {
    const { resetWidgetsState, resetWidgetsStatsState } = this.props;

    await resetWidgetsState();
    await resetWidgetsStatsState();
  }

  _onCriteriaChange = (inputName: string, criteria: string) => {
    const {
      searchContext: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        timePeriod = {}
      }
    } = this.props;

    this.setState(
      state => {
        return {
          form: {
            ...state.form,
            criteria
          }
        };
      },
      () =>
        this._search({
          searchContext: { key: advancedSearchKey, filters, timePeriod }
        })
    );
  };

  _mapTimestamps({ timePeriod }) {
    const { user } = this.props;

    const min = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const max = DateFormatter.getTimezonedDate(timePeriod.endTime, user);
    return {
      min,
      max
    };
  }

  _onRefresh = async ({ searchContext }) => {
    const {
      getComparaisonWidgets,
      resetWidgetsState,
      resetWidgetsStatsState,
      getTransactionCountComparison
    } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      timePeriod
    } = searchContext;

    const {
      form: { criteria }
    } = this.state;
    if (criteria === null) {
      await resetWidgetsState();
      return resetWidgetsStatsState();
    }

    const timestamps = this._mapTimestamps({ timePeriod });

    await resetWidgetsState();
    await resetWidgetsStatsState();
    await getComparaisonWidgets({
      filters,
      periodSelection: timePeriod,
      criteria
    });
    return getTransactionCountComparison({
      config: {
        widgetType: COUNTER_KEY,
        widgetId: COUNTER_KEY,
        filters,
        timestamps,
        alias: criteria
      }
    });
  };

  _search = async ({ searchContext }) => {
    const {
      getComparaisonWidgets,
      resetWidgetsState,
      resetWidgetsStatsState,
      getTransactionCountComparison,
      setSearchContext,
      location: { pathname }
    } = this.props;

    const { timePeriod, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname });

    const {
      form: { criteria }
    } = this.state;
    if (criteria === null) {
      await resetWidgetsState();
      return resetWidgetsStatsState();
    }

    const timestamps = this._mapTimestamps({ timePeriod });

    await resetWidgetsState();
    await resetWidgetsStatsState();
    await getComparaisonWidgets({
      filters,
      periodSelection: timePeriod,
      criteria
    });
    return getTransactionCountComparison({
      config: {
        widgetType: COUNTER_KEY,
        widgetId: COUNTER_KEY,
        filters,
        timestamps,
        alias: criteria
      }
    });
  };

  render() {
    const {
      criteriaOptions,
      transactionsCounter = {},
      color,
      widgets,
      user,
      loading,
      error,
      searchContext
    } = this.props;

    const {
      data: { transactionCount = 0 },
      loading: loadingCounter
    } = transactionsCounter;

    const { form } = this.state;

    const widgetsSize = {
      [WidgetType.ComparisonAverageBasket]: styles["col-full"]
    };

    const CounterContainer = ({ loading, count }) => {
      if (loading || count) {
        return (
          <Counter
            loading={loading}
            count={count}
            loadingKey="reporting.transaction.loading"
          />
        );
      }
      return null;
    };

    return (
      <div className="reporting reporting-cards">
        <div className={styles["title-wrapper"]}>
          <FormTitle
            titleKey="reporting.mainTitle"
            actionKey="reporting.comparison.mainTitle"
          />
          <div className={styles["countAndRefresh-container"]}>
            <CounterContainer
              loading={loadingCounter}
              count={transactionCount}
            />
            <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
          </div>
        </div>

        <div className="flex-box">
          <TransactionTimeSelector
            onChange={({ searchContext: nextSearchContext }) =>
              this._search({ searchContext: nextSearchContext })
            }
            searchContext={searchContext}
          />
        </div>

        <TransactionAdvancedSearch
          onChange={this._search}
          searchContext={searchContext}
        />

        <div className="criteria-selection">
          <BootstrapInput
            ref="criteria"
            name="criteria"
            onChange={this._onCriteriaChange}
            descriptor={{
              type: "singleautocomplete",
              label: "reporting.comparison.criteria",
              labelClass: `control-label col-sm-1 ${styles.criteria}`,
              inputClass: "col-sm-4",
              options: criteriaOptions
            }}
            formValue={form.criteria}
          />
        </div>

        <WidgetRender
          loading={loading}
          form={form}
          render={() => (
            <div
              key={`group-widget`}
              className={classNames(styles.wrapper, "group-widget")}
              ref={container => (this.widgetContainer = container)}
            >
              {widgets.map((config: WidgetConfig) => {
                const { id, type } = config;

                const { [type]: size } = widgetsSize;

                return (
                  <WidgetView
                    className={classNames(styles.widget)}
                    enableTrend={true}
                    color={color}
                    user={user}
                    key={id}
                    config={{
                      ...{
                        ...config,
                        criteria: form.criteria,
                        desktopView: true
                      }
                    }}
                    header={false}
                    size={size}
                  />
                );
              })}
            </div>
          )}
        />
      </div>
    );
  }
}

const WidgetRender = ({ loading, form, render }) => {
  const { criteria = null } = form;
  if (loading || criteria === null) {
    return (
      <div className="row text-center no-criteria">
        <I18nSpan msgKey="reporting.comparison.selectCriteria" />
      </div>
    );
  }
  return render();
};

const mapCriteriaOptions = ({ fields, $t }) => {
  return fields.reduce((prevField, field) => {
    const { key: fieldKey, visible, extras } = field;
    const tagsKey = /\b(selectedTags).([^.]+)/g.exec(fieldKey);
    const defaultLabelValue = tagsKey ? tagsKey[2] : fieldKey;

    if (visible || extras || tagsKey) {
      prevField.push({
        label: $t(
          `${TransactionConstants.I18N_PREFIX}.${defaultLabelValue}.label`,
          defaultLabelValue
        ),
        value: fieldKey
      });
    }

    return prevField;
  }, []);
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: currentUser,
      isCustomer,
      isMerchant,
      levelSettings: _levelSettings
    },
    theme: {
      color: {
        data: { color }
      }
    },
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        timePeriod = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;
  const { t: $t } = ownProps;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const canSeeWidget = isCustomer || isMerchant;

  const {
    scope: {
      level: { type: userType, id: levelId }
    }
  } = currentUser;

  const {
    widgets: { data = [], loading, error },
    genericFields: { data: fields = [] }
  } = state;

  const criteriaOptions = mapCriteriaOptions({
    fields,
    $t
  });

  const widgets = data.map(widget => {
    const { name, id, type } = widget;

    const min = DateFormatter.getTimezonedDate(
      timePeriod.startTime,
      currentUser
    );
    const max = DateFormatter.getTimezonedDate(timePeriod.endTime, currentUser);

    return {
      type,
      filters,
      name,
      id,
      period: timePeriod.type,
      userCurrency,
      timePeriod,
      timestamps: {
        min,
        max
      }
    };
  });

  const getTransactionsCount = () => {
    const {
      widgets: { [COUNTER_KEY]: widget }
    } = state.widgetData;

    if (widget) {
      const {
        data: { stats = {} },
        loading,
        error
      } = widget;

      return {
        data: stats,
        loading,
        error
      };
    }

    return {
      data: {}
    };
  };

  const transactionsCounter = getTransactionsCount();

  return {
    searchContext,
    timePeriod,
    periodSelection: timePeriod.type,
    color,
    user: currentUser,
    userType,
    levelId,
    canSeeWidget,
    widgets,
    loading,
    error,
    criteriaOptions,
    transactionsCounter
  };
};

const mapDispatchToProps = dispatch => ({
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  resetWidgetsState: () => dispatch(resetWidgetsState()),
  resetWidgetsStatsState: () => dispatch(resetWidgetsStatsState()),
  getComparaisonWidgets: ({ filters, periodSelection, criteria }) =>
    dispatch(getComparaisonWidgets({ filters, periodSelection, criteria })),
  getTransactionCountComparison: ({ config }) =>
    dispatch(getTransactionCountComparaison({ ...config })),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl: true
      })
    )
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TransactionComparisonView);
