import React, { Component } from "react";
import F from "../../commons/HTTPFetcher";
import I18nSpan from "../../i18n/components/I18nSpan";
import _ from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

import {
  addNotificationError,
  addNotificationSuccess
} from "../../redux/actions";

import styles from "./styles/styles.css";

interface Props {
  color: any;
  templateActions: any;
}

interface State {
  file: any;
}

class UploadFile extends Component<Props, State> {
  state = {
    file: {}
  } as State;

  _onChangeFile = e => {
    const {
      target: { files }
    } = e;

    if (Object.keys(files).length > 0) {
      return this.setState({ file: files[0] });
    }
    return this.setState({ file: {} });
  };

  downloadTemplate = url => {
    const { addNotificationError } = this.props;

    F.download(url).catch(promiseText => {
      promiseText.then(text => addNotificationError(text));
    });
  };

  _handleSubmit = event => {
    event.preventDefault();

    const { fileKey, onSubmit } = this.props;

    const { file } = this.state;

    if (onSubmit) {
      onSubmit(file);
      return this.setState({ file: {} });
    }

    const { name } = file;
    const {
      addNotificationSuccess,
      addNotificationError,
      uploadUrl,
      notifications
    } = this.props;

    if (!name) {
      return addNotificationError("notification.error");
    }

    const data = new FormData();
    data.append(fileKey, file);

    return F.importFile(uploadUrl, {
      body: data
    })
      .then(() => {
        addNotificationSuccess(notifications.success);
        return this.setState({ file: {} });
      })
      .catch(promiseError => {
        return addNotificationError(notifications.error);
      });
  };

  render() {
    const {
      templateActions,
      color,
      tooltip,
      buttonsTitles,
      filesAccepted,
      t
    } = this.props;
    const {
      file: { name = "" }
    } = this.state;

    const accept = filesAccepted.join("|");

    return (
      <div className={styles.container}>
        <Templates
          buttonsTitles={buttonsTitles}
          tooltip={t(tooltip)}
          templateActions={templateActions}
          downloadTemplate={url => this.downloadTemplate(url)}
        />

        <form
          onSubmit={this._handleSubmit}
          className={styles.form}
          id={_.uniqueId("upload")}
          encType={"multipart/form-data"}
        >
          <input
            type="text"
            readOnly
            value={name}
            title={name}
            className={name ? "form-control file-name" : "hidden"}
          />
          <button
            type="submit"
            className={classNames(
              name ? "btn" : "hidden",
              "button-container upload-file"
            )}
          >
            <span
              className={classNames(
                "glyphicon",
                "glyphicon-import upload-icon"
              )}
            />
            <I18nSpan msgKey={buttonsTitles.upload} />
          </button>
          <div
            className={classNames(
              "button-container",
              styles["button-container"]
            )}
          >
            <label
              htmlFor="file-upload"
              className={classNames(
                styles["custom-file-upload"],
                "custom-file-upload"
              )}
            >
              <i className="glyphicon icon-select" />
              <I18nSpan msgKey={"sim.import.file"} />
            </label>
            <input
              className={styles["input-file"]}
              type="file"
              id="file-upload"
              accept={accept}
              onChange={this._onChangeFile}
              onClick={e => {
                e.target.value = "";
              }}
            />
          </div>
        </form>

        <style>
          {`


                        .hover-menu .btn-ingenico, .btn-ingenico:focus,
                        .btn-file, .btn-upload {
                            color: ${color};
                            border-color: ${color};
                        }

                        .upload-file{
                          background-color: #fff;
                          margin-right: 12px;
                          box-shadow: 1px 1px 1px 0 rgba(0,0,0,.05);
                          border-radius: 10px;
                          text-transform: uppercase;
                          line-height: 1.2em;
                          font-weight: 700;
                        }

                        .upload-icon{
                          color: ${color};
                        }

                        .custom-import-action {
                          border: none;
                          background: #FFFFFF;
                          box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.05);
                          border-radius: 10px;
                      }

                        .custom-import-action:hover, .custom-import-action:active {
                            border: none;
                            background-color: #fff !important;
                        }

                        .custom-import-action:focus, .custom-import-action:active:focus {
                          outline: 0;
                          background-color: #fff !important;
                          -webkit-box-shadow: none;
                                  box-shadow: none;
                      }

                        .custom-file-upload {
                          border-color: #adadad;
                        }

                        .custom-file-upload:hover {
                            border-color: ${color};
                        }

                        .hover-menu .hover-menu-list .downItemMenu:before {
                          color: ${color};
                        }

                        .hover-menu-list .downItemMenu.icon-chevron:after  {
                          background-color: ${color};
                        }

                        .hover-menu-list .downItemMenu.icon-template:before  {
                          background-color: ${color};
                        }

                        .icon-select:before {
                          background-color: ${color};
                        }

                        .icon-template:before {
                          background-color: ${color};
                        }
                    `}
        </style>
      </div>
    );
  }
}

const Templates = ({
  buttonsTitles,
  templateActions,
  downloadTemplate,
  tooltip
}) => {
  if (templateActions.length === 0) {
    return null;
  }
  if (templateActions.length > 1) {
    return (
      <div className={`hover-menu ${styles["template-actions"]}`}>
        <ul className="hover-menu-list">
          <li className="menuItem glyphicon">
            <span className="downItemMenu btn-ingenico glyphicon icon-template icon-chevron">
              <I18nSpan msgKey={buttonsTitles.templates} />
            </span>
            <div className="downItemMenu-list-wrapper">
              <ul>
                {templateActions.map((action, index) => (
                  <li key={index} className="create">
                    <button
                      onClick={e => downloadTemplate(action.url)}
                      type="submit"
                      className="btn btn-template-choice"
                    >
                      <I18nSpan msgKey={action.label} />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  const [url] = templateActions;
  return (
    <button
      className={classNames(
        "btn",
        "btn-default",
        styles["import-action"],
        "custom-import-action"
      )}
      title={tooltip}
      onClick={e => downloadTemplate(url)}
    >
      <span className="glyphicon icon-template" />
      <I18nSpan msgKey={buttonsTitles.templates} />
    </button>
  );
};

UploadFile.defaultProps = {
  templateActions: [],
  filesAccepted: ["text/plain"]
};

UploadFile.propTypes = {
  uploadUrl: PropTypes.string.isRequired,
  fileKey: PropTypes.string.isRequired,
  buttonsTitles: PropTypes.shape({
    upload: PropTypes.string.isRequired,
    templates: PropTypes.string.isRequired
  }),
  notifications: PropTypes.shape({
    success: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired
  }),
  filesAccepted: PropTypes.arrayOf(PropTypes.string),
  templateActions: PropTypes.array,
  onSubmit: PropTypes.func
};

const mapStateToProps = state => {
  const {
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;
  return { color };
};

const mapDispatchToProps = dispatch => ({
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(UploadFile);
