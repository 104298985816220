import {
  PENDING_GET_PREDEFINED_ALERTS_ACTION,
  SUCCESS_GET_PREDEFINED_ALERTS_ACTION,
  FAILURE_GET_PREDEFINED_ALERTS_ACTION,
  SUCCESS_DELETE_PREDEFINED_ALERT_ACTION
} from "../actionsTypes";
import {
  initSelection,
  onToggleSelectAll,
  onToggleUnSelectAll
} from "./selection";
import get from "get-value";

const initialState = {
  data: [],
  selection: {},
  loading: false,
  error: false,
  errors: {}
};

const _onPredefinedAlertDeleted = ({ alertId, predefinedAlerts }) => {
  return predefinedAlerts.filter(predefinedAlert => {
    const { id } = predefinedAlert;

    return id !== alertId;
  });
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_PREDEFINED_ALERTS_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_PREDEFINED_ALERTS_ACTION:
      const { predefinedAlerts } = action.payload;
      const selection = initSelection({ data: predefinedAlerts, keyId: "id" });

      return {
        ...state,
        selection,
        data: predefinedAlerts
      };
    case FAILURE_GET_PREDEFINED_ALERTS_ACTION:
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    case SUCCESS_DELETE_PREDEFINED_ALERT_ACTION: {
      const {
        payload: { id: alertId }
      } = action;
      const { data: prevAlerts, selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const predefinedAlerts = _onPredefinedAlertDeleted({
        alertId,
        predefinedAlerts: prevAlerts
      });

      return {
        ...state,
        selection,
        data: predefinedAlerts
      };
    }
    case "TOGGLE_SELECT_PREDEFINED_ALERTS_ACTION":
    case "TOGGLE_UNSELECT_PREDEFINED_ALERTS_ACTION": {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find(alert => get(alert, "id") === id) : false
        }
      };
    }
    case "TOGGLE_SELECT_ALL_PREDEFINED_ALERTS_ACTION": {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: "id"
      });

      return {
        ...state,
        selection
      };
    }
    case "TOGGLE_UNSELECT_ALL_PREDEFINED_ALERTS_ACTION": {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    default:
      return state;
  }
};
