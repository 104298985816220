class Customer {
  selectedCurrency = {};
  selectedOffer = {};

  constructor(
    public id: string,
    public name: string,
    public programs: Array<string>,
    public destAddress1: string,
    public destAddress2: string,
    public destPort: number,
    public commercialContact: string,
    public supportContact: string,
    public replyTo: string,
    public color: string,
    public ticketTemplate: string,
    public tags: Array<string>,
    public status: string,
    public currency: string,
    public offerId: string,
    public terminalCustomerId: string,
    public domainName: string,
    public languages: Array<string>,
    public wechatApiKey: string,
    public singleSession: boolean
  ) {
    this.id = id;
    this.name = name;
    this.programs = programs || [];
    this.destAddress1 = destAddress1;
    this.destAddress2 = destAddress2;
    this.destPort = destPort;
    this.commercialContact = commercialContact;
    this.supportContact = supportContact;
    this.replyTo = replyTo;
    this.color = color;
    this.ticketTemplate = ticketTemplate;
    this.tags = tags;
    this.status = status;
    this.currency = currency;
    this.offerId = offerId;
    this.terminalCustomerId = terminalCustomerId;
    this.domainName = domainName;
    this.languages = languages;
    this.wechatApiKey = wechatApiKey;
    this.singleSession = singleSession;
  }

  toStoreForm() {
    return {
      id: this.id,
      name: this.name,
      programs: this.programs,
      destAddress1: this.destAddress1,
      destAddress2: this.destAddress2,
      destPort: this.destPort,
      commercialContact: this.commercialContact,
      supportContact: this.supportContact,
      replyTo: this.replyTo,
      color: this.color,
      ticketTemplate: this.ticketTemplate,
      tags: this.tags,
      status: this.status,
      currency: this.currency,
      offerId: this.offerId,
      terminalCustomerId: this.terminalCustomerId,
      domainName: this.domainName,
      languages: this.languages,
      wechatApiKey: this.wechatApiKey,
      singleSession: this.singleSession
    };
  }

  static buildFromForm(customerForm) {
    return new Customer(
      customerForm.id,
      customerForm.name,
      customerForm.programs,
      customerForm.destAddress1,
      customerForm.destAddress2,
      customerForm.destPort,
      customerForm.commercialContact,
      customerForm.supportContact,
      customerForm.replyTo,
      "#E31C18",
      customerForm.ticketTemplate,
      customerForm.tags,
      customerForm.status,
      customerForm.currency,
      customerForm.offerId,
      customerForm.terminalCustomerId ? customerForm.terminalCustomerId : null,
      customerForm.domainName,
      customerForm.languages,
      customerForm.wechatApiKey,
      customerForm.singleSession
    );
  }

  static wrapFromServer(customer: Customer) {
    return new Customer(
      customer.id,
      customer.name,
      customer.programs,
      customer.destAddress1,
      customer.destAddress2,
      customer.destPort,
      customer.commercialContact,
      customer.supportContact,
      customer.replyTo,
      customer.color,
      customer.ticketTemplate,
      customer.tags,
      customer.status,
      customer.currency,
      customer.offerId,
      customer.terminalCustomerId,
      customer.domainName,
      customer.languages,
      customer.wechatApiKey,
      customer.singleSession
    );
  }
}

export default Customer;
