import React, { Component } from "react";
import * as DateTime from "react-datetime";
import * as moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../../../i18n/components/I18nSpan";
import BootstrapInput from "../../../../ingenicoForm/components/BootstrapInput";
import {
  CreateTransactionOnClosingHoursAlert,
  TransactionOnClosingHoursAlert
} from "../../model/TransactionOnClosingHoursAlert";

import styles from "../../../styles/TransactionOnClosingHours.css";

interface Props {
  onChange: (alert: CreateTransactionOnClosingHoursAlert) => void;
}

interface State {
  form?: any;
}

class TransactionOnClosingHours extends Component<Props, State> {
  state = {
    form: {
      fromTime: moment()
        .hour(0)
        .minute(0),
      toTime: moment()
        .hour(1)
        .minute(0),
      dayOfWeek: []
    },
    options: {
      dayOfWeek: []
    }
  };

  private onTimeChange = (fieldName: string) => timePeriod =>
    this.setState(
      state => ({
        form: { ...state.form, [fieldName]: timePeriod }
      }),
      this.createAlertFromState
    );

  private createAlertFromState = (name, value) =>
    this.setState(
      state => ({
        form: { ...state.form, [name]: value }
      }),
      () => {
        const { form } = this.state;
        const closingDays = form.dayOfWeek.map(day => day.value);

        const transactionOnClosingHoursAlert = CreateTransactionOnClosingHoursAlert.create(
          form.fromTime.format("HH:mm"),
          form.toTime.format("HH:mm"),
          closingDays
        );

        return this.props.onChange(transactionOnClosingHoursAlert);
      }
    );

  render() {
    const { form } = this.state;
    const { options } = this.props;

    return (
      <div
        id="alert-transaction-on-closing-hours"
        className={`alert-item ${styles.container}`}
      >
        <I18nSpan
          msgKey={`alert.predefined.${TransactionOnClosingHoursAlert.I18N_KEY}.create-1`}
        />
        <DateTime
          locale={moment.locale()}
          value={form.fromTime}
          defaultValue={moment()
            .hour(0)
            .minute(0)}
          onChange={this.onTimeChange("fromTime")}
          className="dateTime"
          dateFormat={false}
          inputProps={{ required: true }}
        />
        <I18nSpan
          msgKey={`alert.predefined.${TransactionOnClosingHoursAlert.I18N_KEY}.create-2`}
        />
        <DateTime
          locale={moment.locale()}
          value={form.toTime}
          defaultValue={moment()
            .hour(0)
            .minute(0)}
          onChange={this.onTimeChange("toTime")}
          className="dateTime"
          dateFormat={false}
          inputProps={{ required: true }}
        />

        <I18nSpan
          msgKey={`alert.predefined.${TransactionOnClosingHoursAlert.I18N_KEY}.create-3`}
        />
        <BootstrapInput
          descriptor={{
            type: "multipleselect",
            options: options.dayOfWeek
          }}
          ref="dayOfWeek"
          name="dayOfWeek"
          validation={() => undefined}
          onChange={this.createAlertFromState}
          formValue={form.dayOfWeek}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { t } = ownProps;

  const dayOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY"
  ].map(day => {
    return {
      id: day,
      name: t(`scheduled.closingWeekDay.${day}`)
    };
  });

  return {
    options: {
      dayOfWeek
    }
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, null)
)(TransactionOnClosingHours);
