import get from "get-value";

import {
  PENDING_GET_SALES_ACTION,
  SUCCESS_GET_SALES_ACTION,
  FAILURE_GET_SALES_ACTION,
  RESET_SALES_ACTION,
  SUCCESS_GET_SALE_DETAIL_ACTION,
  FAILURE_GET_SALE_DETAIL_ACTION,
  SUCCESS_SET_SALE_DETAIL_ACTION,
  SHOW_SALE_DETAIL_ACTION,
  HIDE_SALE_DETAIL_ACTION,
  SUCCESS_GET_SALES_SCROLL_ACTION
} from "../actionsTypes";
import {
  initSelection,
  onToggleSelectAll,
  onToggleUnSelectAll
} from "./selection";

const initialState = {
  data: [],
  detail: {},
  selection: {},
  showSaleDetail: false,
  loading: true,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_SALES_ACTION: {
      return {
        ...state,
        detail: {},
        selection: {},
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_SALES_ACTION: {
      const {
        payload: { count, scrollId, data }
      } = action;
      data.map(item => {
        const { saleId } = item;
        Object.assign(item, {
          sale: {
            id: saleId
          }
        });
        return item;
      });
      const selection = initSelection({ data, key: "sale" });

      return {
        ...state,
        data,
        selection,
        count,
        scrollId,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_SALES_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        data: initialState.data,
        selection: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case SUCCESS_GET_SALES_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const data = [...state.data, ...scrollData];
      const selection = {
        ...initSelection({ data, key: "sale" }),
        ...state.selection
      };

      return {
        ...state,
        data,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case RESET_SALES_ACTION: {
      return {
        ...state,
        ...initialState
      };
    }
    case "TOGGLE_SELECT_SALES_ACTION":
    case "TOGGLE_UNSELECT_SALES_ACTION": {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find(sale => get(sale, "saleId") === id) : false
        }
      };
    }
    case "TOGGLE_SELECT_ALL_SALES_ACTION": {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: "saleId"
      });

      return {
        ...state,
        selection
      };
    }
    case "TOGGLE_UNSELECT_ALL_SALES_ACTION": {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case SUCCESS_SET_SALE_DETAIL_ACTION:
    case SUCCESS_GET_SALE_DETAIL_ACTION: {
      const {
        payload: { detail }
      } = action;

      return {
        ...state,
        detail
      };
    }
    case FAILURE_GET_SALE_DETAIL_ACTION: {
      const { payload: detail } = action;

      return {
        ...state,
        detail
      };
    }
    case SHOW_SALE_DETAIL_ACTION: {
      return {
        ...state,
        showSaleDetail: true
      };
    }
    case HIDE_SALE_DETAIL_ACTION: {
      return {
        ...state,
        showSaleDetail: false
      };
    }
    default:
      return state;
  }
};
