import { OptionItem } from "../../ingenicoForm/model/OptionItem";
import TagUtil from "../../tag/model/TagUtil";

export default class Merchant {
  id: string;
  name: string;
  programId: string;
  address: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
  countryOfEstablishment: string;
  tags: string;
  selectedTags: string;
  brandName: string;
  website: string;
  vatNumber: string;
  commercialContact: string;
  supportContact: string;
  merchantReference: string;
  merchantCategoryCode: string;
  permissions: string;
  applicationSettings: any;

  constructor(
    id,
    name,
    programId,
    address,
    city,
    zipCode,
    state,
    country,
    countryOfEstablishment,
    tags,
    selectedTags,
    brandName,
    website,
    vatNumber,
    commercialContact,
    supportContact,
    merchantReference,
    merchantCategoryCode,
    permissions,
    applicationSettings
  ) {
    this.id = id;
    this.name = name;
    this.programId = programId;
    this.address = address;
    this.city = city;
    this.zipCode = zipCode;
    this.state = state;
    this.country = country;
    this.countryOfEstablishment = countryOfEstablishment;
    this.tags = tags;
    this.selectedTags = selectedTags;
    this.brandName = brandName;
    this.website = website;
    this.vatNumber = vatNumber;
    this.commercialContact = commercialContact;
    this.supportContact = supportContact;
    this.merchantReference = merchantReference;
    this.merchantCategoryCode = merchantCategoryCode;
    this.permissions = permissions;
    this.applicationSettings = applicationSettings;
  }

  toMerchantForm() {
    return {
      id: this.id,
      name: this.name,
      programId: this.programId,
      address: this.address,
      city: this.city,
      zipCode: this.zipCode,
      state: this.state,
      country: this.country,
      countryOfEstablishment: this.countryOfEstablishment,
      tags: this.tags,
      selectedTags: this.selectedTags,
      brandName: this.brandName,
      website: this.website,
      vatNumber: this.vatNumber,
      commercialContact: this.commercialContact,
      supportContact: this.supportContact,
      merchantReference: this.merchantReference,
      merchantCategoryCode: this.merchantCategoryCode,
      permissions: this.permissions
    };
  }

  static buildFromForm(merchantForm) {
    return new Merchant(
      merchantForm.id,
      merchantForm.name,
      merchantForm.programId,
      merchantForm.address,
      merchantForm.city,
      merchantForm.zipCode,
      merchantForm.state,
      merchantForm.country,
      merchantForm.countryOfEstablishment,
      merchantForm.tags,
      TagUtil.selectedTagsToObject(merchantForm.selectedTags),
      merchantForm.brandName,
      merchantForm.website,
      merchantForm.vatNumber,
      merchantForm.commercialContact,
      merchantForm.supportContact,
      merchantForm.merchantReference,
      merchantForm.merchantCategoryCode,
      merchantForm.permissions,
      merchantForm.applicationSettings
    );
  }

  static wrapFromServer(merchant) {
    return new Merchant(
      merchant.id,
      merchant.name,
      merchant.programId,
      merchant.address,
      merchant.city,
      merchant.zipCode,
      merchant.state,
      merchant.country,
      merchant.countryOfEstablishment,
      merchant.tags,
      merchant.selectedTags,
      merchant.brandName,
      merchant.website,
      merchant.vatNumber,
      merchant.commercialContact,
      merchant.supportContact,
      merchant.merchantReference,
      merchant.merchantCategoryCode,
      merchant.permissions
    );
  }

  static asOption(merchant): OptionItem {
    return {
      value: merchant.id,
      label: merchant.name
    };
  }
}
