import {
  PENDING_GET_MERCHANTS_LIST_ACTION,
  SUCCESS_GET_MERCHANTS_LIST_ACTION,
  FAILURE_GET_MERCHANTS_LIST_ACTION,
  PENDING_GET_MERCHANTS_ACTION,
  SUCCESS_GET_MERCHANTS_ACTION,
  SUCCESS_GET_MERCHANTS_SCROLL_ACTION,
  SUCCESS_GET_TRANSACTIONS_RECEIPTS_ACTION,
  FAILURE_GET_MERCHANTS_ACTION,
  RESET_MERCHANTS_ACTION,
  SUCCESS_DELETE_MERCHANT_ACTION,
  SUCCESS_UPDATE_STATUS_MERCHANTS_ACTION
} from "../actionsTypes";
import {
  initSelection,
  onToggleSelectAll,
  onToggleUnSelectAll,
  filterId,
  onStatusUpdated
} from "./selection";
import get from "get-value";

import { reduceCount } from "../actions/sseActions";

const initialState = {
  dataList: [],
  data: [],
  selection: {},
  receipts: {},
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_MERCHANTS_LIST_ACTION: {
      return {
        ...state,
        dataList: [],
        selection: {},
        loading: true
      };
    }
    case PENDING_GET_MERCHANTS_ACTION: {
      return {
        ...state,
        data: [],
        loading: true
      };
    }
    case SUCCESS_GET_MERCHANTS_LIST_ACTION: {
      const {
        payload: { count, scrollId, data: dataList }
      } = action;

      const selection = initSelection({ data: dataList, key: "merchant" });

      return {
        ...state,
        dataList,
        selection,
        count,
        scrollId,
        loading: false
      };
    }
    case SUCCESS_GET_MERCHANTS_ACTION: {
      const {
        payload: { data }
      } = action;

      return {
        ...state,
        data,
        loading: false
      };
    }
    case SUCCESS_GET_MERCHANTS_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const dataList = [...state.dataList, ...scrollData];
      const selection = {
        ...initSelection({ data: dataList, key: "merchant" }),
        ...state.selection
      };

      return {
        ...state,
        dataList,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_MERCHANTS_LIST_ACTION: {
      const errors = action.payload;

      return {
        ...state,
        dataList: initialState.dataList,
        selection: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case FAILURE_GET_MERCHANTS_ACTION: {
      const errors = action.payload;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case `TOGGLE_SELECT_MERCHANTS_ACTION`:
    case `TOGGLE_UNSELECT_MERCHANTS_ACTION`: {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        dataList
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value
            ? dataList.find(merchant => get(merchant, "merchant.id") === id)
            : false
        }
      };
    }
    case `TOGGLE_SELECT_ALL_MERCHANTS_ACTION`: {
      const { selection: prevSelection, dataList } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data: dataList,
        keyId: "merchant.id"
      });

      return {
        ...state,
        selection
      };
    }
    case `TOGGLE_UNSELECT_ALL_MERCHANTS_ACTION`: {
      const { selection } = state;
      const nextSelection = onToggleUnSelectAll({ selection });

      return {
        ...state,
        selection: nextSelection
      };
    }
    case SUCCESS_GET_TRANSACTIONS_RECEIPTS_ACTION: {
      const {
        payload: { receipts }
      } = action;

      return {
        ...state,
        receipts
      };
    }

    case SUCCESS_UPDATE_STATUS_MERCHANTS_ACTION: {
      const {
        payload: { status, ids }
      } = action;
      const { dataList } = state;
      const nextData = onStatusUpdated({
        data: dataList,
        ids,
        status,
        key: "merchant"
      });

      return {
        ...state,
        dataList: nextData
      };
    }
    case SUCCESS_DELETE_MERCHANT_ACTION: {
      const {
        payload: { id }
      } = action;

      const { dataList, selection: prevSelection, count } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const nextData = filterId({
        data: dataList,
        keyValue: id,
        keyId: "merchant.id"
      });
      const countNumber = parseInt(count);
      const nextCount = reduceCount({ countNumber, data: dataList, nextData });

      return {
        ...state,
        selection,
        count: nextCount.toString(),
        dataList: nextData
      };
    }
    case RESET_MERCHANTS_ACTION: {
      return initialState;
    }
    default:
      return state;
  }
};
