import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import I18nSpan from "../../../i18n/components/I18nSpan";

import styles from "./style/LoadingMobile.css";

import keyIndicatorStyles from "./style/KeyIndicatorMobile.css";

interface Props {
  titleKey: string;
  iconKey: string;
  args?: any;
  children: any;
  forbidden?: boolean;
  error?: boolean;
  color: string;
  loading: boolean;
  renderTrend: Function;
}

interface State {}

export const Loading = ({ loading, children, color }: any) => {
  if (loading) {
    return (
      <div className={classNames(styles.loading)}>
        <div className={classNames("loadingTxt-custom", styles.loadingTxt)}>
          <div className={styles.spinner}>
            <div className={styles["double-bounce1"]} />
            <div className={styles["double-bounce2"]} />
          </div>
          LOADING •••
        </div>
        <style>
          {`
                    /* Style loader */
              .loadingTxt-custom {color: ${color}!important;}
            `}
        </style>
      </div>
    );
  }
  return children;
};

const Indicator = ({ children, loading, color }: any) => {
  return (
    <div className={classNames(styles.indicator)}>
      <Loading loading={loading} color={color}>
        {children}
      </Loading>
    </div>
  );
};

class KeyIndicator extends Component<Props, State> {
  render() {
    const {
      titleKey,
      iconKey,
      args,
      children,
      forbidden,
      color,
      darkMode: prefersDarkMode,
      error,
      loading,
      renderTrend
    } = this.props;

    if (error) {
      return (
        <Indicator loading={loading} color={color}>
          <div>
            <IndicatorTitle titleKey={titleKey} args={args} />
            <div className={keyIndicatorStyles.error}>
              <I18nSpan msgKey={"notification.error"} />
            </div>
          </div>
        </Indicator>
      );
    }

    if (forbidden) {
      return (
        <Indicator loading={loading} color={color}>
          <div>
            <IndicatorTitle titleKey={titleKey} args={args} />
            <div className={keyIndicatorStyles.error}>Access forbidden</div>
          </div>
        </Indicator>
      );
    }

    return (
      <Indicator loading={loading} color={color}>
        <IndicatorTitle titleKey={titleKey} args={args} iconKey={iconKey} />
        <div className={classNames(keyIndicatorStyles["indicator-container"])}>
          <IndicatorValue prefersDarkMode={prefersDarkMode}>
            {children}
          </IndicatorValue>
          <TrendIndicator renderTrend={renderTrend} />
        </div>
      </Indicator>
    );
  }
}

const TrendIndicator = ({ renderTrend }) => {
  if (renderTrend) {
    return <div className={styles["trend-indicator"]}>{renderTrend()}</div>;
  }

  return null;
};

const IndicatorTitle = ({ titleKey, args, iconKey }) => (
  <div className={keyIndicatorStyles["indicator-title-wrapper"]}>
    <I18nSpan
      msgKey={titleKey}
      args={args}
      class={classNames(keyIndicatorStyles["indicator-title"])}
    />
    <i className={`icon ${keyIndicatorStyles[iconKey]}`} />
  </div>
);

const IndicatorValue = ({ children, prefersDarkMode }) => (
  <div
    className={classNames(keyIndicatorStyles["indicator-value"], {
      [keyIndicatorStyles["indicator-value--dark"]]: prefersDarkMode,
      [keyIndicatorStyles["indicator-value--light"]]: !prefersDarkMode
    })}
  >
    {children}
  </div>
);

const mapStateToProps = (state, ownProps) => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

export default connect(mapStateToProps, null)(KeyIndicator);
