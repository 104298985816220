import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";

import Validators from "../../ingenicoForm/validation/Validators";

import {
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

import styles from "./../styles/styles.css";

interface FormError {
  code: string;
  args: string | null;
}

interface State {
  form: {
    name: string;
    value: string;
  };
  errors: {
    name: Array<FormError>;
    value: Array<FormError>;
  };
}

interface Props {
  userLevel: string;
  userId: string;
  onSubmit: Function;
}

class CreateSecuredVariables extends Component<Props, State> {
  state = {
    form: {
      name: "",
      value: ""
    },
    errors: {}
  } as State;

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  _onChangeHandler = (name: string, value: string) => {
    this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value
        }
      };
    }, this._checkError(name));
  };

  _resetForm = () =>
    this.setState({
      form: {
        name: "",
        value: ""
      },
      errors: {}
    } as State);

  _notificationError = (message: string) => {
    const { addNotificationError } = this.props;

    addNotificationError(message);

    throw new Error(message);
  };

  _onSave = (event: Event) => {
    event.preventDefault();

    const { onSubmit } = this.props;

    this._checkErrors(async () => {
      const { form, errors } = this.state;
      const hasErrors = _.any(form, (fieldValue, field) => {
        const error = errors[field];

        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        const { form: securedVariables } = this.state;

        try {
          await onSubmit({
            securedVariables,
            message: "securedVariable.message.create.success"
          });

          return this._resetForm();
        } catch (error) {
          return this._notificationError(error);
        }
      }
    });
  };

  _nameValidation = (value: string) =>
    Promise.all([
      Validators.requiredValidator(value),
      Validators.asciiUppercaseAndNumberValidator(
        value,
        "securedVariable.validation.name.invalid.format"
      )
    ]);

  _valueValidation = (value: string) =>
    Promise.all([Validators.requiredValidator(value)]);

  _checkError = name => {
    return then => {
      const { errors } = this.state as State;

      return this._waitForValidation([name], errors, then);
    };
  };

  _checkErrors = then =>
    this._waitForValidation(_.keys(this.state.form), {}, then);

  _waitForValidation = (
    names: Array<string>,
    newErrors: Array<String>,
    then
  ) => {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });

    return Promise.all([requiredPromise]);
  };

  render() {
    const {
      form: { name, value },
      errors: { name: errorsName, value: errorsValue }
    } = this.state;

    return (
      <div className={`row ${styles.container}`}>
        <div className="col-sm-4">
          <BootstrapInput
            descriptor={{
              id: "name",
              type: "text",
              label: "securedVariable.form.name",
              inputClass: "col-sm-12",
              placeholder: "securedVariable.form.name"
            }}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name={"name"}
            formValue={name}
            required={true}
            validation={this._nameValidation}
            errors={errorsName}
          />
        </div>
        <div className="col-sm-4">
          <BootstrapInput
            descriptor={{
              id: "value",
              type: "text",
              label: "securedVariable.form.value",
              inputClass: "col-sm-12",
              placeholder: "securedVariable.form.value"
            }}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("value")}
            name={"value"}
            formValue={value}
            required={true}
            validation={this._valueValidation}
            errors={errorsValue}
          />
        </div>
        <div className={`col-sm-4 ${styles.deleteContainer}`}>
          <a onClick={this._onSave} className="btn btn-circle">
            <i className="glyphicon glyphicon-plus" />
          </a>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default connect(null, mapDispatchToProps)(CreateSecuredVariables);
