import React from "react";
import classNames from "classnames";
import styles from "../styles/styles.css";

export default function VerificationCodeInput({
  label,
  onChange,
  value,
  pair
}) {
  return (
    <div
      className={classNames(styles["form-group"], {
        [styles["otp-pair"]]: pair
      })}
    >
      <label htmlFor="otp" className={styles["control-label"]}>
        {label}
      </label>
      <input
        id="otp"
        name="otp"
        className="form-control"
        onChange={onChange}
        required
        value={value}
        autoComplete="username"
      />
    </div>
  );
}
