import get from "get-value";
import {
  PENDING_GET_STORES_LIST_ACTION,
  SUCCESS_GET_STORES_LIST_ACTION,
  FAILURE_GET_STORES_LIST_ACTION,
  PENDING_GET_STORES_ACTION,
  SUCCESS_GET_STORES_ACTION,
  SUCCESS_GET_STORES_SCROLL_ACTION,
  FAILURE_GET_STORES_ACTION,
  RESET_STORES_ACTION,
  SUCCESS_UPDATE_STATUS_STORES_ACTION,
  SUCCESS_DELETE_STORE_ACTION
} from "../actionsTypes";
import {
  initSelection,
  onStatusUpdated,
  onToggleSelectAll,
  onToggleUnSelectAll,
  filterId
} from "./selection";

import { reduceCount } from "../actions/sseActions";

const initialState = {
  dataList: [],
  data: [],
  selection: {},
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_STORES_LIST_ACTION: {
      return {
        ...state,
        dataList: [],
        selection: {},
        loading: true
      };
    }
    case PENDING_GET_STORES_ACTION: {
      return {
        ...state,
        data: [],
        loading: true
      };
    }
    case SUCCESS_GET_STORES_LIST_ACTION: {
      const {
        payload: { count, scrollId, data: dataList }
      } = action;

      const selection = initSelection({ data: dataList, key: "store" });

      return {
        ...state,
        dataList,
        selection,
        count,
        scrollId,
        loading: false
      };
    }
    case SUCCESS_GET_STORES_ACTION: {
      const {
        payload: { data }
      } = action;

      return {
        ...state,
        data,
        loading: false
      };
    }
    case SUCCESS_GET_STORES_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const data = [...state.data, ...scrollData];
      const selection = {
        ...initSelection({ data, key: "store" }),
        ...state.selection
      };

      return {
        ...state,
        data,
        selection,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_STORES_LIST_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        dataList: initialState.dataList,
        selection: {},
        loading: false,
        error: true,
        ...errors
      };
    }
    case FAILURE_GET_STORES_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case "TOGGLE_SELECT_STORES_ACTION":
    case "TOGGLE_UNSELECT_STORES_ACTION": {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        dataList
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value
            ? dataList.find(store => get(store, "store.id") === id)
            : false
        }
      };
    }
    case "TOGGLE_SELECT_ALL_STORES_ACTION": {
      const { selection: prevSelection, dataList } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data: dataList,
        keyId: "store.id"
      });

      return {
        ...state,
        selection
      };
    }
    case "TOGGLE_UNSELECT_ALL_STORES_ACTION": {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case SUCCESS_UPDATE_STATUS_STORES_ACTION: {
      const {
        payload: { status, ids }
      } = action;
      const { dataList } = state;
      const nextData = onStatusUpdated({
        data: dataList,
        ids,
        status,
        key: "store"
      });

      return {
        ...state,
        dataList: nextData
      };
    }
    case SUCCESS_DELETE_STORE_ACTION: {
      const {
        payload: { id }
      } = action;

      const { dataList, selection: prevSelection, count } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const nextData = filterId({
        data: dataList,
        keyValue: id,
        keyId: "store.id"
      });
      const countNumber = parseInt(count);
      const nextCount = reduceCount({ countNumber, data: dataList, nextData });

      return {
        ...state,
        selection,
        count: nextCount.toString(),
        dataList: nextData
      };
    }
    case RESET_STORES_ACTION: {
      return initialState;
    }
    default:
      return state;
  }
};
