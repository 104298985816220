import React, { Component } from "react";
import { withRouter } from "react-router";
import * as DateTime from "react-datetime";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../i18n/components/I18nSpan";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import UserUtil from "../../user/UserUtil";
import UserValidations from "../../user/validations/UserValidations";
import Validators from "../../ingenicoForm/validation/Validators";
import AuthConstants from "../../connection/constants/ConnectionConstant";
import SettlementFields from "./SettlementFields";
import If from "../../components/if";

import ErrorsList from "../../ingenicoForm/validation/ErrorsList";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import { SettlementFileFormatConstants } from "../models/SettlementFileFormat";
import { extractObjectKeys } from "../../commons/field/Utils";
import CreationPopin from "../../components/CreationPopin";
import Permissions from "../../user/constants/Permissions";
import ConnectionConstant from "../../connection/constants/ConnectionConstant";

// @secure

import {
  createCustomer,
  getOffers,
  getSmsProviders,
  getCurrencies,
  addNotificationSuccess,
  addNotificationError,
  getPermissionsForm
} from "../../redux/actions";

export class CreateCustomerView extends Component<Props, State> {
  state = {
    form: {
      name: null,
      replyTo: null,
      currency: null,
      offerId: null,
      terminalCustomerId: null,
      commercialContact: null,
      supportContact: null,
      domainName: null,
      userLogin: null,
      userEmail: null,
      permissions: null,
      languages: null,
      smsProvider: null,
      settlementConfiguration: false,
      settlement: {
        reconciliatedTransactionsOnly: true,
        acquirerIdentifier: null,
        host: null,
        triggerTime: "",
        path: null,
        user: null,
        fileFormat: {
          type: SettlementFileFormatConstants.CSV,
          transactionFields: [],
          fieldSeparator: "",
          fieldQuoting: ""
        }
      },
      alipayReconciliationConfiguration: false,
      alipayReconciliation: {
        partnerId: null,
        host: null,
        email: null,
        user: null
      }
    },
    errors: {
      settlement: {
        fileFormat: {}
      },
      alipayReconciliation: {}
    }
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentDidMount() {
    const {
      getOffers,
      getSmsProviders,
      getCurrencies,
      getPermissionsForm
    } = this.props;

    Promise.all([
      getCurrencies(),
      getOffers(),
      getSmsProviders(),
      getPermissionsForm({ restrictionLevel: AuthConstants.CUSTOMER })
    ]);
  }

  _saveCustomer = e => {
    e.preventDefault();

    const {
      saveCustomer,
      addNotificationSuccess,
      addNotificationError,
      currencies,
      currentUser: { hasCustomerUserManagement }
    } = this.props;

    if (
      this.state.form.settlementConfiguration &&
      !this.state.form.settlement.keyDefined &&
      !this.state.form.settlement.key
    ) {
      addNotificationError("notification.customer.keyNotSet");
    } else if (
      this.state.form.alipayReconciliationConfiguration &&
      !this.state.form.alipayReconciliation.keyDefined &&
      !this.state.form.alipayReconciliation.key
    ) {
      addNotificationError("notification.customer.keyNotSet");
    } else if (
      this.state.form.settlementConfiguration &&
      this.state.form.settlement.triggerTime == ""
    ) {
      addNotificationError("notification.customer.triggerNotSet");
    } else {
      const keys = extractObjectKeys(this.state.form, 3);

      this._checkErrors(async () => {
        const hasErrors = _.any(keys, name => {
          const error = _.get(this.state.errors, name);
          return !_.isEmpty(error);
        });

        if (!hasErrors) {
          const { form: customer } = this.state;

          customer.currency = _.findWhere(currencies, {
            alpha3: customer.currency
          });
          customer.languages = customer.languages
            ? customer.languages.map(lang => lang.value)
            : [];
          customer.settlement.fileFormat.transactionFields = customer.settlement
            .fileFormat.transactionFields
            ? customer.settlement.fileFormat.transactionFields.map(
                field => field.value
              )
            : [];
          customer.settlement.path = customer.settlement.path
            ? customer.settlement.path
            : "";

          try {
            const { id, name } = await saveCustomer({ customer });

            addNotificationSuccess("customer.create.success");

            if (hasCustomerUserManagement)
              return this._showCreatePopin({ id, name });

            return this._goToList();
          } catch (error) {
            addNotificationError("notification.error");

            throw new Error(
              `CreatCustomerView._saveCustomer() error: ${error}`
            );
          }
        }
      });
    }
  };

  _goToList = () => {
    const { history } = this.props;

    return history.push("/main/customer");
  };

  _goToCreateCustomerUser = (id: string) => {
    const { history } = this.props;

    return history.push(`/main/user/new-user/customer/${id}`);
  };

  _showCreatePopin = ({ id, name }) => {
    this.setState({
      showCreatePopin: true,
      id,
      name
    });
  };

  _closeDeletePopin = () => {
    this._goToList();
  };

  _waitForValidation = (
    names: Array<string>,
    newErrors: Array<String>,
    then
  ) => {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        _.set(
          newErrors,
          name,
          _.chain(errors)
            .flatten()
            .compact()
            .value()
        );
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  _checkError = name => {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  };

  _checkErrors = then => {
    this._waitForValidation(extractObjectKeys(this.state.form, 3), {}, then);
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    _.set(newForm, name, value);
    this.setState(newForm, this._checkError(name));
  };

  _onTimeChange = selectedTime => {
    let newErrors = this.state.errors;
    newErrors.triggerTime =
      selectedTime === ""
        ? [{ code: "form.error.required", args: [selectedTime] }]
        : [];

    let updatedCustomer = this.state.form;
    updatedCustomer.settlement.triggerTime = selectedTime;
    this.setState({
      form: updatedCustomer,
      errors: newErrors
    });
  };

  _onSettlementKeyChange = event => {
    const fileReader = new FileReader();
    fileReader.onloadend = (content: ProgressEvent) => {
      const target = content.target as FileReader;
      const textContent = target.result;
      this._onChangeHandler("settlement.key", textContent);
    };

    fileReader.readAsText(event.target.files[0]);
  };

  _onAlipayReconciliationKeyChange = event => {
    const fileReader = new FileReader();
    fileReader.onloadend = (content: ProgressEvent) => {
      const target = content.target as FileReader;
      const textContent = target.result;
      this._onChangeHandler("alipayReconciliation.key", textContent);
    };

    fileReader.readAsText(event.target.files[0]);
  };

  _getKeyMessage = type => {
    const { t } = this.props;

    return this.state.form[type].key || this.state.form[type].keyDefined
      ? "(" + t("customer.create.key-set") + ")"
      : "";
  };

  _previewFile = () => {
    const columns = ["A", "B", "C"];

    return _.reduce(
      columns,
      (acc, e) => {
        return (
          acc +
          _.get(this.state.form.settlement, "fileFormat.fieldQuoting", "") +
          e +
          _.get(this.state.form.settlement, "fileFormat.fieldQuoting", "") +
          _.get(this.state.form.settlement, "fileFormat.fieldSeparator", "")
        );
      },
      ""
    );
  };

  _checkEmail = value => {
    return Validators.emailValidator(value);
  };

  render() {
    const { t } = this.props;
    const { showCreatePopin, id, name } = this.state;
    const styleForTextInformation = { paddingTop: "7px" };

    return (
      <div>
        <FormTitle
          titleKey="customer.title"
          actionKey="customer.create.action"
        />
        {showCreatePopin && (
          <CreationPopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={() => this._goToCreateCustomerUser(id)}
            objectName={name}
            userLevel={ConnectionConstant.CUSTOMER_NEW}
          />
        )}
        <form className="ingenico-form form-horizontal customer-form">
          <BootstrapInput
            inputRef={this.setInputRef("name")}
            name="name"
            onChange={this._onChangeHandler}
            errors={this.state.errors.name}
            validation={UserValidations.required}
            required={true}
            descriptor={{
              type: "text",
              label: "customer.form.name.label",
              placeholder: "customer.form.name.placeholder"
            }}
            formValue={this.state.form.name}
          />
          <BootstrapInput
            inputRef={this.setInputRef("replyTo")}
            name="replyTo"
            onChange={this._onChangeHandler}
            errors={this.state.errors.replyTo}
            validation={this._checkEmail}
            descriptor={{
              type: "email",
              label: "customer.form.replyTo.label"
            }}
            formValue={this.state.form.replyTo}
          />
          <BootstrapInput
            inputRef={this.setInputRef("currency")}
            name="currency"
            onChange={this._onChangeHandler}
            errors={this.state.errors.currency}
            required={true}
            validation={UserValidations.required}
            descriptor={{
              type: "singleautocomplete",
              label: "customer.form.currency.label",
              options: this.props.options.currencies
            }}
            formValue={this.state.form.currency}
          />
          <BootstrapInput
            inputRef={this.setInputRef("offerId")}
            name="offerId"
            onChange={this._onChangeHandler}
            errors={this.state.errors.offerId}
            descriptor={{
              type: "singleautocomplete",
              label: "customer.form.offer.label",
              options: this.props.options.offers
            }}
            formValue={this.state.form.offerId}
          />
          <BootstrapInput
            inputRef={this.setInputRef("terminalCustomerId")}
            name="terminalCustomerId"
            onChange={this._onChangeHandler}
            errors={this.state.errors.terminalCustomerId}
            descriptor={{
              type: "text",
              label: "customer.form.terminalCustomerId.label",
              placeholder: "customer.form.terminalCustomerId.placeholder"
            }}
            formValue={this.state.form.terminalCustomerId}
          />
          <BootstrapInput
            inputRef={this.setInputRef("commercialContact")}
            name="commercialContact"
            onChange={this._onChangeHandler}
            errors={this.state.errors.commercialContact}
            descriptor={{
              type: "text",
              label: "customer.form.commercialContact.label",
              placeholder: "customer.form.commercialContact.placeholder"
            }}
            formValue={this.state.form.commercialContact}
          />
          <BootstrapInput
            inputRef={this.setInputRef("supportContact")}
            name="supportContact"
            onChange={this._onChangeHandler}
            errors={this.state.errors.supportContact}
            descriptor={{
              type: "text",
              label: "customer.form.supportContact.label",
              placeholder: "customer.form.supportContact.placeholder"
            }}
            formValue={this.state.form.supportContact}
          />
          <BootstrapInput
            inputRef={this.setInputRef("domainName")}
            name="domainName"
            onChange={this._onChangeHandler}
            errors={this.state.errors.domainName}
            descriptor={{
              type: "text",
              label: "customer.form.domainName.label",
              placeholder: "customer.form.domainName.placeholder"
            }}
            formValue={this.state.form.domainName}
          />
          <BootstrapInput
            inputRef={this.setInputRef("languages")}
            name="languages"
            onChange={this._onChangeHandler}
            errors={this.state.errors.languages}
            descriptor={{
              type: "multipleselect",
              label: "customer.form.languages.label",
              options: this.props.options.languages
            }}
            formValue={this.state.form.languages}
          />
          <BootstrapInput
            inputRef={this.setInputRef("smsProvider")}
            name="smsProvider"
            onChange={this._onChangeHandler}
            errors={this.state.errors.smsProviders}
            descriptor={{
              type: "singleautocomplete",
              label: "customer.form.smsProvider.label",
              options: this.props.options.smsProviders
            }}
            formValue={this.state.form.smsProvider}
          />
          <BootstrapInput
            inputRef={this.setInputRef("settlementConfiguration")}
            name="settlementConfiguration"
            onChange={this._onChangeHandler}
            errors={this.state.errors.settlementConfiguration}
            descriptor={{
              id: "settlementConfiguration",
              type: "checkbox",
              label: "customer.form.settlementConfiguration.label"
            }}
            formValue={this.state.form.settlementConfiguration}
          />

          <If test={this.state.form.settlementConfiguration}>
            <div>
              <BootstrapInput
                inputRef={this.setInputRef("settlement.acquirerIdentifier")}
                name="settlement.acquirerIdentifier"
                onChange={this._onChangeHandler}
                required={false}
                descriptor={{
                  type: "text",
                  label: "customer.form.acquirerIdentifier.label"
                }}
                formValue={this.state.form.settlement.acquirerIdentifier}
              />
              <BootstrapInput
                inputRef={this.setInputRef("settlement.fileFormat.type")}
                name="settlement.fileFormat.type"
                onChange={this._onChangeHandler}
                errors={this.state.errors.settlement.fileFormat.type}
                required={true}
                validation={UserValidations.required}
                descriptor={{
                  type: "select",
                  label: "customer.form.fileFormat.label",
                  options: this.props.options.fileFormats
                }}
                formValue={this.state.form.settlement.fileFormat.type}
              />
              {this.state.form.settlement.fileFormat.type ===
                SettlementFileFormatConstants.CSV && (
                <div>
                  <BootstrapInput
                    inputRef={this.setInputRef(
                      "settlement.fileFormat.transactionFields"
                    )}
                    name="settlement.fileFormat.transactionFields"
                    onChange={this._onChangeHandler}
                    errors={
                      this.state.errors.settlement.fileFormat.transactionFields
                    }
                    required={true}
                    validation={UserValidations.required}
                    descriptor={{
                      type: "multipleselect",
                      label: "customer.form.transactionFields.label",
                      options: this.props.options.transactionFields
                    }}
                    formValue={
                      this.state.form.settlement.fileFormat.transactionFields
                    }
                  />
                  <BootstrapInput
                    inputRef={this.setInputRef(
                      "settlement.fileFormat.fieldSeparator"
                    )}
                    name="settlement.fileFormat.fieldSeparator"
                    onChange={this._onChangeHandler}
                    errors={
                      this.state.errors.settlement.fileFormat.fieldSeparator
                    }
                    descriptor={{
                      type: "text",
                      label: "customer.form.fieldSeparator.label"
                    }}
                    formValue={
                      this.state.form.settlement.fileFormat.fieldSeparator
                    }
                  />
                  <BootstrapInput
                    inputRef={this.setInputRef(
                      "settlement.fileFormat.fieldQuoting"
                    )}
                    name="settlement.fileFormat.fieldQuoting"
                    onChange={this._onChangeHandler}
                    errors={
                      this.state.errors.settlement.fileFormat.fieldQuoting
                    }
                    descriptor={{
                      type: "text",
                      label: "customer.form.fieldQuoting.label"
                    }}
                    formValue={
                      this.state.form.settlement.fileFormat.fieldQuoting
                    }
                  />
                </div>
              )}
              <BootstrapInput
                inputRef={this.setInputRef(
                  "settlement.reconciliatedTransactionsOnly"
                )}
                name="settlement.reconciliatedTransactionsOnly"
                onChange={this._onChangeHandler}
                errors={
                  this.state.errors.settlement.reconciliatedTransactionsOnly
                }
                descriptor={{
                  id: "reconciliatedTransactionsOnly",
                  type: "checkbox",
                  label: "customer.form.reconciliatedTransactionsOnly.label"
                }}
                formValue={
                  this.state.form.settlement.reconciliatedTransactionsOnly
                }
              />
              <div className="form-group">
                <p className="control-label col-sm-3">
                  {t("customer.create.preview")} :
                </p>
                <span className="col-sm-6" style={styleForTextInformation}>
                  {this._previewFile()}
                </span>
              </div>
              <div className="form-group date">
                <label className="control-label col-sm-3">
                  <I18nSpan msgKey="customer.form.triggerTime.label" />
                  <sup className="required-label">*</sup>
                </label>
                <div className="col-sm-6">
                  <DateTime
                    locale={this.state.locale}
                    value={this.state.form.settlement.triggerTime}
                    onChange={this._onTimeChange}
                    dateFormat={false}
                    inputProps={{ required: true }}
                  />
                  <ErrorsList errors={this.state.errors.triggerTime} />
                </div>
              </div>
              <BootstrapInput
                inputRef={this.setInputRef("settlement.host")}
                name="settlement.host"
                onChange={this._onChangeHandler}
                errors={this.state.errors.settlement.host}
                required={true}
                validation={UserValidations.required}
                descriptor={{
                  type: "text",
                  label: "customer.form.host.label",
                  placeholder: "customer.form.host.placeholder",
                  validationType: "hostAndPort"
                }}
                formValue={this.state.form.settlement.host}
              />
              <BootstrapInput
                inputRef={this.setInputRef("settlement.path")}
                name="settlement.path"
                onChange={this._onChangeHandler}
                errors={this.state.errors.settlement.path}
                required={false}
                descriptor={{
                  type: "text",
                  label: "customer.form.path.label"
                }}
                formValue={this.state.form.settlement.path}
              />
              <BootstrapInput
                inputRef={this.setInputRef("settlement.user")}
                name="settlement.user"
                onChange={this._onChangeHandler}
                errors={this.state.errors.settlement.user}
                required={true}
                validation={UserValidations.required}
                descriptor={{
                  type: "text",
                  label: "customer.form.user.label"
                }}
                formValue={this.state.form.settlement.user}
              />
              <div className="form-group">
                <label className="control-label col-sm-3">
                  <I18nSpan msgKey="customer.form.key.label" />
                  <sup className="required-label">*</sup>
                </label>
                <div className="col-sm-6" style={styleForTextInformation}>
                  <input
                    name="key"
                    style={{ display: "inline-block" }}
                    className="mtn"
                    type="file"
                    required={true}
                    onChange={this._onSettlementKeyChange}
                  />
                  <p style={{ display: "inline-block" }}>
                    {this._getKeyMessage("settlement")}
                  </p>
                </div>
              </div>
            </div>
          </If>

          <BootstrapInput
            inputRef={this.setInputRef("alipayReconciliationConfiguration")}
            name="alipayReconciliationConfiguration"
            onChange={this._onChangeHandler}
            descriptor={{
              id: "alipayReconciliationConfiguration",
              type: "checkbox",
              className: "mtn",
              label: "customer.form.alipayReconciliationConfiguration.label"
            }}
            formValue={this.state.form.alipayReconciliationConfiguration}
          />

          <If test={this.state.form.alipayReconciliationConfiguration}>
            <div>
              <BootstrapInput
                inputRef={this.setInputRef("alipayReconciliation.partnerId")}
                name="alipayReconciliation.partnerId"
                onChange={this._onChangeHandler}
                errors={this.state.errors.alipayReconciliation.partnerId}
                validation={UserValidations.required}
                required={true}
                descriptor={{
                  type: "text",
                  label: "customer.form.partnerId.label"
                }}
                formValue={this.state.form.alipayReconciliation.partnerId}
              />
              <BootstrapInput
                inputRef={this.setInputRef("alipayReconciliation.email")}
                name="alipayReconciliation.email"
                onChange={this._onChangeHandler}
                errors={this.state.errors.alipayReconciliation.email}
                required={true}
                validation={UserValidations.required}
                descriptor={{
                  type: "email",
                  label: "customer.form.email.label",
                  placeholder: "customer.form.email.placeholder"
                }}
                formValue={this.state.form.alipayReconciliation.email}
              />
              <BootstrapInput
                inputRef={this.setInputRef("alipayReconciliation.host")}
                name="alipayReconciliation.host"
                onChange={this._onChangeHandler}
                errors={this.state.errors.alipayReconciliation.host}
                required={true}
                validation={UserValidations.required}
                descriptor={{
                  type: "text",
                  label: "customer.form.host.label",
                  placeholder: "customer.form.host.placeholder",
                  validationType: "hostAndPort"
                }}
                formValue={this.state.form.alipayReconciliation.host}
              />
              <BootstrapInput
                inputRef={this.setInputRef("alipayReconciliation.user")}
                name="alipayReconciliation.user"
                onChange={this._onChangeHandler}
                errors={this.state.errors.alipayReconciliation.user}
                required={true}
                validation={UserValidations.required}
                descriptor={{
                  type: "text",
                  label: "customer.form.user.label"
                }}
                formValue={this.state.form.alipayReconciliation.user}
              />
              <div className="form-group">
                <label className="control-label col-sm-3">
                  <I18nSpan msgKey="customer.form.key.label" />
                  <sup className="required-label">*</sup>
                </label>
                <div className="col-sm-6" style={styleForTextInformation}>
                  <input
                    name="key"
                    style={{ display: "inline-block" }}
                    className="mtn"
                    type="file"
                    required={true}
                    onChange={this._onAlipayReconciliationKeyChange}
                  />
                  <p style={{ display: "inline-block" }}>
                    {this._getKeyMessage("alipayReconciliation")}
                  </p>
                </div>
              </div>
            </div>
          </If>

          <div className="pull-right">
            <button
              onClick={this._saveCustomer}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    offers: { data: offers },
    smsProviders: { data: smsProviders },
    currencies: { data: currencies },
    permissionsForm: { data: permissions }
  } = state;

  const { i18n, t } = ownProps;

  const permissionOptions = permissions
    ? permissions.map(UserUtil.toMultipleSelectFormat)
    : [];

  const languageOptions = i18n.languages.map(l => ({
    id: l,
    name: t(`language.${l}`)
  }));

  const transactionFieldsData = SettlementFields.map(field => ({
    id: field,
    name: t(`transaction.${field}.label`)
  }));

  const currencyOptions = currencies.map((currency: any) => ({
    value: currency.alpha3,
    label: `${currency.alpha3} - ${currency.num}`
  }));

  const offerOptions = offers.map((offer: any) => ({
    value: offer.id,
    label: offer.name
  }));

  const smsProvidersOptions = smsProviders.map((smsProvider: any) => ({
    value: smsProvider,
    label: smsProvider
  }));

  const hasCustomerUserManagement = _.includes(
    user.permissions,
    Permissions.CUSTOMER_USER_MANAGEMENT
  );

  return {
    user,
    currencies,
    options: {
      currencies: currencyOptions,
      offers: offerOptions,
      permissions: permissionOptions,
      languages: languageOptions,
      transactionFields: transactionFieldsData,
      fileFormats: SettlementFileFormatConstants,
      smsProviders: smsProvidersOptions
    },
    currentUser: {
      hasCustomerUserManagement
    }
  };
};

const mapDispatchToProps = dispatch => ({
  saveCustomer: ({ customer }) => dispatch(createCustomer({ customer })),
  getOffers: () => dispatch(getOffers()),
  getCurrencies: () => dispatch(getCurrencies()),
  getSmsProviders: () => dispatch(getSmsProviders()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getPermissionsForm: ({ restrictionLevel }) =>
    dispatch(getPermissionsForm({ restrictionLevel }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateCustomerView);
