import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import { constantCase } from "constant-case";

export const editAlert = ({ sectionType, id, threshold }) => async dispatch => {
  const name = constantCase(sectionType);
  dispatch(editAlertStart({ name }));

  return F.put(`/${sectionType}-alert/${id}/threshold`, {
    body: threshold,
    contentType: ContentTypes.TEXT
  }).then(
    () => dispatch(editAlertSucceeded({ name })),
    errors => {
      dispatch(editAlertFailure({ name, errors }));

      throw errors;
    }
  );
};

export const editAlertStart = ({ name }) => ({
  type: `PENDING_UPDATE_${name}_ALERT_ACTION`
});

export const editAlertSucceeded = ({ name }) => ({
  type: `SUCCESS_UPDATE_${name}_ALERT_ACTION`
});

export const editAlertFailure = ({ name, errors }) => ({
  type: `FAILURE_UPDATE_${name}_ALERT_ACTION`,
  payload: {
    errors
  }
});

export const updateAlertStatus = ({
  sectionType,
  id,
  status
}) => async dispatch => {
  const name = constantCase(sectionType);
  dispatch(updateAlertStatusStart({ name }));

  return F.put(`/${sectionType}-alert/${id}/active`, {
    body: status.toString(),
    contentType: ContentTypes.TEXT
  }).then(
    () => dispatch(updateAlertStatusSucceeded({ name, id, status })),
    errors => {
      dispatch(updateAlertStatusFailure({ name, errors }));

      throw errors;
    }
  );
};

export const updateAlertStatusStart = ({ name }) => ({
  type: `PENDING_UPDATE_STATUS_${name}_ALERT_ACTION`
});

export const updateAlertStatusSucceeded = ({ name, id, status }) => ({
  type: `SUCCESS_UPDATE_STATUS_${name}_ALERT_ACTION`,
  payload: {
    id,
    status
  }
});

export const updateAlertStatusFailure = ({ name, errors }) => ({
  type: `FAILURE_UPDATE_STATUS_${name}_ALERT_ACTION`,
  payload: {
    errors
  }
});
