const loadScript = (configuration, cscPortalURL) => {
  const script = document.createElement("script");
  script.onload = () => {
    const widget = cscWidget.widget.new();
    widget.render(configuration);
  };
  script.src = `${cscPortalURL}/csc-widget/bundle.js`;

  document.head.appendChild(script);
};

export const loadWidget = (configuration = {}, cscPortalURL) =>
  loadScript(configuration, cscPortalURL);
