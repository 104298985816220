import React from "react";
import {
  CreatePayThenRefundAlert,
  PayThenRefundAlert
} from "../../model/PayThenRefundAlert";
import I18nSpan from "../../../../i18n/components/I18nSpan";

interface Props {
  onChange: (alert: CreatePayThenRefundAlert) => void;
}

interface State {
  maxMinutesIn48Hours?: number;
  minMinutes?: number;
  alert?: CreatePayThenRefundAlert;
}

export default class PayThenRefund extends React.Component<Props, State> {
  payThenRefundAlert = new CreatePayThenRefundAlert();

  state = {
    maxMinutesIn48Hours: 2880,
    minMinutes: 1,
    alert: this.payThenRefundAlert
  };

  private onChange = e => {
    const payThenRefundAlert: CreatePayThenRefundAlert = this.state.alert;
    const numberOfSeconds =
      e.target.value === "0" || e.target.value.trim() === ""
        ? this.state.minMinutes
        : e.target.value;
    payThenRefundAlert.setDuration(parseInt(numberOfSeconds));
    this.setState(
      {
        alert: payThenRefundAlert
      },
      () => this.props.onChange(payThenRefundAlert)
    );
  };

  private onNumberInputClick = (e: Event) => {
    const input = e.target as HTMLInputElement;
    input.select();
  };

  render() {
    return (
      <div id="alert-pay-then-refund" className="alert-item">
        <I18nSpan
          msgKey={`alert.predefined.${PayThenRefundAlert.I18N_KEY}.create-1`}
        />
        <input
          type="number"
          name="nbMinutes"
          value={this.state.alert.duration.asMinutes()}
          onChange={this.onChange}
          onClick={this.onNumberInputClick}
          min={this.state.minMinutes}
          max={this.state.maxMinutesIn48Hours}
        />
        <I18nSpan
          msgKey={`alert.predefined.${PayThenRefundAlert.I18N_KEY}.create-2`}
        />
      </div>
    );
  }
}
