import {
  PENDING_GET_TIMEZONES_ACTION,
  SUCCESS_GET_TIMEZONES_ACTION,
  FAILURE_GET_TIMEZONES_ACTION,
  RESET_TIMEZONES_STATE_ACTION
} from "../actionsTypes";

export const getTimezones = () => async dispatch => {
  dispatch(getTimezonesStart());

  const { default: F } = await import("../../commons/HTTPFetcher");

  return F.get("/timezones")
    .then(timezones => {
      dispatch(getTimezonesSucceeded(timezones));

      return timezones;
    })
    .catch(errors => {
      dispatch(getTimezonesFailure({ errors }));
      throw errors;
    });
};

const getTimezonesStart = () => ({
  type: PENDING_GET_TIMEZONES_ACTION
});

const getTimezonesSucceeded = timezones => ({
  type: SUCCESS_GET_TIMEZONES_ACTION,
  payload: {
    timezones
  }
});

const getTimezonesFailure = errors => ({
  type: FAILURE_GET_TIMEZONES_ACTION,
  payload: {
    errors
  }
});

export const resetTimezonesState = () => ({
  type: RESET_TIMEZONES_STATE_ACTION
});
