import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import NewPasswordInput from "./NewPasswordMobileInput";
import PasswordInput from "./PasswordMobileInput";
import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "../styles/styles.mobile.css";

interface Props {
  addNotificationSuccess: Function;
  addNotificationError: Function;
  resetPassword: Function;
  history: any;
  token: any;
}

class ResetPasswordMobileView extends Component<Props, {}> {
  render() {
    const {
      t,
      password,
      repeatPassword,
      showPassword,
      onInputChange,
      onShowPassword
    } = this.props;

    return (
      <div className={styles["login-form-wrapper"]}>
        <NewPasswordInput
          label={t("resetPassword.form.new")}
          showPassword={showPassword}
          onShowPassword={onShowPassword}
          onChange={onInputChange}
          value={password}
        />
        <PasswordInput
          name="repeatPassword"
          label={t("resetPassword.form.repeatNew")}
          showPassword={showPassword}
          onShowPassword={onShowPassword}
          onChange={onInputChange}
          value={repeatPassword}
        />

        <div className={styles["submit-container"]}>
          <input
            type="submit"
            className={styles.buttonlink}
            value={t("resetPassword.form.submit")}
          />
        </div>
        <div className={styles["small-link"]}>
          <Link to="/authenticate" className={styles.link}>
            <I18nSpan msgKey="resetPassword.form.back" />
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ResetPasswordMobileView);
