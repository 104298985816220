import { v4 as uuidv4 } from "uuid";
import * as openpgp from "openpgp/dist/compat/openpgp"; // https://github.com/openpgpjs/openpgpjs/issues/408#issuecomment-200559285
import OpenPGPWorker from "worker-loader!openpgp/dist/compat/openpgp.worker";

interface Params {
  userId: string;
  userLevel: string;
  value: string;
  pubKey: string;
}

export const encryptValue = async ({
  userId,
  userLevel,
  value,
  pubKey
}: Params) => {
  try {
    if (pubKey) {
      new OpenPGPWorker(); // set the relative web worker path

      const payload = JSON.stringify({
        id: userId,
        type: userLevel,
        value,
        random: uuidv4()
      });

      const openpgpEncrypt = async (plaintext: string) => {
        const encOpt = async t => ({
          message: openpgp.message.fromText(t),
          publicKeys: (await openpgp.key.readArmored(pubKey)).keys
        });

        const readableStream = new ReadableStream({
          start(controller) {
            controller.enqueue(plaintext);
            controller.close();
          }
        });
        const options = await encOpt(readableStream);
        const { data } = await openpgp.encrypt(options);

        return await openpgp.stream.readToEnd(data);
      };

      return await openpgpEncrypt(payload);
    }
  } catch {
    throw { key: "securedVariable.validation.value.publicNotFound" };
  }
};
