import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_OFFERS_ACTION,
  SUCCESS_GET_OFFERS_ACTION,
  FAILURE_GET_OFFERS_ACTION
} from "../actionsTypes";

export const getOffers = () => async dispatch => {
  dispatch(getOffersStart());

  return F.get("/offer").then(
    offers => {
      dispatch(getOffersSucceeded(offers));
      return offers;
    },
    errors => {
      dispatch(getOffersFailure({ errors }));

      throw errors;
    }
  );
};

const getOffersStart = () => ({
  type: PENDING_GET_OFFERS_ACTION
});

const getOffersSucceeded = offers => ({
  type: SUCCESS_GET_OFFERS_ACTION,
  payload: {
    offers
  }
});

const getOffersFailure = errors => ({
  type: FAILURE_GET_OFFERS_ACTION,
  payload: {
    errors
  }
});
