import React from "react";
import * as momentTimezone from "moment-timezone";
import { withTranslation } from "react-i18next";

import Utils from "../../sim/Utils";
import NumberFormatter from "../../formatters/NumberFormatter";
import { LoaderChart } from "../Loader/Loader";
import AmountApexChart from "../../chart/components/chart/AmountApexChart";

interface Props {
  loading: boolean;
  error?: boolean;
  title: string;
  color: string;
  value: number;
  renderTrend: Function;
  currency: any;
}

const _buildConfigFromProps = ({
  timestamps,
  currency,
  currencyDecimal,
  currencyCodeAlpha3,
  t,
  timePeriod,
  transactions,
  color,
  palette,
  palettes,
  timezone
}) => {
  const { min, max } = timestamps;

  const initialPalette = Utils.findPaletteById(palette, palettes);

  const currencySymbol = `${currencyCodeAlpha3}${currency}`;

  const chartPrefix = `reporting.activity.transactions.${timePeriod.type}`;

  return {
    title: {
      text: ""
    },
    dataLabels: {
      enabled: false
    },
    noData: {
      text: t("apexCharts.noData")
    },
    xaxis: {
      type: "datetime",
      title: {
        text: t(`${chartPrefix}.xAxis.title`),
        offsetY: 10
      },
      min,
      max,
      labels: {
        rotate: -45,
        style: {
          colors: "black",
          fontFamily: "tahoma"
        }
      }
    },
    yaxis: {
      title: {
        text: t("reporting.activity.transactions.yAxis.title")
      },
      labels: {
        formatter: function(value) {
          return NumberFormatter.formatAmount(
            value,
            currencySymbol,
            currencyDecimal
          );
        }
      }
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return NumberFormatter.formatAmount(
            value,
            currencySymbol,
            currencyDecimal
          );
        }
      }
    },
    series: transactions.map(({ data }) => {
      // https://github.com/apexcharts/apexcharts.js/issues/631#issuecomment-611593641
      const dataWithLocalTimestamp = data.map(([timestamp, value]) => {
        const utcOffset =
          momentTimezone.tz(timestamp, timezone).utcOffset() * 60000;
        const localizedTimestamp = timestamp + utcOffset;

        return [localizedTimestamp, value];
      });

      return {
        data: dataWithLocalTimestamp,
        name: t(`${chartPrefix}.bar.description`)
      };
    }),
    ...(initialPalette && { colors: [...initialPalette.colors] })
  };
};

const TransactionRevenueChart = ({
  title,
  color,
  error,
  loading,
  values,
  timestamps,
  formatter,
  currency: userCurrency,
  renderTrend,
  t,
  palette,
  palettes,
  timePeriod,
  timezone
}: Props) => {
  const { currency, currencyDecimal, currencyCodeAlpha3 } = userCurrency;

  const transactions = [
    {
      data: values.map(point => [point.timestamp, point.value])
    }
  ];

  const { series, ...options } = _buildConfigFromProps({
    timestamps,
    currency,
    currencyDecimal,
    currencyCodeAlpha3,
    t,
    timePeriod,
    currencyDecimal,
    transactions,
    color,
    palette,
    palettes,
    timezone
  });

  return (
    <LoaderChart loading={loading} color={color}>
      <AmountApexChart options={options} series={series} type="bar" />
      <style>
        {`
                  /*Style loader*/
                  .load-bar,
                  .spinner .bounce1,
                  .spinner .bounce3,
                  .load-bar .bar2 {background-color:${color}!important;}
                  .spinner .bounce2{background-color:#FFFFFF!important;}
                  .load-bar .bar1 {background-color:${color};}
                  .load-bar .bar3 {background-color:#D2D7D3;}

              `}
      </style>
    </LoaderChart>
  );
};

export default withTranslation()(TransactionRevenueChart);
