import React from "react";

import MatchMobileContainer from "../../gridCharts/MatchMobileContainer";
import EditCurrentUserDesktopView from "./EditCurrentUserDesktopView";
import EditCurrentUserMobileView from "../../components/EditCurrentUserMobile/EditCurrentUserMobileView";

const EditCurrentUserView = () => (
  <div>
    <MatchMobileContainer
      renderMobile={() => <EditCurrentUserMobileView />}
      renderDesktop={() => <EditCurrentUserDesktopView />}
    />
  </div>
);

export default EditCurrentUserView;
