import React, { Component } from "react";
import I18nSpan from "../../i18n/components/I18nSpan";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

import styles from "../styles/styles.css";

interface Props {
  onClickButton: Function;
  onClosePopin: Function;
  t: Function;
}

interface State {
  form: any;
}

class SessionClosePopin extends Component<Props, State> {
  state = {
    form: {
      reason: "",
      reasonMessage: ""
    }
  };

  _onChangeHandler = (name, value) =>
    this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value
        }
      };
    });

  _mapReasons = () => {
    const { t } = this.props;
    return {
      deviceLost: t("taxAudit.sessions.reason.deviceLost"),
      mobileApplicationDeinstalled: t(
        "taxAudit.sessions.reason.mobileApplicationDeinstalled"
      ),
      customReason: t("taxAudit.sessions.reason.customReason")
    };
  };

  render() {
    const { onClosePopin, onClickButton } = this.props;

    return (
      <div>
        <div className="popin">
          <div className="popinContent">
            <a onClick={onClosePopin} className="closeButton" />
            <div className="popinTitle">
              <I18nSpan msgKey="taxAudit.sessions.popin.title" />
            </div>
            <I18nSpan msgKey="taxAudit.sessions.popin.message" />

            <div className={styles["reasonSelector"]}>
              <BootstrapInput
                onChange={this._onChangeHandler}
                name="reason"
                required={true}
                descriptor={{
                  type: "select",
                  options: this._mapReasons(),
                  label: "taxAudit.sessions.popin.reason"
                }}
                formValue={this.state.form.reason}
              />

              {this.state.form.reason === "customReason" && (
                <BootstrapInput
                  onChange={this._onChangeHandler}
                  name="reasonMessage"
                  required={false}
                  descriptor={{
                    type: "textarea"
                  }}
                  formValue={this.state.form.reasonMessage}
                />
              )}
            </div>

            <div className="button-list">
              <li>
                <a onClick={onClosePopin} className="btn btn-ingenico">
                  <I18nSpan msgKey="taxAudit.sessions.popin.button.cancel" />
                </a>
              </li>
              <li>
                <a
                  onClick={() =>
                    onClickButton({
                      reason: this.state.form.reason,
                      reasonMessage: this.state.form.reasonMessage
                    })
                  }
                  className="btn btn-ingenico"
                >
                  <I18nSpan msgKey="taxAudit.sessions.popin.button.close" />
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(SessionClosePopin);
