import React, { Component } from "react";
import { connect } from "react-redux";

import KeyIndicator from "./KeyIndicator";
import I18nNumber from "../../../i18n/components/I18nNumber";
import { WidgetConfig } from "../Widget";
import { getData } from "../../utils/DataProvider";

import styles from "./style/TerminalConnections.css";

interface Data {
  volumes: Array<any>;
}

interface Props {
  getData: Function;
  data: Data;
  loading: boolean;
  error?: boolean;
  title: string;
  color: string;
  id: string;
  config: WidgetConfig;
}

const TerminalContract = ({
  count,
  color,
  total,
  contract,
  loading,
  error
}: any) => {
  return (
    <KeyIndicator
      color={color}
      titleKey={`poi.contract.${contract}`}
      loading={loading}
      error={error}
    >
      <div>
        <I18nNumber number={count} format="0,0" />
        <span> / </span>
        <I18nNumber number={total} format="0,0" />
      </div>
    </KeyIndicator>
  );
};

const TerminalsContract = ({ volumes, error, color, loading }: any) => {
  if (volumes.length === 0) {
    return (
      <TerminalContract
        count={0}
        color={color}
        total={0}
        contract={"notFound"}
        loading={loading}
        error={error}
      />
    );
  }
  return (
    <div className={styles.container}>
      {volumes.map(aggregation => {
        const {
          key: contract = "",
          doc_count: count,
          pois: { value: poisValue }
        } = aggregation;
        const total = poisValue * contract;

        return (
          <TerminalContract
            key={contract}
            count={count}
            color={color}
            total={total}
            contract={contract}
            loading={loading}
            error={error}
          />
        );
      })}
    </div>
  );
};

class TerminalConnections extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { user } = config;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ user, config });
    }
  }

  render() {
    const { data, color, loading, error } = this.props;

    const { volumes = [] } = data;

    return (
      <TerminalsContract
        volumes={volumes}
        error={error}
        color={color}
        loading={loading}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }

  return {
    data: {},
    loading: true
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TerminalConnections);
