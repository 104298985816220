// @secure
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";
import parseFormat from "moment-parseformat";

const Dateformats = {
  formats: {
    locale: {
      dateTime: "L LTS",
      date: "L"
    },
    iso: {
      dateTime: "YYYY-MM-DD'T'HH:mm:ssZ",
      date: "YYYY-MM-DD"
    },
    jn: {
      dateTime: "YYYY/MM/DD HH:mm:ss",
      date: "YYYY/MM/DD"
    },
    eu: {
      dateTime: "DD/MM/YYYY HH:mm:ss",
      date: "DD/MM/YYYY"
    },
    range: {
      dateTime: "YYYY-MM-DDTHH:mm:ss.SSS"
    }
  }
};

export { Dateformats };

export default {
  formatDateTime(datetime, user) {
    return datetime !== null && datetime !== "" && user
      ? moment(datetime).format(this.getDateFormat(user))
      : "";
  },

  formatDateTimeUtc(datetime, user) {
    return datetime !== null && datetime !== "" && user
      ? moment.utc(datetime).format(this.getDateFormat(user))
      : "";
  },

  formatAndLocalizeDateTime(datetime, user) {
    const timezone = user ? user.timezone : "";
    const localizedDateTime: Moment = momentTimezone.tz(
      datetime,
      "YYYY-MM-DD'T'HH:mm:ss",
      timezone
    );

    return datetime && user
      ? moment(localizedDateTime)
          .locale(window.navigator.language)
          .format(this.getDateFormat(user))
      : "";
  },

  getDateFormat(user, withTime = true) {
    const userFormat = user && user.dateFormat ? user.dateFormat : "locale"; //Locale by default
    const { formats } = Dateformats;
    return withTime ? formats[userFormat].dateTime : formats[userFormat].date;
  },

  formatAndLocalizeDateTimeNew({
    datetime,
    timezone = "",
    dateFormat,
    user
  }: any) {
    // const timezone = user ? user.timezone : "";
    const localizedDateTime = momentTimezone.tz(
      datetime,
      "YYYY-MM-DD'T'HH:mm:ss",
      timezone
    );

    return datetime
      ? moment(localizedDateTime)
          .locale(window.navigator.language)
          .format(this.getDateFormatNew(dateFormat))
      : "";
  },

  getDateFormatNew({ dateFormat = "locale", withTime = true }: any) {
    // const userFormat = dateFormat ? dateFormat : ; //Locale by default
    const { formats } = Dateformats;

    return withTime ? formats[dateFormat].dateTime : formats[dateFormat].date;
  },

  getDateFormatPattern(user) {
    const userFormat = user && user.dateFormat ? user.dateFormat : "locale"; //Locale by default
    const { formats } = Dateformats;

    if (userFormat === "locale") {
      return parseFormat(moment.utc().format("L LTS"), null);
    }

    return formats[userFormat].dateTime;
  },

  getTimezonedDate(datetime: moment.Moment, user) {
    // Change date depending on user's timezone
    // See http://stackoverflow.com/questions/36845923/moment-js-timezone-valueof-returning-wrong-timestamp
    if (user) {
      let clonedDate = datetime.clone();
      //Compute timestamp difference due to user timezone
      const browserOffset = clonedDate
        .clone()
        .local()
        .utcOffset();
      const dateOffset = clonedDate.utcOffset();
      clonedDate = clonedDate.add(browserOffset - dateOffset, "minutes");

      return clonedDate.valueOf();
    } else {
      return datetime.valueOf();
    }
  }
};
