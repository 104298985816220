import Grid from "@material-ui/core/Grid/Grid";
import LoadingChart from "../../home/components/widgets/LoadingChart";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default props => {
  const useStylesGrid = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "50%"
      },
      rootInit: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "33%"
      },
      rootSetup: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
      },
      container: {
        height: "100%"
      },
      containerSetup: {
        flexWrap: "nowrap",
        height: "14.36vh"
      },
      setupText: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        fontWeight: 700
      },
      containerSetupButton: {
        padding: "0 4vw 2.07vh 4vw"
      },
      containerSetupButtonSetGoals: {
        padding: "0"
      }
    })
  );

  const classesGrid = useStylesGrid();

  return (
    <Grid
      alignItems="center"
      direction="column"
      justify="center"
      container
      className={classesGrid.container}
    >
      <LoadingChart loading={props.loading} color={props.color} />
    </Grid>
  );
};
