import _ from "lodash";

import {
  FAILURE_AUTH_ACTION,
  PENDING_AUTH_ACTION,
  SUCCESS_AUTH_ACTION,
  PENDING_GET_2FA_PAIR_ACTION,
  SUCCESS_GET_2FA_PAIR_ACTION,
  FAILURE_GET_2FA_PAIR_ACTION,
  FAILURE_REFRESH_AUTH_ACTION,
  PENDING_REFRESH_AUTH_ACTION,
  SUCCESS_REFRESH_AUTH_ACTION,
  SUCCESS_IMPERSONATE_ACTION,
  PENDING_IMPERSONATE_ACTION,
  FAILURE_IMPERSONATE_ACTION,
  SET_OAUTH_REDIRECT_URI_ACTION,
  PENDING_GET_USER_SETTINGS_ACTION,
  SUCCESS_GET_USER_SETTINGS_ACTION,
  SUCCESS_UPDATE_USER_SETTINGS_ACTION,
  FAILURE_GET_USER_SETTINGS_ACTION,
  PENDING_GET_LEVEL_SETTINGS_ACTION,
  SUCCESS_GET_LEVEL_SETTINGS_ACTION,
  FAILURE_GET_LEVEL_SETTINGS_ACTION,
  SUCCESS_REVOKE_ACTION,
  SUCCESS_UPDATE_CUSTOMER_SETTINGS_ACTION,
  FAILURE_RESET_PASSWORD_ACTION,
  FAILURE_UPDATE_PASSWORD_ACTION
} from "../actionsTypes";
import {
  AUTHENTICATION_STORAGE_KEY,
  AUTHENTICATION_STORAGE_LEVEL_SETTINGS,
  OAUTH_REDIRECT_URI_STORAGE_KEY,
  OAUTH_STATE_STORAGE_KEY,
  OAUTH_TOKEN_STORAGE_KEY,
  OAUTH_TOKEN_EXPIRES_AT_STORAGE_KEY
} from "../actions/auth";
import constants from "../../connection/constants/ConnectionConstant";

const defaultUser = {
  scope: {
    access: {},
    level: {}
  }
};

export const getUser = () => {
  const user = localStorage.getItem(AUTHENTICATION_STORAGE_KEY);

  const currentUser = user ? JSON.parse(user) : null;

  return currentUser || defaultUser;
};

export const getLevelSettings = () => {
  const levelSettings = localStorage.getItem(
    AUTHENTICATION_STORAGE_LEVEL_SETTINGS
  );
  const currentLevelSettings = levelSettings ? JSON.parse(levelSettings) : {};

  return currentLevelSettings;
};

const getToken = () => {
  const token = localStorage.getItem(OAUTH_TOKEN_STORAGE_KEY);
  const currentToken = token ? JSON.parse(token) : {};

  return currentToken;
};

const getTokenExpiresAt = () => {
  const tokenExpiresAt = localStorage.getItem(
    OAUTH_TOKEN_EXPIRES_AT_STORAGE_KEY
  );
  const currentTokenExpiresAt = tokenExpiresAt
    ? parseInt(tokenExpiresAt, 10)
    : 0;

  return currentTokenExpiresAt;
};

const getLevelType = (): string | null => {
  const user = getUser();

  if (!user) {
    return null;
  }

  return user.scope.level.type;
};

const defaultRedirectUri = `${window.location.origin}/main`;
const redirectUri =
  localStorage.getItem(OAUTH_REDIRECT_URI_STORAGE_KEY) || defaultRedirectUri;
const clientId = `portal${
  process.env.NODE_ENV !== "production" ? "-local" : ""
}`;
const oauthState = localStorage.getItem(OAUTH_STATE_STORAGE_KEY) || "";

const initialState = () => ({
  user: getUser(),
  levelSettings: getLevelSettings(),
  oauth: {
    redirectUri,
    oauthState,
    clientId
  },
  "2fa": {},
  token: getToken(),
  tokenExpiresAt: getTokenExpiresAt(),
  isIngenico: getLevelType() === constants.INGENICO,
  isCustomer: getLevelType() === constants.CUSTOMER,
  isMerchant: getLevelType() === constants.MERCHANT,
  isRestrictedByIds: _.has(getUser().scope.access, "restrictedByIds"),
  isRestrictedByTags: _.has(getUser().scope.access, "restrictedByTags"),
  loading: false,
  error: false,
  errors: {}
});

export const defaultInitialState = initialState();

const AuthReducer = (state = defaultInitialState, action: any) => {
  switch (action.type) {
    case PENDING_AUTH_ACTION:
    case PENDING_REFRESH_AUTH_ACTION:
    case PENDING_IMPERSONATE_ACTION:
    case PENDING_GET_USER_SETTINGS_ACTION:
    case PENDING_GET_LEVEL_SETTINGS_ACTION: {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_AUTH_ACTION:
    case SUCCESS_REFRESH_AUTH_ACTION:
    case SUCCESS_IMPERSONATE_ACTION: {
      const {
        payload: { token, tokenExpiresAt }
      } = action;

      return {
        ...state,
        token,
        tokenExpiresAt,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_2FA_PAIR_ACTION: {
      const {
        payload: { pair }
      } = action;

      return {
        ...state,
        "2fa": {
          ...state["2fa"],
          pair
        },
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_USER_SETTINGS_ACTION:
    case SUCCESS_UPDATE_USER_SETTINGS_ACTION:
      const {
        payload: { user }
      } = action;
      const levelType = getLevelType(user);

      return {
        ...state,
        user,
        isIngenico: levelType === constants.INGENICO,
        isCustomer: levelType === constants.CUSTOMER,
        isMerchant: levelType === constants.MERCHANT,
        error: false,
        errors: {}
      };
    case SUCCESS_GET_LEVEL_SETTINGS_ACTION:
      const {
        payload: { levelSettings }
      } = action;

      return {
        ...state,
        levelSettings,
        error: false,
        errors: {}
      };
    case SUCCESS_UPDATE_CUSTOMER_SETTINGS_ACTION:
      const {
        payload: { settings: customerSettings }
      } = action;

      return {
        ...state,
        levelSettings: {
          ...state.levelSettings,
          ...customerSettings
        },
        error: false,
        errors: {}
      };
    case FAILURE_AUTH_ACTION:
    case FAILURE_GET_USER_SETTINGS_ACTION:
    case FAILURE_GET_LEVEL_SETTINGS_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        user: defaultUser,
        loading: false,
        error: true,
        errors
      };
    }
    case SET_OAUTH_REDIRECT_URI_ACTION: {
      const {
        payload: { redirectUri, oauthState }
      } = action;

      return {
        ...state,
        oauth: {
          ...state.oauth,
          redirectUri,
          oauthState
        },
        error: false,
        errors: {}
      };
    }
    case SUCCESS_REVOKE_ACTION: {
      return {
        ...state,
        oauth: {
          ...state.oauth,
          redirectUri: defaultRedirectUri
        },
        error: false,
        errors: {}
      };
    }
    case FAILURE_REFRESH_AUTH_ACTION: {
      return initialState();
    }
    default:
      return state;
  }
};

export default AuthReducer;
