import React from "react";
import classNames from "classnames";

import styles from "./Loader.css";

import LoaderChartStyle from "./LoaderChart.css";

export default ({ loading, children, color }: any) => {
  if (loading) {
    return (
      <div className={classNames(styles.loading)}>
        <div className={classNames("loadingTxt-custom", styles.loadingTxt)}>
          <div className={styles.spinner}>
            <div className={styles["double-bounce1"]} />
            <div className={styles["double-bounce2"]} />
          </div>
          LOADING •••
        </div>
        <style>
          {`
                    /* Style loader */
              .loadingTxt-custom {color: ${color}!important;}
            `}
        </style>
      </div>
    );
  }
  return children;
};

interface Props {
  loading?: boolean;
  color?: string;
  children?: any;
}

export const LoaderChart = ({ loading, color, children }: Props) => {
  if (loading) {
    return (
      <div className={LoaderChartStyle.container}>
        <div className={classNames(LoaderChartStyle.loading)}>
          <div
            className={classNames(
              "loadingTxt-custom",
              LoaderChartStyle.loadingTxt
            )}
          >
            <div
              className={classNames("spinner-custom", LoaderChartStyle.spinner)}
            >
              <div
                className={classNames(
                  "bounce1-custom",
                  LoaderChartStyle.bounce1
                )}
              />
              <div
                className={classNames(
                  "bounce2-custom",
                  LoaderChartStyle.bounce2
                )}
              />
              <div
                className={classNames(
                  "bounce3-custom",
                  LoaderChartStyle.bounce3
                )}
              />
            </div>
          </div>
        </div>
        <style>
          {`
                    /* Style loader */
               .loadingTxt-custom {color: ${color}!important;}
              .load-bar,
              .spinner-custom .bounce1-custom,
              .spinner-custom .bounce2-custom,
              .spinner-custom .bounce3-custom,
              .load-bar .bar2 {background-color:${color}!important;}
              .spinner .bounce2{background-color:#FFFFFF!important;}
              .load-bar .bar1 {background-color:#BDC3C7;}
              .load-bar .bar3 {background-color:#D2D7D3;}

            `}
        </style>
      </div>
    );
  }

  return children;
};
