import { combineReducers } from "redux";

import authReducer from "./auth";
import tagReducer from "./tag";
import tagsReducer from "./tags";
import merchantsReducer from "./merchants";
import merchantReducer from "./merchant";
import fieldsReducer from "./fields";
import catalogItemsReducer from "./catalogItems";
import catalogItemReducer from "./catalogItem";
import vatRatesReducer from "./vatRates";
import vatRateReducer from "./vatRate";
import widgetsReducer from "./widgets";
import widgetDataReducer from "./widgetData";
import storesReducer from "./stores";
import countriesReducer from "./countries";
import userSettingsReducer from "./userSettings";
import reducerSimsActivities from "./simsActivities";
import timezonesReducer from "./timezones";
import programsReducer from "./programs";
import possReducer from "./poss";
import posReducer from "./pos";
import programReducer from "./program";
import merchantCategoryCodesReducer from "./merchantCategoryCodes";
import themeReducer from "./theme";
import poisReducer from "./pois";
import poiReducer from "./poi";
import widgetTrendReducer from "./widgetTrend";
import simsReducer from "./sims";
import simReducer from "./sim";
import terminalActivitiesReducer from "./terminalActivities";
import salesReducer from "./sales";
import transactionsReducer from "./transactions";
import reconciliationsReducer from "./reconciliations";
import offersReducer from "./offers";
import offerReducer from "./offer";
import schemesReducer from "./schemes";
import customersReducer from "./customers";
import customerReducer from "./customer";
import auditsReducer from "./audits";
import autoinitProgramReducer from "./autoinitProgram";
import usersReducer from "./users";
import userReducer from "./user";
import storeReducer from "./store";
import notificationReducer from "./notification";
import terminalAlertsReducer from "./terminalAlerts";
import simAlertsReducer from "./simAlerts";
import templatesReducer from "./templates";
import templateReducer from "./template";
import menuReducer from "./menu";
import predefinedAlertsReducer from "./predefinedAlerts";
import { configReducer } from "./config";
import servicesReducer from "./services";
import transactionAlertsReducer from "./transactionAlerts";
import transactionAlertReducer from "./transactionAlert";
import scheduledReportsReducer from "./scheduledReports";
import schedulerJobsReducer from "./schedulerJobs";
import versionReducer from "./version";
import smsProvidersReducer from "./smsProviders";
import commentReducer from "./comment";
import currenciesReducer from "./currencies";
import searchContextReducer from "./searchContext";
import permissionsFormReducer from "./permissions";
import palettesReducer from "./palettes";
import secondFactorsReducer from "./secondFactors";
import taxAuditsReducer from "./taxAudits";
import taxAuditLogReducer from "./logs";
import sessionsReducer from "./sessions";

export default combineReducers({
  auth: authReducer,
  tags: tagsReducer,
  tag: tagReducer,
  merchants: merchantsReducer,
  merchant: merchantReducer,
  genericFields: fieldsReducer,
  catalogItems: catalogItemsReducer,
  catalogItem: catalogItemReducer,
  vatRates: vatRatesReducer,
  vatRate: vatRateReducer,
  widgets: widgetsReducer,
  widgetData: widgetDataReducer,
  stores: storesReducer,
  countries: countriesReducer,
  userSettings: userSettingsReducer,
  simsActivities: reducerSimsActivities,
  timezones: timezonesReducer,
  poss: possReducer,
  pos: posReducer,
  programs: programsReducer,
  program: programReducer,
  merchantCategoryCodes: merchantCategoryCodesReducer,
  theme: themeReducer,
  pois: poisReducer,
  poi: poiReducer,
  widgetTrend: widgetTrendReducer,
  sims: simsReducer,
  terminalActivities: terminalActivitiesReducer,
  sales: salesReducer,
  taxAudits: taxAuditsReducer,
  logs: taxAuditLogReducer,
  transactions: transactionsReducer,
  reconciliations: reconciliationsReducer,
  offers: offersReducer,
  offer: offerReducer,
  schemes: schemesReducer,
  customers: customersReducer,
  customer: customerReducer,
  audits: auditsReducer,
  autoinitProgram: autoinitProgramReducer,
  sim: simReducer,
  users: usersReducer,
  user: userReducer,
  store: storeReducer,
  notification: notificationReducer,
  terminalAlerts: terminalAlertsReducer,
  simAlerts: simAlertsReducer,
  templates: templatesReducer,
  template: templateReducer,
  menu: menuReducer,
  predefinedAlerts: predefinedAlertsReducer,
  services: servicesReducer,
  config: configReducer,
  transactionAlerts: transactionAlertsReducer,
  transactionAlert: transactionAlertReducer,
  scheduledReports: scheduledReportsReducer,
  schedulerJobs: schedulerJobsReducer,
  version: versionReducer,
  smsProviders: smsProvidersReducer,
  comment: commentReducer,
  currencies: currenciesReducer,
  searchContext: searchContextReducer,
  permissionsForm: permissionsFormReducer,
  palettes: palettesReducer,
  secondFactors: secondFactorsReducer,
  sessions: sessionsReducer
} as any);
