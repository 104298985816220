import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_MERCHANT_CATEGORY_CODE_ACTION,
  SUCCESS_GET_MERCHANT_CATEGORY_CODE_ACTION,
  FAILURE_GET_MERCHANT_CATEGORY_CODE_ACTION
} from "../actionsTypes";

export const getMerchantCategoryCodes = () => async dispatch => {
  dispatch(getMerchantCategoryCodesStart());

  try {
    const merchantCategoryCodes = await F.get("/merchantCategoryCodes");

    return dispatch(getMerchantCategoryCodesSucceeded(merchantCategoryCodes));
  } catch (errors) {
    dispatch(getMerchantCategoryCodesFailure({ errors }));

    throw errors;
  }
};

export const getMerchantCategoryCodesStart = () => ({
  type: PENDING_GET_MERCHANT_CATEGORY_CODE_ACTION
});

export const getMerchantCategoryCodesSucceeded = merchantCategoryCodes => ({
  type: SUCCESS_GET_MERCHANT_CATEGORY_CODE_ACTION,
  payload: {
    merchantCategoryCodes
  }
});

export const getMerchantCategoryCodesFailure = errors => ({
  type: FAILURE_GET_MERCHANT_CATEGORY_CODE_ACTION,
  payload: {
    errors
  }
});
