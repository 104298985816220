import Widget from "./Widget";

// @secure

export interface WidgetGroup {
  key: WidgetGroupKey;
  widgets: Array<Widget>;
}

export enum WidgetGroupKey {
  ACTIVITY = "menu.activity",
  TERMINALS_PAYMENT = "menu.terminalsPayment",
  TOTALS = "home.maintainer.title1",
  TERMINALS_ACTIVITY = "home.maintainer.title2",
  SIM_ACTIVITY = "home.sim.title",
  COMPARISON = "menu.comparison",
  PAYMENT_METHODS = "menu.cards"
}
