import i18next from "i18next";
import _ from "lodash";
import { constantCase } from "constant-case";

import { ErrorResponse } from "../../notifications/model/ErrorResponse";
import Errors from "../../commons/errors/Errors";
import { Notification } from "../../notifications/model/Notification";

export const addNotificationSuccess = (
  i18nKeyOrNotification,
  args?: Array<any>
) => {
  return addNotification({
    level: "success",
    title: i18nKeyOrNotification,
    args
  });
};

export const addNotificationError = (
  error: ErrorResponse,
  args: Array<String>
) => {
  const title = Errors.print(error, args);

  return addNotification({
    level: "error",
    title
  });
};

export const addNotificationWarning = (
  i18nKeyOrNotification,
  args?: Array<any>
) => {
  return addNotification({
    level: "warning",
    title: i18nKeyOrNotification,
    args
  });
};

export const addNotificationInfo = (
  i18nKeyOrNotification,
  args?: Array<any>
) => {
  return addNotification({
    level: "info",
    title: i18nKeyOrNotification,
    args
  });
};

const addNotification = ({ level, title: i18nKeyOrNotification, args }) => {
  const name = constantCase(level);
  let notificationObject: Notification = {
    level,
    title: ""
  };

  if (typeof i18nKeyOrNotification === "string") {
    //i18nKey
    notificationObject.title =
      level !== "error"
        ? i18next.t(i18nKeyOrNotification, args)
        : i18nKeyOrNotification;
  } else {
    _.extend(notificationObject, i18nKeyOrNotification);
  }

  return {
    name,
    type: `ADD_NOTIFICATION_${name}_ACTION`,
    payload: {
      ...notificationObject
    }
  };
};
