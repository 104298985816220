import {
  CreatePredefinedAlert,
  PredefinedAlert,
  PredefinedAlertType
} from "./PredefinedAlert";
import PredefinedConstants from "../PredefinedAlertConstant";

export class CreateRefundTooHighAlert extends CreatePredefinedAlert {
  static create(currency: string, exponent: number) {
    return new CreateRefundTooHighAlert(
      PredefinedConstants.DEFAULT_VALUE_THRESHOLD,
      currency,
      exponent
    );
  }

  constructor(
    public valueThreshold: number,
    public valueThresholdCurrency: string,
    public valueThresholdExponent: number
  ) {
    super(RefundTooHighAlert.TYPE);
    this.valueThreshold =
      valueThreshold || PredefinedConstants.DEFAULT_VALUE_THRESHOLD;
    this.valueThresholdCurrency = valueThresholdCurrency || "";
    this.valueThresholdExponent = valueThresholdExponent || 2;
  }
}

export class RefundTooHighAlert extends PredefinedAlert {
  static TYPE: PredefinedAlertType = "RefundTooHigh";
  static I18N_KEY = "refund-too-high";

  constructor(
    public id: string,
    public valueThreshold: number,
    public valueThresholdCurrency: string,
    public valueThresholdExponent: number
  ) {
    super();
    this.id = id;
    this.valueThreshold = valueThreshold;
    this.valueThresholdCurrency = valueThresholdCurrency;
    this.valueThresholdExponent = valueThresholdExponent;
  }

  i18nKey(): string {
    return RefundTooHighAlert.I18N_KEY;
  }

  i18nArgs(locale: string): Array<string> {
    return [
      this.valueThresholdCurrency,
      (
        this.valueThreshold / Math.pow(10, this.valueThresholdExponent)
      ).toString()
    ];
  }
}
