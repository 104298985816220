import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

export default ({ renderMobile, renderDesktop }) => {
  const matches = useMediaQuery(`(max-width: 767px)`, { noSsr: true });

  if (
    (process.env.NODE_ENV !== "production" ||
      window.location.origin === PORTAL_DEV_URL) &&
    matches
  ) {
    return renderMobile();
  }

  return renderDesktop();
};
