import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { compose } from "redux";
import * as moment from "moment";
import { withTranslation } from "react-i18next";
import flatten from "flat";
import LogEventFilters from "./../constants/LogEventFilters";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import { SearchContextRedux } from "../../searchContext/SearchContext";

import {
  setSearchContext,
  getPayload,
  getLogs,
  resetLogs
} from "../../redux/actions";

import styles from "../styles/TaxAuditLog.css";

interface Props {
  color: any;
  searchContext: SearchContextRedux;
  location: any;
  tableCount: any;
  history: any;
  logId: any;
  merchantId: string;
  t: any;
  getLogs: Function;
  resetLogs: Function;
  getPayload: Function;
}

interface State {
  payload: any;
}

const advancedSearchKey = AdvancedSearchConstants.TAX_AUDIT_LOG_KEY;

class TaxAuditLogDetailView extends Component<Props, State> {
  state = {
    payload: null
  };

  formatter = {
    createDate: (date: string) =>
      moment(date)
        .format(`DD/MM/YYYY HH:mm:ss`)
        .toString(),
    eventCreateDate: (date: string) =>
      moment(date)
        .format(`DD/MM/YYYY HH:mm:ss`)
        .toString(),
    "ecrContext.ecrEnabled": (state: string) => (state ? "Yes" : "No"),
    UnitNetAmount: (amount: string) => {
      return `${amount} ${this.state.payload.currency}`;
    },
    amount: (amount: string) => `${amount} ${this.state.payload.currency}`
  };

  filters = {
    [LogEventFilters.NO_PRIVATE_PROPERTIES]: prop => /(\.)?(^)?_/.test(prop),
    [LogEventFilters.NO_EMPTY_PROPERTIES]: (prop, list) => !list[prop],
    currency: false
  };

  async componentDidMount() {
    const { logId, merchantId, getPayload } = this.props;
    try {
      let payload = await getPayload({
        id: logId,
        merchantId
      })
        .then(p => flatten(p, { safe: true }))
        .then((flattened: any) => this._normalizePayload(flattened));

      this.setState({ payload });
    } catch (err) {}
  }

  componentWillUnmount() {
    const { resetLogs } = this.props;
    resetLogs();
  }

  _isNestedKey(val) {
    return val.includes(".");
  }

  _normalizePayload(p: any) {
    return Object.keys(p).reduce((acc: any, key: any) => {
      /^data\./.test(key)
        ? (acc[key.replace(/^data\./, "")] = p[key])
        : (acc[key] = p[key]);
      return acc;
    }, {});
  }

  _filterByKey = payload => val =>
    !Object.keys(this.filters).some(
      key =>
        key === val ||
        (_.isFunction(this.filters[key]) && this.filters[key](val, payload))
    );

  _groupByPropertyDepth = key =>
    this._isNestedKey(key) ? _.first(key.split(".")) : "rest";

  render() {
    const { color, t } = this.props;
    const { payload } = this.state;

    const TaxAuditLogItem = ({ id, payload }) => {
      return (
        <div>
          <div className={styles["detail-title"]}>
            {_.capitalize(this._isNestedKey(id) ? _.last(id.split(".")) : id)}
          </div>
          <div className={styles["detail-value"]}>
            {_.has(this.formatter, id)
              ? this.formatter[id](payload[id], payload)
              : payload[id]}
          </div>
        </div>
      );
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle titleKey="logs.mainTitle" actionKey="logs.description" />
            <div className={styles["countAndRefresh-container"]}></div>
          </div>
          <div className={styles.edition}></div>
        </div>
        {payload && (
          <div>
            {_.values(
              _.groupBy(Object.keys(payload || {}), this._groupByPropertyDepth)
            ).map((keys: any) => {
              return (
                <>
                  {keys.some(this._isNestedKey) ? (
                    <div className={styles["detail-header"]}>
                      {_.capitalize(_.first(keys[0].split(".")))}
                    </div>
                  ) : (
                    <div className={styles["detail-header-main"]}>
                      {t("logs.eventDetails")}
                    </div>
                  )}
                  <div className={styles["detail-container"]}>
                    {keys.filter(this._filterByKey(payload)).map(key => {
                      let attributes, tableData;
                      const logItem = payload[key];

                      if (_.isArray(logItem)) {
                        tableData = logItem.map(obj =>
                          _.mapKeys(obj, (v, k) => _.capitalize(k))
                        );
                        attributes = Object.keys(_.first(tableData));
                      }

                      return _.isArray(logItem) ? (
                        <div
                          className="data-table-wrapper"
                          style={{
                            "min-width": "100%",
                            height: `${175 * tableData.length}px`
                          }}
                        >
                          <div className={styles["detail-header"]}>
                            {_.capitalize(key)}
                          </div>
                          <DataTable
                            data={tableData}
                            notSortableFields={attributes}
                            attributes={attributes}
                            visibleAttributes={attributes}
                            isColumnSettable={false}
                            formatter={this.formatter}
                            idAttribute="id"
                            hideSearchBox={true}
                            toggleable={false}
                            color={color}
                            hasActions={false}
                            selectableNew={false}
                            version="new"
                            useSelection={false}
                            remoteRowCount={tableData.length || 0}
                          />
                        </div>
                      ) : (
                        <TaxAuditLogItem payload={payload} key={key} id={key} />
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount }
    }
  } = state;

  const {
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    }
  } = state;
  const {
    searchContext: { data: searchContext }
  } = state;

  const {
    match: {
      params: { logId, merchantId }
    }
  } = ownProps;

  return {
    color,
    tableCount,
    sortDescription,
    searchContext,
    permissions: (user && user.permissions) || [],
    logId,
    merchantId
  };
};

const mapDispatchToProps = dispatch => ({
  getPayload: props => dispatch(getPayload({ ...props })),
  getLogs: ({ filters, fields, sort, tableCount }) =>
    dispatch(getLogs({ filters, fields, sort, tableCount })),
  resetLogs: () => dispatch(resetLogs()),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    ),
  dispatch
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TaxAuditLogDetailView);
