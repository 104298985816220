import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import get from "get-value";
import _ from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Iframe from "../components/Iframe";
import I18nSpan from "../i18n/components/I18nSpan";
import { closeQrCodesSelectionPopin, closeQrCode } from "../redux/actions";

import styles from "./styles/styles.css";

interface Props {
  history: any;
  closeQrCodesSelectionPopin: Function;
  closeQrCode: Function;
  qrCodes: any;
  showPopin: boolean;
  isLoading: boolean;
  merchantName: string;
  storeName: string;
  posAutoInit: any;
}

class MultipleQrCodeViewer extends Component<Props, {}> {
  _closeSelection = () => {
    const { closeQrCodesSelectionPopin } = this.props;

    return closeQrCodesSelectionPopin();
  };

  _sendQrCodes = () => {
    const { qrCodes, history } = this.props;

    if (qrCodes) {
      return history.push(`/main/settings/posQRCode/multiple/send`);
    }
  };

  _closeQrCode = posId => {
    const { closeQrCode } = this.props;

    return closeQrCode(posId);
  };

  render() {
    const {
      showPopin,
      qrCodes,
      isLoading,
      merchantName,
      storeName,
      posAutoInit
    } = this.props;

    if (Object.keys(qrCodes).length > 0) {
      return (
        <Modal
          show={showPopin}
          onHide={this._closeSelection}
          animation={false}
          dialogClassName="receiptsModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <I18nSpan msgKey={"pos.qrCodes.modal.title"} />{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="receiptContainer">
              {Object.keys(qrCodes).map(storeId => {
                return (
                  <div key={storeId} className={styles.storeContainer}>
                    <div className={styles.storeInfo}>
                      <span>
                        <strong>• {merchantName} : </strong>
                        {qrCodes[storeId]["merchant.name"]}
                      </span>
                      <span>
                        <strong>• {storeName} : </strong>
                        {qrCodes[storeId]["store.name"]}
                      </span>
                    </div>
                    {qrCodes[storeId].posIds.map(pos => (
                      <div key={get(pos.pos, "pos.id")} className="receipt">
                        <div className="header">
                          <span
                            className="close"
                            onClick={() =>
                              this._closeQrCode(get(pos.pos, "pos.id"))
                            }
                          >
                            x
                          </span>
                        </div>
                        <div className={`receiptBody ${styles.qrCodeBody}`}>
                          <div className={styles.posInfo}>
                            <span>
                              <strong>• {posAutoInit} : </strong>
                              {get(pos.pos, "pos.autoInit")}
                            </span>
                          </div>
                          <Iframe
                            id={`${storeId}-iframe`}
                            docContent={pos.svg}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
              {isLoading ? (
                <div className="receipt loading">
                  <div className="loadingTxt">
                    <div className="spinner">
                      <div className="bounceBx bounce1" />
                      <div className="bounceBx bounce2" />
                      <div className="bounceBx bounce3" />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <iframe
              className={styles["print-iframe"]}
              ref={el => (this.printIframeElement = el)}
            />
          </Modal.Body>
        </Modal>
      );
    }

    return null;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { t } = ownProps;
  const merchantName = t("pos.list.header.merchant.name");
  const storeName = t("pos.list.header.store.name");
  const posAutoInit = t("pos.list.header.pos.autoInit");

  return {
    merchantName,
    storeName,
    posAutoInit
  };
};

const mapDispatchToProps = dispatch => ({
  closeQrCodesSelectionPopin: () => dispatch(closeQrCodesSelectionPopin()),
  closeQrCode: posId => dispatch(closeQrCode(posId))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(MultipleQrCodeViewer);
