import React, { Component } from "react";
import { connect } from "react-redux";

import AdvancedSearch from "../../../advancedSearch/components/AdvancedSearch";
import AdvancedSearchConstants from "../../../advancedSearch/constants/AdvancedSearchConstants";

interface Props {
  onChange: Function;
  fields: Array<any>;
  saleFields: any;
  searchFilters: any;
}

class SaleAdvancedSearch extends Component<Props, {}> {
  render() {
    const {
      searchFilters,
      saleFields = [],
      searchContext,
      onChange
    } = this.props;

    return (
      <div>
        <AdvancedSearch
          name={AdvancedSearchConstants.SALE_KEY}
          searchFilters={searchFilters}
          onChange={onChange}
          genericFields={saleFields}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    genericFields: { data: fields }
  } = state;

  const searchFilters = fields
    ? fields.reduce((prevField: any, field: any) => {
        const { key, type, searchable } = field;

        if (searchable) {
          const tagsKey = /\b(selectedTags).([^.]+)/g.exec(key);
          prevField.push({
            i18nKey: `reporting.sale.list.header.${key}`,
            rawLabel: tagsKey ? tagsKey[2] : null,
            name: key,
            type
          });
        }

        return prevField;
      }, [])
    : [];

  return { searchFilters, saleFields: fields };
};

export default connect(mapStateToProps, null)(SaleAdvancedSearch);
