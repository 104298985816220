import React, { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../../ingenicoForm/components/FormTitle";
import AdvancedSearchConstants from "../../../advancedSearch/constants/AdvancedSearchConstants";
import { Refresh } from "../../../datatable/components/Refresh";

import DateFormatter from "../../../formatters/DateFormatter";
import {
  getReturningCustomersWidgets,
  getFields,
  resetWidgetsState,
  resetWidgetsStatsState,
  resetWidgetsTrendState,
  setSearchContext
} from "../../../redux/actions";
import { WidgetConfig } from "../../../home/components/Widget";
import WidgetView from "../../../home/components/Widget";

import { setUrlWithParams } from "../../../searchContext/URLizer";

import styles from "../styles/ReturningCustomers.css";

// @secure

interface Props {
  resetWidgetsTrendState: Function;
  getWidgets: Function;
  getFields: Function;
  resetWidgetsState: Function;
  resetWidgetsStatsState: Function;
  filters: any;
  periodSelection: string;
  color: any;
  user: any;
  widgets: any;
}

interface State {}

const advancedSearchKey = AdvancedSearchConstants.TRANSACTION_KEY;

class ReturningCustomersView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.setUrlParams({ searchContext });
  }

  async componentDidMount() {
    const { getWidgets } = this.props;

    await getWidgets();
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  async componentWillUnmount() {
    const { resetWidgetsState, resetWidgetsStatsState } = this.props;

    await resetWidgetsState();
    await resetWidgetsStatsState();
  }

  _search = async () => {
    const { getWidgets, resetWidgetsState } = this.props;

    await resetWidgetsState();
    await getWidgets();
  };

  render() {
    const { color, user, widgets } = this.props;

    return (
      <div className="reporting reporting-activity">
        <div className={styles["title-wrapper"]}>
          <FormTitle
            color={color}
            titleKey="reporting.mainTitle"
            actionKey="reporting.customers.categories.returningCustomers"
          />

          <div className={styles["countAndRefresh-container"]}>
            <Refresh onRefresh={this._search} />
          </div>
        </div>

        <div
          key={`group-widget`}
          className={classNames(styles.wrapper, "group-widget")}
          ref={container => (this.widgetContainer = container)}
        >
          {widgets.map((config: WidgetConfig) => {
            const { id } = config;

            return (
              <WidgetView
                className={classNames(styles.widget)}
                enableTrend={true}
                color={color}
                user={user}
                key={id}
                config={{ ...config, desktopView: true }}
                header={false}
              />
            );
          })}
        </div>
        <style>
          {`
                        .btn-ingenico,.btn-ingenico:focus{
                            color: ${color};
                            border-color: ${color};
                        }
                        .btn-ingenico:hover {
                            color: #FFF;
                            background-color: ${color};
                        }
                    `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user: currentUser, isMerchant, levelSettings: _levelSettings },
    theme: {
      color: {
        data: { color }
      }
    },
    services: { data: services = [] },
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        timePeriod = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const getTransactionsCount = () => {
    const {
      widgets: { transactionNumber }
    } = state.widgetData;

    if (transactionNumber) {
      const {
        data: { stats = {} },
        loading,
        error
      } = transactionNumber;

      return {
        data: stats,
        loading,
        error
      };
    }
    return {
      data: {},
      loading: true
    };
  };

  const transactionsCounter = getTransactionsCount();

  const canSeeWidget = isMerchant;

  const {
    scope: {
      level: { type: userType, id: merchantId }
    }
  } = currentUser;

  const {
    widgets: { data = [] },
    loading,
    error
  } = state;

  const widgets = data.map(widget => {
    const { name, id, type } = widget;

    const min = DateFormatter.getTimezonedDate(
      timePeriod.startTime,
      currentUser
    );
    const max = DateFormatter.getTimezonedDate(timePeriod.endTime, currentUser);

    return {
      type,
      filters,
      name,
      id,
      period: timePeriod.type,
      userCurrency,
      timePeriod,
      timestamps: {
        min,
        max
      },
      merchantId
    };
  });

  return {
    transactionsCounter,
    periodSelection: timePeriod.type,
    filters,
    services,
    color,
    user: currentUser,
    userType,
    levelId: merchantId,
    canSeeWidget,
    widgets,
    loading,
    error,
    searchContext
  };
};

const mapDispatchToProps = dispatch => ({
  resetWidgetsState: () => dispatch(resetWidgetsState()),
  resetWidgetsStatsState: () => dispatch(resetWidgetsStatsState()),
  getWidgets: () => dispatch(getReturningCustomersWidgets({})),
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  resetWidgetsTrendState: () => dispatch(resetWidgetsTrendState()),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl: true
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReturningCustomersView);
