import React, { Component } from "react";
import get from "get-value";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import FormTitle from "../../../ingenicoForm/components/FormTitle";
import AdvancedSearchConstants from "../../../advancedSearch/constants/AdvancedSearchConstants";
import ExportButton from "../../transactions/components/ExportButton";

import Counter from "../../../datatable/components/Counter";

import TransactionAdvancedSearch from "../../../reporting/transactions/components/TransactionAdvancedSearch";

import DataTable from "../../../datatable/components/DataTable";
import NumberFormatter from "../../../formatters/NumberFormatter";
import DateFormatter from "../../../formatters/DateFormatter";

import TransactionReceiptViewer from "../../../receipt/TransactionReceiptViewer";
import ReconciliationTimeSelector from "./ReconciliationTimeSelector";
import Utils from "../Utils";

import { generateUrl } from "../../../commons/utils/url";

import {
  ReconciliationToTerminalAction,
  PreviewMerchantTicket
} from "../../../datatable/components/multipleActions";

import {
  getFields,
  getReconciliations,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetReconciliations,
  getReconciliationReceipt,
  closeTicket,
  getScroll,
  setSearchContext
} from "../../../redux/actions";

import { Refresh } from "../../../datatable/components/Refresh";
import { setUrlWithParams } from "../../../searchContext/URLizer";
import ReconciliationConstants from "../../constants/ReconciliationConstants";

import styles from "../styles/styles.css";

interface Props {
  count: any;
  reconciliations: any;
  formatters: any;
  visibleAttributes: any;
  selectedReconciliationIds: any;
  color: any;
  timePeriod: any;
  loadingReconciliations: boolean;
  getReconciliations: Function;
  getFields: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  resetReconciliations: Function;
  closeTicket: Function;
  getReconciliationReceipt: Function;
  showMerchantPopin: boolean;
  popinContent: string;
  transactionId: string;
  tableCount: any;
  showExportWithFile: boolean;
  attributes: any;
  getReconciliationsScroll: Function;
  reconciliationScrollId: string;
}

interface State {}

const advancedSearchKey = AdvancedSearchConstants.RECONCILIATION_KEY;

class ListReconciliationView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.setUrlParams({ searchContext });
  }

  async componentDidMount() {
    const { getFields, searchContext } = this.props;
    const name = "reconciliation";
    const version = "v2";

    await getFields({ name, version });

    await this._onRefresh({ searchContext });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  componentWillUnmount() {
    const { resetReconciliations } = this.props;

    resetReconciliations();
  }

  _buildTimePeriodParams = ({ timePeriod }) => {
    const { user } = this.props;

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);
    return {
      begin,
      end
    };
  };

  _onRefresh = async ({ searchContext }) => {
    const { tableCount, getReconciliations, user } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort } = {},
      timePeriod
    } = searchContext;

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);

    return getReconciliations({
      filters,
      sort,
      tableCount,
      begin,
      end
    });
  };

  _search = async ({ searchContext }) => {
    const {
      tableCount,
      setSearchContext,
      location: { pathname },
      getReconciliations,
      user
    } = this.props;
    const { sort, filters, timePeriod } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);

    return getReconciliations({
      filters,
      sort,
      tableCount,
      begin,
      end
    });
  };

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  _closeTicket = () => {
    const { closeTicket } = this.props;
    closeTicket();
  };

  _openTicket = row => {
    return () => {
      const { getReconciliationReceipt } = this.props;
      const { transactionId } = row;

      getReconciliationReceipt({ id: transactionId });
    };
  };

  onSelectRow = (rowId, value) => {
    const {
      selectedReconciliationIds,
      toggleUnSelect,
      toggleSelect
    } = this.props;

    if (selectedReconciliationIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  redirectToTransactionList = reconciliation => {
    const { history, setSearchContext } = this.props;
    const redirectTo = () => history.push("/main/reporting/transaction");
    const { transactionId: id, fileIdentifierTerminal: value } = reconciliation;

    const filters = [
      {
        filterType: "string",
        name: "fileIdentifierTerminal",
        operator: "=",
        value
      }
    ];
    const searchContext = {
      key: AdvancedSearchConstants.TRANSACTION_KEY,
      filters
    };

    setSearchContext({
      context: searchContext,
      pathname: "/main/reporting/transaction",
      updateUrl: true
    });
    return redirectTo();
  };

  _getReconciliationById = (id: string) => {
    const { reconciliations } = this.props;
    return reconciliations.filter(
      reconciliation => id === reconciliation.transactionId
    )[0];
  };

  _loadMoreRows = () => {
    const { getReconciliationsScroll, reconciliationScrollId } = this.props;

    return getReconciliationsScroll({ scrollId: reconciliationScrollId });
  };

  _getStorageKey = () => {
    const { userLogin } = this.props;

    return `visibleAttributes#${userLogin}#reporting.reconciliation.list`;
  };

  render() {
    const {
      fields,
      color,
      showExportWithFile,
      count,
      reconciliations,
      formatters,
      visibleAttributes,
      attributes,
      selectedReconciliationIds,
      transactionId,
      popinContent,
      showMerchantPopin,
      sortDescription,
      loadingReconciliations,
      connectionStore,
      searchContext,
      columnSetting,
      t
    } = this.props;

    const ReconciliationToTerminal = () => {
      const [id] = selectedReconciliationIds;
      const reconciliation = this._getReconciliationById(id);

      if (
        selectedReconciliationIds.length === 1 &&
        reconciliation.fileIdentifierTerminal
      ) {
        return (
          <ReconciliationToTerminalAction
            redirectToTransactionList={() =>
              this.redirectToTransactionList(reconciliation)
            }
            msgKey={
              "reporting.reconciliation.list.header.transactionsPlaceHolder"
            }
          />
        );
      }

      return null;
    };

    const previewMerchantTicket = () => {
      const merchantReceiptIsVisible = row => {
        return Utils.merchantReceiptIsVisible(row, connectionStore);
      };
      const [id] = selectedReconciliationIds;
      const reconciliation = this._getReconciliationById(id);

      if (
        selectedReconciliationIds.length === 1 &&
        merchantReceiptIsVisible(reconciliation)
      ) {
        return (
          <PreviewMerchantTicket
            _openTicket={this._openTicket(reconciliation)}
            msgKey={"dataTable.merchantReceipt"}
          />
        );
      }

      return null;
    };

    const ExportToCsv = ({ showExport, reconciliationsCount }: any) => {
      if (showExport) {
        const convertAttributesIntoMap = attributes =>
          attributes.reduce((prevAttribute, attribute) => {
            return {
              ...prevAttribute,
              [attribute]: true
            };
          }, {});

        const defaultVisibleAttributes =
          columnSetting && columnSetting.reconciliation
            ? convertAttributesIntoMap(columnSetting.reconciliation)
            : convertAttributesIntoMap(attributes.slice(0, 10));
        const visibleAttributesSaved =
          JSON.parse(localStorage.getItem(this._getStorageKey())) ||
          defaultVisibleAttributes;

        const columnsToExport = Object.entries(visibleAttributesSaved).reduce(
          (prevAttribute, attribute) => {
            if (attribute[1]) {
              const content = attribute[0];
              const name = t(
                `reporting.${ReconciliationConstants.I18N_PREFIX}.${content}.label`,
                content
              );

              return [
                ...prevAttribute,
                {
                  name,
                  content
                }
              ];
            }

            return prevAttribute;
          },
          []
        );

        return (
          <ExportButton
            exportUrl={generateUrl({
              url: "/reconciliation/export",
              version: "v2"
            })}
            filtersKey={advancedSearchKey}
            buildTimePeriodParams={this._buildTimePeriodParams}
            canExport={columnsToExport.length > 0 && reconciliationsCount > 0}
            columns={columnsToExport}
            formats={["CSV"]}
            version="new"
            searchContext={searchContext}
          />
        );
      }
      return null;
    };

    return (
      <div className="reporting reporting-transaction data-table-wrapper">
        <div className={styles["title-wrapper"]}>
          <FormTitle
            color={color}
            titleKey="reporting.reconciliation.title"
            actionKey="reporting.reconciliation.action"
          />

          <div className={styles["countAndRefresh-container"]}>
            <Counter
              loading={loadingReconciliations}
              count={count}
              loadingKey="reporting.reconciliation.counter.loading"
            />
            <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
          </div>
        </div>

        <div className="flex-box">
          <ReconciliationTimeSelector
            onChange={({ searchContext: nextSearchContext }) =>
              this._search({ searchContext: nextSearchContext })
            }
            searchContext={searchContext}
          />

          <div className="no-flex">
            <ExportToCsv
              showExport={showExportWithFile}
              reconciliationsCount={count}
            />
          </div>
        </div>

        <TransactionAdvancedSearch
          name={AdvancedSearchConstants.RECONCILIATION_KEY}
          onChange={this._search}
          searchContext={searchContext}
          i18nKey="reporting.reconciliation.list.header"
        />

        <DataTable
          fields={fields}
          data={reconciliations}
          idAttribute="transactionId"
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          i18nKey="reporting.reconciliation.list"
          hideSearchBox={true}
          hasActions={false}
          selectionButtonKey={[ReconciliationToTerminal, previewMerchantTicket]}
          toggleable={true}
          formatter={formatters}
          onUpdateColumns={this._updateColumns}
          version="new"
          onRefresh={this._search}
          selectableNew={true}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedReconciliationIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={count}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />
        <TransactionReceiptViewer
          showPopin={showMerchantPopin}
          transactionId={transactionId}
          popinContent={popinContent}
          onClose={this._closeTicket}
          receiptActionType="reconciliation"
          receiptType="merchant"
        />
      </div>
    );
  }
}

const normalizeReconciliation = ({ state }) => {
  const {
    reconciliations: { data, scrollId, count },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const reconciliations = data.map(reconciliation => {
    const normalizedReconciliations = fields.reduce((prevField, field) => {
      return {
        ...prevField,
        [field]: get(reconciliation, field)
      };
    }, {});

    return {
      ...normalizedReconciliations
    };
  });

  return {
    count,
    scrollId,
    reconciliations
  };
};

const _getColumns = ({ type, fields: genericFields }) => {
  const fields = genericFields.reduce((prevField, field) => {
    const { key, required, visible, extras } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);

  return fields;
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting &&
    columnSetting.reconciliation &&
    columnSetting.reconciliation.length > 0
    ? columnSetting.reconciliation
    : _getColumns({ fields });
};

const currencyFormatter = (val, row) => {
  const currencySymbol = row.currencySymbol;
  const currencyCodeAlpha3 = row.currencyCodeAlpha3;
  const currencyExponent = row.currencyExponent;

  return NumberFormatter.formatAmount(
    val,
    currencyCodeAlpha3 + currencySymbol,
    currencyExponent
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: connectionStore,
    genericFields: { data: fields = [] },
    reconciliations: {
      selection,
      loading: loadingReconciliations,
      receiptHtml: popinContent,
      transactionId,
      showMerchantPopin
    },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount }
    },
    services: { data: services = [] },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {},
        timePeriod = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const {
    count,
    scrollId: reconciliationScrollId,
    reconciliations
  } = normalizeReconciliation({ state });
  const attributes = _getColumns({ fields });

  const { user } = connectionStore;

  const selectedReconciliationIds = _.keys(_.pick(selection, _.identity));

  const columnSetting = user ? user.columnSetting : false;

  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const showExportWithFile = Utils.canExportReconciliations(
    connectionStore,
    services
  );

  const userLogin = user ? user.login : "";

  return {
    user,
    periodSelection: timePeriod.type,
    fields,
    loadingReconciliations,
    color,
    count,
    reconciliations,
    showExportWithFile,
    selectedReconciliationIds,
    selection,
    tableCount,
    visibleAttributes,
    attributes,
    popinContent,
    transactionId,
    showMerchantPopin,
    reconciliationScrollId,
    formatters: {
      debitsAmount: currencyFormatter,
      creditsAmount: currencyFormatter,
      debitsReversalAmount: currencyFormatter
    },
    sortDescription,
    connectionStore,
    searchContext,
    columnSetting,
    userLogin
  };
};

const mapDispatchToProps = dispatch => ({
  resetReconciliations: () => dispatch(resetReconciliations()),
  getReconciliations: ({ filters, fields, sort, tableCount, begin, end }) =>
    dispatch(
      getReconciliations({ filters, fields, sort, tableCount, begin, end })
    ),
  getReconciliationsScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: advancedSearchKey, scrollId })),
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  getReconciliationReceipt: ({ id }) =>
    dispatch(getReconciliationReceipt({ id })),
  closeTicket: () => dispatch(closeTicket()),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "RECONCILIATIONS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "RECONCILIATIONS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "RECONCILIATIONS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "RECONCILIATIONS" })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl: true
      })
    )
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ListReconciliationView);
