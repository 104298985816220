import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_PROGRAMS_ACTION,
  SUCCESS_GET_PROGRAMS_ACTION,
  FAILURE_GET_PROGRAMS_ACTION,
  PENDING_DELETE_PROGRAM_ACTION,
  FAILURE_DELETE_PROGRAM_ACTION,
  SUCCESS_DELETE_PROGRAM_ACTION
} from "../actionsTypes";

export const getPrograms = () => async dispatch => {
  dispatch(getProgramsStart());

  try {
    const programs = await F.get("/customer/me/program");

    return dispatch(getProgramsSucceeded(programs));
  } catch (errors) {
    dispatch(getProgramsFailure({ errors }));

    throw errors;
  }
};

export const getProgramsStart = () => ({
  type: PENDING_GET_PROGRAMS_ACTION
});

export const getProgramsSucceeded = programs => ({
  type: SUCCESS_GET_PROGRAMS_ACTION,
  payload: {
    programs
  }
});

export const getProgramsFailure = errors => ({
  type: FAILURE_GET_PROGRAMS_ACTION,
  payload: {
    errors
  }
});

export const deleteProgram = ({ programId }) => async dispatch => {
  dispatch(deleteProgramStart());

  try {
    await F.delete(`/customer/me/program/${programId}`);
    return dispatch(deleteProgramSucceeded({ programId }));
  } catch (errors) {
    dispatch(deleteProgramFailure({ errors }));
    throw errors;
  }
};

export const deleteProgramStart = () => ({
  type: PENDING_DELETE_PROGRAM_ACTION
});

export const deleteProgramSucceeded = ({ programId }) => ({
  type: SUCCESS_DELETE_PROGRAM_ACTION,
  payload: {
    programId
  }
});

export const deleteProgramFailure = errors => ({
  type: FAILURE_DELETE_PROGRAM_ACTION,
  errors
});
