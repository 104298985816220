import * as moment from "moment";
import { getTransactionsAgg } from "../../redux/actions/widgetData";

import { upperBoundToMonthRanges } from "../utils/BoundMonth";

export const loadTrendsCashback = ({
  filters = [],
  timePeriod,
  user,
  timestamps
}: any) => {
  const ranges = upperBoundToMonthRanges(timePeriod.type, timestamps);

  const timezone = user ? moment.tz(user.timezone).format("Z") : "";

  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  return getTransactionsAgg({
    restriction: "transaction",
    filters: [...filters, ...defaultFilters],
    aggregation: {
      name: "trendsCashback",
      field: "mshTransactionTime",
      type: "date_range",
      timezone,
      ranges,
      subaggs: [
        {
          name: "cashbackSum",
          type: "sum",
          field: "cashbackAmount"
        }
      ]
    }
  })
    .then(response => {
      const {
        trendsCashback: {
          buckets: [
            {
              cashbackSum: { value: cashbackSumBefore }
            },
            {
              cashbackSum: { value: cashbackSumNow }
            }
          ]
        }
      } = response;

      const cashback = parseInt(
        ((cashbackSumNow - cashbackSumBefore) / cashbackSumBefore) * 100,
        10
      );

      return {
        value: cashbackSumNow,
        cashback
      };
    })
    .catch(errors => {
      throw errors;
    });
};
