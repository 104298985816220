import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import {
  editTag,
  getTags,
  resetTagsState,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";
import constants from "../../connection/constants/ConnectionConstant";

interface Props {
  userType: string;
  levelId: string;
  history: any;
  editTag: Function;
  getTags: Function;
  resetTags: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  tag: {
    name: string;
    values: Array<string>;
  };
}

interface State {
  form: any;
  errors?: any;
}

class EditTagView extends Component<Props, State> {
  state = {
    form: {
      newValues: []
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentDidMount() {
    const { getTags } = this.props;

    getTags();
  }

  componentWillUnmount() {
    const { resetTags } = this.props;

    resetTags();
  }

  _onChangeHandler = (name: string, value: string | Array<string>) => {
    const newForm = this.state.form;
    const {
      tag: { values }
    } = this.props;
    if (name === "newValues" && _.intersection(values, value).length === 0) {
      newForm[name] = value;
    }
    this.setState(newForm);
  };

  _saveTag = async e => {
    e.preventDefault();

    const {
      tag: { name, values },
      editTag,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const {
      form: { newValues }
    } = this.state;

    const tag = {
      name,
      values: [...values, ...newValues]
    };

    try {
      await editTag({ tag });
      addNotificationSuccess("tag.edit.success");
      this._goToTagList();
    } catch (error) {
      const { errorKey } = await error;
      const notificationErrorKey = `notification.tag.error.${errorKey}`;

      addNotificationError(notificationErrorKey);
    }
  };

  _goToTagList = () => this.props.history.push("/main/settings/tags");

  render() {
    const { tag: { name, values } = { name: "", values: [] } } = this.props;

    const valuesReadOnly = values.length === 0;

    const {
      form: { newValues }
    } = this.state;
    return (
      <div>
        <FormTitle titleKey="tag.title" actionKey="tag.edit.action" />
        <form className="ingenico-form form-horizontal tag-form">
          <BootstrapInput
            onChange={_.noop}
            inputRef={this.setInputRef("name")}
            name="name"
            required={true}
            descriptor={{
              type: "text",
              label: "tag.form.name.label",
              readOnly: true
            }}
            formValue={name}
          />
          <BootstrapInput
            onChange={_.noop}
            inputRef={this.setInputRef("values")}
            name="values"
            readOnly={true}
            descriptor={{
              type: "multiple",
              label: "tag.form.values.label",
              readOnly: true
            }}
            formValue={values}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("newValues")}
            name="newValues"
            descriptor={{
              type: "multiple",
              label: "tag.form.newValues.label",
              placeholder: "tag.form.newValues.placeholder",
              readOnly: valuesReadOnly
            }}
            formValue={newValues}
          />
          <div className="pull-right">
            <button
              onClick={this._saveTag}
              name="save"
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToTagList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: {
        scope: {
          level: { type: levelType }
        }
      }
    }
  } = state;

  const {
    match: {
      params: { tagId: name }
    }
  } = ownProps;

  const { data: tags } = state.tags;
  const tag = tags.find(index => index.name === name);

  return {
    tags,
    tag,
    levelType
  };
};

const mapDipatchToProps = dispatch => ({
  editTag: ({ tag, tagType }) => dispatch(editTag({ tag, tagType })),
  getTags: ({ tagType }) => dispatch(getTags({ tagType })),
  resetTags: ({ tagType }) => dispatch(resetTagsState({ tagType })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { levelType } = stateProps;

  switch (levelType) {
    case constants.CUSTOMER: {
      const tagType = constants.CUSTOMER_NEW;

      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        editTag: ({ tag }) => dispatchProps.editTag({ tag, tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    case constants.MERCHANT: {
      const tagType = constants.MERCHANT;

      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        editTag: ({ tag }) => dispatchProps.editTag({ tag, tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    default:
      break;
  }
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDipatchToProps, mergeProps)
)(EditTagView);
