import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_SESSIONS_ACTION,
  SUCCESS_GET_SESSIONS_ACTION,
  FAILURE_GET_SESSIONS_ACTION,
  PENDING_DELETE_SESSION_ACTION,
  FAILURE_DELETE_SESSION_ACTION,
  SUCCESS_DELETE_SESSION_ACTION
} from "../actionsTypes";

export const getSessions = merchantId => async dispatch => {
  dispatch(getSessionsStart());
  return F.get(`/pub/fiscal/ecr/session/${merchantId}`)
    .then(sessions => dispatch(getSessionsSucceeded(sessions)))
    .catch(errors => {
      dispatch(getSessionsFailure({ errors }));
      throw errors;
    });
};

export const getSessionsStart = () => ({
  type: PENDING_GET_SESSIONS_ACTION
});

export const getSessionsSucceeded = sessions => ({
  type: SUCCESS_GET_SESSIONS_ACTION,
  payload: {
    sessions
  }
});

export const getSessionsFailure = errors => ({
  type: FAILURE_GET_SESSIONS_ACTION,
  payload: {
    errors
  }
});

export const deleteSession = ({
  sessionId,
  reason,
  reasonMessage
}) => dispatch => {
  dispatch(deleteSessionStart());
  return F.delete(`/pub/fiscal/ecr/session/${sessionId}`, {
    contentType: ContentTypes.JSON,
    body: { reason, ...(reasonMessage && { reasonMessage }) }
  })
    .then(() => dispatch(deleteSessionSucceeded({ sessionId })))
    .catch(errors => {
      dispatch(deleteSessionFailure({ errors }));
      throw errors;
    });
};

export const deleteSessionStart = () => ({
  type: PENDING_DELETE_SESSION_ACTION
});

export const deleteSessionSucceeded = ({ sessionId }) => ({
  type: SUCCESS_DELETE_SESSION_ACTION,
  payload: {
    sessionId
  }
});

export const deleteSessionFailure = errors => ({
  type: FAILURE_DELETE_SESSION_ACTION,
  errors
});
