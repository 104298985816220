import React, { useState, useEffect } from "react";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";

import { useSelector } from "react-redux";
import classNames from "classnames";
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import LogoFooter from "./LogoFooter/LogoFooter";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

const CreateTerminalEnding = ({ handleBack, buttonText, message, logo }) => {
  const { darkMode: prefersDarkMode } = useSelector(state =>
    mapStateToProps(state)
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: prefersDarkMode ? "transparent" : "transparent",
        position: "relative"
      },
      target: {
        position: "absolute"
      },
      divider: {
        width: "100%",
        padding: "0 10vw calc(var(--vh, 1vh) * 2.07) 10vw"
      }
    })
  );

  const useStylesGrid = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }
    })
  );

  const useStylesGridButtons = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      container: {
        padding: "0 10vw 0 10vw",
        height: "calc(var(--vh, 1vh) * calc(30 - 8))",
        justifyContent: "space-around",
        width: "100%",
        display: "flex",
        flexDirection: "column"
      }
    })
  );

  const useStylesGridMessage = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      container: {
        padding: "0 10vw 0 10vw",
        height: "calc(var(--vh, 1vh) * 50)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center"
      },
      logo: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        padding: "10%",
        border: "1px solid",
        borderColor: prefersDarkMode ? "#303030" : "#E5E5E5",
        marginBottom: "10%"
      },
      message: {
        textAlign: "center",
        fontWeight: "bold",
        color: prefersDarkMode ? "white" : "#000000"
      }
    })
  );

  const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "uppercase",
        backgroundColor: prefersDarkMode ? "#3B3B3B" : "#E5E5E5",
        borderRadius: "5px",
        width: "100%",
        fontWeight: "bold",
        height: "calc(var(--vh, 1vh) * 8)",
        fontSize: "11px",
        color: prefersDarkMode ? "#E5E5E5" : "#404041",
        textAlign: "center",
        "&:hover": {
          color: prefersDarkMode ? "#404041" : "#404041",
          backgroundColor: "#E5E5E5"
        }
      },
      contained: {}
    })
  );

  const classes = useStyles();

  const classesButton = useStylesButton();

  const classesGridMessage = useStylesGridMessage();

  const classesGrid = useStylesGrid();

  const classesGridButtons = useStylesGridButtons();

  return (
    <Container className={classNames(classes.root)} disableGutters>
      <Grid container className={classesGrid.container}>
        <Grid item className={classesGridMessage.container}>
          <div className={classesGridMessage.logo}>{logo}</div>
          <p className={classesGridMessage.message}>{message}</p>
        </Grid>
        <Grid item className={classesGridButtons.container}>
          <Button
            classes={{
              root: classesButton.root,
              contained: classesButton.contained
            }}
            onClick={() => handleBack()}
          >
            {buttonText}
          </Button>
          <LogoFooter />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateTerminalEnding;
