export default {
  SALE: "SALE",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  INSTALLATION: "INSTALLATION",
  UPGRADE: "UPGRADE",
  CAISSE_SESSION_OPEN: "CAISSE_SESSION_OPEN",
  CAISSE_SESSION_CLOSE: "CAISSE_SESSION_CLOSE",
  CAISSE_SESSION_CLOSE_FROM_PORTAL: "CAISSE_SESSION_CLOSE_FROM_PORTAL",
  TURNING_OFFLINE: "TURNING_OFFLINE",
  TURNING_ONLINE: "TURNING_ONLINE",
  SEND_RECEIPT: "SEND_RECEIPT",
  CREATE_BLOCKCHAIN: "CREATE_BLOCKCHAIN",
  CLOSE_PERIOD_SUCCESS: "CLOSE_PERIOD_SUCCESS",
  CLOSE_PERIOD_FAIL: "CLOSE_PERIOD_FAIL",
  CREATED_CONTEXT: "CREATED_CONTEXT",
  CREATE_FSL: "CREATE_FSL",
  ECR_SESSION_ALERT: "ECR_SESSION_ALERT",
  USER_EVENTS: "USER_EVENTS",
  ECR_EVENTS: "ECR_EVENTS",
  INSTALLATION_EVENTS: "INSTALLATION_EVENTS",
  CLOSE_PERIOD_EVENTS: "CLOSE_PERIOD_EVENTS",
  ALERT_EVENTS: "ALERT_EVENTS",
  ALL_EVENTS: "ALL_EVENTS"
};
