import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { WidgetConfig } from "../Widget";
import { replaceIdByName } from "../../services/AggregationDataHandler";
import TransactionsPie from "../../../chart/components/chart/TransactionsPie";
import LoadingChart from "./LoadingChart";
import { getData } from "../../utils/DataProvider";

interface Data {
  criteria?: string;
}

interface Props {
  data?: Data;
  fields?: any;
  loadingFields?: boolean;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  config: WidgetConfig;
}

class ComparisonCountChart extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { user, desktopView } = config;
    if (desktopView) {
      return getData({ user, config });
    }
  }

  generateSeries(data) {
    const { t } = this.props;

    if (
      data.criteria === "selectedService" ||
      data.criteria === "transactionType" ||
      data.criteria === "nokReason" ||
      data.criteria === "transactionResult" ||
      data.criteria === "technologySelected"
    ) {
      const dataCount = replaceIdByName("count", data).map(c => {
        return {
          id: c.id,
          y: c.y,
          value: c.value,
          name: t(`reporting.comparison.${c.name.toLowerCase()}`)
        };
      });
      return [
        {
          data: dataCount
        }
      ];
    }
    const dataCount = replaceIdByName("count", data);

    return [{ data: dataCount }];
  }

  render() {
    const {
      data,
      loadingFields,
      title,
      color,
      loading,
      config: { defaultPaletteId },
      t
    } = this.props;
    const titleWidget = t(title);
    const format =
      "<b>{point.name}</b>: {point.percentage:.2f}% ({point.value})";
    const series = this.generateSeries(data);
    const isLoading = loading || loadingFields;

    return (
      <LoadingChart loading={isLoading} color={color}>
        <TransactionsPie
          color={color}
          title={titleWidget}
          series={series}
          dataLabelsFormat={format}
          palette={defaultPaletteId}
        />
      </LoadingChart>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const {
    widgets: { [id]: widget },
    transactions: {
      data: { fields = {} },
      loading: loadingFields,
      errorFields
    }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      loadingFields,
      errorFields,
      fields,
      data: stats,
      loading,
      error
    };
  }

  return {
    data: {},
    loading: true,
    loadingFields,
    errorFields,
    fields: {}
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ComparisonCountChart);
