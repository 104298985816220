import React, { Component } from "react";
import get from "get-value";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../../ingenicoForm/components/FormTitle";
import AdvancedSearchConstants from "../../../advancedSearch/constants/AdvancedSearchConstants";
import Counter from "../../../datatable/components/Counter";
import { Refresh } from "../../../datatable/components/Refresh";
import DataTable from "../../../datatable/components/DataTable";
import SaleAdvancedSearch from "./SaleAdvancedSearch";
import SaleTimeSelector from "./SaleTimeSelector";
import NumberFormatter from "../../../formatters/NumberFormatter";
import SaleViewer from "./SaleViewer";
import DateFormatter from "../../../formatters/DateFormatter";

import {
  getFields,
  getSales,
  getSaleDetail,
  getComment,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetSales,
  getScroll,
  setSearchContext
} from "../../../redux/actions";
import { DetailAction } from "../../../datatable/components/multipleActions";

import { setUrlWithParams } from "../../../searchContext/URLizer";

import styles from "../styles/styles.css";

interface Props {
  count: any;
  saleFields: any;
  sales: any;
  formatters: any;
  visibleAttributes: any;
  selectedSaleIds: any;
  color: any;
  timePeriod: any;
  isLoading: boolean;
  getSales: Function;
  getFields: Function;
  getSaleDetail: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  resetSales: Function;
  getSalesScroll: Function;
  saleScrollId: string;
}

interface State {}

const advancedSearchKey = AdvancedSearchConstants.SALE_KEY;

class ListSaleView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.setUrlParams({ searchContext });
  }

  async componentDidMount() {
    const { getFields, searchContext } = this.props;
    const name = "sale";

    await getFields({ name });

    await this._onRefresh({ searchContext });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  componentWillUnmount() {
    const { resetSales } = this.props;

    resetSales();
  }

  _onRefresh = async ({ searchContext }) => {
    const {
      tableCount,
      getSales,
      connectionStore: { user }
    } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort },
      timePeriod
    } = searchContext;

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);

    return getSales({
      filters,
      fields: [],
      sort,
      tableCount,
      begin,
      end
    });
  };

  _search = async ({ searchContext }) => {
    const {
      tableCount,
      setSearchContext,
      location: { pathname },
      getSales,
      connectionStore: { user }
    } = this.props;
    const { sort, filters, timePeriod } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);

    return getSales({
      filters,
      fields: [],
      sort,
      tableCount,
      begin,
      end
    });
  };

  _onDetail = (rowId: any) => event => {
    const { getSaleDetail, findComment } = this.props;

    getSaleDetail({ id: rowId });
    findComment({ saleId: rowId });
  };

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  onSelectRow = (rowId, value) => {
    const { selectedSaleIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedSaleIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  _loadMoreRows = () => {
    const { getSalesScroll, saleScrollId } = this.props;

    return getSalesScroll({ scrollId: saleScrollId });
  };

  render() {
    const {
      color,
      count,
      attributes,
      saleFields,
      sales,
      formatters,
      visibleAttributes,
      selectedSaleIds,
      sortDescription,
      loading,
      getMessage,
      searchContext
    } = this.props;

    const DetailButton = () => {
      if (selectedSaleIds.length === 1) {
        const [saleId] = selectedSaleIds;

        return (
          <DetailAction
            onDetail={this._onDetail(saleId)}
            msgKey={"reporting.sale.detail"}
          />
        );
      }

      return null;
    };

    return (
      <div className="reporting reporting-transaction data-table-wrapper">
        <div className={styles["title-wrapper"]}>
          <FormTitle
            color={color}
            titleKey="reporting.sale.title"
            actionKey="reporting.sale.action"
          />

          <div className={styles["countAndRefresh-container"]}>
            <Counter
              loading={loading}
              count={count}
              loadingKey="reporting.sale.loading"
            />
            <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
          </div>
        </div>
        <div className="flex-box">
          <SaleTimeSelector
            onChange={({ searchContext: nextSearchContext }) =>
              this._search({ searchContext: nextSearchContext })
            }
            searchContext={searchContext}
          />
        </div>

        <SaleAdvancedSearch
          onChange={this._search}
          searchContext={searchContext}
        />

        <DataTable
          data={sales}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          i18nKey="reporting.sale.list"
          idAttribute="saleId"
          hideSearchBox={true}
          hasActions={false}
          toggleable={true}
          color={color}
          formatter={formatters}
          disableStatusValues={["Suspended"]}
          onUpdateColumns={this._updateColumns}
          selectionButtonKey={[DetailButton]}
          onRefresh={this._search}
          version="new"
          selectableNew={true}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedSaleIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={count}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />

        <SaleViewer saleFields={saleFields} />
      </div>
    );
  }
}

const normalizeSale = ({ state }) => {
  const {
    sales: { data, scrollId, count },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const sales = data.map(sale => {
    const normalizedSales = fields.reduce((prevField, field) => {
      return {
        ...prevField,
        [field]: get(sale, field)
      };
    }, {});

    return {
      ...normalizedSales
    };
  });

  return {
    count,
    scrollId,
    sales
  };
};

const _getColumns = ({ type, fields: genericFields }) => {
  const fields = genericFields.reduce((prevField, field) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);

  return fields;
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting && columnSetting.sale && columnSetting.sale.length > 0
    ? columnSetting.sale
    : _getColumns({ fields });
};

const currencyFormatter = (val, row) => {
  const currencySymbol = row.currencySymbol;
  const currencyCodeAlpha3 = row.currencyCodeAlpha3;
  const currencyExponent = row.currencyExponent;

  return NumberFormatter.formatAmount(
    val,
    currencyCodeAlpha3 + currencySymbol,
    currencyExponent
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: connectionStore,
    genericFields: { data: fields = [] },
    sales: { selection, loading },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount }
    },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const { count, scrollId: saleScrollId, sales } = normalizeSale({ state });
  const saleFields = _getColumns({ fields, type: "required" });

  const { user } = connectionStore;

  const selectedSaleIds = _.keys(_.pick(selection, _.identity));

  const columnSetting = user ? user.columnSetting : false;

  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const attributes = _getColumns({ fields });

  return {
    loading,
    attributes,
    color,
    count,
    saleScrollId,
    sales,
    saleFields,
    selectedSaleIds,
    selection,
    tableCount,
    visibleAttributes,
    formatters: {
      debitsAmount: currencyFormatter,
      creditsAmount: currencyFormatter,
      debitsReversalAmount: currencyFormatter
    },
    sortDescription,
    connectionStore,
    searchContext
  };
};

const mapDispatchToProps = dispatch => ({
  resetSales: () => dispatch(resetSales()),
  getSales: ({ filters, fields, sort, tableCount, begin, end }) =>
    dispatch(getSales({ filters, fields, sort, tableCount, begin, end })),
  getSalesScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: advancedSearchKey, scrollId })),
  getSaleDetail: ({ id }) => dispatch(getSaleDetail({ id })),
  getFields: ({ name }) => dispatch(getFields({ name })),
  findComment: ({ saleId }) => dispatch(getComment({ commentId: saleId })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "SALES" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "SALES" })),
  toggleSelectAll: () => dispatch(toggleSelectAll({ selectionType: "SALES" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "SALES" })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl: true
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ListSaleView);
