import React, { Component } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import TransactionTimeSelector from "./TransactionTimeSelector";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import TransactionAdvancedSearch from "../transactions/components/TransactionAdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import Counter from "../../datatable/components/Counter";

import DateFormatter from "../../formatters/DateFormatter";
import {
  getPaymentMethodsWidgets,
  getFields,
  resetWidgetsState,
  resetWidgetsStatsState,
  resetWidgetsTrendState,
  setSearchContext
} from "../../redux/actions";
import { WidgetConfig } from "../../home/components/Widget";
import WidgetView from "../../home/components/Widget";
import { WidgetType } from "../../home/models/WidgetType";
import { Refresh } from "../../datatable/components/Refresh";
import { setUrlWithParams } from "../../searchContext/URLizer";

import styles from "./styles/PaymentMethodsView.css";

// @secure

interface Props {
  resetWidgetsTrendState: Function;
  getPaymentMethodsWidgets: Function;
  getFields: Function;
  resetWidgetsState: Function;
  resetWidgetsStatsState: Function;
  filters: any;
  transactionsCounter: any;
}

interface State {}

const advancedSearchKey = AdvancedSearchConstants.TRANSACTION_KEY;

const COUNTER_KEY = "transactionCounter";

class PaymentMethodsView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.setUrlParams({ searchContext });
  }

  async componentDidMount() {
    const { getPaymentMethodsWidgets, getFields, searchContext } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
      timePeriod: periodSelection = {}
    } = searchContext;

    Promise.all([
      getPaymentMethodsWidgets({ filters, periodSelection }),
      getFields({ name: "transaction", version: "v2" })
    ]);
  }

  async componentWillUnmount() {
    const { resetWidgetsState, resetWidgetsStatsState } = this.props;

    return await Promise.all([resetWidgetsState(), resetWidgetsStatsState()]);
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _onRefresh = async ({ searchContext }) => {
    const { getPaymentMethodsWidgets, resetWidgetsState } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      timePeriod: periodSelection
    } = searchContext;

    await resetWidgetsState();
    return getPaymentMethodsWidgets({ filters, periodSelection });
  };

  _search = async ({ searchContext }) => {
    const {
      getPaymentMethodsWidgets,
      resetWidgetsState,
      setSearchContext
    } = this.props;

    const { filters, timePeriod: periodSelection } = searchContext;

    setSearchContext({ context: searchContext, pathname: "cards" });

    await resetWidgetsState();
    return getPaymentMethodsWidgets({ filters, periodSelection });
  };

  render() {
    const {
      color,
      user,
      widgets,
      transactionsCounter = {},
      searchContext
    } = this.props;

    const {
      data: { transactionCount = 0 },
      loading: loadingCounter
    } = transactionsCounter;

    const widgetsSize = {
      [WidgetType.CardAverageBasket]: styles["col-full"],
      [WidgetType.WalletAverageBasket]: styles["col-full"]
    };

    const CounterContainer = ({ loading, count }) => {
      if (loading || count) {
        return (
          <Counter
            loading={loading}
            count={transactionCount}
            loadingKey="reporting.transaction.loading"
          />
        );
      }
      return null;
    };

    return (
      <div className="reporting reporting-activity">
        <div className={styles["title-wrapper"]}>
          <FormTitle
            titleKey="reporting.mainTitle"
            actionKey="reporting.tendertype.mainTitle"
          />

          <div className={styles["countAndRefresh-container"]}>
            <CounterContainer
              loading={loadingCounter}
              count={transactionCount}
            />
            <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
          </div>
        </div>

        <div className="flex-box">
          <TransactionTimeSelector
            onChange={({ searchContext: nextSearchContext }) =>
              this._search({ searchContext: nextSearchContext })
            }
            searchContext={searchContext}
          />
        </div>

        <TransactionAdvancedSearch
          onChange={this._search}
          searchContext={searchContext}
        />
        <br />

        <div
          key={`group-widget`}
          className={classNames(styles.wrapper, "group-widget")}
          ref={container => (this.widgetContainer = container)}
        >
          {widgets.map((config: WidgetConfig) => {
            const { id, type } = config;

            const { [type]: size } = widgetsSize;

            return (
              <WidgetView
                className={classNames(styles.widget)}
                enableTrend={true}
                color={color}
                user={user}
                key={id}
                config={{ ...config, desktopView: true }}
                header={false}
                size={size}
              />
            );
          })}
        </div>
        <style>
          {`
                        .btn-ingenico,.btn-ingenico:focus{
                            color: ${color};
                            border-color: ${color};
                        }
                        .btn-ingenico:hover {
                            color: #FFF;
                            background-color: ${color};
                        }
                    `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: currentUser,
      isCustomer,
      isMerchant,
      levelSettings: _levelSettings
    },
    theme: {
      color: {
        data: { color }
      }
    },
    services: { data: services = [] }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const getTransactionsCount = () => {
    const {
      widgets: { [COUNTER_KEY]: widget }
    } = state.widgetData;

    if (widget) {
      const {
        data: { stats = {} },
        loading,
        error
      } = widget;

      return {
        data: stats,
        loading,
        error
      };
    }

    return {
      data: {}
    };
  };

  const transactionsCounter = getTransactionsCount();

  const canSeeWidget = isCustomer || isMerchant;

  const {
    scope: {
      level: { type: userType, id: levelId }
    }
  } = currentUser;

  const {
    widgets: { data = [], loading, error },
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        timePeriod = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const widgets = data.map(widget => {
    const { name, id, type } = widget;

    const min = DateFormatter.getTimezonedDate(
      timePeriod.startTime,
      currentUser
    );
    const max = DateFormatter.getTimezonedDate(timePeriod.endTime, currentUser);

    return {
      type,
      filters,
      name,
      id,
      period: timePeriod.type,
      userCurrency,
      timePeriod,
      timestamps: {
        min,
        max
      }
    };
  });

  return {
    searchContext,
    transactionsCounter,
    services,
    color,
    user: currentUser,
    userType,
    levelId,
    canSeeWidget,
    widgets,
    loading,
    error
  };
};

const mapDispatchToProps = dispatch => ({
  resetWidgetsState: () => dispatch(resetWidgetsState()),
  resetWidgetsStatsState: () => dispatch(resetWidgetsStatsState()),
  getPaymentMethodsWidgets: ({ filters, periodSelection }) =>
    dispatch(getPaymentMethodsWidgets({ filters, periodSelection })),
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  resetWidgetsTrendState: () => dispatch(resetWidgetsTrendState()),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl: true
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentMethodsView);
