import React, { useState, useEffect, useRef } from "react";
import {
  BrowserMultiFormatReader,
  BarcodeFormat,
  DecodeHintType
} from "@zxing/library";

import styles from "./styles/QRCodeScanner.css";

const InitialState = {
  selectedDevice: "",
  errors: "",
  denied: false
};

const QRCodeScanner = ({ handleResult }) => {
  const [codeReader, setCodeReader] = useState();
  const [started, setStarted] = useState(false);

  const [{ errors, denied }, setResult] = useState(InitialState);

  const refVideo = useRef();

  useEffect(() => {
    if (!codeReader) {
      initScanner();
    }
    return () => {
      stopScanner();
    };
  }, [started]);

  const initScanner = () => {
    const hints = new Map();
    const formats = [
      BarcodeFormat.QR_CODE,
      BarcodeFormat.DATA_MATRIX /*, ...*/
    ];

    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

    const codeReader = new BrowserMultiFormatReader(hints, 1500);

    codeReader
      .decodeOnceFromVideoDevice(undefined, "video")
      .then(result => {
        if (result) {
          console.log("handle scanner");

          // stopScanner();
          return handleResult && handleResult(result.text);
        }
      })
      .catch(error => {
        console.log("error", errors);
        return setResult(state => ({
          ...state,
          denied: true
        }));
      });

    setCodeReader(codeReader);
    setStarted(true);
  };

  const stopScanner = () => {
    codeReader && codeReader.reset();
  };

  if (denied) {
    return (
      <div>
        <div className={styles.denied}>
          <p>You should give access to your camera</p>
        </div>
      </div>
    );
  }
  return (
    <video
      className={styles["video-mask"]}
      ref={refVideo}
      id="video"
      width="100%"
    />
  );
};

export default QRCodeScanner;
