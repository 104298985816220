import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_STORES_LIST_ACTION,
  SUCCESS_GET_STORES_LIST_ACTION,
  FAILURE_GET_STORES_LIST_ACTION,
  PENDING_GET_STORES_ACTION,
  SUCCESS_GET_STORES_ACTION,
  FAILURE_GET_STORES_ACTION,
  RESET_STORES_ACTION,
  PENDING_DELETE_STORES_ACTION,
  FAILURE_DELETE_STORES_ACTION,
  PENDING_UPDATE_STATUS_STORES_ACTION,
  FAILURE_UPDATE_STATUS_STORES_ACTION
} from "../actionsTypes";

export const getStoresList = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getStoresListStart());

  const url = new URL("/stores/search", PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    ({ count, scrollId, searchResults: data }) => {
      dispatch(getStoresListSucceeded({ count, scrollId, data }));

      return data;
    },
    errors => {
      dispatch(getStoresListFailure({ errors }));

      throw errors;
    }
  );
};

const getStoresListStart = () => ({
  type: PENDING_GET_STORES_LIST_ACTION
});

const getStoresListSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_STORES_LIST_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getStoresListFailure = errors => ({
  type: FAILURE_GET_STORES_LIST_ACTION,
  payload: {
    errors
  }
});

export const getStores = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getStoresStart());

  const url = new URL("/stores/search", PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    ({ searchResults: data }) => {
      dispatch(getStoresSucceeded({ data }));

      return data;
    },
    errors => {
      dispatch(getStoresFailure({ errors }));

      throw errors;
    }
  );
};

const getStoresStart = () => ({
  type: PENDING_GET_STORES_ACTION
});

const getStoresSucceeded = ({ data }) => ({
  type: SUCCESS_GET_STORES_ACTION,
  payload: {
    data
  }
});

const getStoresFailure = errors => ({
  type: FAILURE_GET_STORES_ACTION,
  payload: {
    errors
  }
});

export const resetStores = () => ({
  type: RESET_STORES_ACTION
});

//////

export const deleteStores = ({ ids }) => async dispatch => {
  dispatch(deleteStoresStart());

  try {
    const url = new URL("/store/batch", PORTAL_URL);

    return await F.delete(`${url.pathname}${url.search}`, {
      body: ids,
      contentType: ContentTypes.JSON
    });
  } catch (errors) {
    dispatch(deleteStoresFailure({ errors }));

    throw errors;
  }
};

export const deleteStoresStart = () => ({
  type: PENDING_DELETE_STORES_ACTION
});

export const deleteStoresFailure = errors => ({
  type: FAILURE_DELETE_STORES_ACTION,
  payload: {
    errors
  }
});

//////

export const updateStoresStatus = ({ ids, status }) => dispatch => {
  dispatch(updateStoresStatusStart());

  try {
    const url = new URL(`/store/batch/status/${status}`, PORTAL_URL);

    return F.put(`${url.pathname}${url.search}`, {
      body: ids,
      contentType: ContentTypes.JSON
    });
  } catch (errors) {
    dispatch(updateStoresStatusFailure({ errors }));

    throw errors;
  }
};

export const updateStoresStatusStart = () => ({
  type: PENDING_UPDATE_STATUS_STORES_ACTION
});

export const updateStoresStatusFailure = errors => ({
  type: FAILURE_UPDATE_STATUS_STORES_ACTION,
  payload: {
    errors
  }
});
