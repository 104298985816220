import { upperBoundToMonth } from "../utils/BoundMonth";
import F from "../../commons/HTTPFetcher";
import { toPeriodType } from "../../reporting/models/TimePeriod";

const fetchNetRevenueTrends = ({ period, startTime, endTime }) => {
  if (startTime && endTime) {
    return F.get(`/trend/revenues/${period}/${startTime}/${endTime}`);
  }
  return F.get("/trend/revenues");
};

export const loadTrendsRevenues = ({ timePeriod, timestamps }: any) => {
  const { min, max } = timestamps;

  const boundedPeriod = upperBoundToMonth(timePeriod.type);

  return fetchNetRevenueTrends({
    period: boundedPeriod,
    startTime: min,
    endTime: max
  })
    .then(({ netRevenues: { trend: value } }) => {
      return {
        netRevenues: value
      };
    })
    .catch(errors => {
      throw errors;
    });
};

export const getTransactionRevenues = ({
  filters = [],
  timePeriod,
  timestamps
}: any) => {
  const { min: begin, max: end } = timestamps;

  const periodType = toPeriodType(timePeriod.type);

  return Promise.all([
    F.get(
      `/transactions/revenues?period=${periodType}&begin=${begin}&end=${end}&filters=${JSON.stringify(
        filters
      )}`
    ),
    loadTrendsRevenues({
      timePeriod,
      timestamps
    })
  ])
    .then(response => {
      const [
        {
          revenues: { net: value },
          stats: values
        },
        { netRevenues }
      ] = response;
      return {
        value,
        netRevenues,
        values
      };
    })
    .catch(errors => {
      throw errors;
    });
};
