import { Component } from "react";

class If extends Component {
  render() {
    const { test, children } = this.props;

    if (test) {
      return children;
    } else {
      return false;
    }
  }
}

export default If;
