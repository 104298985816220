import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import ConfirmationPopin from "./ConfirmationPopIn";
import CreateSecuredVariables from "./CreateSecuredVariables";
import securedVariablesClient, {
  SecuredVariable
} from "../clients/SecuredVariablesClient";
import { encryptValue } from "../utils/encrypt";
import I18nSpan from "../../i18n/components/I18nSpan";
import ConnectionConstants from "../../connection/constants/ConnectionConstant";

import {
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

import styles from "./../styles/styles.css";

interface State {
  currentUserLevel: string;
  securedVariables: SecuredVariable;
  securedVariablesErrors: string;
  deleteConfirmation: {
    securedVariable: SecuredVariable;
    show: boolean;
  };
}

interface Props {
  userId: string;
  userLevel: string;
}

class SecuredVariablesView extends Component<Props, State> {
  userLevel = {
    [ConnectionConstants.MERCHANT]: "merchants",
    [ConnectionConstants.CUSTOMER]: "customer"
  };

  state = {
    securedVariables: {},
    securedVariablesErrors: "",
    deleteConfirmation: {
      securedVariable: {
        name: ""
      },
      show: false
    },
    currentUserLevel: this.userLevel[this.props.userLevel]
  } as State;

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentDidMount() {
    const { userId } = this.props;
    const { currentUserLevel } = this.state;

    this.getSecuredVariables(currentUserLevel, userId);
  }

  getSecuredVariables = async (userLevel, userId) => {
    try {
      const { securedVariables } = await securedVariablesClient.list(
        userLevel,
        userId
      );

      return this.setState({ securedVariables } as any);
    } catch (error) {
      return this.setState({ securedVariablesErrors: true } as any);
    }
  };

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.securedVariables !== nextProps.securedVariables) {
      const { securedVariables } = nextProps;

      this.setState({ securedVariables } as State);
    }
  }

  _openPopinConfirmDelete = (event: Event, name: string) => {
    event.preventDefault();

    return this.setState(prevState => {
      return {
        ...prevState,
        deleteConfirmation: {
          securedVariable: {
            name
          },
          show: true
        }
      };
    });
  };

  _close = () => {
    return this.setState({ deleteConfirmation: { show: false } });
  };

  _onSubmitSecureVariable = async ({
    securedVariables,
    message
  }: {
    securedVariables: SecuredVariable;
    message: string;
  }) => {
    const {
      userId,
      mshPublicKeyGpg: pubKey,
      addNotificationSuccess
    } = this.props;
    const { currentUserLevel } = this.state as State;
    const { value, name } = securedVariables;

    try {
      const encrypted = await encryptValue({
        userId,
        userLevel: currentUserLevel,
        value,
        pubKey
      });
      await securedVariablesClient.create(currentUserLevel, userId, {
        ...securedVariables,
        value: encrypted
      });

      return this.setState(
        prevState => {
          return {
            ...prevState,
            securedVariables: {
              ...prevState.securedVariables,
              [name]: encrypted
            }
          };
        },
        () => addNotificationSuccess(message)
      );
    } catch (error) {
      const { key } = await error;

      throw key;
    }
  };

  _removeElement = (name: string) => {
    const { securedVariables } = this.state as State;
    delete securedVariables[name];

    return this.setState({ securedVariables } as any);
  };

  _onRemoveSecureVariable = async (name: string) => {
    const { userId, addNotificationSuccess, addNotificationError } = this.props;
    const { currentUserLevel } = this.state;

    this._close();

    try {
      await securedVariablesClient.delete(currentUserLevel, userId, name);
      this._removeElement(name);

      return addNotificationSuccess("securedVariable.message.delete.success");
    } catch {
      return addNotificationError("securedVariable.message.delete.error");
    }
  };

  render() {
    const { userId } = this.props;
    const {
      currentUserLevel,
      securedVariables,
      securedVariablesErrors,
      deleteConfirmation: { securedVariable, show }
    } = this.state as State;

    return (
      <div>
        <div className="col-sm-12">
          <div className={classNames("row", styles.separator)}>
            <CreateSecuredVariables
              userLevel={currentUserLevel}
              userId={userId}
              onSubmit={this._onSubmitSecureVariable}
            />
          </div>
          <div className="row">
            {show && (
              <ConfirmationPopin
                onClose={this._close}
                onCancel={this._close}
                onAccept={this._onRemoveSecureVariable}
                objectName={securedVariable.name}
              />
            )}
            {securedVariablesErrors && (
              <div className={classNames("col-sm-12", styles.errorList)}>
                <I18nSpan msgKey="securedVariable.message.list.error" />
              </div>
            )}
            {securedVariables &&
              !securedVariablesErrors &&
              Object.entries(securedVariables).length === 0 && (
                <div className={classNames("col-sm-12", styles.emptyList)}>
                  <I18nSpan msgKey="securedVariable.noSecuredVariables" />
                </div>
              )}
            {securedVariables &&
              !securedVariablesErrors &&
              Object.entries(securedVariables).map((variable, index) => {
                const [name, value] = variable;

                return (
                  <div
                    className={classNames("row", styles.container)}
                    key={index}
                  >
                    <div className="col-sm-4">
                      <BootstrapInput
                        descriptor={{
                          id: name,
                          readOnly: true,
                          type: "text",
                          inputClass: "col-sm-12",
                          label: "securedVariable.form.name"
                        }}
                        onChange={() => undefined}
                        formValue={name}
                        inputRef={this.setInputRef(name)}
                        name={name}
                      />
                    </div>
                    <div className="col-sm-4">
                      <BootstrapInput
                        descriptor={{
                          id: value,
                          readOnly: true,
                          type: "text",
                          label: "securedVariable.form.value",
                          inputClass: "col-sm-12",
                          className: styles.valueContainer
                        }}
                        inputRef={this.setInputRef(value)}
                        name={value}
                        onChange={() => undefined}
                        formValue={"**************"}
                      />
                    </div>
                    <div
                      className={classNames("col-sm-4", styles.deleteContainer)}
                    >
                      <a
                        onClick={event =>
                          this._openPopinConfirmDelete(event, name)
                        }
                        className="btn btn-circle"
                      >
                        <i className="glyphicon glyphicon-trash" />
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    config: {
      data: { mshPublicKeyGpg = "" }
    }
  } = state;

  return {
    mshPublicKeyGpg
  };
};

const mapDispatchToProps = dispatch => ({
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecuredVariablesView);
