import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import constants from "../../connection/constants/ConnectionConstant";

interface Props {
  renderTransactionAlert: boolean;
  renderFraudAlert: boolean;
  getOffers: Function;
  match: any;
}

interface State {}

class ListAlertView extends Component<Props, State> {
  render() {
    const { renderTransactionAlert, renderFraudAlert } = this.props;

    const transactionAlert = renderTransactionAlert;
    const fraudAlert = renderFraudAlert;

    switch (true) {
      case transactionAlert && fraudAlert: {
        return <Redirect to="/main/reporting/alerts/predefined" />;
      }
      case transactionAlert && !fraudAlert: {
        return <Redirect to="/main/reporting/alerts/advanced" />;
      }
      case !transactionAlert && fraudAlert: {
        return <Redirect to="/main/reporting/alerts/predefined" />;
      }
      default:
        return null;
    }
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      user: {
        scope: {
          level: { type: userType }
        }
      }
    },
    services: { data: services = [] } = { data: [] }
  } = state;

  const hasTransactionAlertService = services.includes("reportingAlert");
  const hasTransactionAlertSmsService = services.includes("reportingAlertSms");

  const renderTransactionAlert =
    hasTransactionAlertService || hasTransactionAlertSmsService;

  switch (userType) {
    case constants.CUSTOMER: {
      return {
        renderTransactionAlert,
        renderFraudAlert: false
      };
    }
    case constants.MERCHANT: {
      // hasTransactionAlertService is in fact for E-mail alert reportingAlert service
      const renderFraudAlert = services.includes("fraudAlert");
      return {
        renderTransactionAlert,
        renderFraudAlert
      };
    }
    default:
      break;
  }
  return {
    renderFraudAlert: false,
    renderTransactionAlert: false
  };
};

export default withRouter(connect(mapStateToProps, null)(ListAlertView));
