import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import Validators from "../../ingenicoForm/validation/Validators";
import I18nSpan from "../../i18n/components/I18nSpan";
import PosConstants from "../constants/PosConstant";
import {
  posErrorNotification,
  requiredMinLength
} from "../validations/PosValidations";
import {
  createPos,
  getStores,
  getMerchants,
  resetPos,
  link,
  loadDownloadPolicies,
  loadPoiContracts,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";
import Utils from "../../sim/Utils";

interface Props {
  getMerchants: Function;
  createPos: Function;
  getStores: Function;
  link: Function;
  loadDownloadPolicies: Function;
  loadPoiContracts: Function;
  resetPos: Function;
  match: any;
  history: any;
  options: any;
  readOnly: any;
  display: any;
  isCustomer: boolean;
}

interface State {
  form: any;
  errors: any;
}

export class CreatePosView extends Component<Props, State> {
  state = {
    form: {
      name: null,
      posId: null,
      storeId: null,
      storeLocation: null,
      autoInit: null,
      downloadPolicy: PosConstants.TRANSAC,
      syncDevice: true,
      poiContract: PosConstants.DEFAULT_POI_CONTRACT,
      merchantPoiId: "",
      merchantId: null
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentDidMount() {
    const {
      getMerchants,
      getStores,
      loadDownloadPolicies,
      loadPoiContracts,
      isCustomer,
      readOnly: { merchantId }
    } = this.props;
    let filters = [];

    if (isCustomer) {
      filters = merchantId
        ? [
            {
              filterType: "array",
              name: "merchant.id",
              operator: "=",
              value: [merchantId]
            }
          ]
        : [];

      getMerchants({
        filters,
        fields: ["merchant.id", "merchant.name"],
        sort: {
          field: "merchant.name.raw",
          order: "ASC"
        }
      });
    }

    getStores({
      filters,
      fields: ["store.id", "store.name"],
      sort: {
        field: "store.name.raw",
        order: "ASC"
      }
    });
    loadDownloadPolicies();
    loadPoiContracts();
  }

  componentWillUnmount() {
    const { resetPos } = this.props;

    resetPos();
  }

  _goToList = () => {
    const { history } = this.props;

    history.push("/main/settings/pos");
  };

  _onExit = e => {
    e.preventDefault();

    const { history } = this.props;

    history.push("/main/settings/pos");
  };

  _onMerchantChangeHandler = async (name, merchant) => {
    const { getStores } = this.props;

    if (merchant === null) {
      await getStores({
        filters: [],
        fields: ["store.id", "store.name"],
        sort: {
          field: "store.name.raw",
          order: "ASC"
        }
      });
    } else {
      const { value } = merchant;
      const filters = value
        ? [
            {
              filterType: "array",
              name: "merchant.id",
              operator: "=",
              value: [value]
            }
          ]
        : [];
      await getStores({
        filters,
        fields: ["store.id", "store.name"],
        sort: {
          field: "store.name.raw",
          order: "ASC"
        }
      });
    }

    return this.setState(
      state => {
        return {
          options: {
            ...state.options,
            stores: this.props.options.stores
          },
          form: {
            ...state.form,
            storeId: null
          }
        };
      },
      () => this._onChangeHandler(name, merchant)
    );
  };

  _onChangeHandler = (name, value) => {
    return this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value === "" ? null : value
        }
      };
    }, this._checkError(name));
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  _autoInitValidation = value => {
    const { t } = this.props;
    const translation = t("pos.form.autoInit.label");

    switch (true) {
      case value && !value.match(/^[a-zA-Z0-9]+$/):
        return new Promise(resolve => {
          resolve({ code: "form.error.asciiCharacters", args: [value] });
        });
      case value && value.length < 4:
        return new Promise(resolve => {
          let r = Validators.sizeOfStringValidator(4, translation)(value);
          resolve(r);
        });
      case value && value.length > 48:
        return new Promise(resolve => {
          let r = Validators.maxStringValidator(48, translation)(value);
          resolve(r);
        });
      default: {
        break;
      }
    }
  };

  _saveBtnHandler = e => {
    e.preventDefault();
    const {
      match: {
        params: { poiId, merchantId }
      },
      createPos,
      link,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    this._checkErrors(() => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        const { form: pos } = this.state;

        createPos({
          pos: {
            ...pos,
            merchantId: pos.merchantId?.value ?? pos.merchantId,
            storeId: pos.storeId?.value ?? pos.storeId
          }
        })
          .then(res => {
            const posId = res.id;
            addNotificationSuccess("pos.create.success");
            merchantId
              ? link({ poiId, posId }).then(() => {
                  addNotificationSuccess("pos.link.success");
                  this._goToList();
                })
              : this._goToList();
          })
          .catch((error: any) =>
            posErrorNotification({ error, addNotificationError, form: data })
          );
      }
    });
  };

  _waitForValidation = (
    names: Array<string>,
    newErrors: Array<String>,
    then
  ) => {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  };

  _checkError = name => {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  };

  _checkErrors = then => {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  };

  render() {
    const { options, readOnly, display, t } = this.props;

    const { form, errors } = this.state;

    return (
      <div>
        <FormTitle titleKey="pos.title" actionKey="pos.list.button.create" />
        <form className="ingenico-form form-horizontal create-pos-form">
          <BootstrapInput
            validation={requiredMinLength(t(`pos.form.name.label`))}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            init123343451
            name="name"
            errors={errors.name}
            required={true}
            descriptor={{
              type: "text",
              label: "pos.form.name.label",
              placeholder: "pos.form.name.placeholder"
            }}
            formValue={form.name}
          />

          {!display.merchant && (
            <BootstrapInput
              onChange={this._onMerchantChangeHandler}
              inputRef={this.setInputRef("merchantId")}
              name="merchantId"
              errors={errors.merchantId}
              descriptor={{
                type: "multipleselectwindowed",
                label: "pos.form.merchantId.label",
                options: options.merchants,
                readOnly: readOnly.merchantId,
                isClearable: true
              }}
              formValue={
                readOnly.merchantId
                  ? {
                      label: options.merchantName,
                      value: readOnly.merchantId
                    }
                  : undefined
              }
            />
          )}

          <BootstrapInput
            validation={value =>
              Promise.resolve(Validators.requiredValidator(value))
            }
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("storeId")}
            name="storeId"
            errors={errors.storeId}
            required={true}
            descriptor={{
              type: "multipleselectwindowed",
              label: "pos.form.storeId.label",
              options: options.stores
            }}
            formValue={form.storeId}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("posReference")}
            name="posReference"
            errors={errors.posReference}
            required={false}
            descriptor={{ type: "text", label: "pos.form.posReference.label" }}
            formValue={form.posReference}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("storeLocation")}
            name="storeLocation"
            errors={errors.storeLocation}
            descriptor={{
              type: "text",
              label: "pos.form.storeLocation.label",
              placeholder: "pos.form.storeLocation.placeholder"
            }}
            formValue={form.storeLocation}
          />

          <BootstrapInput
            validation={this._autoInitValidation}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("autoInit")}
            name="autoInit"
            errors={errors.autoInit}
            descriptor={{
              type: "text",
              label: "pos.form.autoInit.label"
            }}
            formValue={form.autoInit}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("downloadPolicy")}
            name="downloadPolicy"
            errors={errors.downloadPolicy}
            required={true}
            descriptor={{
              type: "select",
              label: "pos.form.downloadPolicy",
              options: options.downloadPolicies
            }}
            formValue={form.downloadPolicy}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("syncDevice")}
            name="syncDevice"
            errors={errors.syncDevice}
            descriptor={{
              type: "checkbox",
              label: "pos.form.syncDevice",
              className: "mtn"
            }}
            formValue={form.syncDevice}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("poiContract")}
            name="poiContract"
            errors={errors.poiContract}
            descriptor={{
              type: "select",
              label: "pos.form.poiContract.label",
              options: options.poiContracts,
              readOnly: readOnly.poiContract
            }}
            formValue={form.poiContract}
          />

          <div className="pull-right">
            <button
              onClick={this._saveBtnHandler}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._onExit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapMerchants = ({ merchants }) => {
  return _.map(merchants, merchant => {
    const {
      merchant: { name, id }
    } = merchant;

    return {
      name,
      id
    };
  });
};

const mapStores = ({ stores }) => {
  return _.map(stores, store => {
    const {
      store: { name, id }
    } = store;

    return {
      name,
      id
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { isCustomer, isMerchant },
    merchants: { data: merchants },
    stores: { data: stores },
    pos: { policiesList: rawDownloadPolicies, contractsList: rawPoiContracts }
  } = state;

  const {
    match: {
      params: { merchantId }
    }
  } = ownProps;

  const merchantOptions = isCustomer ? mapMerchants({ merchants }) : [];

  const merchantName = merchantOptions.find(
    merchant => merchant.id === merchantId
  )?.name;

  const storeOptions = mapStores({ stores });
  const downloadPolicies = rawDownloadPolicies
    ? Utils.makeDataZipObject({
        list: rawDownloadPolicies,
        key: "pos.downloadPolicy"
      })
    : [];
  const poiContracts = rawPoiContracts
    ? Utils.makeDataZipObject({
        list: rawPoiContracts,
        key: "poi.contract"
      })
    : [];

  return {
    options: {
      merchants: merchantOptions,
      merchantName,
      stores: storeOptions,
      downloadPolicies,
      poiContracts
    },
    readOnly: {
      poiContract: !isCustomer,
      merchantId
    },
    display: {
      merchant: isMerchant
    },
    isCustomer
  };
};

const mapDispatchToProps = dispatch => ({
  createPos: ({ pos }) => dispatch(createPos({ pos })),
  resetPos: () => dispatch(resetPos()),
  getMerchants: ({ filters, fields, sort, tableCount }) =>
    dispatch(getMerchants({ filters, fields, sort, tableCount })),
  getStores: ({ filters, fields, sort, tableCount }) =>
    dispatch(getStores({ filters, fields, sort, tableCount })),
  link: ({ poiId, posId }) => dispatch(link({ poiId, posId })),
  loadDownloadPolicies: () => dispatch(loadDownloadPolicies()),
  loadPoiContracts: () => dispatch(loadPoiContracts()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CreatePosView);
