import React, { Component } from "react";
import classNames from "classnames";

import styles from "./styles.css";

interface Props {
  size: string;
  type: string;
}

interface State {}

class Thumbnail extends Component<Props, State> {
  render() {
    const { size = "", type, children } = this.props;

    return (
      <div
        className={classNames(
          "widget-thumbnails",
          styles.widget,
          styles.thumbnail,
          size,
          type
        )}
      >
        <div className={classNames(styles["widget-container"])}>{children}</div>
      </div>
    );
  }
}

export default Thumbnail;
