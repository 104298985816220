import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_MERCHANTS_LIST_ACTION,
  SUCCESS_GET_MERCHANTS_LIST_ACTION,
  FAILURE_GET_MERCHANTS_LIST_ACTION,
  PENDING_GET_MERCHANTS_ACTION,
  SUCCESS_GET_MERCHANTS_ACTION,
  FAILURE_GET_MERCHANTS_ACTION,
  PENDING_DELETE_MERCHANT_ACTION,
  FAILURE_DELETE_MERCHANT_ACTION,
  RESET_MERCHANTS_ACTION,
  PENDING_GET_AMAZON_PAY_SETTINGS_ACTION,
  SUCCESS_GET_AMAZON_PAY_SETTINGS_ACTION,
  FAILURE_GET_AMAZON_PAY_SETTINGS_ACTION,
  PENDING_UPDATE_AMAZON_PAY_SETTINGS_ACTION,
  SUCCESS_UPDATE_AMAZON_PAY_SETTINGS_ACTION,
  FAILURE_UPDATE_AMAZON_PAY_SETTINGS_ACTION
} from "../actionsTypes";

export const getMerchantsList = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getMerchantsListStart());

  const url = new URL("/merchants/search", PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    ({ count, scrollId, searchResults: data }) => {
      dispatch(getMerchantsListSucceeded({ count, scrollId, data }));

      return data;
    },
    errors => {
      dispatch(getMerchantsListFailure({ errors }));

      throw errors;
    }
  );
};

const getMerchantsListStart = () => ({
  type: PENDING_GET_MERCHANTS_LIST_ACTION
});

const getMerchantsListSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_MERCHANTS_LIST_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getMerchantsListFailure = errors => ({
  type: FAILURE_GET_MERCHANTS_LIST_ACTION,
  payload: {
    errors
  }
});

export const getMerchants = ({
  filters,
  fields,
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => dispatch => {
  dispatch(getMerchantsStart());

  const url = new URL("/merchants/search", PORTAL_URL);

  if (tableCount) {
    url.searchParams.append("max", tableCount);
  }

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    ({ searchResults: data }) => {
      dispatch(getMerchantsSucceeded({ data }));

      return data;
    },
    errors => {
      dispatch(getMerchantsFailure({ errors }));

      throw errors;
    }
  );
};

const getMerchantsStart = () => ({
  type: PENDING_GET_MERCHANTS_ACTION
});

const getMerchantsSucceeded = ({ data }) => ({
  type: SUCCESS_GET_MERCHANTS_ACTION,
  payload: {
    data
  }
});

const getMerchantsFailure = errors => ({
  type: FAILURE_GET_MERCHANTS_ACTION,
  payload: {
    errors
  }
});

export const deleteMerchants = ({ merchantIds }) => async dispatch => {
  dispatch(deleteMerchantStart());

  try {
    return await F.delete(`/merchant/batch`, {
      contentType: ContentTypes.JSON,
      body: merchantIds
    });
  } catch (errors) {
    dispatch(deleteMerchantFailure({ errors }));
    throw errors;
  }
};

const deleteMerchantStart = () => ({
  type: PENDING_DELETE_MERCHANT_ACTION
});

const deleteMerchantFailure = errors => ({
  type: FAILURE_DELETE_MERCHANT_ACTION,
  payload: {
    errors
  }
});

export const getAmazonPaySettings = ({ merchantId }) => dispatch => {
  dispatch(getAmazonPaySettingsStart());

  return F.get(`/merchant/${merchantId}/amazonPaySettings`).then(
    amazonPaySettings => {
      dispatch(getAmazonPaySettingsSucceeded(amazonPaySettings));
      return amazonPaySettings;
    },
    errors => {
      dispatch(getAmazonPaySettingsFailure(errors));
      throw errors;
    }
  );
};

const getAmazonPaySettingsStart = () => ({
  type: PENDING_GET_AMAZON_PAY_SETTINGS_ACTION
});

const getAmazonPaySettingsSucceeded = amazonPaySettings => ({
  type: SUCCESS_GET_AMAZON_PAY_SETTINGS_ACTION,
  payload: {
    amazonPaySettings
  }
});

const getAmazonPaySettingsFailure = errors => ({
  type: FAILURE_GET_AMAZON_PAY_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const updateAmazonPaySettings = ({
  merchantId,
  settings
}) => async dispatch => {
  dispatch(updateAmazonPaySettingsStart());

  return F.put(`/merchant/${merchantId}/amazonPaySettings`, {
    body: {
      ...settings
    },
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(updateAmazonPaySettingsSucceeded()),
    errors => {
      dispatch(updateAmazonPaySettingsFailure({ errors }));

      throw errors;
    }
  );
};

const updateAmazonPaySettingsStart = () => ({
  type: PENDING_UPDATE_AMAZON_PAY_SETTINGS_ACTION
});

const updateAmazonPaySettingsSucceeded = () => ({
  type: SUCCESS_UPDATE_AMAZON_PAY_SETTINGS_ACTION
});

const updateAmazonPaySettingsFailure = errors => ({
  type: FAILURE_UPDATE_AMAZON_PAY_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const resetMerchants = () => ({
  type: RESET_MERCHANTS_ACTION
});
