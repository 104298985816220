import F from "../../commons/HTTPFetcher";
import { generateUrl } from "../../commons/utils/url";

export default {
  async isAutoInitIdExisting(autoInitId) {
    const Authorization = `Bearer ${F.jwt}`;

    const url = generateUrl({ url: `/pos/autoInitId/${autoInitId}` });

    const response = await fetch(url, {
      headers: {
        Authorization
      }
    });
    const result = await response.json();

    return result;
  }
};
