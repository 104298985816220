import {
  RESET_AUTOINIT_PROGRAM_STATE_ACTION,
  PENDING_GET_AUTOINIT_PROGRAM_ACTION,
  SUCCESS_GET_AUTOINIT_PROGRAM_ACTION,
  FAILURE_GET_AUTOINIT_PROGRAM_ACTION
} from "../actionsTypes";

const initialState = {
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_AUTOINIT_PROGRAM_ACTION: {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_AUTOINIT_PROGRAM_ACTION: {
      const {
        payload: { defaultProgram }
      } = action;

      return {
        data: defaultProgram,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_AUTOINIT_PROGRAM_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_AUTOINIT_PROGRAM_STATE_ACTION:
      return initialState;
    default:
      return state;
  }
};
