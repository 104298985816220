import * as moment from "moment";
import {
  CreatePredefinedAlert,
  languages,
  PredefinedAlert,
  PredefinedAlertType
} from "./PredefinedAlert";

const humanizeDuration = require("humanize-duration");

export class CreateCompleteRefundOutsidePolicyAlert extends CreatePredefinedAlert {
  constructor(public duration: moment.Duration) {
    super(CompleteRefundOutsidePolicyAlert.TYPE);
    this.duration = duration || moment.duration(1, "days");
  }

  setDuration(minutes: number) {
    this.duration = moment.duration(minutes, "days");
  }
}

export class CompleteRefundOutsidePolicyAlert extends PredefinedAlert {
  static TYPE: PredefinedAlertType = "CompleteRefundOutsidePolicy";
  static I18N_KEY = "complete-refund-outside-policy";

  constructor(public id: string, public timeWindow: moment.Duration) {
    super();
    this.id = id;
    this.timeWindow = timeWindow;
  }

  i18nKey(): string {
    return CompleteRefundOutsidePolicyAlert.I18N_KEY;
  }

  i18nArgs(locale: string): Array<string> {
    return [
      humanizeDuration((this.timeWindow as any)._milliseconds, {
        delimiter: " ",
        largest: 2,
        round: true,
        language: locale,
        languages
      })
    ];
  }
}
