import { constantCase } from "constant-case";

export const setSearchContext = ({
  key,
  context = {},
  pathname,
  updateUrl = false
}) => {
  const name = constantCase(key);
  return {
    type: `SET_${name}_SEARCH_CONTEXT_ACTION`,
    name,
    payload: {
      context,
      pathname,
      updateUrl
    }
  };
};

export const cleanSearchContext = ({
  key,
  context = {},
  pathname,
  updateUrl = false
}) => {
  const name = constantCase(key);
  return {
    type: `CLEAN_${name}_SEARCH_CONTEXT_ACTION`,
    name,
    payload: {
      context,
      pathname,
      updateUrl
    }
  };
};
