import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  FAILURE_CREATE_PROGRAM_ACTION,
  PENDING_CREATE_PROGRAM_ACTION,
  SUCCESS_CREATE_PROGRAM_ACTION,
  RESET_PROGRAM_STATE_ACTION,
  PENDING_GET_PROGRAM_ACTION,
  SUCCESS_GET_PROGRAM_ACTION,
  FAILURE_GET_PROGRAM_ACTION,
  FAILURE_UPDATE_PROGRAM_ACTION,
  PENDING_UPDATE_PROGRAM_ACTION,
  SUCCESS_UPDATE_PROGRAM_ACTION
} from "../actionsTypes";

export const createProgram = ({ program }) => async dispatch => {
  dispatch(createProgramStart());

  try {
    await F.post("/customer/me/program", {
      body: program,
      contentType: ContentTypes.JSON
    });

    return dispatch(createProgramSucceeded(program));
  } catch (errors) {
    dispatch(createProgramFailure({ errors }));
    throw errors;
  }
};

export const createProgramStart = () => ({
  type: PENDING_CREATE_PROGRAM_ACTION
});

export const createProgramSucceeded = (program: any) => ({
  type: SUCCESS_CREATE_PROGRAM_ACTION,
  payload: {
    program
  }
});

export const createProgramFailure = (errors: any) => ({
  type: FAILURE_CREATE_PROGRAM_ACTION,
  payload: {
    errors
  }
});

export const editProgram = ({ program }) => async dispatch => {
  dispatch(editProgramStart());

  try {
    const data = await F.put(`/customer/me/program/${program.id}`, {
      body: program,
      contentType: ContentTypes.JSON
    });

    return dispatch(editProgramSucceeded(data));
  } catch (errors) {
    dispatch(editProgramFailure({ errors }));
    throw errors;
  }
};

export const editProgramStart = () => ({
  type: PENDING_UPDATE_PROGRAM_ACTION
});

export const editProgramSucceeded = ({ program }) => ({
  type: SUCCESS_UPDATE_PROGRAM_ACTION,
  payload: {
    program
  }
});

export const editProgramFailure = errors => ({
  type: FAILURE_UPDATE_PROGRAM_ACTION,
  payload: {
    errors
  }
});

export const getProgram = ({ id }) => async dispatch => {
  dispatch(getProgramStart());

  try {
    const program = await F.get(`/customer/me/program/${id}`);

    dispatch(getProgramSucceeded(program));
  } catch (errors) {
    dispatch(getProgramFailure(errors));
    throw errors;
  }
};

export const getProgramStart = () => ({
  type: PENDING_GET_PROGRAM_ACTION
});

export const getProgramSucceeded = program => ({
  type: SUCCESS_GET_PROGRAM_ACTION,
  payload: {
    program
  }
});

export const getProgramFailure = errors => ({
  type: FAILURE_GET_PROGRAM_ACTION,
  payload: {
    errors
  }
});

export const resetProgramState = () => ({
  type: RESET_PROGRAM_STATE_ACTION
});
