import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import get from "get-value";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { eventSourceListener } from "../../commons/EventSourceListener";
import { SimProviders, SimStatus } from "../constants/SimFieldConstants";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import SimAdvancedSearch from "./SimAdvancedSearch";
import Counter from "../../datatable/components/Counter";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import HoverMenu from "../../components/HoverMenu";
import I18nSpan from "../../i18n/components/I18nSpan";
import { HTTPFetcher } from "../../commons/HTTPFetcher";
import { Refresh } from "../../datatable/components/Refresh";

import {
  EditAction,
  DeleteAction,
  ToggleAction,
  AssignAction
} from "../../datatable/components/multipleActions";

import {
  getFields,
  getSims,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  deleteSims,
  updateSimsStatus,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetSims,
  getScroll,
  deleteEvent,
  updateStatusEvent,
  addNotificationError,
  setSearchContext
} from "../../redux/actions";
import { getNamesFromSelection } from "../../redux/reducers/selection";
import { setUrlWithParams } from "../../searchContext/URLizer";
import UploadFile from "../../components/upload/UploadFile";

import styles from "../styles/styles.css";

interface Props {
  loading: boolean;
  history: any;
  selectedSimIds: Array<string>;
  selection: any;
  simList: Array<any>;
  simCount: number;
  color: any;
  formatter: any;
  tableCount: number;
  simNames: any;
  visibleAttributes: any;
  toggleActivationButtonStatus: string;
  getFields: Function;
  getSims: Function;
  attributes: any;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  activationButtonStatus: Function;
  deleteSims: Function;
  updateSimsStatus: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  resetSims: Function;
  getSimsScroll: Function;
  simScrollId: string;
  updateStatusEvent: Function;
  deleteEvent: Function;
  isIngenico: boolean;
}

interface State {
  fileName: any;
  showPopinSingle: boolean;
}

const advancedSearchKey = AdvancedSearchConstants.SIM_KEY;

class ListSimView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      fileName: "",
      showPopinSingle: false
    };
    this.setUrlParams({ searchContext });
  }

  templateActions = [
    {
      url: HTTPFetcher.generateUrl("/sim/import/template?operation=creation"),
      label: "sim.import.templateList.creation"
    },
    {
      url: HTTPFetcher.generateUrl("/sim/import/template?operation=activation"),
      label: "sim.import.templateList.activation"
    },
    {
      url: HTTPFetcher.generateUrl("/sim/import/template?operation=suspension"),
      label: "sim.import.templateList.suspension"
    },
    {
      url: HTTPFetcher.generateUrl("/sim/import/template?operation=resumption"),
      label: "sim.import.templateList.resumption"
    },
    {
      url: HTTPFetcher.generateUrl("/sim/import/template?operation=deletion"),
      label: "sim.import.templateList.deletion"
    }
  ];

  evtSource = { close: () => undefined } as EventSource;

  async componentDidMount() {
    const { getFields, eventGatewayURL, searchContext } = this.props;
    const name = "sims";

    await getFields({ name });

    await this._onRefresh({ searchContext });

    eventSourceListener({
      type: advancedSearchKey,
      action: this._handleUpdateStatus,
      evtSourceContainer: this,
      callbackWithParams: true,
      eventGatewayURL
    });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _changeStatus({ data, id }) {
    const { status } = data;
    const { updateSimsStatusView } = this.props;
    return updateSimsStatusView({ ids: [id], status, sectionType: "sims" });
  }

  _handleDelete({ id }) {
    const { deleteSim } = this.props;
    return deleteSim({ id, sectionType: "sim" });
  }

  _handleUpdateStatus = event => {
    const { id, eventType, data } = event;

    switch (eventType) {
      case "statusUpdated": {
        return this._changeStatus({ data, id });
      }
      case "deleted": {
        return this._handleDelete({ id });
      }
    }
  };

  componentWillUnmount() {
    const { resetSims } = this.props;

    resetSims();
    this.evtSource.close();
  }

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getSims } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getSims({ filters, sort, tableCount });
  };

  _search = ({ searchContext }) => {
    const {
      getSims,
      tableCount,
      setSearchContext,
      location: { pathname }
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    return getSims({ filters, sort, tableCount });
  };

  _goToCreateSim = () => {
    const { history } = this.props;

    return history.push("/main/sim/new-sim");
  };

  _openPopinSingle = () => {
    const { selection } = this.props;
    const simNames = getNamesFromSelection({
      selection,
      keyName: "sim.id"
    });

    return this.setState({
      showPopinSingle: true,
      simNames
    });
  };

  _closePopinSingle = () => {
    return this.setState({
      showPopinSingle: false
    });
  };

  _deleteSim = async () => {
    const {
      selectedSimIds: ids,
      deleteSims,
      addNotificationError
    } = this.props;

    try {
      await deleteSims({ ids });

      return this._closePopinSingle();
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      addNotificationError(errorKey);

      return this._closePopinSingle();
    }
  };

  _updateStatus = async ({ activationButtonStatus }) => {
    const {
      selectedSimIds: ids,
      updateSimsStatus,
      addNotificationError
    } = this.props;
    const status =
      activationButtonStatus === "Suspended" ? "Activated" : "Suspended";

    try {
      await updateSimsStatus({ ids, status });
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      return addNotificationError(errorKey);
    }
  };

  _isRowEditable = row => {
    const { isIngenico } = this.props;

    return isIngenico ? true : row["sim.IMSI"];
  };

  _onChangeFile = e => {
    this.setState({ fileName: e.target.files[0].name });
  };

  isLocked = (sim: any) => {
    const { operationId = false } = sim;

    return !!operationId;
  };

  notLocked = (sim: any) => {
    return !this.isLocked(sim);
  };

  isActivated = (row: any) => {
    const { ["sim.status"]: status } = row;

    return status !== undefined && status !== SimStatus.INVENTORY;
  };

  lockedIcon = (sim: any) => {
    if (this.isLocked(sim)) {
      const { t } = this.props;
      const lockStyle = {
        color: "black",
        cursor: "inherit"
      };

      return (
        <a className="btn" style={lockStyle}>
          <i
            className="glyphicon glyphicon-remove"
            title={t("sim.list.sierraProcessing")}
            aria-hidden="true"
          />
        </a>
      );
    }
  };

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);
      return acc;
    }, []);
    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  _getSimById = (id: string) => {
    const { simList } = this.props;
    return simList.filter(sim => id === sim["sim.id"])[0];
  };

  _hasIp(sim) {
    const { ["sim.GGSN2"]: ipGGSN2, ["sim.GGSN1"]: ipGGSN1 } = sim;
    return ipGGSN1 && ipGGSN2;
  }

  _isInventory = selectedSimIds => {
    let isInventory = false;

    selectedSimIds.map(id => {
      const sim = this._getSimById(id);
      if (sim && !this.isActivated(sim) && !this.isLocked(sim)) {
        isInventory = true;
      }
    });
    return isInventory;
  };

  onSelectRow = (rowId, value) => {
    const { selectedSimIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedSimIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = selectionDisabled => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll({ selectionDisabled });
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  _loadMoreRows = () => {
    const { getSimsScroll, simScrollId } = this.props;

    return getSimsScroll({ scrollId: simScrollId });
  };

  render() {
    const { showPopinSingle, simNames } = this.state;
    const {
      loading,
      attributes,
      visibleAttributes,
      formatter,
      selectedSimIds,
      simList,
      simCount,
      color,
      sortDescription,
      isIngenico,
      searchContext
    } = this.props;

    const activationButtonStatus = handleToggleActivationButtonStatus({
      selectionIds: selectedSimIds,
      sims: simList
    });

    const AssignButton = () => {
      const [simId] = selectedSimIds;
      const sim = this._getSimById(simId);
      if (
        sim &&
        selectedSimIds.length === 1 &&
        !this.isActivated(sim) &&
        !this.isLocked(sim)
      ) {
        const route =
          sim["sim.operator"] === SimProviders.SIERRA
            ? `/main/sim/activate/sierra/${sim["sim.id"]}`
            : `/main/sim/activate/radius/${sim["sim.id"]}`;

        return <AssignAction route={route} msgKey={"sim.assign.action"} />;
      }

      return null;
    };

    const EditSim = () => {
      const { isIngenico } = this.props;
      const [simId] = selectedSimIds;
      const sim = this._getSimById(simId);
      if (
        sim &&
        selectedSimIds.length === 1 &&
        this.isActivated(sim) &&
        this.notLocked(sim)
      ) {
        const route = `/main/${isIngenico ? "" : "settings/"}sim/edit-sim/${
          sim["sim.id"]
        }`;

        return <EditAction route={route} msgKey={"sim.edit.action"} />;
      }

      return null;
    };

    const ToggleActivationButton = () => {
      const { isIngenico } = this.props;
      const isInventory = this._isInventory(selectedSimIds);

      if (isIngenico) {
        if (!isInventory) {
          return (
            <ToggleAction
              onToggle={() => this._updateStatus({ activationButtonStatus })}
              msgKey={`sim.toggle.${activationButtonStatus}`}
            />
          );
        }

        return null;
      } else {
        return (
          <ToggleAction
            onToggle={() => this._updateStatus({ activationButtonStatus })}
            msgKey={`sim.toggle.${activationButtonStatus}`}
          />
        );
      }
    };

    const DeleteSim = () => {
      const { isIngenico } = this.props;

      if (isIngenico) {
        return <DeleteAction onDelete={this._openPopinSingle} />;
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle titleKey="sim.title" actionKey="sim.list.action" />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={simCount}
                loadingKey="sim.list.loading"
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>

          {isIngenico && (
            <div className={styles["button-container"]} id="sim-buttons">
              <div className={styles["sim-action"]}>
                <HoverMenu
                  titleKey="sim.create.action"
                  icon="icon-plus"
                  trailingIcon="icon-chevron"
                >
                  <li className="new-sierra">
                    <Link to="/main/sim/import-sierra">
                      <I18nSpan msgKey="sim.provider.sierraWireless" />
                    </Link>
                  </li>
                  <li className="new-other">
                    <Link to="/main/sim/new-sim">
                      <I18nSpan msgKey="sim.provider.other" />
                    </Link>
                  </li>
                </HoverMenu>
              </div>

              <UploadFile
                uploadUrl={"/sim/import"}
                templateActions={this.templateActions}
                fileKey="sims"
                tooltip="store.import.template"
                buttonsTitles={{
                  upload: "sim.import.upload",
                  templates: "sim.import.template",
                  selectFile: "sim.import.selectFile"
                }}
                notifications={{
                  success: "sim.import.uploadFile.success",
                  error: "sim.import.uploadFile.error"
                }}
              />
            </div>
          )}
        </div>

        {showPopinSingle && (
          <ConfirmationPopin
            onClosePopin={this._closePopinSingle}
            onClickButton={this._deleteSim}
            objectName={simNames}
          />
        )}

        <SimAdvancedSearch
          searchContext={searchContext}
          onChange={this._search}
        />

        <DataTable
          data={simList}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          i18nKey="sim.list"
          i18nKeyResolver={key =>
            key.startsWith("poi.")
              ? `sim.list.header.${key.replace("poi.", "")}`
              : `sim.list.header.${key}`
          }
          idAttribute="sim.id"
          toggleable={true}
          hideSearchBox={true}
          formatter={formatter}
          disableStatusValues={["Suspended"]}
          disablePredicate={this.isLocked}
          isRowEditable={this._isRowEditable}
          onRefresh={this._search}
          onUpdateColumns={this._updateColumns}
          selectableNew={true}
          selectionButtonKey={[
            ToggleActivationButton,
            DeleteSim,
            EditSim,
            AssignButton
          ]}
          hasActions={false}
          version="new"
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedSimIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={simCount}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const handleToggleActivationButtonStatus = ({ selectionIds, sims }) => {
  const selection = sims.reduce((prevSelectedSim, selectedSim) => {
    const selectedSimId = selectedSim["sim.id"];

    if (selectionIds.includes(selectedSimId)) {
      return {
        ...prevSelectedSim,
        [selectedSimId]: selectedSim
      };
    }

    return prevSelectedSim;
  }, {});

  const isAllActivated = selectionIds.every(simId => {
    const { [simId]: selected } = selection;
    return selected && selection[simId]["sim.status"] === "Activated";
  });

  return isAllActivated ? "Activated" : "Suspended";
};

const simsWithStatus = ({ getMessage, state }) => {
  const {
    sims: { data, scrollId, count },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const sims = data.map(sim => {
    const {
      sim: { status, operationId }
    } = sim;

    const normalizedSim = fields.reduce((prevField: any, field: any) => {
      return {
        ...prevField,
        operationId,
        [field]: get(sim, field)
      };
    }, {});

    return {
      ...normalizedSim,
      statusi18n: getMessage(`dataTable.state.${status}`)
    };
  });

  return {
    count,
    scrollId,
    sims
  };
};

interface GetColumnsParams {
  type: string;
  fields: Array<string>;
}

const _getColumns = ({ type, fields }: GetColumnsParams) => {
  return fields.reduce((prevField: Array<string>, field: any) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isIngenico },
    genericFields: { data: fields },
    sims: { selection, loading },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount, eventGatewayURL }
    },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const { t: getMessage } = ownProps;

  const {
    count: simCount,
    scrollId: simScrollId,
    sims: simList
  } = simsWithStatus({
    getMessage,
    state
  });

  const selectedSimIds = _.keys(_.pick(selection, _.identity));

  const columnSetting = user ? user.columnSetting : false;

  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const attributes = _getColumns({ fields });

  const contractFormatter = (val: any, row: any) => {
    if (row["sim.operator"] === SimProviders.SIERRA) {
      return row.offerName;
    } else {
      return val ? getMessage(`sim.values.contract.${val}`) : "";
    }
  };

  const operatorFormatter = (val: any) => {
    return val ? getMessage(`sim.values.sim.operator.${val}`) : val;
  };

  return {
    simScrollId,
    loading,
    attributes,
    color,
    simCount,
    simList,
    selectedSimIds,
    selection,
    formatter: {
      "sim.contract": contractFormatter,
      "sim.operator": operatorFormatter,
      "merchant.selectedTags": (selectedTags: any) =>
        _.values(selectedTags).join(", ")
    },
    tableCount,
    visibleAttributes,
    sortDescription,
    isIngenico,
    eventGatewayURL,
    searchContext
  };
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  const test =
    columnSetting &&
    columnSetting.simSetting &&
    columnSetting.simSetting.length > 0
      ? columnSetting.simSetting
      : _getColumns({ fields });
  return test;
};

const mapDispatchToProps = dispatch => ({
  resetSims: () => dispatch(resetSims()),
  getSims: ({ filters, fields, sort, tableCount }) =>
    dispatch(getSims({ filters, fields, sort, tableCount })),
  getSimsScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: advancedSearchKey, scrollId })),
  getFields: ({ name }) => dispatch(getFields({ name })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "SIMS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "SIMS" })),
  toggleSelectAll: ({ selectionDisabled }) =>
    dispatch(toggleSelectAll({ selectionType: "SIMS", selectionDisabled })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "SIMS" })),
  deleteSims: ({ ids }) => dispatch(deleteSims({ ids })),
  updateSimsStatus: ({ ids, status }) =>
    dispatch(updateSimsStatus({ ids, status })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  updateSimsStatusView: ({ ids, status, sectionType }) =>
    dispatch(updateStatusEvent({ ids, status, sectionType })),
  deleteSim: ({ id, sectionType }) =>
    dispatch(deleteEvent({ id, sectionType })),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isIngenico } = stateProps;

  if (isIngenico) {
    return {
      ...stateProps,
      ...ownProps,
      ...dispatchProps,
      getSims: ({ filters, fields, sort, tableCount }) =>
        dispatchProps.getSims({ filters, fields, sort, tableCount })
    };
  }
  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    getSims: ({ filters, fields, sort, tableCount }) =>
      dispatchProps.getSims({
        filters: [
          ...filters,
          {
            filterType: "array",
            name: "sim.status",
            operator: "notEqual",
            value: ["Inventory"]
          }
        ],
        fields,
        sort,
        tableCount
      })
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(ListSimView);
