import React, { Component } from "react";
import { withRouter } from "react-router";
import _ from "lodash";
import { connect } from "react-redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";

import {
  getDefaultAutoinitProgram,
  createDefaultAutoinitProgram,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

class CreateAutoinitDefaultProgramView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(nextProps) {
    const { form, errors } = nextProps;

    return this.setState({
      form,
      errors
    });
  }

  componentDidMount() {
    const { getDefaultAutoinitProgram } = this.props;

    getDefaultAutoinitProgram();
  }

  _goToList = () => {
    const { history } = this.props;

    return history.push("/main/customer");
  };

  _noop = () => {
    const requiredPromise = Promise.resolve();

    return Promise.all([requiredPromise]);
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this._checkErrors);
  };

  _onExit = e => {
    e.preventDefault();
    const { history } = this.props;

    return history.push("/main/customer");
  };

  _checkErrors = then => {
    const waitForValidation = (nameList, newErrors) => {
      if (_.isEmpty(nameList)) {
        this.setState({ errors: newErrors }, then);
      } else {
        const name: any = _.first(nameList);
        const value = _.get(this.state.form, name);
        const refsValue = _.get(this.inputRefs, name);

        const errors = refsValue
          ? [refsValue.props.validation(value)]
          : [this._noop()];
        Promise.all(errors).then(errors => {
          newErrors[name] = _.chain(errors)
            .flatten()
            .compact()
            .value();
          waitForValidation(_.tail(nameList), newErrors);
        });
      }
    };

    return waitForValidation(_.keys(this.state.form), {});
  };

  _saveBtnHandler = e => {
    e.preventDefault();
    const {
      saveDefault,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    this._checkErrors(() => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const { defaultProgram } = this.state.form;

        return saveDefault(defaultProgram)
          .then(() => {
            addNotificationSuccess("program.edit.success");

            return this._goToList();
          })
          .catch(() => addNotificationError("notification.error"));
      }
    });
  };

  render() {
    return (
      <div>
        <FormTitle titleKey="program.title" actionKey="program.create.action" />
        <div className="col-sm-8">
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("defaultProgram")}
            name="defaultProgram"
            errors={this.state.errors.defaultProgram}
            required={false}
            descriptor={{
              type: "jsonarea",
              className: "defaultProgram",
              label: "program.form.content.label"
            }}
            formValue={this.state.form.defaultProgram}
          />

          <div className="pull-right button" style={{ marginTop: "22px" }}>
            <button
              onClick={this._saveBtnHandler}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._onExit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    autoinitProgram: { data: defaultProgram }
  } = state;

  return {
    form: {
      defaultProgram
    },
    errors: {}
  };
};

const mapDispatchToProps = dispatch => ({
  getDefaultAutoinitProgram: () => dispatch(getDefaultAutoinitProgram()),
  saveDefault: defaultProgram =>
    dispatch(createDefaultAutoinitProgram(defaultProgram)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAutoinitDefaultProgramView)
);
