import _ from "lodash";
import * as log from "loglevel";
import { Sema } from "async-sema";

import * as ContentTypes from "./constants/HTTPContentTypes";
import { AUTHENTICATION_STORAGE_KEY } from "../redux/actions/auth";
import { history } from "../history";
import { store } from "../redux/store";
import { refresh, addNotificationError } from "../redux/actions";

const TOKEN_REGEX = /Bearer\s*(.*)/;
const UNAUTHORIZED_HTTP_CODE = 401;
const FORBIDDEN_HTTP_CODE = 403;
const authorizationHeaderName = "Authorization";
const contentTypeHeaderName = "Content-type";
const countHeaderName = "X-total-count";
const scrollIdHeaderName = "X-scroll-id";

const download = require("downloadjs");

const templateRegex = /attachment; filename=(.+)/;

export class HTTPFetcher {
  jwt: string;
  refresh_token: string;
  expiresAt: number;
  jwtHandler: (jwt: string) => void;
  lock: Sema;
  cscUser: boolean;

  constructor() {
    const token = localStorage.getItem("token");
    const tokenExpiresAt = localStorage.getItem("tokenExpiresAt");
    const refresh_token = token ? JSON.parse(token).refresh_token : "";
    const expiresAt = tokenExpiresAt ? parseInt(tokenExpiresAt, 10) : 0; // https://github.com/evert/fetch-mw-oauth2/blob/master/src/fetch-wrapper.ts#L213
    const currentUser = localStorage.getItem(AUTHENTICATION_STORAGE_KEY);
    const cscUser = currentUser ? JSON.parse(currentUser).cscUser : false;

    this.jwt = localStorage.getItem(JWT_KEY) || "";
    this.refresh_token = refresh_token;
    this.expiresAt = expiresAt;
    this.jwtHandler = () => undefined;
    this.lock = new Sema(1);
    this.cscUser = cscUser;
  }

  get(url: string, version?: string): Promise<any> {
    const headers = new Headers();
    headers.append(contentTypeHeaderName, ContentTypes.JSON.value);

    return this.fetchUrl("GET", HTTPFetcher.generateUrl(url, version), headers);
  }

  async head(url: string, version?: string): Promise<any> {
    const headers = new Headers();

    if (this.jwt) {
      if (!this.cscUser) await this.refreshToken();

      headers.append(authorizationHeaderName, `Bearer ${this.jwt}`);
    }

    const response = await fetch(HTTPFetcher.generateUrl(url, version), {
      method: "HEAD",
      credentials: "same-origin",
      headers
    });
    if (this.cscUser) this.extractJwt(response);

    return response;
  }

  post<BodyType>(
    url: String,
    body?: ObjectBody | StringBody,
    version?: String
  ): Promise<any> {
    const headers = new Headers();
    if (body && body.contentType) {
      headers.append(contentTypeHeaderName, body.contentType.value);
    }

    const transformedBody = body ? HTTPFetcher.generateBody(body) : null;

    return this.fetchUrl(
      "POST",
      HTTPFetcher.generateUrl(url, version),
      headers,
      transformedBody
    );
  }

  async importFile<BodyType>(
    url: String,
    body?: ObjectBody | StringBody,
    defaultFilename: string = "download",
    version?: String
  ): Promise<any> {
    const headers = new Headers();
    if (body && body.contentType) {
      headers.append(contentTypeHeaderName, body.contentType.value);
    }

    if (this.jwt) {
      if (!this.cscUser) await this.refreshToken();

      headers.append(authorizationHeaderName, `Bearer ${this.jwt}`);
    }

    const transformedBody = body ? HTTPFetcher.generateBody(body) : null;

    return fetch(HTTPFetcher.generateUrl(url, version), {
      method: "POST",
      body: transformedBody as any,
      headers
    }).then(resp => {
      if (resp.ok) {
        if (this.cscUser) this.extractJwt(resp);

        const headers = resp.headers;
        const filenameFromServer = headers.get("content-disposition");

        let name = defaultFilename;
        if (
          filenameFromServer !== null &&
          templateRegex.test(filenameFromServer)
        ) {
          const eventualFilename = templateRegex.exec(filenameFromServer);
          if (eventualFilename && eventualFilename[1]) {
            name = eventualFilename[1];
          }
        }
        resp.blob().then(blob => {
          download(blob, name);
        });
      } else {
        throw resp.text();
      }
    });
  }

  async export(
    url: string,
    body?: ObjectBody | StringBody,
    defaultFilename: string = "download"
  ): Promise<any> {
    const headers = new Headers();
    if (body) {
      headers.append(contentTypeHeaderName, body.contentType.value);
    }

    if (this.jwt) {
      if (!this.cscUser) await this.refreshToken();

      headers.append(authorizationHeaderName, `Bearer ${this.jwt}`);
    }

    const transformedBody = body ? HTTPFetcher.generateBody(body) : null;

    return fetch(url, {
      method: "POST",
      headers,
      body: transformedBody as any
    }).then(resp => {
      if (resp.ok) {
        if (this.cscUser) this.extractJwt(resp);

        const headers = resp.headers;
        const filenameFromServer = headers.get("content-disposition");

        let name = defaultFilename;
        if (
          filenameFromServer !== null &&
          templateRegex.test(filenameFromServer)
        ) {
          const eventualFilename = templateRegex.exec(filenameFromServer);
          if (eventualFilename && eventualFilename[1]) {
            name = eventualFilename[1];
          }
        }
        resp.blob().then(blob => {
          download(blob, name);
        });
      } else {
        throw resp.text();
      }
    });
  }

  async exportNew(url: string, body?: ObjectBody | StringBody): Promise<any> {
    const headers = new Headers();
    if (body) {
      headers.append(contentTypeHeaderName, body.contentType.value);
    }

    if (this.jwt) {
      if (!this.cscUser) await this.refreshToken();

      headers.append(authorizationHeaderName, `Bearer ${this.jwt}`);
    }

    const transformedBody = body ? HTTPFetcher.generateBody(body) : null;

    const resp = await fetch(url, {
      method: "POST",
      headers,
      body: transformedBody as any
    });
    if (resp.ok) {
      if (this.cscUser) this.extractJwt(resp);

      const headers = resp.headers;
      const filenameFromServer = headers.get("content-disposition");

      let name: string = "download";
      if (
        filenameFromServer !== null &&
        templateRegex.test(filenameFromServer)
      ) {
        const eventualFilename = templateRegex.exec(filenameFromServer);
        if (eventualFilename && eventualFilename[1]) {
          name = eventualFilename[1];
        }
      }

      const { path } = await resp.json();

      return { path, name };
    } else {
      throw resp.text();
    }
  }

  put<BodyType>(
    url: String,
    body?: ObjectBody | StringBody,
    version?: String
  ): Promise<any> {
    const headers = new Headers();
    if (body) {
      headers.append(contentTypeHeaderName, body.contentType.value);
    }

    const transformedBody = body ? HTTPFetcher.generateBody(body) : null;

    return this.fetchUrl(
      "PUT",
      HTTPFetcher.generateUrl(url, version),
      headers,
      transformedBody
    );
  }

  patch<BodyType>(
    url: String,
    body?: ObjectBody | StringBody,
    version?: String
  ): Promise<any> {
    const headers = new Headers();
    if (body) {
      headers.append(contentTypeHeaderName, body.contentType.value);
    }

    const transformedBody = body ? HTTPFetcher.generateBody(body) : null;

    return this.fetchUrl(
      "PATCH",
      HTTPFetcher.generateUrl(url, version),
      headers,
      transformedBody
    );
  }

  delete(
    url: String,
    body?: ObjectBody | StringBody,
    version?: String
  ): Promise<any> {
    const headers = new Headers();
    if (body) {
      headers.append(contentTypeHeaderName, body.contentType.value);
    }

    const transformedBody = body ? HTTPFetcher.generateBody(body) : null;

    return this.fetchUrl(
      "DELETE",
      HTTPFetcher.generateUrl(url, version),
      headers,
      transformedBody
    );
  }

  async download(
    url: string,
    defaultFilename: string = "download"
  ): Promise<any> {
    if (!this.cscUser) await this.refreshToken();

    return fetch(url, {
      method: "GET",
      credentials: "same-origin",
      headers: {
        authorization: `Bearer ${this.jwt}`
      }
    }).then(resp => {
      if (resp.ok) {
        if (this.cscUser) this.extractJwt(resp);

        const headers = resp.headers;
        const filenameFromServer = headers.get("content-disposition");

        let name = defaultFilename;
        if (
          filenameFromServer !== null &&
          templateRegex.test(filenameFromServer)
        ) {
          const eventualFilename = templateRegex.exec(filenameFromServer);
          if (eventualFilename && eventualFilename[1]) {
            name = eventualFilename[1];
          }
        }
        resp.blob().then(blob => {
          download(blob, name);
        });
      } else {
        throw resp.text();
      }
    });
  }

  private async fetchUrl(
    method: string,
    url: string,
    headers: Headers,
    body?: Object
  ): Promise<any> {
    try {
      if (this.jwt) {
        if (!this.cscUser) await this.refreshToken();

        headers.append(authorizationHeaderName, `Bearer ${this.jwt}`);
      }
      const response = await fetch(url, {
        method,
        credentials: "include",
        body: body as any,
        headers
      });
      if (this.cscUser) this.extractJwt(response);

      return this.handleResponse(response);
    } catch (error) {
      // TODO add endpoint to log errorrs: { url, method, body, headers, error };
      throw error;
    }
  }

  static generateUrl = (url: String, version: String = DEFAULT_API_VERSION) => {
    const versionApiUrl = version ? `/${version}` : "";

    return `${versionApiUrl}${url}`;
  };

  private updateJwt() {
    localStorage.setItem(JWT_KEY, this.jwt);

    return this.jwtHandler(this.jwt);
  }

  public updateJwtFrom(token: String) {
    this.jwt = token;

    return this.updateJwt();
  }

  public updateTokenFrom(token: any) {
    const { access_token, refresh_token, expires_in: expiresIn } = JSON.parse(
      token
    );
    const expires_in = parseInt(expiresIn, 10);

    this.jwt = access_token;
    this.refresh_token = refresh_token;
    this.expiresAt = Date.now() + expires_in * 1000;
  }

  private extractJwt(response: Response) {
    const authorizationHeader = response.headers.get(authorizationHeaderName);

    if (authorizationHeader && TOKEN_REGEX.test(authorizationHeader)) {
      const possiblyTokenJwt = TOKEN_REGEX.exec(authorizationHeader);

      if (possiblyTokenJwt) {
        this.jwt = possiblyTokenJwt[1];

        return this.updateJwt();
      }
    }
  }

  private responseHandling = async (response: Response, headers?: Headers) => {
    const contentType = headers.get(contentTypeHeaderName);

    switch (true) {
      case _.includes(contentType, ContentTypes.JSON.value): {
        const json = await response.json();
        const count = headers.get(countHeaderName);
        const scrollId = headers.get(scrollIdHeaderName);

        if (count) {
          return {
            count,
            searchResults: json,
            ...(scrollId && { scrollId })
          };
        }

        return json;
      }
      case headers.has("content-disposition"): {
        return response.blob().then(blob => {
          return URL.createObjectURL(blob);
        });
      }
      default: {
        return response.text();
      }
    }
  };

  private checkStatus(response: Response, headers?: Headers) {
    const { status } = response;

    switch (status) {
      case UNAUTHORIZED_HTTP_CODE: {
        log.warn("Request not-authorized, please login.");
        this.cancelJwt();

        break;
      }
      case FORBIDDEN_HTTP_CODE: {
        log.warn("Request forbidden");

        if (history.location.pathname !== "/main") history.push("/");

        store.dispatch(addNotificationError("navigation.notFound"));

        break;
      }
      default:
        break;
    }

    throw this.responseHandling(response, headers);
  }

  private handleResponse(response: Response) {
    const { headers, ok } = response;

    if (ok) {
      return this.responseHandling(response, headers);
    } else {
      return this.checkStatus(response, headers);
    }
  }

  private cancelJwt() {
    this.jwt = "";
    localStorage.removeItem(JWT_KEY);

    return this.jwtHandler(this.jwt);
  }

  private async refreshToken(boundary = 10) {
    await this.lock.acquire();

    try {
      if (Date.now() > this.expiresAt - boundary * 1000) {
        // lower expiresAt by 10 seconds to prevent unwanted 401
        const {
          access_token,
          refresh_token,
          expires_in
        } = await store.dispatch(refresh(this.refresh_token));

        this.jwt = access_token;
        this.refresh_token = refresh_token;
        this.expiresAt = Date.now() + expires_in * 1000;
      }
    } catch (errors) {
      throw errors;
    } finally {
      this.lock.release();
    }
  }

  private static serialize(obj: object) {
    return Object.keys(obj)
      .reduce((a: Array<any>, k) => {
        a.push(`${k}=${encodeURIComponent(obj[k])}`);

        return a;
      }, [])
      .join("&");
  }

  private static generateBody({ contentType, body }) {
    if (contentType instanceof ContentTypes.FORMClass) {
      return HTTPFetcher.serialize(body);
    } else if (contentType instanceof ContentTypes.JSONClass) {
      return JSON.stringify(body);
    } else {
      return body;
    }
  }
}

interface Body<BodyType> {
  contentType?:
    | ContentTypes.JSONClass
    | ContentTypes.FORMClass
    | ContentTypes.TEXTClass;
  body?: BodyType;
}

interface ObjectBody extends Body<object> {
  contentType?: ContentTypes.JSONClass | ContentTypes.FORMClass;
}

interface StringBody extends Body<string> {
  contentType?: ContentTypes.TEXTClass;
}

const F = new HTTPFetcher();
Object.seal(F);

export default F;
