import * as moment from "moment";
import {
  SettlementFileFormat,
  SettlementFileFormatConstants
} from "./SettlementFileFormat";

// @secure

export default class AlipayReconciliationSettings {
  static buildFromForm(alipayReconciliationForm) {
    const hostAndPort = alipayReconciliationForm.host
      ? alipayReconciliationForm.host.split(":")
      : [];
    if (hostAndPort.length > 1) {
      alipayReconciliationForm.host = hostAndPort[0];
      alipayReconciliationForm.port = parseInt(hostAndPort[1]);
    }

    return {
      partnerId: alipayReconciliationForm.partnerId,
      host: alipayReconciliationForm.host,
      port: alipayReconciliationForm.port,
      email: alipayReconciliationForm.email,
      user: alipayReconciliationForm.user,
      key: alipayReconciliationForm.key
    };
  }

  static wrapFromServer(alipayReconciliation) {
    const hostAndPort = alipayReconciliation.port
      ? `${alipayReconciliation.host}:${alipayReconciliation.port}`
      : alipayReconciliation.host;

    return {
      alipayReconciliationConfiguration: true,
      partnerId: alipayReconciliation.partnerId,
      host: hostAndPort,
      email: alipayReconciliation.email,
      user: alipayReconciliation.user,
      keyDefined: alipayReconciliation.keyDefined
    };
  }
}
