import {
  PENDING_GET_LOGO_ACTION,
  SUCCESS_GET_LOGO_ACTION,
  FAILURE_GET_LOGO_ACTION,
  PENDING_GET_COLOR_ACTION,
  SUCCESS_GET_COLOR_ACTION,
  FAILURE_GET_COLOR_ACTION,
  PENDING_GET_CUSTOM_CSS_ACTION,
  SUCCESS_GET_CUSTOM_CSS_ACTION,
  SET_MODE_ACTION
} from "../actionsTypes";
import { THEME_MODE } from "../actions/theme";

const getDarkMode = () => {
  if (localStorage.getItem(THEME_MODE)) {
    return localStorage.getItem(THEME_MODE) === "dark";
  }

  return matchMedia("(prefers-color-scheme: dark)").matches;
};

const initialState = {
  logo: {
    data: null,
    loading: false,
    error: false,
    errors: {}
  },
  color: {
    data: {
      color: ""
    },
    loading: false,
    error: false,
    errors: {}
  },
  customCss: {
    data: {
      css: ""
    },
    loading: false,
    error: false,
    errors: {}
  },
  darkMode: getDarkMode()
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_LOGO_ACTION: {
      return {
        ...state,
        logo: {
          data: initialState.logo.data,
          loading: true,
          error: false,
          errors: {}
        }
      };
    }
    case PENDING_GET_COLOR_ACTION: {
      return {
        ...state,
        color: {
          data: initialState.color.data,
          loading: true,
          error: false,
          errors: {}
        }
      };
    }
    case PENDING_GET_CUSTOM_CSS_ACTION: {
      return {
        ...state,
        customCss: {
          ...state.customCss,
          data: initialState.customCss.data,
          loading: true,
          error: false,
          errors: {}
        }
      };
    }
    case SUCCESS_GET_LOGO_ACTION: {
      const {
        payload: { logo: data }
      } = action;

      return {
        ...state,
        logo: {
          data: `data:image/png;base64,${data}`,
          loading: false,
          error: false,
          errors: {}
        }
      };
    }
    case SUCCESS_GET_COLOR_ACTION: {
      const {
        payload: { color: data }
      } = action;

      return {
        ...state,
        color: {
          data,
          loading: false,
          error: false,
          errors: {}
        }
      };
    }
    case SUCCESS_GET_CUSTOM_CSS_ACTION: {
      const {
        payload: { css }
      } = action;

      return {
        ...state,
        customCss: {
          ...state.customCss,
          data: { css },
          loading: false,
          error: false,
          errors: {}
        }
      };
    }
    case FAILURE_GET_LOGO_ACTION: {
      const errors = action.payload;

      return {
        ...state,
        logo: {
          data: initialState.logo.data,
          loading: false,
          error: true,
          ...errors
        }
      };
    }
    case SET_MODE_ACTION: {
      const {
        payload: { darkMode }
      } = action;

      return {
        ...state,
        darkMode
      };
    }
    case FAILURE_GET_COLOR_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        color: {
          data: initialState.color.data,
          loading: false,
          error: true,
          errors
        }
      };
    }
    default:
      return state;
  }
};
