import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import get from "get-value";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import DataTable from "../../datatable/components/DataTable";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import TimePeriod from "../../reporting/models/TimePeriod";
import FilteredById from "../../components/FilteredById";
import AuditAdvancedSearch from "./AuditAdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import DateFormatter from "../../formatters/DateFormatter";
import Counter from "../../datatable/components/Counter";
import AuditTimeSelector from "./AuditTimeSelector";

import AuditDetailViewer from "./AuditDetailViewer";

import {
  getFields,
  getAudits,
  getScroll,
  setSearchContext
} from "../../redux/actions";

import styles from "../styles/styles.css";
import { setUrlWithParams } from "../../searchContext/URLizer";
import { Refresh } from "../../datatable/components/Refresh";

const advancedSearchKey = AdvancedSearchConstants.AUDIT_KEY;

class AuditView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      selectedId: null,
      showDocumentDetails: false,
      documentDetails: null
    };
    this.setUrlParams({ searchContext });
  }

  async componentDidMount() {
    const { getFields, searchContext } = this.props;

    await getFields({ name: "audit" });

    await this._onRefresh({ searchContext });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _search = ({ searchContext }) => {
    const {
      getAudits,
      tableCount,
      setSearchContext,
      location: { pathname },
      user
    } = this.props;
    const { sort, filters: prevFilters, timePeriod } = searchContext;

    const { selectedId, fixedFilters } = this.state;

    const filters = selectedId ? fixedFilters : prevFilters;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);

    return getAudits({ filters, sort, tableCount, begin, end });
  };

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getAudits, user } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: prevFilters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort },
      timePeriod
    } = searchContext;

    const { selectedId, fixedFilters } = this.state;

    const filters = selectedId ? fixedFilters : prevFilters;

    const begin = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const end = DateFormatter.getTimezonedDate(timePeriod.endTime, user);

    return getAudits({ filters, sort, tableCount, begin, end });
  };

  _filterDocumentById = documentId => {
    const fixedFilters = [
      {
        filterType: "string",
        name: "id",
        operator: "=",
        value: documentId
      }
    ];

    const { searchContext } = this.props;

    return this.setState({ fixedFilters, selectedId: documentId }, () => {
      return this._onRefresh({ searchContext });
    });
  };

  _onDocumentIdFilterClose = () => {
    const { searchContext } = this.props;

    return this.setState({ selectedId: null }, () => {
      return this._onRefresh({ searchContext });
    });
  };

  _displayDocumentDetails = ({ details }: any) => {
    this.setState(state => {
      return {
        documentDetails: details,
        showDocumentDetails: !state.showDocumentDetails
      };
    });
  };

  _hideDocumentDetails = () => {
    this.setState({
      documentDetails: null,
      showDocumentDetails: false
    });
  };

  _loadMoreRows = () => {
    const { getAuditsScroll, auditScrollId } = this.props;

    return getAuditsScroll({ scrollId: auditScrollId });
  };

  render() {
    const {
      eventsCount,
      events,
      rawEvents,
      formatter,
      attributes,
      filterByDocumentIdTitle,
      documentDetailsTitle,
      loading,
      searchContext,
      sortDescription,
      t
    } = this.props;
    const { selectedId, showDocumentDetails, documentDetails } = this.state;

    const filterByDocumentId = row => (
      <a
        className="btn btn-circle"
        title={filterByDocumentIdTitle}
        onClick={() => this._filterDocumentById(row.id)}
      >
        <i className="glyphicon glyphicon-filter" />
      </a>
    );

    const detailsByDocumentId = (row, rawData) => {
      return (
        <a
          className="btn btn-circle"
          title={documentDetailsTitle}
          onClick={() => {
            const rowData = rawData.find(item => item.id === row.id);
            this._displayDocumentDetails({ details: rowData });
          }}
        >
          <i className="glyphicon glyphicon-eye-open" />
        </a>
      );
    };

    return (
      <div className="data-table-wrapper auditEvents">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle titleKey="monitoring.title" actionKey="audit.title" />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={eventsCount}
                loadingKey="audit.list.loading"
              />

              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>
        </div>

        <AuditDetailViewer
          showModal={showDocumentDetails}
          documentDetails={documentDetails}
          onClose={this._hideDocumentDetails}
        />

        <AuditTimeSelector
          onChange={({ searchContext: nextSearchContext }) =>
            this._search({ searchContext: nextSearchContext })
          }
          searchContext={searchContext}
          disabled={this.state.selectedId != null}
        />

        <AuditAdvancedSearch
          searchContext={searchContext}
          onChange={this._search}
          disabled={selectedId != null}
        />

        <FilteredById
          id={selectedId}
          i18nKey="audit.documentId"
          onClose={this._onDocumentIdFilterClose}
        />

        <DataTable
          data={events}
          rawData={rawEvents}
          attributes={attributes}
          idAttribute="id"
          formatter={formatter}
          isColumnSettable={true}
          i18nKey="audit.list"
          actionsElement={[filterByDocumentId, detailsByDocumentId]}
          hideSearchBox={true}
          onRefresh={this._search}
          version="new"
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={eventsCount}
          sortKey={advancedSearchKey}
          searchContext={searchContext}
          sortDescription={sortDescription}
        />
      </div>
    );
  }
}

const _getColumns = ({ fields: genericFields, type }) => {
  const fields = genericFields.reduce((prevField, field) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);

  return fields;
};

const normalizeAudit = ({ state }) => {
  const {
    audits: { data: rawEvents, count, scrollId },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ fields: genericFields, type: "required" });
  const events = rawEvents.map(event => {
    const normalizedEvent = fields.reduce((prevField, field) => {
      if (field === "data.name") {
        return {
          ...prevField,
          "data.name": get(event, field)
        };
      }
      return {
        ...prevField,
        [field]: get(event, field)
      };
    }, {});

    return {
      ...normalizedEvent
    };
  });

  return {
    count,
    scrollId,
    events,
    rawEvents
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    genericFields: { data: fields },
    audits: { loading },
    config: {
      data: { tableCount }
    },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      },
      timePeriod
    },
    searchContext: { data: searchContext }
  } = state;
  const { t } = ownProps;

  const {
    count: eventsCount,
    events,
    rawEvents: rawEvents,
    scrollId: auditScrollId
  } = normalizeAudit({ state });
  const timestampFormatter = {
    timestamp(val) {
      if (val) {
        val = new Date(parseInt(val));
      }

      return DateFormatter.formatDateTime(val, user);
    }
  };

  const attributes = _getColumns({ fields });
  const filterByDocumentIdTitle = t("dataTable.filterByDocumentId");
  const documentDetailsTitle = t("dataTable.documentDetails");

  return {
    user,
    loading,
    timePeriod: timePeriod || TimePeriod.MONTH_TO_DATE,
    eventsCount,
    events,
    rawEvents,
    auditScrollId,
    formatter: timestampFormatter,
    tableCount,
    attributes,
    filterByDocumentIdTitle,
    documentDetailsTitle,
    searchContext,
    sortDescription
  };
};

const mapDispatchToProps = dispatch => ({
  getFields: ({ name }) => dispatch(getFields({ name })),
  getAudits: ({ filters, fields, sort, tableCount, begin, end }) =>
    dispatch(getAudits({ filters, fields, sort, tableCount, begin, end })),
  getAuditsScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "audit", scrollId })),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AuditView);
