import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  FAILURE_CREATE_OFFER_ACTION,
  PENDING_CREATE_OFFER_ACTION,
  SUCCESS_CREATE_OFFER_ACTION,
  RESET_OFFER_STATE_ACTION,
  PENDING_GET_OFFER_ACTION,
  SUCCESS_GET_OFFER_ACTION,
  FAILURE_GET_OFFER_ACTION,
  FAILURE_UPDATE_OFFER_ACTION,
  PENDING_UPDATE_OFFER_ACTION,
  SUCCESS_UPDATE_OFFER_ACTION
} from "../actionsTypes";

export const createOffer = ({ offer }) => dispatch => {
  dispatch(createOfferStart());

  return F.post("/offer", {
    body: offer,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(createOfferSucceeded()),
    errors => {
      dispatch(createOfferFailure({ errors }));

      throw errors;
    }
  );
};

const createOfferStart = () => ({
  type: PENDING_CREATE_OFFER_ACTION
});

const createOfferSucceeded = () => ({
  type: SUCCESS_CREATE_OFFER_ACTION
});

const createOfferFailure = (errors: any) => ({
  type: FAILURE_CREATE_OFFER_ACTION,
  payload: {
    errors
  }
});

export const editOffer = ({ offer }) => dispatch => {
  dispatch(editOfferStart());

  return F.put(`/offer/${offer.id}`, {
    body: offer,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(editOfferSucceeded()),
    errors => {
      dispatch(editOfferFailure({ errors }));

      throw errors;
    }
  );
};

const editOfferStart = () => ({
  type: PENDING_UPDATE_OFFER_ACTION
});

const editOfferSucceeded = () => ({
  type: SUCCESS_UPDATE_OFFER_ACTION
});

const editOfferFailure = errors => ({
  type: FAILURE_UPDATE_OFFER_ACTION,
  payload: {
    errors
  }
});

export const getOffer = offerId => dispatch => {
  dispatch(getOfferStart());

  return F.get(`/offer/${offerId}`).then(
    offer => dispatch(getOfferSucceeded(offer)),
    errors => {
      dispatch(getOfferFailure(errors));

      throw errors;
    }
  );
};

const getOfferStart = () => ({
  type: PENDING_GET_OFFER_ACTION
});

const getOfferSucceeded = offer => ({
  type: SUCCESS_GET_OFFER_ACTION,
  payload: {
    offer
  }
});

const getOfferFailure = errors => ({
  type: FAILURE_GET_OFFER_ACTION,
  payload: {
    errors
  }
});

export const resetOfferState = () => ({
  type: RESET_OFFER_STATE_ACTION
});
