import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import TimePeriods from "../../reporting/models/TimePeriod";

const TimeSelector = ({
  t,
  selected = 0,
  onChange,
  darkMode: prefersDarkMode
}) => {
  const timePeriods = [
    TimePeriods.INTRADAY,
    TimePeriods.WEEK_TO_DATE,
    TimePeriods.MONTH_TO_DATE
  ];

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        margin: "2.78vh 0 2.78vh 0"
      }
    })
  );

  const useStylesButtons = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "uppercase",
        color: prefersDarkMode ? "#454545" : "#c2c2c2",
        borderRadius: "10px",
        "&$contained": {
          color: prefersDarkMode ? "#FFFFFF" : "#000",
          backgroundColor: prefersDarkMode ? "#3B3B3B" : "#e0e0e0"
        },
        height: "5.29vh",
        width: "calc((100vw / 2) - 5.33vw)"
      },
      contained: {}
    })
  );

  const classes = useStyles();

  const classesButton = useStylesButtons();

  const [value, setValue] = useState(selected);

  const matches = useMediaQuery(`(max-width: 768px)`);

  const isSelected = (v, pos) => ({
    ...(v === pos ? { variant: "contained" } : {})
  });

  if (matches) {
    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Button
            {...isSelected(value, 0)}
            onClick={e => {
              setValue(0);
              onChange(timePeriods[0]);
            }}
            classes={{
              root: classesButton.root,
              contained: classesButton.contained
            }}
            disableElevation
            disableRipple
          >
            {t("periods.today")}
          </Button>
          <Button
            {...isSelected(value, 1)}
            onClick={() => {
              setValue(1);
              onChange(timePeriods[1]);
            }}
            classes={{
              root: classesButton.root,
              contained: classesButton.contained
            }}
            disableElevation
            disableRipple
          >
            {t("periods.week")}
          </Button>
          {/*<Button*/}
          {/*{...isSelected(value, 2)}*/}
          {/*onClick={() => {*/}
          {/*setValue(2);*/}
          {/*onChange(timePeriods[2]);*/}
          {/*}}*/}
          {/*classes={{*/}
          {/*root: classesButton.root,*/}
          {/*contained: classesButton.contained*/}
          {/*}}*/}
          {/*disableElevation*/}
          {/*disableRipple*/}
          {/*>*/}
          {/*{t("periods.month")}*/}
          {/*</Button>*/}
        </Grid>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state, ownProps) => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, null)
)(TimeSelector);
