import Grid from "@material-ui/core/Grid/Grid";
import classNames from "classnames";
import {
  Button,
  createStyles,
  Input,
  InputLabel,
  makeStyles,
  Theme
} from "@material-ui/core";
import React, { useState } from "react";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

export default ({ onCreate }) => {
  const { t } = useTranslation();

  const { darkMode: prefersDarkMode } = useSelector(state =>
    mapStateToProps(state)
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        height: "calc(var(--vh, 1vh) * 25.28)",
        backgroundColor: prefersDarkMode ? "#303030" : "#FFFFFF",
        boxShadow: "0 5px 20px 0 rgba(0,0,0,0.03)",
        borderRadius: "10px",
        margin: "calc(var(--vh, 1vh) * 2.78) 0",
        position: "relative"
      },
      rootSetup: {
        height: "calc(var(--vh, 1vh) * 28.31)",
        padding: "20px 20px 0 20px"
      },
      formControl: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        borderRadius: "6px",
        height: "36px",
        border: "initial",
        boxShadow: "initial",
        marginBottom: "5px"
      },
      formControlDaily: {
        background: "rgba(250,202,12,0.10)"
      },
      formControlWeekly: {
        background: "rgba(229,75,75,0.10)"
      },
      formControlMonthly: {
        background: "rgba(0,195,215,0.10)"
      },
      label: {
        position: "absolute",
        left: "12px",
        top: "50%",
        marginTop: "-5px",
        opacity: "0.75",
        fontWeight: 700,
        fontSize: "10px"
      },
      labelDaily: {
        color: "#FACA0C"
      },
      labelWeekly: {
        color: "#E54B4B"
      },
      labelMonthly: {
        color: "#00C3D7"
      }
    })
  );

  const useStylesGrid = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "50%"
      },
      rootInit: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "33%"
      },
      rootSetup: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column"
      },
      container: {
        height: "100%"
      },
      containerSetup: {
        flexWrap: "nowrap",
        height: "calc(var(--vh, 1vh) * 14.36)"
      },
      setupText: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        fontWeight: 700
      },
      containerSetupButton: {
        padding: "0 4vw calc(var(--vh, 1vh) * 2.07) 4vw"
      },
      containerSetupButtonSetGoals: {
        padding: "0"
      }
    })
  );

  const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "uppercase",
        backgroundColor: prefersDarkMode ? "#3B3B3B" : "#E5E5E5",
        borderRadius: "5px",
        width: "100%",
        fontWeight: "bold",
        height: "calc(var(--vh, 1vh) * 5.8)",
        fontSize: "11px",
        color: prefersDarkMode ? "#FFFFFF" : "#404041",
        textAlign: "center",
        "&:hover": {
          backgroundColor: "#E5E5E5"
        }
      },
      edit: {
        position: "absolute",
        bottom: 10,
        left: 10,
        height: "20px",
        width: "40px",
        padding: 0,
        backgroundColor: "#F5F5F5",
        borderRadius: "5px",
        fontSize: "9px",
        color: "#C5C5C5"
      },
      contained: {}
    })
  );

  const useStylesInput = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%"
      },
      inputDaily: {
        color: "#FACA0C",
        fontWeight: 700,
        fontSize: "12px",
        textAlign: "center"
      },
      inputWeekly: {
        color: "#E54B4B",
        fontWeight: 700,
        fontSize: "12px",
        textAlign: "center"
      },
      inputMonthly: {
        color: "#00C3D7",
        fontWeight: 700,
        fontSize: "12px",
        textAlign: "center"
      },
      underline: {
        borderBottom: "none",
        "&:before": {
          borderBottom: "none"
        },
        "&:hover:not($disabled):before": {
          borderBottom: "none"
        },
        "&:after": {
          borderBottom: "none"
        }
      },
      focused: {},
      disabled: {}
    })
  );

  const classesInput = useStylesInput();

  const classesButton = useStylesButton();

  const classesGrid = useStylesGrid();

  const classes = useStyles();

  const [daily, setDaily] = useState();
  const [monthly, setMonthly] = useState();
  const [weekly, setWeekly] = useState();

  return (
    <Grid container className={classesGrid.container}>
      <Grid container className={classesGrid.containerSetup}>
        <Grid item className={classesGrid.root}>
          <div
            className={classNames(
              "form-control",
              classes.formControl,
              classes.formControlDaily
            )}
          >
            <InputLabel
              className={classNames(classes.label, classes.labelDaily)}
              htmlFor="my-input"
            >
              {t("widget.objectives.daily")}
            </InputLabel>
            <Input
              id="my-input"
              aria-describedby="my-helper-text"
              type="number"
              classes={{
                root: classesInput.inputDaily,
                disabled: classesInput.disabled,
                underline: classesInput.underline,
                input: classesInput.inputDaily
              }}
              defaultValue={0}
              onChange={event => {
                setDaily(parseInt(event.target.value));
              }}
            />
          </div>
          <div
            className={classNames(
              "form-control",
              classes.formControl,
              classes.formControlWeekly
            )}
          >
            <InputLabel
              className={classNames(classes.label, classes.labelWeekly)}
              htmlFor="my-input"
            >
              {t("widget.objectives.weekly")}
            </InputLabel>
            <Input
              id="my-input"
              aria-describedby="my-helper-text"
              type="number"
              classes={{
                root: classesInput.inputWeekly,
                disabled: classesInput.disabled,
                underline: classesInput.underline,
                input: classesInput.inputWeekly
              }}
              defaultValue={0}
              onChange={event => {
                setWeekly(parseInt(event.target.value));
              }}
            />
          </div>
          <div
            className={classNames(
              "form-control",
              classes.formControl,
              classes.formControlMonthly
            )}
          >
            <InputLabel
              className={classNames(classes.label, classes.labelMonthly)}
              htmlFor="my-input"
            >
              {t("widget.objectives.monthly")}
            </InputLabel>
            <Input
              id="my-input"
              aria-describedby="my-helper-text"
              type="number"
              classes={{
                root: classesInput.inputMonthly,
                disabled: classesInput.disabled,
                underline: classesInput.underline,
                input: classesInput.inputMonthly
              }}
              defaultValue={0}
              onChange={event => {
                setMonthly(parseInt(event.target.value));
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container className={classesGrid.containerSetupButtonSetGoals}>
        <Button
          classes={{
            root: classesButton.root,
            contained: classesButton.contained
          }}
          onClick={() => {
            return (
              onCreate &&
              onCreate({
                daily,
                monthly,
                weekly
              })
            );
          }}
        >
          {t("widget.objectives.setupButton.done")}
        </Button>
      </Grid>
    </Grid>
  );
};
