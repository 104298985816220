import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_SCHEDULED_REPORTS_ACTION,
  SUCCESS_GET_SCHEDULED_REPORTS_ACTION,
  FAILURE_GET_SCHEDULED_REPORTS_ACTION,
  PENDING_GET_FREQUENCIES_REPORT_ACTION,
  SUCCESS_GET_FREQUENCIES_REPORT_ACTION,
  FAILURE_GET_FREQUENCIES_REPORT_ACTION
} from "../actionsTypes";
import Scheduled from "../../scheduled/models/Scheduled";

export const getScheduledReports = () => async dispatch => {
  dispatch(getScheduledReportsStart());

  return F.get("/scheduled-reports")
    .then(scheduledList => {
      const scheduledReports = scheduledList.map(Scheduled.wrapFromServer);
      dispatch(getScheduledReportsSucceeded({ scheduledReports }));
      return scheduledReports;
    })
    .catch(errors => {
      dispatch(getScheduledReportsFailure({ errors }));
      throw errors;
    });
};

const getScheduledReportsStart = () => ({
  type: PENDING_GET_SCHEDULED_REPORTS_ACTION
});

const getScheduledReportsSucceeded = ({ scheduledReports }) => ({
  type: SUCCESS_GET_SCHEDULED_REPORTS_ACTION,
  payload: {
    scheduledReports
  }
});

const getScheduledReportsFailure = errors => ({
  type: FAILURE_GET_SCHEDULED_REPORTS_ACTION,
  payload: {
    errors
  }
});

export const getFrequencies = () => dispatch => {
  dispatch(getFrequenciesStart());

  return F.get("/scheduled-reports/frequencies")
    .then(frequencies => {
      dispatch(getFrequenciesSucceeded({ frequencies }));
      return frequencies;
    })
    .catch(errors => {
      dispatch(getFrequenciesFailure({ errors }));
      throw errors;
    });
};

const getFrequenciesStart = () => ({
  type: PENDING_GET_FREQUENCIES_REPORT_ACTION
});

const getFrequenciesSucceeded = ({ frequencies }) => ({
  type: SUCCESS_GET_FREQUENCIES_REPORT_ACTION,
  payload: {
    frequencies
  }
});

const getFrequenciesFailure = errors => ({
  type: FAILURE_GET_FREQUENCIES_REPORT_ACTION,
  payload: {
    errors
  }
});
