import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_SCHEMES_ACTION,
  SUCCESS_GET_SCHEMES_ACTION,
  FAILURE_GET_SCHEMES_ACTION,
  PENDING_UPLOAD_SCHEME_ACTION,
  FAILURE_UPLOAD_SCHEME_ACTION,
  SUCCESS_UPLOAD_SCHEME_ACTION,
  PENDING_DELETE_SCHEME_ACTION,
  FAILURE_DELETE_SCHEME_ACTION,
  SUCCESS_DELETE_SCHEME_ACTION
} from "../actionsTypes";

export const getSchemes = () => async dispatch => {
  dispatch(getSchemesStart());

  return F.get("/scheme")
    .then(schemes => {
      return dispatch(getSchemesSucceeded({ schemes }));
    })
    .catch(errors => {
      dispatch(getSchemesFailure({ errors }));

      throw errors;
    });
};

const getSchemesStart = () => ({
  type: PENDING_GET_SCHEMES_ACTION
});

const getSchemesSucceeded = ({ schemes }) => ({
  type: SUCCESS_GET_SCHEMES_ACTION,
  payload: {
    schemes
  }
});

const getSchemesFailure = errors => ({
  type: FAILURE_GET_SCHEMES_ACTION,
  payload: {
    errors
  }
});

export const uploadScheme = scheme => async dispatch => {
  dispatch(uploadSchemeStart());

  return F.post("/scheme", {
    body: scheme
  }).then(
    () => dispatch(uploadSchemeSucceeded()),
    errors => {
      dispatch(uploadSchemeFailure({ errors }));

      throw errors;
    }
  );
};

const uploadSchemeStart = () => ({
  type: PENDING_UPLOAD_SCHEME_ACTION
});

const uploadSchemeSucceeded = () => ({
  type: SUCCESS_UPLOAD_SCHEME_ACTION
});

const uploadSchemeFailure = errors => ({
  type: FAILURE_UPLOAD_SCHEME_ACTION,
  errors
});

export const deleteScheme = schemeId => async dispatch => {
  dispatch(deleteSchemeStart());

  return F.delete(`/scheme/${schemeId}`).then(
    () => dispatch(deleteSchemeSucceeded(schemeId)),
    errors => {
      dispatch(deleteSchemeFailure(errors));

      throw errors;
    }
  );
};

const deleteSchemeStart = () => ({
  type: PENDING_DELETE_SCHEME_ACTION
});

const deleteSchemeSucceeded = schemeId => ({
  type: SUCCESS_DELETE_SCHEME_ACTION,
  payload: {
    schemeId
  }
});

const deleteSchemeFailure = errors => ({
  type: FAILURE_DELETE_SCHEME_ACTION,
  errors
});
