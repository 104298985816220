import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import AdvancedSearch from "../../advancedSearch/components/AdvancedSearch";

interface Props {
  tableCount: any;
  getStores: Function;
  getStoresFields: Function;
  fields: Array<any>;
  storeFields: any;
  searchFilters: any;
  onChange: Function;
  searchContext: any;
}

interface State {
  tableCount: any;
}

class StoreAdvancedSearch extends Component<Props, State> {
  render() {
    const {
      searchFilters,
      storeFields = [],
      searchContext,
      onChange
    } = this.props;

    return (
      <div>
        <AdvancedSearch
          name={AdvancedSearchConstants.STORE_KEY}
          searchFilters={searchFilters}
          onChange={onChange}
          genericFields={storeFields}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    genericFields: { data: fields },
    config: {
      data: { tableCount }
    }
  } = state;

  const searchFilters = fields
    ? fields.reduce((prevField: any, field: any) => {
        const { key, type, searchable } = field;

        if (searchable) {
          const tagsKey = /\b(selectedTags).([^.]+)/g.exec(key);
          prevField.push({
            i18nKey: `store.list.header.${key}`,
            rawLabel: tagsKey ? tagsKey[2] : null,
            name: key,
            type
          });
        }

        return prevField;
      }, [])
    : [];

  return { searchFilters, storeFields: fields, tableCount };
};

export default withRouter(connect(mapStateToProps, null)(StoreAdvancedSearch));
