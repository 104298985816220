import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import TransactionsPie from "../../../chart/components/chart/TransactionsPie";
import { WidgetConfig } from "../Widget";
import LoadingChart from "./LoadingChart";
import { getData } from "../../utils/DataProvider";

interface Data {
  countTransactionsTypes?: any;
}

interface Props {
  getData: Function;
  data: Data;
  loading: boolean;
  error?: boolean;
  title: string;
  color: string;
  id: string;
  config: WidgetConfig;
}

class PaymentMethodsCount extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { user } = config;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ user, config });
    }
  }

  render() {
    const {
      data = {},
      title,
      color,
      loading,
      config: { defaultPaletteId },
      t
    } = this.props;

    const titleWidget = t(title);

    const format =
      "<b>{point.name}</b>: {point.percentage:.2f}% ({point.value})";

    const { countTransactionsTypes = [] } = data;

    const series = [
      {
        data: countTransactionsTypes.map(c => ({
          name: t(`reporting.comparison.${c.key.toLowerCase()}`),
          y: c.doc_count,
          value: c.doc_count
        }))
      }
    ];

    return (
      <LoadingChart loading={loading} color={color}>
        <TransactionsPie
          color={color}
          title={titleWidget}
          series={series}
          dataLabelsFormat={format}
          palette={defaultPaletteId}
        />
      </LoadingChart>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  const {
    widgets: { [id]: widget }
  } = state.widgetData;
  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;
    return {
      data: stats,
      loading,
      error
    };
  }
  return {
    data: {},
    loading: true
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentMethodsCount);
