import F from "../../commons/HTTPFetcher";
import { constantCase } from "constant-case";

export const getAlerts = ({ sectionType }) => async dispatch => {
  const name = constantCase(sectionType);
  dispatch(getAlertsStart({ name }));

  try {
    const alerts = await F.get(`/${sectionType}-alert/list`);

    return dispatch(getAlertsSucceeded({ name, alerts }));
  } catch (errors) {
    dispatch(getAlertsFailure({ name, errors }));

    throw errors;
  }
};

export const getAlertsStart = ({ name }) => ({
  type: `PENDING_GET_${name}_ALERTS_ACTION`
});

export const getAlertsSucceeded = ({ name, alerts }) => ({
  type: `SUCCESS_GET_${name}_ALERTS_ACTION`,
  payload: {
    alerts
  }
});

export const getAlertsFailure = ({ name, errors }) => ({
  type: `FAILURE_GET_${name}_ALERTS_ACTION`,
  payload: {
    errors
  }
});
