import React, { Component } from "react";

import NotificationContainer from "../../notifications/components/NotificationContainer";
import I18nSpan from "../../i18n/components/I18nSpan";
import { LoginCopyright } from "./LoginCopyright";
import classNames from "classnames";

import styles from "../styles/styles.css";

interface Props {
  history: any;
  customCss: any;
  apiGatewayCscURL: any;
  getCustomCss: Function;
  getConfig: Function;
}

export class LoginDesktopWrapper extends Component<Props, {}> {
  render() {
    const { children } = this.props;

    return (
      <div className={`vertical-center login-page ${styles["login-page"]}`}>
        <div className={styles["login-holder"]}>
          <h1 className={styles.welcomemsh}>
            <I18nSpan msgKey="login.logo" />
          </h1>
          <div className="login-box">
            <div>
              <h1 className={styles.welcometo}>
                <I18nSpan msgKey="login.welcome" />
              </h1>
              <div className="logo">
                <div
                  className={classNames(
                    styles["login-customer-logo"],
                    "login-customer-logo"
                  )}
                ></div>
                <p className={styles["login-logo-container"]}>
                  <span className={styles["login-logo-wrapper"]}>
                    <I18nSpan
                      msgKey="login.logo"
                      class={styles["login-logo"]}
                    />
                    <I18nSpan
                      msgKey="login.portal"
                      class={styles["login-portal"]}
                    />
                  </span>
                </p>
              </div>
            </div>
            {children}
          </div>
          <LoginCopyright />
        </div>
        <NotificationContainer />
      </div>
    );
  }
}
