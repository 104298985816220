import React, { Component } from "react";
import _ from "lodash";
import get from "get-value";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import I18nSpan from "../../i18n/components/I18nSpan";
import DataTable from "../../datatable/components/DataTable";
import CatalogItemAdvancedSearch from "./CatalogItemAdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import Counter from "../../datatable/components/Counter";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import NumberFormatter from "../../formatters/NumberFormatter";
import { eventSourceListener } from "../../commons/EventSourceListener";
import { Refresh } from "../../datatable/components/Refresh";
import {
  getCatalogItems,
  deleteCatalogItems,
  getFields,
  getScroll,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetCatalogItems,
  addNotificationError,
  deleteEvent,
  setSearchContext
} from "../../redux/actions";

import {
  EditAction,
  DeleteAction,
  UploadPhotoAction
} from "../../datatable/components/multipleActions";
import { getNamesFromSelection } from "../../redux/reducers/selection";

import styles from "../styles/ListCatalogItemView.css";
import { setUrlWithParams } from "../../searchContext/URLizer";

interface Props {
  history: any;
  getCatalogItems: Function;
  deleteCatalogItems: Function;
  getFields: Function;
  catalogItems: any;
  catalogItemsCount: any;
  getCatalogScroll: any;
  catalogScrollId: string;
  attributes: any;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  selection: any;
  selectedCatalogItemIds: any;
  tableCount: any;
  color: any;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
}

interface State {
  catalogItemNames: Array<string>;
  showDeleteConfirmation: boolean;
}

const advancedSearchKey = AdvancedSearchConstants.CATALOG_KEY;

class ListCatalogItemView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      showDeleteConfirmation: false
    };
    this.setUrlParams({ searchContext });
  }
  formatter = {
    "catalogItem.selectedTags": (selectedTags: any) =>
      _.values(selectedTags).join(", ")
  };

  evtSource = { close: () => undefined } as EventSource;

  async componentDidMount() {
    const { getFields, eventGatewayURL, searchContext } = this.props;
    const name = "catalog";

    await getFields({ name });

    await this._onRefresh({ searchContext });

    eventSourceListener({
      type: AdvancedSearchConstants.CATALOG_SSE_KEY,
      action: this._handleUpdateStatus,
      evtSourceContainer: this,
      callbackWithParams: true,
      eventGatewayURL
    });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _handleDelete({ id }) {
    const { deleteCatalogItem } = this.props;
    return deleteCatalogItem({ id, sectionType: "catalogitem" });
  }

  _handleUpdateStatus = event => {
    const { id, eventType } = event;

    switch (eventType) {
      case "deleted": {
        return this._handleDelete({ id });
      }
    }
  };

  componentWillUnmount() {
    const { resetCatalogItems } = this.props;

    resetCatalogItems();
    this.evtSource.close();
  }

  _onRefresh = async ({ searchContext }) => {
    const { tableCount, getCatalogItems } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getCatalogItems({ filters, fields: [], sort, tableCount });
  };

  _search = async ({ searchContext }) => {
    const {
      tableCount,
      setSearchContext,
      location: { pathname },
      getCatalogItems
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    return getCatalogItems({ filters, fields: [], sort, tableCount });
  };

  _goToCreateCatalogItem = () => {
    const { history } = this.props;

    history.push("/main/settings/catalog/new-catalogItem");
  };

  _showDeletePopin = () => {
    const { selection } = this.props;
    const catalogItemsNames = getNamesFromSelection({
      selection,
      keyName: "catalogItem.name"
    });

    return this.setState({
      showDeleteConfirmation: true,
      catalogItemsNames
    });
  };

  _closeDeletePopin = () => {
    this.setState({
      showDeleteConfirmation: false
    });
  };

  _updateColumns = (columns: any) => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  _deleteCatalogItem = async () => {
    const {
      selectedCatalogItemIds: ids,
      deleteCatalogItems,
      addNotificationError
    } = this.props;

    try {
      await deleteCatalogItems({ ids });

      return this._closeDeletePopin();
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      addNotificationError(errorKey);

      return this._closeDeletePopin();
    }
  };

  onSelectRow = (rowId, value) => {
    const { selectedCatalogItemIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedCatalogItemIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  _loadMoreRows = () => {
    const { getCatalogScroll, catalogScrollId } = this.props;

    return getCatalogScroll({ scrollId: catalogScrollId });
  };

  render() {
    const { showDeleteConfirmation, catalogItemsNames } = this.state;
    const {
      visibleAttributes,
      color,
      attributes,
      selectedCatalogItemIds,
      loading,
      catalogItemsCount,
      catalogItems,
      sortDescription,
      searchContext
    } = this.props;

    const hasPhoto = (catalogItems, id) => {
      let test = false;
      _.map(catalogItems, catalogItem => {
        if (
          catalogItem["catalogItem.id"] === id &&
          catalogItem["catalogItem.photoMd5Hash"]
        ) {
          test = true;
        }
      });
      return test;
    };

    const UploadPhoto = () => {
      if (selectedCatalogItemIds.length === 1) {
        const [id] = selectedCatalogItemIds;

        const edit = "edit";
        const route = hasPhoto(catalogItems, id)
          ? `/main/settings/catalog/upload-photo/${id}/${edit}`
          : `/main/settings/catalog/upload-photo/${id}`;

        return (
          <UploadPhotoAction
            route={route}
            msgKey={"catalogItem.upload.action"}
          />
        );
      }

      return null;
    };

    const EditCatalog = () => {
      if (selectedCatalogItemIds.length === 1) {
        const [id] = selectedCatalogItemIds;
        const route = `/main/settings/catalog/edit-catalogItem/${id}`;

        return <EditAction route={route} msgKey={"catalogItem.edit.action"} />;
      }

      return null;
    };

    const DeleteCatalog = () => (
      <DeleteAction onDelete={this._showDeletePopin} />
    );

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle
              color={color}
              titleKey="catalogItem.title"
              actionKey="catalogItem.list.action"
            />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                count={catalogItemsCount}
                loadingKey="catalogItem.list.loading"
                loading={loading}
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>

          <div className={styles.edition}>
            <div>
              <span
                className="btn btn-ingenico create-button catalog-action"
                onClick={this._goToCreateCatalogItem}
              >
                <span className="glyphicon glyphicon-plus" aria-hidden="true" />
                <I18nSpan msgKey="catalogItem.list.button.create" />
              </span>
            </div>
          </div>
        </div>

        <CatalogItemAdvancedSearch
          searchContext={searchContext}
          onChange={this._search}
        />

        {showDeleteConfirmation && (
          <ConfirmationPopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={this._deleteCatalogItem}
            objectName={catalogItemsNames}
          />
        )}

        <DataTable
          data={catalogItems}
          notSortableFields={["catalogItem.selectedTags"]}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          formatter={this.formatter}
          i18nKey="catalogItem.list"
          idAttribute="catalogItem.id"
          hideSearchBox={true}
          toggleable={true}
          color={color}
          hasActions={false}
          onRefresh={this._search}
          disableStatusValues={["Suspended"]}
          onUpdateColumns={this._updateColumns}
          selectableNew={true}
          selectionButtonKey={[EditCatalog, DeleteCatalog, UploadPhoto]}
          version="new"
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedCatalogItemIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={catalogItemsCount}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const normalizedCatalogItems = ({ state }) => {
  const {
    catalogItems: { data, count, scrollId },
    genericFields: { data: genericFields },
    auth: {
      levelSettings: {
        currency: {
          alpha3: currencyCodeAlpha3,
          symbol: currency,
          exponent: currencyDecimal
        }
      } = {
        currency: {
          alpha3: "",
          symbol: "?",
          exponent: 0
        }
      }
    }
  } = state;
  const currencySymbol = currencyCodeAlpha3 + currency;
  const currencyExponent = currency ? currencyDecimal : 2;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const catalogItems = data.map((catalogItem: any) => {
    const normalizedCatalogItems = fields.reduce(
      (prevField: Array<string>, field: any) => {
        if (field === "catalogItem.price") {
          const formatPrice = NumberFormatter.formatAmount(
            get(catalogItem, field),
            currencySymbol,
            currencyExponent
          );

          return {
            ...prevField,
            [field]: formatPrice
          };
        }

        return {
          ...prevField,
          [field]: get(catalogItem, field)
        };
      },
      {}
    );

    return {
      ...normalizedCatalogItems
    };
  });

  return {
    count,
    scrollId,
    catalogItems
  };
};

interface GetColumnsParams {
  type: string;
  fields: Array<string>;
}

const _getColumns = ({ type, fields }: GetColumnsParams) => {
  return fields.reduce((prevField: Array<string>, field: any) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting &&
    columnSetting["catalogItem.list"] &&
    columnSetting["catalogItem.list"].length > 0
    ? columnSetting["catalogItem.list"]
    : _getColumns({ fields });
};

const mapStateToProps = state => {
  const {
    auth: { user },
    genericFields: { data: fields },
    catalogItems: { selection, loading },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount, eventGatewayURL }
    },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const {
    catalogItems,
    count: catalogItemsCount,
    scrollId: catalogScrollId
  } = normalizedCatalogItems({
    state
  });

  const selectedCatalogItemIds = _.keys(_.pick(selection, _.identity));

  const columnSetting = user ? user.columnSetting : false;
  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const attributes = _getColumns({ type: "visible", fields });

  return {
    loading,
    catalogItemsCount,
    catalogItems,
    catalogScrollId,
    selectedCatalogItemIds,
    selection,
    color,
    tableCount,
    attributes,
    sortDescription,
    eventGatewayURL,
    visibleAttributes,
    searchContext
  };
};

const mapDispatchToProps = dispatch => ({
  resetCatalogItems: () => dispatch(resetCatalogItems()),
  getCatalogItems: ({ filters, fields, sort, tableCount }) =>
    dispatch(getCatalogItems({ filters, fields, sort, tableCount })),
  deleteCatalogItems: (catalogItemId: string) =>
    dispatch(deleteCatalogItems(catalogItemId)),
  getFields: ({ name }) => dispatch(getFields({ name })),
  getCatalogScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "catalog", scrollId })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "CATALOGS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "CATALOGS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "CATALOGS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "CATALOGS" })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  deleteCatalogItem: ({ id, sectionType }) =>
    dispatch(deleteEvent({ id, sectionType })),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ListCatalogItemView);
