import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import AlertConstants from "../constants/AlertConstant";
import NumberFormatter from "../../formatters/NumberFormatter";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";

import Constants from "../constants/AlertConstant";
import ConfirmationPopin from "../../components/ConfirmationPopin";

import { Tabs } from "./TabsContainer";

import { CreateAlert } from "./CreateButtons";

import styles from "../styles/ListAlertView.css";

import {
  getStores,
  getMerchants,
  getTransactionAlerts,
  updateTransactionAlertStatus,
  deleteTransactionAlert,
  addNotificationSuccess,
  addNotificationError,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll
} from "../../redux/actions";

import {
  EditAction,
  DeleteAction,
  ToggleAction
} from "../../datatable/components/multipleActions";

import constants from "../../connection/constants/ConnectionConstant";
import { getNamesFromSelection } from "../../redux/reducers/selection";

interface Props {
  getStores: Function;
  getMerchants: Function;
  getTransactionAlerts: Function;
  updateTransactionAlertStatus: Function;
  deleteTransactionAlert: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  merchants: any;
  stores: any;
  alerts: any;
  attributes: any;
  userIsCustomer: boolean;
  history: any;
  selection: any;
}

interface State {
  color: any;
  showPopinSingle: boolean;
  alertName: any;
  alertId: any;
}

class ListAdvancedAlertView extends Component<Props, State> {
  state = {
    alertName: "",
    showPopinSingle: false
  };

  formatter = {
    "merchant.selectedTags": selectedTags => _.values(selectedTags).join(", "),
    "store.selectedTags": selectedTags => _.values(selectedTags).join(", ")
  };

  componentDidMount() {
    const {
      userIsCustomer,
      getStores,
      getMerchants,
      getTransactionAlerts
    } = this.props;

    const filters = [];

    getTransactionAlerts();
    if (userIsCustomer) {
      getMerchants({
        filters,
        fields: ["merchant.id", "merchant.name"]
      });
    } else {
      getStores({
        filters,
        fields: ["store.id", "store.name"]
      });
    }
  }

  _updateStatus = async status => {
    const { selectedAlertIds, updateStatus, addNotificationError } = this.props;
    const newStatus = status ? false : true;
    const [id] = selectedAlertIds;
    try {
      await updateStatus({ id, status: newStatus });
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      return addNotificationError(errorKey);
    }
  };

  _cancelAlert = () => {
    const {
      deleteTransactionAlert,
      addNotificationSuccess,
      addNotificationError,
      selectedAlertIds
    } = this.props;
    const [alertId] = selectedAlertIds;

    if (alertId == "") return undefined;

    return deleteTransactionAlert({ id: alertId }).then(
      () => {
        this._closePopinSingle();
        addNotificationSuccess("alert.cancel.success");
      },
      () => {
        this._closePopinSingle();
        addNotificationError("alert.cancel.error");
      }
    );
  };

  _openPopinConfirmDelete = () => {
    const { selection } = this.props;
    const alertName = getNamesFromSelection({
      selection,
      keyName: "name"
    });

    this.setState({
      showPopinSingle: true,
      alertName
    });
  };

  _closePopinSingle = () => {
    this.setState({
      showPopinSingle: false
    });
  };

  onSelectRow = (rowId, value) => {
    const { selectedAlertIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedAlertIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const {
      alerts,
      attributes,
      renderTransactionAlert,
      renderFraudAlert,
      t,
      selectedAlertIds
    } = this.props;
    const { alertName, showPopinSingle } = this.state;

    const formatter = {
      "threshold.criteria": quantityCode =>
        t(`alert.threshold.criteria.${quantityCode}`),
      "threshold.timeWindow": timeWindowCode =>
        t(`alert.threshold.timeWindow.${timeWindowCode}`)
    };

    const toggleActivationButton = () => {
      const [alertId] = selectedAlertIds;
      const selectedAlert = alerts.filter(alert => alertId === alert.id);
      const [selected = {}] = selectedAlert;
      const { active: status } = selected;

      if (selectedAlertIds.length === 1) {
        return (
          <ToggleAction
            onToggle={() => this._updateStatus(status)}
            msgKey={`alert.list.${status ? "deactivate" : "activate"}`}
          />
        );
      }

      return null;
    };

    const EditAlert = () => {
      if (selectedAlertIds.length === 1) {
        const [alertId] = selectedAlertIds;

        const route = `/main/reporting/alerts/${AlertConstants.ADVANCED_ALERT}/edit/${alertId}`;

        return <EditAction route={route} msgKey={"alert.edit.action"} />;
      }

      return null;
    };

    const DeleteAlert = () => {
      if (selectedAlertIds.length === 1) {
        return <DeleteAction onDelete={this._openPopinConfirmDelete} />;
      }

      return null;
    };

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <FormTitle titleKey="alert.title" actionKey="alert.list.action" />
          <CreateAlert
            alertType={AlertConstants.ADVANCED_ALERT}
            transactionAlert={renderTransactionAlert}
            fraudAlert={renderFraudAlert}
          />
        </div>
        <Tabs
          target="/main/reporting/alerts/"
          active={AlertConstants.ADVANCED_ALERT}
          transactionAlert={renderTransactionAlert}
          fraudAlert={renderFraudAlert}
        />
        {showPopinSingle && (
          <ConfirmationPopin
            onClosePopin={this._closePopinSingle}
            onClickButton={this._cancelAlert}
            objectName={alertName}
          />
        )}
        <DataTable
          data={alerts}
          attributes={attributes}
          formatter={formatter}
          i18nKey="alert.list"
          idAttribute="id"
          disableStatusValues={[false]}
          isRowEditable={() => true}
          hasActions={false}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedAlertIds}
          useSelection={true}
          selectableNew={true}
          selectionButtonKey={[EditAlert, DeleteAlert, toggleActivationButton]}
        />
      </div>
    );
  }
}

const _formatCurrency = ({ value, userCurrency }) => {
  const dataRevenues = userCurrency;

  if (!value) {
    return 0;
  }
  if (
    dataRevenues &&
    _.isNumber(dataRevenues.currencyDecimal) &&
    dataRevenues.currency
  ) {
    const decimal = dataRevenues.currencyDecimal;

    return NumberFormatter.formatAmount(value, null, decimal);
  } else {
    return value;
  }
};

const _formatFilter = ({ filter, ownProps, userIsCustomer: isCustomer }) => {
  const { merchants, stores, userCurrency, t } = ownProps;

  const defaultValue = _.get(filter, "value").toString();
  const value = t(
    `reporting.transaction.values.${filter.name}.${defaultValue}`,
    defaultValue
  );
  const operator = _.get(filter, "operator");

  let alertStoreList = [];

  if (isCustomer) {
    alertStoreList = merchants;
    alertStoreList &&
      alertStoreList.map(alertStoreItem => {
        alertStoreItem = Object.assign(alertStoreItem, {
          id: alertStoreItem.merchant.id,
          name: alertStoreItem.merchant.name
        });
      });
  } else {
    alertStoreList = stores;
    alertStoreList &&
      alertStoreList.map(alertStoreItem => {
        alertStoreItem = Object.assign(alertStoreItem, {
          id: alertStoreItem.store.id,
          name: alertStoreItem.store.name
        });
      });
  }

  if (_.endsWith(filter.name, ".id")) {
    const entity = _.find(alertStoreList, e => e.id == value); //eslint-disable-line eqeqeq
    return entity != undefined ? entity.name : value; //eslint-disable-line eqeqeq
  } else if (_.isNumber(value)) {
    return `${filter.name} ${filter.operator} ${_formatCurrency({
      value,
      userCurrency
    })}`;
  } else if (operator === "exists") {
    return `${filter.name} exists`;
  } else if (operator === "notExists") {
    return `${filter.name} not exists`;
  } else if (filter.tag) {
    return `${filter.name} : ${value}`;
  } else {
    return value;
  }
};

const _formatCriteria = (criteria, t) => {
  const translatedCriteria = t(`alert.threshold.criteria.${criteria}`);

  return translatedCriteria;
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: {
        scope: {
          level: { type: userType }
        }
      },
      levelSettings: _levelSettings,
      isCustomer: userIsCustomer
    },
    services: { data: services = [] } = { data: [] },
    transactionAlerts: { data: alerts, selection },
    merchants: { data: merchants },
    stores: { data: stores }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const checkPermissions = () => {
    const hasTransactionAlertService = services.includes("reportingAlert");
    const hasTransactionAlertSmsService = services.includes(
      "reportingAlertSms"
    );

    const renderTransactionAlert =
      hasTransactionAlertService || hasTransactionAlertSmsService;

    switch (userType) {
      case constants.CUSTOMER: {
        return {
          renderTransactionAlert,
          renderFraudAlert: false
        };
      }
      case constants.MERCHANT: {
        // hasTransactionAlertService is in fact for E-mail alert reportingAlert service
        const renderFraudAlert = services.includes("fraudAlert");
        return {
          renderTransactionAlert,
          renderFraudAlert
        };
      }
      default:
        break;
    }
    return {
      renderFraudAlert: false,
      renderTransactionAlert: false
    };
  };

  const { t } = ownProps;

  const copyAlert = _.cloneDeep(alerts);
  const augmentedAlerts = copyAlert
    ? copyAlert.map(alert => {
        const thresholdValue =
          alert.threshold.criteria === Constants.SUM
            ? _formatCurrency({ value: alert.threshold.value, userCurrency })
            : alert.threshold.value;
        const translatedCriteria = _formatCriteria(alert.threshold.criteria, t);

        const alertWithCriteria: any = _.set(
          alert,
          "criteria",
          `${translatedCriteria} ${alert.threshold.operator} ${thresholdValue}`
        );

        return _.set(
          alertWithCriteria,
          "filterValues",
          _(alertWithCriteria.filters)
            .filter(a => !_.isEmpty(a))
            .map(filter => _formatFilter({ filter, ownProps, userIsCustomer }))
            .join(" , ")
        );
      })
    : [];
  const selectedAlertIds = _.keys(_.pick(selection, _.identity));

  return {
    userCurrency,
    merchants,
    stores,
    alerts: augmentedAlerts,
    attributes: ["name", "threshold.timeWindow", "criteria", "filterValues"],
    userIsCustomer,
    selectedAlertIds,
    selection,
    ...checkPermissions()
  };
};

const mapDispatchToProps = dispatch => ({
  getMerchants: ({ filters, fields, sort, tableCount }) =>
    dispatch(getMerchants({ filters, fields, sort, tableCount })),
  getStores: ({ filters, fields, sort, tableCount }) =>
    dispatch(getStores({ filters, fields, sort, tableCount })),
  getTransactionAlerts: () => dispatch(getTransactionAlerts()),
  updateStatus: ({ id, status }) =>
    dispatch(updateTransactionAlertStatus({ id, status })),
  deleteTransactionAlert: ({ id }) => dispatch(deleteTransactionAlert({ id })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "TRANSACTION_ALERTS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "TRANSACTION_ALERTS" })),
  toggleSelectAll: () =>
    dispatch(toggleSelectAll({ selectionType: "TRANSACTION_ALERTS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "TRANSACTION_ALERTS" }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ListAdvancedAlertView);
