import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";

import setApexChartDefaultLocale from "../../../apexcharts";

interface Props {
  options: any;
  series: any;
  type: string;
  language: string;
}

function AmountApexChart({ options, series, type, language }: Props) {
  const [lang, setLang] = useState(null);

  useEffect(() => {
    setApexChartDefaultLocale(language);

    setLang(language);
  }, [language]);

  if (lang && lang === language) {
    return (
      <Chart options={options} series={series} type={type} height={"100%"} />
    );
  }

  return null;
}

export const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: { language }
    }
  } = state;

  return {
    language
  };
};

export default connect(mapStateToProps, null)(AmountApexChart);
