import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { SearchFilter } from "../models/SearchFilter";
import Autocomplete from "../../components/Autocomplete";
import AdvancedSearchService from "../services/AdvancedSearchService";

import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "../styles/styles.css";

const VALUE_INDEX = 2;

/**
 * @vulnerabilities XSS
 */
interface AdvancedSearchProps {
  onChange: Function;
  sort: string;
  formatter?: Object;
  disabled?: Boolean;
  name: string;
  searchFilters: Array<any>;
  suggestionI18nKey: string;
  className: string;
}

interface AdvancedSearchState {
  lists: Object;
  placeholder?: String;
  fulltextLabel?: String;
  createdItems?: Array<SearchFilter>;
}

class AdvancedSearch extends Component<
  AdvancedSearchProps,
  AdvancedSearchState
> {
  state = {
    lists: {},
    placeholder: "",
    fulltextLabel: "",
    createdItems: []
  };

  componentDidMount() {
    const { searchFilters: fields, suggestionI18nKey: i18nKey } = this.props;
    const lists = AdvancedSearchService.buildDataTree({
      fields,
      i18nKey
    });

    return this.setState({ lists });
  }

  componentWillReceiveProps(newProps: AdvancedSearchProps) {
    const { t } = this.props;
    const {
      name,
      searchFilters: fields,
      suggestionI18nKey: i18nKey,
      genericFields,
      searchContext
    } = newProps;
    const lists = AdvancedSearchService.buildDataTree({
      fields,
      i18nKey
    });

    const placeholder = t("searchbar.placeholder");
    const fulltextLabel = t("searchbar.fulltext");

    return this.setState({
      lists,
      createdItems: AdvancedSearchService.getCreatedItemsFromSearchContext({
        name,
        i18nKey,
        genericFields,
        searchContext
      }),
      placeholder,
      fulltextLabel
    });
  }

  onAutocompleteChange = (createdItems: Array<SearchFilter>) => {
    const { formatter, name, onChange, searchContext } = this.props;
    const filters = AdvancedSearchService.convertItemsToFilters(
      createdItems,
      formatter
    );

    const {
      timePeriod = {},
      sortByAdvancedSearchKey: { [name]: sort }
    } = searchContext;
    const newSearchContext = {
      key: name,
      sort,
      filters,
      timePeriod
    };

    return this.setState({ createdItems }, () => {
      return (
        onChange && onChange({ filters, sort, searchContext: newSearchContext })
      );
    });
  };

  autocompleteItemFormatter = item => {
    const { fulltextLabel } = this.state;
    const IN_OPERATOR = "in";
    const NOT_IN_OPERATOR = "notEqual";
    const ARRAY_TYPE = "array";
    const LABEL_PROPERTY = "label";
    const RAW_VALUE = "rawValue";
    let formattedLabel = "";

    if (_.isString(item)) {
      formattedLabel = `${fulltextLabel} : ${item}`;
    } else {
      const { type } = item[0];
      const { value } = item[1];
      if (
        type === ARRAY_TYPE &&
        (value === IN_OPERATOR || value === NOT_IN_OPERATOR)
      ) {
        const listParts = item.slice(VALUE_INDEX);

        const [itemKey, operator] = item;

        const label = itemKey.rawLabel ? itemKey.rawLabel : itemKey.label;

        const propAndOperator = `${label} ${operator.label}`;

        const showRawValue = _.every(listParts, item => {
          return item.hasOwnProperty(RAW_VALUE);
        });

        const listContent = _.pluck(
          listParts,
          showRawValue ? RAW_VALUE : LABEL_PROPERTY
        ).join(", ");

        formattedLabel = `${propAndOperator} [${listContent}]`;
      } else {
        formattedLabel = item
          .map(part =>
            part.hasOwnProperty(LABEL_PROPERTY)
              ? part.rawLabel
                ? part.rawLabel
                : part.rawValue
                ? part.rawValue
                : part.label
              : part
          )
          .join(" ");
      }
    }

    return formattedLabel;
  };

  _filterFunction = (allSuggestions, selectedValues) => {
    return _.chain(allSuggestions)
      .reject(suggestion =>
        _.chain(selectedValues)
          .pluck("value")
          .contains(suggestion.value)
          .value()
      )
      .value();
  };

  render() {
    const { className, disabled, name, countries } = this.props;
    const { lists, createdItems, placeholder } = this.state;

    return (
      <div className={styles.advancedSearch}>
        <Autocomplete
          lists={lists}
          createdItems={createdItems}
          placeholder={placeholder}
          onChange={this.onAutocompleteChange}
          allowFreeText={true}
          createdItemLabelFormatter={this.autocompleteItemFormatter}
          className={className}
          disabled={disabled}
          name={name}
          filterFunction={this._filterFunction}
          timePeriod={this.props.timePeriod}
          countries={countries}
        />
        <span
          data-tip
          data-for="search-help"
          className="react-tooltip react-tooltip-search glyphicon glyphicon-question-sign"
        />
        <ReactTooltip
          id="search-help"
          delayHide={1000}
          place="left"
          type="info"
          effect="solid"
        >
          <I18nSpan msgKey="searchbar.help.title" />
          <br />
          <br />
          <ol>
            <li>
              <b>
                <I18nSpan msgKey="searchbar.help.usage1.example" />
              </b>
              <I18nSpan msgKey="searchbar.help.usage1.explanation" />
            </li>
            <li>
              <b>
                <I18nSpan msgKey="searchbar.help.usage2.example" />
              </b>
              <I18nSpan msgKey="searchbar.help.usage2.explanation" />
            </li>
          </ol>
        </ReactTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    countries: { data: countries }
  } = state;

  return {
    countries
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, null)
)(AdvancedSearch);
