import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import Validators from "../../ingenicoForm/validation/Validators";
import constants from "../../connection/constants/ConnectionConstant";

import {
  createTag,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  history: any;
  createTag: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
}

interface State {
  form: any;
  errors: any;
}

class CreateTagView extends Component<Props, State> {
  state = {
    form: {
      name: null,
      values: []
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  _onChangeHandler = (name: string, value: string | Array<string>) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this.checkErrors);
  };

  _saveTag = e => {
    e.preventDefault();

    const tag = this.state.form;
    this.checkErrors(() => {
      const hasErrors = _.any(tag, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const {
          createTag,
          addNotificationSuccess,
          addNotificationError
        } = this.props;

        createTag({ tag }).then(
          () => {
            addNotificationSuccess("tag.create.success");
            this._goToList();
          },
          async (errorData: any) => {
            const { key, errorKey } = await errorData;
            // for merchant using olad error format compatibility : still in provisioning
            const message = key
              ? key
              : _.include(["tag.already.exist", "tag.alreadyExists"], errorKey)
              ? "tag.create.conflict"
              : errorKey;
            addNotificationError(message);
          }
        );
      }
    });
  };

  _goToList = () => {
    const { history } = this.props;
    history.push("/main/settings/tags");
  };

  checkErrors(then) {
    const waitForValidation = (nameList: Array<string>, newErrors) => {
      if (_.isEmpty(nameList)) {
        this.setState({ errors: newErrors }, then);
      } else {
        const name = _.first(nameList);
        const value = _.get(this.state.form, name);
        const refsValue = _.get(this.inputRefs, name);

        const errors = refsValue
          ? [refsValue.props.validation(value)]
          : [this._noop()];
        Promise.all(errors).then(errors => {
          newErrors[name] = _.chain(errors)
            .flatten()
            .compact()
            .value();
          waitForValidation(_.tail(nameList), newErrors);
        });
      }
    };
    waitForValidation(_.keys(this.state.form), {});
  }

  tagNameValidator(tagName) {
    const tagNamePromise = new Promise(resolve => {
      if (!tagName) resolve(Validators.requiredValidator(tagName));
      else resolve(Validators.containsCharValidator(tagName, "."));
    });
    return Promise.all([tagNamePromise]);
  }

  _noop() {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  }

  render() {
    const {
      form: { name, values },
      errors: { name: errorsName }
    } = this.state;

    return (
      <div>
        <FormTitle titleKey="tag.title" actionKey="tag.create.action" />
        <form className="ingenico-form form-horizontal tag-form">
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            required={true}
            validation={this.tagNameValidator}
            descriptor={{
              type: "text",
              label: "tag.form.name.label"
            }}
            formValue={name}
            errors={errorsName}
          />
          <BootstrapInput
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("values")}
            name="values"
            descriptor={{
              type: "multiple",
              label: "tag.form.values.label",
              placeholder: "tag.form.values.placeholder"
            }}
            formValue={values}
          />
          <div className="pull-right">
            <button
              onClick={this._saveTag}
              name="save"
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      user: {
        scope: {
          level: { type: levelType }
        }
      }
    },
    tag: { data: tag }
  } = state;

  return {
    tag,
    levelType
  };
};

const mapDipatchToProps = dispatch => ({
  createTag: ({ tag, tagType }) => dispatch(createTag({ tag, tagType })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { levelType } = stateProps;

  switch (levelType) {
    case constants.CUSTOMER: {
      const tagType = constants.CUSTOMER_NEW;

      return {
        ...stateProps,
        ...dispatchProps,
        createTag: ({ tag }) => dispatchProps.createTag({ tag, tagType }),
        ...ownProps
      };
    }
    case constants.MERCHANT: {
      const tagType = constants.MERCHANT;

      return {
        ...stateProps,
        ...dispatchProps,
        createTag: ({ tag }) => dispatchProps.createTag({ tag, tagType }),
        ...ownProps
      };
    }
    default:
      break;
  }
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDipatchToProps, mergeProps)
)(CreateTagView);
