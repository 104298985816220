import { constantCase } from "constant-case";

import F from "../../commons/HTTPFetcher";
import { generateUrl } from "../../commons/utils/url";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import { buildParams } from "../../commons/search/SearchParams";

import {
  PENDING_GET_TRANSACTIONS_ACTION,
  SUCCESS_GET_TRANSACTIONS_ACTION,
  FAILURE_GET_TRANSACTIONS_ACTION,
  PENDING_GET_TRANSACTION_CONFLICT_ACTION,
  SUCCESS_GET_TRANSACTION_CONFLICT_ACTION,
  FAILURE_GET_TRANSACTION_CONFLICT_ACTION,
  PENDING_MERGE_TRANSACTION_CONFLICT_ACTION,
  SUCCESS_MERGE_TRANSACTION_CONFLICT_ACTION,
  FAILURE_MERGE_TRANSACTION_CONFLICT_ACTION,
  PENDING_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION,
  SUCCESS_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION,
  FAILURE_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION,
  PENDING_SEND_TRANSACTIONS_RECEIPT_ACTION,
  SUCCESS_SEND_TRANSACTIONS_RECEIPT_ACTION,
  FAILURE_SEND_TRANSACTIONS_RECEIPT_ACTION,
  PENDING_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  SUCCESS_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  FAILURE_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  PENDING_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  SUCCESS_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  FAILURE_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  PENDING_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  SUCCESS_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  FAILURE_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  RESET_TRANSACTIONS_ACTION,
  PENDING_GET_TRANSACTIONS_DETAIL_ACTION,
  SUCCESS_GET_TRANSACTIONS_DETAIL_ACTION,
  FAILURE_GET_TRANSACTIONS_DETAIL_ACTION,
  CLEAR_TRANSACTIONS_DETAIL_ACTION,
  SUCCESS_GET_TRANSACTIONS_RECEIPTS_ACTION,
  FAILURE_GET_TRANSACTIONS_RECEIPTS_ACTION,
  SHOW_TRANSACTIONS_RECEIPTS_ACTION,
  HIDE_TRANSACTIONS_RECEIPTS_ACTION,
  SHOW_TRANSACTIONS_DETAIL_ACTION,
  HIDE_TRANSACTIONS_DETAIL_ACTION,
  CLOSE_TRANSACTIONS_RECEIPTS_ACTION
} from "../actionsTypes";

const getTransactionsStart = () => ({
  type: PENDING_GET_TRANSACTIONS_ACTION
});

const getTransactionsSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_TRANSACTIONS_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getTransactionsFailure = ({ errors }) => ({
  type: FAILURE_GET_TRANSACTIONS_ACTION,
  payload: {
    errors
  }
});

export const getTransactions = ({
  filters,
  fields,
  sort = { field: "mshTransactionTime", order: "DESC" },
  tableCount: max,
  begin,
  end
}) => async dispatch => {
  dispatch(getTransactionsStart());

  try {
    const url = new URL("/transaction/search", PORTAL_URL);

    Object.entries({ max, begin, end }).forEach(param => {
      const [paramKey, paramValue] = param;

      if (paramValue) {
        return url.searchParams.append(paramKey, paramValue);
      }
    });

    const { count, scrollId, searchResults: data } = await F.post(
      `${url.pathname}${url.search}`,
      {
        body: {
          filters,
          fields,
          sort
        },
        contentType: ContentTypes.JSON
      },
      "v2"
    );

    return dispatch(getTransactionsSucceeded({ count, scrollId, data }));
  } catch (errors) {
    dispatch(getTransactionsFailure({ errors }));

    throw errors;
  }
};

//////

const getTransactionConflictStart = () => ({
  type: PENDING_GET_TRANSACTION_CONFLICT_ACTION
});

const getTransactionConflictSucceeded = ({ count, data }) => ({
  type: SUCCESS_GET_TRANSACTION_CONFLICT_ACTION,
  payload: {
    count,
    data
  }
});

const getTransactionConflictFailure = ({ errors }) => ({
  type: FAILURE_GET_TRANSACTION_CONFLICT_ACTION,
  payload: {
    errors
  }
});

export const getTransactionConflict = (args, user) => async dispatch => {
  dispatch(getTransactionConflictStart());

  const searchParams = buildParams(args, user);
  const url = new URL("/reporting/transactionconflict", PORTAL_URL);

  Object.entries(searchParams).forEach(param => {
    const [paramKey, paramValue] = param;

    if (paramValue) {
      return url.searchParams.append(paramKey, paramValue);
    }
  });

  return F.get(`${url.pathname}${url.search}`).then(
    ({ count, transactions: data }) => {
      dispatch(getTransactionConflictSucceeded({ count, data }));
    },
    errors => {
      dispatch(getTransactionConflictFailure({ errors }));

      throw errors;
    }
  );
};

//////

const mergeTransactionConflictStart = () => ({
  type: PENDING_MERGE_TRANSACTION_CONFLICT_ACTION
});

const mergeTransactionConflictSucceeded = () => ({
  type: SUCCESS_MERGE_TRANSACTION_CONFLICT_ACTION
});

const mergeTransactionConflictFailure = ({ errors }) => ({
  type: FAILURE_MERGE_TRANSACTION_CONFLICT_ACTION,
  payload: {
    errors
  }
});

export const mergeTransactionConflict = ({
  transactionId,
  resolutionSource
}) => async dispatch => {
  dispatch(mergeTransactionConflictStart());

  return F.get(
    `/reporting/mergeConflict/${transactionId}/${resolutionSource}`
  ).then(
    () => dispatch(mergeTransactionConflictSucceeded()),
    errors => {
      dispatch(mergeTransactionConflictFailure({ errors }));

      throw errors;
    }
  );
};

//////

export const downloadTransactionsReceipt = ({
  receiptId,
  receiptType
}) => async dispatch => {
  try {
    dispatch(downloadTransactionsReceiptStart());

    const url = generateUrl({
      url: `/transaction/${receiptId}/receipt/${receiptType}/pdf`,
      version: "v2"
    });

    await F.download(url, `${receiptType}-receipt-${receiptId}.pdf`);

    dispatch(downloadTransactionsReceiptSucceeded());
  } catch (errors) {
    dispatch(downloadTransactionsReceiptFailure({ errors }));

    throw errors;
  }
};

const downloadTransactionsReceiptStart = () => ({
  type: PENDING_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION
});

const downloadTransactionsReceiptSucceeded = () => ({
  type: SUCCESS_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION
});

const downloadTransactionsReceiptFailure = ({ errors }) => ({
  type: FAILURE_DOWNLOAD_TRANSACTIONS_RECEIPT_ACTION,
  payload: {
    errors
  }
});

//////

export const sendTransactionsReceipt = ({
  ids,
  recipients,
  sendCopy,
  mailBody
}) => async dispatch => {
  dispatch(sendTransactionsReceiptStart());

  try {
    await F.post(
      "/transaction/receipt/email",
      {
        contentType: ContentTypes.JSON,
        body: {
          ids,
          recipients,
          sendCopy,
          mailBody
        }
      },
      "v2"
    );

    return dispatch(sendTransactionsReceiptSucceeded());
  } catch (errors) {
    dispatch(sendTransactionsReceiptFailure({ errors }));

    throw errors;
  }
};

const sendTransactionsReceiptStart = () => ({
  type: PENDING_SEND_TRANSACTIONS_RECEIPT_ACTION
});

const sendTransactionsReceiptSucceeded = () => ({
  type: SUCCESS_SEND_TRANSACTIONS_RECEIPT_ACTION
});

const sendTransactionsReceiptFailure = ({ errors }) => ({
  type: FAILURE_SEND_TRANSACTIONS_RECEIPT_ACTION,
  payload: {
    errors
  }
});

//////

export const resetTransactions = () => ({
  type: RESET_TRANSACTIONS_ACTION
});

//////

export const getTransactionsMultipleReceipt = receiptIds => async dispatch => {
  dispatch(getTransactionsMultipleReceiptStart());

  try {
    const receiptsHtml = await F.post(
      "/transaction/receipt/plain",
      {
        contentType: ContentTypes.JSON,
        body: receiptIds
      },
      "v2"
    );

    dispatch(getTransactionsMultipleReceiptSucceeded());

    return receiptsHtml;
  } catch (errors) {
    dispatch(getTransactionsMultipleReceiptFailure({ errors }));

    throw errors;
  }
};

const getTransactionsMultipleReceiptStart = () => ({
  type: PENDING_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION
});

const getTransactionsMultipleReceiptSucceeded = () => ({
  type: SUCCESS_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION
});

const getTransactionsMultipleReceiptFailure = ({ errors }) => ({
  type: FAILURE_GET_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  payload: {
    errors
  }
});

//////

export const downloadTransactionsMultipleReceipt = receiptIds => async dispatch => {
  dispatch(downloadTransactionsMultipleReceiptStart());

  const url = generateUrl({ url: `/transaction/receipt/pdf`, version: "v2" });

  try {
    const multipleReceipts = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${F.jwt}`,
        "Content-type": "application/json"
      },
      body: JSON.stringify(receiptIds)
    });

    if (multipleReceipts.ok) {
      dispatch(downloadTransactionsMultipleReceiptSucceeded());

      return await multipleReceipts.blob();
    } else {
      throw await multipleReceipts.text();
    }
  } catch (errors) {
    dispatch(downloadTransactionsMultipleReceiptFailure({ errors }));

    throw errors;
  }
};

const downloadTransactionsMultipleReceiptStart = () => ({
  type: PENDING_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION
});

const downloadTransactionsMultipleReceiptSucceeded = () => ({
  type: SUCCESS_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION
});

const downloadTransactionsMultipleReceiptFailure = ({ errors }) => ({
  type: FAILURE_DOWNLOAD_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  payload: {
    errors
  }
});

//////

export const sendTransactionsMultipleReceipt = ({
  ids,
  recipients,
  sendCopy,
  mailBody,
  receiptActionType
}) => async dispatch => {
  dispatch(sendTransactionsMultipleReceiptStart());

  const url =
    receiptActionType === "transaction"
      ? "/transaction/receipt/email"
      : "/reconciliations/transaction/receipts/send";

  const version = receiptActionType === "transaction" ? "v2" : "v1";

  try {
    await F.post(
      url,
      {
        contentType: ContentTypes.JSON,
        body: {
          ids,
          recipients,
          sendCopy,
          mailBody
        }
      },
      version
    );

    return dispatch(sendTransactionsMultipleReceiptSucceeded());
  } catch (errors) {
    dispatch(sendTransactionsMultipleReceiptFailure({ errors }));

    throw errors;
  }
};

const sendTransactionsMultipleReceiptStart = () => ({
  type: PENDING_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION
});

const sendTransactionsMultipleReceiptSucceeded = () => ({
  type: SUCCESS_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION
});

const sendTransactionsMultipleReceiptFailure = ({ errors }) => ({
  type: FAILURE_SEND_TRANSACTIONS_MULTIPLE_RECEIPT_ACTION,
  payload: {
    errors
  }
});

//////

export const getTransactionDetail = ({ id }) => async dispatch => {
  dispatch(getTransactionDetailStart());

  try {
    const detail = await F.get(`/transaction/${id}`, "v2");

    dispatch(getTransactionDetailSucceeded({ detail }));
    dispatch(showTransactionDetail());
  } catch (errors) {
    dispatch(getTransactionDetailFailure({ errors }));

    throw errors;
  }
};

const getTransactionDetailStart = () => ({
  type: PENDING_GET_TRANSACTIONS_DETAIL_ACTION
});

const getTransactionDetailSucceeded = ({ detail }) => ({
  type: SUCCESS_GET_TRANSACTIONS_DETAIL_ACTION,
  payload: {
    detail
  }
});

const getTransactionDetailFailure = ({ errors }) => ({
  type: FAILURE_GET_TRANSACTIONS_DETAIL_ACTION,
  payload: {
    errors
  }
});

export const clearTransactionDetail = () => ({
  type: CLEAR_TRANSACTIONS_DETAIL_ACTION
});

const showTransactionDetail = () => ({
  type: SHOW_TRANSACTIONS_DETAIL_ACTION
});

export const hideTransactionDetail = () => ({
  type: HIDE_TRANSACTIONS_DETAIL_ACTION
});

//////

export const getTransactionReceipt = ({
  receiptId,
  receiptType
}) => async dispatch => {
  dispatch(getTransactionReceiptStart({ receiptType }));

  try {
    const receiptHtml = await F.get(
      `/transaction/${receiptId}/receipt/${receiptType}/plain`,
      "v2"
    );

    return dispatch(
      getTransactionReceiptSucceeded({ receiptId, receiptHtml, receiptType })
    );
  } catch (errors) {
    dispatch(getTransactionReceiptFailure({ errors, receiptType }));

    throw errors;
  }
};

const getTransactionReceiptStart = ({ receiptType }) => {
  const name = constantCase(receiptType);

  return {
    type: `PENDING_GET_TRANSACTIONS_${name}_RECEIPT_ACTION`
  };
};

const getTransactionReceiptSucceeded = ({
  receiptId,
  receiptHtml,
  receiptType
}) => {
  const name = constantCase(receiptType);

  return {
    type: `SUCCESS_GET_TRANSACTIONS_${name}_RECEIPT_ACTION`,
    payload: {
      receiptId,
      receiptType,
      receiptHtml
    }
  };
};

const getTransactionReceiptFailure = ({ errors, receiptType }) => {
  const name = constantCase(receiptType);

  return {
    type: `FAILURE_GET_TRANSACTIONS_${name}_RECEIPT_ACTION`,
    payload: {
      errors
    }
  };
};

//////

export const sendTransactionReceipt = ({
  id,
  recipients,
  sendCopy,
  mailBody,
  receiptType,
  receiptActionType
}) => async dispatch => {
  dispatch(sendTransactionReceiptStart({ receiptType, receiptActionType }));

  const url =
    receiptActionType === "transaction"
      ? `/transaction/${id}/receipt/${receiptType}/email`
      : `/reconciliation/${id}/merchantreceipt/send`;

  try {
    await F.post(
      url,
      {
        contentType: ContentTypes.JSON,
        body: {
          recipients,
          sendCopy,
          mailBody
        }
      },
      "v2"
    );

    return dispatch(
      sendTransactionReceiptSucceeded({ receiptType, receiptActionType })
    );
  } catch (errors) {
    dispatch(
      sendTransactionReceiptFailure({ errors, receiptType, receiptActionType })
    );

    throw errors;
  }
};

const sendTransactionReceiptStart = ({ receiptType, receiptActionType }) => {
  const name = constantCase(receiptType);
  const section = constantCase(receiptActionType);

  return {
    type: `PENDING_SEND_${section}_${name}_RECEIPT_ACTION`
  };
};

const sendTransactionReceiptSucceeded = ({
  receiptType,
  receiptActionType
}) => {
  const name = constantCase(receiptType);
  const section = constantCase(receiptActionType);

  return {
    type: `SUCCESS_SEND_${section}_${name}_RECEIPT_ACTION`
  };
};

const sendTransactionReceiptFailure = ({
  errors,
  receiptType,
  receiptActionType
}) => {
  const name = constantCase(receiptType);
  const section = constantCase(receiptActionType);

  return {
    type: `FAILURE_SEND_${section}_${name}_RECEIPT_ACTION`,
    payload: {
      errors
    }
  };
};

//////

export const openReceiptsSelectionPopin = receiptsSelection => async dispatch => {
  try {
    const receiptsToDisplay = await Promise.all(
      receiptsSelection.map(receiptId =>
        F.get(`/transaction/${receiptId}/receipt/merchant/plain`, "v2")
      )
    );
    const receipts = receiptsSelection.reduce((prevReceipt, receipt, index) => {
      return {
        ...prevReceipt,
        [receipt]: receiptsToDisplay[index]
      };
    }, {});

    dispatch(getTransactionReceiptsSucceeded({ receipts }));
    dispatch(showTransactionReceipts());
  } catch (errors) {
    dispatch(getTransactionReceiptsFailure({ errors }));

    throw errors;
  }
};

const getTransactionReceiptsSucceeded = ({ receipts }) => {
  return {
    type: SUCCESS_GET_TRANSACTIONS_RECEIPTS_ACTION,
    payload: {
      receipts
    }
  };
};

const showTransactionReceipts = () => {
  return {
    type: SHOW_TRANSACTIONS_RECEIPTS_ACTION
  };
};

const getTransactionReceiptsFailure = ({ errors }) => {
  return {
    type: FAILURE_GET_TRANSACTIONS_RECEIPTS_ACTION,
    payload: {
      errors
    }
  };
};

export const closeReceiptsSelectionPopin = () => {
  return {
    type: HIDE_TRANSACTIONS_RECEIPTS_ACTION
  };
};

export const closeTransactionsReceipt = receiptType => {
  const name = constantCase(receiptType);

  return {
    type: `CLOSE_TRANSACTIONS_${name}_RECEIPT_ACTION`,
    payload: {
      receiptType
    }
  };
};

export const closeTransactionsReceipts = receiptId => {
  return {
    type: CLOSE_TRANSACTIONS_RECEIPTS_ACTION,
    payload: {
      receiptId
    }
  };
};

export const transactionBuildParams = ({ timePeriod, filters }) => dispatch => {
  return buildParams({ timePeriod, filters });
};
