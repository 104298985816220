import React from "react";
import * as moment from "moment";
import I18nSpan from "../../../../i18n/components/I18nSpan";
import PredefinedConstants from "../../PredefinedAlertConstant";
import {
  CreateRefundOverThresholdAlert,
  RefundOverThresholdAlert
} from "../../model/RefundOverThresholdAlert";

interface Props {
  onChange: (alert: CreateRefundOverThresholdAlert) => void;
  currencySymbol?: string;
  currencyExponent?: number;
}

interface State {
  minMinutes?: number;
  maxMinutesIn48Hours?: number;
  minStores?: number;
  maxStores?: number;
  minValueThreshold?: number;
  maxValueThreshold?: number;
  form?: any;
}

export default class RefundOverThreshold extends React.Component<Props, State> {
  state = {
    minMinutes: 1,
    maxMinutesIn48Hours: 2880,
    minStores: 2,
    maxStores: Number.MAX_SAFE_INTEGER,
    minValueThreshold: 1,
    maxValueThreshold: Number.MAX_SAFE_INTEGER,
    form: {
      nbMinutes: PredefinedConstants.DEFAULT_DURATION,
      valueThreshold:
        PredefinedConstants.DEFAULT_VALUE_THRESHOLD /
        Math.pow(10, this.props.currencyExponent)
    }
  };

  private getNumberOrMinimum = (input: HTMLInputElement) => {
    if (input.value === "0" || input.value.trim() === "") {
      return input.name === "nbMinutes"
        ? this.state.minMinutes
        : input.name === "valueThreshold"
        ? this.state.minValueThreshold
        : input.value;
    } else return input.value;
  };

  private onChange = (e: Event) => {
    const newForm = this.state.form;
    const input = e.target as HTMLInputElement;
    newForm[input.name] = this.getNumberOrMinimum(input);
    this.setState(
      {
        form: newForm
      },
      this.createAlertFromState
    );
  };

  private createAlertFromState = () => {
    const refundOverThresholdAlert = new CreateRefundOverThresholdAlert(
      moment.duration(parseInt(this.state.form.nbMinutes), "minutes"),
      parseInt(this.state.form.valueThreshold) *
        Math.pow(10, this.props.currencyExponent),
      this.props.currencySymbol,
      this.props.currencyExponent
    );
    this.props.onChange(refundOverThresholdAlert);
  };

  private onNumberInputClick = (e: Event) => {
    const input = e.target as HTMLInputElement;
    input.select();
  };

  render() {
    return (
      <div id="alert-refund-over-threshold" className="alert-item">
        <I18nSpan
          msgKey={`alert.predefined.${RefundOverThresholdAlert.I18N_KEY}.create-1`}
        />
        <span>{this.props.currencySymbol}</span>
        <input
          type="number"
          name="valueThreshold"
          onChange={this.onChange}
          value={this.state.form.valueThreshold}
          onClick={this.onNumberInputClick}
          min={this.state.minValueThreshold}
          max={this.state.maxValueThreshold}
        />
        <I18nSpan
          msgKey={`alert.predefined.${RefundOverThresholdAlert.I18N_KEY}.create-2`}
        />
        <input
          type="number"
          name="nbMinutes"
          value={this.state.form.nbMinutes}
          onChange={this.onChange}
          onClick={this.onNumberInputClick}
          min={this.state.minMinutes}
          max={this.state.maxMinutesIn48Hours}
        />
        <I18nSpan
          msgKey={`alert.predefined.${RefundOverThresholdAlert.I18N_KEY}.create-3`}
        />
      </div>
    );
  }
}
