import React, { Component } from "react";
import { connect } from "react-redux";

import KeyIndicator from "./KeyIndicator";
import I18nNumber from "../../../i18n/components/I18nNumber";
import Trend from "./Trend";

import { WidgetConfig } from "../Widget";
import { getData, getDataWithTrend, getTrend } from "../../utils/DataProvider";

interface Data {
  transactionCount: number;
}

interface Props {
  enableTrend: boolean;
  trend: any;
  getData: Function;
  getTrend: Function;
  data: Data;
  loading: boolean;
  error?: boolean;
  title: string;
  color: string;
  config: WidgetConfig;
}

class TransactionNumber extends Component<Props, any> {
  componentDidMount() {
    const { config, getData, getTrend, enableTrend = false } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getDataWithTrend({ config, getData, getTrend, enableTrend });
    }
  }

  render() {
    const {
      data,
      title,
      color,
      loading,
      error,
      config,
      trend: { data: { trend: trendData } } = { data: { trend: {} } },
      enableTrend
    } = this.props;
    const {
      timePeriod: { type: periodType },
      timestamps
    } = config;

    const { min, max } = timestamps;
    const { transactionCount } = data;
    return (
      <KeyIndicator
        color={color}
        titleKey={title}
        loading={loading}
        error={error}
        renderTrend={() => (
          <Trend
            enableTrend={enableTrend}
            data={trendData}
            type={periodType}
            startTime={min}
            endTime={max}
            field="sales"
          />
        )}
      >
        <I18nNumber number={transactionCount} format="0,0" />
      </KeyIndicator>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const {
    widgets: { [id]: widget = {} }
  } = state.widgetData;

  const {
    widgetTrend: { widgets = {} }
  } = state;

  const wrapTrend = () => {
    const { [id]: trendData = {} } = widgets;
    const { data = {}, loading = true, error } = trendData;
    return {
      data,
      loading,
      error
    };
  };

  const trend = wrapTrend();

  const { data: { stats = {} } = {}, loading = true, error } = widget;

  return {
    trend,
    data: stats,
    loading,
    error
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config })),
  getTrend: ({ config }) => dispatch(getTrend({ config }))
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionNumber);
