import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import LoginInput from "./LoginDesktopInput";
import PasswordInput from "./PasswordDesktopInput";
import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "../styles/styles.css";

class AuthenticateDesktopView extends Component<Props, {}> {
  render() {
    const {
      t,
      login,
      password,
      captcha,
      showPassword,
      onInputChange,
      onShowPassword
    } = this.props;

    return (
      <div
        className={`col-xs-12 col-md-7 col-sm-7 ${styles["login-form-wrapper"]}`}
      >
        <div className={styles["login-form"]}>
          <LoginInput
            label={t("login.form.login")}
            onChange={onInputChange}
            value={login}
          />
          <PasswordInput
            label={t("login.form.password")}
            showPassword={showPassword}
            onShowPassword={onShowPassword}
            onChange={onInputChange}
            value={password}
          />
          <input type="hidden" id="captcha" name="captcha" value={captcha} />
          <div className={styles["small-link"]}>
            <Link to="/login/forgot-password" className={styles.link}>
              <I18nSpan msgKey="login.form.passwordForgot" />
            </Link>
          </div>
          <div className={styles["submit-container"]}>
            <input
              type="submit"
              className={styles.buttonlink}
              value={t("login.form.signIn")}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AuthenticateDesktopView);
