import React from "react";
import { Divider, createStyles, makeStyles, Theme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid/Grid";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

const DividerMobile = () => {
  const { t } = useTranslation();

  const { darkMode: prefersDarkMode } = useSelector(state =>
    mapStateToProps(state)
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: prefersDarkMode ? "#3B3B3B" : "#E5E5E5",
        color: prefersDarkMode ? "#E5E5E5" : "#404041"
      },
      grid: {
        padding: "calc(var(--vh, 1vh) * 3) 0 calc(var(--vh, 1vh) * 3) 0"
      }
    })
  );

  const classes = useStyles();

  return (
    <Grid item className={classes.grid}>
      <Divider
        classes={{
          root: classes.root
        }}
      />
    </Grid>
  );
};

export default DividerMobile;
