import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";

import I18nSpan from "../../i18n/components/I18nSpan";
import SubMenu from "./SubMenu";

import { getMenu, resetMenu } from "../../redux/actions";

import styles from "../styles/styles.css";

interface Props {
  getMenu: Function;
  resetMenu: Function;
}

interface State {}

class Menu extends Component<Props, State> {
  state = {
    menuPath: {
      descriptor: {}
    }
  };

  componentDidMount() {
    const { getMenu } = this.props;

    return getMenu();
  }

  componentWillUnmount() {
    const { resetMenu } = this.props;

    return resetMenu();
  }

  componentWillReceiveProps(newProps: any) {
    const { menuPath, openMenu } = newProps;

    return this.setState({
      menuPath,
      openMenu
    });
  }

  _selectMenu = descriptor => event => {
    const { menuPath } = this.state;
    const { name: openMenu, link, subMenu } = descriptor;

    return this.setState(
      {
        openMenu,
        menuPath: {
          descriptor: subMenu ? menuPath.descriptor : descriptor
        }
      },
      () => {
        const { history } = this.props;

        if (link) {
          return history.push(`/main/${link}`);
        }
      }
    );
  };

  _selectSubMenu = descriptor => {
    return this.setState({
      menuPath: {
        descriptor
      }
    });
  };

  render() {
    const { menuDescriptor: menu, isIngenico } = this.props;
    const { menuPath, openMenu } = this.state;
    return (
      <ul className="top-menu">
        {menu.map(descriptor => {
          const {
            name: descriptorName,
            subMenu: descriptorSubMenu,
            icon: descriptorIcon
          } = descriptor;
          const setOpenDrop = openMenu === descriptorName;
          const isSelected =
            menuPath.descriptor.name === descriptorName ? "selected" : "";

          if (descriptorName) {
            return (
              <li key={descriptorName} className={isSelected}>
                <div
                  className={classNames(
                    "menu-title",
                    `menu-entry-${descriptorName}`,
                    { new: !isIngenico }
                  )}
                  onClick={this._selectMenu(descriptor)}
                >
                  <div
                    className={classNames(
                      "inner-menu-title",
                      {
                        new: !isIngenico
                      },
                      `${descriptor.icon}`
                    )}
                  >
                    <i
                      className={
                        !isIngenico
                          ? `icon-${descriptorIcon} ${styles.icon}`
                          : `glyphicon glyphicon-${descriptorIcon}`
                      }
                      aria-hidden="true"
                    />
                    <I18nSpan msgKey={`menu.${descriptorName}`} />
                  </div>
                </div>
                <SubMenu
                  open={setOpenDrop}
                  onChange={this._selectSubMenu}
                  descriptor={descriptor}
                  subMenu={descriptorSubMenu}
                  isIngenico={isIngenico}
                />
              </li>
            );
          }

          return null;
        })}
      </ul>
    );
  }
}

const mapMenuPath = ({ menuDescriptor, location }) => {
  const menuPath = menuDescriptor.reduce(
    (prevDescriptor, descriptor) => {
      if (descriptor.subMenu) {
        const subMenu = descriptor.subMenu.find(descriptorSubMenu => {
          const locationPath = location.pathname.replace(/\/main\//, "");

          return locationPath.includes(descriptorSubMenu.link);
        });

        if (subMenu) {
          return {
            ...prevDescriptor,
            descriptor,
            subMenu
          };
        }

        return prevDescriptor;
      } else {
        const descriptorLink = location.pathname.includes(descriptor.link);

        if (descriptorLink) {
          return {
            ...prevDescriptor,
            descriptor
          };
        }

        return prevDescriptor;
      }
    },
    {
      descriptor: menuDescriptor[0]
    }
  );
  return menuPath;
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { isIngenico }
  } = state;
  const { location } = ownProps;

  const {
    menu: { data: menu, loading }
  } = state;

  const menuPath = mapMenuPath({ menuDescriptor: menu, location });

  const { descriptor } = menuPath;
  const openMenu = descriptor ? menuPath.descriptor.name : false;

  return {
    menu,
    loading,
    menuDescriptor: menu,
    isIngenico,
    menuPath,
    openMenu
  };
};

const mapDispatchToProps = dispatch => ({
  getMenu: () => dispatch(getMenu()),
  resetMenu: () => dispatch(resetMenu())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
