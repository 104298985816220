import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

const InputMobile = ({ label, onChange, value, id, name }) => {
  const { darkMode: prefersDarkMode } = useSelector(state =>
    mapStateToProps(state)
  );

  const useStyles = makeStyles({
    root: {
      width: "100%"
    },
    label: {
      color: prefersDarkMode ? "#E5E5E5" : "#3B3B3B",
      fontSize: "1.4rem",
      textTransform: "uppercase",
      fontWeight: 700,
      fontFamily: "unset",
      "&$focusedLabel": {
        color: prefersDarkMode ? "#E5E5E5" : "#3B3B3B"
      }
    },
    focusedLabel: {},
    input: {
      padding: "calc(var(--vh, 1vh) * 0.8) 5vw calc(var(--vh, 1vh) * 0.8) 5vw",
      backgroundColor: prefersDarkMode ? "#3B3B3B" : "white",
      borderRadius: "7px",
      color: prefersDarkMode ? "#E5E5E5" : "#3B3B3B",
      fontSize: "14px",
      "&$focused": {
        color: prefersDarkMode ? "#E5E5E5" : "#3B3B3B"
      },
      "&:-webkit-autofill": {
        "-webkit-text-fill-color": "transparent",
        "-webkit-box-shadow": "inset 0 0 0 1000px transparent",
        backgroundClip: "content-box" // https://stackoverflow.com/a/55080978
      }
    },
    underline: {
      color: prefersDarkMode ? "#E5E5E5" : "#3B3B3B",
      "&:before": {
        border: "none"

        // borderColor: prefersDarkMode ? "#E5E5E5" : "#3B3B3B"
      },
      "&:hover:not($disabled):before": {
        border: "none",
        borderColor: prefersDarkMode ? "#E5E5E5" : "#3B3B3B"
      },
      "&:after": {
        // borderColor: "white"
        border: "none"
      }
    },
    focused: {},
    disabled: {}
  });

  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      id={id}
      name={name}
      label={label}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.label,
          focused: classes.focusedLabel
        }
      }}
      InputProps={{
        readOnly: true,
        classes: {
          root: classes.input,
          disabled: classes.disabled,
          underline: classes.underline
          // input: classes.input
        }
      }}
      defaultValue={value}
      value={value}
    />
  );
};

export default InputMobile;
