import React, { Component } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

class Radios extends Component {
  _onChange = e => {
    const { onChange, name } = this.props;

    return onChange(name, e.target.value);
  };

  render() {
    const { options, id, className, name, required } = this.props;

    return (
      <div>
        {_.map(options, option => (
          <label key={option} className="radio-inline">
            <input
              type="radio"
              id={id ? id : ""}
              className={className ? className : ""}
              name={name}
              onChange={this._onChange}
              value={option}
              required={required || false}
            />
            {option}
          </label>
        ))}
      </div>
    );
  }
}

Radios.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bo
};

export default Radios;
