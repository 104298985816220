import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import classNames from "classnames";
import { FixedSizeList as List } from "react-window";
import OutsideClickHandler from "react-outside-click-handler";
import { withTranslation } from "react-i18next";

import { capitalize } from "../commons/lang/StringUtils";

import Suggestion from "./autocomplete/Suggestion";
import ManufacturerUtils from "../manufacturer/Utils";
import { SimProviders } from "../sim/constants/SimFieldConstants";

import { getValues } from "../redux/actions";
import { store } from "../redux/store";

class Autocomplete extends Component<any, any> {
  inputEl: any;

  state = {
    ...this.buildStateFromProps(this.props),
    suggestions: [],
    createdItems: this.buildStateFromProps(this.props).createdItems || [],
    selectedSuggestion: {},
    selectedCreatedItem: false,
    isLastItemInCreationSelected: false,
    itemInCreation: [],
    isProcessing: false
  };

  componentWillReceiveProps(props) {
    this.setState(this.buildStateFromProps(props));
  }

  buildStateFromProps(props) {
    const { lists, createdItems } = props;
    const newState: any = {
      breadcrumb: [lists.beginBy],
      rootListName: lists.beginBy,
      lists
    };

    if (_.has(props, "createdItems")) {
      newState.createdItems = createdItems || [];
    }

    return newState;
  }

  onInputInput = event => {
    const newWidth = this.computeWidth(this.getInputEl(), event.target.value);

    return this.setState(
      {
        isLastCreatedItemSelected: false,
        inputWidth: newWidth
      },
      () => {
        const { onInput } = this.props;

        onInput(event);

        return this.showSuggestions();
      }
    );
  };

  onFocus = () => {
    if (!this.isNbMaxItems()) {
      const { openOnFocus } = this.props;
      const inputSearch = this.refs["input"] as HTMLElement;

      inputSearch.focus();

      if (openOnFocus) {
        return this.showSuggestions();
      }
    }
  };

  isNbMaxItems = () => {
    const { nbItemsMax } = this.props;
    const { createdItems } = this.state;

    return createdItems.length >= nbItemsMax;
  };

  onSuggestionClick = (event, selectedSuggestion) => {
    return this.setState(
      {
        selectedSuggestion,
        isOpen: false
      },
      () => this.pushSimpleFilter(selectedSuggestion)
    );
  };

  onRemoveBtnClick = clickedItem => {
    return () => {
      const { createdItems, isLastCreatedItemSelected } = this.state;
      const index = _.findIndex(createdItems, item => item === clickedItem);
      const isLastCreatedItem = index === createdItems.length - 1;
      const createdItem = createdItems.splice(index, 1);

      return this.setState(
        state => {
          return {
            ...state,
            createdItems,
            createdItem,
            isLastCreatedItemSelected:
              isLastCreatedItem && isLastCreatedItemSelected
                ? false
                : isLastCreatedItemSelected
          };
        },
        () => {
          const { onChange } = this.props;
          const { createdItems } = this.state;

          onChange(createdItems);

          return this.focus();
        }
      );
    };
  };

  onBlur = () => {
    return this.setState({
      selectedSuggestion: null,
      isOpen: false
    });
  };

  onKeyDown = event => {
    const { onKeyDown } = this.props;
    const { selectedSuggestion } = this.state;
    const { keyCode } = event;
    const BACKSPACE = 8;
    const TAB = 9;
    const ENTER = 13;
    const ESCAPE = 27;
    const UP_ARROW = 38;
    const RIGHT_ARROW = 39;
    const BOTTOM_ARROW = 40;

    switch (keyCode) {
      case ENTER:
      case RIGHT_ARROW: {
        event.preventDefault();
        this.pushSimpleFilter(selectedSuggestion);

        break;
      }
      case ESCAPE:
      case TAB: {
        event.preventDefault();
        this.onEscape();

        break;
      }
      case BOTTOM_ARROW: {
        this.move(this.down, event);

        break;
      }
      case UP_ARROW: {
        this.move(this.up, event);

        break;
      }
      case BACKSPACE: {
        this.onBackSpace(event);

        break;
      }
      default: {
        break;
      }
    }

    return onKeyDown(event);
  };

  onBackSpace = event => {
    if (this.previousItemCanBeDeleted()) {
      event.preventDefault();

      return this.setState(
        state => {
          const {
            isLastCreatedItemSelected,
            isLastItemInCreationSelected,
            itemInCreation,
            breadcrumb,
            createdItems
          } = state;

          if (isLastCreatedItemSelected || isLastItemInCreationSelected) {
            if (isLastItemInCreationSelected) {
              itemInCreation.length--;
              breadcrumb.length--;
            } else {
              createdItems.length > 0 && createdItems.length--;
            }
          }

          return {
            ...state,
            isLastItemInCreationSelected:
              isLastCreatedItemSelected || isLastItemInCreationSelected
                ? false
                : this.isInCreation(),
            isLastCreatedItemSelected:
              isLastCreatedItemSelected || isLastItemInCreationSelected
                ? false
                : !this.isInCreation()
          };
        },
        () => {
          const { onChange } = this.props;
          const { createdItems, itemInCreation } = this.state;

          if (_.isEmpty(itemInCreation)) onChange(createdItems);

          return this.showSuggestions();
        }
      );
    }
  };

  clearSelection = (callback?) => {
    return this.setState(
      state => {
        return {
          ...state,
          isLastItemInCreationSelected: false,
          isLastCreatedItemSelected: false
        };
      },
      () => {
        if (callback) callback(this.state);
      }
    );
  };

  previousItemCanBeDeleted = () => {
    const { breadcrumb, createdItems } = this.state;

    return (
      this.getInputEl().value === "" &&
      breadcrumb.length >= 1 &&
      createdItems.length >= 0
    );
  };

  isInCreation = () => {
    const { itemInCreation } = this.state;

    return itemInCreation.length > 0;
  };

  onEscape = () => {
    const { breadcrumb } = this.state;
    const { descendantList } = this.getCurrentList();

    if (descendantList === _.last(breadcrumb)) {
      return this.setState(
        state => {
          const { createdItems, itemInCreation } = state;

          return {
            ...state,
            breadcrumb: this.getInitialBreadcrumb(),
            createdItems: [...createdItems, itemInCreation],
            itemInCreation: []
          };
        },
        () =>
          this.clearSelection(newState => {
            const { onChange } = this.props;
            const { createdItems } = newState;

            onChange(createdItems);

            return this.showSuggestions();
          })
      );
    }
  };

  pushSimpleFilter = selectedSuggestion =>
    this.setFormatFilters(selectedSuggestion);

  setFormatFilters = async selectedSuggestion => {
    const { name, allowFreeText, countries, t } = this.props;
    const { lists } = this.state;
    let currentValue;
    const { value: inputValue } = this.getInputEl();

    if (selectedSuggestion) {
      currentValue = selectedSuggestion;
    } else if (allowFreeText && inputValue) {
      currentValue = inputValue;
    } else {
      return;
    }

    const { descendantList, name: field, type } = currentValue;

    if (type === "array" && field) {
      const values = await store.dispatch(
        getValues({ name, field, fields: [] })
      );
      const singleValueField = `one${capitalize(field)}`;

      const findCountry = (item: string) => {
        const country = countries.find((country: any) => country.code === item);

        return country.display;
      };

      const contractFormatter = (val: any) => {
        return val || val === SimProviders.SIERRA
          ? t(`sim.values.contract.${val}`)
          : "";
      };

      const operatorFormatter = (val: any) => {
        return val ? t(`sim.values.sim.operator.${val}`) : val;
      };

      const transactionFormatter = ({ field, val }) => {
        return t(`reporting.transaction.values.${field}.${val}`, val);
      };

      const computeRawField = ({ field, item }) => {
        switch (field) {
          case "sim.operator": {
            const rawValue = operatorFormatter(item);

            return { rawValue };
          }
          case "sim.contract": {
            const rawValue = contractFormatter(item);

            return { rawValue };
          }
          case "poi.manufacturerId": {
            const rawValue = ManufacturerUtils.manufacturerNameFromCode(item);

            return { rawValue };
          }
          case "store.country": {
            const rawValue = findCountry(item);

            return { rawValue };
          }
          case "selectedService":
          case "transactionType":
          case "transactionResult":
          case "technologySelected":
          case "status":
          case "nokReason":
          case "cvmResult":
          case "cardType": {
            const rawValue = transactionFormatter({ field, val: item });

            return { rawValue };
          }
          default:
            return {};
        }
      };

      const itemToOption = descendantList => item => {
        return {
          value: item,
          label: item,
          ...computeRawField({ field, item }),
          descendantList
        };
      };

      return this.setState(
        state => {
          const { itemInCreation, breadcrumb, createdItems } = state;

          if (descendantList) {
            return {
              ...state,
              itemInCreation: [...itemInCreation, currentValue],
              breadcrumb: [...breadcrumb, descendantList],
              lists: {
                ...lists,
                [field]: {
                  ...lists[field],
                  options: values.map(itemToOption(field))
                },
                [singleValueField]: {
                  ...lists[singleValueField],
                  options: values.map(itemToOption(""))
                }
              }
            };
          }

          return {
            ...state,
            breadcrumb: this.getInitialBreadcrumb(),
            createdItems:
              itemInCreation.length > 0
                ? [...createdItems, [...itemInCreation, currentValue]]
                : [...createdItems, currentValue],
            itemInCreation: itemInCreation.length > 0 ? [] : itemInCreation,
            lists: {
              ...lists,
              [field]: {
                ...lists[field],
                options: values.map(itemToOption(field))
              },
              [singleValueField]: {
                ...lists[singleValueField],
                options: values.map(itemToOption(""))
              }
            }
          };
        },
        () => this.clearSelection(this.chooseSuggestion)
      );
    }

    return this.setState(
      state => {
        const { itemInCreation, breadcrumb, createdItems } = state;

        if (descendantList) {
          return {
            ...state,
            itemInCreation: [...itemInCreation, currentValue],
            breadcrumb: [...breadcrumb, descendantList]
          };
        }

        return {
          ...state,
          breadcrumb: this.getInitialBreadcrumb(),
          createdItems:
            itemInCreation.length > 0
              ? [...createdItems, [...itemInCreation, currentValue]]
              : [...createdItems, currentValue],
          itemInCreation: itemInCreation.length > 0 ? [] : itemInCreation
        };
      },
      () => this.clearSelection(this.chooseSuggestion)
    );
  };

  chooseSuggestion = newState => {
    const { onChange } = this.props;
    const { createdItems, itemInCreation } = newState;

    if (createdItems.length > 0 && !itemInCreation.length)
      onChange(createdItems);

    this.getInputEl().value = "";

    if (this.isNbMaxItems()) {
      this.blur();

      return this.onBlur();
    } else {
      return this.setState(newState, () => this.showSuggestions());
    }
  };

  move = (motion, event) => {
    event.preventDefault();
    const { isOpen } = this.state;

    if (!isOpen) {
      this.showSuggestions();
    }

    return this.setState(
      state => {
        const nextIndex = motion();
        const { suggestions } = state;

        return {
          ...state,
          selectedSuggestion: suggestions[nextIndex]
        };
      },
      () => this.clearSelection()
    );
  };

  up = () => {
    const potentialNextIndex = this.getSelectedSuggestionIndex() - 1;

    return Math.max(potentialNextIndex, 0);
  };

  down = () => {
    const { suggestions } = this.state;
    const potentialNextIndex = this.getSelectedSuggestionIndex() + 1;

    return Math.min(potentialNextIndex, suggestions.length - 1);
  };

  getSelectedSuggestionIndex = () => {
    const { suggestions, selectedSuggestion } = this.state;

    return _.findIndex(
      suggestions,
      suggestion => suggestion === selectedSuggestion
    );
  };

  focus = () => this.getInputEl().focus();

  blur = () => this.getInputEl().blur();

  showSuggestions = () => {
    if (!this.isNbMaxItems()) {
      const { single, allowFreeText, filterFunction, name } = this.props;
      const { createdItems, itemInCreation } = this.state;
      const { value: inputValue } = this.getInputEl();
      const regex = new RegExp(inputValue, "i");
      const { options = [] } = this.getCurrentList();
      const getCurrentListFiltered = options.filter(item => {
        const { label, rawLabel } = item;
        return regex.test(rawLabel ? rawLabel : label);
      });
      const filteredOptions =
        single && !getCurrentListFiltered.length && allowFreeText
          ? [{ label: inputValue, value: inputValue }]
          : getCurrentListFiltered;
      const suggestions = name
        ? filterFunction(filteredOptions, itemInCreation.slice(2))
        : filterFunction(filteredOptions, createdItems);

      return this.setState({
        isOpen: true,
        suggestions,
        selectedSuggestion: suggestions[0]
      });
    }
  };

  getInCreationListClassName = () => {
    const { itemInCreation } = this.state;

    return classNames("autocomplete--inCreation", {
      enable: itemInCreation.length === 0
    });
  };

  getCurrentList = () => {
    const { lists, breadcrumb } = this.state;
    const list = lists[_.last(breadcrumb as string)];

    return list || { options: [], inputType: "text" };
  };

  getInitialBreadcrumb = () => {
    const { rootListName } = this.state;

    return [rootListName];
  };

  getInputEl = () => {
    if (!this.inputEl) {
      this.inputEl = this.refs["input"];
    }

    return this.inputEl;
  };

  getPlaceHolder = () => {
    const { placeholder } = this.props;
    const { breadcrumb } = this.state;

    return breadcrumb.length === 1 ? placeholder : "";
  };

  getStyle = () => {
    const { inputWidth: width } = this.state;

    return { width };
  };

  computeWidth = (input, newValue) => {
    const sizer = document.createElement("span");
    sizer.id = "sizer";
    const fullStyle = window.getComputedStyle(input);
    sizer.style.font = fullStyle.font;
    sizer.style.padding = fullStyle.padding;
    sizer.style.display = "inline-block";
    sizer.style.opacity = "0";
    sizer.style.position = "absolute";
    sizer.innerHTML = newValue;
    document.body.appendChild(sizer);
    const width = `${sizer.offsetWidth}px`;
    document.body.removeChild(sizer);

    return width;
  };

  render() {
    const {
      createdItemLabelFormatter,
      suggestionFormatter,
      className,
      disabled,
      id
    } = this.props;
    const {
      createdItems,
      isLastCreatedItemSelected,
      itemInCreation,
      isLastItemInCreationSelected,
      suggestions,
      selectedSuggestion,
      isOpen: opened
    } = this.state;

    const createdItemsList = createdItems.map((item, index) => {
      const isSelected =
        isLastCreatedItemSelected && index === createdItems.length - 1;

      return (
        <li
          key={`created-item-${index}`}
          className={isSelected ? "item selected" : "item"}
        >
          {createdItemLabelFormatter(item)}
          <span className="icon-remove" onClick={this.onRemoveBtnClick(item)} />
        </li>
      );
    });

    const inCreationList = itemInCreation.map((item, index) => {
      const isSelected =
        isLastItemInCreationSelected && index === itemInCreation.length - 1;

      return (
        <li
          key={`item-in-creation-${index}`}
          className={isSelected ? "item selected" : "item"}
        >
          {item.rawLabel
            ? item.rawLabel
            : item.rawValue
            ? item.rawValue
            : item.label}
        </li>
      );
    });

    const placeholder = this.getPlaceHolder();

    return (
      <div
        ref="root"
        className={`autocomplete magnifier ${className} ${classNames({
          disabled
        })}`}
        id={id}
      >
        <div
          id="autocomplete--wrapper"
          ref="adscontainer"
          onClick={this.onFocus}
        >
          <ul className="autocomplete--createdItems">{createdItemsList}</ul>
          <ul className={this.getInCreationListClassName()}>
            {inCreationList}
          </ul>
          <span className="autocomplete--container">
            <OutsideClickHandler
              onOutsideClick={() => {
                return this.setState({ isOpen: false });
              }}
            >
              <ul
                ref={ref => (this.suggestionsRef = ref)}
                className={classNames("autocomplete--suggestions", { opened })}
              >
                <List
                  className="List"
                  height={
                    suggestions.length * 26 < 250
                      ? suggestions.length * 26
                      : 250
                  }
                  itemCount={suggestions.length}
                  itemSize={26}
                  width={180}
                >
                  {({ index, style }) => {
                    const suggestion = suggestions[index];

                    return (
                      <Suggestion
                        key={`suggestion-item-${suggestion.label}-${index}`}
                        selectedSuggestion={selectedSuggestion}
                        suggestion={suggestion}
                        onSuggestionClick={this.onSuggestionClick}
                        formatter={
                          suggestionFormatter(
                            suggestion,
                            this.getInputEl().value
                          ) as any
                        }
                        style={style}
                      />
                    );
                  }}
                </List>
              </ul>
            </OutsideClickHandler>
            <input
              ref="input"
              className="autocomplete--input"
              type={this.getCurrentList().inputType || "text"}
              onInput={this.onInputInput}
              onKeyDown={this.onKeyDown}
              style={this.getStyle()}
              placeholder={placeholder}
            />
          </span>
        </div>
      </div>
    );
  }

  public static defaultProps = {
    onInput: () => undefined,
    onKeyDown: () => undefined,
    onChange: () => undefined,
    createdItemLabelFormatter: item =>
      item
        .map(part => {
          return part.rawLabel
            ? part.rawLabel
            : part.rawValue
            ? part.rawValue
            : part.label || part;
        })
        .join(" "),
    suggestionFormatter: item =>
      item.rawLabel
        ? item.rawLabel
        : item.rawValue
        ? item.rawValue
        : item.label,
    placeholder: "",
    openOnFocus: true,
    lists: {},
    filterFunction: (all, _) => all,
    nbItemsMax: 100
  };

  public static propTypes = {
    onInput: PropTypes.func,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    createdItemLabelFormatter: PropTypes.func,
    suggestionFormatter: PropTypes.func,
    nbItemsMax: PropTypes.number,
    placeholder: PropTypes.string,
    openOnFocus: PropTypes.bool,
    allowFreeText: PropTypes.bool,
    lists: PropTypes.object,
    filterFunction: PropTypes.func,
    disabled: PropTypes.bool,
    single: PropTypes.bool,
    name: PropTypes.string
  };
}

export default withTranslation()(Autocomplete);
