import React, { Component } from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import ExclusiveContainer from "../../ingenicoForm/components/ExclusiveContainer";
import TagInput from "../../ingenicoForm/components/TagInput";
import MultipleSelectWithValue from "../../ingenicoForm/components/MultipleSelectWithValue";
import AuthConstants from "../../connection/constants/ConnectionConstant";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";

type RestrictedByContainerProps = {
  form: any;
  options: any;
  readOnly: any;
  errors: any;
  fullMode?: boolean;
  onChangeHandler: any;
  levelType: string;
};

class RestrictedByContainer extends Component<RestrictedByContainerProps, {}> {
  buildExclusiveContainerChangeHandler = (exclusiveGroupName: string) => (
    newSelectedName,
    previousSelectedName
  ) => {
    const { form } = this.props;

    delete form[previousSelectedName];
    form[exclusiveGroupName] = newSelectedName;

    return this.setState(form);
  };

  render() {
    const {
      form,
      options,
      readOnly,
      errors,
      fullMode,
      onChangeHandler,
      levelType,
      t
    } = this.props;

    const disabledRestrictedBy = !readOnly.authenticatedUserScopeAccessFull
      ? [
          "restrictedByIds",
          "restrictedByTags",
          "restrictedByTransactionField"
        ].find(restrictedBy => readOnly[restrictedBy] === true)
      : false;

    const Empty = () => null;

    const restrictedByTagsElement = !_.isEmpty(options.tags) ? (
      <div
        key="restrictedByTags"
        label={t("user.form.tags")}
        name="restrictedByTags"
      >
        <div className="tag-header">{t("menu.tags")}</div>
        <TagInput
          key={`restrictedByTags.Tag`}
          name="restrictedByTags"
          tags={options.tags}
          formValue={form.restrictedByTags}
          onChange={onChangeHandler}
        />
      </div>
    ) : null;

    const restrictedByTransactionFieldElement = !_.isEmpty(options.fields) ? (
      <div
        label={t("user.form.transactionFields")}
        name="restrictedByTransactionField"
        key="restrictedByTransactionField"
      >
        <MultipleSelectWithValue
          name="restrictedByTransactionField"
          fields={options.fields}
          formValue={form.restrictedByTransactionField}
          onChange={onChangeHandler}
          readOnly={disabledRestrictedBy === "restrictedByTransactionField"}
        />
      </div>
    ) : null;

    const tagOrScopeGroup = [
      <BootstrapInput
        descriptor={{
          type: "custom",
          component: Empty
        }}
        ref="full"
        name="full"
        label={t("user.form.full")}
        onChange={onChangeHandler}
        required={false}
        formValue={form.full}
        errors={errors.full}
      />,
      <BootstrapInput
        descriptor={{
          className: "scopeUser",
          type: "multipleselectwindowed",
          options: options.restrictedByIds,
          isMulti: true,
          readOnly: disabledRestrictedBy === "restrictedByIds"
        }}
        ref="restrictedByIds"
        name="restrictedByIds"
        label={`${t("header.portal.restrictedByIds")} ${t(
          `${
            levelType === AuthConstants.CUSTOMER
              ? AdvancedSearchConstants.MERCHANT_KEY
              : AdvancedSearchConstants.STORE_KEY
          }.title`
        )}`}
        onChange={onChangeHandler}
        required={false}
        formValue={form.restrictedByIds}
        errors={errors.restrictedByIds}
      />,
      restrictedByTagsElement,
      restrictedByTransactionFieldElement
    ];

    const tagOrScopeGroupWithoutEmptyDivs = tagOrScopeGroup.reduce(
      (prev, curr) => {
        if (curr) {
          return [
            ...prev,
            {
              ...curr,
              key: curr.key || curr.ref
            }
          ];
        }

        return prev;
      },
      []
    );

    if (fullMode) {
      return (
        <div>
          <BootstrapInput
            descriptor={{
              id: "restrictedByServices",
              type: "checkbox",
              className: "serviceSet mtn",
              label: "user.form.restrictedByServices",
              readOnly: readOnly.restrictedByServices
            }}
            ref="restrictedByServices"
            name="restrictedByServices"
            onChange={onChangeHandler}
            required={false}
            formValue={form.restrictedByServices}
            errors={errors.restrictedByServices}
          />
          <BootstrapInput
            descriptor={{
              id: "serviceSet",
              type: "multipleselect",
              className: "serviceSet mtn",
              label: "user.form.services",
              options: options.serviceSet
            }}
            ref="serviceSet"
            name="serviceSet"
            onChange={onChangeHandler}
            required={false}
            formValue={form.serviceSet}
            errors={errors.serviceSet}
            hide={!form.restrictedByServices}
          />
          <ExclusiveContainer
            groupName="tag_or_scope"
            disabled={disabledRestrictedBy}
            onChange={this.buildExclusiveContainerChangeHandler("tag_or_scope")}
            selectedName={form["tag_or_scope"]}
          >
            {tagOrScopeGroupWithoutEmptyDivs}
          </ExclusiveContainer>
        </div>
      );
    }

    return null;
  }
}

RestrictedByContainer.defaultProps = {
  fullMode: true
};

export default withTranslation()(RestrictedByContainer);
