import React from "react";
import { Link, withRouter } from "react-router-dom";

import AlertConstants from "../constants/AlertConstant";

import I18nSpan from "../../i18n/components/I18nSpan";

export const Tabs = ({ target, active, transactionAlert, fraudAlert }) => {
  switch (true) {
    case transactionAlert && fraudAlert: {
      return (
        <div className="btn-group" role="group">
          <Link
            title={AlertConstants.PREDEFINED_ALERT}
            type="button"
            name={AlertConstants.PREDEFINED_ALERT}
            className={`btn btn-ingenico ${
              active === AlertConstants.PREDEFINED_ALERT ? "active" : ""
            }`}
            to={`${target}${AlertConstants.PREDEFINED_ALERT}`}
          >
            <I18nSpan msgKey={"alert.list.button.predefined"} />
          </Link>
          <Link
            title={AlertConstants.ADVANCED_ALERT}
            type="button"
            name={AlertConstants.ADVANCED_ALERT}
            className={`btn btn-ingenico ${
              active === AlertConstants.ADVANCED_ALERT ? "active" : ""
            }`}
            to={`${target}${AlertConstants.ADVANCED_ALERT}`}
          >
            <I18nSpan msgKey={"alert.list.button.advanced"} />
          </Link>
        </div>
      );
    }
    default:
      return null;
  }
};
