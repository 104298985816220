import * as moment from "moment";
import {
  CreatePredefinedAlert,
  languages,
  PredefinedAlert,
  PredefinedAlertType
} from "./PredefinedAlert";
import PredefinedConstants from "../PredefinedAlertConstant";

const humanizeDuration = require("humanize-duration");

export class CreateRefundOverThresholdAlert extends CreatePredefinedAlert {
  static create(currency: string, exponent: number) {
    return new CreateRefundOverThresholdAlert(
      moment.duration(PredefinedConstants.DEFAULT_DURATION, "minutes"),
      PredefinedConstants.DEFAULT_VALUE_THRESHOLD,
      currency,
      exponent
    );
  }

  constructor(
    public duration: moment.Duration,
    public valueThreshold: number,
    public valueThresholdCurrency: string,
    public valueThresholdExponent: number
  ) {
    super(RefundOverThresholdAlert.TYPE);
    this.duration =
      duration ||
      moment.duration(PredefinedConstants.DEFAULT_DURATION, "minutes");
    this.valueThreshold =
      valueThreshold || PredefinedConstants.DEFAULT_VALUE_THRESHOLD;
    this.valueThresholdCurrency = valueThresholdCurrency || "";
    this.valueThresholdExponent = valueThresholdExponent || 2;
  }
}

export class RefundOverThresholdAlert extends PredefinedAlert {
  static TYPE: PredefinedAlertType = "RefundOverThreshold";
  static I18N_KEY = "refund-over-threshold";

  constructor(
    public id: string,
    public timeWindow: moment.Duration,
    public valueThreshold: number,
    public valueThresholdCurrency: string,
    public valueThresholdExponent: number
  ) {
    super();
    this.id = id;
    this.timeWindow = timeWindow;
    this.valueThreshold = valueThreshold;
    this.valueThresholdCurrency = valueThresholdCurrency;
    this.valueThresholdExponent = valueThresholdExponent;
  }

  i18nKey(): string {
    return RefundOverThresholdAlert.I18N_KEY;
  }

  i18nArgs(locale: string): Array<string> {
    return [
      this.valueThresholdCurrency,
      this.valueThreshold / Math.pow(10, this.valueThresholdExponent),
      humanizeDuration((this.timeWindow as any)._milliseconds, {
        delimiter: " ",
        largest: 2,
        round: true,
        language: locale,
        languages: languages
      })
    ];
  }
}
