import {
  FAILURE_ADD_CATALOGITEM_ACTION,
  PENDING_ADD_CATALOGITEM_ACTION,
  SUCCESS_ADD_CATALOGITEM_ACTION,
  RESET_CATALOGITEM_STATE_ACTION,
  PENDING_GET_CATALOGITEM_ACTION,
  SUCCESS_GET_CATALOGITEM_ACTION,
  FAILURE_GET_CATALOGITEM_ACTION,
  PENDING_GET_PHOTO_ACTION,
  SUCCESS_GET_PHOTO_ACTION,
  FAILURE_GET_PHOTO_ACTION,
  PENDING_GET_MEASURES_ACTION,
  SUCCESS_GET_MEASURES_ACTION,
  FAILURE_GET_MEASURES_ACTION
} from "../actionsTypes";

const initialState = {
  data: [],
  photo: "",
  measures: [],
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_ADD_CATALOGITEM_ACTION:
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    case SUCCESS_ADD_CATALOGITEM_ACTION: {
      const { catalogItem } = action.payload;
      return {
        ...state,
        data: {
          ...catalogItem
        }
      };
    }
    case FAILURE_ADD_CATALOGITEM_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case PENDING_GET_CATALOGITEM_ACTION: {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_CATALOGITEM_ACTION: {
      const { catalogItem } = action.payload;
      return {
        ...state,
        data: {
          ...catalogItem
        },
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_CATALOGITEM_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case PENDING_GET_PHOTO_ACTION: {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_PHOTO_ACTION: {
      const { photo } = action.payload;
      return {
        ...state,
        photo,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_PHOTO_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case PENDING_GET_MEASURES_ACTION: {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_MEASURES_ACTION: {
      const { measures } = action.payload;
      return {
        ...state,
        measures,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_MEASURES_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_CATALOGITEM_STATE_ACTION:
      return initialState;
    default:
      return state;
  }
};
