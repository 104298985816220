import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import _ from "lodash";
import { connect } from "react-redux";

import { loadWidget } from "../widget/widget";

import I18nSpan from "../i18n/components/I18nSpan";
import If from "./if";
import Permissions from "../user/constants/Permissions";
import ConnectionConstant from "../connection/constants/ConnectionConstant";
import Utils from "../merchant/Utils";

import {
  revoke,
  collapseMenu,
  getMerchants,
  getStores
} from "../redux/actions";

import styles from "./styles/styles.css";
import MatchMobileContainer from "../gridCharts/MatchMobileContainer";

class Header extends Component<Props, State> {
  state = {
    hideMenu: true
  };

  componentDidMount() {
    const { history, cscUser, api, cscPortalURL, location } = this.props;

    this.loadData();

    if (cscUser) {
      loadWidget(
        {
          api,
          history,
          system: "msh"
        },
        cscPortalURL
      );
    }
    this.setState({ pathname: location.pathname });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== this.state.pathname) {
      this.loadData();
      this.setState({ pathname: this.props.location.pathname });
    }
  }

  loadData = () => {
    const {
      isCustomer,
      isMerchant,
      restrictedByIds,
      getMerchants,
      getStores,
      hasStoreManagement,
      hasMerchantManagement
    } = this.props;

    if (restrictedByIds) {
      switch (true) {
        case isCustomer && hasMerchantManagement: {
          getMerchants({
            filters: [
              {
                filterType: "array",
                name: "merchant.id",
                operator: "in",
                value: restrictedByIds
              }
            ],
            fields: ["merchant.name"]
          });

          break;
        }
        case isMerchant && hasStoreManagement: {
          getStores({
            filters: [
              {
                filterType: "array",
                name: "store.id",
                operator: "in",
                value: restrictedByIds
              }
            ],
            fields: ["store.name"]
          });

          break;
        }
        default:
          break;
      }
    }
  };

  logout = async () => {
    const { revoke, refreshToken } = this.props;

    revoke(refreshToken);
  };

  _toggleMenu = () => {
    return this.setState(state => ({
      hideMenu: !state.hideMenu
    }));
  };

  _outsideMenu = () => {
    const { hideMenu } = this.state;

    if (!hideMenu) {
      return this._toggleMenu();
    }
  };

  toggleCollapse = () => {
    const { collapseMenu, collapsed } = this.props;
    return collapseMenu({ collapsed: !collapsed });
  };

  render() {
    const {
      user,
      timezoneOffset,
      shownName,
      cscUser,
      isMerchant,
      hasImpersonation,
      logoContent,
      restrictedNames
    } = this.props;
    const { hideMenu } = this.state;
    const I18nMsgKey = `header.portal.${
      isMerchant ? ConnectionConstant.MERCHANT : ConnectionConstant.CUSTOMER
    }`;

    const userSpecificMarkup = cscUser ? (
      <div className={styles["authenticated-header"]}>
        <div id="csc-widget" className="csc-widget-container" />
      </div>
    ) : (
      <div className={styles["authenticated-header"]}>
        <span id="username" className={styles.username}>
          {shownName}
        </span>
        <span className={styles.timeZone}>{timezoneOffset}</span>
        <OutsideClickHandler onOutsideClick={this._outsideMenu}>
          <div className="itemAccountMenu">
            <a
              onClick={this._toggleMenu}
              className={classNames(
                "edit-user-btn",
                styles.mainLinkAccountMenu
              )}
            />
            <ul className={classNames({ [styles.hideMenuUl]: hideMenu })}>
              <li className={classNames({ [styles.hideMenuLi]: hideMenu })}>
                <Link
                  className="edit-settings"
                  to="/main/my-account"
                  onClick={this._toggleMenu}
                >
                  <i className={`icon ${styles["icon-my_account"]}`} />
                  <div className={styles["menu-list-item-wrapper"]}>
                    <I18nSpan
                      class={styles["menu-list-item"]}
                      msgKey="header.myAccount"
                    />
                  </div>
                </Link>
              </li>
              <li className={classNames({ [styles.hideMenuLi]: hideMenu })}>
                <Link
                  className="edit-password"
                  to="/main/edit-password"
                  onClick={this._toggleMenu}
                >
                  <i className={`icon ${styles["icon-edit"]}`} />
                  <div className={styles["menu-list-item-wrapper"]}>
                    <I18nSpan
                      class={styles["menu-list-item"]}
                      msgKey="user.passwordForm.settingsLink"
                    />
                  </div>
                </Link>
              </li>
              {hasImpersonation && (
                <li className={classNames({ [styles.hideMenuLi]: hideMenu })}>
                  <Link
                    className="login-as-btn"
                    to="/main/monitoring/login-as"
                    onClick={this._toggleMenu}
                  >
                    <i className={`icon ${styles["icon-my_account"]}`} />
                    <div className={styles["menu-list-item-wrapper"]}>
                      <I18nSpan
                        class={styles["menu-list-item"]}
                        msgKey="header.login-as"
                      />
                    </div>
                  </Link>
                </li>
              )}
              <li className={classNames({ [styles.hideMenuLi]: hideMenu })}>
                <Link className="logout-btn" onClick={this.logout}>
                  <i className={`icon ${styles["icon-log_out"]}`} />
                  <div className={styles["menu-list-item-wrapper"]}>
                    <I18nSpan
                      class={styles["menu-list-item"]}
                      msgKey="header.logout"
                    />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </OutsideClickHandler>
      </div>
    );

    const LogoMSH = () => (
      <p className={`logo-container ${styles["logo-container"]}`}>
        <I18nSpan msgKey="login.logo" class={styles["login-logo"]} />
      </p>
    );

    const RestrictionsMarkup = ({
      restrictedByTags,
      restrictedByIds,
      restrictedNames
    }) => (
      <div className="restriction-card">
        <ul>
          <li>
            <If test={restrictedByTags}>
              <span
                className={
                  restrictedByTags
                    ? "restrictedByTags label label-success mls"
                    : "hidden"
                }
              >
                <I18nSpan msgKey="header.portal.restrictedByTags" /> :{" "}
                {_.values(restrictedByTags).join(", ")}
              </span>
            </If>
          </li>
          <li>
            <If test={restrictedByIds && restrictedNames.length !== 0}>
              <span
                className={
                  restrictedByIds
                    ? "restrictedByIds label label-success mls"
                    : "hidden"
                }
              >
                <I18nSpan msgKey="header.portal.restrictedByIds" /> :{" "}
                {restrictedNames.join(", ")}
              </span>
            </If>
          </li>
        </ul>
      </div>
    );

    const userSpecificAndRestrictionsMarkupWrapper = user ? (
      <div className="col-md-3 col-sm-3 col-xs-10 pull-xs-right header-top">
        {userSpecificMarkup}
        <RestrictionsMarkup
          restrictedByTags={user.scope.access.restrictedByTags}
          restrictedByIds={user.scope.access.restrictedByIds}
          restrictedNames={restrictedNames}
        />
      </div>
    ) : null;

    return (
      <MatchMobileContainer
        renderDesktop={() => (
          <div className="header">
            <button
              type="button"
              className="btn collapse-btn"
              aria-label="Menu"
              onClick={this.toggleCollapse}
            >
              <span
                className="glyphicon glyphicon-menu-hamburger"
                aria-hidden="true"
              />
            </button>
            <div className="row">
              <div className="col-md-9 col-sm-9 hidden-xs">
                <div className={styles["logo-wrapper"]}>
                  <LogoMSH />
                </div>
              </div>
              {userSpecificAndRestrictionsMarkupWrapper}
            </div>
          </div>
        )}
        renderMobile={() => (
          <div className="header">
            <div className="row">
              <div className="col-md-9 col-sm-9 hidden-xs">
                <div className={styles["logo-wrapper"]}>
                  <LogoMSH />
                </div>
              </div>
              {userSpecificAndRestrictionsMarkupWrapper}
            </div>
          </div>
        )}
      />
    );
  }
}

export const HeaderWithRouter = withRouter(Header);

export const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user,
      isCustomer,
      isMerchant,
      token: { refresh_token: refreshToken }
    },
    theme: {
      logo: { data: logoContent }
    },
    config: {
      data: { apiGatewayCscURL, cscPortalURL }
    },
    menu: { collapsed },
    merchants: { data: merchants },
    stores: { data: stores }
  } = state;

  const timezoneOffset = user ? user.timezone : "";
  const shownName = user ? user.name || user.login : "";
  const restrictedByIds =
    user && user.scope.access.restrictedByIds
      ? user.scope.access.restrictedByIds
      : false;

  const hasImpersonation = user
    ? _.includes(user.permissions, Permissions.IMPERSONATION)
    : false;

  const hasStoreManagement = user
    ? _.includes(user.permissions, Permissions.STORE_MANAGEMENT)
    : false;

  const hasMerchantManagement = user
    ? _.includes(user.permissions, Permissions.MERCHANT_MANAGEMENT)
    : false;

  const restriction = isCustomer
    ? Utils.getForUser({ user, list: merchants })
    : Utils.getForUser({ user, list: stores });

  const restrictedNames = restriction.map((r: any) => {
    switch (true) {
      case isCustomer: {
        return r.merchant.name;
      }
      case isMerchant: {
        return r.store.name;
      }
      default:
        break;
    }
  });

  return {
    user,
    refreshToken,
    timezoneOffset,
    shownName,
    restrictedNames,
    restrictedByIds,
    isCustomer,
    isMerchant,
    hasImpersonation,
    hasStoreManagement,
    hasMerchantManagement,
    logoContent,
    collapsed,
    cscUser: user.cscUser,
    api: apiGatewayCscURL,
    cscPortalURL
  };
};

const mapDispatchToProps = dispatch => ({
  revoke: refreshToken => dispatch(revoke(refreshToken)),
  collapseMenu: ({ collapsed }) => dispatch(collapseMenu({ collapsed })),
  getMerchants: ({ filters, fields, sort, tableCount }) =>
    dispatch(getMerchants({ filters, fields, sort, tableCount })),
  getStores: ({ filters, fields, sort, tableCount }) =>
    dispatch(getStores({ filters, fields, sort, tableCount }))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWithRouter);
