import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";

import I18nSpan from "../../../i18n/components/I18nSpan";
import SaleDetailView from "./SaleDetailView";
import DateFormatter from "../../../formatters/DateFormatter";
import Textarea from "../../../ingenicoForm/components/form/Textarea";
import SaleConstants from "../constants/SaleConstants";
import Loading from "../../../components/Loading";
import {
  getSaleDetail,
  setSaleDetail,
  getComment,
  editComment,
  hideSaleDetail,
  resetCommentState,
  addNotificationSuccess,
  addNotificationError
} from "../../../redux/actions";

import styles from "../styles/styles.css";

interface Props {
  required: any;
  salesCount: any;
  sales: any;
  sale: any;
  saleFields: any;
  showModal: any;
  showEmpty: boolean;
  saleFieldsWithI18n: any;
  isLoading: boolean;
  saleIndex: number;
  setSaleDetail: Function;
  getSaleDetail: Function;
  form: {
    comment: string;
  };
}

interface State {
  showEmpty: boolean;
  form: {
    comment: string;
  };
}

class SaleViewer extends Component<Props, State> {
  state = {
    showEmpty: false,
    form: {}
  } as State;

  componentWillReceiveProps(newProps: any) {
    const { form } = newProps;

    return this.setState({
      form
    });
  }

  close = () => {
    const { setSaleDetail, hideSaleDetail, resetCommentState } = this.props;

    setSaleDetail({ detail: {} });
    hideSaleDetail();
    resetCommentState();
  };

  changeFieldVisibility = () => {
    this.setState({
      showEmpty: !this.state.showEmpty
    } as State);
  };

  previousSale = () => {
    const { saleIndex } = this.props;
    this.changeSale(saleIndex - 1);
  };

  nextSale = () => {
    const { saleIndex } = this.props;
    this.changeSale(saleIndex + 1);
  };

  changeSale = async index => {
    const { getSaleDetail, findComment, sales } = this.props;

    if (index >= 0 && index < sales.length) {
      const { saleId } = sales[index];

      getSaleDetail({ id: saleId });
      findComment({ saleId });
    }
  };

  _onChangeHandler = (name, value) => {
    return this.setState(state => {
      return {
        ...state,
        form: {
          ...state.form,
          [name]: value
        }
      };
    }, this.checkErrors);
  };

  _saveBtnHandler = e => {
    e.preventDefault();
    const {
      sale: { saleId },
      putComment,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const {
      form: { comment }
    } = this.state;

    putComment({ saleId, comment })
      .then(() => {
        addNotificationSuccess("reporting.comment.edit.success");
      })
      .catch(error => {
        const response = JSON.parse(error.responseText);
        addNotificationError(response.key);
      });
  };

  render() {
    const {
      required,
      showModal,
      saleIndex,
      isLoading,
      salesCount,
      sales,
      sale,
      saleFieldsWithI18n
    } = this.props;
    const {
      showEmpty,
      form: { comment }
    } = this.state;

    const salesCountPagination = sales.length;

    interface CarouselControlProps {
      index: number;
      left?: boolean;
      right?: boolean;
    }

    const CarouselControl = ({ index, left, right }: CarouselControlProps) => {
      switch (true) {
        case left && index > 0: {
          return (
            <a
              className="carousel-control left"
              role="button"
              id="previous-sale"
              onClick={this.previousSale}
            >
              <span>
                <i className="glyphicon glyphicon-chevron-left" />
              </span>
            </a>
          );
        }
        case right && index < sales.length - 1: {
          return (
            <a
              className="carousel-control right"
              role="button"
              id="next-sale"
              onClick={this.nextSale}
            >
              <span className="glyphicon glyphicon-chevron-right" />
            </a>
          );
        }
        default:
          return null;
      }
    };

    interface PaginationProps {
      index: number;
      count: number;
    }

    const Pagination = ({ index, count }: PaginationProps) => {
      return (
        <div>
          <span>{(index >= 0 ? index : 0) + 1}</span>
          <span> / </span>
          <span>{count}</span>
        </div>
      );
    };

    if (salesCount > 0) {
      return (
        <div>
          <Modal
            show={showModal}
            onHide={this.close}
            animation={false}
            dialogClassName="transaction-detail-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <I18nSpan msgKey={"reporting.sale.detail"} />
                <label id="input-show-empty">
                  <input
                    type="checkbox"
                    checked={showEmpty}
                    onChange={this.changeFieldVisibility}
                  />
                  <I18nSpan msgKey="reporting.sale.form.showEmpty" />
                </label>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Loading isLoading={isLoading}>
                <CarouselControl index={saleIndex} left={true} />

                <SaleDetailView
                  sale={sale}
                  hideEmpty={!showEmpty}
                  fields={saleFieldsWithI18n}
                />

                <div className="comment-update form-group row">
                  <label className="text-right-not-xs col-sm-4 col-xs-12">
                    <I18nSpan msgKey="reporting.sale.form.comment" />
                  </label>
                  <div className="col-sm-8 col-xs-12">
                    <Textarea
                      name="comment"
                      validation={() => undefined}
                      required={required || false}
                      value={comment}
                      onChange={this._onChangeHandler}
                      className={styles["textAreaStyle"]}
                    />
                  </div>
                  <div className="pull-right">
                    <button
                      onClick={this._saveBtnHandler}
                      className="btn btn-ingenico save-button"
                    >
                      <I18nSpan msgKey={"button.label.ok"} />
                    </button>
                    <button
                      onClick={this.close}
                      className="btn btn-ingenico btn-ingenico-alert exit-button"
                    >
                      <I18nSpan msgKey="button.label.exit" />
                    </button>
                  </div>
                </div>

                <CarouselControl index={saleIndex} right={true} />
              </Loading>
            </Modal.Body>
            <Modal.Footer>
              <Pagination index={saleIndex} count={salesCountPagination} />
            </Modal.Footer>
          </Modal>
        </div>
      );
    }

    return null;
  }
}

const getSaleIndex = ({ sale, sales }) => {
  return _.findIndex(sales, (t: any) => t.saleId === sale.saleId);
};

const dateTimeUtcFields = ["createSaleTime"];

const computeDateTimeUtcFields = ({ sale, user }: any) => {
  const fields = dateTimeUtcFields.reduce((acc: any, fieldname: string) => {
    const { [fieldname]: saleField } = sale;
    if (saleField) {
      acc[fieldname] = DateFormatter.formatDateTimeUtc(saleField, user);
    }
    return acc;
  }, {});
  return {
    ...sale,
    ...fields
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    sales: {
      detail,
      loading: isLoading,
      data: sales,
      count: salesCount,
      showSaleDetail: showModal
    },
    comment: { data: comment }
  } = state;
  const { saleFields: saleFieldsKeys } = ownProps;

  const saleFieldsI18n = _(saleFieldsKeys)
    .map(field => `reporting.${SaleConstants.I18N_PREFIX}.${field}.label`)
    .value();

  const saleFieldsWithI18n = _.zipWith(
    saleFieldsKeys,
    saleFieldsI18n,
    (field, field18n) => ({
      name: field,
      i18nKey: field18n
    })
  );

  const sale = computeDateTimeUtcFields({ sale: detail, user });
  const saleIndex = getSaleIndex({ sale, sales });

  return {
    isLoading,
    sales,
    showModal,
    salesCount,
    saleIndex,
    sale,
    saleFieldsWithI18n,
    user,
    form: {
      comment: (comment || { value: "" }).value
    }
  };
};

const mapDispatchToProps = dispatch => ({
  getSaleDetail: ({ id }) => dispatch(getSaleDetail({ id })),
  setSaleDetail: ({ detail }) => dispatch(setSaleDetail({ detail })),
  findComment: ({ saleId }) => dispatch(getComment({ commentId: saleId })),
  putComment: ({ saleId, comment }) =>
    dispatch(editComment({ commentId: saleId, comment })),
  hideSaleDetail: () => dispatch(hideSaleDetail()),
  resetCommentState: () => dispatch(resetCommentState()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default connect(mapStateToProps, mapDispatchToProps)(SaleViewer);
