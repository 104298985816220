import React, { Component } from "react";
import { connect } from "react-redux";

import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import AdvancedSearch from "../../advancedSearch/components/AdvancedSearch";

interface Props {
  getPoss: Function;
  getPois: Function;
  fields: Array<any>;
  posFields: any;
  searchFilters: any;
}

interface State {
  tableCount: any;
}

class PosAdvancedSearch extends Component<Props, State> {
  render() {
    const {
      searchFilters,
      posFields = [],
      searchContext,
      onChange
    } = this.props;

    return (
      <div>
        <AdvancedSearch
          name={AdvancedSearchConstants.POS_KEY}
          searchFilters={searchFilters}
          onChange={onChange}
          genericFields={posFields}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    genericFields: { data: fields = [] },
    config: {
      data: { tableCount }
    }
  } = state;
  const searchFilters = fields.reduce((prevField: any, field: any) => {
    const { key, type, searchable } = field;

    if (searchable) {
      const tagsKey = /\b(selectedTags).([^.]+)/g.exec(key);
      prevField.push({
        i18nKey: `pos.list.header.${key}`,
        rawLabel: tagsKey ? tagsKey[2] : null,
        name: key,
        type
      });
    }

    return prevField;
  }, []);

  return { searchFilters, posFields: fields, tableCount };
};

export default connect(mapStateToProps, null)(PosAdvancedSearch);
