const INGENICO = "Ingenico";
const LANDI = "Landi";
const VERIFONE = "Verifone";
const OGONE = "Ogone";
const VIRTUAL = "Virtual";
const INGENICO_CODE = "00";
const LANDI_CODE = "04";
const VERIFONE_CODE = "01";
const OGONE_CODE = "02";
const VIRTUAL_CODE = "03";

export default {
  INGENICO,
  LANDI,
  VERIFONE,
  OGONE,
  VIRTUAL,

  INGENICO_CODE,
  LANDI_CODE,
  VERIFONE_CODE,
  OGONE_CODE,
  VIRTUAL_CODE,

  ALL: [
    {
      code: INGENICO_CODE,
      name: INGENICO
    },
    {
      code: LANDI_CODE,
      name: LANDI
    },
    {
      code: VERIFONE_CODE,
      name: VERIFONE
    },
    {
      code: OGONE_CODE,
      name: OGONE
    },
    {
      code: VIRTUAL_CODE,
      name: VIRTUAL
    }
  ]
};
