import React, { Component } from "react";
import { withRouter } from "react-router";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import TagUtil from "../../tag/model/TagUtil";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import Validators from "../../ingenicoForm/validation/Validators";
import I18nSpan from "../../i18n/components/I18nSpan";
import TagInput from "../../ingenicoForm/components/TagInput";
import { requiredMinLength } from "../validations/MerchantValidations";
import CreationMerchantPopin from "../../components/CreationMerchantPopin";
import Permissions from "../../user/constants/Permissions";
import constants from "../../connection/constants/ConnectionConstant";

import {
  createMerchant,
  getTags,
  getPrograms,
  getCountries,
  getMerchantCategoryCodes,
  resetTagsState,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

import SelectedTag from "../../tag/model/SelectedTag";

interface Props {
  history: any;
  saveMerchant: Function;
  getTags: Function;
  getPrograms: Function;
  getCountries: Function;
  getMerchantCategoryCodes: Function;
  tags: any;
  options: any;
  currentUser: any;
  user: any;
}

interface State {
  form: any;
  errors: any;
  showCreatePopin: boolean;
}

export class CreateMerchantView extends Component<Props, State> {
  state = {
    showCreatePopin: false,
    form: {
      name: null,
      programId: null,
      merchantReference: null,
      merchantCategoryCode: null,
      address: null,
      city: null,
      zipCode: null,
      state: null,
      country: null,
      countryOfEstablishment: null,
      commercialContact: null,
      supportContact: null,
      userLogin: null,
      userEmail: null,
      selectedTags: new Map<string, SelectedTag>(),
      applicationSettings: {}
    },
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  _onExit = e => {
    e.preventDefault();

    const { history } = this.props;

    history.push("/main/settings/merchant");
  };

  _goToList = () => {
    const { history } = this.props;

    history.push("/main/settings/merchant");
  };

  _goToCreateMerchantUser(id: string) {
    const { history } = this.props;

    return history.push(`/main/user/new-user/merchant/${id}`);
  }

  _goToCreateMerchantStore() {
    const { history } = this.props;
    const { id: merchantId } = this.state;

    return history.push(
      `/main/settings/store/new-store/merchant/${merchantId}`
    );
  }

  _showCreatePopin = ({ id, name }) => {
    this.setState({
      showCreatePopin: true,
      id,
      name
    });
  };

  _closeDeletePopin = () => {
    this._goToList();
  };

  async componentDidMount() {
    const {
      getTags,
      getPrograms,
      getCountries,
      getMerchantCategoryCodes,
      user
    } = this.props;

    await Promise.all([
      getTags(),
      getPrograms(),
      getCountries(),
      getMerchantCategoryCodes()
    ]);
    return this.setState(state => {
      return {
        ...state,
        form: {
          ...state.form,
          selectedTags: TagUtil.getSelectedTags(user)
        }
      };
    });
  }

  _saveMerchant = e => {
    e.preventDefault();

    const {
      saveMerchant,
      currentUser: { hasMerchantUserManagement, hasStoreManagementPermission },
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const { form } = this.state;

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        saveMerchant({ merchant: form }).then(
          (res: any) => {
            const { id, name } = res;
            addNotificationSuccess("merchant.create.success");

            if (hasMerchantUserManagement || hasStoreManagementPermission)
              return this._showCreatePopin({ id, name });

            return this._goToList();
          },
          async (error: any) => {
            const { key: errorKey } = await error;
            const notificationErrorKey = `notification.merchant.error.${errorKey}`;

            addNotificationError(notificationErrorKey);
          }
        );
      }
    });
  };

  _onChangeHandler = (name: string, value: string) => {
    const newForm = this.state.form;
    newForm[name] = value === "" ? null : value;
    this.setState(newForm, this._checkError(name));
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _checkError(name: string) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  }

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  render() {
    const { showCreatePopin, id, name, errors = {} } = this.state;
    const {
      currentUser: { hasStoreManagementPermission, hasMerchantUserManagement },
      options,
      t
    } = this.props;

    const { form } = this.state;

    const SaveButton = ({ onSave }) => (
      <button onClick={onSave} className="btn btn-ingenico save-button">
        <I18nSpan msgKey={"button.label.ok"} />
      </button>
    );

    return (
      <div>
        <FormTitle
          titleKey="merchant.title"
          actionKey="merchant.list.button.create"
        />
        {showCreatePopin && (
          <CreationMerchantPopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={() => this._goToCreateMerchantUser(id)}
            onClickStoreButton={() => this._goToCreateMerchantStore()}
            objectName={name}
            userLevel={constants.MERCHANT}
            hasStoreManagementPermission={hasStoreManagementPermission}
            hasMerchantUserManagement={hasMerchantUserManagement}
          />
        )}
        <form className="ingenico-form form-horizontal create-merchant-form">
          <BootstrapInput
            validation={requiredMinLength(t(`merchant.form.name.label`))}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            errors={errors.name}
            required={true}
            descriptor={{
              type: "text",
              label: "merchant.form.name.label",
              placeholder: "merchant.form.name.placeholder"
            }}
            formValue={form.name}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("programId")}
            name="programId"
            errors={errors.programId}
            required={false}
            descriptor={{
              type: "singleautocomplete",
              label: "merchant.form.program.label",
              options: options.programs
            }}
            formValue={form.programId}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("merchantReference")}
            name="merchantReference"
            errors={errors.merchantReference}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.merchantReference.label"
            }}
            formValue={form.merchantReference}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("countryOfEstablishment")}
            name="countryOfEstablishment"
            errors={errors.country}
            required={false}
            descriptor={{
              type: "singleautocomplete",
              label: "merchant.form.countryOfEstablishment.label",
              options: options.countriesOfEstablishment
            }}
            formValue={form.countryOfEstablishment}
          />
          <BootstrapInput
            descriptor={{
              type: "singleautocomplete",
              label: "merchant.form.merchantCategoryCode.label",
              options: options.merchantCategoryCodes,
              allowFreeText: true
            }}
            inputRef={this.setInputRef("merchantCategoryCode")}
            name="merchantCategoryCode"
            onChange={this._onChangeHandler}
            validation={value => {
              if (!options.merchantCategoryCodes.find(i => i.value === value))
                return Validators.patternValidator(/^(\d){4}\b/)(value);
            }}
            formValue={form.merchantCategoryCode}
            errors={errors.merchantCategoryCode}
            required={false}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("address")}
            name="address"
            errors={errors.address}
            required={false}
            descriptor={{ type: "text", label: "merchant.form.address.label" }}
            formValue={form.address}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("city")}
            name="city"
            errors={errors.city}
            required={false}
            descriptor={{ type: "text", label: "merchant.form.city.label" }}
            formValue={form.city}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("zipCode")}
            name="zipCode"
            errors={errors.zipCode}
            required={false}
            descriptor={{ type: "text", label: "merchant.form.zipCode.label" }}
            formValue={form.zipCode}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("state")}
            name="state"
            errors={errors.state}
            required={false}
            descriptor={{ type: "text", label: "merchant.form.state.label" }}
            formValue={form.state}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("country")}
            name="country"
            errors={errors.country}
            required={false}
            descriptor={{
              type: "singleautocomplete",
              label: "merchant.form.country.label",
              options: options.countries
            }}
            formValue={form.country}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("commercialContact")}
            name="commercialContact"
            errors={errors.commercialContact}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.commercialContact.label",
              placeholder: "merchant.form.commercialContact.label"
            }}
            formValue={this.state.form.commercialContact}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("supportContact")}
            name="supportContact"
            errors={errors.supportContact}
            required={false}
            descriptor={{
              type: "text",
              label: "merchant.form.supportContact.label",
              placeholder: "merchant.form.supportContact.label"
            }}
            formValue={form.supportContact}
          />

          {!_.isEmpty(options.tags) && (
            <div className="form-group">
              <label className="control-label col-sm-3">
                <span>Tags</span>
              </label>
              <TagInput
                key={"selectedTags.Tag"}
                name="selectedTags"
                tags={options.tags}
                formValue={form.selectedTags}
                onChange={this._onChangeHandler}
              />
            </div>
          )}

          <BootstrapInput
            descriptor={{
              type: "jsonarea",
              label: "store.form.applicationSettings.label"
            }}
            inputRef={this.setInputRef("applicationSettings")}
            name="applicationSettings"
            onChange={this._onChangeHandler}
            formValue={form.applicationSettings}
            errors={errors.applicationSettings}
          />

          <div className="pull-right">
            <SaveButton onSave={this._saveMerchant} />
            <button
              onClick={this._onExit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapCountries = ({ countries }) => {
  return _.map(countries, country => {
    const { display: label, code: value } = country;
    return {
      label,
      value
    };
  });
};

const mapCountriesOfEstablishment = ({ countries }) => {
  return _.map(countries, countryOfEstablishment => {
    const { display: label, code: value } = countryOfEstablishment;
    return {
      label,
      value
    };
  });
};

const mapMerchantCategoryCodes = ({ merchantCategoryCodes }) => {
  return _.map(merchantCategoryCodes, merchantCategoryCode => {
    const { mcc: label, mcc: value } = merchantCategoryCode;
    return {
      label,
      value
    };
  });
};

const mapPrograms = ({ programs }) => {
  return _.map(programs, program => {
    const { name: label, id: value } = program;
    return {
      label,
      value
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    merchant: { data: merchant },
    tags: { data: tags },
    countries: { data: prevCountries },
    programs: { data: prevPrograms },
    merchantCategoryCodes: { data: prevMerchantCategoryCodes }
  } = state;
  const {
    scope: {
      level: { type: levelType }
    }
  } = user;

  const hasMerchantUserManagement = _.includes(
    user.permissions,
    Permissions.MERCHANT_USER_MANAGEMENT
  );
  const hasStoreManagementPermission = _.includes(
    user.permissions,
    Permissions.STORE_MANAGEMENT
  );

  const countries = mapCountries({ countries: prevCountries });
  const programs = mapPrograms({ programs: prevPrograms });
  const countriesOfEstablishment = mapCountriesOfEstablishment({
    countries: prevCountries
  });
  const merchantCategoryCodes = mapMerchantCategoryCodes({
    merchantCategoryCodes: prevMerchantCategoryCodes
  });

  const options = {
    countries,
    countriesOfEstablishment,
    merchantCategoryCodes,
    tags,
    programs
  };

  return {
    levelType,
    merchant,
    options,
    user,
    currentUser: {
      hasMerchantUserManagement,
      hasStoreManagementPermission
    }
  };
};

const mapDispatchToProps = dispatch => ({
  saveMerchant: ({ merchant }) => dispatch(createMerchant({ merchant })),
  getPrograms: () => dispatch(getPrograms()),
  getCountries: () => dispatch(getCountries()),
  getMerchantCategoryCodes: () => dispatch(getMerchantCategoryCodes()),
  getTags: ({ tagType }) => dispatch(getTags({ tagType })),
  resetTags: ({ tagType }) => dispatch(resetTagsState({ tagType })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { levelType } = stateProps;

  switch (levelType) {
    case constants.CUSTOMER: {
      const tagType = constants.CUSTOMER_NEW;
      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    case constants.MERCHANT: {
      const tagType = constants.MERCHANT;
      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    default:
      break;
  }
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(CreateMerchantView);
