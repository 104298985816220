import * as moment from "moment";
import * as _ from "lodash";
import { Service } from "../../offer/model/Service";
import { Filter } from "../../advancedSearch/models/SearchParams";

class Scheduled implements ScheduledI {
  id: string;
  name: string;
  frequency: string;
  triggerTime: string;
  active: boolean;
  reportTypes: Array<Service>;
  recipients: Array<String>;
  sendMeCopy: boolean;
  filters: Array<Filter>;
  timezone: String;

  constructor(
    id,
    name,
    frequency,
    triggerTime,
    active,
    reportTypes,
    recipients,
    sendMeCopy,
    filters,
    timezone
  ) {
    this.id = id;
    this.name = name;
    this.frequency = frequency;
    this.triggerTime = triggerTime;
    this.active = active;
    this.reportTypes = reportTypes;
    this.recipients = recipients;
    this.sendMeCopy = sendMeCopy;
    this.filters = filters;
    this.timezone = timezone;
  }

  static buildFromForm(scheduledForm) {
    const startOfDay = scheduledForm.triggerTime.clone().startOf("day");
    const durationFromStart = moment.duration(
      scheduledForm.triggerTime.diff(startOfDay)
    );

    return new Scheduled(
      scheduledForm.id,
      scheduledForm.name,
      scheduledForm.frequency,
      durationFromStart.toISOString(),
      !!scheduledForm.active,
      _.pluck(scheduledForm.reportTypes, "value"),
      _.pluck(scheduledForm.recipients, "value"),
      scheduledForm.sendMeCopy,
      scheduledForm.filters,
      scheduledForm.timezone
    );
  }

  static wrapFromServer(scheduled) {
    return new Scheduled(
      scheduled.id,
      scheduled.name,
      scheduled.frequency,
      scheduled.triggerTime,
      !!scheduled.active,
      scheduled.reportTypes,
      scheduled.recipients,
      !!scheduled.sendMeCopy,
      scheduled.filters,
      scheduled.timezone
    );
  }
}

export default Scheduled;

export interface ScheduledI {
  id: string;
  name: string;
  frequency: string;
  triggerTime: string;
  active: boolean;
  reportTypes: Array<Service>;
  recipients: Array<String>;
  sendMeCopy: boolean;
  filters: Array<Filter>;
  timezone: String;
}
