import React from "react";
import * as _ from "lodash";
import Tag from "../../tag/model/Tag";
import SelectedTag from "../../tag/model/SelectedTag";

interface Props {
  tags: Array<Tag>;
  onChange: (name, value) => void;
  formValue: Map<string, SelectedTag>;
  name?: string;
}

class TagInput extends React.Component<Props, {}> {
  public static defaultProps: Partial<Props> = {
    formValue: new Map<string, SelectedTag>()
  };

  private _onChange(tagName, e) {
    return this._changeFormValue(tagName, e.target.value);
  }

  private _getSelectedValue(
    currentValue: SelectedTag,
    tagValues: Array<string>
  ): string | null {
    if (currentValue && currentValue.value !== null) {
      return null;
    } else {
      return !_.isEmpty(tagValues) ? _.first(tagValues) : "";
    }
  }

  private _displayTagValues(tagName: string) {
    const formValue = this.props.formValue;
    const currentVal = formValue.get(tagName);
    const currentTag = _.find(
      this.props.tags,
      (tag: Tag) => tag.name === tagName
    );
    const tagValues = currentTag.values;

    return this._changeFormValue(
      tagName,
      this._getSelectedValue(currentVal, tagValues)
    );
  }

  private _changeFormValue(tagName: string, newValue: string) {
    const formValue = this.props.formValue;
    if (newValue || newValue === "") {
      formValue.set(tagName, { name: tagName, value: newValue, fixed: false });
    } else {
      formValue.delete(tagName);
    }
    return this.props.onChange(this.props.name, formValue);
  }

  render() {
    return (
      <div className="tag-input col-sm-6">
        {_.map(this.props.tags, (tag: Tag) => {
          const tagValues = tag.values;
          const tagName = tag.name;
          const formValue = this.props.formValue;
          const isTagShown = !!formValue.get(tagName);

          var component;
          if (isTagShown && tagValues.length > 0) {
            component = (
              <select
                className="form-control-combo"
                name={tagName}
                value={formValue.get(tagName).value}
                onChange={this._onChange.bind(this, tagName)}
                disabled={formValue.get(tagName).fixed}
              >
                {_.map(tagValues, value => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            );
          } else if (isTagShown && tagValues.length === 0) {
            component = (
              <input
                className="form-control-combo"
                name={tagName}
                value={formValue.get(tagName).value}
                onChange={this._onChange.bind(this, tagName)}
                disabled={formValue.get(tagName).fixed}
              />
            );
          } else {
            component = "";
          }

          return (
            <div key={tagName} className="tag-merchant-container col-sm-12">
              <div className="tag-merchant">
                <label className="tag-font checkbox-inline">
                  <input
                    type="checkbox"
                    name={`checkbox.${tagName}`}
                    checked={isTagShown}
                    value={tagName}
                    onChange={this._displayTagValues.bind(this, tagName)}
                    disabled={
                      formValue.get(tagName) && formValue.get(tagName).fixed
                    }
                  />
                  {tagName}
                </label>
              </div>

              <div className="col-sm-6">{component}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default TagInput;
