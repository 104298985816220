import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import TransactionTimeSelector from "./TransactionTimeSelector";
import TransactionAdvancedSearch from "../transactions/components/TransactionAdvancedSearch";
import DataTable from "../../datatable/components/DataTable";
import advancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";

import {
  getFields,
  getTransactionConflict,
  resetTransactions,
  cleanSearchContext,
  setSearchContext
} from "../../redux/actions";

import { setUrlWithParams } from "../../searchContext/URLizer";

interface IState {
  conflictList: any;
}

const advancedSearchKey = advancedSearchConstants.TRANSACTION_KEY;

class ConflictList extends Component<any, IState> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      conflictList: []
    };
    this.setUrlParams({ searchContext });
  }

  conflictColumns = [
    "transactionId",
    "pos.name",
    "store.name",
    "transactionAmount"
  ];

  async componentDidMount() {
    const { getFields, searchContext } = this.props;

    await getFields({ name: "transaction", version: "v2" });
    this._search({ searchContext });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  componentWillUnmount() {
    const { resetTransactions } = this.props;

    resetTransactions();
  }

  _search = async ({ searchContext }) => {
    const { user, getTransactionConflict, setSearchContext } = this.props;

    const { timePeriod, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname: advancedSearchKey });

    if (user) {
      const searchParams = { timePeriod, filters };

      return getTransactionConflict(searchParams, user);
    }
  };

  onRowClick = (rowIdx, row) => {
    const { history } = this.props;
    const { id } = row;

    history.push(`/main/reporting/resolve-conflict/${id}`);
  };

  render() {
    const { conflictList, searchContext } = this.props;

    return (
      <div className="data-table-wrapper">
        <FormTitle
          titleKey="reporting.transaction.title"
          actionKey="reporting.conflict.title"
        />

        <TransactionTimeSelector
          onChange={({ searchContext: nextSearchContext }) =>
            this._search({ searchContext: nextSearchContext })
          }
          searchContext={searchContext}
        />

        <TransactionAdvancedSearch
          onChange={this._search}
          filtersMap={this.conflictColumns}
          searchContext={searchContext}
        />

        <DataTable
          i18nKey="reporting.conflict.list"
          attributes={this.conflictColumns}
          data={conflictList}
          onRowClick={this.onRowClick}
          hideSearchBox={true}
          hasActions={false}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    transactions: { data: conflictList },
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  return {
    user,
    conflictList,
    searchContext
  };
};

const mapDispatchToProps = dispatch => ({
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  getTransactionConflict: (args, user) =>
    dispatch(getTransactionConflict(args, user)),
  resetTransactions: () => dispatch(resetTransactions()),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname
      })
    ),
  cleanSearchContext: () =>
    dispatch(
      cleanSearchContext({
        key: advancedSearchKey,
        context: { key: advancedSearchKey },
        pathname: advancedSearchKey
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ConflictList);
