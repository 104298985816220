import Validators from "../../ingenicoForm/validation/Validators";

export function nameValidation(minLengthTranslation) {
  return value => {
    const requiredPromise = Promise.resolve(
      Validators.requiredValidator(value)
    );

    const minNameSize = 4;
    const sizePromise = Promise.resolve(
      Validators.sizeOfStringValidator(minNameSize, minLengthTranslation)(value)
    );

    const maxNameSize = 255;
    const maxSizePromise = Promise.resolve(
      Validators.maxStringValidator(maxNameSize, minLengthTranslation)(value)
    );

    return Promise.all([requiredPromise, sizePromise, maxSizePromise]);
  };
}

export function contentValidation() {
  return value => {
    const requiredPromise = Promise.resolve(
      Validators.requiredValidator(value)
    );

    return Promise.all([requiredPromise]);
  };
}
