import {
  PENDING_GET_COUNTRIES_ACTION,
  SUCCESS_GET_COUNTRIES_ACTION,
  FAILURE_GET_COUNTRIES_ACTION,
  RESET_COUNTRIES_STATE_ACTION
} from "../actionsTypes";

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_COUNTRIES_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_COUNTRIES_ACTION:
      const {
        payload: { countries: data }
      } = action;

      return {
        ...state,
        data,
        loading: false,
        error: false,
        errors: {}
      };
    case FAILURE_GET_COUNTRIES_ACTION: {
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_COUNTRIES_STATE_ACTION: {
      return initialState;
    }
    default:
      return state;
  }
};
