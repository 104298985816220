import { constantCase } from "constant-case";
import F from "../../commons/HTTPFetcher";

export const getFields = ({ name: fieldType, version }) => dispatch => {
  dispatch(getFieldsStart(fieldType));

  return F.get(`/${fieldType}/fields`, version).then(
    fields => dispatch(getFieldsSucceeded({ fieldType, fields })),
    errors => {
      dispatch(getFieldsFailure({ fieldType, errors }));

      throw errors;
    }
  );
};

const getFieldsStart = fieldType => {
  const name = constantCase(fieldType);

  return {
    name,
    type: `PENDING_GET_FIELDS_ACTION_${name}`
  };
};

const getFieldsSucceeded = ({ fieldType, fields }) => {
  const name = constantCase(fieldType);

  return {
    name,
    type: `SUCCESS_GET_FIELDS_ACTION_${name}`,
    payload: {
      fields
    }
  };
};

const getFieldsFailure = ({ fieldType, errors }) => {
  const name = constantCase(fieldType);

  return {
    name,
    type: `FAILURE_GET_FIELDS_ACTION_${name}`,
    payload: {
      errors
    }
  };
};
