import React, { Component } from "react";
import { withRouter } from "react-router";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import UserMode from "../constants/UserMode";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import OfferUtils from "../../offer/util/OfferUtils";
import TransactionUtils from "../../reporting/transactions/Utils";
import TransactionConstants from "../../reporting/constants/TransactionConstants";
import UserUtil from "../UserUtil";
import TagUtil from "../../tag/model/TagUtil";
import UserValidations from "../validations/UserValidations";
import Validators from "../../ingenicoForm/validation/Validators";
import RestrictedByContainer from "./RestrictedByContainer";
import ConnectionConstant from "../../connection/constants/ConnectionConstant";

import {
  getUser,
  getUserAccessibleScope,
  getUserApplicationSettings,
  getFields,
  getMerchants,
  getStores,
  getTags,
  editUser,
  editUserApplicationSettings,
  resetUserState,
  addNotificationSuccess,
  addNotificationError,
  getPermissionsForm
} from "../../redux/actions";

class EditUserView extends Component<Props, State> {
  state = {
    form: {},
    errors: {},
    levels: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(nextProps) {
    const { form, errors } = nextProps;

    return this.setState({
      form,
      errors
    });
  }

  componentDidMount() {
    const { initUser } = this.props;

    initUser();
  }

  componentWillUnmount() {
    const { resetUserState } = this.props;

    resetUserState();
  }

  _onChangeHandler = (name, value) => {
    return this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value
        }
      };
    }, this._checkError(name));
  };

  _checkError(name) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  }

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _noop() {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  }

  _updateUser = e => {
    e.preventDefault();

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];

        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        const {
          levels: { currentUserLevel },
          isMerchant: isUserMerchant,
          editUser,
          editUserApplicationSettings,
          addNotificationSuccess,
          addNotificationError
        } = this.props;
        const userToSave = UserUtil.buildFromForm(
          this.state.form,
          currentUserLevel
        );

        try {
          const { login, applicationSettings } = this.state.form;
          const updateUserApplicationSettings = (isMerchant: boolean) => {
            if (isMerchant) {
              return editUserApplicationSettings({
                userId: login,
                applicationSettings: _checkApplicationSettings(
                  applicationSettings
                )
              });
            }
          };

          await Promise.all([
            editUser({ user: userToSave }),
            updateUserApplicationSettings(isUserMerchant)
          ]);

          return await Promise.all([
            addNotificationSuccess("user.update.success"),
            this._goToList()
          ]);
        } catch (error) {
          addNotificationError("notification.error");
        }
      }
    });
  };

  _goToList = () => {
    const {
      history,
      isIngenico,
      levels: { authenticatedUserLevel, currentUserLevel }
    } = this.props;
    const listPath =
      isIngenico || authenticatedUserLevel === currentUserLevel
        ? ""
        : `/${ConnectionConstant.MERCHANT}`;

    return history.push(
      `/main/${isIngenico ? "" : "settings/"}user${listPath}`
    );
  };

  _emailStyle = value => {
    return Promise.all([
      UserValidations.required(value),
      Validators.emailValidator(value)
    ]);
  };

  render() {
    const {
      options,
      readOnly,
      isMerchant,
      fullMode,
      hasSingleSession,
      levels: { authenticatedUserLevel }
    } = this.props;
    const { form, errors } = this.state;

    return (
      <div>
        <FormTitle titleKey="user.title" actionKey="user.update.action" />

        <form className="ingenico-form form-horizontal users-form">
          <BootstrapInput
            descriptor={{
              id: "loginUser",
              type: "text",
              label: "user.form.login.label",
              placeholder: "user.form.login.placeholder",
              readOnly: true
            }}
            inputRef={this.setInputRef("login")}
            name="login"
            onChange={this._onChangeHandler}
            required={true}
            formValue={form.login}
            errors={errors.login}
          />
          <BootstrapInput
            descriptor={{
              id: "emailUser",
              type: "email",
              label: "user.form.email.label"
            }}
            inputRef={this.setInputRef("email")}
            name="email"
            onChange={this._onChangeHandler}
            required={true}
            formValue={form.email}
            validation={this._emailStyle}
            errors={errors.email}
          />
          <BootstrapInput
            descriptor={{
              id: "permissionsUser",
              className: "permissions mtn",
              type: "multipleselect",
              label: "user.form.permissions.label",
              options: options.permissions
            }}
            inputRef={this.setInputRef("permissions")}
            name="permissions"
            onChange={this._onChangeHandler}
            required={false}
            formValue={form.permissions}
            errors={errors.permissions}
          />
          {hasSingleSession && (
            <BootstrapInput
              descriptor={{
                id: "singleSession",
                className: "mtn custom-control-input",
                type: "checkbox",
                label: "user.form.singleSession"
              }}
              inputRef={this.setInputRef("singleSession")}
              name="singleSession"
              onChange={this._onChangeHandler}
              formValue={form.singleSession}
              errors={errors.singleSession}
            />
          )}

          <RestrictedByContainer
            fullMode={fullMode}
            form={form}
            options={options}
            readOnly={readOnly}
            errors={errors}
            onChangeHandler={this._onChangeHandler}
            levelType={authenticatedUserLevel}
          />

          {isMerchant && (
            <BootstrapInput
              descriptor={{
                type: "jsonarea",
                label: "user.form.applicationSettings.label"
              }}
              inputRef={this.setInputRef("applicationSettings")}
              name="applicationSettings"
              onChange={this._onChangeHandler}
              formValue={form.applicationSettings}
              errors={errors.applicationSettings}
            />
          )}
          <div className="pull-right">
            <button
              onClick={this._updateUser}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const buildPermissionsOptions = state => {
  const {
    permissionsForm: { data: permissions }
  } = state;
  const permissionsOptions = permissions.map(UserUtil.toMultipleSelectFormat);

  return permissionsOptions;
};

const buildSimpleForm = ({ state }) => {
  const permissions = buildPermissionsOptions(state);

  return {
    form: {
      restrictedByServices: false,
      restrictedByTags: new Map(),
      tag_or_scope: "full"
    },
    options: {
      permissions,
      restrictedByIds: []
    },
    readOnly: {
      restrictedByServices: false,
      restrictedByTags: false
    }
  };
};

const _buildFieldOptions = ({ state, getMessage }) => {
  const {
    auth: { isCustomer },
    genericFields: { data: transactionFieldGroups = [] }
  } = state;

  if (isCustomer) {
    const transactionFieldsKeys = TransactionUtils.extractFieldKeys({
      transactionFieldGroups,
      type: "visibleAndExtras"
    });

    return TransactionUtils.i18nFields(transactionFieldsKeys, field =>
      getMessage(`${TransactionConstants.I18N_PREFIX}.${field}.label`, field)
    );
  } else {
    return [];
  }
};

const filterAccessibleScopes = ({ accessibleScope, allScope }) => {
  const access = accessibleScope || { full: true };
  const filteredAccessibleScopes = _.chain(allScope)
    .filter(item => access.full || _.includes(access.restrictedByIds, item.id))
    .map((item: any) => ({ id: item.id, name: item.name }))
    .value();

  return filteredAccessibleScopes;
};

const buildCompleteForm = ({ state, getMessage }) => {
  const {
    auth: { user: authenticatedUser, isCustomer, isMerchant },
    user: { data: currentUser, accessibleScope },
    merchants: { data: merchants },
    stores: { data: stores },
    tags: { data: tags },
    services: { data: services = [] }
  } = state;

  let allScope = [];
  let restrictedByTagsOptions = [];

  if (isCustomer) {
    allScope = merchants;
    allScope.map(item => {
      item = Object.assign(item, {
        id: item.merchant.id,
        name: item.merchant.name
      });
    });
    restrictedByTagsOptions = tags;
  } else if (isMerchant) {
    allScope = stores;
    allScope.map(item => {
      item = Object.assign(item, {
        id: item.store.id,
        name: item.store.name
      });
    });
    restrictedByTagsOptions = tags;
  }

  const accessibleScopes = filterAccessibleScopes({
    accessibleScope,
    allScope
  });
  const fieldOptions = _buildFieldOptions({ state, getMessage });

  //Init Permissions
  const permissionsOptions = buildPermissionsOptions(state);

  // Init Services
  const serviceSetOptions = OfferUtils.servicesToFormOptions(services);

  const formModel: any = Object.assign(
    UserUtil.buildToForm(currentUser, accessibleScopes)
  );

  let restrictedByServicesReadOnly = false;
  if (authenticatedUser && authenticatedUser.services) {
    restrictedByServicesReadOnly = true;
    formModel.restrictedByServices = true;
  }

  formModel.restrictedByTags = TagUtil.getSelectedTags(authenticatedUser);

  const restrictedByIdsReadOnly =
    formModel.restrictedByIds && formModel.restrictedByIds.length > 0;
  const restrictedByTagsReadOnly = formModel.restrictedByTags.size > 0;
  const restrictedByTransactionFieldReadOnly =
    "restrictedByTransactionField" in formModel;

  return {
    form: formModel,
    options: {
      permissions: permissionsOptions,
      serviceSet: serviceSetOptions,
      restrictedByIds: accessibleScopes,
      tags: restrictedByTagsOptions,
      fields: fieldOptions
    },
    readOnly: {
      authenticatedUserScopeAccessFull: authenticatedUser.scope.access.full,
      restrictedByServices: restrictedByServicesReadOnly,
      restrictedByIds: restrictedByIdsReadOnly,
      restrictedByTags: restrictedByTagsReadOnly,
      restrictedByTransactionField: restrictedByTransactionFieldReadOnly
    }
  };
};

const _checkApplicationSettings = applicationSettings => {
  return applicationSettings && Object.keys(applicationSettings).length === 0
    ? { mPos: {} }
    : applicationSettings;
};

export const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: {
        scope: {
          level: { type: authenticatedUserLevel }
        }
      },
      isIngenico,
      isCustomer,
      isMerchant
    },
    user: { data: currentUser, applicationSettings },
    users: { userMode }
  } = state;
  const {
    t: getMessage,
    match: {
      params: { userId }
    }
  } = ownProps;
  const currentUserLevelType = currentUser.scope.level.type;
  const fullMode =
    !isIngenico && authenticatedUserLevel === currentUserLevelType;

  const descriptor =
    isIngenico || userMode === UserMode.MY_HIERARCHY
      ? buildSimpleForm({ state })
      : buildCompleteForm({ state, getMessage });
  const formModel = UserUtil.buildToForm(
    currentUser,
    descriptor.options.restrictedByIds
  );

  if (isMerchant) {
    Object.assign(formModel, {
      applicationSettings: _checkApplicationSettings(applicationSettings)
    });
  }

  formModel.restrictedByServices =
    formModel.restrictedByServices || descriptor.form.restrictedByServices;
  if (descriptor.form.tag_or_scope !== "full") {
    formModel.tag_or_scope = descriptor.form.tag_or_scope;
    for (let k of descriptor.form.restrictedByTags.keys()) {
      if (!formModel.restrictedByTags) {
        formModel.restrictedByTags = new Map();
      }
      formModel.restrictedByTags.set(
        k,
        descriptor.form.restrictedByTags.get(k)
      );
    }
  }

  const hasSingleSession = formModel.hasOwnProperty("singleSession");

  return {
    form: formModel,
    options: descriptor.options,
    readOnly: descriptor.readOnly,
    errors: {},
    levels: {
      authenticatedUserLevel,
      currentUserLevel: currentUser.scope.level.type
    },
    isIngenico,
    isCustomer,
    isMerchant,
    userId,
    fullMode,
    hasSingleSession
  };
};

const mapDispatchToProps = dispatch => ({
  getUser: userId => dispatch(getUser(userId)),
  getUserAccessibleScope: () => dispatch(getUserAccessibleScope()),
  getUserApplicationSettings: userId =>
    dispatch(getUserApplicationSettings(userId)),
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  getMerchants: ({ filters, fields }) =>
    dispatch(getMerchants({ filters, fields })),
  getStores: ({ filters, fields }) => dispatch(getStores({ filters, fields })),
  getTags: ({ tagType }) => dispatch(getTags({ tagType })),
  editUser: ({ user }) => dispatch(editUser({ user })),
  editUserApplicationSettings: ({ userId, applicationSettings }) =>
    dispatch(editUserApplicationSettings({ userId, applicationSettings })),
  resetUserState: () => dispatch(resetUserState()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getPermissionsForm: ({ restrictionLevel }) =>
    dispatch(getPermissionsForm({ restrictionLevel }))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomer, isMerchant, userId } = stateProps;
  const initUser = () => {
    const filters = [];

    if (isCustomer) {
      const tagType = ConnectionConstant.CUSTOMER_NEW;
      Promise.all([
        dispatchProps.getMerchants({
          filters,
          fields: ["merchant.id", "merchant.name"],
          sort: {
            field: "merchant.name.raw",
            order: "ASC"
          }
        }),
        dispatchProps.getTags({ tagType }),
        dispatchProps.getFields({ name: "transaction", version: "v2" })
      ]);
    } else if (isMerchant) {
      const tagType = ConnectionConstant.MERCHANT;
      Promise.all([
        dispatchProps.getStores({
          filters,
          fields: ["store.id", "store.name"],
          sort: {
            field: "store.name.raw",
            order: "ASC"
          }
        }),
        dispatchProps.getTags({ tagType })
      ]);
    }

    dispatchProps.getUser(userId).then(user => {
      return Promise.all([
        dispatchProps.getPermissionsForm({
          restrictionLevel: user.scope.level.type
        }),
        dispatchProps.getUserAccessibleScope(),
        isMerchant && dispatchProps.getUserApplicationSettings(user.login)
      ]);
    });
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    initUser
  };
};

export const EditUserViewWithRouter = withRouter(EditUserView);

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(EditUserViewWithRouter);
