import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import Validators from "../../ingenicoForm/validation/Validators";
import I18nSpan from "../../i18n/components/I18nSpan";
import TagInput from "../../ingenicoForm/components/TagInput";
import TagUtil from "../../tag/model/TagUtil";
import NumberFormatter from "../../formatters/NumberFormatter";
import {
  requiredMinLength,
  requiredFields,
  priceValidation
} from "../validations/CatalogItemValidations";
import {
  editCatalogItem,
  getCatalogItem,
  getTags,
  getVatRates,
  resetTagsState,
  addNotificationSuccess,
  addNotificationError,
  getMeasures
} from "../../redux/actions";

import constants from "../../connection/constants/ConnectionConstant";

interface Props {
  history: any;
  editCatalogItem: Function;
  getCatalogItem: Function;
  getTags: Function;
  getVatRates: Function;
  match: any;
  tags: any;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  currencyDecimal: any;
  getMeasures: Function;
}

interface State {
  options: any;
  errors: any;
  catalogItem: any;
}

class EditCatalogItemView extends Component<Props, State> {
  state = {
    catalogItem: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  async componentDidMount() {
    const {
      getCatalogItem,
      getTags,
      getVatRates,
      merchantId,
      getMeasures,
      match: {
        params: { catalogItemId }
      }
    } = this.props;

    await Promise.all([
      getCatalogItem(catalogItemId),
      getTags(),
      getVatRates(merchantId),
      getMeasures()
    ]);
  }

  componentWillReceiveProps(newProps) {
    const { catalogItem, errors } = newProps;

    return this.setState({
      catalogItem,
      errors
    });
  }

  _updateCatalogItem = e => {
    e.preventDefault();

    const {
      editCatalogItem,
      addNotificationSuccess,
      addNotificationError,
      currencyDecimal
    } = this.props;
    const { catalogItem } = this.state;

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.catalogItem, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        const price = catalogItem.price;
        const unformatedPrice = NumberFormatter.unformatAmount(
          price.toString(),
          currencyDecimal
        );

        const data = { ...catalogItem, price: unformatedPrice };
        const { url } = data;
        if (url !== undefined && url === "") delete data.url;

        editCatalogItem(data).then(
          () => {
            addNotificationSuccess("catalogItem.edit.success");
            return this._goToList();
          },
          async (error: any) => {
            const { errorKey } = await error;
            const notificationErrorKey = `notification.catalogItem.error.${errorKey}`;

            addNotificationError(notificationErrorKey);
          }
        );
      }
    });
  };

  _onChangeHandler = (name: string, value: string) => {
    const { catalogItem } = this.state;
    const updatedCatalogItem = catalogItem;
    updatedCatalogItem[name] = value;
    this.setState(updatedCatalogItem, this._checkError(name));
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    const { catalogItem: form } = this.state;
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name = _.first(names);
      const value = _.get(form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [_.noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _checkError(name: string) {
    const { errors } = this.state;
    return then => {
      this._waitForValidation([name], errors, then);
    };
  }

  _checkErrors(then) {
    const { catalogItem } = this.state;
    this._waitForValidation(_.keys(catalogItem), {}, then);
  }

  _onExit = e => {
    e.preventDefault();
    this._goToList();
  };

  _goToList = () => {
    this.props.history.push("/main/settings/catalog");
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  _checkUrl = value => {
    return Validators.patternValidator(
      "(https|http)://((\\w)*|([0-9]*)|([-|_])*)+([\\.|/]((\\w)*|([0-9]*)|([-|_])*))+",
      "url"
    )(value);
  };

  render() {
    const { options, t } = this.props;
    const { errors, catalogItem } = this.state;

    const SaveButton = ({ onSave }) => (
      <button onClick={onSave} className="btn btn-ingenico save-button">
        <I18nSpan msgKey={"button.label.ok"} />
      </button>
    );

    return (
      <div>
        <FormTitle
          titleKey="catalogItem.title"
          actionKey="catalogItem.edit.action"
        />
        <form className="ingenico-form form-horizontal edit-catalog-form">
          <BootstrapInput
            validation={requiredMinLength(t(`catalogItem.form.name.label`))}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            errors={errors.name}
            required={true}
            descriptor={{
              type: "text",
              label: "catalogItem.form.name.label",
              placeholder: "catalogItem.form.name.placeholder"
            }}
            formValue={catalogItem.name}
          />
          <BootstrapInput
            validation={() => undefined}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("description")}
            name="description"
            errors={errors.description}
            required={false}
            descriptor={{
              type: "text",
              label: "catalogItem.form.description.label"
            }}
            formValue={catalogItem.description}
          />
          <BootstrapInput
            validation={this._checkUrl}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("url")}
            name="url"
            errors={errors.url}
            required={false}
            descriptor={{
              type: "url",
              label: "catalogItem.form.url.label",
              placeholder: "catalogItem.form.url.placeholder"
            }}
            formValue={catalogItem.url}
          />
          <BootstrapInput
            validation={requiredFields()}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("vatRate")}
            name="vatRate"
            errors={errors.vatRate}
            required={true}
            descriptor={{
              type: "singleautocomplete",
              label: "catalogItem.form.vatRate.label",
              options: options.vatRatesList
            }}
            formValue={catalogItem.vatRate}
          />
          <BootstrapInput
            validation={requiredFields()}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("measure")}
            name="measure"
            errors={errors.measure}
            required={true}
            descriptor={{
              type: "singleautocomplete",
              label: "catalogItem.form.measure.label",
              options: options.measuresList
            }}
            formValue={catalogItem.measure}
          />
          <BootstrapInput
            validation={priceValidation()}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("price")}
            name="price"
            errors={errors.price}
            required={true}
            descriptor={{
              type: "text",
              label: "catalogItem.form.price.label"
            }}
            formValue={catalogItem.price}
          />
          <BootstrapInput
            validation={() => undefined}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("gtin")}
            name="gtin"
            errors={errors.gtin}
            required={false}
            descriptor={{
              type: "text",
              label: "catalogItem.form.gtin.label"
            }}
            formValue={catalogItem.gtin}
          />
          {!_.isEmpty(options.tags) && (
            <div className="form-group">
              <label className="control-label col-sm-3">
                <span>Tags</span>
              </label>
              <TagInput
                key={"selectedTags.Tag"}
                name="selectedTags"
                tags={options.tags}
                formValue={catalogItem.selectedTags}
                onChange={this._onChangeHandler}
              />
            </div>
          )}
          <div className="pull-right">
            <SaveButton onSave={this._updateCatalogItem} />
            <button
              onClick={this._onExit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user },
    catalogItem: { data: catalogItemList },
    tags: { data: tags },
    vatRates: { data: vatRates },
    auth: {
      levelSettings: { currency: { exponent: currencyDecimal } } = {
        currency: {
          exponent: 0
        }
      }
    },
    catalogItem: { measures = [] }
  } = state;

  const {
    scope: {
      level: { type: levelType, id: merchantId }
    }
  } = user;

  const vatRatesKeys = Object.keys(vatRates);
  const vatRatesList = _.map(vatRatesKeys, key => ({
    label: key,
    value: key
  }));

  const measuresList = _.map(measures, value => ({
    label: value,
    value: value
  }));

  const computeTags = ({ data }) => {
    const userTags = TagUtil.getSelectedTags(user);
    _(data.selectedTags)
      .keys()
      .forEach(key => {
        if (!userTags.has(key)) {
          userTags.set(key, {
            name: key,
            value: data.selectedTags[key],
            fixed: false
          });
        }
      })
      .value();

    return {
      ...data,
      selectedTags: userTags
    };
  };

  const catalogItem = computeTags({ data: catalogItemList });

  const formatedPrice = NumberFormatter.formatAmount(
    catalogItem.price,
    null,
    currencyDecimal
  );
  Object.assign(catalogItem, { price: formatedPrice });

  return {
    measures,
    levelType,
    merchantId,
    catalogItem,
    options: {
      tags: tags,
      measuresList,
      vatRatesList
    },
    errors: {},
    currencyDecimal
  };
};

const mapDispatchToProps = dispatch => ({
  editCatalogItem: (catalogItem: any) => dispatch(editCatalogItem(catalogItem)),
  getCatalogItem: async (id: string) => await dispatch(getCatalogItem(id)),
  getVatRates: (merchantId: any) => dispatch(getVatRates(merchantId)),
  getTags: ({ tagType }) => dispatch(getTags({ tagType })),
  resetTags: ({ tagType }) => dispatch(resetTagsState({ tagType })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getMeasures: () => dispatch(getMeasures())
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { levelType } = stateProps;

  switch (levelType) {
    case constants.MERCHANT: {
      const tagType = constants.MERCHANT;
      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    default:
      break;
  }
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(EditCatalogItemView);
