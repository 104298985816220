import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { CirclePicker } from "react-color";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import Widget from "../models/Widget";
import WidgetRenderer from "./Widget";
import I18nSpan from "../../i18n/components/I18nSpan";
import TimeSelector from "../../components/TimeSelector";
import TimePeriods, {
  PeriodSelection,
  TimePeriod
} from "../../reporting/models/TimePeriod";
import TransactionAdvancedSearch from "../../reporting/transactions/components/TransactionAdvancedSearch";
import TransactionConstants from "../../reporting/constants/TransactionConstants";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import { WidgetStyle } from "../models/WidgetStyle";
import { WidgetGroup, WidgetGroupKey } from "../models/WidgetGroup";
import Utils from "../../../jsx/sim/Utils";

import {
  getAvailableWidgets,
  getWidgets,
  getFields,
  createWidget,
  resetAvailableWidgetsState,
  resetWidgetStatsState,
  resetWidgetsStatsState,
  addNotificationError,
  setSearchContext,
  cleanSearchContext,
  getPalettes
} from "../../redux/actions";
import DateFormatter from "../../formatters/DateFormatter";
import { getData, getDataWithTrend, getTrend } from "../utils/DataProvider";

import styles from "./styles/AddWidgetStyle.css";
import Validators from "../../ingenicoForm/validation/Validators";

const OPTION_KEY_SEPARATOR = "-";

const advancedSearchKey = AdvancedSearchConstants.WIDGET_KEY;

// @vulnerabilities XSS CSRF

interface Props {
  history: any;
  getData: Function;
  addNotificationError: Function;
  resetAvailableWidgetsState: Function;
  resetWidgetsStatsState: Function;
  createWidget: Function;
  getAvailableWidgets: Function;
  getWidgets: Function;
  criteriaOptions: Array<any>;
  userCurrency: any;
  color: string;
  widgets: Array<WidgetGroup>;
  user: any;
  form: any;
  loading: boolean;
  cleanSearchContext: Function;
  getFields: Function;
}

interface Form {
  name: string;
  filters: [];
  criteria?: string;
  timePeriod: null;
}

interface State {
  widgetSelected: boolean;
  form: Form;
  loadingWidget: boolean;
}

interface TimeSelectorChangeProps {
  timePeriod: TimePeriod;
}

const RenderOption = ({ widget, index, groupKey, option }: any) => {
  return (
    <option
      key={widget.type}
      value={`${groupKey}${OPTION_KEY_SEPARATOR}${index}`}
    >
      {option}
    </option>
  );
};

const WidgetPreview = ({ selected, color, user, widget }: any) => {
  const { criteria } = widget;

  if (widget.hasOwnProperty("criteria") && criteria === null) {
    return null;
  }
  if (!selected) {
    return null;
  }
  return (
    <div className={classNames(styles.wrapper)}>
      <WidgetRenderer
        draggable={true}
        color={color}
        user={user}
        config={{ ...widget, id: "preview", desktopView: true }}
      />
    </div>
  );
};

export class AddWidgetView extends Component<Props, State> {
  state = {
    form: {
      name: "",
      filters: [],
      timePeriod: null,
      timestamps: {}
    },
    colors: [],
    errors: {},
    loadingWidget: false,
    widgetSelected: false
  } as State;

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillUnmount() {
    const { resetAvailableWidgetsState, resetWidgetsStatsState } = this.props;
    resetAvailableWidgetsState();
    resetWidgetsStatsState();
  }

  async componentDidMount() {
    const {
      getAvailableWidgets,
      getWidgets,
      getFields,
      cleanSearchContext,
      getPalettes
    } = this.props;
    cleanSearchContext();

    await Promise.all([
      getPalettes(),
      getAvailableWidgets(),
      getWidgets(),
      getFields({ name: "transaction", version: "v2" })
    ]);
  }

  onWidgetSelect = event => {
    const {
      widgets,
      user,
      userCurrency,
      searchContext,
      defaultPaletteId,
      colors,
      resetWidgetStatsState
    } = this.props;
    const optionValue = event.target.value.split(OPTION_KEY_SEPARATOR);
    const groupKey = optionValue[0];
    const selectedGroup = getGroupByKey(widgets, groupKey);
    const widgetIndex = optionValue[1];
    const selectedWidget = selectedGroup
      ? selectedGroup.widgets[widgetIndex]
      : null;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {}
    } = searchContext;

    selectedWidget.filters = filters;

    const { period, timePeriod: selectedTimePeriod } = selectedWidget;

    const timePeriod = TimePeriod.fromPeriodSelection(period);

    const min = selectedTimePeriod
      ? DateFormatter.getTimezonedDate(timePeriod.startTime, user)
      : 0;
    const max = selectedTimePeriod
      ? DateFormatter.getTimezonedDate(timePeriod.endTime, user)
      : 0;

    const nextFields = {
      ...selectedWidget,
      defaultPaletteId,
      userCurrency,
      timePeriod,
      timestamps: {
        min,
        max
      }
    };

    this.setState({ form: nextFields, colors, widgetSelected: true }, () => {
      const { criteria } = this.state.form;

      resetWidgetStatsState();
      if (criteria === null) {
        this.onCriteriaChange("criteria", null);
      }
    });
  };

  _waitForValidation = (
    names: Array<string>,
    newErrors: Array<String>,
    then
  ) => {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        _.set(
          newErrors,
          name,
          _.chain(errors)
            .flatten()
            .compact()
            .value()
        );
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  };

  _checkError = name => {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  };

  _checkErrors = then => {
    this._waitForValidation(_.keys(this.props.form), {}, then);
  };

  onWidgetNameChange = (name: string, value: string) => {
    this.setState(state => {
      return {
        ...state,
        form: {
          ...state.form,
          [name]: value
        }
      };
    });
  };

  onWidgetPaletteChange = (name: string, value: string) => {
    const { palettes } = this.props;
    const palette = Utils.findPaletteById(value, palettes);
    this.setState(state => {
      return {
        ...state,
        form: {
          ...state.form,
          [name]: value
        },
        ...(palette ? { colors: [...palette.colors] } : { colors: [] })
      };
    });
  };

  onTimeSelectorChange = async ({ timePeriod }) => {
    const { user, setSearchContext } = this.props;

    const min = DateFormatter.getTimezonedDate(timePeriod.startTime, user);
    const max = DateFormatter.getTimezonedDate(timePeriod.endTime, user);

    const timestamps = {
      min,
      max
    };

    setSearchContext({ context: { timePeriod }, pathname: advancedSearchKey });

    this.setState(state => {
      return {
        form: {
          ...state.form,
          timePeriod,
          timestamps
        }
      };
    }, this.updateWidgetPreview);
  };

  onFiltersChange = ({ filters, sort, searchContext }) => {
    const { setSearchContext } = this.props;

    setSearchContext({ context: searchContext, pathname: advancedSearchKey });

    this.setState(state => {
      return {
        form: {
          ...state.form,
          filters
        }
      };
    }, this.updateWidgetPreview);
  };

  onCriteriaChange = (name: string, criteria: string) => {
    this.setState(state => {
      return {
        form: {
          ...state.form,
          criteria
        }
      };
    }, this._checkError(name));
  };

  updateWidgetPreview() {
    const { form: config } = this.state;
    if (config.criteria === null) {
      return null;
    }
    return this.refresh({ config, enableTrend: false });
  }

  maxOrderGenerator() {
    const { userWidgets } = this.props;
    if (userWidgets.length > 0) {
      return (
        _.last(userWidgets.sort((prev, next) => prev.order - next.order))
          .order + 1
      );
    }
    return -1;
  }

  refresh({ config, enableTrend }) {
    const { getData, getTrend } = this.props;
    return getDataWithTrend({
      config: { ...config, id: "preview" },
      getData,
      getTrend,
      enableTrend
    });
  }

  onWidgetSave = async event => {
    event.preventDefault();

    const { createWidget, addNotificationError, t } = this.props;
    const { form, errors } = this.state;

    const order = this.maxOrderGenerator();

    const { criteria } = this.state.form;

    if (criteria === null) {
      return this.onCriteriaChange("criteria", null);
    }

    this._checkErrors(async () => {
      const hasErrors = _.any(form, (value, name) => {
        const error = errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        createWidget({ widget: { ...form, order } }).then(
          () => {
            this._goToHome();
          },
          async error => {
            const { key: errorKey } = await error;
            const notificationErrorKey = `notification.widget.error.${errorKey}`;

            addNotificationError(notificationErrorKey);
          }
        );
      }
    });
  };

  _goToHome = () => {
    const { history } = this.props;

    return history.push("/main");
  };

  _required = value => {
    const requiredPromise = new Promise(resolve => {
      resolve(Validators.requiredValidator(value));
    });
    return Promise.all([requiredPromise]);
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  render() {
    const {
      color,
      criteriaOptions,
      user,
      widgets,
      loading,
      searchContext,
      t,
      palettesOptions
    } = this.props;

    const { form, widgetSelected, errors, colors } = this.state;

    return (
      <div>
        <FormTitle
          color={color}
          titleKey="widget.title"
          actionKey="widget.add.action"
        />

        <form
          className="ingenico-form form-horizontal scheduled-form"
          onSubmit={this.onWidgetSave}
        >
          <div className="form-group">
            <label className="control-label col-sm-3">
              <I18nSpan msgKey="widget.add.type" />
            </label>
            <div className="col-sm-6">
              {loading && <span>Loading ...</span>}
              <select
                disabled={loading}
                onChange={this.onWidgetSelect}
                className="form-control widget-select"
              >
                <option />
                {widgets.map((widgetGroup: WidgetGroup) => {
                  return (
                    <optgroup key={widgetGroup.key} label={t(widgetGroup.key)}>
                      {widgetGroup.widgets.map((widget, index) => {
                        const option = t(`widget.type.${widget.type}`);
                        return (
                          <RenderOption
                            key={index}
                            widget={widget}
                            index={index}
                            groupKey={widgetGroup.key}
                            option={option}
                          />
                        );
                      })}
                    </optgroup>
                  );
                })}
              </select>
            </div>
          </div>

          {widgetSelected && (
            <div>
              <BootstrapInput
                onChange={this.onWidgetNameChange}
                inputRef={this.setInputRef("name")}
                name="name"
                required={false}
                descriptor={{ type: "text", label: "widget.add.name" }}
                formValue={form.name}
              />

              {[
                WidgetStyle.All,
                WidgetStyle.AllWithCriteria,
                WidgetStyle.OnlyTimePeriod
              ].includes(form.style) && (
                <div id="widget-period" className="form-group">
                  <label className="control-label col-sm-3">
                    <I18nSpan msgKey="widget.add.period" />
                  </label>
                  <div className="col-sm-6">
                    <TimeSelector
                      searchContext={searchContext}
                      onChange={this.onTimeSelectorChange}
                      timePeriods={_.pick(TimePeriods, [
                        PeriodSelection.INTRADAY,
                        PeriodSelection.PREVIOUS_DAY,
                        PeriodSelection.WEEK_TO_DATE,
                        PeriodSelection.MONTH_TO_DATE,
                        PeriodSelection.YEAR_TO_DATE,
                        PeriodSelection.LAST_WEEK,
                        PeriodSelection.LAST_MONTH,
                        PeriodSelection.LAST_YEAR
                      ])}
                      initialTimePeriod={form.timePeriod}
                    />
                  </div>
                </div>
              )}

              {[
                WidgetStyle.All,
                WidgetStyle.AllWithCriteria,
                WidgetStyle.OnlyFilters
              ].includes(form.style) && (
                <div id="widget-filters" className="form-group">
                  <label className="control-label col-sm-3">
                    <I18nSpan msgKey="widget.add.filters" />
                  </label>
                  <div className="col-sm-6">
                    <TransactionAdvancedSearch
                      className="mtn"
                      name={AdvancedSearchConstants.WIDGET_KEY}
                      onChange={this.onFiltersChange}
                      searchContext={searchContext}
                    />
                  </div>
                </div>
              )}

              {form.style === WidgetStyle.AllWithCriteria && (
                <div id="widget-criteria" className="form-group">
                  <label className="control-label col-sm-3">
                    <I18nSpan msgKey="reporting.comparison.criteria" />
                  </label>
                  <div className="col-sm-6">
                    <BootstrapInput
                      validation={this._required}
                      required={true}
                      inputRef={this.setInputRef("criteria")}
                      name="criteria"
                      onChange={this.onCriteriaChange}
                      errors={errors.criteria}
                      descriptor={{
                        type: "singleautocomplete",
                        options: criteriaOptions
                      }}
                      formValue={form.criteria}
                    />
                  </div>
                </div>
              )}

              <BootstrapInput
                validation={this._noop}
                onChange={this.onWidgetPaletteChange}
                inputRef={this.setInputRef("defaultPaletteId")}
                name="defaultPaletteId"
                placeholder={t(`user.form.palette.label`)}
                descriptor={{
                  type: "select",
                  label: "user.form.palette.label",
                  options: palettesOptions
                }}
                formValue={form.defaultPaletteId}
                errors={errors.defaultPaletteId}
              />
              <div className="form-group">
                {colors && !_.isEmpty(colors) && (
                  <div>
                    <label className="control-label col-sm-3">
                      <I18nSpan msgKey="user.form.colorsPreview.label" />
                    </label>
                    <div className="col-sm-6 preview-palette">
                      <CirclePicker colors={colors} />
                    </div>
                  </div>
                )}
              </div>

              <div className={styles["widget-preview"]}>
                <WidgetPreview
                  selected={widgetSelected}
                  color={color}
                  user={user}
                  widget={form}
                />
              </div>
              <div className={styles["buttons-bar"]}>
                <SaveButton />
                <Link
                  to={"/main"}
                  className={classNames(
                    "btn",
                    "btn-ingenico",
                    styles.buttons,
                    "btn-ingenico-alert",
                    "exit-button"
                  )}
                >
                  <I18nSpan msgKey="button.label.exit" />
                </Link>
              </div>
            </div>
          )}
        </form>
        <NoCriteria hide={widgetSelected} />
        <style>
          {`

          .btn-ingenico {
              color: ${color};
              border-color: ${color};
          }

          `}
        </style>
      </div>
    );
  }
}

const SaveButton = () => {
  return (
    <button
      className={classNames(
        "btn",
        "btn-ingenico",
        styles.buttons,
        styles["save-button"]
      )}
      type="submit"
    >
      <I18nSpan msgKey="button.label.add" />
    </button>
  );
};

const NoCriteria = ({ hide }: any) => {
  if (hide) return null;
  return (
    <div className="row text-center no-criteria">
      <I18nSpan msgKey="widget.add.select" />
    </div>
  );
};

const getGroupByKey = (
  widgetGroups: Array<WidgetGroup>,
  groupKey: string
): WidgetGroup => {
  return widgetGroups.filter(group => group.key === groupKey)[0];
};

const groupWidgetsByType = (widgets: Array<Widget>) => {
  const widgetGroups: Array<WidgetGroup> = [
    {
      key: WidgetGroupKey.ACTIVITY,
      widgets: []
    },
    {
      key: WidgetGroupKey.TERMINALS_PAYMENT,
      widgets: []
    },
    {
      key: WidgetGroupKey.TOTALS,
      widgets: []
    },
    {
      key: WidgetGroupKey.TERMINALS_ACTIVITY,
      widgets: []
    },
    {
      key: WidgetGroupKey.SIM_ACTIVITY,
      widgets: []
    },
    {
      key: WidgetGroupKey.COMPARISON,
      widgets: []
    },
    {
      key: WidgetGroupKey.PAYMENT_METHODS,
      widgets: []
    }
  ];

  widgets.forEach(widget => {
    getGroupByKey(widgetGroups, widget.groupKey).widgets.push(widget);
  });

  return widgetGroups;
};

export const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, levelSettings: _levelSettings },
    widgets: {
      data: userWidgets,
      availableWidgets: { data: availableWidgets, loading }
    },
    genericFields: { data: transactionFields = [] },
    theme: {
      color: {
        data: { color }
      }
    },
    searchContext: { data: searchContext },
    palettes: { data: palettes = [] }
  } = state;

  const { t } = ownProps;

  const { defaultPaletteId } = user;
  const palettesOptions = _.zipObject(
    palettes.map(item => [item.id, item.name])
  );
  const initialPalette = Utils.findPaletteById(defaultPaletteId, palettes);

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const criteriaOptions = transactionFields.reduce((prevField, field) => {
    const { key: fieldKey, visible, extras } = field;
    const tagsKey = /\b(selectedTags).([^.]+)/g.exec(fieldKey);
    const defaultLabelValue = tagsKey ? tagsKey[2] : fieldKey;

    if (visible || extras || tagsKey) {
      prevField.push({
        label: t(
          `${TransactionConstants.I18N_PREFIX}.${defaultLabelValue}.label`,
          defaultLabelValue
        ),
        value: fieldKey
      });
    }

    return prevField;
  }, []);

  const groupWidgets = groupWidgetsByType(availableWidgets);
  const cleanedGroupWidgets = groupWidgets.filter(
    group => group.widgets.length > 0
  );

  return {
    loading,
    user,
    userWidgets,
    widgets: cleanedGroupWidgets,
    color,
    criteriaOptions,
    userCurrency,
    searchContext,
    palettes,
    defaultPaletteId,
    palettesOptions,
    ...(initialPalette
      ? { colors: [...initialPalette.colors] }
      : { colors: [] })
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config })),
  getTrend: ({ config }) => dispatch(getTrend({ config })),
  createWidget: ({ widget }) => dispatch(createWidget({ widget })),
  resetAvailableWidgetsState: () => dispatch(resetAvailableWidgetsState()),
  getWidgets: () => dispatch(getWidgets()),
  getAvailableWidgets: () => dispatch(getAvailableWidgets()),
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  resetWidgetsStatsState: () => dispatch(resetWidgetsStatsState()),
  resetWidgetStatsState: () =>
    dispatch(resetWidgetStatsState({ widgetId: "preview" })),

  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname
      })
    ),
  cleanSearchContext: () =>
    dispatch(
      cleanSearchContext({
        key: advancedSearchKey,
        context: { key: advancedSearchKey },
        pathname: advancedSearchKey
      })
    ),
  getPalettes: () => dispatch(getPalettes())
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AddWidgetView);
