import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import I18nSpan from "../../../i18n/components/I18nSpan";

import styles from "../../styles/styles.css";

export const UploadPhotoAction = ({ route, msgKey }) => {
  return (
    <Link
      className={classNames(
        "btn",
        "multiple-action",
        styles["multiple-action"]
      )}
      to={route}
    >
      <span
        className={classNames(
          "glyphicon",
          "glyphicon-upload",
          styles.glyphicon
        )}
      />
      <I18nSpan msgKey={msgKey} />
    </Link>
  );
};
