import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import I18nSpan from "../../../i18n/components/I18nSpan";

import styles from "../../styles/styles.css";

export const CreateStoreAction = ({ route, msgKey }) => {
  return (
    <Link
      className={classNames(
        "btn",
        "multiple-action",
        styles["multiple-action"],
        styles["link-display"]
      )}
      to={route}
    >
      <i
        className={`material-icons ${styles["material-icons"]} ${styles["store-icon"]}`}
      >
        store_mall_directory
      </i>
      <I18nSpan msgKey={msgKey} class={classNames(styles["spanStyle"])} />
    </Link>
  );
};
