import { changeLang } from "../../i18n";

import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  FAILURE_CREATE_USER_ACTION,
  PENDING_CREATE_USER_ACTION,
  SUCCESS_CREATE_USER_ACTION,
  FAILURE_CREATE_MPOS_USER_ACTION,
  PENDING_CREATE_MPOS_USER_ACTION,
  SUCCESS_CREATE_MPOS_USER_ACTION,
  PENDING_EXIST_LOGIN_ACTION,
  SUCCESS_EXIST_LOGIN_ACTION,
  FAILURE_EXIST_LOGIN_ACTION,
  RESET_USER_STATE_ACTION,
  PENDING_GET_USER_ACTION,
  SUCCESS_GET_USER_ACTION,
  FAILURE_GET_USER_ACTION,
  PENDING_GET_USER_ACCESSIBLE_SCOPE_ACTION,
  SUCCESS_GET_USER_ACCESSIBLE_SCOPE_ACTION,
  FAILURE_GET_USER_ACCESSIBLE_SCOPE_ACTION,
  PENDING_GET_USER_APPLICATION_SETTINGS_ACTION,
  SUCCESS_GET_USER_APPLICATION_SETTINGS_ACTION,
  FAILURE_GET_USER_APPLICATION_SETTINGS_ACTION,
  FAILURE_UPDATE_USER_ACTION,
  PENDING_UPDATE_USER_ACTION,
  SUCCESS_UPDATE_USER_ACTION,
  FAILURE_UPDATE_USER_APPLICATION_SETTINGS_ACTION,
  PENDING_UPDATE_USER_APPLICATION_SETTINGS_ACTION,
  SUCCESS_UPDATE_USER_APPLICATION_SETTINGS_ACTION,
  FAILURE_UPDATE_USER_SETTINGS_ACTION,
  PENDING_UPDATE_USER_SETTINGS_ACTION,
  SUCCESS_UPDATE_USER_SETTINGS_ACTION,
  FAILURE_UPDATE_USER_LANGUAGE_ACTION,
  PENDING_UPDATE_USER_LANGUAGE_ACTION,
  SUCCESS_UPDATE_USER_LANGUAGE_ACTION
} from "../actionsTypes";

import { AUTHENTICATION_STORAGE_KEY } from "./auth";

const createUserStart = () => ({
  type: PENDING_CREATE_USER_ACTION
});

const createUserSucceeded = () => ({
  type: SUCCESS_CREATE_USER_ACTION
});

const createUserFailure = (errors: any) => ({
  type: FAILURE_CREATE_USER_ACTION,
  payload: {
    errors
  }
});

export const createUser = ({ user, level, entityId }) => dispatch => {
  dispatch(createUserStart());

  const url = entityId
    ? `/user/level/${level}/${entityId}`
    : `/user/level/${level}`;

  return F.post(
    url,
    {
      body: user,
      contentType: ContentTypes.JSON
    },
    "v2"
  ).then(
    user => {
      dispatch(createUserSucceeded());
      return user;
    },
    errors => {
      dispatch(createUserFailure({ errors }));

      throw errors;
    }
  );
};

const createMposUserStart = () => ({
  type: PENDING_CREATE_MPOS_USER_ACTION
});

const createMposUserSucceeded = () => ({
  type: SUCCESS_CREATE_MPOS_USER_ACTION
});

const createMposUserFailure = (errors: any) => ({
  type: FAILURE_CREATE_MPOS_USER_ACTION,
  payload: {
    errors
  }
});

export const createMposUser = mPosUser => dispatch => {
  dispatch(createMposUserStart());

  return F.post("/users-mpos", {
    body: mPosUser,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(createMposUserSucceeded()),
    errors => {
      dispatch(createMposUserFailure(errors));

      throw errors;
    }
  );
};

const isLoginExistingStart = () => ({
  type: PENDING_EXIST_LOGIN_ACTION
});

const isLoginExistingSucceeded = () => ({
  type: SUCCESS_EXIST_LOGIN_ACTION
});

const isLoginExistingFailure = errors => ({
  type: FAILURE_EXIST_LOGIN_ACTION,
  payload: {
    errors
  }
});

export const isLoginExisting = login => dispatch => {
  dispatch(isLoginExistingStart());

  return F.head(`/user/${login}`).then(
    response => {
      dispatch(isLoginExistingSucceeded());

      return response.status !== 404;
    },
    errors => {
      dispatch(isLoginExistingFailure({ errors }));

      throw errors;
    }
  );
};

const editUserStart = () => ({
  type: PENDING_UPDATE_USER_ACTION
});

const editUserSucceeded = () => ({
  type: SUCCESS_UPDATE_USER_ACTION
});

const editUserFailure = errors => ({
  type: FAILURE_UPDATE_USER_ACTION,
  payload: {
    errors
  }
});

export const editUser = ({ user }) => dispatch => {
  dispatch(editUserStart());

  return F.put(`/user/${user.login}`, {
    body: user,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(editUserSucceeded()),
    errors => {
      dispatch(editUserFailure({ errors }));

      throw errors;
    }
  );
};

const editUserApplicationSettingsStart = () => ({
  type: PENDING_UPDATE_USER_APPLICATION_SETTINGS_ACTION
});

const editUserApplicationSettingsSucceeded = () => ({
  type: SUCCESS_UPDATE_USER_APPLICATION_SETTINGS_ACTION
});

const editUserApplicationSettingsFailure = errors => ({
  type: FAILURE_UPDATE_USER_APPLICATION_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const editUserApplicationSettings = ({
  userId,
  applicationSettings
}) => dispatch => {
  dispatch(editUserApplicationSettingsStart());

  return F.put(`/user/${userId}/applicationSettings`, {
    body: applicationSettings,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(editUserApplicationSettingsSucceeded()),
    errors => {
      dispatch(editUserApplicationSettingsFailure({ errors }));

      throw errors;
    }
  );
};

const getUserStart = () => ({
  type: PENDING_GET_USER_ACTION
});

const getUserSucceeded = user => ({
  type: SUCCESS_GET_USER_ACTION,
  payload: {
    user
  }
});

const getUserFailure = errors => ({
  type: FAILURE_GET_USER_ACTION,
  payload: {
    errors
  }
});

export const getUser = userId => dispatch => {
  dispatch(getUserStart());

  return F.get(`/user/${userId}`, "v2").then(
    user => {
      dispatch(getUserSucceeded(user));

      return user;
    },
    errors => {
      dispatch(getUserFailure(errors));

      throw errors;
    }
  );
};

const getUserAccessibleScopeStart = () => ({
  type: PENDING_GET_USER_ACCESSIBLE_SCOPE_ACTION
});

const getUserAccessibleScopeSucceeded = accessibleScope => ({
  type: SUCCESS_GET_USER_ACCESSIBLE_SCOPE_ACTION,
  payload: {
    accessibleScope
  }
});

const getUserAccessibleScopeFailure = errors => ({
  type: FAILURE_GET_USER_ACCESSIBLE_SCOPE_ACTION,
  payload: {
    errors
  }
});

export const getUserAccessibleScope = () => dispatch => {
  dispatch(getUserAccessibleScopeStart());

  return F.get("/me/accessibleScope").then(
    accessibleScope =>
      dispatch(getUserAccessibleScopeSucceeded(accessibleScope)),
    errors => {
      dispatch(getUserAccessibleScopeFailure(errors));

      throw errors;
    }
  );
};

const updateUserSettingsStart = () => ({
  type: PENDING_UPDATE_USER_SETTINGS_ACTION
});

const updateUserSettingsSucceeded = user => ({
  type: SUCCESS_UPDATE_USER_SETTINGS_ACTION,
  payload: {
    user
  }
});

const updateUserSettingsFailure = errors => ({
  type: FAILURE_UPDATE_USER_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const updateUserSettings = ({ data }) => dispatch => {
  dispatch(updateUserSettingsStart());

  const { JSON: contentType } = ContentTypes;

  return F.put("/me/settings", {
    body: data,
    contentType
  }).then(
    user => {
      localStorage.setItem(AUTHENTICATION_STORAGE_KEY, JSON.stringify(user));

      dispatch(updateUserSettingsSucceeded(user));

      return user;
    },
    errors => {
      dispatch(updateUserSettingsFailure(errors));

      throw errors;
    }
  );
};

const updateUserLanguageStart = () => ({
  type: PENDING_UPDATE_USER_LANGUAGE_ACTION
});

const updateUserLanguageSucceeded = () => ({
  type: SUCCESS_UPDATE_USER_LANGUAGE_ACTION
});

const updateUserLanguageFailure = errors => ({
  type: FAILURE_UPDATE_USER_LANGUAGE_ACTION,
  payload: {
    errors
  }
});

export const updateUserLanguage = newLocale => dispatch => {
  dispatch(updateUserLanguageStart());

  return F.put(`/me/settings/language/${newLocale}`).then(
    () => {
      changeLang(newLocale);

      dispatch(updateUserLanguageSucceeded());
    },
    errors => {
      dispatch(updateUserLanguageFailure(errors));

      throw errors;
    }
  );
};

const getUserApplicationSettingsStart = () => ({
  type: PENDING_GET_USER_APPLICATION_SETTINGS_ACTION
});

const getUserApplicationSettingsSucceeded = applicationSettings => ({
  type: SUCCESS_GET_USER_APPLICATION_SETTINGS_ACTION,
  payload: {
    applicationSettings
  }
});

const getUserApplicationSettingsFailure = errors => ({
  type: FAILURE_GET_USER_APPLICATION_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const getUserApplicationSettings = userId => dispatch => {
  dispatch(getUserApplicationSettingsStart());

  return F.get(`/user/${userId}/applicationSettings`).then(
    applicationSettings =>
      dispatch(getUserApplicationSettingsSucceeded(applicationSettings)),
    errors => {
      dispatch(getUserApplicationSettingsFailure(errors));

      throw errors;
    }
  );
};

export const resetUserState = () => ({
  type: RESET_USER_STATE_ACTION
});
