import React, { Component } from "react";
import * as TagsInput from "react-tagsinput";
import _ from "lodash";
import { connect } from "react-redux";

interface Props {
  name: string;
  placeholder: string;
  value: any;
  color: any;
  readOnly: boolean;
  onChange: Function;
}

interface State {
  tags: any;
}

class Multiple extends Component<Props, State> {
  state = {
    tags: this.props.value
  };

  _onChange = () => {
    const { onChange, name } = this.props;
    const { tags } = this.state;

    return onChange(name, tags);
  };

  handleChange = tags => {
    this.setState({ tags }, this._onChange);
  };

  render() {
    const { readOnly, value: valueList, placeholder, color } = this.props;

    let multiselect;
    if (readOnly) {
      multiselect = (
        <div>
          <div>
            <ul className="nav nav-pills personalized">
              {_.map(valueList, (value, idx) => (
                <li key={idx} className="active">
                  <span>{value}</span>
                </li>
              ))}
            </ul>
          </div>
          <style>
            {`
                        .personalized .active span,
                        .personalized .active span:hover {
                            background-color: ${color} !important;
                            display: block;
                            border-radius: 4px;
                            padding: 10px 15px;
                        }
                    `}
          </style>
        </div>
      );
    } else {
      multiselect = (
        <TagsInput
          inputProps={{ placeholder }}
          ref="tags"
          value={valueList}
          onChange={this.handleChange}
          onlyUnique={true}
        />
      );
    }
    return <div> {multiselect} </div>;
  }
}

const mapStateToProps = state => {
  const {
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  return { color };
};

export default connect(mapStateToProps, null)(Multiple);
