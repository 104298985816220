import * as moment from "moment";
import {
  CreatePredefinedAlert,
  languages,
  PredefinedAlert,
  PredefinedAlertType
} from "./PredefinedAlert";
import PredefinedConstants from "../PredefinedAlertConstant";

const humanizeDuration = require("humanize-duration");

export class CreatePayThenRefundAlert extends CreatePredefinedAlert {
  constructor(public duration: moment.Duration) {
    super(PayThenRefundAlert.TYPE);
    this.duration =
      duration ||
      moment.duration(PredefinedConstants.DEFAULT_DURATION, "minutes");
  }

  setDuration(minutes: number) {
    this.duration = moment.duration(minutes, "minutes");
  }
}

export class PayThenRefundAlert extends PredefinedAlert {
  static TYPE: PredefinedAlertType = "PayThenRefund";
  static I18N_KEY = "pay-then-refund";

  constructor(public id: string, public timeWindow: moment.Duration) {
    super();
    this.id = id;
    this.timeWindow = timeWindow;
  }

  i18nKey(): string {
    return PayThenRefundAlert.I18N_KEY;
  }

  i18nArgs(locale: string): Array<string> {
    return [
      humanizeDuration((this.timeWindow as any)._milliseconds, {
        delimiter: " ",
        largest: 2,
        round: true,
        language: locale,
        languages
      })
    ];
  }
}
