import React from "react";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

const SetupManual = ({ manual, handleManual }) => {
  const { t } = useTranslation();

  const { darkMode: prefersDarkMode } = useSelector(state =>
    mapStateToProps(state)
  );

  const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "uppercase",
        backgroundColor: prefersDarkMode ? "#3B3B3B" : "#E5E5E5",
        borderRadius: "5px",
        width: "100%",
        fontWeight: "bold",
        height: "calc(var(--vh, 1vh) * 8)",
        fontSize: "11px",
        color: prefersDarkMode ? "#E5E5E5" : "#404041",
        textAlign: "center",
        "&:hover": {
          color: prefersDarkMode ? "#404041" : "#404041",
          backgroundColor: "#E5E5E5"
        }
      },
      contained: {}
    })
  );

  const classesButton = useStylesButton();

  if (manual) {
    return null;
  }

  return (
    <Button
      classes={{
        root: classesButton.root,
        contained: classesButton.contained
      }}
      onClick={() => handleManual()}
    >
      {t("poi.mobile.add.manually")}
    </Button>
  );
};

export default SetupManual;
