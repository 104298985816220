import { constantCase } from "constant-case";

export const deleteEvent = ({ id, sectionType }) => {
  const name = constantCase(sectionType);

  return {
    type: `SUCCESS_DELETE_${name}_ACTION`,
    payload: {
      id
    }
  };
};

export const updateStatusEvent = ({ ids, status, sectionType }) => {
  const name = constantCase(sectionType);

  return {
    type: `SUCCESS_UPDATE_STATUS_${name}_ACTION`,
    payload: {
      ids,
      status
    }
  };
};

export const reduceCount = ({ countNumber, data, nextData }) => {
  return countNumber > 0 && data.length !== nextData.length
    ? countNumber - 1
    : countNumber;
};
