import {
  PENDING_GET_AUDITS_ACTION,
  SUCCESS_GET_AUDITS_ACTION,
  SUCCESS_GET_AUDITS_SCROLL_ACTION,
  FAILURE_GET_AUDITS_ACTION
} from "../actionsTypes";

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_AUDITS_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_AUDITS_ACTION:
      const {
        payload: { count, scrollId, audits }
      } = action;

      return {
        ...state,
        ...initialState,
        data: audits,
        count,
        scrollId
      };
    case SUCCESS_GET_AUDITS_SCROLL_ACTION: {
      const {
        payload: { data: scrollData }
      } = action;
      const data = [...state.data, ...scrollData];

      return {
        ...state,
        data,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_AUDITS_ACTION: {
      const { payload: errors } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    default:
      return state;
  }
};
