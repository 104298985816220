import { store } from "../../redux/store";
import { isLoginExisting } from "../../redux/actions";
import Validators from "../../ingenicoForm/validation/Validators";

export default {
  loginUnicity(value) {
    value = value || "";

    return store
      .dispatch(isLoginExisting(value))
      .then(isExisting =>
        isExisting ? { code: "form.error.login.existing", args: [value] } : null
      );
  },

  withoutSpecialChars: ({
    field,
    value,
    code = "form.error.asciiCharacters"
  }: any) => {
    if (value && !/^([a-zA-Z0-9_.-]{2,})$/.test(value)) {
      return { code, args: [field ? field : value] };
    }
    return null;
  },

  email(value) {
    return Promise.resolve(Validators.emailValidator(value));
  },

  required(value) {
    return Promise.resolve(Validators.requiredValidator(value));
  },

  noop() {
    return Promise.resolve(null);
  },

  minLength: (minLengthTranslation: string) => value =>
    Promise.resolve(
      Validators.sizeOfStringValidator(2, minLengthTranslation)(value)
    ),

  plainLogin: value =>
    Validators.hasSpaceChar(value)
      ? { code: "form.error.login.spaces", args: [value] }
      : null
};

export const userErrorNotification = async ({
  error,
  addNotificationError,
  form
}: any) => {
  const { errorKey } = await error;

  switch (errorKey) {
    case "user.login.already.exists":
      return addNotificationError("form.error.login.existing", [form.login]);
    case "user.two-factor.aleady.set":
      return addNotificationError("form.error.two-factor.existing", form);
    case "user.password.history.not.found":
      return addNotificationError(
        "form.error.two-factor.historyNotFound",
        form
      );
    case "user.two-factor.not.found":
      return addNotificationError("form.error.two-factor.notFound");
    default:
      addNotificationError(errorKey);
  }
};
