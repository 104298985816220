import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  FAILURE_ADD_TAG_ACTION,
  PENDING_ADD_TAG_ACTION,
  SUCCESS_ADD_TAG_ACTION,
  FAILURE_UPDATE_TAG_ACTION,
  PENDING_UPDATE_TAG_ACTION,
  SUCCESS_UPDATE_TAG_ACTION
} from "../actionsTypes";

export const createTag = ({ tag, tagType }) => dispatch => {
  dispatch(addTagStart());

  const url = `/${tagType}/tag`;
  return F.post(url, {
    body: tag,
    contentType: ContentTypes.JSON
  })
    .then(data => {
      dispatch(addTagSucceeded(data));
      return data;
    })
    .catch(errors => dispatch(addTagFailure({ errors })));
};

export const addTagStart = () => ({
  type: PENDING_ADD_TAG_ACTION
});

export const addTagSucceeded = tags => ({
  type: SUCCESS_ADD_TAG_ACTION,
  payload: {
    tags
  }
});

export const addTagFailure = errors => ({
  type: FAILURE_ADD_TAG_ACTION,
  payload: {
    errors
  }
});

export const editTag = ({ tag, tagType }) => dispatch => {
  dispatch(editTagStart());

  const url = `/${tagType}/tag`;
  return F.put(url, {
    body: tag,
    contentType: ContentTypes.JSON
  })
    .then(data => {
      dispatch(editTagSucceeded(data));
      return data;
    })
    .catch(errors => dispatch(editTagFailure({ errors })));
};

export const editTagStart = () => ({
  type: PENDING_UPDATE_TAG_ACTION
});

export const editTagSucceeded = tags => ({
  type: SUCCESS_UPDATE_TAG_ACTION,
  payload: {
    tags
  }
});

export const editTagFailure = errors => ({
  type: FAILURE_UPDATE_TAG_ACTION,
  payload: {
    errors
  }
});
