import React, { Component } from "react";
import i18next from "i18next";
import { connect } from "react-redux";

import NumberFormatter from "../../../formatters/NumberFormatter";
import AdvancedSearchConstants from "../../../advancedSearch/constants/AdvancedSearchConstants";
import TransactionConstants from "../../constants/TransactionConstants";
import AdvancedSearch from "../../../advancedSearch/components/AdvancedSearch";

interface Props {
  name: string;
  onChange: Function;
  disabled: boolean;
  filtersMap: Array<string>;
  transactionFields: any;
  searchFilters: any;
  reduxField: string;
  userType: string;
}

interface State {
  currency: any;
}

interface State {}

class TransactionAdvancedSearch extends Component<Props, State> {
  _transactionDataFormatter = () => {
    const { currency } = this.props;

    return {
      formatNumber: valueItem =>
        NumberFormatter.unformatAmount(
          valueItem,
          currency && currency.currencyDecimal
        )
    };
  };

  render() {
    const {
      className,
      name,
      onChange,
      disabled,
      searchFilters,
      transactionFields,
      i18nKey,
      searchContext
    } = this.props;
    const suggestionI18nKey = key => {
      if (i18nKey) {
        return `${i18nKey}.${key}`;
      }

      return `${TransactionConstants.I18N_PREFIX}.${key}.label`;
    };

    return (
      <div>
        <AdvancedSearch
          className={className}
          searchFilters={searchFilters}
          name={name}
          onChange={onChange}
          formatter={this._transactionDataFormatter()}
          suggestionI18nKey={suggestionI18nKey}
          disabled={disabled}
          genericFields={transactionFields}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

TransactionAdvancedSearch.defaultProps = {
  name: AdvancedSearchConstants.TRANSACTION_KEY,
  transactionFields: []
};

const mapStateToProps = (state, ownProps) => {
  let {
    auth: { levelSettings: _levelSettings },
    genericFields: { data: fields = [] }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const { transactionFields, filtersMap, i18nKey } = ownProps;
  fields = transactionFields || fields;
  const searchFilters = fields.reduce((prevField: any, field: any) => {
    const { key, type, searchable } = field;
    const tagsKey = /\b(selectedTags).([^.]+)/g.exec(key);
    const searchableField = filtersMap
      ? filtersMap.includes(key) || tagsKey
      : searchable;

    if (searchableField) {
      prevField.push({
        i18nKey: i18nKey
          ? `${i18nKey}.${key}`
          : `${TransactionConstants.I18N_PREFIX}.${key}.label`,
        rawLabel: tagsKey ? tagsKey[2] : null,
        name: key,
        type
      });
    }

    return prevField;
  }, []);

  return {
    searchFilters,
    transactionFields: fields,
    currency: userCurrency
  };
};

export default connect(mapStateToProps, null)(TransactionAdvancedSearch);
