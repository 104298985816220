import { constantCase } from "constant-case";

import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import { toPeriodType } from "../../reporting/models/TimePeriod";
// import { getTransactionsAgg } from "../../reporting/clients/ActivityClient";
import {
  getReturningCustomersAgg,
  getReturningCustomersSearch
} from "../../reporting/clients/ReturningCustomersClient";
import { WidgetType } from "../../home/models/WidgetType";

export const getTransactionsAgg = ({
  restriction,
  filters,
  aggregation,
  begin,
  end
}) => {
  const url = new URL("/transaction/aggregate", PORTAL_URL);

  Object.entries({ begin, end }).forEach(param => {
    const [paramKey, paramValue] = param;

    if (paramValue) {
      return url.searchParams.append(paramKey, paramValue);
    }
  });

  return F.post(
    `${url.pathname}${url.search}`,
    {
      body: {
        restriction,
        filters,
        aggregation
      },
      contentType: ContentTypes.JSON
    },
    "v2"
  );
};

import {
  RESET_WIDGETS_STATS_STATE_ACTION,
  RESET_STATS_STATE_ACTION
} from "../actionsTypes";

export interface TimestampsParams {
  min: string;
  max: string;
}

export const getSimVolumesPer = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps,
  userId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;

  const aggregation = "sim.contract.raw";
  const alias = "contract";

  const url = new URL("/reporting/sim/volumes", PORTAL_URL);

  Object.entries({ begin, end, aggregation, alias }).forEach(param => {
    const [paramKey, paramValue] = param;

    if (paramValue) {
      return url.searchParams.append(paramKey, paramValue);
    }
  });

  return F.get(`${url.pathname}${url.search}`)
    .then(data => {
      return dispatch(
        getStatsSucceeded({
          widgetType,
          widgetId,
          stats: { volumes: data }
        })
      );
    })
    .catch(errors => {
      return dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    });
};

export const getTerminalVolumesPer = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;

  const aggregation = "pos.poiContract.raw";
  const alias = "contract";
  const url = `/concert-connection/aggregate`;

  const body = {
    filters: [
      {
        filterType: "integer",
        name: "endTimestamp",
        operator: "<",
        value: end
      },
      {
        filterType: "integer",
        name: "endTimestamp",
        operator: ">=",
        value: begin
      }
    ],
    aggregation: {
      name: alias,
      type: "term",
      field: aggregation,
      size: 2147483647,
      subaggs: [
        {
          field: "poi.id.raw",
          name: "pois",
          type: "cardinality",
          precisionThreshold: 40000
        },
        {
          field: "ipVolumeToTerminal",
          name: "ipVolumeToTerminal",
          type: "sum"
        },
        {
          field: "ipVolumeFromTerminal",
          name: "ipVolumeFromTerminal",
          type: "sum"
        }
      ]
    }
  };

  return F.post(url, {
    body,
    contentType: ContentTypes.JSON
  })
    .then(data => {
      const { contract: { buckets } = { buckets: [] } } = data;

      return dispatch(
        getStatsSucceeded({
          widgetType,
          widgetId,
          stats: { volumes: buckets }
        })
      );
    })
    .catch(errors => {
      return dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    });
};

export const getCustomersTopByCount = ({
  widgetType,
  widgetId,
  filters = [],
  merchantId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const defaultFilters = [
      {
        filterType: "string",
        name: "merchantId",
        operator: "=",
        value: merchantId
      },
      {
        filterType: "integer",
        name: "nbPayments",
        operator: ">",
        value: 1
      }
    ];

    const {
      searchResults: returningCustomersTopByCount
    } = await getReturningCustomersSearch({
      filters: [...defaultFilters, ...filters],
      sort: {
        field: "nbPayments",
        order: "DESC"
      },
      max: 5
    });

    const topByCount = returningCustomersTopByCount.map(visit => {
      const {
        uniqueCardId: cardId,
        nbPayments: count,
        totalAmount: sum
      } = visit;

      return {
        cardId,
        count,
        sum
      };
    });

    const stats = {
      topByCount
    };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getCustomersTopByAmount = ({
  widgetType,
  widgetId,
  filters = [],
  merchantId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const defaultFilters = [
      {
        filterType: "string",
        name: "merchantId",
        operator: "=",
        value: merchantId
      },
      {
        filterType: "integer",
        name: "nbPayments",
        operator: ">",
        value: 1
      }
    ];

    const {
      searchResults: returningCustomersTopByAmount
    } = await getReturningCustomersSearch({
      filters: [...defaultFilters, ...filters],
      sort: {
        field: "totalAmount",
        order: "DESC"
      },
      max: 5
    });

    const topByAmount = returningCustomersTopByAmount.map(visit => {
      const {
        uniqueCardId: cardId,
        nbPayments: count,
        totalAmount: sum
      } = visit;

      return {
        cardId,
        count,
        sum
      };
    });

    const stats = {
      topByAmount
    };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getCustomersAverageBasket = ({
  widgetType,
  widgetId,
  filters = [],
  merchantId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const defaultFilters = [
      {
        filterType: "string",
        name: "merchantId",
        operator: "=",
        value: merchantId
      }
    ];

    const returningCustomersAverageBasket = await getReturningCustomersAgg({
      restriction: "none",
      filters: [],
      aggregation: {
        name: "only_the_merchant",
        type: "filter",
        filters: [...defaultFilters],
        subaggs: [
          {
            name: "single_vs_returning",
            type: "range",
            field: "nbPayments",
            keyed: true,
            ranges: [
              {
                key: "single",
                to: 2
              },
              {
                key: "returning",
                from: 2
              }
            ],
            subaggs: [
              {
                field: "avgBasket",
                name: "avgBasket",
                type: "avg"
              }
            ]
          }
        ]
      }
    });
    const {
      only_the_merchant: {
        single_vs_returning: {
          buckets: {
            returning: {
              avgBasket: { value: recurringTransactionsAverage }
            },
            single: {
              avgBasket: { value: singleTransactionsAverage }
            }
          }
        }
      }
    } = returningCustomersAverageBasket;

    const stats = {
      averageSales: {
        recurringTransactionsAverage,
        singleTransactionsAverage
      }
    };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getAverageVisits = ({
  widgetType,
  widgetId,
  filters = [],
  merchantId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const defaultFilters = [
      {
        filterType: "string",
        name: "merchantId",
        operator: "=",
        value: merchantId
      }
    ];

    const returningCustomersAverageVisits = await getReturningCustomersAgg({
      restriction: "none",
      filters: [],
      aggregation: {
        name: "only_the_merchant",
        type: "filter",
        filters: [...defaultFilters],
        subaggs: [
          {
            name: "single_vs_returning",
            type: "range",
            field: "nbPayments",
            keyed: true,
            ranges: [
              {
                key: "returning",
                from: 2
              }
            ],
            subaggs: [
              {
                field: "nbPayments",
                name: "avg_visit",
                type: "avg"
              }
            ]
          }
        ]
      }
    });
    const {
      only_the_merchant: {
        single_vs_returning: {
          buckets: {
            returning: {
              avg_visit: { value: nbReturningAverageVisit }
            }
          }
        }
      }
    } = returningCustomersAverageVisits;

    const nbVisit =
      nbReturningAverageVisit === null ? 0 : nbReturningAverageVisit;

    const stats = { visits: parseInt(nbVisit, 10) };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getCustomerTransactionCount = ({
  widgetType,
  widgetId,
  filters = [],
  merchantId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const defaultFilters = [
      {
        filterType: "string",
        name: "merchantId",
        operator: "=",
        value: merchantId
      }
    ];

    const returningCustomersSalesCount = await getReturningCustomersAgg({
      restriction: "none",
      filters: [],
      aggregation: {
        name: "only_the_merchant",
        type: "filter",
        filters: [...defaultFilters],
        subaggs: [
          {
            name: "single_vs_returning",
            type: "range",
            field: "nbPayments",
            keyed: true,
            ranges: [
              {
                key: "single",
                to: 2
              },
              {
                key: "returning",
                from: 2
              }
            ],
            subaggs: [
              {
                field: "totalAmount",
                name: "sum_payments",
                type: "sum"
              }
            ]
          }
        ]
      }
    });
    const {
      only_the_merchant: {
        single_vs_returning: {
          buckets: {
            single: {
              sum_payments: { value: nbNewAmount }
            },
            returning: {
              sum_payments: { value: nbReturningAmount }
            }
          }
        }
      }
    } = returningCustomersSalesCount;

    const stats = {
      customers: [
        {
          name: "reporting.customers.categories.newCustomers",
          y: nbNewAmount / (nbReturningAmount + nbNewAmount),
          value: nbNewAmount
        },
        {
          name: "reporting.customers.categories.returningCustomers",
          y: nbReturningAmount / (nbReturningAmount + nbNewAmount),
          value: nbReturningAmount
        }
      ]
    };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getCustomerCount = ({
  widgetType,
  widgetId,
  filters = [],
  merchantId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const defaultFilters = [
      {
        filterType: "string",
        name: "merchantId",
        operator: "=",
        value: merchantId
      }
    ];

    const returningCustomersCount = await getReturningCustomersAgg({
      filters: [],
      aggregation: {
        name: "only_the_merchant",
        type: "filter",
        filters: [...defaultFilters],
        subaggs: [
          {
            name: "single_vs_returning",
            type: "range",
            field: "nbPayments",
            keyed: true,
            ranges: [
              {
                key: "single",
                to: 2
              },
              {
                key: "returning",
                from: 2
              }
            ]
          }
        ]
      }
    });

    const {
      only_the_merchant: {
        single_vs_returning: {
          buckets: {
            single: { doc_count: nbNew },
            returning: { doc_count: nbReturning }
          }
        }
      }
    } = returningCustomersCount;

    const stats = {
      customers: [
        {
          name: "reporting.customers.categories.newCustomers",
          y: nbNew / (nbReturning + nbNew),
          value: nbNew
        },
        {
          name: "reporting.customers.categories.returningCustomers",
          y: nbReturning / (nbReturning + nbNew),
          value: nbReturning
        }
      ]
    };
    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getTransactionCountComparaison = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps,
  alias
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;

  return getTransactionsAgg({
    restriction: "transaction",
    filters: [...filters],
    aggregation: {
      name: alias,
      type: "count",
      field: `${alias}.raw`
    },
    begin,
    end
  })
    .then(data => {
      const { [alias]: { value } = { value: 0 } } = data;
      const stats = { transactionCount: value };
      return dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
    })
    .catch(errors => {
      dispatch(getStatsFailure({ widgetType, widgetId, errors }));
      throw errors;
    });
};

/////////////

export const getTransactionCount = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;

  try {
    const defaultFilters = [
      {
        filterType: "array",
        name: "transactionType",
        operator: "in",
        value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
      },
      {
        filterType: "array",
        name: "selectedService",
        operator: "in",
        value: ["PAYMENT", "QUASI-CASH PAYMENT"]
      },
      {
        filterType: "array",
        name: "transactionResult",
        operator: "in",
        value: ["APPROVED", "CAPTURED"]
      }
    ];

    const {
      transactionCount: { value } = { value: 0 }
    } = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "transactionCount",
        type: "count",
        field: "transactionAmount"
      },
      begin,
      end
    });
    const stats = { transactionCount: value };

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getTransactionCashback = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;

  try {
    const defaultFilters = [
      {
        filterType: "array",
        name: "transactionType",
        operator: "in",
        value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
      },
      {
        filterType: "array",
        name: "selectedService",
        operator: "in",
        value: ["PAYMENT", "QUASI-CASH PAYMENT"]
      },
      {
        filterType: "array",
        name: "transactionResult",
        operator: "in",
        value: ["APPROVED", "CAPTURED"]
      }
    ];

    const stats = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "cashback",
        type: "sum",
        field: "cashbackAmount"
      },
      begin,
      end
    });

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getNetRevenue = ({
  widgetType,
  widgetId,
  filters = [],
  timePeriod,
  timestamps
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;

  const periodType = toPeriodType(timePeriod.type);

  try {
    const {
      revenues: { net: value }
    } = await F.get(
      `/transactions/revenues?period=${periodType}&begin=${begin}&end=${end}&filters=${JSON.stringify(
        filters
      )}`
    );
    let stats = {};
    switch (widgetType) {
      case WidgetType.Revenue: {
        stats = {
          value
        };
        break;
      }
      case WidgetType.NetRevenuePerTerminal: {
        const countPoi = await searchTerminal({
          filters: [
            {
              filterType: "string",
              name: "poi.status",
              operator: "=",
              value: "Activated"
            }
          ]
        });

        const amount = value && countPoi ? value / countPoi : value;
        stats = { amount };
        break;
      }
    }

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getRefundAmount = ({
  widgetType,
  widgetId,
  filters = [],
  timePeriod,
  timestamps
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;

  const periodType = toPeriodType(timePeriod.type);

  try {
    const {
      revenues: { refund: value }
    } = await F.get(
      `/transactions/revenues?period=${periodType}&begin=${begin}&end=${end}&filters=${JSON.stringify(
        filters
      )}`
    );

    const stats = {
      value
    };

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getAverageBasket = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));
  const { min: begin, max: end } = timestamps;

  try {
    const defaultFilters = [
      {
        filterType: "array",
        name: "transactionType",
        operator: "in",
        value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
      },
      {
        filterType: "array",
        name: "selectedService",
        operator: "in",
        value: ["PAYMENT", "QUASI-CASH PAYMENT"]
      },
      {
        filterType: "array",
        name: "transactionResult",
        operator: "in",
        value: ["APPROVED", "CAPTURED"]
      }
    ];

    const stats = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "averageBasket",
        type: "avg",
        field: "transactionAmount"
      },
      begin,
      end
    });

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getTransactionRevenueChart = ({
  widgetType,
  widgetId,
  filters = [],
  timePeriod,
  timestamps,
  secondTimePeriod
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const periodType = toPeriodType(timePeriod.type);

  if (secondTimePeriod) {
    const {
      previous: { min: prevBegin, max: prevEnd }
    } = timestamps;
    const secondPeriodType = toPeriodType(secondTimePeriod.type);

    return Promise.all([
      F.get(
        `/transactions/revenues?period=${periodType}&begin=${prevBegin}&end=${prevEnd}&filters=${JSON.stringify(
          filters
        )}`
      ),
      F.get(
        `/transactions/revenues?period=${secondPeriodType}&begin=${begin}&end=${end}&filters=${JSON.stringify(
          filters
        )}`
      )
    ])
      .then(data => {
        const [previous, current] = data;

        const stats = { previous, current };
        dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
        return stats;
      })
      .catch(errors => {
        dispatch(getStatsFailure({ widgetType, widgetId, errors }));
        throw errors;
      });
  }
  return F.get(
    `/transactions/revenues?period=${periodType}&begin=${begin}&end=${end}&filters=${JSON.stringify(
      filters
    )}`
  )
    .then(stats => {
      dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
      return stats;
    })
    .catch(errors => {
      dispatch(getStatsFailure({ widgetType, widgetId, errors }));
      throw errors;
    });
};

export const getTerminalConnections = ({
  widgetType,
  widgetId,
  timestamps,
  aggregation,
  alias
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const { min: begin, max: end } = timestamps;
    const url = `/concert-connection/aggregate`;
    const body = {
      filters: [
        {
          filterType: "integer",
          name: "endTimestamp",
          operator: "<",
          value: end
        },
        {
          filterType: "integer",
          name: "endTimestamp",
          operator: ">=",
          value: begin
        }
      ],
      aggregation: {
        name: alias,
        type: "term",
        field: aggregation,
        size: 2147483647,
        subaggs: [
          {
            field: "poi.id.raw",
            name: "pois",
            type: "cardinality",
            precisionThreshold: 40000
          },
          {
            field: "ipVolumeToTerminal",
            name: "ipVolumeToTerminal",
            type: "sum"
          },
          {
            field: "ipVolumeFromTerminal",
            name: "ipVolumeFromTerminal",
            type: "sum"
          }
        ]
      }
    };

    const { contract: { buckets: volumes } = { buckets: [] } } = await F.post(
      url,
      {
        body,
        contentType: ContentTypes.JSON
      }
    );

    const stats = {
      volumes,
      criteria: alias
    };

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const searchMerchant = async ({ filters, fields = [] }) => {
  const url = new URL("/merchants/search", PORTAL_URL);

  url.searchParams.append("max", "0");

  const { count } = await F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields
    },
    contentType: ContentTypes.JSON
  });

  return count;
};

export const getMerchantCount = ({
  widgetType,
  widgetId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const count = await searchMerchant({
      filters: [
        {
          filterType: "string",
          name: "merchant.status",
          operator: "=",
          value: "Activated"
        }
      ]
    });

    const stats = {
      count
    };

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const searchTerminal = async ({ filters, fields = [] }) => {
  const url = new URL("/poi/search", PORTAL_URL);

  url.searchParams.append("max", "0");

  const { count } = await F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields
    },
    contentType: ContentTypes.JSON
  });

  return count;
};

export const getTerminalCount = ({
  widgetType,
  widgetId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const count = await searchTerminal({
      filters: [
        {
          filterType: "string",
          name: "poi.status",
          operator: "=",
          value: "Activated"
        }
      ]
    });

    const stats = {
      count
    };

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getGrossRevenue = ({
  widgetType,
  widgetId,
  timestamps,
  filters = []
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min, max } = timestamps;

  try {
    const defaultFilters = [
      {
        filterType: "array",
        name: "transactionType",
        operator: "in",
        value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
      },
      {
        filterType: "array",
        name: "selectedService",
        operator: "in",
        value: ["PAYMENT", "QUASI-CASH PAYMENT"]
      },
      {
        filterType: "array",
        name: "transactionResult",
        operator: "in",
        value: ["APPROVED", "CAPTURED"]
      }
    ];

    const {
      sum_amount: { value = 0 } = { value: 0 }
    } = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "sum_amount",
        type: "sum",
        field: "transactionAmount"
      },
      begin: min,
      end: max
    });

    let stats = {};
    switch (widgetType) {
      case WidgetType.GrossRevenue: {
        stats = {
          amount: value
        };
        break;
      }
      case WidgetType.CustomerAverageRevenue: {
        const countPoi = await searchTerminal({
          filters: [
            {
              filterType: "string",
              name: "poi.status",
              operator: "=",
              value: "Activated"
            }
          ]
        });

        const amount = value && countPoi ? value / countPoi : value;
        stats = { amount };
        break;
      }
    }

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getMaintainerActiveTerminalsCount = ({
  widgetType,
  widgetId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const filters = [
      {
        filterType: "string",
        name: "poi.status",
        operator: "=",
        value: "Activated"
      },
      {
        filterType: "string",
        name: "firstConnection",
        operator: "exists",
        value: ""
      }
    ];

    const url = new URL("/poi/search", PORTAL_URL);

    url.searchParams.append("max", "0");

    const { count } = await F.post(`${url.pathname}${url.search}`, {
      body: {
        filters,
        fields: []
      },
      contentType: ContentTypes.JSON
    });

    const stats = {
      count
    };

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getTerminalsOverQuotaCount = ({
  widgetType,
  widgetId
}: any) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const { count = 0 } = await F.get(`/terminal-alert/limit-stat`);

    const stats = { count };

    dispatch(getStatsSucceeded({ widgetType, widgetId, stats }));
  } catch (errorsPromise) {
    const errors = await errorsPromise;
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getMaintainerTerminalsWithoutTelecollectCount = ({
  widgetType,
  widgetId
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const count = await F.get(`/terminal-alert/telecollect-stat`);

    const stats = { count };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const searchSims = ({
  filters,
  fields = [],
  max = "0",
  begin,
  end
}: any) => {
  const url = new URL("/sims/search", PORTAL_URL);

  Object.entries({ max, begin, end }).forEach(param => {
    const [paramKey, paramValue] = param;

    if (paramValue) {
      return url.searchParams.append(paramKey, paramValue);
    }
  });

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields
    },
    contentType: ContentTypes.JSON
  });
};

export const getActiveSimsCount = ({
  widgetType,
  widgetId,
  userId
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  try {
    const { count } = await searchSims({
      filters: [
        {
          filterType: "string",
          name: "sim.status",
          operator: "=",
          value: "Activated"
        },
        {
          filterType: "string",
          name: "acquirer.id",
          operator: "=",
          value: userId
        }
      ]
    });

    const { count: total } = await searchSims({
      filters: [
        {
          filterType: "array",
          name: "sim.status",
          operator: "in",
          value: ["Activated", "Terminated", "Suspended"]
        },
        {
          filterType: "string",
          name: "acquirer.id",
          operator: "=",
          value: userId
        }
      ]
    });
    const stats = {
      count,
      total
    };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getComparisonAverageBasket = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps,
  alias
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const type = "avg";

  return getTransactionsAgg({
    restriction: "transaction",
    filters,
    aggregation: {
      name: alias,
      type: "term",
      field: `${alias}.raw`,
      size: 2147483647,
      subaggs: [
        {
          field: "transactionAmount",
          name: `${type}-on-transaction-amount`,
          type
        }
      ]
    },
    begin,
    end
  })
    .then(data => {
      const stats = {
        ...data,
        criteria: alias
      };

      return dispatch(
        getStatsSucceeded({
          widgetType,
          widgetId,
          stats
        })
      );
    })
    .catch(errors => {
      dispatch(getStatsFailure({ widgetType, widgetId, errors }));
      throw errors;
    });
};

export const getComparisonCountChart = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps,
  alias
}) => dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const type = "count";

  return getTransactionsAgg({
    restriction: "transaction",
    filters,
    aggregation: {
      name: alias,
      type: "term",
      field: `${alias}.raw`,
      size: 2147483647,
      subaggs: [
        {
          field: "transactionAmount",
          name: `${type}-on-transaction-amount`,
          type
        }
      ]
    },
    begin,
    end
  })
    .then(data => {
      const stats = {
        ...data,
        criteria: alias
      };

      return dispatch(
        getStatsSucceeded({
          widgetType,
          widgetId,
          stats
        })
      );
    })
    .catch(errors => {
      dispatch(getStatsFailure({ widgetType, widgetId, errors }));
      throw errors;
    });
};

export const getComparisonAmountChart = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps,
  alias
}) => dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const type = "sum";

  return getTransactionsAgg({
    restriction: "transaction",
    filters,
    aggregation: {
      name: alias,
      type: "term",
      field: `${alias}.raw`,
      size: 2147483647,
      subaggs: [
        {
          field: "transactionAmount",
          name: `${type}-on-transaction-amount`,
          type
        }
      ]
    },
    begin,
    end
  })
    .then(data => {
      const stats = {
        ...data,
        criteria: alias
      };

      return dispatch(
        getStatsSucceeded({
          widgetType,
          widgetId,
          stats
        })
      );
    })
    .catch(errors => {
      dispatch(getStatsFailure({ widgetType, widgetId, errors }));
      throw errors;
    });
};

export const getPaymentMethodsAmount = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const paymentMethodsAmount = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "cards",
        type: "term",
        field: "transactionType.raw",
        size: 2147483647,
        subaggs: [
          {
            field: "transactionAmount",
            name: "sum_by_cards_label",
            type: "sum"
          }
        ]
      },
      begin,
      end
    });
    const { cards: { buckets: transactionsTypes } = {} } = paymentMethodsAmount;

    const stats = { transactionsTypes };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getCardAverageBasket = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const averageBasket = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "cards",
        type: "filter",
        filters: [
          {
            filterType: "string",
            name: "transactionType",
            operator: "=",
            value: "CARD"
          }
        ],
        subaggs: [
          {
            field: "applicationLabel.raw",
            name: "by_cards_label",
            type: "term",
            size: 2147483647,
            subaggs: [
              {
                field: "transactionAmount",
                name: "avg_basket",
                type: "avg"
              }
            ]
          }
        ]
      },
      begin,
      end
    });

    const {
      cards: { by_cards_label: { buckets: cards = [] } = {} } = {}
    } = averageBasket;

    const stats = { cards };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getPaymentMethodsCount = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const paymentMethodsCount = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "cards",
        type: "term",
        field: "transactionType.raw",
        size: 2147483647
      },
      begin,
      end
    });
    const {
      cards: { buckets: countTransactionsTypes } = {}
    } = paymentMethodsCount;
    const stats = { countTransactionsTypes };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getWalletAverageBasket = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const walletsAverageBasket = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "wallets",
        type: "filter",
        filters: [
          {
            filterType: "string",
            name: "transactionType",
            operator: "=",
            value: "WALLET"
          }
        ],
        subaggs: [
          {
            field: "applicationLabel.raw",
            name: "by_wallets_label",
            type: "term",
            size: 2147483647,
            subaggs: [
              {
                field: "transactionAmount",
                name: "avg_basket",
                type: "avg"
              }
            ]
          }
        ]
      },
      begin,
      end
    });
    const {
      wallets: { by_wallets_label: { buckets = [] } = {} } = {}
    } = walletsAverageBasket;

    const stats = { walletsAverage: buckets };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getWalletsAmount = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const paymentMethodsWalletsAmount = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "wallets",
        type: "filter",
        filters: [
          {
            filterType: "string",
            name: "transactionType",
            operator: "=",
            value: "WALLET"
          }
        ],
        subaggs: [
          {
            field: "applicationLabel.raw",
            name: "by_wallets_label",
            type: "term",
            size: 2147483647,
            subaggs: [
              {
                field: "transactionAmount",
                name: "sum_wallet",
                type: "sum"
              }
            ]
          }
        ]
      },
      begin,
      end
    });
    const {
      wallets: { by_wallets_label: { buckets: walletsAmount = [] } = {} } = {}
    } = paymentMethodsWalletsAmount;
    const stats = { walletsAmount };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getWalletsCount = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const paymentMethodsWalletsCount = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "wallets",
        type: "filter",
        filters: [
          {
            filterType: "string",
            name: "transactionType",
            operator: "=",
            value: "WALLET"
          }
        ],
        subaggs: [
          {
            field: "applicationLabel.raw",
            name: "by_wallets_label",
            type: "term",
            size: 2147483647
          }
        ]
      },
      begin,
      end
    });

    const {
      wallets: { by_wallets_label: { buckets: walletsCount = [] } = {} } = {}
    } = paymentMethodsWalletsCount;
    const stats = { walletsCount };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getCardsCount = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const paymentMethodsCardsCount = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "cards",
        type: "filter",
        filters: [
          {
            filterType: "string",
            name: "transactionType",
            operator: "=",
            value: "CARD"
          }
        ],
        subaggs: [
          {
            field: "applicationLabel.raw",
            name: "by_cards_label",
            type: "term",
            size: 2147483647
          }
        ]
      },
      begin,
      end
    });

    const {
      cards: { by_cards_label: { buckets: cardsCount = [] } = {} } = {}
    } = paymentMethodsCardsCount;
    const stats = { cardsCount };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

export const getCardsAmount = ({
  widgetType,
  widgetId,
  filters = [],
  timestamps
}) => async dispatch => {
  dispatch(getStatsStart({ widgetType, widgetId }));

  const { min: begin, max: end } = timestamps;
  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  try {
    const paymentMethodsCardsAmount = await getTransactionsAgg({
      restriction: "transaction",
      filters: [...filters, ...defaultFilters],
      aggregation: {
        name: "cards",
        type: "filter",
        filters: [
          {
            filterType: "string",
            name: "transactionType",
            operator: "=",
            value: "CARD"
          }
        ],
        subaggs: [
          {
            field: "applicationLabel.raw",
            name: "by_cards_label",
            type: "term",
            size: 2147483647,
            subaggs: [
              {
                field: "transactionAmount",
                name: "sum_cards",
                type: "sum"
              }
            ]
          }
        ]
      },
      begin,
      end
    });
    const {
      cards: {
        by_cards_label: { buckets: cardsAmount }
      }
    } = paymentMethodsCardsAmount;
    const stats = { cardsAmount };

    dispatch(
      getStatsSucceeded({
        widgetType,
        widgetId,
        stats
      })
    );
  } catch (errors) {
    dispatch(getStatsFailure({ widgetType, widgetId, errors }));
    throw errors;
  }
};

const getStatsStart = ({ widgetType, widgetId }) => {
  const name = constantCase(widgetType);

  return {
    name,
    type: `PENDING_GET_STATS_ACTION_${name}`,
    payload: {
      widgetId
    }
  };
};

const getStatsSucceeded = ({ widgetType, widgetId, stats }) => {
  const name = constantCase(widgetType);

  return {
    name,
    type: `SUCCESS_GET_STATS_ACTION_${name}`,
    payload: {
      widgetId,
      stats
    }
  };
};

const getStatsFailure = ({ widgetType, widgetId, errors }) => {
  const name = constantCase(widgetType);

  return {
    name,
    type: `FAILURE_GET_STATS_ACTION_${name}`,
    payload: {
      widgetId,
      errors
    }
  };
};

export const resetWidgetsStatsState = () => ({
  type: RESET_WIDGETS_STATS_STATE_ACTION
});

export const resetWidgetStatsState = ({ widgetId }) => ({
  type: RESET_STATS_STATE_ACTION,
  payload: {
    widgetId
  }
});
