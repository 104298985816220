import { constantCase } from "constant-case";
import F from "../../commons/HTTPFetcher";

export const getScroll = ({ type, scrollId }) => dispatch => {
  // https://github.com/bvaughn/react-virtualized/issues/1056#issuecomment-373777895
  dispatch(getScrollStart({ type }));

  return F.get(`/${type}/search/${scrollId}`).then(
    data => {
      dispatch(getScrollSucceeded({ type, data }));

      return data;
    },
    errors => {
      dispatch(getScrollFailure({ type, errors }));

      throw errors;
    }
  );
};

export const getScrollStart = ({ type: scrollType }) => {
  const type = `PENDING_GET_${constantCase(scrollType)}_SCROLL_ACTION`;

  return {
    type
  };
};

export const getScrollSucceeded = ({ type: scrollType, data }) => {
  const type = `SUCCESS_GET_${constantCase(scrollType)}_SCROLL_ACTION`;

  return {
    type,
    payload: {
      data
    }
  };
};

export const getScrollFailure = ({ type: scrollType, errors }) => {
  const type = `FAILURE_GET_${constantCase(scrollType)}_SCROLL_ACTION`;

  return {
    type,
    payload: {
      errors
    }
  };
};
