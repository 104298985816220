import React, { Component } from "react";
import classNames from "classnames";

import Div100vh from "../../components/Div100vh/Div100vh";
import NotificationContainer from "../../notifications/components/NotificationContainer";
import I18nSpan from "../../i18n/components/I18nSpan";
import LogoLogin from "../../../assets/images/logo_login.mobile.svg";
import ReCaptcha from "./ReCaptcha";

import styles from "../styles/styles.mobile.css";

interface Props {
  history: any;
  customCss: any;
  apiGatewayCscURL: any;
  getCustomCss: Function;
  getConfig: Function;
}

export class LoginMobileWrapper extends Component<Props, {}> {
  render() {
    const { children } = this.props;

    return (
      <Div100vh>
        <div className={classNames("login-page", styles["login-page"])}>
          <div className={styles["login-holder-header"]}>
            <p className={styles["login-logo-container"]} />
            <h1 className={styles.welcometo}>
              <I18nSpan msgKey="login.welcome" />
            </h1>
            <h1 className={styles.welcomemsh}>
              <I18nSpan msgKey="login.logo" />
            </h1>
          </div>
          <div className={styles["login-box"]}>{children}</div>
          <div className={styles["logo-ingenico"]}>
            <LogoLogin width="100%" />
          </div>
          <ReCaptcha />
          <NotificationContainer />
        </div>
      </Div100vh>
    );
  }
}
