import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../../i18n/components/I18nSpan";
import FormTitle from "../../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../../ingenicoForm/components/BootstrapInput";
import Validators from "../../../ingenicoForm/validation/Validators";

import {
  addNotificationError,
  addNotificationSuccess,
  getTemplate,
  resetTemplateState,
  updateTemplate
} from "../../../redux/actions";

const PlaceHolders = ({ placeHolders }: any) => {
  return (
    <ul className="listItems">
      {placeHolders.map(placeholder => (
        <li key={placeholder} className="pvs">{`{${placeholder}}`}</li>
      ))}
    </ul>
  );
};

const EMBEDDED_PLACEHOLDERS = {
  scheduledReport: ["transactions_block"]
};

interface Props {
  templateId: any;
  getTemplate: Function;
  resetTemplate: Function;
  updateTemplate: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
}

interface Form {
  subject: string | null;
  body: string | null;
  embeddedFields: any;
  placeHolders: Array<any>;
}

interface State {
  form: Form;
  embeddedPlaceholders: Array<any>;
  errors: any;
}

class EditEmailTemplateView extends Component<Props, State> {
  state = {
    form: {
      subject: null,
      body: null,
      embeddedFields: {},
      placeHolders: []
    },
    embeddedPlaceholders: [],
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(newProps: any) {
    const { form, errors, embeddedPlaceholders } = newProps;

    return this.setState({
      form,
      errors,
      embeddedPlaceholders
    });
  }

  _goToList() {
    this.props.history.push("/main/settings/templates/email");
  }

  componentDidMount() {
    const { getTemplate, templateId } = this.props;
    getTemplate({ templateId });
  }

  componentWillUnmount() {
    const { resetTemplate } = this.props;
    resetTemplate();
  }

  _saveTemplate = event => {
    event.preventDefault();

    const {
      templateId,
      updateTemplate,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const { form, embeddedPlaceholders, errors } = this.state;
    const { body, subject } = form;

    this._checkErrors(async () => {
      const hasErrors = _.any(form, (value, name) => {
        const error = errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const embeddedFragments = embeddedPlaceholders.reduce(
          (acc, current) => {
            return {
              ...acc,
              [current]: form[current]
            };
          },
          {}
        );

        const template = {
          body,
          embeddedFragments,
          subject
        };

        return updateTemplate({ templateId, template }).then(
          data => {
            addNotificationSuccess("customer.settings.templates.form.success");
            this._goToList();
          },
          () => {
            addNotificationError("customer.settings.templates.error.invalid");
          }
        );
      }
    });
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  _onChangeHandler = (name: string, value: string) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this._checkError(name));
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _checkError(name: string) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  }

  render() {
    const { form, errors, embeddedPlaceholders } = this.state;
    const { templateId } = this.props;

    return (
      <div>
        <FormTitle
          titleKey="customer.settings.title"
          actionKey="customer.settings.templates.action"
        />

        <div className="updatePasswordForm-container">
          <form
            className="ingenico-form form-horizontal edit-template-form"
            noValidate
            encType="application/x-www-form-urlencoded"
            onSubmit={this._saveTemplate}
          >
            <div className="form-group">
              <label className="control-label col-sm-3">
                <span>
                  <I18nSpan msgKey="customer.settings.templates.form.id" />
                </span>
                <sup className="required-label" />
              </label>
              <div className="col-sm-6 ptxs mtxs">
                <span>
                  <I18nSpan
                    msgKey={`customer.settings.templates.titles.${templateId}`}
                  />
                </span>
              </div>
            </div>

            <BootstrapInput
              validation={value =>
                Promise.resolve(Validators.requiredValidator(value))
              }
              onChange={this._onChangeHandler}
              inputRef={this.setInputRef("subject")}
              name="subject"
              errors={errors.subject}
              required={true}
              descriptor={{
                type: "text",
                label: "customer.settings.templates.form.subject",
                placeholder: "customer.settings.templates.form.subject"
              }}
              formValue={form.subject}
            />

            <BootstrapInput
              validation={value =>
                Promise.resolve(Validators.requiredValidator(value))
              }
              onChange={this._onChangeHandler}
              inputRef={this.setInputRef("body")}
              name="body"
              errors={errors.body}
              required={true}
              descriptor={{
                type: "textarea",
                label: "customer.settings.templates.form.body",
                placeholder: "customer.settings.templates.form.body"
              }}
              formValue={form.body}
            />

            {embeddedPlaceholders.map(embeddedPlaceholder => {
              return (
                <BootstrapInput
                  key={`embeddedField-${embeddedPlaceholder}`}
                  validation={value =>
                    Promise.resolve(Validators.requiredValidator(value))
                  }
                  onChange={this._onChangeHandler}
                  inputRef={this.setInputRef(embeddedPlaceholder)}
                  name={embeddedPlaceholder}
                  errors={errors[embeddedPlaceholder]}
                  required={true}
                  descriptor={{
                    type: "textarea",
                    label: `customer.settings.templates.form.embeddedFragments.${embeddedPlaceholder}`,
                    placeholder: `customer.settings.templates.form.embeddedFragments.${embeddedPlaceholder}`
                  }}
                  formValue={form[embeddedPlaceholder]}
                />
              );
            })}

            <div className="form-group">
              <label className="control-label col-sm-3">
                <span>
                  <I18nSpan msgKey="customer.settings.templates.form.placeholders" />
                </span>
              </label>
              <div className="col-sm-6" id="content">
                <PlaceHolders placeHolders={form.placeHolders} />
              </div>
            </div>

            <div className="form-group">
              <div className="col-sm-12 text-right">
                <button className="btn btn-ingenico save-button" type="submit">
                  <span>
                    <I18nSpan msgKey="customer.settings.templates.form.save" />
                  </span>
                </button>
                <Link
                  to={"/main/settings/templates/email"}
                  className="btn btn-ingenico save-button"
                >
                  <span>
                    <I18nSpan msgKey="button.label.exit" />
                  </span>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { templateId }
    }
  } = ownProps;

  const {
    template: { data: template, loading }
  } = state;

  const { body, embeddedFragments = {}, subject, placeHolders = [] } = template;

  const { [templateId]: embeddedPlaceholders = [] } = EMBEDDED_PLACEHOLDERS;

  const embeddedFields = embeddedPlaceholders.reduce((acc, embeddedField) => {
    const { [embeddedField]: fragment = "" } = embeddedFragments;
    return {
      ...acc,
      [embeddedField]: fragment
    };
  }, {});

  const form = {
    body,
    subject,
    placeHolders,
    ...embeddedFields
  };

  return {
    loading,
    form,
    embeddedPlaceholders,
    errors: {},
    templateId
  };
};

const mapDispatchToProps = dispatch => ({
  getTemplate: ({ templateId }) =>
    dispatch(getTemplate({ templateId, templateType: "EMAIL" })),
  updateTemplate: ({ templateId, template }) =>
    dispatch(updateTemplate({ templateId, template, templateType: "EMAIL" })),
  resetTemplate: () => dispatch(resetTemplateState({ templateType: "EMAIL" })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export const EditEmailTemplateViewWithRouter = withRouter(
  EditEmailTemplateView
);

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(EditEmailTemplateViewWithRouter);
