import React, { Component } from "react";
import { connect } from "react-redux";

import TopCustomersList from "./TopCustomersList";
import { getData } from "../../utils/DataProvider";
import { WidgetConfig } from "../Widget";
import LoadingChart from "./LoadingChart";

interface Data {
  count: number;
}

interface Props {
  getData: Function;
  data: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  id: string;
  config: WidgetConfig;
}

interface State {}

class TopCustomersByAmountList extends Component<Props, State> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ config });
    }
  }

  render() {
    const {
      data: { topByAmount = [] },
      color,
      loading,
      config
    } = this.props;
    const { userCurrency = {} } = config;
    const { currency, currencyDecimal, currencyCodeAlpha3 } = userCurrency;

    const customersTop = topByAmount.map(top => {
      return {
        ...top,
        currency,
        currencyCodeAlpha3,
        currencyDecimal
      };
    });

    return (
      <LoadingChart loading={loading} color={color}>
        <TopCustomersList
          chartPrefix="reporting.customers.topbyamountlist"
          customersTop={customersTop}
        />
      </LoadingChart>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }
  return {
    data: {},
    loading: true
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopCustomersByAmountList);
