import React, { Component } from "react";
import { connect } from "react-redux";
import I18nSpan from "../i18n/components/I18nSpan";
import BootstrapInput from "../ingenicoForm/components/BootstrapInput";
import {
  getSecondFactors,
  deleteSecondFactor,
  addSecondFactor,
  addNotificationError,
  addNotificationSuccess
} from "../redux/actions";
import { userErrorNotification } from "../user/validations/UserValidations";

interface Props {
  getSecondFactors: Function;
  onClosePopin: Function;
  addSecondFactor: Function;
  deleteSecondFactor: Function;
  addNotificationError: Function;
  secondFactorsOptions: any;
  userId: any;
}

interface State {
  supportedFactor: any;
}

class SelectFactorPopin extends Component<Props, State> {
  state = {
    supportedFactor: ""
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentDidMount() {
    const { getSecondFactors } = this.props;

    getSecondFactors();
  }

  _onSecondFactorChange = (name, value) => {
    this.setState({ supportedFactor: value });
  };

  _updateSecondFactor = () => {
    const {
      addSecondFactor,
      deleteSecondFactor,
      onClosePopin,
      addNotificationError,
      addNotificationSuccess,
      userId
    } = this.props;
    const { supportedFactor } = this.state;

    supportedFactor === ""
      ? deleteSecondFactor({ userId })
          .then(() => {
            addNotificationSuccess("user.twoFactor.delete.success");
            return onClosePopin();
          })
          .catch((error: any) => {
            userErrorNotification({
              error,
              addNotificationError,
              form: userId
            });
            return onClosePopin();
          })
      : addSecondFactor({ userId, supportedFactor })
          .then(() => {
            addNotificationSuccess("user.twoFactor.add.success");
            return onClosePopin();
          })
          .catch((error: any) => {
            userErrorNotification({
              error,
              addNotificationError,
              form: userId
            });
            return onClosePopin();
          });
  };

  render() {
    const { onClosePopin, secondFactorsOptions, required } = this.props;
    const { supportedFactor } = this.state;

    return (
      <div>
        <div className="popin">
          <div className="popinContent">
            <a onClick={onClosePopin} className="closeButton" />
            <div className="popinTitle">
              <I18nSpan msgKey="popin.SelectSecondFactor" />
            </div>

            <div className="export-dataType">
              <BootstrapInput
                inputRef={this.setInputRef("supportedFactor")}
                name="supportedFactor"
                formValue={supportedFactor}
                required={required}
                onChange={this._onSecondFactorChange}
                descriptor={{
                  type: "select",
                  className: "serviceSet mtn",
                  options: secondFactorsOptions
                }}
              />
            </div>

            <div className="button-list">
              <li>
                <a onClick={onClosePopin} className="btn btn-ingenico">
                  <I18nSpan msgKey="popin.button.cancel" />
                </a>
              </li>
              <li>
                <a
                  onClick={this._updateSecondFactor}
                  className="btn btn-ingenico"
                >
                  <I18nSpan msgKey="popin.button.save" />
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    secondFactors: { data: secondFactorsOptions }
  } = state;

  return {
    secondFactorsOptions
  };
};

const mapDispatchToProps = dispatch => ({
  getSecondFactors: () => dispatch(getSecondFactors()),
  deleteSecondFactor: ({ userId }) => dispatch(deleteSecondFactor({ userId })),
  addSecondFactor: ({ userId, supportedFactor }) =>
    dispatch(addSecondFactor({ userId, supportedFactor })),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectFactorPopin);
