import React, { Component } from "react";
import { withRouter } from "react-router";
import _ from "lodash";
import sanitizeHtml from "sanitize-html";
import { connect } from "react-redux";
import { compose } from "redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";

import FormTitle from "../../ingenicoForm/components/FormTitle";

import Validators from "../../ingenicoForm/validation/Validators";
import {
  updateSettings,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

const FormValidators = {
  required(value) {
    return Promise.resolve(Validators.requiredValidator(value));
  },

  noop() {
    return Promise.resolve(null);
  }
};

interface Props {
  history: any;
}

interface State {
  form: any;
}

class SettingsCustomerView extends Component<Props, State> {
  state = {
    form: this.props.form,
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  _updateCustomer = e => {
    e.preventDefault();

    const {
      updateSettings,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const {
      color,
      ticketTemplate,
      commercialContact,
      supportContact
    } = this.state.form;
    const cleanTicketTemplate = sanitizeHtml(ticketTemplate, {
      allowedTags: ["div", "img"],
      allowedAttributes: {
        div: ["style"],
        img: ["width", "style", "src"]
      },
      allowedSchemesByTag: {
        img: ["data"]
      }
    });
    const settings = {
      color,
      ticketTemplate: cleanTicketTemplate,
      commercialContact,
      supportContact
    };

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        updateSettings(settings).then(
          () => {
            addNotificationSuccess("customer.settings.success");
            // this._goToHome();
          },
          () => addNotificationError("notification.error")
        );
      }
    });
  };

  _goToHome = () => {
    this.props.history.push("/main");
  };

  _onChangeHandler = (name, value) => {
    const updatedForm = this.state.form;
    updatedForm[name] = value;
    this.setState(updatedForm, this._checkError(name));
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [FormValidators.noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _checkError(name: string) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  }

  render() {
    const { form, errors } = this.state;

    return (
      <div>
        <FormTitle
          titleKey="customer.settings.title"
          actionKey="customer.settings.action"
        />
        <form className="ingenico-form form-horizontal customer-form">
          <BootstrapInput
            validation={FormValidators.required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("color")}
            name="color"
            errors={errors.color}
            required={true}
            descriptor={{
              type: "text",
              label: "customer.form.color.label"
            }}
            formValue={form.color}
          />
          <BootstrapInput
            validation={FormValidators.noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("ticketTemplate")}
            name="ticketTemplate"
            descriptor={{
              type: "textarea",
              label: "customer.form.receiptTemplate.label"
            }}
            formValue={form.ticketTemplate}
          />
          <BootstrapInput
            validation={FormValidators.noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("commercialContact")}
            name="commercialContact"
            required={false}
            placeholder="customer.form.commercialContact.label"
            descriptor={{
              type: "text",
              label: "customer.form.commercialContact.label"
            }}
            formValue={form.commercialContact}
          />
          <BootstrapInput
            validation={FormValidators.noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("supportContact")}
            name="supportContact"
            placeholder="customer.form.supportContact.placeholder"
            descriptor={{
              type: "text",
              label: "customer.form.supportContact.label"
            }}
            formValue={form.supportContact}
          />
          <BootstrapInput
            validation={FormValidators.noop}
            onChange={() => null}
            inputRef={this.setInputRef("replyTo")}
            name="replyTo"
            readOnly={true}
            descriptor={{
              type: "text",
              readOnly: "true",
              label: "customer.form.replyTo.label"
            }}
            formValue={form.replyTo}
          />
          <BootstrapInput
            validation={FormValidators.noop}
            onChange={() => null}
            inputRef={this.setInputRef("selectedCurrency")}
            name="selectedCurrency"
            readOnly={true}
            descriptor={{
              type: "text",
              readOnly: "true",
              label: "customer.form.currency.label"
            }}
            formValue={form.selectedCurrency}
          />
          <div className="pull-right">
            <button
              onClick={this._updateCustomer}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToHome}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { levelSettings: customerSettings }
  } = state;

  const settings = customerSettings
    ? {
        color: customerSettings.color,
        ticketTemplate: customerSettings.ticketTemplate,
        commercialContact: customerSettings.commercialContact,
        supportContact: customerSettings.supportContact,
        replyTo: customerSettings.replyTo,
        selectedCurrency: `${customerSettings.currency.alpha3} - ${customerSettings.currency.num}`
      }
    : {};

  return {
    form: _.clone(settings)
  };
};

const mapDispatchToProps = dispatch => ({
  updateSettings: data => dispatch(updateSettings(data)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsCustomerView);
