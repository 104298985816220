import { constantCase } from "constant-case";
import F from "../../commons/HTTPFetcher";
import constants from "../../advancedSearch/constants/AdvancedSearchConstants";

export const getValues = ({ name: fieldType, field, fields }) => dispatch => {
  dispatch(getValuesStart(fieldType));
  switch (fieldType) {
    case constants.STORE_KEY: {
      return F.get(`/stores/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.MERCHANT_KEY: {
      return F.get(`/merchants/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.POS_KEY: {
      return F.get(`/pos/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.POI_KEY:
    case constants.SETTINGS_TERMINAL_KEY: {
      return F.get(`/poi/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case "catalog": {
      return F.get(`/catalog/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.MONITORING_TERMINAL_KEY: {
      return F.get(`/poi-monitoring/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.AUDIT_KEY: {
      return F.get(`/audit/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.SIM_NEW_KEY: {
      return F.get(`/sim-monitoring/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.SIM_KEY: {
      return F.get(`/sims/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.TRANSACTION_KEY:
    case constants.WIDGET_KEY:
    case constants.REPORT_KEY:
    case constants.ALERT_KEY: {
      return F.get(`/transaction/values?field=${field}`, "v2").then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.RECONCILIATION_KEY: {
      return F.get(`/reconciliation/values?field=${field}`, "v2").then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.SALE_KEY: {
      return F.get(`/sale/values?field=${field}`).then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    case constants.TAX_AUDIT: {
      return F.get(`/taxaudit/values?field=${field}`, "v2").then(
        values => {
          dispatch(getValuesSucceeded({ fieldType, values }));
          return values;
        },
        errors => {
          dispatch(getValuesFailure({ fieldType, errors }));
          throw errors;
        }
      );
    }
    default: {
      break;
    }
  }
};

const getValuesStart = fieldType => {
  const name = constantCase(fieldType);

  return {
    name,
    type: `PENDING_GET_VALUES_ACTION_${name}`
  };
};

const getValuesSucceeded = ({ fieldType, values }) => {
  const name = constantCase(fieldType);

  return {
    name,
    type: `SUCCESS_GET_VALUES_ACTION_${name}`,
    payload: {
      values
    }
  };
};

const getValuesFailure = ({ fieldType, errors }) => {
  const name = constantCase(fieldType);

  return {
    name,
    type: `FAILURE_GET_VALUES_ACTION_${name}`,
    payload: {
      errors
    }
  };
};
