import React, { Component } from "react";
import * as _ from "lodash";
import ErrorsList from "../validation/ErrorsList";

interface Props {
  inputClass: string;
  name: string;
  id: string;
  className: string;
  fields: string;
  onChange: Function;
  formValue: any;
  readonly: boolean;
}

class MultipleSelectWithValue extends Component<Props, {}> {
  state = {
    sortedFields: []
  };

  componentWillReceiveProps() {
    const { fields } = this.props;

    this.setState({
      sortedFields: _.sortBy(fields)
    });
  }

  _onChange(oldValue, e) {
    const { formValue: newFormValue } = this.props;
    newFormValue.name = e.target.value;
    this._onChangeValue("formValue", newFormValue);
  }

  _displayValue(oldValue, e) {
    const { formValue: newFormValue } = this.props;
    newFormValue.value = e.target.value;
    this._onChangeValue("formValue", newFormValue);
  }

  _onChangeValue(name, newValue) {
    const { onChange } = this.props;
    const newState = {};
    newState[name] = newValue;
    return this.setState(newState, () => onChange(this.props.name, newValue));
  }

  componentDidMount() {
    const { onChange, name } = this.props;

    return onChange(name, {});
  }

  render() {
    return (
      <div>
        {[this.state.sortedFields].map(fields => {
          const tagValues = fields;
          const formValue = this.props.formValue;
          const selectedField = formValue.name || "";
          const selectedValue = formValue.value || "";
          const errorMessages = _.isEmpty(this.props.errors) ? (
            ""
          ) : (
            <ErrorsList errors={this.props.errors} />
          );

          const component = (
            <select
              className="form-control"
              name={selectedField}
              value={selectedField}
              onChange={this._onChange.bind(this, selectedField)}
              disabled={this.props.readOnly}
            >
              {tagValues.map(value => {
                if (
                  typeof value.key === "string" &&
                  typeof value.value == "string"
                ) {
                  return (
                    <option key={value.key} value={value.value}>
                      {value.label}
                    </option>
                  );
                }
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          );

          return (
            <div key={selectedField} className="form-group">
              <div className="col-sm-6">{component}</div>
              <div className="col-sm-4">
                <input
                  type="text"
                  className="form-control input-type-text"
                  name={`text.${selectedValue}`}
                  value={selectedValue}
                  onChange={this._displayValue.bind(this, selectedValue)}
                  disabled={this.props.readOnly}
                />
                {errorMessages}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

MultipleSelectWithValue.defaultProps = {
  formValue: {}
};

export default MultipleSelectWithValue;
