import React from "react";

import styles from "./styles/Loading.css";

interface LoadingProps {
  isLoading: boolean;
  children: any;
}

const Loading = ({ isLoading, children }: LoadingProps) => {
  if (isLoading) {
    return (
      <div className={styles.loading}>
        <div className={styles.loadingTxt}>
          <div className={styles.spinner}>
            <div className={styles.bounce1} />
            <div className={styles.bounce2} />
            <div className={styles.bounce3} />
          </div>
        </div>
      </div>
    );
  }
  return <div>{children}</div>;
};

export default Loading;
