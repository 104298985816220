import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: "0.84vh"
  },
  input: {
    color: "white",
    fontSize: "14px",
    "&$focused": {
      color: "white"
    },
    "&:-webkit-autofill": {
      "-webkit-text-fill-color": "white",
      "-webkit-box-shadow": "inset 0 0 0 1000px #00c3d7",
      backgroundClip: "content-box" // https://stackoverflow.com/a/55080978
    }
  },
  inputButton: {
    color: "white"
  },
  underline: {
    color: "white",
    "&:before": {
      borderColor: "white"
    },
    "&:hover:not($disabled):before": {
      borderColor: "white"
    },
    "&:after": {
      borderColor: "white"
    }
  },
  focused: {},
  disabled: {}
});

export default function PasswordInput({
  name = "password",
  label,
  showPassword,
  onShowPassword,
  onChange,
  value
}) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      id={name}
      name={name}
      type={showPassword ? "text" : "password"}
      label={label}
      onChange={onChange}
      InputLabelProps={{
        classes: {
          root: classes.input,
          focused: classes.focused
        }
      }}
      InputProps={{
        classes: {
          root: classes.input,
          disabled: classes.disabled,
          underline: classes.underline,
          input: classes.input
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={classes.inputButton}
              aria-label="toggle password visibility"
              onClick={onShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
      value={value}
    />
  );
}
