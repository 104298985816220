import {
  RESET_POI_STATE_ACTION,
  PENDING_GET_POI_ACTION,
  SUCCESS_GET_POI_ACTION,
  FAILURE_GET_POI_ACTION
} from "../actionsTypes";

const initialState = {
  data: {},
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_POI_ACTION: {
      return {
        ...state,
        loading: true,
        error: false,
        errors: {}
      };
    }
    case SUCCESS_GET_POI_ACTION: {
      const {
        payload: { poi }
      } = action;

      return {
        data: poi,
        loading: false,
        error: false,
        errors: {}
      };
    }
    case FAILURE_GET_POI_ACTION: {
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    }
    case RESET_POI_STATE_ACTION:
      return initialState;
    default:
      return state;
  }
};
