import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";

import Menu from "../../menu/components/Menu";

import { LogoMarkup } from "./LogoMarkup";

import styles from "./../styles/styles.css";
import { collapseMenu, getVersion } from "../../redux/actions";
import { WhiteLabel } from "./WhiteLabel/WhiteLabel";

interface Props {
  collapseMenu: Function;
  collapsed: boolean;
  version: string;
}

interface State {}

class Sidebar extends Component<Props, State> {
  componentDidMount() {
    const { getVersion } = this.props;

    window.addEventListener("resize", this.matchMedia);
    getVersion();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.matchMedia);
  }

  matchMedia = () => {
    const match = window.matchMedia(`(max-width: 768px)`);
    this.updateSidebarStore(match.matches);
  };

  updateSidebarStore(matchMedia) {
    const { collapseMenu } = this.props;

    if (matchMedia) {
      return collapseMenu({ collapsed: false });
    }
  }

  render() {
    const { version, collapsed } = this.props;

    const sidebarClasses = classNames(
      "sidebar",
      "menu",
      "nav",
      { [styles.menu]: !collapsed },
      { [styles.opened]: collapsed }
    );

    return (
      <div className={sidebarClasses}>
        <LogoMarkup />
        <div className="menu-wrapper">
          <Menu />
        </div>
        <AppVersion version={version} />
        <WhiteLabel />
      </div>
    );
  }
}

const AppVersion = ({ version }) => {
  if (version) {
    return <p className="version">{version}</p>;
  }
  return null;
};

const mapStateToProps = (state, ownProps) => {
  const {
    menu: { collapsed },
    version: { data: version }
  } = state;

  return {
    collapsed,
    version
  };
};

const mapDispatchToProps = dispatch => ({
  collapseMenu: ({ collapsed }) => dispatch(collapseMenu({ collapsed })),
  getVersion: () => dispatch(getVersion())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
