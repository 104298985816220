import React, { Component } from "react";
import * as momentTimezone from "moment-timezone";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";

import { WidgetConfig } from "../Widget";
import dateFormatter from "../../../formatters/DateFormatter";
import NumberFormatter from "../../../formatters/NumberFormatter";
import AmountApexChart from "../../../chart/components/chart/AmountApexChart";

import { getData } from "../../utils/DataProvider";
import LoadingChart from "./LoadingChart";
import Utils from "../../../sim/Utils";

import style from "./style/TransactionRevenueChart.css";

interface Data {
  stats?: any;
  currency: any;
  currencyDecimal: any;
}

interface Props {
  getData: Function;
  data: Data;
  loading: boolean;
  error?: boolean;
  title: string;
  color: string;
  config: WidgetConfig;
  user: any;
  customerSettings: any;
}

class TransactionRevenueChart extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getData({ config });
    }
  }

  _buildConfigFromProps({ timePeriod, currencyDecimal, transactions, color }) {
    const {
      user,
      customerSettings,
      t,
      defaultPaletteId,
      palettes,
      config: { defaultPaletteId: palette }
    } = this.props;

    const widgetPalette = palette ? palette : defaultPaletteId;
    const initialPalette = Utils.findPaletteById(widgetPalette, palettes);

    const isExistingCurrency = customerSettings && customerSettings.currency;
    const currency = isExistingCurrency
      ? customerSettings.currency.alpha3 + customerSettings.currency.symbol
      : "?";

    const chartPrefix = `reporting.activity.transactions.${timePeriod.type}`;

    const startTime = dateFormatter.getTimezonedDate(
      timePeriod.startTime,
      user
    );
    const endTime = dateFormatter.getTimezonedDate(timePeriod.endTime, user);

    return {
      title: {
        text: ""
      },
      dataLabels: {
        enabled: false
      },
      noData: {
        text: t("apexCharts.noData")
      },
      chart: {
        toolbar: {
          autoSelected: "selection"
        }
      },
      xaxis: {
        type: "datetime",
        title: {
          text: t(`${chartPrefix}.xAxis.title`),
          offsetY: 10
        },
        min: startTime,
        max: endTime,
        labels: {
          rotate: -45,
          style: {
            colors: "black",
            fontFamily: "tahoma"
          }
        }
      },
      yaxis: {
        title: {
          text: t("reporting.activity.transactions.yAxis.title")
        },
        labels: {
          formatter: function(value) {
            return NumberFormatter.formatAmount(
              value,
              currency,
              currencyDecimal
            );
          }
        }
      },
      tooltip: {
        y: {
          formatter: function(value) {
            return NumberFormatter.formatAmount(
              value,
              currency,
              currencyDecimal
            );
          }
        }
      },
      series: transactions.map(({ data }) => {
        const { timezone } = user;

        // https://github.com/apexcharts/apexcharts.js/issues/631#issuecomment-611593641
        const dataWithLocalTimestamp = data.map(([timestamp, value]) => {
          const utcOffset =
            momentTimezone.tz(timestamp, timezone).utcOffset() * 60000;
          const localizedTimestamp = timestamp + utcOffset;

          return [localizedTimestamp, value];
        });

        return {
          data: dataWithLocalTimestamp,
          name: t(`${chartPrefix}.bar.description`)
        };
      }),
      ...(initialPalette && { colors: [...initialPalette.colors] })
    };
  }

  render() {
    const { data = {}, color, loading, config } = this.props;
    const { timePeriod } = config;

    const { stats = [], currencyDecimal } = data as Data;

    const transactions = [
      {
        data: stats.map(point => [point.timestamp, point.value])
      }
    ];

    const { series, ...options } = this._buildConfigFromProps({
      timePeriod,
      currencyDecimal,
      transactions,
      color
    });

    return (
      <LoadingChart loading={loading} color={color}>
        <AmountApexChart options={options} series={series} type="bar" />
        <style>
          {`
                  /*Style loader*/
                  .load-bar,
                  .spinner .bounce1,
                  .spinner .bounce3,
                  .load-bar .bar2 {background-color:${color}!important;}
                  .spinner .bounce2{background-color:#FFFFFF!important;}
                  .load-bar .bar1 {background-color:${color};}
                  .load-bar .bar3 {background-color:#D2D7D3;}

              `}
        </style>
      </LoadingChart>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, levelSettings: customerSettings },
    palettes: { data: palettes = [] }
  } = state;
  const { id } = ownProps;
  const {
    widgets: { [id]: widget }
  } = state.widgetData;
  const { defaultPaletteId } = user;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      customerSettings,
      data: stats,
      loading,
      error,
      palettes,
      defaultPaletteId,
      user
    };
  }

  return {
    user,
    customerSettings,
    data: {},
    loading: true
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(TransactionRevenueChart);
