import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import { constantCase } from "constant-case";

export const updateTemplate = ({
  templateId,
  template,
  templateType
}) => dispatch => {
  dispatch(updateTemplateStart({ templateType }));

  return F.put(`/customer/template/${templateId}`, {
    body: template,
    contentType: ContentTypes.JSON
  })
    .then(() => dispatch(updateTemplateSucceeded({ templateType })))
    .catch(errors => {
      dispatch(updateTemplateFailure({ templateType, errors }));
      throw errors;
    });
};

const updateTemplateStart = ({ templateType }) => {
  const name = constantCase(templateType);
  return {
    name,
    type: `PENDING_UPDATE_${name}_TEMPLATE_ACTION`
  };
};

const updateTemplateSucceeded = ({ templateType }) => {
  const name = constantCase(templateType);
  return {
    name,
    type: `SUCCESS_UPDATE_${name}_TEMPLATE_ACTION`
  };
};

const updateTemplateFailure = ({ templateType, errors }) => {
  const name = constantCase(templateType);
  return {
    type: `FAILURE_UPDATE_${name}_TEMPLATE_ACTION`,
    payload: {
      errors
    }
  };
};

export const resetTemplateState = ({ templateType }) => {
  const name = constantCase(templateType);
  return {
    type: `RESET_${name}_TEMPLATE_STATE_ACTION`
  };
};

export const getTemplate = ({ templateId, templateType }) => dispatch => {
  dispatch(getTemplateStart({ templateType }));

  const url = `/customer/template/${templateId}`;
  return F.get(url)
    .then(template => {
      dispatch(getTemplateSucceeded({ template, templateType }));
      return template;
    })
    .catch(errors => {
      dispatch(getTemplateFailure({ templateType, errors }));
      throw errors;
    });
};

const getTemplateStart = ({ templateType }) => {
  const name = constantCase(templateType);
  return {
    name,
    type: `PENDING_GET_${name}_TEMPLATE_ACTION`
  };
};

const getTemplateSucceeded = ({ templateType, template }) => {
  const name = constantCase(templateType);
  return {
    name,
    type: `SUCCESS_GET_${name}_TEMPLATE_ACTION`,
    payload: {
      template
    }
  };
};

const getTemplateFailure = ({ templateType, errors }) => {
  const name = constantCase(templateType);
  return {
    type: `FAILURE_GET_${name}_TEMPLATE_ACTION`,
    payload: {
      errors
    }
  };
};
