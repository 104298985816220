import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import Customer from "../../customer/models/Customer";
import SettlementSettings from "../../customer/models/SettlementSettings";
import SmsSettings from "../../customer/models/SmsSettings";
import AlipayReconciliationSettings from "../../customer/models/AlipayReconciliationSettings";

import {
  FAILURE_CREATE_CUSTOMER_ACTION,
  PENDING_CREATE_CUSTOMER_ACTION,
  SUCCESS_CREATE_CUSTOMER_ACTION,
  RESET_CUSTOMER_STATE_ACTION,
  PENDING_GET_CUSTOMER_ACTION,
  SUCCESS_GET_CUSTOMER_ACTION,
  FAILURE_GET_CUSTOMER_ACTION,
  FAILURE_UPDATE_CUSTOMER_ACTION,
  PENDING_UPDATE_CUSTOMER_ACTION,
  SUCCESS_UPDATE_CUSTOMER_ACTION,
  FAILURE_UPDATE_CUSTOMER_STATUS_ACTION,
  PENDING_UPDATE_CUSTOMER_STATUS_ACTION,
  SUCCESS_UPDATE_CUSTOMER_STATUS_ACTION
} from "../actionsTypes";

const replace = function(key, value) {
  if (key === "services" && !_.isArray(value)) {
    return [];
  }
  return value;
};

export const createCustomer = ({
  customer: customerForm
}) => async dispatch => {
  dispatch(createCustomerStart());

  let customer: any = {
    customer: Customer.buildFromForm(customerForm)
  };

  if (customerForm.settlementConfiguration) {
    customer.settlementSettings = SettlementSettings.buildFromForm(
      customerForm.settlement
    );
  }

  if (customerForm.alipayReconciliationConfiguration) {
    customer.alipayReconciliationSettings = AlipayReconciliationSettings.buildFromForm(
      customerForm.alipayReconciliation
    );
  }

  if (customerForm.smsProvider) {
    customer.smsSettings = SmsSettings.buildFromForm(customerForm);
  }

  const customerJson = JSON.parse(JSON.stringify(customer, replace));

  return F.post("/customer", {
    body: customerJson,
    contentType: ContentTypes.JSON
  }).then(
    createdCustomer => {
      dispatch(createCustomerSucceeded());

      return createdCustomer;
    },
    errors => {
      dispatch(createCustomerFailure({ errors }));

      throw errors;
    }
  );
};

const createCustomerStart = () => ({
  type: PENDING_CREATE_CUSTOMER_ACTION
});

const createCustomerSucceeded = () => ({
  type: SUCCESS_CREATE_CUSTOMER_ACTION
});

const createCustomerFailure = errors => ({
  type: FAILURE_CREATE_CUSTOMER_ACTION,
  payload: {
    errors
  }
});

export const editCustomer = ({ customer: customerForm }) => async dispatch => {
  dispatch(editCustomerStart());

  let customerData = Customer.buildFromForm(customerForm);

  if (customerForm.settlementConfiguration) {
    const settlementSettingsData = {
      settlementSettings: SettlementSettings.buildFromForm(
        customerForm.settlement
      )
    };
    customerData = _.merge(customerData, settlementSettingsData);
  }

  if (customerForm.alipayReconciliationConfiguration) {
    const alipayReconciliationSettingsData = {
      alipayReconciliationSettings: AlipayReconciliationSettings.buildFromForm(
        customerForm.alipayReconciliation
      )
    };
    customerData = _.merge(customerData, alipayReconciliationSettingsData);
  }

  if (customerForm.smsProvider) {
    const smsSettingsData = {
      smsSettings: SmsSettings.buildFromForm(customerForm)
    };
    customerData = _.merge(customerData, smsSettingsData);
  }

  return F.put(`/customer/${customerData.id}`, {
    body: customerData,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(editCustomerSucceeded()),
    errors => {
      dispatch(editCustomerFailure({ errors }));

      throw errors;
    }
  );
};

const editCustomerStart = () => ({
  type: PENDING_UPDATE_CUSTOMER_ACTION
});

const editCustomerSucceeded = () => ({
  type: SUCCESS_UPDATE_CUSTOMER_ACTION
});

const editCustomerFailure = errors => ({
  type: FAILURE_UPDATE_CUSTOMER_ACTION,
  payload: {
    errors
  }
});

export const getCustomer = customerId => async dispatch => {
  dispatch(getCustomerStart());

  try {
    const customer = await F.get(`/customer/${customerId}`);
    let customerFromServer = Customer.wrapFromServer(customer);
    let customerForm = {
      ...customerFromServer,
      settlementConfiguration: false,
      settlement: {},
      alipayReconciliationConfiguration: false,
      alipayReconciliation: {}
    };

    if (customer.settings.settlementSettings) {
      customerForm.settlement = SettlementSettings.wrapFromServer(
        customer.settings.settlementSettings
      );
      customerForm.settlementConfiguration = true;
    } else {
      customerForm.settlement = { fileFormat: {} };
    }

    if (customer.settings.alipayReconciliationSettings) {
      customerForm.alipayReconciliation = AlipayReconciliationSettings.wrapFromServer(
        customer.settings.alipayReconciliationSettings
      );
      customerForm.alipayReconciliationConfiguration = true;
    }

    if (customer.settings.smsSettings) {
      const formSmsProvider = SmsSettings.wrapFromServer(
        customer.settings.smsSettings
      );
      customerForm = _.merge(customerForm, formSmsProvider);
    }

    return dispatch(getCustomerSucceeded(customerForm));
  } catch (errors) {
    dispatch(getCustomerFailure(errors));
    throw errors;
  }
};

const getCustomerStart = () => ({
  type: PENDING_GET_CUSTOMER_ACTION
});

const getCustomerSucceeded = customer => ({
  type: SUCCESS_GET_CUSTOMER_ACTION,
  payload: {
    customer
  }
});

const getCustomerFailure = errors => ({
  type: FAILURE_GET_CUSTOMER_ACTION,
  payload: {
    errors
  }
});

export const resetCustomerState = () => ({
  type: RESET_CUSTOMER_STATE_ACTION
});

const editCustomerStatusStart = () => ({
  type: PENDING_UPDATE_CUSTOMER_STATUS_ACTION
});

const editCustomerStatusSucceeded = ({ id, status }) => ({
  type: SUCCESS_UPDATE_CUSTOMER_STATUS_ACTION,
  payload: {
    id,
    status
  }
});

const editCustomerStatusFailure = errors => ({
  type: FAILURE_UPDATE_CUSTOMER_STATUS_ACTION,
  payload: {
    errors
  }
});

export const editCustomerStatus = ({ customerId, status }) => dispatch => {
  dispatch(editCustomerStatusStart());

  return F.put(`/customer/${customerId}/status/${status}`).then(
    res => {
      const { id, status } = res;
      dispatch(editCustomerStatusSucceeded({ id, status }));
    },
    errors => {
      dispatch(editCustomerStatusFailure(errors));

      throw errors;
    }
  );
};
