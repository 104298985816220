import React, { Component } from "react";

import NumberFormatter from "../../formatters/NumberFormatter";

interface Props {
  number: number;
  currency: string;
  currencyDecimal: number;
  currencyCodeAlpha3: string;
}

interface State {
  message: string;
}

class I18nAmount extends Component<Props, State> {
  static defaultProps = {
    number: 0,
    currency: "€",
    currencyDecimal: 2,
    currencyCodeAlpha3: ""
  };

  constructor(props) {
    super(props);

    this.state = this.renderMessage(props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.renderMessage(nextProps));
  }

  renderMessage(props) {
    return {
      message: NumberFormatter.formatAmount(
        props.number,
        props.currencyCodeAlpha3 + props.currency,
        props.currencyDecimal
      )
    };
  }

  render() {
    const { message } = this.state;

    return <span>{message}</span>;
  }
}

export default I18nAmount;
