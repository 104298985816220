import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";

import {
  PENDING_GET_AUDITS_ACTION,
  SUCCESS_GET_AUDITS_ACTION,
  FAILURE_GET_AUDITS_ACTION
} from "../actionsTypes";

export const getAudits = ({
  filters,
  fields,
  sort = { field: "timestamp.raw", order: "DESC" },
  tableCount: max,
  begin,
  end
}) => async dispatch => {
  dispatch(getAuditsStart());

  const url = new URL("/audit/search", PORTAL_URL);

  Object.entries({ max, begin, end }).forEach(param => {
    const [paramKey, paramValue] = param;

    if (paramValue) {
      return url.searchParams.append(paramKey, paramValue);
    }
  });

  return F.post(`${url.pathname}${url.search}`, {
    body: {
      filters,
      fields,
      sort
    },
    contentType: ContentTypes.JSON
  }).then(
    auditSearch => {
      const { count, scrollId, searchResults: audits } = auditSearch;

      return dispatch(getAuditsSucceeded({ count, scrollId, audits }));
    },
    errors => {
      dispatch(getAuditsFailure({ errors }));

      throw errors;
    }
  );
};

export const getAuditsStart = () => ({
  type: PENDING_GET_AUDITS_ACTION
});

export const getAuditsSucceeded = ({ count, scrollId, audits }) => ({
  type: SUCCESS_GET_AUDITS_ACTION,
  payload: {
    count,
    scrollId,
    audits
  }
});

export const getAuditsFailure = errors => ({
  type: FAILURE_GET_AUDITS_ACTION,
  payload: {
    errors
  }
});
