const Permissions = {
  MERCHANT_USER_MANAGEMENT: "merchantUserManagement",
  CUSTOMER_USER_MANAGEMENT: "customerUserManagement",
  MERCHANT_SETTINGS_MANAGEMENT: "merchantSettingsManagement",
  CUSTOMER_SETTINGS_MANAGEMENT: "customerSettingsManagement",
  TERMINAL_MANAGEMENT: "terminalManagement",
  SWAP_MANAGEMENT: "swapManagement",
  SIM_MANAGEMENT: "simManagement",
  USER_MANAGEMENT: "userManagement",
  PROGRAM_MANAGEMENT: "programManagement",
  TAG_MANAGEMENT: "tagManagement",
  POS_MANAGEMENT: "posManagement",
  STORE_MANAGEMENT: "storeManagement",
  MERCHANT_MANAGEMENT: "merchantManagement",
  MPOS_USER_MANAGEMENT: "mPosUserManagement",
  MACHINE_USER_MANAGEMENT: "machineUserManagement",
  MACHINE_ENROLLMENT: "machineEnrollment",
  MPOS_ENROLLMENT: "mPosEnrollment",
  IMPERSONATION: "impersonation",
  CATALOG_MANAGEMENT: "catalogManagement"
};
export default Permissions;
