import { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import F from "../../commons/HTTPFetcher";

import { getUserSettings, getLevelSettings } from "../../redux/actions";

class ToHomePage extends Component<any, any> {
  async componentDidMount() {
    try {
      const {
        getUserSettings,
        getLevelSettings,
        match: {
          params: { tokenValue }
        },
        location: { search }
      } = this.props;
      const params = new URLSearchParams(search);
      const newlocation = params.get("location");

      F.updateJwtFrom(tokenValue);

      await Promise.all([getUserSettings(), getLevelSettings()]);

      window.location.assign(newlocation);
    } catch (error) {
      throw new Error(`to MSH homepage error: ${error}`);
    }
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  getUserSettings: () => dispatch(getUserSettings()),
  getLevelSettings: () => dispatch(getLevelSettings())
});

export default withRouter(connect(null, mapDispatchToProps)(ToHomePage));
