import React, { Component } from "react";
import classNames from "classnames";
import I18nSpan from "../../i18n/components/I18nSpan";

interface Props {
  titleKey: string;
  actionKey?: string;
}

export default class FormTitle extends Component<Props, any> {
  render() {
    const { titleKey, actionKey } = this.props;

    return (
      <div className="title">
        <div className="title-font">
          <span
            className={classNames(
              "main-title",
              actionKey ? "with-sub-title" : ""
            )}
          >
            <I18nSpan msgKey={titleKey} />
          </span>
          {actionKey && (
            <span className="sub-title">
              <I18nSpan msgKey={actionKey} />
            </span>
          )}
        </div>
      </div>
    );
  }
}
