Apex.chart = {
  locales: [
    {
      name: "en",
      options: {
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        shortMonths: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        days: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        toolbar: {
          exportToSVG: "Download SVG",
          exportToPNG: "Download PNG",
          exportToCSV: "Download CSV",
          menu: "Menu",
          selection: "Selection",
          selectionZoom: "Selection Zoom",
          zoomIn: "Zoom In",
          zoomOut: "Zoom Out",
          pan: "Panning",
          reset: "Reset Zoom"
        }
      }
    },
    {
      name: "fr",
      options: {
        months: [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre"
        ],
        shortMonths: [
          "Janv",
          "Févr",
          "Mars",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Août",
          "Sept",
          "Oct",
          "Nov",
          "Déc"
        ],
        days: [
          "Dimanche",
          "Lundi",
          "Mardi",
          "Mercredi",
          "Jeudi",
          "Vendredi",
          "Samedi"
        ],
        shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        toolbar: {
          exportToSVG: "Télécharger SVG",
          exportToPNG: "Télécharger PNG",
          exportToCSV: "Télécharger CSV",
          menu: "Menu",
          selection: "Sélection",
          selectionZoom: "Zoom de sélection",
          zoomIn: "Agrandir",
          zoomOut: "Dézoomer",
          pan: "Panoramique",
          reset: "Réinitialiser le zoom"
        }
      }
    },
    {
      name: "es",
      options: {
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        shortMonths: [
          "Enero",
          "Feb",
          "Marzo",
          "Abr",
          "Mayo",
          "Jun",
          "Jul",
          "Agosto",
          "Sep",
          "Oct",
          "Nov",
          "Dic"
        ],
        days: [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado"
        ],
        shortDays: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        toolbar: {
          exportToSVG: "Descargar SVG",
          exportToPNG: "Descargar PNG",
          exportToCSV: "Descargar CSV",
          menu: "Menú",
          selection: "Selección",
          selectionZoom: "Zoom de selección",
          zoomIn: "Acercarse",
          zoomOut: "Disminuir el zoom",
          pan: "Paneo",
          reset: "Restablecer zoom"
        }
      }
    },
    {
      name: "cr",
      options: {
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre"
        ],
        shortMonths: [
          "Enero",
          "Feb",
          "Marzo",
          "Abr",
          "Mayo",
          "Jun",
          "Jul",
          "Agosto",
          "Sep",
          "Oct",
          "Nov",
          "Dic"
        ],
        days: [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado"
        ],
        shortDays: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
        toolbar: {
          exportToSVG: "Descargar SVG",
          exportToPNG: "Descargar PNG",
          exportToCSV: "Descargar CSV",
          menu: "Menú",
          selection: "Selección",
          selectionZoom: "Zoom de selección",
          zoomIn: "Acercarse",
          zoomOut: "Disminuir el zoom",
          pan: "Paneo",
          reset: "Restablecer zoom"
        }
      }
    }
  ],
  defaultLocale: "en"
};

export default function setApexChartDefaultLocale(language) {
  const apexChartLocales = Apex.chart.locales.map(locale => locale.name);

  if (apexChartLocales.includes(language)) {
    Apex.chart.defaultLocale = language;
  } else {
    Apex.chart.defaultLocale = "en";
  }
}
