import React from "react";

import styles from "../styles/styles.css";

export default function PasswordInput({
  label,
  showPassword,
  onShowPassword,
  onChange,
  value
}) {
  return (
    <div className={styles["form-group"]}>
      <label htmlFor="password" className={styles["control-label"]}>
        {label}
      </label>
      <div className="input-group-field">
        <div
          className={`${styles["input-group-addon"]} icon-visibility-${
            showPassword ? "on" : "off"
          }`}
          onClick={onShowPassword}
        />
        <input
          type={showPassword ? "text" : "password"}
          id="password"
          name="password"
          className="form-control"
          onChange={onChange}
          required
          value={value}
          autoComplete="current-password"
        />
      </div>
    </div>
  );
}
