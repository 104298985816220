import React, { Component } from "react";
import { connect } from "react-redux";
import { rgba } from "polished";

interface Props {
  isIngenico: boolean;
  color: any;
}

interface State {}

class ThemeView extends Component<Props, State> {
  render() {
    const { isIngenico, color } = this.props;

    if (color) {
      return (
        <style>
          {`
                    /*Style loader*/
                    .load-bar,
                    .spinner .double-bounce1,
                    .load-bar .bar2 {background-color:${color}!important;}
                    .load-bar .bar1 {background-color:#BDC3C7;}
                    .load-bar .bar3 {background-color:#D2D7D3;}

                    .disable-row {
                        background-color: #F2F2F2!important;
                        color: #CCC!important;
                    }
                    .btn-ingenico {
                        color: ${color};
                        border-color: ${color};
                    }
                    .btn-ingenico:focus:not(:hover) {
                      color: ${color};
                    }
                    .btn-ingenico:hover {
                      background-color: transparent;
                      color: ${color};
                  }
                    .btn-ingenico.active, .btn-ingenico.active:hover, .btn-ingenico.active:focus {
                      background-color: ${color} !important;
                      color: white !important;
                      outline: 0 !important;
                    }
                    .btn-upload {
                        color: ${color};
                        border-color: ${color};
                        background: white;
                    }
                    .btn-upload:hover {
                        background-color: ${color};
                    }

                    .btn-ingenico-alert {
                        color: ${color};
                        border-color: ${color};
                    }
                    .btn-ingenico-alert:focus {
                      outline: 0 !important;
                  }
                    .btn-ingenico-alert:hover {
                      background-color: transparent;;
                      color: ${color};
                    }
                    .title .title-font .main-title span {
                        border-top-color:${color};
                    }
                    .title .title-font .main-title.with-sub-title:after {
                        color:${color};
                    }
                    ${
                      isIngenico
                        ? `.menu .top-menu .menu-title:hover,
                           .menu .top-menu .selected .menu-title {
                            color: ${color};
                          }`
                        : `.menu .top-menu .menu-title:hover i[class|="icon"],
                           .menu .top-menu .sub-menu-title:hover i[class|="icon"],
                           .menu .top-menu .selected > .menu-title i[class|="icon"] {
                            background-color: ${color};
                          }
                          .menu .menu-title:hover span.new::before,
                          .menu .sub-menu-title:hover span.new::before,
                          .menu li.selected > div.sub-menu-title span.new::before {
                            background-color: ${color};
                          }`
                    }
                    .header .logo-container::before {
                      background-color: ${color};
                    }
                    .countLine {
                      background-color: ${color};
                      box-shadow: 0 6px 12px 0 ${color ? rgba(color, 0.3) : ""};
                    }
                    .multiple-action:hover .glyphicon {
                      color: ${color};
                    }
                `}
        </style>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  const {
    data: { color }
  } = state.theme.color;

  return {
    color
  };
};

export default connect(mapStateToProps, null)(ThemeView);
