import React, { Component } from "react";
import { connect } from "react-redux";

import KeyIndicator from "./KeyIndicator";
import I18nAmount from "../../../i18n/components/I18nAmount";
import { WidgetConfig } from "../Widget";
import { getData, getDataWithTrend, getTrend } from "../../utils/DataProvider";
import Trend from "./Trend";

interface AverageBasketType {
  currency?: string;
  currencyCodeAlpha3?: string;
  currencyDecimal?: number;
  value?: number;
}

interface Data {
  averageBasket?: AverageBasketType;
}

interface Props {
  enableTrend: boolean;
  getData: Function;
  getTrend: Function;
  data: Data;
  trend: any;
  formatter?: Function;
  loading: boolean;
  error?: boolean;
  title: string;
  color: string;
  config: WidgetConfig;
}

class AverageBasket extends Component<Props, any> {
  componentDidMount() {
    const { config, getData, getTrend, enableTrend = false } = this.props;
    const { desktopView } = config;
    if (desktopView) {
      return getDataWithTrend({ config, getData, getTrend, enableTrend });
    }
  }

  render() {
    const {
      data,
      title,
      color,
      error,
      loading,
      config,
      formatter,
      trend: { data: { trend: trendData } } = { data: { trend: {} } },
      enableTrend
    } = this.props;

    const { averageBasket = {} } = data;

    const {
      timestamps: { min, max },
      timePeriod: { type: periodType },
      userCurrency = {}
    } = config;

    const { value: prevValue } = averageBasket as AverageBasketType;

    const { currency, currencyDecimal, currencyCodeAlpha3, value } = formatter
      ? formatter({ userCurrency, value: prevValue })
      : { ...userCurrency, value: prevValue };

    return (
      <KeyIndicator
        color={color}
        titleKey={title}
        error={error}
        loading={loading}
        renderTrend={() => (
          <Trend
            enableTrend={enableTrend}
            data={trendData}
            type={periodType}
            startTime={min}
            endTime={max}
            field="avg"
          />
        )}
      >
        <div>
          <I18nAmount
            number={value}
            currency={currency}
            currencyDecimal={currencyDecimal}
            currencyCodeAlpha3={currencyCodeAlpha3}
          />
        </div>
      </KeyIndicator>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  const {
    widgetTrend: { widgets: { [id]: trendData } } = { widgets: {} }
  } = state;

  const wrapTrend = () => {
    if (trendData) {
      const { data, loading, error } = trendData;

      return {
        data,
        loading,
        error
      };
    }
    return {
      data: {},
      loading: true
    };
  };

  const trend = wrapTrend();

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      trend,
      data: stats,
      loading,
      error
    };
  }

  return {
    trend,
    data: {},
    loading: true
  };
};

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config })),
  getTrend: ({ config }) => dispatch(getTrend({ config }))
});

export default connect(mapStateToProps, mapDispatchToProps)(AverageBasket);
