import React, { Component } from "react";
import I18nSpan from "../i18n/components/I18nSpan";

interface Props {
  objectName: any;
  onClickButton: Function;
  onClosePopin: Function;
  onCreatePosButton: Function;
  hasPosManagement: boolean;
}

class LinkPopin extends Component<Props, {}> {
  render() {
    const {
      onClosePopin,
      objectName,
      onClickButton,
      onCreatePosButton,
      hasPosManagement
    } = this.props;

    return (
      <div>
        <div className="popin">
          <div className="popinContent">
            <a onClick={onClosePopin} className="closeButton" />
            {hasPosManagement ? (
              <div>
                <div className="popinTitle">
                  <I18nSpan msgKey="popin.LinkWithCreate" />
                </div>

                <I18nSpan
                  msgKey="popin.message.LinkWithCreate"
                  args={[objectName]}
                />
              </div>
            ) : (
              <div>
                <div className="popinTitle">
                  <I18nSpan msgKey="popin.link" />
                </div>

                <I18nSpan msgKey="popin.message.link" args={[objectName]} />
              </div>
            )}

            <div className="button-list">
              <li>
                <a onClick={onClickButton} className="btn btn-ingenico">
                  <I18nSpan msgKey="popin.button.link" />
                </a>
              </li>
              {hasPosManagement && (
                <li>
                  <a onClick={onCreatePosButton} className="btn btn-ingenico">
                    <I18nSpan msgKey="popin.button.createPos" />
                  </a>
                </li>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LinkPopin;
