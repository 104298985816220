import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router";
import * as openpgp from "openpgp/dist/compat/openpgp"; // https://github.com/openpgpjs/openpgpjs/issues/408#issuecomment-200559285
import OpenPGPWorker from "worker-loader!openpgp/dist/compat/openpgp.worker";
import { ungzip } from "pako";
import untar from "js-untar";
import I18nSpan from "../../i18n/components/I18nSpan";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import If from "../../components/if";
import SecuredVariables from "../../securedVariables/components/SecuredVariablesView";

import styles from "../styles/styles.css";
import classNames from "classnames";
import FormTitle from "../../ingenicoForm/components/FormTitle";

import {
  getAmazonPaySettings,
  updateAmazonPaySettings,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  userLevel: string;
  userId: string;
  getAmazonPaySettings: Function;
  updateAmazonPaySettings: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
}

interface State {
  form: {
    amazonPayConfiguration: boolean;
    amazonPassword: string;
    showPasswordField: boolean;
    uploadedFileContents: any;
  };
  securedVariables: any;
  securedVariablesErrors: boolean;
  mshPublicKeyGpg: string;
}

class ApplicationSettingsView extends Component<Props, State> {
  state = {
    form: {
      applicationSettings: {},
      amazonPayConfiguration: false,
      amazonPassword: "",
      showPasswordField: false,
      uploadedFileContents: null,
      amazonFilename: ""
    },
    existingAmazonPayToken: "",
    securedVariables: {},
    securedVariablesErrors: false
  };

  async componentDidMount() {
    const { userId: merchantId, getAmazonPaySettings } = this.props;

    try {
      const {
        amazonPayToken,
        ...applicationSettings
      } = await getAmazonPaySettings({ merchantId });

      this.setState(prevState => {
        const { form } = prevState;

        return {
          ...prevState,
          existingAmazonPayToken:
            amazonPayToken || prevState.existingAmazonPayToken,
          form: {
            ...form,
            applicationSettings:
              applicationSettings || prevState.form.applicationSettings
          }
        };
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  _onChangeHandler = (name, value) => {
    this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value
        }
      };
    });
  };

  onAmazonFileUpload = async event => {
    const { target } = event;

    if (!target || !target.files) {
      return false;
    }

    const fileList = target.files;

    // Uploads will push to the file input's `.files` array. Get the last uploaded file.
    const latestUploadedFile = fileList.item(fileList.length - 1);

    // Render the password field when a file is uploaded
    this.setState(state => {
      return {
        ...state,
        form: {
          ...state.form,
          showPasswordField: true
        }
      };
    });

    try {
      const uploadedFileContents = await this.readUploadedFileAsArrayBuffer(
        latestUploadedFile
      );

      const amazonFilename = latestUploadedFile.name;

      this.setState(state => {
        return {
          ...state,
          form: {
            ...state.form,
            uploadedFileContents,
            amazonFilename
          }
        };
      });
    } catch {
      // notification on error
      /* const actions = this.getFlux().actions; */
      // TODO : display relevamt error notification IF NECESSARY
      /* /actions.notification.error("form.error.format.json"); */
    }
  };

  readUploadedFileAsArrayBuffer(inputFile) {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        const { result } = temporaryFileReader;
        const uInt8Arr = new Uint8Array(result);

        return resolve(uInt8Arr);
      };
      temporaryFileReader.readAsArrayBuffer(inputFile);
    });
  }

  saveApplicationSettings = async event => {
    event.preventDefault();

    const {
      userId: merchantId,
      mshPublicKeyGpg: pubkey,
      updateAmazonPaySettings,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    const {
      form: {
        uploadedFileContents,
        amazonPassword: passwords,
        applicationSettings
      },
      existingAmazonPayToken
    } = this.state;

    const settings = {};

    if (uploadedFileContents) {
      new OpenPGPWorker(); // set the relative web worker path

      const openpgpDecrypt = async (pgp: BinaryType) => {
        try {
          const decOpt = async ciphertext => ({
            message: await openpgp.message.read(ciphertext),
            passwords,
            format: "binary"
          });

          const readableStream = new ReadableStream({
            start(controller) {
              controller.enqueue(pgp);
              controller.close();
            }
          });
          const options = await decOpt(readableStream);
          const { data } = await openpgp.decrypt(options);

          return await openpgp.stream.readToEnd(data);
        } catch (error) {
          addNotificationError("user.passwordForm.error.password.mismatch");

          throw new Error(error);
        }
      };
      const amazonPayTokenTarGz = await openpgpDecrypt(uploadedFileContents);

      // ungzip
      const { buffer: amazonPayTokenTar } = ungzip(amazonPayTokenTarGz);

      // untar
      const amazonPayToken = await untar(amazonPayTokenTar);

      // token
      const { buffer: amazonPayTokenAB } = amazonPayToken.find(file => {
        const { name } = file;

        return name === AMAZONPAY_FILENAME;
      });

      // encrypt the decrypted data with MSH public key with OpenPGP
      const openpgpEncrypt = async (uInt8Arr: Uint8Array) => {
        try {
          const encOpt = async binary => ({
            message: openpgp.message.fromBinary(binary),
            publicKeys: (await openpgp.key.readArmored(pubkey)).keys
          });

          const readableStream = new ReadableStream({
            start(controller) {
              controller.enqueue(uInt8Arr);
              controller.close();
            }
          });
          const options = await encOpt(readableStream);
          const { data } = await openpgp.encrypt(options);

          return await openpgp.stream.readToEnd(data);
        } catch (error) {
          throw new Error(error);
        }
      };
      const amazonPayTokenEncrypted = await openpgpEncrypt(
        new Uint8Array(amazonPayTokenAB)
      );

      Object.assign(settings, {
        ...applicationSettings,
        amazonPayToken: amazonPayTokenEncrypted
      });
    } else {
      Object.assign(settings, {
        ...applicationSettings,
        amazonPayToken: existingAmazonPayToken
      });
    }

    return updateAmazonPaySettings({ merchantId, settings }).then(
      () => {
        addNotificationSuccess("merchant.settings.success");
        this._goToHome();
      },
      () => addNotificationError("notification.error")
    );
  };

  _goToHome = () => {
    this.props.history.push("/main");
  };

  render() {
    const { userLevel, userId } = this.props;
    const {
      form: {
        amazonPayConfiguration,
        amazonFilename,
        amazonPassword,
        showPasswordField
      }
    } = this.state;

    return (
      <div className="col-sm-12">
        <FormTitle
          titleKey="applicationSettings.title"
          actionKey="applicationSettings.title"
        />

        <div className="row">
          <form
            className={classNames(
              "ingenico-form",
              "form-horizontal",
              "col-sm-12"
            )}
          >
            <BootstrapInput
              ref="amazonPayConfiguration"
              name="amazonPayConfiguration"
              onChange={this._onChangeHandler}
              descriptor={{
                type: "checkbox",
                id: "amazonPayConfiguration",
                className: "mtn",
                label: "applicationSettings.form.amazonPay.label",
                inputClass: "",
                labelClass: "col-sm-1",
                disabledControlLabel: true
              }}
              formValue={amazonPayConfiguration}
            />

            <If test={amazonPayConfiguration}>
              <div>
                <div className="form-group">
                  <label className="col-sm-12">
                    <I18nSpan msgKey="applicationSettings.form.amazonPay.amazonFileUpload.label" />
                  </label>
                  <div className="col-sm-6">
                    <label
                      htmlFor="AmazonFileUpload"
                      className={styles["upload-button-text"]}
                    >
                      <I18nSpan msgKey={"form.jsonarea.button.fileupload"} />
                      <input
                        id={"AmazonFileUpload"}
                        name={"AmazonFileUpload"}
                        className={styles["file-input-field"]}
                        type="file"
                        onChange={this.onAmazonFileUpload}
                      />
                    </label>
                    <p className="filename">{amazonFilename}</p>
                  </div>
                </div>

                <If test={showPasswordField}>
                  <BootstrapInput
                    descriptor={{
                      id: "amazonPassword",
                      type: "password",
                      inputClass: "col-sm-4",
                      labelClass: "col-sm-12",
                      label:
                        "applicationSettings.form.amazonPay.amazonPassword.label",
                      placeholder:
                        "applicationSettings.form.amazonPay.amazonPassword.placeholder"
                    }}
                    ref="amazonPassword"
                    name="amazonPassword"
                    onChange={this._onChangeHandler}
                    required={true}
                    formValue={amazonPassword}
                  />
                </If>
              </div>
            </If>
            <div className="pull-right">
              <button
                onClick={this.saveApplicationSettings}
                className="btn btn-ingenico save-button"
              >
                <I18nSpan msgKey={"button.label.ok"} />
              </button>
              <button
                onClick={this._goToHome}
                className="btn btn-ingenico btn-ingenico-alert exit-button"
              >
                <I18nSpan msgKey="button.label.exit" />
              </button>
            </div>
          </form>
        </div>
        <FormTitle titleKey="securedVariable.title" />
        <div className={styles.securedVariableForm}>
          <SecuredVariables userLevel={userLevel} userId={userId} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: {
      user: {
        scope: {
          level: { type: userLevel, id: userId }
        }
      }
    },
    config: {
      data: { mshPublicKeyGpg = "" }
    }
  } = state;

  return {
    userLevel,
    userId,
    mshPublicKeyGpg
  };
};

const mapDispatchToProps = dispatch => ({
  getAmazonPaySettings: ({ merchantId }) =>
    dispatch(getAmazonPaySettings({ merchantId })),
  updateAmazonPaySettings: ({ merchantId, settings }) =>
    dispatch(updateAmazonPaySettings({ merchantId, settings })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationSettingsView)
);
