import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import {
  FAILURE_GET_SIMS_ACTIVITIES_ACTION,
  PENDING_GET_SIMS_ACTIVITIES_ACTION,
  SUCCESS_GET_SIMS_ACTIVITIES_ACTION,
  RESET_SIMS_ACTIVITIES_ACTION,
  PENDING_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION,
  SUCCESS_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION,
  FAILURE_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION,
  RESET_SIMS_ACTIVITIES_CONNECTIONS_ACTION
} from "../actionsTypes";

export const getSimsActivities = ({
  filters,
  fields = [],
  sort = { field: "timestamp", order: "DESC" },
  tableCount
}) => async dispatch => {
  dispatch(getSimsStart());

  try {
    const url = new URL("/sim-monitoring/search", PORTAL_URL);

    if (tableCount) {
      url.searchParams.append("max", tableCount);
    }

    const { count, scrollId, searchResults: data } = await F.post(
      `${url.pathname}${url.search}`,
      {
        body: {
          filters,
          fields,
          sort
        },
        contentType: ContentTypes.JSON
      }
    );

    return dispatch(getSimsSucceeded({ count, scrollId, data }));
  } catch (errors) {
    dispatch(getSimsFailure({ errors }));

    throw errors;
  }
};

const getSimsStart = () => ({
  type: PENDING_GET_SIMS_ACTIVITIES_ACTION
});

const getSimsSucceeded = ({ count, scrollId, data }) => ({
  type: SUCCESS_GET_SIMS_ACTIVITIES_ACTION,
  payload: {
    count,
    scrollId,
    data
  }
});

const getSimsFailure = errors => ({
  type: FAILURE_GET_SIMS_ACTIVITIES_ACTION,
  payload: {
    errors
  }
});

export const resetSimsActivities = () => ({
  type: RESET_SIMS_ACTIVITIES_ACTION
});

export const getSimsActivitiesConnections = ({
  begin,
  end
}) => async dispatch => {
  dispatch(getSimsActivitiesConnectionsStart());

  //TODO: still in provisioning. we cannot use the search service
  const url = new URL("/reporting/sim/connection", PORTAL_URL);

  Object.entries({ begin, end }).forEach(param => {
    const [paramKey, paramValue] = param;

    if (paramValue) {
      return url.searchParams.append(paramKey, paramValue);
    }
  });

  return F.get(`${url.pathname}${url.search}`)
    .then(data => {
      return dispatch(getSimsActivitiesConnectionsSucceeded({ data }));
    })
    .catch(errors => {
      dispatch(getSimsActivitiesConnectionsFailure({ errors }));
    });
};

const getSimsActivitiesConnectionsStart = () => ({
  type: PENDING_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION
});

const getSimsActivitiesConnectionsSucceeded = ({ data }) => ({
  type: SUCCESS_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION,
  payload: {
    data
  }
});

const getSimsActivitiesConnectionsFailure = errors => ({
  type: FAILURE_GET_SIMS_ACTIVITIES_CONNECTIONS_ACTION,
  payload: {
    errors
  }
});

export const resetSimsActivitiesConnections = () => ({
  type: RESET_SIMS_ACTIVITIES_CONNECTIONS_ACTION
});
