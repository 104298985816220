import React, { Component } from "react";
import I18nSpan from "../i18n/components/I18nSpan";

interface Props {
  objectName: any;
  onClickButton: Function;
  onClosePopin: Function;
}

class ConfirmationPopin extends Component<Props, {}> {
  render() {
    const { onClosePopin, objectName, onClickButton } = this.props;
    const objectNamesToDelete = Array.isArray(objectName)
      ? [objectName.join(", ")]
      : [objectName];

    return (
      <div>
        <div className="popin">
          <div className="popinContent">
            <a onClick={onClosePopin} className="closeButton" />
            <div className="popinTitle">
              <I18nSpan msgKey="popin.confirmation" />
            </div>
            <I18nSpan
              msgKey="popin.message.delete"
              args={objectNamesToDelete}
            />

            <div className="button-list">
              <li>
                <a onClick={onClosePopin} className="btn btn-ingenico">
                  <I18nSpan msgKey="popin.button.cancel" />
                </a>
              </li>
              <li>
                <a onClick={onClickButton} className="btn btn-ingenico">
                  <I18nSpan msgKey="popin.button.delete" />
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationPopin;
