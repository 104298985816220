import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_PERMISSIONS_FORM_ACTION,
  SUCCESS_GET_PERMISSIONS_FORM_ACTION,
  FAILURE_GET_PERMISSIONS_FORM_ACTION
} from "../actionsTypes";

export const getPermissionsForm = ({ restrictionLevel }) => async dispatch => {
  dispatch(getPermissionsFormStart());

  return F.get(`/me/permissions/${restrictionLevel}`).then(
    permissionsForm => {
      dispatch(getPermissionsFormSucceeded(permissionsForm));
      return permissionsForm;
    },
    errors => {
      dispatch(getPermissionsFormFailure({ errors }));

      throw errors;
    }
  );
};

const getPermissionsFormStart = () => ({
  type: PENDING_GET_PERMISSIONS_FORM_ACTION
});

const getPermissionsFormSucceeded = permissionsForm => ({
  type: SUCCESS_GET_PERMISSIONS_FORM_ACTION,
  payload: {
    permissionsForm
  }
});

const getPermissionsFormFailure = errors => ({
  type: FAILURE_GET_PERMISSIONS_FORM_ACTION,
  payload: {
    errors
  }
});
