import React, { Component } from "react";
import { AutoSizer, MultiGrid, InfiniteLoader } from "react-virtualized";
import classNames from "classnames";
import { rgba } from "polished";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import Sanitizer from "../../commons/Sanitizer";
import I18nSpan from "../../i18n/components/I18nSpan";

const ASC = "ASC";
const DESC = "DESC";

import styles from "../styles/styles.css";
import Counter from "./Counter";

interface Props {
  data: Array<any>;
  notSortableFields: Array<string>;
  attributes: Array<string>;
  visibleAttributes: Array<string>;
  extraAttributes: Array<string>;
  customColumns: Array<object>;
  formatter: any;
  i18nKey: string;
  i18nKeyResolver: Function;
  idAttribute: string;
  onEditButtonClick: Function;
  onDeleteButtonClick: Function;
  onDetailButtonClick: Function;
  selectable: boolean;
  isRowEditable: Function;
  actionsElement: Array<Function>;
  color: string;
  onSearch: Function;
  hideSearchBox: boolean;
  onSort: Function;
  toggleable: boolean;
  disableStatusValues: Array<string>;
  disablePredicate: Function;
  hasActions: boolean;
  sortDescription: {
    direction: string;
    column: string;
  };
  onRefresh: Function;
  onUpdateColumns: Function;
  isColumnSettable: boolean;
  onToggleSelect: Function;
  onToggleSelectAll: Function;
  onToggleUnSelectAll: Function;
  selection: Array<string>;
  useSelection: boolean;
  // remoteRowCount: number;
  selectionCounter: boolean;
  searchContext: any;
}

interface State {}

class DataTable extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      searchedKeyword: null,
      sortColumn:
        props.sortDescription &&
        props.sortDescription.field.replace(/.raw$/, ""),
      sortDirection: props.sortDescription && props.sortDescription.order,
      attributes: _.union(props.attributes, props.extraAttributes),
      visibleAttributes: this.convertAttributesIntoMap({}),
      attributesAreaVisibility: false
    };
    this.multiGridElement = null;
    this.scroll = {
      scrollTop: 0,
      scrollLeft: 0
    };
  }

  componentWillReceiveProps(newProps: any) {
    const attributes = _.union(newProps.attributes, newProps.extraAttributes);
    const visibleAttributesSaved =
      JSON.parse(localStorage.getItem(this.getStorageKey())) || {};

    const visibleAttributes = this.convertAttributesIntoMap(
      visibleAttributesSaved
    );

    return this.setState(
      {
        attributes,
        visibleAttributes,
        data: this._filterData(
          newProps.data,
          this.state.searchedKeyword,
          this.state.sortColumn,
          this.state.sortDirection
        )
      },
      () => this.multiGridElement.forceUpdateGrids()
    );
  }

  isVisible = a => {
    return (
      !this.props.visibleAttributes ||
      _.includes(this.props.visibleAttributes, a)
    );
  };

  convertAttributesIntoMap = localStorageAttributes => {
    const visibleAttributes = {};
    _.map(
      this.props.attributes,
      a =>
        (visibleAttributes[a.toString()] = _.get(
          localStorageAttributes,
          a.toString(),
          this.isVisible(a)
        ))
    );
    _.map(
      this.props.extraAttributes,
      a =>
        (visibleAttributes[a.toString()] = localStorageAttributes[a.toString()])
    );
    return visibleAttributes;
  };

  i18nKeyResolver = attribute => {
    if (this.props.i18nKeyResolver) {
      return this.props.i18nKeyResolver(attribute);
    }

    return `${this.props.i18nKey}.header.${attribute}`;
  };

  headerI18n = attribute => {
    const { t } = this.props;

    return t(this.i18nKeyResolver(attribute), attribute);
  };

  _search = e => {
    const value = e.target.value;

    if (_.isFunction(this.props.onSearch)) {
      this.props.onSearch(e);
    } else {
      this.setState({
        searchedKeyword: value,
        data: this._filterData(
          this.props.data,
          value,
          this.state.sortColumn,
          this.state.sortDirection
        )
      });
    }
  };

  _sort = attribute => {
    const { onSort, version } = this.props;
    const { searchedKeyword } = this.state;
    let sortDirection, sortColumn;

    if (this.state.sortColumn === attribute) {
      if (this.state.sortDirection === ASC) {
        sortDirection = DESC;
        sortColumn = attribute;
      } else if (this.state.sortDirection === DESC) {
        sortDirection = null;
        sortColumn = null;
      } else {
        sortDirection = ASC;
        sortColumn = attribute;
      }
    } else {
      sortDirection = ASC;
      sortColumn = attribute;
    }

    if (_.isFunction(onSort)) {
      onSort({ direction: sortDirection, column: sortColumn });
    }

    const data = this._filterData(
      this.props.data,
      searchedKeyword,
      sortColumn,
      sortDirection
    );

    if (version === "new") {
      return this.setState(
        {
          sortDirection,
          sortColumn
        },
        () => {
          const { onRefresh, fields, sortKey, searchContext } = this.props;

          const notRawField = attr => {
            if (fields) {
              return fields.find(item => {
                const { key, type } = item;

                return key === attr && (type === "date" || type === "amount");
              });
            }
          };
          const sortData = this.state.sortDirection
            ? {
                field: `${this.state.sortColumn}${
                  notRawField(attribute) ? "" : ".raw"
                }`,
                order: this.state.sortDirection
              }
            : undefined;

          const {
            timePeriod,
            filtersByAdvancedSearchKey: { [sortKey]: filters = [] } = {}
          } = searchContext;
          const newSearchContext = {
            key: sortKey,
            sort: sortData,
            filters,
            timePeriod
          };
          return onRefresh({
            filters,
            sort: sortData,
            searchContext: newSearchContext
          });
        }
      );
    }

    return this.setState({
      sortDirection,
      sortColumn,
      data
    });
  };

  _onRefresh = event => {
    event.preventDefault();

    const { onRefresh } = this.props;
    const { sortColumn, sortDirection } = this.state;

    if (sortColumn) {
      return onRefresh({
        field: `${sortColumn}.raw`,
        order: sortDirection
      });
    }

    return onRefresh();
  };

  _filterData = (data, keyWord, sortColumn, sortDirection) => {
    const { version } = this.props;

    if (version === "new") {
      return data;
    }

    let result;

    if (!keyWord) {
      result = data;
    } else {
      result = _.filter(data, row =>
        _.some(_.keys(row), attrName => {
          const value = this._formatField(row, attrName);
          return this._arrayContainsValue(value, keyWord);
        })
      );
    }

    if (sortColumn) {
      const resultSorted = _.sortBy(result, sortColumn);

      if (sortDirection === DESC) {
        resultSorted.reverse();
      }

      return resultSorted;
    }

    return result;
  };

  _arrayContainsValue = (value, keyWord) => {
    if (value && (_.isString(value) || _.isNumber(value))) {
      return this._containsIgnoreCase(value, keyWord);
    } else if (_.isArray(value)) {
      return _.some(value, element =>
        _.isString(element) || _.isNumber(element)
          ? this._arrayContainsValue(element, keyWord)
          : this._arrayContainsValue(_.flatten(_.values(element)), keyWord)
      );
    } else {
      return false;
    }
  };

  _containsIgnoreCase = (haystack, needle) => {
    if (!haystack) {
      return false;
    }

    return (
      haystack
        .toString()
        .toUpperCase()
        .indexOf(needle.toUpperCase()) >= 0
    );
  };

  _toggleVisibility = (attribute, e) => {
    const newVisibleAttributes = _.clone(this.state.visibleAttributes);
    newVisibleAttributes[attribute] = e.target.checked;
    this.setState(
      {
        visibleAttributes: newVisibleAttributes
      },
      this.saveSelectedColumns
    );
  };

  _toggleAll = e => {
    const newVisibleAttributes = _.mapValues(
      _.clone(this.state.visibleAttributes),
      () => e.target.checked
    );
    this.setState(
      {
        visibleAttributes: newVisibleAttributes
      },
      this.saveSelectedColumns
    );
  };

  _formatField = (row, attribute) => {
    const { formatter } = this.props;

    let value = _.get(
      this.isExtraAttribute(attribute) ? row.extras : row,
      attribute
    );

    if (formatter && formatter[attribute]) {
      value = formatter[attribute](value, row);
    }

    return value;
  };

  _decorateFieldValue = (row, attribute) => {
    const obj = this._formatField(row, attribute);

    const { t, formattersCellValue } = this.props;
    const _translateCellValue = ({ value }: any) => {
      const needFormat = _.includes(attribute, formattersCellValue);
      if (needFormat) {
        const transationFound = i18next.exists(`dataTable.state.${value}`);
        return transationFound ? t(`dataTable.state.${value}`) : value;
      }
      return value;
    };

    if (_.isString(obj) || _.isNumber(obj)) {
      return _translateCellValue({ value: obj });
    } else {
      const value = _.get(row, attribute);

      if (_.isArray(value)) {
        return _.map(value, (elem: any) =>
          elem.value ? elem.value : elem
        ).join(" ");
      } else {
        return value;
      }
    }
  };

  _decorateRowEditing = row => {
    return this.props.isRowEditable ? this.props.isRowEditable(row) : true;
  };

  getDetailButtonClickHandler = id => {
    return () => this.props.onDetailButtonClick(id);
  };

  getVisibleAttributes = () => {
    return _.pick(this.state.visibleAttributes, a => a === true);
  };

  toggleAttributesVisibility = () => {
    if (this.state.attributesAreaVisibility && this.props.isColumnSettable)
      this.props.onUpdateColumns(this.state.visibleAttributes);
    this.setState({
      attributesAreaVisibility: !this.state.attributesAreaVisibility
    });
  };

  isExtraAttribute = attribute => {
    return _.includes(this.props.extraAttributes, attribute);
  };

  saveSelectedColumns = () => {
    localStorage.setItem(
      this.getStorageKey(),
      JSON.stringify(this.state.visibleAttributes)
    );
  };

  getStorageKey = () => {
    const {
      user: { login: userLogin = "" },
      i18nKey
    } = this.props;

    return `visibleAttributes#${userLogin}#${i18nKey}`;
  };

  filterDisablePredicate = () => {
    const { data: prevData = [] } = this.state === null ? {} : this.state;

    const { disablePredicate } = this.props;

    if (disablePredicate) {
      return prevData.filter(value => disablePredicate(value));
    }

    return prevData;
  };

  _infiniteLoaderChildFunction = ({ onRowsRendered, registerChild }) => {
    this._onRowsRendered = onRowsRendered;

    const {
      actionsElement,
      customColumns,
      onDeleteButtonClick,
      onEditButtonClick,
      onDetailButtonClick,
      idAttribute,
      selectableNew,
      i18nKey,
      hasActions,
      useSelection,
      disablePredicate,
      disableStatusValues,
      rawData,
      remoteRowCount,
      onRowClick,
      t: $τ
    } = this.props;
    const { selection: selectionState, isCompleteSelection } = this.state;
    const selection = useSelection ? this.props.selection : selectionState;
    const actionsElementGenerator = _.clone(actionsElement || []);
    const customColumnsGenerator = _.clone(customColumns || []);
    const customHeaders =
      _.clone(customColumnsGenerator).map(_.property("column")) || [];
    const toggleSelect = row => {
      const { onToggleSelect } = this.props;
      const disabled = disablePredicate && disablePredicate(row);

      if (disabled) {
        return;
      }

      return onToggleSelect(row[idAttribute]);
    };

    if (onDeleteButtonClick) {
      actionsElementGenerator.push(row => (
        <a
          onClick={onDeleteButtonClick(row[idAttribute], row)}
          title={$τ("dataTable.delete")}
          className="btn btn-circle"
        >
          <i className="glyphicon glyphicon-trash" />
        </a>
      ));
    }

    if (onEditButtonClick) {
      actionsElementGenerator.push(row => (
        <span className={this._decorateRowEditing(row) ? "" : "noneditable"}>
          <a
            onClick={onEditButtonClick(row[idAttribute], row)}
            title={$τ("dataTable.edit")}
            className="btn btn-circle"
          >
            <i className="glyphicon glyphicon-pencil" />
          </a>
        </span>
      ));
    }

    if (onDetailButtonClick) {
      actionsElementGenerator.push(row => (
        <span>
          <a
            onClick={this.getDetailButtonClickHandler(row[idAttribute])}
            title={$τ("dataTable.detail")}
            className="btn btn-circle"
          >
            <i className="glyphicon glyphicon-eye-open" />
          </a>
        </span>
      ));
    }

    const selectAllToggle = isAllSelection => {
      const { onToggleSelectAll, onToggleUnSelectAll } = this.props;
      const selectionDisabled = this.filterDisablePredicate();

      return isAllSelection
        ? onToggleUnSelectAll && onToggleUnSelectAll()
        : onToggleSelectAll && onToggleSelectAll(selectionDisabled);
    };

    const selectionDisabled = this.filterDisablePredicate();
    const dataLength =
      disablePredicate && selectionDisabled.length > 0
        ? this.props.data.length - selectionDisabled.length
        : this.props.data.length;

    const isAllSelection = useSelection
      ? this.props.selection.length !== 0 &&
        this.props.selection.length === dataLength
      : false;

    return (
      <div className="data-table">
        <AutoSizer>
          {({ height, width }) => {
            const visibleAttributes = _.keys(this.getVisibleAttributes());
            const columnCount =
              (selectableNew ? 1 : 0) +
              visibleAttributes.length +
              customColumnsGenerator.length +
              (hasActions ? 1 : 0);
            const rowCount =
              parseInt(remoteRowCount || this.state.data.length, 10) + 1;
            let gridHeight = height;
            let gridWidth = width;
            const columnWidth = ({ index }) => {
              if (selectableNew) {
                if (index === 0) {
                  return 34;
                }

                if (34 + (columnCount - 1) * 200 < width) {
                  return (width - 34) / (columnCount - 1);
                }
              }

              if (columnCount * 200 < width) {
                return width / columnCount;
              }

              return 200;
            };
            const rowHeight = ({ index }) => {
              if (index === 0) {
                return 65;
              }

              return 40;
            };

            if (process.env.NODE_ENV === "test") {
              // For testing purposes, see: https://github.com/bvaughn/react-virtualized/issues/493#issuecomment-353645153
              gridHeight = 500;
              gridWidth = columnCount * 200;
            }

            return (
              <MultiGrid
                cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                  const rowData = this.state.data[rowIndex - 1];

                  const isDisabled =
                    rowData && disablePredicate && disablePredicate(rowData);

                  const hasDisabledStatus =
                    rowData &&
                    _.some(
                      disableStatusValues,
                      s =>
                        rowData["active"] === s ||
                        rowData["status"] === s ||
                        rowData["hierarchyStatus"] === s ||
                        rowData["pos.status"] === s ||
                        rowData["store.status"] === s ||
                        rowData["merchant.status"] === s ||
                        rowData["acquirer.status"] === s ||
                        rowData["sim.status"] === s
                    );

                  const selected =
                    rowData && _.includes(selection, rowData[idAttribute]);

                  if (rowIndex === 0) {
                    const HeaderSelectionColumn = ({ style }) => {
                      if (selectableNew) {
                        if (useSelection) {
                          return (
                            <div
                              className={`grid-header-selection ${styles.selectionNew}`}
                              key={key}
                              style={style}
                            >
                              <div className="selectAllNew">
                                <input
                                  className="selectAllNewInput"
                                  type="checkbox"
                                  checked={isAllSelection}
                                  onClick={() =>
                                    selectAllToggle(isAllSelection)
                                  }
                                />
                                <label htmlFor="selectAllNewInput" />
                              </div>
                            </div>
                          );
                        }

                        return (
                          <div
                            className={`grid-header-selection ${styles.selectionNew}`}
                            key={key}
                            style={style}
                          >
                            <div className="selectAllNew">
                              <input
                                className="selectAllNewInput"
                                type="checkbox"
                                checked={isCompleteSelection}
                                onClick={selectAllToggle}
                              />
                              <label htmlFor="selectAllNewInput" />
                            </div>
                          </div>
                        );
                      }
                    };

                    const HeaderColumn = ({ style }) => {
                      const {
                        notSortableFields,
                        headerElVirtualized
                      } = this.props;
                      const { sortColumn, sortDirection } = this.state;
                      const attribute = visibleAttributes[columnIndex];
                      const icon = (
                        <div className={styles.sortable}>
                          <span
                            className={classNames(styles["triangle-top"], {
                              [styles["triangle-active"]]:
                                attribute === sortColumn &&
                                sortDirection === ASC
                            })}
                          />
                          <span
                            className={classNames(styles["triangle-bottom"], {
                              [styles["triangle-active"]]:
                                attribute === sortColumn &&
                                sortDirection === DESC
                            })}
                          />
                        </div>
                      );

                      const isSortable = notSortableFields
                        ? !notSortableFields.includes(attribute)
                        : true;

                      return headerElVirtualized({
                        key,
                        style,
                        attribute,
                        icon,
                        sortFunc: this._sort,
                        renderAttributeFunc: this.headerI18n,
                        sortable: isSortable
                      });
                    };

                    const HeaderCustomColumn = ({ style }) => {
                      const customAttribute =
                        customHeaders[columnIndex - visibleAttributes.length];

                      return (
                        <div
                          className={`grid-header header-${Sanitizer.className(
                            customAttribute
                          )}`}
                          key={key}
                          style={style}
                        >
                          {this.headerI18n(customAttribute)}
                        </div>
                      );
                    };

                    const HeaderActionsColumn = ({ style }) => (
                      <div
                        className="grid-header header-actions"
                        key={key}
                        style={style}
                      >
                        <I18nSpan msgKey={`${i18nKey}.header.actions`} />
                      </div>
                    );

                    if (selectableNew) {
                      if (columnIndex === 0) {
                        return <HeaderSelectionColumn style={style} />;
                      }

                      columnIndex--;
                    }

                    if (
                      customColumnsGenerator &&
                      customColumnsGenerator[
                        columnIndex - visibleAttributes.length
                      ]
                    ) {
                      return <HeaderCustomColumn style={style} />;
                    }

                    if (
                      hasActions &&
                      columnIndex ===
                        visibleAttributes.length + customColumnsGenerator.length
                    ) {
                      return <HeaderActionsColumn style={style} />;
                    }

                    return <HeaderColumn style={style} />;
                  }

                  const SelectionColumn = ({ style }) => {
                    if (selectableNew) {
                      return (
                        <div
                          className={classNames(
                            "grid-body",
                            styles.selectionNew,
                            {
                              ["disable-row"]: hasDisabledStatus || isDisabled,
                              selected,
                              odd: rowIndex % 2 === 0,
                              even: rowIndex % 2 !== 0,
                              firstChild: columnIndex === 0,
                              lastChild: columnIndex === columnCount - 1
                            }
                          )}
                          key={key}
                          style={style}
                        >
                          <div className="selectionCheckboxNew">
                            <input
                              className="selectionCheckboxNewInput"
                              type="checkbox"
                              name={`checkbox.${rowData[idAttribute]}`}
                              value={rowData[idAttribute]}
                              checked={selected}
                              onChange={() => toggleSelect(rowData)}
                            />
                            <label htmlFor="selectionCheckboxNewInput" />
                          </div>
                        </div>
                      );
                    }

                    return null;
                  };

                  const CustomColumn = ({ style }) => {
                    const columnObject =
                      customColumnsGenerator[
                        columnIndex - visibleAttributes.length
                      ];

                    return (
                      <div
                        key={key}
                        style={style}
                        className={classNames(
                          "grid-body",
                          Sanitizer.className(columnObject.column),
                          {
                            ["disable-row"]: hasDisabledStatus || isDisabled,
                            selected,
                            odd: rowIndex % 2 === 0,
                            even: rowIndex % 2 !== 0,
                            firstChild: columnIndex === 0,
                            lastChild:
                              columnIndex ===
                              columnCount - (selectableNew ? 2 : 1)
                          }
                        )}
                      >
                        <span className="grid-cell-value">
                          {columnObject.element(rowData)}
                        </span>
                      </div>
                    );
                  };

                  const ActionsColumn = ({ style }) => (
                    <div
                      key={key}
                      style={style}
                      className={classNames("grid-body", "actions", {
                        ["disable-row"]: hasDisabledStatus || isDisabled,
                        selected,
                        odd: rowIndex % 2 === 0,
                        even: rowIndex % 2 !== 0,
                        firstChild: columnIndex === 0,
                        lastChild:
                          columnIndex === columnCount - (selectableNew ? 2 : 1)
                      })}
                    >
                      {_.map(
                        actionsElementGenerator,
                        (elementGenerator: Function, idx) => {
                          const value = elementGenerator(rowData, rawData);

                          return (
                            <span key={`${key}-actions-${idx}`}>{value}</span>
                          );
                        }
                      )}
                    </div>
                  );

                  if (rowData) {
                    if (selectableNew) {
                      if (columnIndex === 0) {
                        return <SelectionColumn style={style} />;
                      }

                      columnIndex--;
                    }

                    if (
                      customColumnsGenerator &&
                      customColumnsGenerator[
                        columnIndex - visibleAttributes.length
                      ]
                    ) {
                      return <CustomColumn style={style} />;
                    }

                    if (
                      hasActions &&
                      columnIndex ===
                        visibleAttributes.length + customColumnsGenerator.length
                    ) {
                      return <ActionsColumn style={style} />;
                    }

                    return (
                      <div
                        key={key}
                        style={style}
                        className={classNames(
                          "grid-body",
                          Sanitizer.className(visibleAttributes[columnIndex]),
                          {
                            ["disable-row"]: hasDisabledStatus || isDisabled,
                            selected,
                            odd: rowIndex % 2 === 0,
                            even: rowIndex % 2 !== 0,
                            firstChild: columnIndex === 0,
                            lastChild:
                              columnIndex ===
                              columnCount - (selectableNew ? 2 : 1),
                            clickable: onRowClick
                          }
                        )}
                        onClick={() => {
                          if (onRowClick) {
                            return onRowClick(rowIndex - 1, rowData);
                          }
                        }}
                      >
                        <span className="grid-cell-value">
                          {this._decorateFieldValue(
                            rowData,
                            visibleAttributes[columnIndex]
                          )}
                        </span>
                      </div>
                    );
                  }

                  return (
                    <div
                      className={styles["placeholder-container"]}
                      key={key}
                      style={style}
                    >
                      <span className={styles.placeholder}></span>
                    </div>
                  );
                }}
                onScroll={({ scrollLeft, scrollTop }) =>
                  this._onDatatableScroll({ scrollTop, scrollLeft })
                }
                scrollLeft={this.scroll.scrollLeft}
                scrollTop={this.scroll.scrollTop}
                fixedRowCount={1}
                columnCount={columnCount}
                columnWidth={columnWidth}
                rowCount={rowCount}
                rowHeight={rowHeight}
                width={gridWidth}
                height={gridHeight}
                onSectionRendered={this._onSectionRendered}
                ref={el => (this.multiGridElement = el)}
              ></MultiGrid>
            );
          }}
        </AutoSizer>
      </div>
    );
  };

  _onDatatableScroll({ scrollTop, scrollLeft }) {
    this.scroll = {
      scrollTop,
      scrollLeft
    };
  }

  _onSectionRendered = ({ rowStartIndex, rowStopIndex }) => {
    const startIndex = rowStartIndex; // https://github.com/bvaughn/react-virtualized/issues/516#issuecomment-293750982
    const stopIndex = rowStopIndex;

    return this._onRowsRendered({
      startIndex,
      stopIndex
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.state.visibleAttributes, nextState.visibleAttributes) || // gridHeader
      !_.isEqual(this.state.data, nextState.data) || // gridBody
      !_.isEqual(
        this.state.attributesAreaVisibility,
        nextState.attributesAreaVisibility
      ) || // gridColumns
      !_.isEqual(this.props.selection, nextProps.selection) || // gridSelection
      nextProps.version !== "new"
    );
  }

  render() {
    const {
      selectableNew,
      color,
      toggleable,
      hideSearchBox,
      selectionButtonKey,
      useSelection,
      remoteRowCount,
      selectionCounter = true,
      t: $τ,
      userSearch,
      userSearchAligned
    } = this.props;
    const { attributes, attributesAreaVisibility } = this.state;

    const columnVisibilityAreaClasses = classNames(
      "column-visibility-area",
      "virtualized",
      {
        hidden: !attributesAreaVisibility,
        ["virtualized-without-multipleActions"]: !selectableNew
      }
    );

    const InfiniteLoaderWrapper = () => {
      const isRowLoaded = ({ index }) => {
        return !!this.state.data[index];
      };

      const loadMoreRows = ({ startIndex, stopIndex }) => {
        const { loadMoreRowsAction } = this.props;

        return loadMoreRowsAction();
      };

      return (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={remoteRowCount}
        >
          {this._infiniteLoaderChildFunction}
        </InfiniteLoader>
      );
    };

    const SelectionCounter = ({ count }) => {
      if (selectionCounter === true) {
        return (
          <div className={styles.selectionCounterContainer}>
            <Counter count={count} description="dataTable.selected" />
            <div className={styles.actionSeparator} />
          </div>
        );
      }
      return null;
    };

    return (
      <div className="data-table-container">
        {!hideSearchBox && userSearch && (
          <input
            className={classNames(
              "pull-right",
              "form-control",
              "user-search-data-table",
              "magnifier",
              {
                "user-search-aligned-data-table": userSearchAligned
              }
            )}
            onChange={this._search}
            placeholder={$τ("dataTable.search")}
            type="search"
          />
        )}
        <div className="clearFloat">
          {toggleable && (
            <div className={classNames("toggle-area", "pull-right")}>
              <OutsideClickHandler
                onOutsideClick={() => {
                  if (attributesAreaVisibility) {
                    this.dropdownContainer.scrollTop = 0;
                    this.toggleAttributesVisibility();
                  }
                }}
              >
                <button
                  className={classNames(
                    "btn",
                    styles["toggle-area"],
                    styles["toggle-area-virtualized"],
                    {
                      "toggle-area-btn": attributesAreaVisibility,
                      [styles[
                        "toggle-area-without-multipleActions"
                      ]]: !selectableNew
                    }
                  )}
                  onClick={this.toggleAttributesVisibility}
                >
                  <span
                    className={classNames(
                      "glyphicon",
                      "icon",
                      "icon-plus",
                      styles["toggle-icon"]
                    )}
                  />
                </button>

                <div className={columnVisibilityAreaClasses}>
                  <div className="input-group checkbox-all">
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          _.keys(this.getVisibleAttributes()).length ===
                          attributes.length
                        }
                        onChange={this._toggleAll}
                      />
                      <span>All</span>
                    </label>
                  </div>
                  <div
                    ref={container => (this.dropdownContainer = container)}
                    className={"checkboxes-container"}
                  >
                    {attributes.map(attribute => (
                      <div
                        key={`toggle-${attribute}`}
                        className={`input-group toggle-${
                          this.isExtraAttribute(attribute) ? "extra" : "attr"
                        }`}
                      >
                        <label>
                          <input
                            id={`toggle-${attribute}`}
                            type="checkbox"
                            checked={this.state.visibleAttributes[attribute]}
                            onChange={event =>
                              this._toggleVisibility(attribute, event)
                            }
                          />
                          {this.headerI18n(attribute)}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          )}

          {!hideSearchBox && !userSearch && (
            <input
              className={classNames(
                "pull-right",
                "form-control",
                "search-data-table",
                "magnifier"
              )}
              onChange={this._search}
              placeholder={$τ("dataTable.search")}
              type="search"
            />
          )}

          {!useSelection && selectableNew && (
            <div
              className={classNames(styles.multipleActions, {
                [styles.multipleActionsHidden]:
                  this.state.selection.length === 0
              })}
            >
              {selectionButtonKey.map((SelectionElement, index) => (
                <SelectionElement
                  key={`list.selection.${index}`}
                  selectionLength={this.state.selection.length}
                />
              ))}
            </div>
          )}

          {useSelection && selectableNew && (
            <div
              className={classNames(styles.multipleActions, {
                [styles.multipleActionsHidden]:
                  this.props.selection.length === 0
              })}
            >
              <SelectionCounter count={this.props.selection.length} />
              {selectionButtonKey.map((SelectionElement, index) => (
                <SelectionElement
                  key={`list.selection.${index}`}
                  selectionLength={this.props.selection.length}
                />
              ))}
            </div>
          )}
        </div>

        <InfiniteLoaderWrapper />

        <style>
          {`
                    .btn-ingenico, .btn-ingenico:focus{
                        border-color: ${color};
                    }
                    .disable-row {
                        background-color: #F2F2F2 !important;
                        color: #CCC!important;
                    }
                    .data-table table thead th {
                      white-space: nowrap;
                      font-size: 12px;
                      background-color: #E2E2E2;
                    }
                    .data-table table tbody tr td {
                      font-size:12px;
                      line-height:1.2em;
                      padding:12px 7px 7px;
                    }
                    .data-table table tbody tr:hover {
                      background-color:#FFF;
                    }
                    .data-table table tbody td:last-child {
                      padding-top:5px;
                    }

                    /*Style loader*/
                    .load-bar,
                    .spinner .double-bounce1,
                    .load-bar .bar2 {
                      background-color: ${color}!important;
                    }
                    .load-bar .bar1 {
                      background-color: #BDC3C7;
                    }
                    .load-bar .bar3 {
                      background-color: #D2D7D3;
                    }
                    input[type=checkbox].selectAllNewInput:checked + label,
                    input[type=checkbox].selectionCheckboxNewInput:checked + label {
                      background: ${color};
                    }
                    .column-visibility-area {
                      input[type=checkbox] + label,
                      input[type=checkbox]:checked + label {
                        background: ${color};
                      }
                    }
                    .${styles["triangle-active"]}:before {
                      background: ${color};
                    }
                `}
        </style>
      </div>
    );
  }
}

DataTable.defaultProps = {
  hideSearchBox: false,
  userSearch: false,
  headerElVirtualized: ({
    key,
    style,
    attribute,
    icon,
    sortFunc,
    renderAttributeFunc,
    sortable
  }) => (
    <div
      key={key}
      style={style}
      className={classNames(
        `grid-header header-${Sanitizer.className(attribute)}`,
        sortable ? "sortable" : ""
      )}
      onClick={() => {
        return sortable && sortFunc(attribute);
      }}
    >
      {renderAttributeFunc(attribute)}
      {sortable && icon}
    </div>
  ),
  hasActions: true,
  selectable: false,
  loadMoreRowsAction: () => undefined
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user }
  } = state;

  return {
    user
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, null)
)(DataTable);
