import React, { Component } from "react";
import Autocomplete from "../../../components/Autocomplete";

import * as _ from "lodash";

interface Props {
  id: string;
  className: string;
  name: string;
  value: any;
  options: OptionType;
  onChange: Function;
  readOnly: boolean;
}

interface State {
  tags: any;
}

interface OptionType {
  name: string;
  id: string;
}

// TODO : Migrate value structure's object to be same as options
class MultipleSelect extends Component<Props, State> {
  state = {
    value: {}
  };

  _onChange = values => {
    const { onChange, name } = this.props;

    onChange(name, values, this);
  };

  _filterFunction = (allSuggestions, selectedValues) => {
    return _.chain(allSuggestions)
      .reject(suggestion =>
        _.chain(selectedValues)
          .pluck("value")
          .contains(suggestion.value)
          .value()
      )
      .value();
  };

  render() {
    const { options, id, className, value, readOnly } = this.props;

    const lists = {
      beginBy: "properties",
      properties: {
        options: options.map(l => {
          return {
            label: l.name,
            value: l.id
          };
        })
      }
    };

    return (
      <Autocomplete
        id={id || ""}
        className={className || ""}
        createdItems={value}
        lists={lists}
        onChange={this._onChange}
        allowFreeText={false}
        createdItemLabelFormatter={item => item.label}
        filterFunction={this._filterFunction}
        disabled={readOnly}
      />
    );
  }
}

export default MultipleSelect;
