import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../i18n/components/I18nSpan";
import HelpBanner from "../../components/HelpBanner";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import * as $ from "jquery";
import {
  uploadLogo,
  addNotificationSuccess,
  addNotificationError,
  getLogo
} from "../../redux/actions";

interface Props {
  timestamp: any;
  encType: any;
  okLabel: any;
  logoContent: any;
  preview: any;
  uploadLogo: Function;
}

interface Form {
  logo: string;
}

interface State {
  preview: any;
  form: Form;
}

class LogoCustomerView extends Component<Props, State> {
  state = {
    form: {
      logo: ""
    }
  } as State;

  _logoChangeHandler = e => {
    e.preventDefault();

    const target = e.target;
    const domTarget: any = $(target)[0];

    this.setState({
      preview: window.URL.createObjectURL(domTarget.files[0]),
      form: {
        logo: target.value
      }
    });
  };

  _submitForm = async e => {
    e.preventDefault();

    const {
      uploadLogo,
      history,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    const {
      logo: { files = [] }
    } = e.target;

    if (files.length === 0) {
      return addNotificationError("notification.logo.error.missingFile");
    }

    const data = new FormData();
    data.append("logo", files[0]);

    try {
      await uploadLogo({ data });
      await getLogo();
      addNotificationSuccess("customer.logo.success");

      return history.push("/main");
    } catch (promiseError) {
      addNotificationError("notification.error");
    }
  };

  _goToHome = e => {
    e.preventDefault();

    const { history } = this.props;

    return history.push("/main");
  };

  render() {
    const { encType, okLabel } = this.props;
    const {
      preview,
      form: { logo }
    } = this.state;

    return (
      <div>
        <FormTitle
          titleKey="customer.settings.title"
          actionKey="customer.logo.action"
        />

        <HelpBanner>
          <I18nSpan msgKey="customer.logo.help" />
        </HelpBanner>

        <form
          onSubmit={this._submitForm}
          className={"ingenico-form customer-form upload-input"}
          noValidate="novalidate"
          encType={encType}
        >
          <div className="uploadBox form-inline">
            <h4>
              <I18nSpan msgKey="customer.logo.logoPreview" />
            </h4>
            <div className="form-group">
              <figure className="imagePreview">
                <img src={preview} className="logo-type" />
              </figure>
            </div>
          </div>

          <div id="logoContainer">
            <input
              type="file"
              id="logo"
              name="logo"
              className="form-control input-type-file"
              value={logo}
              placeholder=""
              onChange={this._logoChangeHandler}
              accept="image/*"
            />
          </div>

          <div className="pull-right form-buttons">
            <button className="btn btn-ingenico save-button">
              <I18nSpan msgKey={okLabel || "button.label.ok"} />
            </button>
            <button
              onClick={this._goToHome}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    theme: {
      color: {
        data: { color }
      },
      logo: { data: logoContent }
    }
  } = state;

  const timestamp = new Date().getTime();

  return {
    timestamp,
    logoContent,
    preview: logoContent,
    color
  };
};

const mapDispatchToProps = dispatch => ({
  uploadLogo: ({ data }) => dispatch(uploadLogo({ data })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getLogo: () => dispatch(getLogo())
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LogoCustomerView);
