import {
  PENDING_GET_MERCHANT_CATEGORY_CODE_ACTION,
  SUCCESS_GET_MERCHANT_CATEGORY_CODE_ACTION,
  FAILURE_GET_MERCHANT_CATEGORY_CODE_ACTION
} from "../actionsTypes";

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_MERCHANT_CATEGORY_CODE_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_MERCHANT_CATEGORY_CODE_ACTION:
      const { merchantCategoryCodes } = action.payload;
      return {
        ...state,
        data: [...merchantCategoryCodes]
      };
    case FAILURE_GET_MERCHANT_CATEGORY_CODE_ACTION:
      const errors = action.payload;
      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    default:
      return state;
  }
};
