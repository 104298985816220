import React from "react";
import I18nSpan from "../../../../i18n/components/I18nSpan";
import PredefinedConstants from "../../PredefinedAlertConstant";
import {
  CreateRefundTooHighAlert,
  RefundTooHighAlert
} from "../../model/RefundTooHighAlert";

interface Props {
  onChange: (alert: CreateRefundTooHighAlert) => void;
  currencySymbol?: string;
  currencyExponent?: number;
}

interface State {
  minValueThreshold?: number;
  maxValueThreshold?: number;
  form?: any;
}

export default class RefundTooHigh extends React.Component<Props, State> {
  state: State = {
    minValueThreshold: 1,
    maxValueThreshold: Number.MAX_SAFE_INTEGER,
    form: {
      valueThreshold:
        PredefinedConstants.DEFAULT_VALUE_THRESHOLD /
        Math.pow(10, this.props.currencyExponent)
    }
  };

  private getNumberOrMinimum = (input: HTMLInputElement) => {
    if (input.value === "0" || input.value.trim() === "") {
      return input.name === "valueThreshold"
        ? this.state.minValueThreshold
        : input.value;
    } else return input.value;
  };

  private onChange = (e: Event) => {
    const newForm = this.state.form;
    const input = e.target as HTMLInputElement;
    newForm[input.name] = this.getNumberOrMinimum(input);
    this.setState(
      {
        form: newForm
      },
      this.createAlertFromState
    );
  };

  private createAlertFromState = () => {
    const refundTooHighAlert = new CreateRefundTooHighAlert(
      parseInt(this.state.form.valueThreshold) *
        Math.pow(10, this.props.currencyExponent),
      this.props.currencySymbol,
      this.props.currencyExponent
    );
    this.props.onChange(refundTooHighAlert);
  };

  private onNumberInputClick = (e: Event) => {
    const input = e.target as HTMLInputElement;
    input.select();
  };

  render() {
    return (
      <div id="alert-refund-too-high" className="alert-item">
        <I18nSpan
          msgKey={`alert.predefined.${RefundTooHighAlert.I18N_KEY}.create-1`}
        />
        <span>{this.props.currencySymbol}</span>
        <input
          type="number"
          name="valueThreshold"
          onChange={this.onChange}
          value={this.state.form.valueThreshold}
          onClick={this.onNumberInputClick}
          min={this.state.minValueThreshold}
          max={this.state.maxValueThreshold}
        />
      </div>
    );
  }
}
