import {
  PENDING_GET_VERSION_ACTION,
  SUCCESS_GET_VERSION_ACTION,
  FAILURE_GET_VERSION_ACTION
} from "../actionsTypes";

const getVersionStart = () => ({
  type: PENDING_GET_VERSION_ACTION
});

const getVersionSucceeded = version => ({
  type: SUCCESS_GET_VERSION_ACTION,
  payload: {
    version
  }
});

const getVersionFailure = errors => ({
  type: FAILURE_GET_VERSION_ACTION,
  payload: {
    errors
  }
});

export const getVersion = () => async dispatch => {
  dispatch(getVersionStart());

  const { default: F } = await import("../../commons/HTTPFetcher");

  return F.get("/version").then(
    ({ version }) => {
      dispatch(getVersionSucceeded(version));

      return version;
    },
    errors => {
      dispatch(getVersionFailure({ errors }));

      throw errors;
    }
  );
};
