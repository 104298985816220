import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import TagUtil from "../../tag/model/TagUtil";

import {
  FAILURE_ADD_CATALOGITEM_ACTION,
  PENDING_ADD_CATALOGITEM_ACTION,
  SUCCESS_ADD_CATALOGITEM_ACTION,
  RESET_CATALOGITEM_STATE_ACTION,
  PENDING_UPDATE_CATALOGITEM_ACTION,
  SUCCESS_UPDATE_CATALOGITEM_ACTION,
  FAILURE_UPDATE_CATALOGITEM_ACTION,
  PENDING_GET_CATALOGITEM_ACTION,
  SUCCESS_GET_CATALOGITEM_ACTION,
  FAILURE_GET_CATALOGITEM_ACTION,
  PENDING_UPLOAD_CATALOGITEM_PHOTO_ACTION,
  SUCCESS_UPLOAD_CATALOGITEM_PHOTO_ACTION,
  FAILURE_UPLOAD_CATALOGITEM_PHOTO_ACTION,
  PENDING_GET_PHOTO_ACTION,
  SUCCESS_GET_PHOTO_ACTION,
  FAILURE_GET_PHOTO_ACTION,
  PENDING_GET_MEASURES_ACTION,
  SUCCESS_GET_MEASURES_ACTION,
  FAILURE_GET_MEASURES_ACTION
} from "../actionsTypes";

export const createCatalogItem = (catalogItem: any) => async dispatch => {
  dispatch(addCatalogItemStart());

  try {
    const newSelectedTags = TagUtil.selectedTagsToObject(
      catalogItem.selectedTags
    );
    const catalogItemList = Object.assign(catalogItem, {
      selectedTags: newSelectedTags
    });
    const data = await F.post("/catalog/item", {
      body: catalogItemList,
      contentType: ContentTypes.JSON
    });

    return dispatch(addCatalogItemSucceeded(data));
  } catch (errors) {
    dispatch(addCatalogItemFailure({ errors }));
    throw errors;
  }
};

export const addCatalogItemStart = () => ({
  type: PENDING_ADD_CATALOGITEM_ACTION
});

export const addCatalogItemSucceeded = (catalogItem: any) => ({
  type: SUCCESS_ADD_CATALOGITEM_ACTION,
  payload: {
    catalogItem
  }
});

export const addCatalogItemFailure = (errors: any) => ({
  type: FAILURE_ADD_CATALOGITEM_ACTION,
  payload: {
    errors
  }
});

export const editCatalogItem = catalogItem => async dispatch => {
  dispatch(editCatalogItemStart());

  try {
    const newSelectedTags = TagUtil.selectedTagsToObject(
      catalogItem.selectedTags
    );
    const catalogItemList = Object.assign(catalogItem, {
      selectedTags: newSelectedTags
    });
    const data = await F.put(`/catalog/item/${catalogItem.id}`, {
      body: catalogItemList,
      contentType: ContentTypes.JSON
    });

    return dispatch(editCatalogItemSucceeded(data));
  } catch (errors) {
    dispatch(editCatalogItemFailure({ errors }));
    throw errors;
  }
};

export const editCatalogItemStart = () => ({
  type: PENDING_UPDATE_CATALOGITEM_ACTION
});

export const editCatalogItemSucceeded = catalogItem => ({
  type: SUCCESS_UPDATE_CATALOGITEM_ACTION,
  payload: {
    catalogItem
  }
});

export const editCatalogItemFailure = errors => ({
  type: FAILURE_UPDATE_CATALOGITEM_ACTION,
  payload: {
    errors
  }
});

export const getCatalogItem = (id: Number) => async dispatch => {
  dispatch(getCatalogItemStart());

  try {
    const data = await F.get(`/catalog/item/${id}`);

    dispatch(getCatalogItemSucceeded(data));
  } catch (errors) {
    dispatch(getCatalogItemFailure(errors));
    throw errors;
  }
};

export const getCatalogItemStart = () => ({
  type: PENDING_GET_CATALOGITEM_ACTION
});

export const getCatalogItemSucceeded = catalogItem => ({
  type: SUCCESS_GET_CATALOGITEM_ACTION,
  payload: {
    catalogItem
  }
});

export const getCatalogItemFailure = errors => ({
  type: FAILURE_GET_CATALOGITEM_ACTION,
  payload: {
    errors
  }
});

export const uploadPhoto = ({ id, data }) => async dispatch => {
  dispatch(uploadPhotoStart());

  try {
    await F.post(`/catalog/item/${id}/photo`, {
      body: data
    });

    return dispatch(uploadPhotoSucceeded());
  } catch (errors) {
    dispatch(uploadPhotoFailure({ errors }));

    throw errors;
  }
};

const uploadPhotoStart = () => ({
  type: PENDING_UPLOAD_CATALOGITEM_PHOTO_ACTION
});

const uploadPhotoSucceeded = () => ({
  type: SUCCESS_UPLOAD_CATALOGITEM_PHOTO_ACTION
});

const uploadPhotoFailure = errors => ({
  type: FAILURE_UPLOAD_CATALOGITEM_PHOTO_ACTION,
  payload: {
    errors
  }
});

export const resetCatalogItemState = () => ({
  type: RESET_CATALOGITEM_STATE_ACTION
});

export const getPhoto = (id: Number) => async dispatch => {
  dispatch(getPhotoStart());

  try {
    const data = await F.get(`/catalog/item/${id}/photo `);

    dispatch(getPhotoSucceeded(data));
  } catch (errors) {
    dispatch(getPhotoFailure(errors));
    throw errors;
  }
};

export const getPhotoStart = () => ({
  type: PENDING_GET_PHOTO_ACTION
});

export const getPhotoSucceeded = photo => ({
  type: SUCCESS_GET_PHOTO_ACTION,
  payload: {
    photo
  }
});

export const getPhotoFailure = errors => ({
  type: FAILURE_GET_PHOTO_ACTION,
  payload: {
    errors
  }
});

export const getMeasures = () => async dispatch => {
  dispatch(getMeasuresStart());

  try {
    const data = await F.get("/catalog/measure");

    dispatch(getMeasuresSucceeded(data));
  } catch (errors) {
    dispatch(getMeasuresFailure(errors));
    throw errors;
  }
};

export const getMeasuresStart = () => ({
  type: PENDING_GET_MEASURES_ACTION
});

export const getMeasuresSucceeded = measures => ({
  type: SUCCESS_GET_MEASURES_ACTION,
  payload: {
    measures
  }
});

export const getMeasuresFailure = errors => ({
  type: FAILURE_GET_MEASURES_ACTION,
  payload: {
    errors
  }
});
