import React from "react";
import I18nSpan from "../../i18n/components/I18nSpan";

export const downloadSuccess = {
  title: "",
  children: <I18nSpan msgKey={"reporting.taxAudit.download.success"} />,
  autoDismiss: 3
};

export const downloadInfo = {
  title: "",
  children: <I18nSpan msgKey={"reporting.taxAudit.download.info"} />,
  autoDismiss: 3
};

export const downloadFailure = {
  title: "",
  children: <I18nSpan msgKey={"reporting.taxAudit.download.failure"} />,
  autoDismiss: 3
};
