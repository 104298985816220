import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "redux";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container/Container";

import Div100vh from "../Div100vh/Div100vh";

const HomeMobileView = ({ children, darkMode: prefersDarkMode }) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: prefersDarkMode ? "#252525" : "#ededed",
        height: "calc(var(--vh, 1vh) * 100)",
        overflow: "scroll"
      }
    })
  );

  const classes = useStyles();

  const matches = useMediaQuery(`(max-width: 768px)`);

  if (matches) {
    return (
      <Div100vh>
        <Container disableGutters className={classes.root}>
          {children}
        </Container>
      </Div100vh>
    );
  }

  return null;
};

const mapStateToProps = (state, ownProps) => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, null)
)(HomeMobileView);
