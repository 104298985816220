import React from "react";
import classNames from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "./MobileHeader.css";

const MobileHeader = ({ user, logoContent, darkMode: prefersDarkMode }) => {
  const matches = useMediaQuery(`(max-width: 768px)`);
  const name = user ? user.name || user.login : "";

  if (matches) {
    return (
      <Container disableGutters>
        <Grid
          className={styles["header-container"]}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid
            className={styles["label-container"]}
            item
            direction="column"
            justify="space-between"
            alignItems="center"
          >
            <Grid
              className={classNames(styles["hello-label"], {
                [styles["hello-label--dark"]]: prefersDarkMode,
                [styles["hello-label--light"]]: !prefersDarkMode
              })}
              item
            >
              <I18nSpan msgKey="header.portal.helloName" />
            </Grid>
            <Grid
              item
              className={classNames(styles["name-user"], {
                [styles["name-user--dark"]]: prefersDarkMode,
                [styles["name-user--light"]]: !prefersDarkMode
              })}
            >
              {name}
            </Grid>
          </Grid>
          <Grid item>
            <img src={logoContent} className={styles["logo-content"]} />
          </Grid>
        </Grid>
      </Container>
    );
  }
  return null;
};

const mapStateToProps = (state, ownProps) => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, null)
)(MobileHeader);
