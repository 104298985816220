import * as _ from "lodash";
import Manufacturers from "../pos/constants/Manufacturers";
import Poi from "../poi/constants/PoiFieldConstants";

const commonFormFields = [
  Poi.SERIAL_NUMBER,
  Poi.TERMINAL_REFERENCE,
  Poi.MERCHANT_ID
];
const ingenicoFormFields = [Poi.PART_NUMBER];
const verifoneFormFields = [Poi.PART_NUMBER];

export default {
  manufacturerNameFromCode(manufacturerCode): string {
    return _.head(
      _(Manufacturers.ALL)
        .filter(m => m.code === manufacturerCode)
        .map(m => m.name)
        .value()
    );
  },

  computeShownFormFields(manufacturerCode) {
    if (manufacturerCode === Manufacturers.INGENICO_CODE) {
      return _.union(commonFormFields, ingenicoFormFields);
    } else if (manufacturerCode === Manufacturers.VERIFONE_CODE) {
      return _.union(commonFormFields, verifoneFormFields);
    } else if (
      manufacturerCode === Manufacturers.OGONE_CODE ||
      manufacturerCode === Manufacturers.VIRTUAL_CODE ||
      manufacturerCode === Manufacturers.LANDI_CODE
    ) {
      return commonFormFields;
    }

    return [];
  },

  hideFormField(shownFormFieldsForMerchant) {
    return formField => !_.includes(shownFormFieldsForMerchant, formField);
  }
};
