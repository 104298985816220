import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../i18n/components/I18nSpan";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";

import {
  addNotificationSuccess,
  addNotificationError,
  updatePassword
} from "../../redux/actions";

interface Props {
  history: any;
  color: any;
  showPwdBtnI18n: any;
  login: any;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  updatePassword: Function;
}

interface State {
  type: string;
  showPwdBtnI18n: string;

  form: {
    password: string | null;
    newPassword: string | null;
    repeatNewPassword: string | null;
  };
  errors: any;
}

class EditPasswordView extends Component<Props, State> {
  state = {
    type: "password",
    form: {
      password: null,
      newPassword: null,
      repeatNewPassword: null
    },
    errors: {}
  } as State;

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  _displayPasswordFields = () => {
    const { t } = this.props;
    const { type: passwordType } = this.state;
    const newState =
      passwordType === "password"
        ? {
            type: "text",
            showPwdBtnI18n: t("user.passwordForm.hidePwd")
          }
        : {
            type: "password",
            showPwdBtnI18n: t("user.passwordForm.showPwd")
          };

    return this.setState(newState);
  };

  async errorNotification(error) {
    const { addNotificationError } = this.props;
    const { errorKey } = await error;

    switch (errorKey) {
      case "user.password.already.used":
        return addNotificationError(
          "user.passwordForm.error.password.alreadyUsed"
        );
      case "user.password.malformed":
        return addNotificationError("user.passwordForm.error.global");
      case "user.invalid.form":
        return addNotificationError("user.passwordForm.error.global");
      case "user.password.mismatch":
        return addNotificationError(
          "user.passwordForm.error.password.mismatch"
        );
      case "user.password.renewal.period.reach":
        return addNotificationError(
          "user.passwordForm.error.password.renewalPeriodReached"
        );
      default:
        addNotificationError(errorKey);
    }
  }

  _saveNewPassword = e => {
    e.preventDefault();

    const {
      history,
      addNotificationSuccess,
      addNotificationError,
      login,
      updatePassword
    } = this.props;
    const { form } = this.state;

    this.checkErrors(() => {
      const hasErrors = _.any(form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const { password, newPassword, repeatNewPassword } = form;
        if (newPassword !== repeatNewPassword) {
          this.inputRefs["newPassword"].refs.Input.focus();

          return addNotificationError("user.passwordForm.error.pwdNotEquals");
        }
        return updatePassword({ login, password, newPassword })
          .then(() => {
            addNotificationSuccess("user.passwordForm.success");
            return history.push("/main");
          })
          .catch((error: any) => this.errorNotification(error));
      }
    });
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  checkErrors(then) {
    const waitForValidation = (nameList: Array<string>, newErrors) => {
      if (_.isEmpty(nameList)) {
        this.setState({ errors: newErrors }, then);
      } else {
        const name = _.first(nameList);
        const value = _.get(this.state.form, name);
        const refsValue = _.get(this.inputRefs, name);

        const errorPromises = refsValue
          ? [refsValue.props.validation(value)]
          : [this._noop()];
        Promise.all(errorPromises).then(errors => {
          newErrors[name] = _.chain(errors)
            .flatten()
            .compact()
            .value();
          waitForValidation(_.tail(nameList), newErrors);
        });
      }
    };
    waitForValidation(_.keys(this.state.form), {});
  }

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm);
  };

  render() {
    const PasswordTooltip = () => {
      return (
        <ReactTooltip
          id="password-help"
          delayHide={1000}
          place="left"
          type="info"
          effect="solid"
        >
          <I18nSpan msgKey="resetPassword.help.title" />
          <ul style={{ listStyle: "disc", paddingLeft: "25px" }}>
            <li>
              <I18nSpan msgKey="resetPassword.help.compliance1" />
            </li>
            <li>
              <I18nSpan msgKey="resetPassword.help.compliance2" />
            </li>
            <ul style={{ listStyle: "circle", paddingLeft: "25px" }}>
              <li>
                <I18nSpan msgKey="resetPassword.help.category1" />
              </li>
              <li>
                <I18nSpan msgKey="resetPassword.help.category2" />
              </li>
              <li>
                <I18nSpan msgKey="resetPassword.help.category3" />
              </li>
              <li>
                <I18nSpan msgKey="resetPassword.help.category4" />
              </li>
            </ul>
          </ul>
        </ReactTooltip>
      );
    };

    const { color, t } = this.props;
    const { type: passwordTypeField, form } = this.state;

    return (
      <div>
        <FormTitle
          titleKey="user.updateSettings.title"
          actionKey="user.updateSettings.action"
        />
        <div className="updatePasswordForm-container">
          <form
            className="ingenico-form form-horizontal edit-password-form"
            noValidate
            encType="application/x-www-form-urlencoded"
            onSubmit={this._saveNewPassword}
          >
            {/*Workaround to avoid password prefill*/}
            <input
              type="password"
              style={{ display: "none" }}
              name="autocompleteOff"
            />

            <BootstrapInput
              required={true}
              onChange={this._onChangeHandler}
              inputRef={this.setInputRef("password")}
              name="password"
              placeholder={t(`user.passwordForm.actualPwd`)}
              descriptor={{
                type: passwordTypeField,
                label: "user.passwordForm.actualPwd",
                id: "password"
              }}
              formValue={form.password}
            />

            <BootstrapInput
              required={true}
              onChange={this._onChangeHandler}
              inputRef={this.setInputRef("newPassword")}
              name="newPassword"
              placeholder={t(`user.passwordForm.newPwd`)}
              descriptor={{
                type: passwordTypeField,
                label: "user.passwordForm.newPwd",
                id: "newPassword"
              }}
              formValue={form.newPassword}
            >
              <span
                data-tip
                data-for="password-help"
                className="react-tooltip glyphicon glyphicon-question-sign"
              />
              <PasswordTooltip />
            </BootstrapInput>

            <BootstrapInput
              required={true}
              onChange={this._onChangeHandler}
              inputRef={this.setInputRef("repeatNewPassword")}
              name="repeatNewPassword"
              id="repeatNewPassword"
              placeholder={t(`user.passwordForm.newPwd2`)}
              descriptor={{
                type: passwordTypeField,
                label: "user.passwordForm.newPwd2",
                onPaste: e => {
                  e.preventDefault();
                },
                id: "repeatNewPassword"
              }}
              formValue={form.repeatNewPassword}
            />

            <div className="form-group">
              <div className="col-sm-12 text-right">
                <a onClick={this._displayPasswordFields} className="btn">
                  <span>{t("user.passwordForm.showPwd")}</span>
                </a>
                <button className="btn btn-ingenico save-button">
                  <span>
                    <I18nSpan msgKey="user.passwordForm.savePwd" />
                  </span>
                </button>
                <Link to={"/main"} className="btn btn-ingenico save-button">
                  <span>
                    <I18nSpan msgKey="button.label.exit" />
                  </span>
                </Link>
              </div>
            </div>
          </form>

          <style>
            {`
                        .btn-ingenico,.btn-ingenico:focus{
                            color: ${color};
                            border-color: ${color};
                        }
                        .btn-ingenico:hover {
                            color: #FFF;
                            background-color: ${color};
                        }
                        a.btn{
                            margin-right: 30px;
                        }
                    `}
          </style>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: { login = "" }
    },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  return {
    color,
    login
  };
};

const mapDispatchToProps = dispatch => ({
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  updatePassword: ({ login, password, newPassword }) =>
    dispatch(updatePassword({ login, password, newPassword }))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(EditPasswordView);
