import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";
import numeral from "numeral";
import _ from "lodash";

import {
  supported as fallbackLng,
  numeralLanguages
} from "../assets/languages/languages.json";

import { generateUrl } from "./commons/utils/url";

const options = {
  backend: {
    loadPath: generateUrl({ url: "/i18n/{{lng}}" })
  },

  fallbackLng, // use en if detected lng is not available

  interpolation: {
    escapeValue: false // react already safes from xss
  }
};

const changeNumeralLang = lang => {
  if (_.includes(numeralLanguages, lang)) {
    numeral.locale(lang);
  }
};

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(options, (err, _t) => {
    changeNumeralLang(i18n.languages[1]);
  });

export const changeLang = async lang => {
  i18n.changeLanguage(lang);
  changeNumeralLang(lang);
  const { default: setApexChartDefaultLocale } = await import("./apexcharts");
  setApexChartDefaultLocale(lang);
};

export default i18n;
