import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_PALETTES_ACTION,
  SUCCESS_GET_PALETTES_ACTION,
  FAILURE_GET_PALETTES_ACTION
} from "../actionsTypes";

export const getPalettes = () => async dispatch => {
  dispatch(getPalettesStart());

  return F.get("/colorPalette", "v2").then(
    palettes => {
      dispatch(getPalettesSucceeded(palettes));
      return palettes;
    },
    errors => {
      dispatch(getPalettesFailure({ errors }));

      throw errors;
    }
  );
};

const getPalettesStart = () => ({
  type: PENDING_GET_PALETTES_ACTION
});

const getPalettesSucceeded = palettes => ({
  type: SUCCESS_GET_PALETTES_ACTION,
  payload: {
    palettes
  }
});

const getPalettesFailure = errors => ({
  type: FAILURE_GET_PALETTES_ACTION,
  payload: {
    errors
  }
});
