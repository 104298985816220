import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../i18n/components/I18nSpan";
import BootstrapInput from "../ingenicoForm/components/BootstrapInput";
import FormTitle from "../ingenicoForm/components/FormTitle";

import {
  getUsers,
  addNotificationSuccess,
  addNotificationError,
  sendTransactionReceipt
} from "../redux/actions";

interface Props {
  history: any;
  getUsers: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  sendCardholderReceipt: Function;
  match: any;
  color: any;
  recipients: any;
}

interface State {
  sendMeCopy: boolean;
  mailBody: any;
  selectedRecipients: any;
}

class CardholderReceiptSendView extends Component<Props, State> {
  state = {
    sendMeCopy: true,
    mailBody: "",
    selectedRecipients: []
  };

  componentDidMount() {
    const { getUsers } = this.props;

    return getUsers();
  }

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  sendBtnHandler = e => {
    e.preventDefault();

    let sendReceiptAction;
    const {
      match: {
        params: { transactionId: id, receiptType: receiptActionType }
      },
      sendCardholderReceipt
    } = this.props;
    const { sendMeCopy: sendCopy, mailBody, selectedRecipients } = this.state;
    const recipients = selectedRecipients.map(r => r.value);

    sendReceiptAction = sendCardholderReceipt({
      id,
      recipients,
      sendCopy,
      mailBody,
      receiptActionType
    });

    sendReceiptAction
      .then(() => {
        addNotificationSuccess("receipt.send.notification.success");
        this.props.history.push("/main/reporting/transaction");
      })
      .catch(error =>
        addNotificationError("receipt.send.notification.error", [
          error.responseText
        ])
      );
  };

  changeSendMeCopy = () => {
    this.setState({ sendMeCopy: !this.state.sendMeCopy });
  };

  onExit = e => {
    e.preventDefault();
    const { history } = this.props;
    history.push("/main/reporting/transaction");
  };

  render() {
    const { color, recipients } = this.props;
    const { selectedRecipients, mailBody, sendMeCopy } = this.state;

    return (
      <div id="receipt-send">
        <FormTitle
          color={color}
          titleKey="receipt.title"
          actionKey="receipt.send.subtitle.cardholder"
        />
        <form className="ingenico-form form-horizontal">
          <BootstrapInput
            descriptor={{
              type: "multipleselect",
              options: recipients,
              label: "receipt.send.recipients",
              className: "mtn"
            }}
            name="selectedRecipients"
            validation={_ => {}}
            onChange={this.onChange}
            formValue={selectedRecipients}
          />

          <BootstrapInput
            descriptor={{
              type: "textarea",
              label: "receipt.send.mailBody"
            }}
            name="mailBody"
            validation={_ => {}}
            onChange={this.onChange}
            formValue={mailBody}
          />

          <div className="form-group">
            <label className="control-label col-sm-3" htmlFor="send-copy">
              <I18nSpan msgKey="receipt.send.sendCopy" />
            </label>
            <div className="col-sm-6">
              <input
                id="send-copy"
                type="checkbox"
                checked={sendMeCopy}
                onChange={this.changeSendMeCopy}
              />
            </div>
          </div>
          <div className="pull-right">
            <button
              onClick={this.sendBtnHandler}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.send"} />
            </button>
            <button
              onClick={this.onExit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    theme: {
      color: { data: color }
    },
    users: { data: users }
  } = state;

  const userMails = _.uniq(users.map(u => u.email));

  return {
    recipients: userMails.map(mail => {
      return {
        id: mail,
        name: mail
      };
    }),
    color
  };
};

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(getUsers()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  sendCardholderReceipt: ({
    id,
    recipients,
    sendCopy,
    mailBody,
    receiptActionType
  }) =>
    dispatch(
      sendTransactionReceipt({
        id,
        recipients,
        sendCopy,
        mailBody,
        receiptType: "cardholder",
        receiptActionType
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CardholderReceiptSendView);
