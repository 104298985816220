import SelectedTag from "./SelectedTag";

export default class TagUtil {
  static getSelectedTags(user): Map<string, SelectedTag> {
    if (_.has(user.scope.access, "restrictedByTags")) {
      const tags = user.scope.access.restrictedByTags;
      const res = new Map<string, SelectedTag>();

      _.forOwn(tags, function(value, key) {
        res.set(key, { name: key, value: value, fixed: true });
      });

      return res;
    } else {
      return new Map<string, SelectedTag>();
    }
  }

  static selectedTagsToObject(selectedTags: Map<string, SelectedTag>): object {
    const nonNullTags = [...selectedTags.values()]
      .filter(st => !!st.value || st.value === "")
      .map(st => ({ [st.name]: st.value }));

    return Object.assign({}, ...nonNullTags);
  }

  static objectToSelectedTags(obj: object): Map<string, SelectedTag> {
    if (!obj) {
      return new Map<string, SelectedTag>();
    }
    // Can be simplified with Object.entries()
    // But needs lib es2017.object in tsconfig.json
    const entries = Object.keys(obj).map<[string, SelectedTag]>(k => [
      k,
      { name: k, value: obj[k], fixed: false }
    ]);
    return new Map<string, SelectedTag>(entries);
  }
}
