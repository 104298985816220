import {
  FAILURE_GET_SCHEMES_ACTION,
  PENDING_GET_SCHEMES_ACTION,
  SUCCESS_GET_SCHEMES_ACTION,
  SUCCESS_DELETE_SCHEME_ACTION
} from "../actionsTypes";

const initialState = {
  data: [],
  loading: false,
  error: false,
  errors: {}
};

const _onSchemeDeleted = ({ schemeId, schemes }) => {
  return schemes.filter(scheme => {
    const { id } = scheme;

    return id !== schemeId;
  });
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_SCHEMES_ACTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case SUCCESS_GET_SCHEMES_ACTION:
      const {
        payload: { schemes: data }
      } = action;

      return {
        ...state,
        ...initialState,
        data
      };
    case FAILURE_GET_SCHEMES_ACTION:
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        ...initialState,
        error: true,
        ...errors
      };
    case SUCCESS_DELETE_SCHEME_ACTION: {
      const {
        payload: { schemeId }
      } = action;
      const { data: prevSchemes } = state;
      const schemes = _onSchemeDeleted({ schemeId, schemes: prevSchemes });

      return {
        ...state,
        data: schemes
      };
    }
    default:
      return state;
  }
};
