import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import Validators from "../../ingenicoForm/validation/Validators";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import Manufacturers from "../../pos/constants/Manufacturers";
import PoiFields from "../constants/PoiFieldConstants";
import ManufacturerUtils from "../../manufacturer/Utils";

import {
  getPoi,
  getMerchants,
  resetPoi,
  editPoi,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  getMerchants: Function;
  editPoi: Function;
  getPoi: Function;
  resetPoi: Function;
  match: any;
  history: any;
  options: any;
}

interface State {
  form: any;
  errors: any;
  options: any;
}

export class EditPoiView extends Component<Props, State> {
  state = {
    form: {},
    errors: {},
    options: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(newProps: any) {
    const { form, errors, options } = newProps;

    return this.setState({
      form,
      errors,
      options
    });
  }

  _goToList = e => {
    const { history } = this.props;
    e.preventDefault();
    history.push("/main/settings/poi");
  };

  componentDidMount() {
    const {
      getMerchants,
      getPoi,
      match: {
        params: { poiId }
      }
    } = this.props;

    getPoi({ id: poiId });
    getMerchants({
      filters: [],
      fields: ["merchant.id", "merchant.name"]
    });
  }

  componentWillUnmount() {
    const { resetPoi } = this.props;

    resetPoi();
  }

  _saveBtnHandler = e => {
    e.preventDefault();

    const {
      editPoi,
      history,
      addNotificationSuccess,
      addNotificationError,
      match: {
        params: { poiId: id }
      }
    } = this.props;

    this.checkErrors(() => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const data = {
          merchantId:
            this.state.form.merchantId?.value ?? this.state.form.merchantId,
          terminalReference: this.state.form.terminalReference,
          downloadPolicy: this.state.form.downloadPolicy,
          syncDevice: this.state.form.syncDevice
        };

        editPoi({ id, poi: data })
          .then(() => {
            addNotificationSuccess("poi.edit.success");
            history.push("/main/settings/poi");
          })
          .catch(errorPromise =>
            errorPromise.then(errorData => addNotificationError(errorData))
          );
      }
    });
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this.checkErrors);
  };

  _onMerchantChangeHandler = (name, merchant) => {
    const merchantId = merchant?.value ?? null;
    const merchantName = merchant?.label ?? "";

    return this.setState(
      state => {
        return {
          form: {
            ...state.form,
            merchantId
          },
          options: {
            ...state.options,
            merchantName
          }
        };
      },
      () => this._onChangeHandler(name, merchantId)
    );
  };

  checkErrors = then => {
    const waitForValidation = (nameList: Array<string>, newErrors) => {
      if (_.isEmpty(nameList)) {
        this.setState({ errors: newErrors }, then);
      } else {
        const name = _.first(nameList);
        const value = _.get(this.state.form, name);
        const refsValue = _.get(this.inputRefs, name);

        const errors = refsValue
          ? [refsValue.props.validation(value)]
          : [this._noop()];
        Promise.all(errors).then(errors => {
          newErrors[name] = _.chain(errors)
            .flatten()
            .compact()
            .value();
          waitForValidation(_.tail(nameList), newErrors);
        });
      }
    };
    waitForValidation(_.keys(this.state.form), {});
  };

  _required = value => {
    const requiredPromise = new Promise(resolve => {
      resolve(Validators.requiredValidator(value));
    });
    return Promise.all([requiredPromise]);
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve();
    });
    return Promise.all([requiredPromise]);
  };

  render() {
    const { form, errors, options } = this.state;
    const merchantIdReadOnly = form.linked;

    const manufacturerName = ManufacturerUtils.manufacturerNameFromCode(
      form.manufacturerId || Manufacturers.INGENICO_CODE
    );
    const serialNumberI18n = `poi.form.serialNumber.${manufacturerName.toLowerCase()}`;

    const showFormFieldsForMerchant = ManufacturerUtils.computeShownFormFields(
      form.manufacturerId
    );
    const hideFormField = ManufacturerUtils.hideFormField(
      showFormFieldsForMerchant
    );

    return (
      <div>
        <FormTitle titleKey="poi.title" actionKey="poi.edit.action" />
        <form className="ingenico-form form-horizontal edit-poi-form">
          <BootstrapInput
            validation={this._required}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(PoiFields.MANUFACTURER_ID)}
            name={PoiFields.MANUFACTURER_ID}
            errors={errors.manufacturerId}
            required={true}
            descriptor={{
              type: "select",
              label: "poi.form.manufacturerId",
              options: options.manufacturers,
              readOnly: true
            }}
            formValue={form.manufacturerId}
          />
          <BootstrapInput
            validation={this._required}
            onChange={this._onChangeHandler}
            hide={hideFormField(PoiFields.SERIAL_NUMBER)}
            inputRef={this.setInputRef(PoiFields.SERIAL_NUMBER)}
            name={PoiFields.SERIAL_NUMBER}
            errors={errors.serialNumber}
            required={true}
            descriptor={{
              type: "text",
              label: serialNumberI18n,
              readOnly: true
            }}
            formValue={form.serialNumber}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            hide={hideFormField(PoiFields.PART_NUMBER)}
            inputRef={this.setInputRef(PoiFields.PART_NUMBER)}
            name={PoiFields.PART_NUMBER}
            errors={errors.partNumber}
            required={false}
            descriptor={{
              type: "text",
              label: "poi.form.partNumber",
              readOnly: true
            }}
            formValue={form.partNumber}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onMerchantChangeHandler}
            hide={hideFormField(PoiFields.MERCHANT_ID)}
            inputRef={this.setInputRef(PoiFields.MERCHANT_ID)}
            name={PoiFields.MERCHANT_ID}
            errors={errors.merchantId}
            required={false}
            descriptor={{
              type: "multipleselectwindowed",
              label: "poi.form.merchantId",
              options: options.merchants,
              readOnly: merchantIdReadOnly,
              isClearable: true
            }}
            formValue={
              form.merchantId || merchantIdReadOnly
                ? {
                    label: options.merchantName,
                    value: form.merchantId
                  }
                : undefined
            }
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            hide={hideFormField(PoiFields.TERMINAL_REFERENCE)}
            inputRef={this.setInputRef(PoiFields.TERMINAL_REFERENCE)}
            name={PoiFields.TERMINAL_REFERENCE}
            errors={errors.terminalReference}
            required={false}
            descriptor={{ type: "text", label: "poi.form.terminalReference" }}
            formValue={form.terminalReference}
          />

          <div className="pull-right">
            <button
              onClick={this._saveBtnHandler}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapMerchants = ({ merchants }) => {
  return _.map(merchants, merchant => {
    const {
      merchant: { name, id }
    } = merchant;

    return {
      name,
      id
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    merchants: { data: prevMerchants },
    poi: { data: poi = [] }
  } = state;

  const {
    match: {
      params: { linked }
    }
  } = ownProps;

  const manufacturerOptions = _.zipObject(
    _(Manufacturers.ALL)
      .map(m => [m.code, m.name])
      .value()
  );
  const merchantOptions = mapMerchants({ merchants: prevMerchants });
  const merchantName = merchantOptions.find(
    merchant => merchant.id === poi.merchantId
  )?.name;
  const options = {
    manufacturers: manufacturerOptions,
    merchants: merchantOptions,
    merchantName
  };

  return {
    form: {
      manufacturerId: poi.manufacturerId,
      serialNumber: poi.serialNumber,
      partNumber: poi.partNumber,
      merchantId: poi.merchantId || "",
      terminalReference: poi.terminalReference,
      linked
    },
    options,
    errors: {}
  };
};

const mapDispatchToProps = dispatch => ({
  getPoi: ({ id }) => dispatch(getPoi({ id })),
  editPoi: ({ id, poi }) => dispatch(editPoi({ id, poi })),
  resetPoi: () => dispatch(resetPoi()),
  getMerchants: ({ filters, fields, sort, tableCount }) =>
    dispatch(getMerchants({ filters, fields, sort, tableCount })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EditPoiView);
