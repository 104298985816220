import F from "../../commons/HTTPFetcher";

import {
  FAILURE_CREATE_POS_ACTION,
  PENDING_CREATE_POS_ACTION,
  SUCCESS_CREATE_POS_ACTION,
  RESET_POS_STATE_ACTION,
  PENDING_GET_POS_ACTION,
  SUCCESS_GET_POS_ACTION,
  FAILURE_GET_POS_ACTION,
  FAILURE_UPDATE_POS_ACTION,
  PENDING_UPDATE_POS_ACTION,
  SUCCESS_UPDATE_POS_ACTION,
  FAILURE_LOAD_DOWNLOAD_POLICIES_ACTION,
  PENDING_LOAD_DOWNLOAD_POLICIES_ACTION,
  SUCCESS_LOAD_DOWNLOAD_POLICIES_ACTION,
  FAILURE_LOAD_POI_CONTRACTS_ACTION,
  PENDING_LOAD_POI_CONTRACTS_ACTION,
  SUCCESS_LOAD_POI_CONTRACTS_ACTION
} from "../actionsTypes";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import _ from "lodash";

export const createPos = ({ pos }) => async dispatch => {
  dispatch(createPosStart());

  const { autoInit, poiContract } = pos;

  if (autoInit === "") delete pos.autoInit;
  if (poiContract === "") delete pos.poiContract;

  return F.post("/pos", {
    body: pos,
    contentType: ContentTypes.JSON
  }).then(
    posCreated => {
      dispatch(createPosSucceeded());
      return posCreated;
    },
    errors => {
      dispatch(createPosFailure(errors));

      throw errors;
    }
  );
};

const createPosStart = () => ({
  type: PENDING_CREATE_POS_ACTION
});

const createPosSucceeded = () => ({
  type: SUCCESS_CREATE_POS_ACTION
});

const createPosFailure = errors => ({
  type: FAILURE_CREATE_POS_ACTION,
  payload: {
    errors
  }
});

export const loadDownloadPolicies = () => async dispatch => {
  dispatch(loadDownloadPoliciesStart());

  try {
    const policiesList = await F.get("/downloadPolicies");

    return dispatch(loadDownloadPoliciesSucceeded({ policiesList }));
  } catch (errors) {
    dispatch(loadDownloadPoliciesFailure({ errors }));

    throw errors;
  }
};

const loadDownloadPoliciesStart = () => ({
  type: PENDING_LOAD_DOWNLOAD_POLICIES_ACTION
});

const loadDownloadPoliciesSucceeded = ({ policiesList }) => ({
  type: SUCCESS_LOAD_DOWNLOAD_POLICIES_ACTION,
  payload: {
    policiesList
  }
});

const loadDownloadPoliciesFailure = errors => ({
  type: FAILURE_LOAD_DOWNLOAD_POLICIES_ACTION,
  payload: {
    errors
  }
});

export const loadPoiContracts = () => async dispatch => {
  dispatch(loadPoiContractsStart());

  try {
    const contractsList = await F.get("/pos/poiContracts");

    return dispatch(loadPoiContractsSucceeded({ contractsList }));
  } catch (errors) {
    dispatch(loadPoiContractsFailure({ errors }));

    throw errors;
  }
};

const loadPoiContractsStart = () => ({
  type: PENDING_LOAD_POI_CONTRACTS_ACTION
});

const loadPoiContractsSucceeded = ({ contractsList }) => ({
  type: SUCCESS_LOAD_POI_CONTRACTS_ACTION,
  payload: {
    contractsList
  }
});

const loadPoiContractsFailure = errors => ({
  type: FAILURE_LOAD_POI_CONTRACTS_ACTION,
  payload: {
    errors
  }
});

export const getPos = ({ id }) => async dispatch => {
  dispatch(getPosStart());

  try {
    const pos = await F.get(`/pos/${id}`);

    return dispatch(getPosSucceeded(pos));
  } catch (errors) {
    dispatch(getPosFailure({ errors }));

    throw errors;
  }
};

const getPosStart = () => ({
  type: PENDING_GET_POS_ACTION
});

const getPosSucceeded = pos => ({
  type: SUCCESS_GET_POS_ACTION,
  payload: {
    pos
  }
});

const getPosFailure = errors => ({
  type: FAILURE_GET_POS_ACTION,
  payload: {
    errors
  }
});

export const editPos = ({ pos }) => async dispatch => {
  dispatch(editPosStart());

  const { id, poiContract } = pos;

  if (poiContract === "") delete pos.poiContract;

  return F.put(`/pos/${id}`, {
    body: pos,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(editPosSucceeded()),
    errors => {
      dispatch(editPosFailure({ errors }));

      throw errors;
    }
  );
};

const editPosStart = () => ({
  type: PENDING_UPDATE_POS_ACTION
});

const editPosSucceeded = () => ({
  type: SUCCESS_UPDATE_POS_ACTION
});

const editPosFailure = errors => ({
  type: FAILURE_UPDATE_POS_ACTION,
  payload: {
    errors
  }
});

export const resetPos = () => ({
  type: RESET_POS_STATE_ACTION
});
