import React, { Component } from "react";

import NumberFormatter from "../../formatters/NumberFormatter";

interface Props {
  number: Array<number | string>;
  format: string;
}

interface State {
  message: string;
}

class I18nNumber extends Component<Props, State> {
  static defaultProps = {
    number: 0
  };

  constructor(props) {
    super(props);

    this.state = this.renderMessage(props);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.renderMessage(nextProps));
  }

  renderMessage(props) {
    const number = parseInt(props.number) || 0;
    const message = NumberFormatter.formatNumber(number, props.format);

    return {
      message
    };
  }

  render() {
    const { message } = this.state;

    return <span className="format-number">{message}</span>;
  }
}

export default I18nNumber;
