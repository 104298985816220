import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  input: {
    color: "white",
    fontSize: "14px",
    "&$focused": {
      color: "white"
    }
  },
  inputButton: {
    color: "white"
  },
  underline: {
    color: "white",
    "&:before": {
      borderColor: "white"
    },
    "&:hover:not($disabled):before": {
      borderColor: "white"
    },
    "&:after": {
      borderColor: "white"
    }
  },
  focused: {},
  disabled: {}
});

export default function NewPasswordInput({
  label,
  showPassword,
  onChange,
  value
}) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      id="password"
      name="password"
      type={showPassword ? "text" : "password"}
      label={label}
      onChange={onChange}
      InputLabelProps={{
        classes: {
          root: classes.input,
          focused: classes.focused
        }
      }}
      InputProps={{
        classes: {
          root: classes.input,
          disabled: classes.disabled,
          underline: classes.underline
        }
      }}
      value={value}
    />
  );
}
