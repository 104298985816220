import Validators from "../../ingenicoForm/validation/Validators";

export function requiredMinLength(minLengthTranslation) {
  return value => {
    const requiredPromise = Promise.resolve(
      Validators.requiredValidator(value)
    );

    const minNameSize = 3;
    const sizePromise = Promise.resolve(
      Validators.sizeOfStringValidator(minNameSize, minLengthTranslation)(value)
    );

    const maxNameSize = 20;
    const maxSizePromise = Promise.resolve(
      Validators.maxStringValidator(maxNameSize, minLengthTranslation)(value)
    );

    return Promise.all([requiredPromise, sizePromise, maxSizePromise]);
  };
}

export function requiredFields() {
  return value => {
    const requiredPromise = Promise.resolve(
      Validators.requiredValidator(value)
    );

    return Promise.all([requiredPromise]);
  };
}

export function priceValidation() {
  return value => {
    return Promise.all([
      Validators.requiredValidator(value),
      Validators.isFloatValidator(value)
    ]);
  };
}
