import React, { Component } from "react";

interface Props {
  content: any;
  begin: any;
  end: any;
  color: string;
}

interface State {}

class Badge extends Component<Props, State> {
  render() {
    const { content = 0, begin, end, color: backgroundColor } = this.props;

    const style = { backgroundColor };

    return (
      <div className="big-badge" style={style}>
        {begin}
        <br />
        <span className="content">{content}</span>
        <br />
        {end}
      </div>
    );
  }
}

export default Badge;
