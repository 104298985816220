import React, { Component } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

const Ref = "Select";

interface Props {
  options: any;
  required: boolean;
  className: string;
  id: string;
  name: string;
  readOnly: boolean;
  value: string;
  onChange: Function;
}

class Select extends Component<Props, any> {
  componentDidMount() {
    this._setDefaultValue(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      this._setDefaultValue(nextProps);
    }
  }

  _setDefaultValue(props) {
    const { value, required, name, onChange } = props;
    if (!value && required) {
      const options = this.getOptions(props.options);

      const firstOption: any = _.first(options);
      if (firstOption) {
        onChange(name, firstOption.value);
      }
    }
  }

  _onChange = e => {
    const { onChange, name } = this.props;
    const {
      target: { value }
    } = e;
    return onChange(name, value);
  };

  getOptions(options) {
    let optionValueLabel = [];
    const buildOptionsList = (option, key) => ({ value: key, label: option });

    if (_.isArray(options)) {
      optionValueLabel = _.map(options, option =>
        buildOptionsList(option, option)
      );
    } else {
      optionValueLabel = _.map(options, buildOptionsList);
    }
    return optionValueLabel;
  }

  render() {
    const {
      options,
      required,
      className,
      id,
      name,
      readOnly,
      value
    } = this.props;
    const optionValueLabel = this.getOptions(options);
    const emptyOption = required ? "" : <option value="" />;

    return (
      <select
        className={`form-control${className ? ` ${className}` : ""}`}
        id={id ? id : ""}
        name={name}
        onChange={this._onChange}
        required={required || false}
        disabled={readOnly || false}
        value={value}
        ref={Ref}
      >
        {emptyOption}
        {_.map(optionValueLabel, (option: any) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }
}

Select.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.objectOf(PropTypes.string)
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
};

export default Select;
