import * as moment from "moment";
import { getTransactionsAgg } from "../../redux/actions/widgetData";

import { upperBoundToMonthRanges } from "../utils/BoundMonth";
import { PeriodSelection } from "../../reporting/models/TimePeriod";

export const loadTrendsTransactionCount = ({
  filters = [],
  timePeriod,
  user,
  timestamps
}: any) => {
  const ranges = upperBoundToMonthRanges(timePeriod.type, timestamps);

  const { min: begin, max: end } = timestamps;

  const timezone = user ? moment.tz(user.timezone).format("Z") : "";

  const defaultFilters = [
    {
      filterType: "array",
      name: "transactionType",
      operator: "in",
      value: ["CASH", "CARD", "WALLET", "CHECK", "DIRECTDEBIT"]
    },
    {
      filterType: "array",
      name: "selectedService",
      operator: "in",
      value: ["PAYMENT", "QUASI-CASH PAYMENT"]
    },
    {
      filterType: "array",
      name: "transactionResult",
      operator: "in",
      value: ["APPROVED", "CAPTURED"]
    }
  ];

  return getTransactionsAgg({
    restriction: "transaction",
    filters: [...filters, ...defaultFilters],
    aggregation: {
      name: "trendsTransactionCount",
      field: "mshTransactionTime",
      type: "date_range",
      timezone,
      ranges,
      subaggs: [
        {
          name: "transactionCount",
          type: "count",
          field: "transactionAmount"
        }
      ]
    }
  })
    .then(trendsTransactionCount => {
      const {
        trendsTransactionCount: {
          buckets: [
            {
              transactionCount: { value: transactionCountBefore }
            },
            {
              transactionCount: { value: transactionCountNow }
            }
          ]
        }
      } = trendsTransactionCount;

      const sales = parseInt(
        ((transactionCountNow - transactionCountBefore) /
          transactionCountBefore) *
          100,
        10
      );

      return {
        count: transactionCountNow,
        sales
      };
    })
    .catch(errors => {
      throw errors;
    });
};
