import React, { useEffect, useState } from "react";

import styles from "./QrTarget.css";
import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const mapStateToProps = state => {
  const {
    theme: { darkMode }
  } = state;

  return {
    darkMode
  };
};

const QrTarget = ({ manualMode, switchToScanner, renderScanner, loading }) => {
  const { t } = useTranslation();

  const { darkMode: prefersDarkMode } = useSelector(state =>
    mapStateToProps(state)
  );

  const useStylesButton = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: "uppercase",
        backgroundColor: prefersDarkMode ? "#303030" : "#303030",
        borderRadius: "5px",
        fontWeight: "bold",
        fontSize: "11px",
        padding: "1.5vh 4vw 1.5vh 4vw",
        color: prefersDarkMode ? "#E5E5E5" : "#FFFFFF",
        textAlign: "center",
        "&:hover": {
          backgroundColor: "#303030"
        }
      },
      contained: {}
    })
  );

  const useStylesTarget = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        borderColor: manualMode ? "#303030" : "#FFFFFF"
      }
    })
  );

  const useStylesLoader = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: "transparent",
        position: "absolute",
        right: "5vw"
      },
      container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      },
      circle: {
        stroke: "#c8c8c8"
      }
    })
  );

  const classesButton = useStylesButton();

  const classesTarget = useStylesTarget();

  const classesLoader = useStylesLoader();

  if (loading) {
    return (
      <div className={styles.container}>
        <div className={styles.target}>
          <div className={styles.containerTop}>
            <div
              className={classNames(styles.square__TL, classesTarget.root)}
            />
            <div
              className={classNames(styles.square__TR, classesTarget.root)}
            />
          </div>
          <div className={styles.containerBottom}>
            <div
              className={classNames(styles.square__BL, classesTarget.root)}
            />
            <div
              className={classNames(styles.square__BR, classesTarget.root)}
            />
          </div>
        </div>
        {loading && (
          <div className={classesLoader.container}>
            <CircularProgress
              classes={{
                circle: classesLoader.circle
              }}
              size={30}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.target}>
        <div className={styles.containerTop}>
          <div className={classNames(styles.square__TL, classesTarget.root)} />
          <div className={classNames(styles.square__TR, classesTarget.root)} />
        </div>
        <div className={styles.containerBottom}>
          <div className={classNames(styles.square__BL, classesTarget.root)} />
          <div className={classNames(styles.square__BR, classesTarget.root)} />
        </div>
      </div>
      {manualMode && (
        <div
          className={classNames({
            [styles["button-container"]]: manualMode
          })}
        >
          <Button
            classes={{
              root: classesButton.root,
              contained: classesButton.contained
            }}
            onClick={() => switchToScanner()}
          >
            {t("poi.mobile.scanqrcode")}
          </Button>
        </div>
      )}
      {!manualMode && renderScanner()}
    </div>
  );
};

export default QrTarget;
