import React, { Component } from "react";
import { connect } from "react-redux";

import AdvancedSearch from "../../advancedSearch/components/AdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";

interface Props {
  getSimsActivities: Function;
  fields: any;
  searchFilters: any;
}

interface State {
  tableCount: any;
  searchFilters: any;
}

class SimAdvancedSearch extends Component<Props, State> {
  render() {
    const { searchFilters, fields = [], searchContext, onChange } = this.props;

    return (
      <div>
        <AdvancedSearch
          name={AdvancedSearchConstants.SIM_NEW_KEY}
          searchFilters={searchFilters}
          onChange={onChange}
          genericFields={fields}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    genericFields: { data: fields },
    config: {
      data: { tableCount }
    }
  } = state;
  const searchFilters = fields
    ? fields.reduce((prevField: any, field: any) => {
        const { key, type, searchable } = field;

        if (searchable) {
          const tagsKey = /\b(selectedTags).([^.]+)/g.exec(key);
          prevField.push({
            i18nKey: `monitoringPoiSim.simActivities.list.header.${key}`,
            rawLabel: tagsKey ? tagsKey[2] : null,
            name: key,
            type
          });
        }

        return prevField;
      }, [])
    : [];
  return { searchFilters, fields, tableCount };
};

export default connect(mapStateToProps, null)(SimAdvancedSearch);
