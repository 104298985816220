import {
  PENDING_GET_SMS_PROVIDERS_ACTION,
  SUCCESS_GET_SMS_PROVIDERS_ACTION,
  FAILURE_GET_SMS_PROVIDERS_ACTION
} from "../actionsTypes";

const getSmsProvdersStart = () => ({
  type: PENDING_GET_SMS_PROVIDERS_ACTION
});

const getSmsProvidersSucceeded = smsProviders => ({
  type: SUCCESS_GET_SMS_PROVIDERS_ACTION,
  payload: {
    smsProviders
  }
});

const getSmsProvidersFailure = errors => ({
  type: FAILURE_GET_SMS_PROVIDERS_ACTION,
  payload: {
    errors
  }
});

export const getSmsProviders = () => async dispatch => {
  dispatch(getSmsProvdersStart());

  const { default: F } = await import("../../commons/HTTPFetcher");

  return F.get("/smsProviders").then(
    smsProviders => {
      dispatch(getSmsProvidersSucceeded(smsProviders));

      return smsProviders;
    },
    errors => {
      dispatch(getSmsProvidersFailure({ errors }));

      throw errors;
    }
  );
};
