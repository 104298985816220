import React, { Component } from "react";

import I18nSpan from "../../i18n/components/I18nSpan";
import { Modal } from "react-bootstrap";

interface Props {
  showModal: boolean;
  onClose: Function;
  documentDetails: any;
}

interface State {
  showModal: boolean;
}

class AuditDetailViewer extends Component<Props, State> {
  _formatDetails({ documentDetails }) {
    const data =
      documentDetails === null
        ? {}
        : JSON.parse(documentDetails.data.replace(/\\/g, ""));

    const document = {
      ...documentDetails,
      data
    };

    return JSON.stringify(document, null, 2);
  }

  render() {
    const { showModal, onClose, documentDetails } = this.props;

    const details = this._formatDetails({ documentDetails });

    return (
      <div>
        <Modal show={showModal} onHide={onClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>
              <I18nSpan msgKey={"dataTable.documentDetails"} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre>{details}</pre>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AuditDetailViewer;
