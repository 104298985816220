import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { WidgetConfig } from "../Widget";
import TransactionsPie from "../../../chart/components/chart/TransactionsPie";
import NumberFormatter from "../../../formatters/NumberFormatter";
import LoadingChart from "./LoadingChart";
import { getData } from "../../utils/DataProvider";

interface Data {
  transactionsTypes?: any;
}

interface UserCurrency {
  currencyCodeAlpha3?: any;
  currencyDecimal?: any;
  currency?: any;
}

interface Props {
  getData: Function;
  data?: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  config: WidgetConfig;
  UserCurrency?: UserCurrency;
}

class PaymentMethodsAmount extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { user, desktopView } = config;
    if (desktopView) {
      return getData({ user, config });
    }
  }

  render() {
    const { data, title, color, loading, config, t } = this.props;

    const { userCurrency = {}, defaultPaletteId } = config;
    const { currency, currencyDecimal, currencyCodeAlpha3 } = userCurrency;

    const { transactionsTypes = [] } = data;

    const series = [
      {
        data: transactionsTypes.map(c => ({
          name: t(`reporting.transaction.values.transactionType.${c.key}`),
          y: c.sum_by_cards_label.value,
          value: NumberFormatter.formatAmount(
            c.sum_by_cards_label.value,
            currencyCodeAlpha3 + currency,
            currencyDecimal
          )
        }))
      }
    ];

    const format =
      "<b>{point.name}</b>: {point.percentage:.2f}% ({point.value})";
    const revenuesTitle = t(title);

    return (
      <LoadingChart loading={loading} color={color}>
        <TransactionsPie
          currency={userCurrency}
          color={color}
          title={revenuesTitle}
          series={series}
          dataLabelsFormat={format}
          palette={defaultPaletteId}
        />
      </LoadingChart>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  const {
    widgets: { [id]: widget }
  } = state.widgetData;
  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;
    return {
      data: stats,
      loading,
      error
    };
  }
  return {
    data: {},
    loading: true
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentMethodsAmount);
