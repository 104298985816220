import React, { Component } from "react";
import { compare } from "fast-json-patch";
import _ from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import If from "../../components/if";

import {
  RadiusCreationFields,
  Sim,
  SimProviders
} from "../constants/SimFieldConstants";
import SimValidation from "../validation/SimValidation";
import Utils from "../Utils";
import {
  editSim,
  getMerchants,
  getSim,
  loadOfferIds,
  getCustomers,
  loadProviders,
  loadContracts,
  resetSim,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

const EditableFields = {
  Radius: {
    Ingenico: ["MSISDN", "contract", "customerId"],
    Customer: ["name", "merchantId", "comment"]
  },
  Sierra: {
    Ingenico: ["offerId", "customerId"],
    Customer: ["name", "merchantId", "comment"]
  }
};

interface Props {
  editSim: Function;
  loadProviders: Function;
  loadContracts: Function;
  getCustomers: Function;
  resetSim: Function;
  getMerchants: Function;
  getSim: Function;
  loadOfferIds: Function;
  history: any;
  match: any;
  originalSim: any;
  merchants: any;
  offerIds: any;
  isIngenicoLevel: boolean;
  isCustomerLevel: boolean;
  customers: any;
  providers: any;
  contracts: any;
}

interface State {
  form: any;
  errors: any;
}

export class EditSimView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(newProps: any) {
    const { form, errors } = newProps;

    return this.setState({
      form,
      errors
    });
  }

  componentDidMount() {
    const {
      match: {
        params: { simId: id }
      },
      getMerchants,
      loadOfferIds,
      getCustomers,
      getSim,
      loadProviders,
      loadContracts,
      isIngenicoLevel
    } = this.props;
    const filters = [];

    getSim({ id });

    // Get data
    if (isIngenicoLevel) {
      getCustomers();
    } else {
      getMerchants({
        filters,
        fields: ["merchant.id", "merchant.name"],
        sort: {
          field: "merchant.name.raw",
          order: "ASC"
        }
      });
    }
    loadProviders({ creatable: true });
    loadContracts();
    loadOfferIds();
  }

  componentWillUnmount() {
    const { resetSim } = this.props;

    resetSim();
  }

  _saveBtnHandler = e => {
    e.preventDefault();
    const {
      editSim,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    this._checkErrors(async () => {
      const hasErrors = _.any(RadiusCreationFields, (fieldName: any) => {
        const error = this.state.errors[fieldName];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        const originalSimWithoutEmptyFields = _.pick(
          this.props.originalSim,
          _.identity
        );

        const editableFields =
          EditableFields[this.isSierra() ? "Sierra" : "Radius"][
            this.props.isIngenicoLevel ? "Ingenico" : "Customer"
          ];

        const editableOriginal = _.pick(
          originalSimWithoutEmptyFields,
          editableFields
        );
        const editableSim = _.pick(this.state.form, editableFields);

        const diff = compare(editableOriginal, editableSim);

        try {
          const {
            originalSim: { id: simId }
          } = this.props;

          await editSim({ simId, sim: diff });
          addNotificationSuccess("sim.edit.success");

          this._goToList();
        } catch (error) {
          const { key: errorKey } = await error;

          addNotificationError(`sim.edit.error.${errorKey}`);
        }
      }
    });
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this._checkError(name));
  };

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(undefined);
    });
    return Promise.all([requiredPromise]);
  };

  _waitForValidation = (names, newErrors, then) => {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: any = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  };

  _checkError = name => {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  };

  _checkErrors = then => {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  };

  _goToList = e => {
    if (e) {
      e.preventDefault();
    }

    const { history, isIngenicoLevel } = this.props;

    history.push(`/main/${isIngenicoLevel ? "" : "settings/"}sim`);
  };

  isSierra = () => {
    return this.state.form.operator === SimProviders.SIERRA;
  };

  render() {
    const {
      contracts,
      merchants,
      offerIds,
      customers,
      isIngenicoLevel,
      isCustomerLevel,
      t
    } = this.props;
    const { form, errors } = this.state;

    const providerName = t(`sim.provider.${form.operator}`);

    return (
      <div>
        <FormTitle titleKey="sim.title" actionKey="sim.edit.action" />
        <form className="ingenico-form form-horizontal sim-form">
          <BootstrapInput
            validation={SimValidation.noop}
            onChange={SimValidation.noop}
            inputRef={this.setInputRef(Sim.PROVIDER)}
            name={Sim.PROVIDER}
            required={false}
            descriptor={{
              type: "text",
              label: "sim.form.provider",
              readOnly: true
            }}
            formValue={providerName}
          />
          <BootstrapInput
            validation={SimValidation.noop}
            onChange={SimValidation.noop}
            inputRef={this.setInputRef(Sim.SSN)}
            name={Sim.SSN}
            required={false}
            descriptor={{
              type: "text",
              label: "sim.form.SSN",
              readOnly: true
            }}
            formValue={form.SSN}
          />
          <If test={isIngenicoLevel}>
            <BootstrapInput
              validation={SimValidation.required}
              onChange={this._onChangeHandler}
              inputRef={this.setInputRef(Sim.CUSTOMER_ID)}
              name={Sim.CUSTOMER_ID}
              errors={errors.customerId}
              required={true}
              descriptor={{
                type: "singleautocomplete",
                label: "sim.form.customerId",
                options: customers,
                readOnly: false
              }}
              formValue={form.customerId}
            />
          </If>
          <If test={isIngenicoLevel && !this.isSierra()}>
            <div>
              <BootstrapInput
                validation={SimValidation.noop}
                onChange={SimValidation.noop}
                inputRef={this.setInputRef(Sim.IMSI)}
                name={Sim.IMSI}
                required={false}
                descriptor={{
                  type: "text",
                  label: "sim.form.IMSI",
                  readOnly: true
                }}
                formValue={form.IMSI}
              />
              <BootstrapInput
                validation={SimValidation.noop}
                onChange={SimValidation.noop}
                inputRef={this.setInputRef(Sim.GGSN1)}
                name={Sim.GGSN1}
                descriptor={{
                  type: "ip",
                  label: "sim.form.GGSN1",
                  readOnly: true
                }}
                formValue={form.GGSN1}
                required={false}
              />
              <BootstrapInput
                validation={SimValidation.noop}
                onChange={SimValidation.noop}
                inputRef={this.setInputRef(Sim.GGSN2)}
                name={Sim.GGSN2}
                descriptor={{
                  type: "ip",
                  label: "sim.form.GGSN2",
                  readOnly: true
                }}
                formValue={form.GGSN2}
                required={false}
              />
            </div>
          </If>
          <BootstrapInput
            validation={SimValidation.noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef(Sim.MSISDN)}
            name={Sim.MSISDN}
            errors={errors.MSISDN}
            descriptor={{
              type: "text",
              label: "sim.form.MSISDN",
              readOnly: isCustomerLevel || this.isSierra()
            }}
            formValue={form.MSISDN}
          />
          <If test={!this.isSierra()}>
            <BootstrapInput
              validation={
                isCustomerLevel ? SimValidation.noop : SimValidation.required
              }
              onChange={this._onChangeHandler}
              inputRef={this.setInputRef(Sim.CONTRACT)}
              name={Sim.CONTRACT}
              descriptor={{
                type: "select",
                label: "sim.form.contract",
                options: contracts,
                readOnly: isCustomerLevel
              }}
              formValue={form.contract}
              required={isIngenicoLevel}
            />
          </If>
          <If test={this.isSierra()}>
            <BootstrapInput
              validation={SimValidation.noop}
              onChange={this._onChangeHandler}
              inputRef={this.setInputRef(Sim.OFFER_ID)}
              name={Sim.OFFER_ID}
              descriptor={{
                type: "select",
                label: "sim.form.offerId",
                options: offerIds,
                readOnly: isCustomerLevel
              }}
              formValue={form.offerId}
              required={true}
            />
          </If>
          <If test={!isIngenicoLevel}>
            <div>
              <BootstrapInput
                validation={SimValidation.noop}
                onChange={this._onChangeHandler}
                inputRef={this.setInputRef(Sim.NAME)}
                name={Sim.NAME}
                descriptor={{
                  type: "text",
                  label: "sim.form.name",
                  readOnly: false
                }}
                formValue={form.name}
              />
              <BootstrapInput
                validation={SimValidation.noop}
                onChange={this._onChangeHandler}
                inputRef={this.setInputRef(Sim.MERCHANT_ID)}
                name={Sim.MERCHANT_ID}
                descriptor={{
                  type: "singleautocomplete",
                  label: "sim.form.merchant",
                  readOnly: false,
                  options: merchants
                }}
                formValue={form.merchantId}
              />
              <BootstrapInput
                validation={SimValidation.noop}
                onChange={this._onChangeHandler}
                inputRef={this.setInputRef(Sim.COMMENT)}
                name={Sim.COMMENT}
                descriptor={{
                  type: "textarea",
                  label: "sim.form.comment",
                  readOnly: false
                }}
                formValue={form.comment}
                required={false}
              />
            </div>
          </If>
          <div className="pull-right">
            <button
              onClick={this._saveBtnHandler}
              name="save"
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { isIngenico: isIngenicoLevel, isCustomer: isCustomerLevel },
    merchants: { data: merchants },
    customers: { data: customers },
    sim: { data: sim, contracts, offers }
  } = state;

  const currentSim = _.clone(sim || {});
  const actualSim = _.merge(
    {
      [Sim.MSISDN]: null,
      [Sim.CONTRACT]: null,
      [Sim.OFFER_ID]: null,
      [Sim.COMMENT]: null,
      [Sim.NAME]: null,
      [Sim.MERCHANT_ID]: null
    },
    currentSim
  );

  const contractsList = contracts
    ? Utils.makeDataZipObject({
        list: contracts,
        key: "sim.values.contract"
      })
    : [];
  const customersList = customers ? Utils.mapCustomers({ customers }) : [];

  const merchantsList = merchants ? Utils.mapMerchants({ merchants }) : [];
  const offerIds = offers
    ? _.zipObject(offers.map((offerId: any) => [offerId.uid, offerId.name]))
    : [];

  return {
    form: _.clone(actualSim),
    originalSim: _.clone(actualSim),
    contracts: contractsList,
    merchants: merchantsList,
    offerIds,
    errors: {},
    isIngenicoLevel,
    customers: customersList,
    isCustomerLevel
  };
};

const mapDispatchToProps = dispatch => ({
  editSim: ({ simId, sim }) => dispatch(editSim({ simId, sim })),
  getSim: ({ id }) => dispatch(getSim({ id })),
  resetSim: () => dispatch(resetSim()),
  getMerchants: ({ filters, fields, sort, tableCount }) =>
    dispatch(getMerchants({ filters, fields, sort, tableCount })),
  getCustomers: () => dispatch(getCustomers()),
  loadOfferIds: () => dispatch(loadOfferIds()),
  loadContracts: () => dispatch(loadContracts()),
  loadProviders: ({ creatable }) => dispatch(loadProviders({ creatable })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(EditSimView);
