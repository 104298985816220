import React, { useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import Permissions from "../../user/constants/Permissions";
import BottomNavigation from "@material-ui/core/BottomNavigation/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction/BottomNavigationAction";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import classNames from "classnames";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import HomeOff from "../../../assets/images/home_off.svg";

import ReportingOff from "../../../assets/images/reporting_off.svg";
import MonitoringOff from "../../../assets/images/monitoring_off.svg";
import AccountOff from "../../../assets/images/account_off.svg";
import AccountOn from "../../../assets/images/account_on.svg";
import TerminalOn from "../../../assets/images/mobile/terminal_on.svg";
import TerminalOff from "../../../assets/images/mobile/terminal_off.svg";

import styles from "./BottomNavigation.css";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const MainBottomNavigation = ({
  history,
  t,
  location,
  darkMode: prefersDarkMode,
  isCustomer,
  permissions
}) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        position: "fixed",
        bottom: 0,
        padding: 0,
        color: "#eab329",
        backgroundColor: prefersDarkMode
          ? "rgba(45,45,45,0.60)"
          : "rgba(255,255,255,0.85)"
      }
    })
  );

  const useStylesNav = makeStyles((theme: Theme) => ({
    root: {
      color: "#dcdcdc",
      "&$selected": {
        color: "#eab329"
      },
      "&$label": {
        fontSize: "1em"
      }
    },
    selected: {},
    label: {
      position: "absolute",
      bottom: "0",
      textTransform: "uppercase"
    }
  }));

  const classes = useStyles();

  const navStyle = useStylesNav();

  const initPath = () => {
    switch (location.pathname) {
      case "/main": {
        return 0;
      }
      case "/main/settings/poi/mobile" || "/main/mobile-monitoring": {
        return 2;
      }
      case "/main/my-account": {
        return 3;
      }
      default:
        return 0;
    }
  };

  const [value, setValue] = useState(initPath());

  const matches = useMediaQuery(`(max-width: 768px)`);

  const AccountIcon = () => {
    if (value !== 3) {
      return (
        <AccountOff
          className={classNames(styles.icon, {
            [styles.selected]: value === 3,
            [styles.notSelected]: value !== 3
          })}
        />
      );
    }
    return (
      <AccountOn
        className={classNames(styles.icon, {
          [styles.selected]: value === 3,
          [styles.notSelected]: false
        })}
      />
    );
  };

  const TerminalIcon = () => {
    if (allowTerminalCreation) {
      if (value !== 2) {
        return (
          <TerminalOff
            className={classNames(styles.icon, styles.terminalOff)}
          />
        );
      }
      return (
        <TerminalOn className={classNames(styles.icon, styles.terminalOn)} />
      );
    }
    if (value !== 2) {
      return (
        <MonitoringOff
          className={classNames(styles.icon, {
            [styles.selected]: value === 2,
            [styles.notSelected]: value !== 2
          })}
        />
      );
    }
    return (
      <MonitoringOff
        className={classNames(styles.icon, {
          [styles.selected]: value === 2,
          [styles.notSelected]: value !== 2
        })}
      />
    );
  };

  const allowTerminalCreation =
    isCustomer && permissions.includes(Permissions.TERMINAL_MANAGEMENT);

  if (matches) {
    return (
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          switch (newValue) {
            case 0: {
              history.push("/");

              break;
            }
            case 1: {
              history.push("/main/mobile-reporting");

              break;
            }
            case 2: {
              const allowed =
                isCustomer &&
                permissions.includes(Permissions.TERMINAL_MANAGEMENT);
              const route = allowed
                ? "/main/settings/poi/mobile"
                : "/main/mobile-monitoring";
              return history.push(route);
            }
            case 3: {
              history.push("/main/my-account");

              break;
            }
            default: {
              break;
            }
          }
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          classes={{
            root: navStyle.root,
            selected: navStyle.selected,
            label: navStyle.label
          }}
          label={t("menu.home")}
          icon={
            <HomeOff
              className={classNames(styles.icon, {
                [styles.selected]: value === 0,
                [styles.notSelected]: value !== 0
              })}
            />
          }
        />

        <BottomNavigationAction
          classes={{
            root: navStyle.root,
            selected: navStyle.selected,
            label: navStyle.label
          }}
          label={t("menu.reporting")}
          icon={
            <ReportingOff
              className={classNames(styles.icon, styles.reporting, {
                [styles.selected]: value === 1,
                [styles.notSelected]: value !== 1
              })}
            />
          }
        />

        <BottomNavigationAction
          classes={{
            root: navStyle.root,
            selected: navStyle.selected,
            label: navStyle.label
          }}
          label={t(allowTerminalCreation ? "menu.poi" : "menu.monitoring")}
          icon={<TerminalIcon />}
        />
        <BottomNavigationAction
          classes={{
            root: navStyle.root,
            selected: navStyle.selected,
            label: navStyle.label
          }}
          label={t("header.myAccount")}
          icon={<AccountIcon />}
        />
      </BottomNavigation>
    );
  }
  return null;
};

export const mapStateToProps = state => {
  const {
    auth: { user, isCustomer },
    theme: { darkMode }
  } = state;

  const { permissions } = user;

  return { isCustomer, permissions, darkMode };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, null)
)(MainBottomNavigation);
