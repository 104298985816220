import React, { Component } from "react";

interface State {}

interface Props {
  onSuggestionClick: Function;
  suggestion: string;
  formatter: Function;
}

class Suggestion extends Component<Props, State> {
  state = {};

  onClickElement = (event: Event) => {
    const { onSuggestionClick, suggestion } = this.props;

    return onSuggestionClick(event, suggestion);
  };

  render() {
    const { selectedSuggestion, suggestion, formatter, style } = this.props;

    return (
      <li
        className={suggestion === selectedSuggestion ? "selected" : ""}
        style={{ ...style, cursor: "pointer" }}
        onClick={e => this.onClickElement(e)}
      >
        {formatter}
      </li>
    );
  }
}

export default Suggestion;
