import i18next from "i18next";

import {
  CreatePredefinedAlert,
  PredefinedAlert,
  PredefinedAlertType
} from "./PredefinedAlert";

export class CreateTransactionOnClosingHoursAlert extends CreatePredefinedAlert {
  static create(fromTime: string, toTime: string, closingDays: Array<string>) {
    return new CreateTransactionOnClosingHoursAlert(
      new ClosingHours(fromTime, toTime, closingDays)
    );
  }

  constructor(public closingHours: ClosingHours) {
    super(TransactionOnClosingHoursAlert.TYPE);
    this.closingHours = closingHours;
  }

  isValid(): Array<string> {
    return this.closingHours.isValid();
  }
}

export class ClosingHours {
  constructor(
    public fromTime: string,
    public toTime: string,
    public closingDays: Array<string>
  ) {
    this.fromTime = fromTime || "00:00";
    this.toTime = toTime || "01:00";
    this.closingDays = closingDays || [];
  }

  closingDaysLabel(): string {
    return this.closingDays
      .map(d => i18next.t(`scheduled.closingWeekDay.${d}`))
      .join(", ");
  }

  isValid(): Array<string> {
    const errors: Array<string> = [];
    if (this.fromTime === this.toTime) {
      errors.push(
        "alert.predefined.transaction-on-closing-hours.closingHours.error"
      );
    }
    if (this.closingDays.length === 0) {
      errors.push(
        "alert.predefined.transaction-on-closing-hours.closingDays.error"
      );
    }
    return errors;
  }
}

export class TransactionOnClosingHoursAlert extends PredefinedAlert {
  static TYPE: PredefinedAlertType = "TransactionOnClosingHours";
  static I18N_KEY = "transaction-on-closing-hours";

  constructor(public id: string, public closingHours: ClosingHours) {
    super();
    this.id = id;
    this.closingHours = closingHours;
  }

  i18nKey(): string {
    return TransactionOnClosingHoursAlert.I18N_KEY;
  }

  i18nArgs(): Array<string> {
    return [
      this.closingHours.fromTime,
      this.closingHours.toTime,
      this.closingHours.closingDaysLabel()
    ];
  }
}
