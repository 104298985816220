import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import Validators from "../../ingenicoForm/validation/Validators";
import I18nSpan from "../../i18n/components/I18nSpan";
import posValidations, {
  posErrorNotification,
  requiredMinLength
} from "../validations/PosValidations";
import Utils from "../../sim/Utils";

import {
  editPos,
  getPos,
  getStores,
  resetPos,
  loadDownloadPolicies,
  loadPoiContracts,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  editPos: Function;
  getPos: Function;
  getStores: Function;
  resetPos: Function;
  loadDownloadPolicies: Function;
  loadPoiContracts: Function;
  history: any;
  pos: any;
  options: any;
  readOnly: any;
}

interface State {
  form: any;
  errors: any;
}

export class EditPosView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(newProps: any) {
    const { form, errors } = newProps;

    return this.setState({
      form,
      errors
    });
  }

  componentDidMount() {
    const {
      getPos,
      getStores,
      loadDownloadPolicies,
      loadPoiContracts,
      match: {
        params: { posId }
      }
    } = this.props;
    const filters = [];

    getStores({ filters, fields: ["store.id", "store.name"] });
    getPos({ id: posId });
    loadPoiContracts();
    loadDownloadPolicies();
  }

  componentWillUnmount() {
    const { resetPos } = this.props;

    resetPos();
  }

  _goToList = () => {
    const { history } = this.props;

    history.push("/main/settings/pos");
  };

  _onExit = e => {
    e.preventDefault();
    const { history } = this.props;

    history.push("/main/settings/pos");
  };

  _onChangeHandler = (name, value) => {
    return this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value === "" ? null : value
        }
      };
    }, this._checkError(name));
  };

  _updateSuccess = () => {
    const { addNotificationSuccess } = this.props;
    addNotificationSuccess("pos.edit.success");
    this._goToList();
  };

  _editBtnHandler = e => {
    e.preventDefault();
    const {
      editPos,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    this._checkErrors(() => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];

        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        if (this.state.form.autoInit == "") this.state.form.autoInit = null;
        if (this.state.form.autoInit === null) {
          const data = this.state.form;

          editPos({ pos: data })
            .then(() => {
              addNotificationSuccess("pos.edit.success");
              this._goToList();
            })
            .catch((error: any) =>
              posErrorNotification({
                error,
                addNotificationError,
                form: this.state.form
              })
            );
        } else {
          this._autoInitValidation(this.state.form.autoInit)
            .then(message => {
              if (message === null || message === false) {
                const data = this.state.form;

                editPos({ pos: data })
                  .then(() => {
                    addNotificationSuccess("pos.edit.success");
                    this._goToList();
                  })
                  .catch(() => addNotificationError("notification.error"));
              } else {
                addNotificationError(message.code, message.args);
              }
            })
            .catch(err => {
              addNotificationError(err.code);
            });
        }
      }
    });
  };

  _waitForValidation = (
    names: Array<string>,
    newErrors: Array<String>,
    then
  ) => {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  };

  _checkError = name => {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  };

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  }

  _noop = () => {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  };

  _promizer = value => {
    return new Promise(cb => {
      cb(value);
    });
  };

  _autoInitValidation(value) {
    const { autoInit, t } = this.props;
    const translation = t("pos.form.autoInit.label");

    switch (true) {
      case value && !value.match(/^[a-zA-Z0-9]+$/):
        return this._promizer({
          code: "form.error.asciiCharacters",
          args: [value]
        });
      case value && value.length < 4:
        return this._promizer(
          Validators.sizeOfStringValidator(4, translation)(value)
        );
      case value && value.length > 48:
        return this._promizer(
          Validators.maxStringValidator(48, translation)(value)
        );
      default: {
        return value
          ? posValidations.autoInitIdUnicityForEdit(value, autoInit)
          : this._promizer(null);
      }
    }
  }

  render() {
    const { options, readOnly, t } = this.props;
    const { form, errors } = this.state;

    return (
      <div>
        <FormTitle titleKey="pos.title" actionKey="pos.edit.action" />
        <form className="ingenico-form form-horizontal edit-pos-form">
          <BootstrapInput
            validation={requiredMinLength(t(`pos.form.name.label`))}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            errors={errors.name}
            required={true}
            descriptor={{
              type: "text",
              label: "pos.form.name.label",
              placeholder: "pos.form.name.placeholder"
            }}
            formValue={form.name}
          />

          <BootstrapInput
            validation={value =>
              Promise.resolve(Validators.requiredValidator(value))
            }
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("storeId")}
            name="storeId"
            errors={errors.storeId}
            required={true}
            descriptor={{
              type: "multipleselectwindowed",
              label: "pos.form.storeId.label",
              options: options.stores,
              readOnly: readOnly.storeId,
              isMulti: false
            }}
            formValue={{
              label: options.storeName,
              value: form.storeId
            }}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("posReference")}
            name="posReference"
            errors={errors.posReference}
            required={false}
            descriptor={{ type: "text", label: "pos.form.posReference.label" }}
            formValue={form.posReference}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("storeLocation")}
            name="storeLocation"
            errors={errors.storeLocation}
            descriptor={{
              type: "text",
              label: "pos.form.storeLocation.label",
              placeholder: "pos.form.storeLocation.placeholder"
            }}
            formValue={form.storeLocation}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("autoInit")}
            name="autoInit"
            errors={errors.autoInit}
            descriptor={{
              type: "text",
              label: "pos.form.autoInit.label"
            }}
            formValue={form.autoInit}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("downloadPolicy")}
            name="downloadPolicy"
            errors={errors.downloadPolicy}
            required={true}
            descriptor={{
              type: "select",
              label: "pos.form.downloadPolicy",
              options: options.downloadPolicies
            }}
            formValue={form.downloadPolicy}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("syncDevice")}
            name="syncDevice"
            errors={errors.syncDevice}
            descriptor={{
              type: "checkbox",
              label: "pos.form.syncDevice",
              className: "mtn"
            }}
            formValue={form.syncDevice}
          />

          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("poiContract")}
            name="poiContract"
            errors={errors.poiContract}
            descriptor={{
              type: "select",
              label: "pos.form.poiContract.label",
              options: options.poiContracts,
              readOnly: readOnly.poiContract
            }}
            formValue={form.poiContract}
          />

          <div className="pull-right">
            <button
              onClick={this._editBtnHandler}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._onExit}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStores = ({ stores }) => {
  return _.map(stores, store => {
    const {
      store: { name, id }
    } = store;

    return {
      name,
      id
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { isCustomer },
    stores: { data: stores },
    pos: {
      policiesList: rawDownloadPolicies,
      contractsList: rawPoiContracts,
      data: pos
    }
  } = state;

  const storeOptions = mapStores({ stores });
  const downloadPolicies = rawDownloadPolicies
    ? Utils.makeDataZipObject({
        list: rawDownloadPolicies,
        key: "pos.downloadPolicy"
      })
    : [];
  const poiContracts = rawPoiContracts
    ? Utils.makeDataZipObject({
        list: rawPoiContracts,
        key: "poi.contract"
      })
    : [];

  const storeName = storeOptions.find(store => store.id === pos.storeId)?.name;

  return {
    form: {
      ...pos
    },
    options: {
      stores: storeOptions,
      storeName,
      downloadPolicies,
      poiContracts
    },
    readOnly: {
      poiContract: !isCustomer,
      storeId: true
    },
    errors: {},
    autoInit: pos.autoInit
  };
};

const mapDispatchToProps = dispatch => ({
  editPos: ({ pos }) => dispatch(editPos({ pos })),
  getPos: ({ id }) => dispatch(getPos({ id })),
  resetPos: () => dispatch(resetPos()),
  getStores: ({ filters, fields, sort, tableCount }) =>
    dispatch(getStores({ filters, fields, sort, tableCount })),
  loadDownloadPolicies: () => dispatch(loadDownloadPolicies()),
  loadPoiContracts: () => dispatch(loadPoiContracts()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(EditPosView);
