import React, { Component } from "react";
import ReactJson from "react-json-view";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import If from "../../../components/if";
import I18nSpan from "../../../i18n/components/I18nSpan";

import {
  addNotificationSuccess,
  addNotificationError
} from "../../../redux/actions";

import styles from "../../styles/styles.css";

interface Props {
  onChange: Function;
  collapseStringsAfter: number;
  onAdd: boolean;
  onEdit: boolean;
  onDelete: boolean;
  displayObjectSize: boolean;
  enableClipboard: boolean;
  theme: string;
  iconStyle: string;
  collapsed: boolean;
  indentWidth: number;
  displayDataTypes: boolean;
  location: any;
  value: any;
}

interface State {
  src?: any;
  uploadedFileContents: any;
  filename: string;
  appSet: boolean;
}

class Jsonarea extends Component<Props, State> {
  state = {
    uploadedFileContents: null,
    filename: "",
    appSet: false
  };

  // To be able to get the JSON when edit views
  componentWillReceiveProps(nextProps) {
    try {
      const { value: src } = nextProps;

      this.setState({
        src
      });
    } catch (e) {
      return {};
    }
  }

  readUploadedFileAsText(inputFile) {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  }

  uploadFile = async event => {
    const { target } = event;
    const { addNotificationError } = this.props;

    if (!target || !target.files) {
      return false;
    }

    const fileList = target.files;

    // Uploads will push to the file input's `.files` array. Get the last uploaded file.
    const latestUploadedFile = fileList.item(fileList.length - 1);

    try {
      const uploadedFileContents = await this.readUploadedFileAsText(
        latestUploadedFile
      );
      const JSONformatted = JSON.parse(uploadedFileContents);
      const filename = latestUploadedFile.name;

      this.setState(
        {
          uploadedFileContents,
          src: JSONformatted,
          filename
        },
        () => {
          const { onChange, name } = this.props;

          onChange(name, JSONformatted);
        }
      );
    } catch {
      addNotificationError("form.error.format.json");
    }
  };

  collapseJsonEditor = () => {
    this.setState(state => ({ appSet: !state.appSet }));
  };

  handleChange = event => {
    const { updated_src } = event;
    const { onChange, name } = this.props;

    onChange(name, updated_src);
  };

  handleChangeUpdate = e => {
    // update the value that is shown in the form
    this.setState(
      { src: e.updated_src },
      // use callback to be sure that you have the latest value of the src
      () => {
        const { onChange, name } = this.props;

        onChange(name, e.updated_src);
      }
    );
  };

  render() {
    const {
      collapseStringsAfter = 15,
      onAdd = true,
      onEdit = true,
      onDelete = true,
      displayObjectSize = true,
      enableClipboard = true,
      theme = "monokai",
      iconStyle = "triangle",
      collapsed = false,
      indentWidth = 4,
      displayDataTypes = true,
      location: { pathname }
    } = this.props;
    const { src, appSet, filename } = this.state;
    const style = {
      minHeight: 96,
      wordBreak: "break-all"
    };

    // necessary when there are multiple jsom editors on the same page
    const jsonareaRandomId = `input-${Math.random() * 999}`;

    const jsonEditor = (
      <div>
        <div className={styles["file-upload"]}>
          <label
            htmlFor={jsonareaRandomId}
            className={styles["upload-button-text"]}
          >
            <I18nSpan
              msgKey={"form.jsonarea.button.fileupload"}
              class={styles["filename"]}
            />
            <input
              className={styles["file-input-field"]}
              type="file"
              id={jsonareaRandomId}
              onChange={this.uploadFile}
            />
          </label>
          <p className="filename">{filename}</p>
        </div>
        <ReactJson
          name={null}
          collapsed={collapsed}
          theme={theme}
          src={src || undefined}
          collapseStringsAfterLength={collapseStringsAfter}
          style={style}
          onEdit={onEdit ? this.handleChangeUpdate : false}
          onDelete={onDelete ? this.handleChange : false}
          onAdd={onAdd ? this.handleChange : false}
          displayObjectSize={displayObjectSize}
          enableClipboard={enableClipboard}
          indentWidth={indentWidth}
          displayDataTypes={displayDataTypes}
          iconStyle={iconStyle}
        />
      </div>
    );

    if (pathname === "/main/program/default") {
      return jsonEditor;
    }
    return (
      <CollapsibleJsonEditor appSet={appSet} onCheck={this.collapseJsonEditor}>
        {jsonEditor}
      </CollapsibleJsonEditor>
    );
  }
}

const CollapsibleJsonEditor = ({ appSet, onCheck, children }) => {
  return (
    <div>
      <input
        type="checkbox"
        name="appSet"
        id="appSet"
        checked={appSet}
        onChange={onCheck}
      />
      <If test={appSet}>{children}</If>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(withRouter, connect(null, mapDispatchToProps))(Jsonarea);
