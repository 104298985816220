import {
  FAILURE_GET_USERS_ACTION,
  PENDING_GET_USERS_ACTION,
  SUCCESS_GET_USERS_ACTION,
  PENDING_GET_USERS_HIERARCHY_ACTION,
  SUCCESS_GET_USERS_HIERARCHY_ACTION,
  FAILURE_GET_USERS_HIERARCHY_ACTION,
  SUCCESS_UPDATE_STATUS_USER_ACTION,
  SUCCESS_DELETE_USER_ACTION,
  CHANGE_USER_MODE_ACTION,
  RESET_USER_MODE_ACTION
} from "../actionsTypes";
import UserMode from "../../user/constants/UserMode";
import {
  initSelection,
  onToggleSelectAll,
  onToggleUnSelectAll
} from "./selection";
import get from "get-value";

const initialState = {
  data: [],
  selection: {},
  loading: false,
  error: false,
  errors: {},
  userMode: UserMode.MINE
};

const _onUserDeleted = ({ userId, users }) => {
  return users.filter(user => {
    const { login: id } = user;

    return id !== userId;
  });
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case PENDING_GET_USERS_ACTION:
    case PENDING_GET_USERS_HIERARCHY_ACTION:
      return {
        ...state,
        selection: {},
        loading: true,
        error: false
      };
    case SUCCESS_GET_USERS_ACTION:
    case SUCCESS_GET_USERS_HIERARCHY_ACTION:
      const {
        payload: { users }
      } = action;
      const selection = initSelection({ data: users, keyId: "login" });

      return {
        ...state,
        data: users,
        selection
      };
    case FAILURE_GET_USERS_ACTION:
    case FAILURE_GET_USERS_HIERARCHY_ACTION:
      const {
        payload: { errors }
      } = action;

      return {
        ...state,
        data: initialState.data,
        loading: false,
        error: true,
        ...errors
      };
    case "TOGGLE_SELECT_USERS_ACTION":
    case "TOGGLE_UNSELECT_USERS_ACTION": {
      const {
        payload: { id }
      } = action;
      const {
        selection: { [id]: value },
        data
      } = state;

      return {
        ...state,
        selection: {
          ...state.selection,
          [id]: !value ? data.find(user => get(user, "login") === id) : false
        }
      };
    }
    case "TOGGLE_SELECT_ALL_USERS_ACTION": {
      const { selection: prevSelection, data } = state;
      const selection = onToggleSelectAll({
        selection: prevSelection,
        data,
        keyId: "login"
      });

      return {
        ...state,
        selection
      };
    }
    case "TOGGLE_UNSELECT_ALL_USERS_ACTION": {
      const { selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });

      return {
        ...state,
        selection
      };
    }
    case SUCCESS_UPDATE_STATUS_USER_ACTION: {
      const {
        payload: { status, id: login }
      } = action;
      const { data } = state;
      const nextData = data.reduce((prevUser, user) => {
        const { login: userLogin } = user;

        if (login === userLogin) {
          return [
            ...prevUser,
            {
              ...user,
              status
            }
          ];
        }

        return [...prevUser, user];
      }, []);

      return {
        ...state,
        data: nextData
      };
    }
    case SUCCESS_DELETE_USER_ACTION: {
      const {
        payload: { userId }
      } = action;
      const { data: prevUsers, selection: prevSelection } = state;
      const selection = onToggleUnSelectAll({ selection: prevSelection });
      const users = _onUserDeleted({ userId, users: prevUsers });

      return {
        ...state,
        selection,
        data: users
      };
    }
    case CHANGE_USER_MODE_ACTION:
      const {
        payload: { userMode }
      } = action;

      return {
        ...state,
        userMode,
        data: initialState.data,
        ...initialState
      };
    case RESET_USER_MODE_ACTION:
      return initialState;
    default:
      return state;
  }
};
