import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import _ from "lodash";
import get from "get-value";
import { connect } from "react-redux";
import { compose } from "redux";

import { eventSourceListener } from "../../commons/EventSourceListener";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import LinkPosToPoi from "./LinkPosToPoi";
import UploadFile from "../../components/upload/UploadFile";
import PoiAdvancedSearch from "./PoiAdvancedSearch";
import ManufacturerUtils from "../../manufacturer/Utils";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import Counter from "../../datatable/components/Counter";
import I18nSpan from "../../i18n/components/I18nSpan";
import { CustomColumn } from "../../datatable/components/CustomColumn";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import { generateUrl } from "../../commons/utils/url";
import { Refresh } from "../../datatable/components/Refresh";
import {
  DeleteAction,
  EditAction,
  UnLinkAction
} from "../../datatable/components/multipleActions";
import { LinkPoiAction } from "../../datatable/components/multipleActions/LinkPoiAction";
import { SwapPoiAction } from "../../datatable/components/multipleActions/SwapPoiAction";
import { getNamesFromSelection } from "../../redux/reducers/selection";
import {
  getFields,
  getPoisList,
  getScroll,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  deletePois,
  deleteEvent,
  addNotificationSuccess,
  addNotificationError,
  hasMonitoringSimService,
  setSearchContext
} from "../../redux/actions";

import styles from "../styles/ListPoiView.css";
import { setUrlWithParams } from "../../searchContext/URLizer";

interface Props {
  hasTerminalManagement: boolean;
  isCustomer: any;
  simLinkable: any;
  loading: boolean;
  visibleAttributes: any;
  poiCount: any;
  poiScrollId: string;
  poiList: any;
  selectedPoiIds: any;
  selection: any;
  getFields: Function;
  getPoisList: Function;
  getPoisScroll: Function;
  attributes: any;
  toggleSelect: Function;
  toggleUnSelect: Function;
  toggleSelectAll: Function;
  toggleUnSelectAll: Function;
  activationButtonStatus: Function;
  deletePois: Function;
  updateStoresStatus: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  deleteEvent: Function;
}
interface State {
  poiNames: any;
  showDeleteConfirmation: boolean;
}

const advancedSearchKey = AdvancedSearchConstants.POI_KEY;

class ListPoiView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      showDeleteConfirmation: false
    };
    this.setUrlParams({ searchContext });
  }

  formatter = {
    "merchant.selectedTags": selectedTags => _.values(selectedTags).join(", "),
    "store.selectedTags": selectedTags => _.values(selectedTags).join(", "),
    "poi.manufacturerId": manufacturerCode =>
      ManufacturerUtils.manufacturerNameFromCode(manufacturerCode)
  };

  evtSource = { close: () => undefined } as EventSource;

  async componentDidMount() {
    const { getFields, eventGatewayURL, searchContext } = this.props;

    await getFields({ name: "poi" });

    await this._onRefresh({ searchContext });

    eventSourceListener({
      type: advancedSearchKey,
      action: this._handleUpdateStatus,
      evtSourceContainer: this,
      callbackWithParams: true,
      eventGatewayURL
    });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  _handleDelete({ id }) {
    const { deletePoi } = this.props;
    return deletePoi({ id, sectionType: "poi" });
  }

  _handleUpdateStatus = event => {
    const { searchContext } = this.props;
    const { id, eventType } = event;

    switch (eventType) {
      case "deleted": {
        return this._handleDelete({ id });
      }
      case "linked": {
        return this._onRefresh({ searchContext });
      }
      case "unlinked": {
        return this._onRefresh({ searchContext });
      }
    }
  };

  componentWillUnmount() {
    this.evtSource.close();
  }

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getPoisList } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getPoisList({ filters, sort, tableCount });
  };

  _search = ({ searchContext }) => {
    const {
      getPoisList,
      tableCount,
      setSearchContext,
      location: { pathname }
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });

    return getPoisList({ filters, sort, tableCount });
  };

  _closeDeletePopin = () => {
    this.setState({
      showDeleteConfirmation: false
    });
  };

  _showDeletePopin = () => {
    const { selection } = this.props;
    const poiNames = getNamesFromSelection({
      selection,
      keyName: "poi.serialNumber"
    });

    return this.setState({
      showDeleteConfirmation: true,
      poiNames
    });
  };

  _deletePois = async () => {
    const {
      selectedPoiIds,
      deletePois,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    try {
      await deletePois({ poiIds: selectedPoiIds });
      addNotificationSuccess("poi.delete.success");

      return this._closeDeletePopin();
    } catch (promiseError) {
      const { errorKey } = await promiseError;
      addNotificationError(errorKey);

      return this._closeDeletePopin();
    }
  };

  _isRowEditable = row => {
    const { hasTerminalManagement } = this.props;

    return hasTerminalManagement;
  };

  _linkFiltersForSim(ssn) {
    const { history, setSearchContext } = this.props;

    const filters = [
      {
        filterType: "array",
        name: "sim.SSN",
        operator: "=",
        value: [ssn]
      }
    ];
    const searchContext = {
      key: AdvancedSearchConstants.SIM_KEY,
      filters
    };

    setSearchContext({
      context: searchContext,
      pathname: "sim",
      updateUrl: true
    });
    return history.push("/main/sim");
  }

  _updateColumns = columns => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }
    return updateUserColumnsSettings({ columnSetting, category });
  };

  onSelectRow = (rowId, value) => {
    const { selectedPoiIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedPoiIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  _loadMoreRows = () => {
    const { getPoisScroll, poiScrollId } = this.props;

    return getPoisScroll({ scrollId: poiScrollId });
  };

  render() {
    const { showDeleteConfirmation, poiNames } = this.state;
    const {
      loading,
      hasTerminalManagement,
      isCustomer,
      simLinkable,
      poiCount,
      poiList,
      selectedPoiIds,
      visibleAttributes,
      attributes,
      selection,
      sortDescription,
      searchContext,
      hasSwapManagement,
      isMerchant,
      hasPosManagement,
      hasMerchantManagement,
      selectionPoi
    } = this.props;

    const linkPosButton = row => (
      <LinkPosToPoi
        hasTerminalManagement={hasTerminalManagement}
        linkedPos={row.pos}
        poi={row}
        onUnlink={() => this._onRefresh({ searchContext })}
      />
    );

    const editPoi = ({ selectionLength }) => {
      if (isCustomer && hasMerchantManagement && selectionLength === 1) {
        const [poiId] = selectedPoiIds;
        const linked = selection[poiId]?.pos ? "/linked" : "";
        const route = `/main/settings/poi/edit-poi/${poiId}${linked}`;

        return <EditAction route={route} msgKey={"poi.edit.action"} />;
      }

      return null;
    };

    const deletePois = () => {
      if (isCustomer && hasTerminalManagement) {
        return <DeleteAction onDelete={this._showDeletePopin} />;
      }

      return null;
    };

    const linkPoiButton = ({ selectionLength }) => {
      if (selectionLength === 1) {
        const [poiId] = selectedPoiIds;
        const route = `/main/settings/poi/link-poi/${poiId}`;

        if (hasTerminalManagement && hasPosManagement) {
          return <LinkPoiAction route={route} />;
        }
      }
      return null;
    };

    const swapPoiButton = ({ selectionLength }) => {
      if (selectionLength === 1 && isMerchant && hasSwapManagement) {
        const [poiId] = selectedPoiIds;

        if (selection[poiId] !== undefined) {
          const { pos } = selection[poiId];

          if (!pos) {
            return null;
          }

          const route = `/main/settings/poi/swap-poi/${poiId}`;

          return (
            pos && <SwapPoiAction route={route} msgKey={"poi.swap.action"} />
          );
        }
      }

      return null;
    };

    const linkColumnPos: CustomColumn = {
      column: "pos.name",
      element: linkPosButton
    };

    const linkSimButton = row =>
      simLinkable ? (
        <a onClick={() => this._linkFiltersForSim(row.simSSN)}>{row.simSSN}</a>
      ) : (
        row.simSSN
      );

    const linkColumnSim: CustomColumn = {
      column: "simLink",
      element: linkSimButton
    };

    const UnLinkPosButton = () => {
      const [id] = selectedPoiIds;
      const { [id]: selectedPoi = { pos: [] } } = selectionPoi;
      const { pos } = selectedPoi;
      const hasBeenLinked = () => {
        const posList = pos ? [pos] : [];

        return posList.length > 0;
      };

      if (
        selectedPoiIds.length === 1 &&
        hasPosManagement &&
        hasTerminalManagement &&
        hasBeenLinked()
      ) {
        const { id: posId } = pos;

        const route = `/main/settings/poi/unlink-pos/${posId}/${id}`;

        return <UnLinkAction route={route} msgKey={"pos.unlink.action"} />;
      }

      return null;
    };

    return (
      <div className="data-table-wrapper poi-list">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle titleKey="poi.title" actionKey="poi.list.action" />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={poiCount}
                loadingKey="poi.list.loading"
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>

          {isCustomer && hasTerminalManagement && (
            <div className={styles.edition}>
              {hasMerchantManagement && (
                <div>
                  <Link
                    className={classNames(
                      "btn",
                      "btn-ingenico",
                      "create-button",
                      styles["poi-action"]
                    )}
                    to="/main/settings/poi/new-poi"
                  >
                    <span
                      className="glyphicon glyphicon-plus"
                      aria-hidden="true"
                    />
                    <I18nSpan msgKey="poi.list.button.create" />
                  </Link>
                </div>
              )}

              <UploadFile
                uploadUrl={"/poi/import"}
                templateActions={[
                  generateUrl({
                    url: "/poi/import/template"
                  })
                ]}
                fileKey="pois"
                tooltip="poi.import.template"
                buttonsTitles={{
                  upload: "poi.import.upload",
                  templates: "poi.import.template"
                }}
                notifications={{
                  success: "poi.import.uploadFile.success",
                  error: "poi.import.uploadFile.error"
                }}
              />
            </div>
          )}
        </div>

        <PoiAdvancedSearch
          searchContext={searchContext}
          onChange={this._search}
        />

        {showDeleteConfirmation && (
          <ConfirmationPopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={this._deletePois}
            objectName={poiNames}
          />
        )}
        <DataTable
          data={poiList}
          notSortableFields={["store.selectedTags", "merchant.selectedTags"]}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          isColumnSettable={true}
          isRowEditable={this._isRowEditable}
          formatter={this.formatter}
          i18nKey="poi.list"
          idAttribute="poi.id"
          hideSearchBox={true}
          toggleable={true}
          hasActions={false}
          customColumns={[linkColumnPos, linkColumnSim]}
          disableStatusValues={["Suspended"]}
          onRefresh={this._search}
          onUpdateColumns={this._updateColumns}
          selectableNew={true}
          selectionButtonKey={[
            deletePois,
            editPoi,
            linkPoiButton,
            swapPoiButton,
            UnLinkPosButton
          ]}
          version="new"
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedPoiIds}
          useSelection={true}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={poiCount}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const poisWithStatus = state => {
  const {
    pois: { dataList, count, scrollId },
    genericFields: { data: genericFields },
    services: { data: services = [] }
  } = state;
  const simMonitoring = hasMonitoringSimService(services);
  const fields = _getColumns({ type: "required", fields: genericFields });
  const pois = dataList.map(poi => {
    const { pos } = poi;
    const sim = poi.interface ? poi.interface.address : "";

    const normalizedPoi = fields.reduce((prevField, field) => {
      return {
        ...prevField,
        [field]: get(poi, field)
      };
    }, {});

    if (simMonitoring && sim) {
      return {
        ...normalizedPoi,
        pos,
        simSSN: sim
      };
    }

    return {
      ...normalizedPoi,
      pos
    };
  });

  return {
    count,
    scrollId,
    pois
  };
};

interface GetColumnsParams {
  type: string;
  fields: Array<string>;
}

const _getColumns = ({ type, fields }: GetColumnsParams) => {
  return fields.reduce((prevField: Array<string>, field: any) => {
    const { key, required, visible } = field;

    switch (type) {
      case "required": {
        if (visible || required) prevField.push(key);

        break;
      }
      default: {
        if (visible) prevField.push(key);

        break;
      }
    }

    return prevField;
  }, []);
};

const getPoiSelection = ({ selectionIds, pois }) => {
  const selection = pois.reduce((prevSelectedPoi, selectedPoi) => {
    const selectedPoiId = selectedPoi["poi.id"];
    if (selectionIds.includes(selectedPoiId)) {
      return {
        ...prevSelectedPoi,
        [selectedPoiId]: selectedPoi
      };
    }

    return prevSelectedPoi;
  }, {});

  return selection;
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isCustomer, isMerchant },
    genericFields: { data: fields },
    pois: { selection: selectionPoi, loading },
    config: {
      data: { tableCount, eventGatewayURL }
    },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const { permissions } = user;
  const simLinkable = _.includes(permissions, "simManagement");
  const hasTerminalManagement = _.includes(permissions, "terminalManagement");
  const hasSwapManagement = _.includes(permissions, "swapManagement");
  const hasPosManagement = _.includes(permissions, "posManagement");
  const hasMerchantManagement = _.includes(permissions, "merchantManagement");
  const {
    count: poiCount,
    pois: poiList,
    scrollId: poiScrollId
  } = poisWithStatus(state);

  const selectedPoiIds = _.keys(_.pick(selectionPoi, _.identity));
  const selection = getPoiSelection({
    selectionIds: selectedPoiIds,
    pois: poiList
  });

  const columnSetting = user ? user.columnSetting : false;
  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const attributes = _getColumns({ fields });

  return {
    loading,
    isCustomer,
    isMerchant,
    poiList,
    selectedPoiIds,
    selection,
    tableCount,
    poiCount,
    poiScrollId,
    simLinkable,
    hasTerminalManagement,
    hasSwapManagement,
    hasPosManagement,
    hasMerchantManagement,
    visibleAttributes,
    attributes,
    sortDescription,
    eventGatewayURL,
    searchContext,
    selectionPoi
  };
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting &&
    columnSetting.terminalSetting &&
    columnSetting.terminalSetting.length > 0
    ? columnSetting.terminalSetting
    : _getColumns({ fields });
};

const mapDispatchToProps = dispatch => ({
  getFields: ({ name }) => dispatch(getFields({ name })),
  getPoisList: ({ filters, fields, sort, tableCount }) =>
    dispatch(getPoisList({ filters, fields, sort, tableCount })),
  getPoisScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "poi", scrollId })),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "POIS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "POIS" })),
  toggleSelectAll: () => dispatch(toggleSelectAll({ selectionType: "POIS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "POIS" })),
  deletePois: ({ poiIds }) => dispatch(deletePois({ poiIds })),
  deletePoi: ({ id, sectionType }) =>
    dispatch(deleteEvent({ id, sectionType })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ListPoiView);
