export default (state = { level: null }, action: any) => {
  switch (action.type) {
    case `ADD_NOTIFICATION_${action.name}_ACTION`: {
      const { payload } = action;

      return {
        ...payload
      };
    }
    default:
      return state;
  }
};
