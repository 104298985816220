import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from "classnames";
import _ from "lodash";
import { upperCaseFirst } from "upper-case-first";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import I18nSpan from "../../i18n/components/I18nSpan";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import If from "../../components/if";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import Permissions from "../constants/Permissions";
import UserMode from "../constants/UserMode";
import Sanitizer from "../../commons/Sanitizer";
import SelectFactorPopin from "../../components/SelectFactorPopin";
import { userErrorNotification } from "../validations/UserValidations";

import { changeLang } from "../../i18n";

import {
  getUsers,
  getUsersHierarchy,
  getMerchants,
  getStores,
  getUserSettingsFetcher,
  getLevelSettingsFetcher,
  getMenu,
  updateUserStatus,
  deleteUser,
  resetUserPassword,
  impersonate,
  revokeToken,
  changeUserMode,
  resetUserMode,
  addNotificationSuccess,
  addNotificationError,
  getServices,
  getColor,
  getLogo,
  toggleSelect,
  toggleUnSelect,
  toggleSelectAll,
  toggleUnSelectAll,
  revokeSecondFactor
} from "../../redux/actions";

import {
  EditAction,
  DeleteAction,
  ResetUserPasswordAction,
  RevokeTokenAction,
  LoginAsAction,
  ToggleAction,
  AssignSecondFactorAction
} from "../../datatable/components/multipleActions";

import { getNamesFromSelection } from "../../redux/reducers/selection";

class ListUserView extends Component<Props, State> {
  state = {
    showPopinSingle: false
  };

  async componentDidMount() {
    const { getUsersOrHierarchy, getMerchantsOrStores } = this.props;

    const users = await getUsersOrHierarchy({});

    const ids = users.reduce((acc, current) => {
      const {
        scope: {
          access: { restrictedByIds = [] }
        }
      } = current;
      return [...acc, ...restrictedByIds];
    }, []);

    if (users.length === 0) {
      return;
    }

    getMerchantsOrStores(ids);
  }

  componentWillUnmount() {
    const { resetUserMode } = this.props;

    resetUserMode();
  }

  _resetPwd = userId => async event => {
    event.preventDefault();

    const {
      resetUserPassword,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    try {
      await resetUserPassword(userId);

      return addNotificationSuccess("user.reset.success");
    } catch {
      return addNotificationError("notification.error");
    }
  };

  _revokeToken = ({ type, userId }) => async event => {
    event.preventDefault();

    const {
      revokeToken,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const revokeType = upperCaseFirst(type);

    try {
      await revokeToken({ type, userId });

      return addNotificationSuccess(`user.revoke${revokeType}.success`);
    } catch {
      return addNotificationError("notification.error");
    }
  };

  _revokeSecondFactor = ({ userId }) => async event => {
    event.preventDefault();

    const {
      revokeSecondFactor,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    try {
      await revokeSecondFactor({ userId });

      return addNotificationSuccess(`user.revokeSecondFactor.success`);
    } catch (error) {
      return userErrorNotification({
        error,
        addNotificationError,
        form: userId
      });
    }
  };

  _revokeDeviceToken = userId => this._revokeToken({ type: "device", userId });

  _revokeMachineToken = userId =>
    this._revokeToken({ type: "machine", userId });

  _loginAs = async login => {
    const {
      impersonate,
      getUserSettings,
      getLevelSettings,
      getMenu,
      history,
      addNotificationSuccess,
      addNotificationError,
      getServices,
      getColor,
      getLogo,
      i18n
    } = this.props;

    try {
      await impersonate(login);
      const { language } = await getUserSettings();
      await getLevelSettings();

      changeLang(language);

      await Promise.all([getColor(), getLogo(), getMenu(), getServices()]);

      addNotificationSuccess("monitoring.connect-as.form.success");

      history.push("/main");
    } catch {
      return addNotificationError("notification.error");
    }
  };

  _deleteUser = async () => {
    const {
      deleteUser,
      addNotificationSuccess,
      addNotificationError,
      selectedUserIds: ids
    } = this.props;
    const userId = ids[0];

    try {
      await deleteUser({ userId });
      addNotificationSuccess("user.delete.success");
      return this._closePopinSingle();
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      addNotificationError(errorKey);

      return this._closePopinSingle();
    }
  };

  _showSelectFactorPopin = () => {
    const { selectedUserIds } = this.props;
    const [userId] = selectedUserIds;
    this.setState({
      showSelectFactorPopin: true,
      userId
    });
  };

  _closeSelectFactorPopin = () => {
    this.setState({
      showSelectFactorPopin: false
    });
  };

  _openPopinSingle = userId => {
    const { selection } = this.props;
    const userNames = getNamesFromSelection({
      selection,
      keyName: "login"
    });

    this.setState({
      showPopinSingle: true,
      userNames
    });
  };

  _closePopinSingle = () => {
    this.setState({
      showPopinSingle: false
    });
  };

  _updateStatus = async status => {
    const {
      selectedUserIds: ids,
      updateUserStatus,
      addNotificationError
    } = this.props;

    const newStatus =
      status === "Suspended" || status === "Pending" ? "Active" : "Suspended";
    const userId = ids[0];
    try {
      await updateUserStatus({ id: userId, status: newStatus });
    } catch (promiseError) {
      const { errorKey } = await promiseError;

      return addNotificationError(errorKey);
    }
  };

  loadMyUsers = async () => {
    const { changeUserMode, getUsers, getMerchants } = this.props;

    changeUserMode(UserMode.MINE);

    const users = await getUsers({});

    const ids = users.reduce((acc, current) => {
      const {
        scope: {
          access: { restrictedByIds = [] }
        }
      } = current;
      return [...acc, ...restrictedByIds];
    }, []);

    const filters = [
      {
        filterType: "array",
        name: "merchant.id",
        operator: "in",
        value: _.uniq(ids)
      }
    ];

    return users.length === 0
      ? _.noop()
      : getMerchants({ filters, fields: ["merchant.id", "merchant.name"] });
  };

  loadForHierarchy = async () => {
    const {
      changeUserMode,
      getUsersHierarchy,
      getStores,
      getMerchants,
      match: {
        params: { level: urlLevel }
      }
    } = this.props;

    changeUserMode(UserMode.MY_HIERARCHY);

    const users = await getUsersHierarchy({ urlLevel });

    const ids = users.reduce((acc, current) => {
      const {
        scope: {
          access: { restrictedByIds = [] }
        }
      } = current;
      return [...acc, ...restrictedByIds];
    }, []);

    const idsMerchants = users.reduce((acc, current) => {
      const {
        scope: {
          level: { id }
        }
      } = current;

      return [...acc, id];
    }, []);

    const filters = [
      {
        filterType: "array",
        name: "store.id",
        operator: "in",
        value: _.uniq(ids)
      }
    ];

    const filtersMerchant = [
      {
        filterType: "array",
        name: "merchant.id",
        operator: "in",
        value: _.uniq(idsMerchants)
      }
    ];
    getMerchants({
      filters: filtersMerchant,
      fields: ["merchant.id", "merchant.name"]
    });

    return users.length === 0
      ? _.noop()
      : getStores({ filters, fields: ["store.id", "store.name"] });
  };

  _headerElCustom = ({
    key,
    style,
    attribute,
    icon,
    sortFunc,
    renderAttributeFunc,
    sortable
  }) => {
    const { userMode, isIngenico, isCustomer, isMerchant } = this.props;

    const customizeAttribute = ({ attribute }) => {
      if (
        attribute === "selectedIds" &&
        isCustomer &&
        userMode === UserMode.MINE
      ) {
        return "merchantSelectedIds";
      }
      if (
        attribute === "selectedIds" &&
        (isMerchant || (isCustomer && userMode === UserMode.MY_HIERARCHY))
      ) {
        return "storeSelectedIds";
      }
      if (attribute === "name" && isIngenico) {
        return "name";
      }
      return attribute;
    };

    const customAttribute = customizeAttribute({ attribute });

    return (
      <div
        key={key}
        style={style}
        className={classNames(
          `grid-header header-${Sanitizer.className(attribute)}`,
          sortable ? "sortable" : ""
        )}
        onClick={() => {
          return sortable && sortFunc(attribute);
        }}
      >
        {renderAttributeFunc(customAttribute)}
        {sortable && icon}
      </div>
    );
  };

  onSelectRow = (rowId, value) => {
    const { selectedUserIds, toggleUnSelect, toggleSelect } = this.props;

    if (selectedUserIds.includes(rowId)) {
      return toggleUnSelect({ id: rowId });
    }

    return toggleSelect({ id: rowId, value });
  };

  onSelectAllRows = () => {
    const { toggleSelectAll } = this.props;

    return toggleSelectAll();
  };

  onUnSelectAllRows = () => {
    const { toggleUnSelectAll } = this.props;

    return toggleUnSelectAll();
  };

  render() {
    const {
      history,
      match: {
        params: { level: urlLevel }
      },
      isIngenico,
      isCustomer,
      isMerchant,
      hasMPosUserPermission,
      hasMPosEnrollmentPermission,
      hasMachineUserPermission,
      hasMerchantUserPermission,
      hasStoreManagementPermission,
      hasImpersonation,
      hasUserPermission,
      labels: {
        resetPwd,
        revokeDeviceUser,
        revokeMachineUser,
        loginAs,
        assignSecondFactor
      },
      hasFullAccess,
      attributesToDisplay,
      users,
      color,
      selectedUserIds
    } = this.props;
    const { showSelectFactorPopin, userId } = this.state;

    const AssignSecondFactor = () => {
      if (selectedUserIds.length === 1) {
        return (
          <AssignSecondFactorAction
            onAssign={this._showSelectFactorPopin}
            msgKey={assignSecondFactor}
          />
        );
      }

      return null;
    };

    const EditUser = () => {
      if (selectedUserIds.length === 1) {
        const [userId] = selectedUserIds;
        const { isIngenico } = this.props;

        const route = `/main/${
          isIngenico ? "" : "settings/"
        }user/edit-user/${userId}`;

        return <EditAction route={route} msgKey={"user.edit.action"} />;
      }

      return null;
    };

    const DeleteUser = () => {
      if (selectedUserIds.length === 1) {
        return <DeleteAction onDelete={this._openPopinSingle} />;
      }

      return null;
    };

    const ResetUser = () => {
      if (selectedUserIds.length === 1) {
        const [userId] = selectedUserIds;

        return (
          <ResetUserPasswordAction
            onReset={this._resetPwd(userId)}
            msgKey={resetPwd}
          />
        );
      }

      return null;
    };

    const RevokeMachineUser = () => {
      const [userId] = selectedUserIds;
      const selectedUser = users.filter(user => userId === user.login);
      const permissions = selectedUser[0] ? selectedUser[0].permissions : [];
      if (
        isCustomer &&
        hasMachineUserPermission &&
        _.includes(permissions, Permissions.MACHINE_ENROLLMENT) &&
        selectedUserIds.length === 1
      ) {
        return (
          <RevokeTokenAction
            onRevoke={this._revokeMachineToken(userId)}
            msgKey={revokeMachineUser}
          />
        );
      }

      return null;
    };

    const RevokeDeviceUser = () => {
      const [userId] = selectedUserIds;
      const selectedUser = users.filter(user => userId === user.login);
      const permissions = selectedUser[0] ? selectedUser[0].permissions : [];
      if (
        isMerchant &&
        hasMPosUserPermission &&
        _.includes(permissions, Permissions.MPOS_ENROLLMENT) &&
        selectedUserIds.length === 1
      ) {
        return (
          <RevokeTokenAction
            onRevoke={this._revokeDeviceToken(userId)}
            msgKey={revokeDeviceUser}
          />
        );
      }

      return null;
    };

    const LoginAsUser = () => {
      if (selectedUserIds.length === 1 && hasImpersonation) {
        const [userId] = selectedUserIds;

        return (
          <LoginAsAction
            onLoginAs={this._loginAs.bind(this, userId)}
            msgKey={loginAs}
          />
        );
      }

      return null;
    };

    const toggleActivationButton = () => {
      const [userId] = selectedUserIds;
      const selectedUser = users.filter(user => userId === user.login);
      const [selected] = selectedUser;
      const status = selected ? selected.status : "";

      if (selectedUserIds.length === 1) {
        return (
          <ToggleAction
            onToggle={() => this._updateStatus(status)}
            msgKey={`user.toggle.${
              status === "Pending" ? "Suspended" : status
            }`}
          />
        );
      }

      return null;
    };

    const RevokeSecondFactor = () => {
      const [userId] = selectedUserIds;
      if (
        selectedUserIds.length === 1 &&
        (hasUserPermission || hasMerchantUserPermission)
      ) {
        return (
          <RevokeTokenAction
            onRevoke={this._revokeSecondFactor({ userId })}
            msgKey={"user.revokeSecondFactor.title"}
          />
        );
      }

      return null;
    };

    const UserModeSwitch = ({
      hasMerchantUserPermission,
      hasStoreManagementPermission
    }) => {
      if (hasMerchantUserPermission && hasStoreManagementPermission) {
        const handleClickMine = async () => {
          await history.push("/main/settings/user");
          return this.loadMyUsers();
        };

        const handleClickHierarchy = async () => {
          await history.push("/main/settings/user/merchant");
          return this.loadForHierarchy();
        };

        return (
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-ingenico ${!urlLevel ? "active" : ""}`}
              onClick={handleClickMine}
            >
              <I18nSpan msgKey={"user.list.button.mine"} />
            </button>
            <button
              type="button"
              className={`btn btn-ingenico ${urlLevel ? "active" : ""}`}
              onClick={handleClickHierarchy}
            >
              <I18nSpan msgKey={"user.list.button.myHierarchy"} />
            </button>
          </div>
        );
      }

      return null;
    };

    const displayedCreateUser =
      !urlLevel && (isCustomer || (isMerchant && hasStoreManagementPermission));
    const displayedCreateMposUser =
      isMerchant &&
      hasFullAccess &&
      hasMPosUserPermission &&
      hasMPosEnrollmentPermission;

    const CreationButtonComponent = ({ displayed }) => {
      if (displayed) {
        return (
          <Link
            className={classNames("btn", "btn-ingenico", "create-button")}
            to={"/main/settings/user/new-user"}
          >
            <span className="glyphicon glyphicon-plus" />
            <I18nSpan msgKey={"user.list.button.create"} />
          </Link>
        );
      }

      return null;
    };

    const CreationMposButtonComponent = ({ displayed }) => {
      if (displayed) {
        return (
          <Link
            className={classNames("btn", "btn-ingenico", "create-button")}
            to={"/main/settings/user/new-mposuser"}
          >
            <span className="glyphicon glyphicon-plus" />
            <I18nSpan msgKey={"user.list.button.create-mpos"} />
          </Link>
        );
      }

      return null;
    };

    const userSearchAligned =
      isIngenico ||
      (isCustomer &&
        !(hasMerchantUserPermission && hasStoreManagementPermission)) ||
      isMerchant;

    return (
      <div className="data-table-wrapper">
        <div className={classNames("user-buttons-container")}>
          <div>
            <FormTitle titleKey="user.title" actionKey="user.list.action" />
          </div>
          <div>
            <CreationButtonComponent displayed={displayedCreateUser} />
            <CreationMposButtonComponent displayed={displayedCreateMposUser} />
          </div>
        </div>
        <UserModeSwitch
          hasMerchantUserPermission={hasMerchantUserPermission}
          hasStoreManagementPermission={hasStoreManagementPermission}
        />

        <If test={this.state.showPopinSingle}>
          <ConfirmationPopin
            onClosePopin={this._closePopinSingle}
            onClickButton={this._deleteUser}
            objectName={this.state.userNames}
          />
        </If>

        {showSelectFactorPopin && (
          <SelectFactorPopin
            onClosePopin={this._closeSelectFactorPopin}
            userId={userId}
          />
        )}

        <DataTable
          data={users}
          attributes={attributesToDisplay}
          i18nKey={!isIngenico && urlLevel ? "user.list" : "user.listCustomer"}
          idAttribute="login"
          color={color}
          disableStatusValues={["Suspended"]}
          headerElVirtualized={this._headerElCustom}
          hasActions={false}
          selectionButtonKey={[
            EditUser,
            DeleteUser,
            ResetUser,
            RevokeMachineUser,
            RevokeDeviceUser,
            LoginAsUser,
            toggleActivationButton,
            AssignSecondFactor,
            RevokeSecondFactor
          ]}
          onToggleSelect={this.onSelectRow}
          onToggleSelectAll={this.onSelectAllRows}
          onToggleUnSelectAll={this.onUnSelectAllRows}
          selection={selectedUserIds}
          useSelection={true}
          selectableNew={true}
          userSearch={true}
          userSearchAligned={userSearchAligned}
        />
      </div>
    );
  }
}

const scopesForAuthenticatedUser = ({
  state,
  userMode,
  isIngenico,
  isCustomer
}: {
  state: any;
  userMode: string;
  isIngenico: boolean;
  isCustomer: boolean;
}) => {
  const {
    customers: { data: customers },
    merchants: { data: merchants },
    stores: { data: stores }
  } = state;

  if (isIngenico) {
    return customers;
  } else if (isCustomer && userMode === UserMode.MINE) {
    return merchants;
  } else {
    return stores;
  }
};

const scopesForReformatName = ({
  state,
  userMode,
  isIngenico,
  isCustomer
}: {
  state: any;
  userMode: string;
  isIngenico: boolean;
  isCustomer: boolean;
}) => {
  const {
    customers: { data: customers },
    merchants: { data: merchants }
  } = state;

  if (isIngenico) {
    return customers;
  } else if (isCustomer && userMode === UserMode.MY_HIERARCHY) {
    return merchants;
  }
};

const _reformattingSelectedTags = access => {
  return _.values(access.restrictedByTags).join(", ");
};

const _reformattingSelectedIds = (access, scopes) => {
  const ids = _.values(access.restrictedByIds);

  return _(scopes)
    .filter(scope => _.includes(ids, scope.id))
    .map(scope => scope.name)
    .value()
    .join(", ");
};

const _reformattingName = (level, scopes) => {
  const { id } = level;

  return scopes
    .filter(scope => _.includes(id, scope.id))
    .map(scope => scope.name);
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isIngenico, isCustomer, isMerchant },
    users: { data: prevUsers, selection, userMode: initialUserMode },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount }
    }
  } = state;
  const {
    t,
    match: {
      params: { level: urlLevel }
    }
  } = ownProps;
  const { permissions } = user;

  const userMode = urlLevel ? UserMode.MY_HIERARCHY : initialUserMode;

  const scopes = scopesForAuthenticatedUser({
    state,
    userMode,
    isIngenico,
    isCustomer
  });
  const scopesDataForReformatName = scopesForReformatName({
    state,
    userMode,
    isIngenico,
    isCustomer
  });
  const hasLevel = ownProps.match.params.level !== undefined;

  scopes.map(scope => {
    if (isMerchant || hasLevel) {
      const { store = {} } = scope;
      scope = Object.assign(scope, {
        id: store.id,
        name: store.name
      });
    } else if (isCustomer && !hasLevel) {
      const { merchant = {} } = scope;
      scope = Object.assign(scope, {
        id: merchant.id,
        name: merchant.name
      });
    }

    return scope;
  });

  if (isCustomer && userMode === UserMode.MY_HIERARCHY) {
    scopesDataForReformatName.map(scope => {
      const { merchant = {} } = scope;
      scope = Object.assign(scope, {
        id: merchant.id,
        name: merchant.name
      });

      return scope;
    });
  }

  const hasImpersonation = user
    ? _.includes(user.permissions, Permissions.IMPERSONATION)
    : false;
  const hasFullAccess = user && user.scope ? user.scope.access.full : false;

  const users = prevUsers.map(user => {
    user.selectedTags = _reformattingSelectedTags(user.scope.access);
    user.selectedIds = _reformattingSelectedIds(user.scope.access, scopes);
    user.statusi18n = t(`dataTable.state.${user.status}`);
    if (isIngenico || (isCustomer && userMode === UserMode.MY_HIERARCHY)) {
      const [name] = _reformattingName(
        user.scope.level,
        scopesDataForReformatName
      );
      user.name = name;
    }

    return user;
  });

  const hasMerchantManagementPermission =
    !isIngenico && _.includes(permissions, Permissions.MERCHANT_MANAGEMENT);
  const hasStoreManagementPermission =
    !isIngenico && _.includes(permissions, Permissions.STORE_MANAGEMENT);

  const basicAttributes = [
    "login",
    "email",
    "selectedTags",
    ...((isCustomer && hasMerchantManagementPermission) ||
    (isMerchant && hasStoreManagementPermission)
      ? ["selectedIds"]
      : []),
    "statusi18n"
  ];

  const attributesToDisplay = isIngenico
    ? ["name", "login", "email", "statusi18n"]
    : userMode === UserMode.MY_HIERARCHY
    ? ["name"].concat(basicAttributes)
    : basicAttributes;

  const selectedUserIds = _.keys(_.pick(selection, _.identity));

  return {
    labels: {
      loginAs: t("user.loginAs.tooltip"),
      resetPwd: t("user.reset.tooltip"),
      deleteUser: t("user.delete.tooltip"),
      revokeDeviceUser: t("user.revokeDevice.tooltip"),
      revokeMachineUser: t("user.revokeMachine.tooltip"),
      assignSecondFactor: t("user.assignSecondFactor.tooltip")
    },
    color,
    hasMerchantUserPermission:
      !isIngenico &&
      _.includes(permissions, Permissions.MERCHANT_USER_MANAGEMENT),
    hasUserPermission:
      !isIngenico && _.includes(permissions, Permissions.USER_MANAGEMENT),
    hasMPosUserPermission:
      !isIngenico && _.includes(permissions, Permissions.MPOS_USER_MANAGEMENT),
    hasMPosEnrollmentPermission:
      !isIngenico && _.includes(permissions, Permissions.MPOS_ENROLLMENT),
    hasMachineUserPermission:
      !isIngenico &&
      _.includes(permissions, Permissions.MACHINE_USER_MANAGEMENT),
    hasMerchantManagementPermission,
    hasStoreManagementPermission,
    hasImpersonation,
    hasFullAccess,
    users,
    userMode,
    isIngenico,
    isCustomer,
    isMerchant,
    attributesToDisplay,
    tableCount,
    selectedUserIds,
    selection
  };
};

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(getUsers()),
  getUsersHierarchy: ({ urlLevel }) =>
    dispatch(getUsersHierarchy({ urlLevel })),
  getMerchants: ({ filters, fields }) =>
    dispatch(getMerchants({ filters, fields })),
  getStores: ({ filters, fields }) => dispatch(getStores({ filters, fields })),
  getUserSettings: () => dispatch(getUserSettingsFetcher()),
  getLevelSettings: () => dispatch(getLevelSettingsFetcher()),
  getMenu: () => dispatch(getMenu()),
  updateUserStatus: ({ id, status }) =>
    dispatch(updateUserStatus({ id, status })),
  deleteUser: ({ userId }) => dispatch(deleteUser({ userId })),
  resetUserPassword: userId => dispatch(resetUserPassword(userId)),
  impersonate: userId => dispatch(impersonate(userId)),
  revokeToken: ({ type, userId }) => dispatch(revokeToken({ type, userId })),
  revokeSecondFactor: ({ userId }) => dispatch(revokeSecondFactor({ userId })),
  changeUserMode: userMode => dispatch(changeUserMode(userMode)),
  resetUserMode: () => dispatch(resetUserMode()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getServices: () => dispatch(getServices()),
  getColor: () => dispatch(getColor()),
  getLogo: () => dispatch(getLogo()),
  toggleSelect: ({ id, value }) =>
    dispatch(toggleSelect({ id, value, selectionType: "USERS" })),
  toggleUnSelect: ({ id }) =>
    dispatch(toggleUnSelect({ id, selectionType: "USERS" })),
  toggleSelectAll: () => dispatch(toggleSelectAll({ selectionType: "USERS" })),
  toggleUnSelectAll: () =>
    dispatch(toggleUnSelectAll({ selectionType: "USERS" }))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    match: {
      params: { level: urlLevel }
    }
  } = ownProps;

  const {
    isIngenico,
    isCustomer,
    isMerchant,
    userMode,
    hasMerchantManagementPermission,
    hasStoreManagementPermission
  } = stateProps;
  const getUsersOrHierarchy = () =>
    !isIngenico && userMode === UserMode.MINE && !urlLevel
      ? dispatchProps.getUsers()
      : dispatchProps.getUsersHierarchy({ urlLevel });
  const getMerchantsOrStores = (ids = []) => {
    if (isCustomer && hasMerchantManagementPermission) {
      return dispatchProps.getMerchants({
        filters: [
          {
            filterType: "array",
            name: "merchant.id",
            operator: "in",
            value: ids
          }
        ],
        fields: ["merchant.id", "merchant.name"]
      });
    } else if (isMerchant && hasStoreManagementPermission) {
      return dispatchProps.getStores({
        filters: [
          {
            filterType: "array",
            name: "store.id",
            operator: "in",
            value: ids
          }
        ],
        fields: ["store.id", "store.name"]
      });
    }
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    getUsersOrHierarchy,
    getMerchantsOrStores
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(ListUserView);
