import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";

import {
  getSchemes,
  uploadScheme,
  deleteScheme,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";
import ConfirmationPopin from "../../components/ConfirmationPopin";
import UploadFile from "../../components/upload/UploadFile";

interface Props {
  deleteScheme: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  uploadScheme: Function;
  getSchemes: Function;
  color: any;
  schemes: any;
}

interface Form {
  scheme: string | null;
}

interface State {
  form?: Form;
  inputKey?: number;
  schemeId?: string;
  deleteConfirmation?: boolean;
}

class SchemeView extends Component<Props, State> {
  state = {
    form: {
      scheme: null
    },
    deleteConfirmation: false,
    schemeId: null,
    inputKey: Date.now()
  };

  attributes = [
    "id",
    "value.applicationName",
    "value.schemeVersion",
    "value.interfaceVersion",
    "value.formatVersion",
    "value.coe"
  ];

  _closeDeletePopin = () => {
    this.setState({
      deleteConfirmation: false
    });
  };

  _showDeletePopin = schemeId => event => {
    return this.setState({
      deleteConfirmation: true,
      schemeId
    });
  };

  componentDidMount() {
    const { getSchemes } = this.props;

    getSchemes();
  }

  _uploadScheme = fileContent => {
    const {
      uploadScheme,
      getSchemes,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    const data = new FormData();
    data.append("scheme", fileContent);

    return uploadScheme(data).then(
      () => {
        this.setState({ form: { scheme: null }, inputKey: Date.now() });
        addNotificationSuccess("scheme.upload.success");
        return getSchemes();
      },
      () => addNotificationError("notification.error")
    );
  };

  _deleteScheme = event => {
    const {
      deleteScheme,
      addNotificationSuccess,
      addNotificationError
    } = this.props;

    const { schemeId } = this.state;

    deleteScheme(schemeId).then(
      () => {
        addNotificationSuccess("scheme.delete.success");
        this._closeDeletePopin();
      },
      () => addNotificationError("notification.error")
    );
  };

  render() {
    const { color, schemes, formatter } = this.props;
    const { schemeId, deleteConfirmation } = this.state;

    return (
      <div className="data-table-wrapper">
        {deleteConfirmation && (
          <ConfirmationPopin
            onClosePopin={this._closeDeletePopin}
            onClickButton={this._deleteScheme}
            objectName={schemeId}
          />
        )}
        <FormTitle titleKey="scheme.title" actionKey="scheme.upload.action" />
        <div className="form-group">
          <label className="control-label col-sm-1">
            <span>Application file</span>
            <sup className="required-label" />
          </label>
          <div className={"col-sm-10"}>
            <UploadFile
              uploadUrl={"/poi/import"}
              fileKey="scheme"
              tooltip="poi.import.template"
              buttonsTitles={{
                upload: "poi.import.upload",
                templates: "poi.import.template"
              }}
              notifications={{
                success: "scheme.upload.success",
                error: "notification.error"
              }}
              filesAccepted={[".json"]}
              onSubmit={this._uploadScheme}
            />
          </div>
        </div>
        <DataTable
          data={schemes}
          attributes={this.attributes}
          key="scheme.list"
          i18nKey="scheme.list"
          idAttribute="id"
          onDeleteButtonClick={this._showDeletePopin}
          color={color}
          hideSearchBox={true}
          formatter={formatter}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    schemes: { data: schemes },
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  return {
    color,
    schemes,
    formatter: {
      "value.coe": coe => (coe ? coe.join("/") : "")
    }
  };
};

const mapDispatchToProps = dispatch => ({
  getSchemes: () => dispatch(getSchemes()),
  uploadScheme: scheme => dispatch(uploadScheme(scheme)),
  deleteScheme: schemeId => dispatch(deleteScheme(schemeId)),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SchemeView);
