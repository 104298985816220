import React, { Component } from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import OfferUtils from "../util/OfferUtils";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import { requiredValidation } from "../validations/OfferValidations";

import {
  getOffer,
  editOffer,
  resetOfferState,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface State {
  from: any;
  errors: any;
}

interface Props {
  options: any;
  editOffer: Function;
  addNotificationSuccess: Function;
  addNotificationError: Function;
  getOffer: Function;
  resetOfferState: Function;
  match: any;
  history: any;
}

class EditOfferView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentDidMount() {
    const {
      match: {
        params: { offerId: id }
      },
      getOffer
    } = this.props;

    getOffer(id);
  }

  componentWillReceiveProps(nextProps) {
    const { form, errors } = nextProps;

    return this.setState({
      form,
      errors
    });
  }

  componentWillUnmount() {
    const { resetOfferState } = this.props;

    resetOfferState();
  }

  _updateOffer = e => {
    e.preventDefault();

    const {
      editOffer,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const defaultApplicationSettings = { mPos: {} };
    const { form } = this.state;

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        try {
          const offer = {
            ...form,
            services: (form.services || []).map(
              (service: any) => service.value
            ),
            applicationSettings:
              form.applicationSettings || defaultApplicationSettings
          };

          await editOffer({ offer });

          addNotificationSuccess("offer.edit.success");
          this._goToList();
        } catch (error) {
          const { key: errorKey } = await error;
          const notificationErrorKey = `notification.offer.error.${errorKey}`;

          return addNotificationError(notificationErrorKey);
        }
      }
    });
  };

  _goToList = () => {
    const { history } = this.props;

    return history.push("/main/offers");
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value;
    this.setState(newForm, this._checkError(name));
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _checkError(name) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.props.form), {}, then);
  }

  _noop() {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  }

  render() {
    const { form, errors = {} } = this.state;
    const { options } = this.props;

    return (
      <div>
        <FormTitle titleKey="offer.title" actionKey="offer.edit.action" />
        <form className="ingenico-form form-horizontal create-store-form">
          <BootstrapInput
            validation={requiredValidation()}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            errors={errors.name}
            required={true}
            descriptor={{
              type: "text",
              label: "offer.form.name.label"
            }}
            formValue={form.name}
          />
          <BootstrapInput
            descriptor={{
              type: "multipleselect",
              options: options.services,
              label: "offer.form.services.label",
              className: "servicesOffer mtn"
            }}
            inputRef={this.setInputRef("services")}
            name="services"
            validation={this._noop}
            onChange={this._onChangeHandler}
            formValue={form.services}
          />
          <BootstrapInput
            descriptor={{
              type: "jsonarea",
              label: "offer.form.applicationSettings.label",
              template: "offer.form.applicationSettings.template"
            }}
            inputRef={this.setInputRef("applicationSettings")}
            name="applicationSettings"
            onChange={this._onChangeHandler.bind(this)}
            formValue={form.applicationSettings}
            errors={this.state.errors.applicationSettings}
          />
          <div className="pull-right">
            <button
              onClick={this._updateOffer}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    offer: { data: currentOffer = [] },
    services: { data: servicesOptions = [] }
  } = state;
  const { t } = ownProps;

  const { services = [], applicationSettings = {} } = currentOffer;

  const form = {
    ...currentOffer,
    services: services.map(service => ({
      label: t(`service.${service}`),
      value: service
    })),
    applicationSettings: applicationSettings || {}
  };

  return {
    form,
    options: {
      services: OfferUtils.servicesToFormOptions(servicesOptions)
    },
    errors: {}
  };
};

const mapDispatchToProps = dispatch => ({
  editOffer: ({ offer }) => dispatch(editOffer({ offer })),
  getOffer: offerId => dispatch(getOffer(offerId)),
  resetOfferState: () => dispatch(resetOfferState()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(EditOfferView);
