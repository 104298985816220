import * as moment from "moment";
import {
  CreatePredefinedAlert,
  languages,
  PredefinedAlert,
  PredefinedAlertType
} from "./PredefinedAlert";
import PredefinedConstants from "../PredefinedAlertConstant";

const humanizeDuration = require("humanize-duration");

export class CreateMultipleUsageAlert extends CreatePredefinedAlert {
  constructor(public duration: moment.Duration, public nbUsages: number) {
    super(MultipleUsageAlert.TYPE);
    this.duration =
      duration ||
      moment.duration(PredefinedConstants.DEFAULT_DURATION, "minutes");
    this.nbUsages = nbUsages || PredefinedConstants.DEFAULT_NB_USAGES;
  }
}

export class MultipleUsageAlert extends PredefinedAlert {
  static TYPE: PredefinedAlertType = "MultipleUsage";
  static I18N_KEY = "multiple-usage";

  constructor(
    public id: string,
    public timeWindow: moment.Duration,
    public nbUsages: number
  ) {
    super();
    this.id = id;
    this.timeWindow = timeWindow;
    this.nbUsages = nbUsages;
  }

  i18nKey(): string {
    return MultipleUsageAlert.I18N_KEY;
  }

  i18nArgs(locale: string): Array<string> {
    return [
      this.nbUsages,
      humanizeDuration((this.timeWindow as any)._milliseconds, {
        delimiter: " ",
        largest: 2,
        round: true,
        language: locale,
        languages
      })
    ];
  }
}
