// @secure
export enum WidgetType {
  TransactionNumber = "transactionNumber",
  Cashback = "cashback",
  Revenue = "revenue",
  RefundAmount = "refundAmount",
  AverageBasket = "averageBasket",
  TransactionRevenueChart = "transactionRevenueChart",
  TerminalConnections = "terminalConnections",
  CustomerMerchantCount = "customerMerchantCount",
  CustomerPoiCount = "customerPoiCount",
  CustomerAverageRevenue = "customerAverageRevenue",
  TerminalsOverQuota = "terminalsOverQuota",
  MaintainerTerminalsWithoutTelecollectCount = "maintainerTerminalsWithoutTelecollectCount",
  MaintainerActiveTerminalsCount = "maintainerActiveTerminalsCount",
  ActiveSimsCount = "activeSimsCount",
  ComparisonAverageBasket = "comparisonAverageBasket",
  ComparisonCount = "comparisonCountChart",
  ComparisonAmount = "comparisonAmountChart",
  PaymentMethodsAmount = "paymentMethodsAmount",
  CardAverageBasket = "cardAverageBasket",
  PaymentMethodsCount = "paymentMethodsCount",
  CardsCount = "cardsCount",
  WalletsAmount = "walletsAmount",
  WalletsCount = "walletsCount",
  CardsAmount = "cardsAmount",
  WalletAverageBasket = "walletAverageBasket",
  CustomersCountPieChart = "customersCountPieChart",
  CustomersTransactionCountPieChart = "customersTransactionCountPieChart",
  CustomersAverageVisitsBadge = "customersAverageVisitsBadge",
  CustomersAverageBasketBarChart = "customersAverageBasketBarChart",
  CustomersTopByAmount = "customersTopByAmount",
  CustomersTopByCount = "customersTopByCount",
  BusinessActivity = "businessActivity",
  TerminalVolumesPer = "terminalVolumesPer",
  SimVolumesPer = "SimVolumesPer",
  GrossRevenue = "grossRevenue",
  NetRevenuePerTerminal = "netRevenuePerTerminal"
}
