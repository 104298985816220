import {
  getMerchantCount,
  getTerminalCount,
  getTransactionCount,
  getTransactionCashback,
  getComparisonCountChart,
  getComparisonAmountChart,
  getAverageBasket,
  getPaymentMethodsAmount,
  getComparisonAverageBasket,
  getCardsCount,
  getCardAverageBasket,
  getTerminalConnections,
  getMaintainerActiveTerminalsCount,
  getMaintainerTerminalsWithoutTelecollectCount,
  getTransactionRevenueChart,
  getTerminalsOverQuotaCount,
  getActiveSimsCount,
  getPaymentMethodsCount,
  getWalletsAmount,
  getWalletsCount,
  getCardsAmount,
  getWalletAverageBasket,
  loadTrendsTransactionCount,
  loadTrendsRevenues,
  loadTrendsCashback,
  loadTrendsAverageBasket,
  getCustomerCount,
  getCustomerTransactionCount,
  getAverageVisits,
  getCustomersAverageBasket,
  getCustomersTopByAmount,
  getCustomersTopByCount,
  getTerminalVolumesPer,
  getSimVolumesPer,
  loadTrendsRefundAmount,
  getRefundAmount,
  getGrossRevenue,
  getNetRevenue
} from "../../redux/actions";
import { WidgetType } from "../models/WidgetType";

export const getDataWithTrend = ({
  config,
  getData,
  getTrend,
  enableTrend
}: any) => {
  const trend = enableTrend ? [getTrend({ config })] : [];
  const data = getData({ config });
  return Promise.all([data, ...trend]);
};

export const getTrend = ({ config }: any) => {
  const { type: widgetType, id: widgetId } = config;

  switch (widgetType) {
    case WidgetType.RefundAmount: {
      return loadTrendsRefundAmount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.TransactionNumber: {
      return loadTrendsTransactionCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.Revenue: {
      return loadTrendsRevenues({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.Cashback: {
      return loadTrendsCashback({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.AverageBasket: {
      return loadTrendsAverageBasket({
        ...config,
        widgetType,
        widgetId
      });
    }
  }
};

export const getData = ({ config }: any) => {
  const { type: widgetType, id: widgetId, criteria, user } = config;

  switch (widgetType) {
    case WidgetType.RefundAmount: {
      return getRefundAmount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.TransactionNumber: {
      return getTransactionCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.Cashback: {
      return getTransactionCashback({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.Revenue:
    case WidgetType.NetRevenuePerTerminal: {
      return getNetRevenue({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.AverageBasket: {
      return getAverageBasket({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.TransactionRevenueChart: {
      return getTransactionRevenueChart({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.TerminalConnections: {
      return getTerminalConnections({
        ...config,
        widgetType,
        widgetId,
        aggregation: "pos.poiContract.raw",
        alias: "contract"
      });
    }
    case WidgetType.CustomerMerchantCount: {
      return getMerchantCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CustomerPoiCount: {
      return getTerminalCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CustomerAverageRevenue:
    case WidgetType.GrossRevenue: {
      return getGrossRevenue({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.TerminalsOverQuota: {
      return getTerminalsOverQuotaCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.MaintainerTerminalsWithoutTelecollectCount: {
      return getMaintainerTerminalsWithoutTelecollectCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.MaintainerActiveTerminalsCount: {
      return getMaintainerActiveTerminalsCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.ActiveSimsCount: {
      const {
        scope: {
          level: { id: userId }
        }
      } = user;

      return getActiveSimsCount({
        ...config,
        widgetType,
        widgetId,
        userId
      });
    }
    case WidgetType.ComparisonAverageBasket: {
      return getComparisonAverageBasket({
        ...config,
        widgetType,
        widgetId,
        alias: criteria
      });
    }
    case WidgetType.ComparisonCount: {
      return getComparisonCountChart({
        ...config,
        widgetType,
        widgetId,
        alias: criteria
      });
    }
    case WidgetType.ComparisonAmount: {
      return getComparisonAmountChart({
        ...config,
        widgetType,
        widgetId,
        alias: criteria
      });
    }
    case WidgetType.PaymentMethodsAmount: {
      return getPaymentMethodsAmount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CardAverageBasket: {
      return getCardAverageBasket({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.PaymentMethodsCount: {
      return getPaymentMethodsCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CardsCount: {
      return getCardsCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.WalletsAmount: {
      return getWalletsAmount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.WalletsCount: {
      return getWalletsCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CardsAmount: {
      return getCardsAmount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.WalletAverageBasket: {
      return getWalletAverageBasket({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CustomersCountPieChart: {
      return getCustomerCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CustomersTransactionCountPieChart: {
      return getCustomerTransactionCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CustomersAverageVisitsBadge: {
      return getAverageVisits({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CustomersAverageBasketBarChart: {
      return getCustomersAverageBasket({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CustomersTopByAmount: {
      return getCustomersTopByAmount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.CustomersTopByCount: {
      return getCustomersTopByCount({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.TerminalVolumesPer: {
      return getTerminalVolumesPer({
        ...config,
        widgetType,
        widgetId
      });
    }
    case WidgetType.SimVolumesPer: {
      const {
        scope: {
          level: { id: userId }
        }
      } = user;

      return getSimVolumesPer({
        ...config,
        widgetType,
        widgetId,
        userId
      });
    }
  }
};
