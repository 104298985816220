import React from "react";

import KeyIndicator from "../KeyIndicator/KeyIndicator";
import I18nAmount from "../../i18n/components/I18nAmount";
import { withTranslation } from "react-i18next";

interface Props {
  loading: boolean;
  error?: boolean;
  title: string;
  color: string;
  value: number;
  renderTrend: Function;
  currency: any;
}

const AverageBasket = ({
  title,
  color,
  error,
  loading,
  value: prevValue,
  formatter,
  currency: userCurrency,
  renderTrend
}: Props) => {
  const { currency, currencyDecimal, currencyCodeAlpha3, value } = formatter
    ? formatter({ userCurrency, value: prevValue })
    : { ...currency, value: prevValue };

  return (
    <KeyIndicator
      color={color}
      titleKey={title}
      loading={loading}
      error={error}
      renderTrend={() => renderTrend()}
    >
      <div>
        <I18nAmount
          number={value}
          currency={currency}
          currencyDecimal={currencyDecimal}
          currencyCodeAlpha3={currencyCodeAlpha3}
        />
      </div>
    </KeyIndicator>
  );
};

export default withTranslation()(AverageBasket);
