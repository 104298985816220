import F from "../../commons/HTTPFetcher";

import {
  FAILURE_CREATE_WIDGET_ACTION,
  FAILURE_DELETE_WIDGET_ACTION,
  FAILURE_GET_AVAILABLE_WIDGETS_ACTION,
  FAILURE_GET_WIDGETS_ACTION,
  PENDING_CREATE_WIDGET_ACTION,
  FAILURE_UPDATE_WIDGET_ACTION,
  PENDING_DELETE_WIDGET_ACTION,
  PENDING_GET_AVAILABLE_WIDGETS_ACTION,
  PENDING_GET_WIDGETS_ACTION,
  PENDING_UPDATE_WIDGET_ACTION,
  RESET_AVAILABLE_WIDGETS_STATE_ACTION,
  RESET_WIDGETS_STATE_ACTION,
  SUCCESS_DELETE_WIDGET_ACTION,
  SUCCESS_GET_AVAILABLE_WIDGETS_ACTION,
  SUCCESS_GET_WIDGETS_ACTION,
  SUCCESS_UPDATE_WIDGET_ACTION,
  SUCCESS_CREATE_WIDGET_ACTION
} from "../actionsTypes";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import { WidgetType } from "../../home/models/WidgetType";
import Widget from "../../home/models/Widget";

export const getSimConnectionsWidgets = ({
  filters = [],
  periodSelection,
  criteria
}) => async dispatch => {
  dispatch(getWidgetStart());

  try {
    const group = [WidgetType.SimVolumesPer];

    const widgets = group.map((widgetName: WidgetType) =>
      Widget.fromType({
        type: widgetName,
        name: widgetName,
        id: widgetName,
        periodSelection,
        filters,
        criteria
      })
    );

    dispatch(getWidgetsSucceeded({ widgets }));
  } catch (errors) {
    dispatch(getWidgetsFailure({ errors }));
    throw errors;
  }
};

export const getTerminalConnectionsWidgets = ({
  filters = [],
  periodSelection,
  criteria
}) => async dispatch => {
  dispatch(getWidgetStart());

  try {
    const group = [WidgetType.TerminalVolumesPer];

    const widgets = group.map((widgetName: WidgetType) =>
      Widget.fromType({
        type: widgetName,
        name: widgetName,
        id: widgetName,
        periodSelection,
        filters,
        criteria
      })
    );

    dispatch(getWidgetsSucceeded({ widgets }));
  } catch (errors) {
    dispatch(getWidgetsFailure({ errors }));
    throw errors;
  }
};

export const getReturningCustomersWidgets = ({
  filters = [],
  periodSelection,
  criteria
}) => async dispatch => {
  dispatch(getWidgetStart());

  try {
    const group = [
      WidgetType.CustomersCountPieChart,
      WidgetType.CustomersTransactionCountPieChart,
      WidgetType.CustomersAverageVisitsBadge,
      WidgetType.CustomersAverageBasketBarChart,
      WidgetType.CustomersTopByCount,
      WidgetType.CustomersTopByAmount
    ];

    const widgets = group.map((widgetName: WidgetType) =>
      Widget.fromType({
        type: widgetName,
        name: widgetName,
        id: widgetName,
        periodSelection,
        filters,
        criteria
      })
    );

    dispatch(getWidgetsSucceeded({ widgets }));
  } catch (errors) {
    dispatch(getWidgetsFailure({ errors }));
    throw errors;
  }
};

export const getComparaisonWidgets = ({
  filters = [],
  periodSelection,
  criteria
}) => async dispatch => {
  dispatch(getWidgetStart());

  try {
    const group = [
      WidgetType.ComparisonCount,
      WidgetType.ComparisonAmount,
      WidgetType.ComparisonAverageBasket
    ];

    const widgets = group.map((widgetName: WidgetType) =>
      Widget.fromType({
        type: widgetName,
        name: widgetName,
        id: widgetName,
        periodSelection,
        filters,
        criteria
      })
    );

    dispatch(getWidgetsSucceeded({ widgets }));
  } catch (errors) {
    dispatch(getWidgetsFailure({ errors }));
    throw errors;
  }
};

export const getSnapshotWidgets = ({
  filters = [],
  periodSelection
}) => async dispatch => {
  dispatch(getWidgetStart());

  try {
    const group = [
      WidgetType.TransactionNumber,
      WidgetType.Revenue,
      WidgetType.Cashback,
      WidgetType.AverageBasket,
      WidgetType.TransactionRevenueChart
    ];

    const widgets = group.map((widgetName: WidgetType) =>
      Widget.fromType({
        type: widgetName,
        name: widgetName,
        id: widgetName,
        periodSelection,
        filters
      })
    );

    dispatch(getWidgetsSucceeded({ widgets }));
  } catch (errors) {
    dispatch(getWidgetsFailure({ errors }));
    throw errors;
  }
};

export const getMobileWidgets = ({
  filters = [],
  periodSelection
}) => async dispatch => {
  dispatch(getWidgetStart());

  try {
    const group = [
      WidgetType.TransactionNumber,
      WidgetType.Revenue,
      WidgetType.TransactionRevenueChart,
      WidgetType.RefundAmount
    ];

    const widgets = group.map((widgetName: WidgetType) =>
      Widget.fromType({
        type: widgetName,
        name: widgetName,
        id: widgetName,
        periodSelection,
        filters
      })
    );

    dispatch(getWidgetsSucceeded({ widgets }));
  } catch (errors) {
    dispatch(getWidgetsFailure({ errors }));
    throw errors;
  }
};

export const getPaymentMethodsWidgets = ({
  filters = [],
  periodSelection
}) => async dispatch => {
  dispatch(getWidgetStart());

  try {
    const group = [
      WidgetType.CardsCount,
      WidgetType.CardsAmount,
      WidgetType.WalletsCount,
      WidgetType.WalletsAmount,
      WidgetType.PaymentMethodsCount,
      WidgetType.PaymentMethodsAmount,
      WidgetType.CardAverageBasket,
      WidgetType.WalletAverageBasket
    ];

    const widgets = group.map((widgetName: WidgetType) =>
      Widget.fromType({
        type: widgetName,
        name: widgetName,
        id: widgetName,
        periodSelection,
        filters
      })
    );

    dispatch(getWidgetsSucceeded({ widgets }));
  } catch (errors) {
    dispatch(getWidgetsFailure({ errors }));
    throw errors;
  }
};

export const getWidgets = () => async dispatch => {
  dispatch(getWidgetStart());

  return F.get("/me/widget").then(
    response => {
      const widgetsOrdered = response
        .sort((prev, next) => prev.order - next.order)
        .map((ob, index) => ({ ...ob, order: index }));
      dispatch(getWidgetsSucceeded({ widgets: widgetsOrdered }));
      return widgetsOrdered;
    },
    errors => {
      return dispatch(getWidgetsFailure({ errors }));
    }
  );
};

const getWidgetStart = () => ({
  type: PENDING_GET_WIDGETS_ACTION
});

const getWidgetsSucceeded = ({ widgets }) => ({
  type: SUCCESS_GET_WIDGETS_ACTION,
  payload: {
    widgets
  }
});

const getWidgetsFailure = errors => ({
  type: FAILURE_GET_WIDGETS_ACTION,
  payload: {
    errors
  }
});

export const resetWidgetsState = () => ({
  type: RESET_WIDGETS_STATE_ACTION
});

export const getAvailableWidgets = () => async dispatch => {
  dispatch(getAvailableWidgetstart());

  try {
    const data = await F.get("/me/availableWidgets");

    const widgets = data
      .map((widgetName: WidgetType) =>
        Widget.fromType({
          type: widgetName,
          name: ""
        })
      )
      .filter(i => i !== null);
    dispatch(getAvailableWidgetsSucceeded({ widgets }));
  } catch (errors) {
    dispatch(getAvailableWidgetsFailure({ errors }));
    throw errors;
  }
};

const getAvailableWidgetstart = () => ({
  type: PENDING_GET_AVAILABLE_WIDGETS_ACTION
});

const getAvailableWidgetsSucceeded = ({ widgets }) => ({
  type: SUCCESS_GET_AVAILABLE_WIDGETS_ACTION,
  payload: {
    widgets
  }
});

const getAvailableWidgetsFailure = errors => ({
  type: FAILURE_GET_AVAILABLE_WIDGETS_ACTION,
  payload: {
    errors
  }
});

export const resetAvailableWidgetsState = () => ({
  type: RESET_AVAILABLE_WIDGETS_STATE_ACTION
});

export const deleteWidget = ({ id }: any) => async dispatch => {
  dispatch(deletetWidgetStart());

  try {
    await F.delete(`/me/widget/${id}`);

    return dispatch(deleteWidgetsSucceeded({ id }));
  } catch (errors) {
    dispatch(deleteWidgetsFailure({ errors }));
    throw errors;
  }
};

const deletetWidgetStart = () => ({
  type: PENDING_DELETE_WIDGET_ACTION
});

const deleteWidgetsSucceeded = ({ id }: any) => ({
  type: SUCCESS_DELETE_WIDGET_ACTION,
  payload: {
    id
  }
});

const deleteWidgetsFailure = errors => ({
  type: FAILURE_DELETE_WIDGET_ACTION,
  payload: {
    errors
  }
});

const toConnect = (widget: any) => {
  const {
    id,
    criteria = null,
    filters = [],
    name,
    order,
    type,
    period = null,
    defaultPaletteId
  } = widget;

  return {
    id,
    criteria,
    filters,
    name,
    order,
    type,
    period,
    defaultPaletteId
  };
};

export const updateWidget = ({ widgets }: any) => async dispatch => {
  dispatch(updateWidgetStart());

  const bodyContent = widgets.map((widget: any) => ({
    ...toConnect(widget),
    id: widget.id
  }));

  try {
    let widgetsUpdated = await F.put(`/me/widget`, {
      body: bodyContent,
      contentType: ContentTypes.JSON
    });

    const widgetsOrdered = widgetsUpdated
      .sort((prev, next) => prev.order - next.order)
      .map((ob, index) => ({ ...ob, order: index }));

    return dispatch(updateWidgetsSucceeded({ widgets: widgetsOrdered }));
  } catch (errors) {
    dispatch(updateWidgetsFailure({ errors }));
    throw errors;
  }
};

const updateWidgetStart = () => ({
  type: PENDING_UPDATE_WIDGET_ACTION
});

const updateWidgetsSucceeded = ({ widgets }: any) => ({
  type: SUCCESS_UPDATE_WIDGET_ACTION,
  payload: {
    widgets
  }
});

const updateWidgetsFailure = errors => ({
  type: FAILURE_UPDATE_WIDGET_ACTION,
  payload: {
    errors
  }
});

const wrapWidget = (widget: Widget) => {
  return {
    name: widget.name,
    order: widget.order,
    type: widget.type,
    criteria: widget.criteria,
    period: widget.timePeriod ? widget.timePeriod.type : null,
    filters: widget.filters,
    defaultPaletteId: widget.defaultPaletteId
  };
};

export const createWidget = ({ widget }) => async dispatch => {
  dispatch(createWidgetStart());

  const url = new URL(`/me/widget`, PORTAL_URL);

  return F.post(url.pathname, {
    body: wrapWidget(widget),
    contentType: ContentTypes.JSON
  })
    .then(data => {
      dispatch(createWidgetsSucceeded());

      return data;
    })
    .catch(errors => {
      dispatch(createWidgetsFailure({ errors }));

      throw errors;
    });
};

const createWidgetStart = () => ({
  type: PENDING_CREATE_WIDGET_ACTION
});

const createWidgetsSucceeded = () => ({
  type: SUCCESS_CREATE_WIDGET_ACTION
});

const createWidgetsFailure = errors => ({
  type: FAILURE_CREATE_WIDGET_ACTION,
  payload: {
    errors
  }
});
