import React, { Component } from "react";
import _ from "lodash";

import TimeSelector from "../../components/TimeSelector";
import TimePeriods, { PeriodSelection } from "../models/TimePeriod";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";

interface Props {
  onChange: Function;
  searchContext: any;
  advancedSearchKey: any;
}

interface State {
  timePeriod: any;
}

const advancedSearchKey = AdvancedSearchConstants.TAX_AUDIT;

class TransactionTimeSelector extends Component<Props, State> {
  state = {
    timePeriod: null
  } as State;

  componentDidMount() {
    const { searchContext } = this.props;

    const { timePeriod = {} } = searchContext;

    return this.setState({
      timePeriod
    });
  }

  handleChange = ({ timePeriod }: any) => {
    const { onChange, searchContext } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
      sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
    } = searchContext;

    const newSearchContext = {
      key: advancedSearchKey,
      sort: sortDescription,
      filters,
      timePeriod
    };

    return this.setState(
      {
        timePeriod
      },
      () => {
        return onChange && onChange({ searchContext: newSearchContext });
      }
    );
  };

  render() {
    const { timePeriod } = this.state;
    const { searchContext } = this.props;

    if (timePeriod) {
      return (
        <TimeSelector
          timePeriods={_.pick(TimePeriods, [
            PeriodSelection.WEEK_TO_DATE,
            PeriodSelection.MONTH_TO_DATE,
            PeriodSelection.YEAR_TO_DATE,
            PeriodSelection.BETWEEN,
            PeriodSelection.LAST_WEEK,
            PeriodSelection.LAST_MONTH
          ])}
          {...this.props}
          onChange={this.handleChange}
          searchContext={searchContext}
          initialTimePeriod={timePeriod}
        />
      );
    }

    return null;
  }
}

export default TransactionTimeSelector;
