import F from "../../commons/HTTPFetcher";

import {
  PENDING_GET_CUSTOMERS_ACTION,
  SUCCESS_GET_CUSTOMERS_ACTION,
  FAILURE_GET_CUSTOMERS_ACTION
} from "../actionsTypes";

export const getCustomers = () => dispatch => {
  dispatch(getCustomersStart());

  return F.get("/customer").then(
    customers => dispatch(getCustomersSucceeded(customers)),
    errors => {
      dispatch(getCustomersFailure(errors));

      throw errors;
    }
  );
};

const getCustomersStart = () => ({
  type: PENDING_GET_CUSTOMERS_ACTION
});

const getCustomersSucceeded = customers => ({
  type: SUCCESS_GET_CUSTOMERS_ACTION,
  payload: {
    customers
  }
});

const getCustomersFailure = errors => ({
  type: FAILURE_GET_CUSTOMERS_ACTION,
  payload: {
    errors
  }
});
