import React, { Component, Fragment, useState } from "react";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import TimeSelector from "../TimeSelector/TimeSelector";
import ChartsGrid from "./ChartsGrid";

import DateFormatter from "../../formatters/DateFormatter";

import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";

import {
  resetWidgetsTrendState,
  resetWidgetsState,
  getMobileWidgets,
  resetWidgetsStatsState,
  setSearchContext
} from "../../redux/actions";
import {
  MatchPreviousPeriod,
  TimePeriod
} from "../../reporting/models/TimePeriod";
import TimePeriods from "../../reporting/models/TimePeriod";

const advancedSearchKey = AdvancedSearchConstants.HOME_KEY;

class ChartsGridWrapper extends Component<Props, State> {
  async componentDidMount() {
    const {
      getMobileWidgets,
      searchContext,
      resetWidgetsStatsState,
      resetWidgetsState
    } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
      timePeriod: periodSelection = {}
    } = searchContext;

    await resetWidgetsState();
    await resetWidgetsStatsState();
    getMobileWidgets({ filters, periodSelection });
  }

  handleChangePeriod = async timePeriod => {
    const {
      getMobileWidgets,
      resetWidgetsStatsState,
      resetWidgetsState,
      setSearchContext,
      searchContext: prevSearchContext
    } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {}
    } = prevSearchContext;

    const searchContext = { filters, timePeriod };

    setSearchContext({ context: searchContext });

    await resetWidgetsState();
    await resetWidgetsStatsState();
    getMobileWidgets({ filters, periodSelection: timePeriod });
  };

  render() {
    const { widgets, user } = this.props;

    return (
      <Fragment>
        <TimeSelector onChange={this.handleChangePeriod} />
        {widgets && !isEmpty(widgets) && (
          <ChartsGrid widgets={widgets} user={user} />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: currentUser,
      isCustomer,
      isMerchant,
      levelSettings: _levelSettings
    },
    widgets: { data = [], loading, error },
    theme: {
      color: {
        data: { color }
      }
    },
    services: { data: services = [] }
  } = state;

  const levelSettings = _levelSettings === null ? {} : _levelSettings;

  const {
    currency: {
      alpha3: currencyCodeAlpha3,
      symbol: currency,
      exponent: currencyDecimal
    } = {
      alpha3: "",
      symbol: "?",
      exponent: 0
    }
  } = levelSettings;

  const userCurrency = {
    currencyCodeAlpha3,
    currency,
    currencyDecimal
  };

  const {
    searchContext: {
      data: {
        filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] } = {},
        timePeriod = {}
      }
    }
  } = state;
  const {
    searchContext: { data: searchContext }
  } = state;

  const canSeeWidget = isCustomer || isMerchant;

  const {
    scope: {
      level: { type: userType, id: levelId }
    }
  } = currentUser;

  const widgets = data.map(widget => {
    const { name, id, type } = widget;

    const min = DateFormatter.getTimezonedDate(
      timePeriod.startTime,
      currentUser
    );
    const max = DateFormatter.getTimezonedDate(timePeriod.endTime, currentUser);

    const timePeriodType = MatchPreviousPeriod({ timePeriod });

    const secondTimePeriod = TimePeriods[timePeriodType];

    const prevMin = DateFormatter.getTimezonedDate(
      secondTimePeriod.startTime,
      currentUser
    );
    const prevMax = DateFormatter.getTimezonedDate(
      secondTimePeriod.endTime,
      currentUser
    );

    return {
      type,
      filters,
      name,
      id,
      period: timePeriod.type,
      userCurrency,
      timePeriod,
      secondTimePeriod,
      timestamps: {
        min,
        max,
        previous: {
          min: prevMin,
          max: prevMax
        }
      }
    };
  });

  const widgetsMobile = widgets.reduce((prevWidget, widget) => {
    return {
      ...prevWidget,
      [widget.id]: widget
    };
  }, {});

  return {
    services,
    color,
    user: currentUser,
    userType,
    levelId,
    canSeeWidget,
    widgets: widgetsMobile,
    loading,
    error,
    searchContext
  };
};

const mapDipatchToProps = dispatch => ({
  resetWidgetsState: () => dispatch(resetWidgetsState()),
  resetWidgetsStatsState: () => dispatch(resetWidgetsStatsState()),
  getMobileWidgets: ({ filters, periodSelection }) =>
    dispatch(getMobileWidgets({ filters, periodSelection })),
  resetWidgetsTrendState: () => dispatch(resetWidgetsTrendState()),
  setSearchContext: ({ context, pathname }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname
      })
    )
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDipatchToProps)
)(ChartsGridWrapper);
