import React, { Component } from "react";
import I18nSpan from "../i18n/components/I18nSpan";
import { capitalize } from "../commons/lang/StringUtils";

interface Props {
  objectName: any;
  onClickButton: Function;
  onClickStoreButton: Function;
  onClosePopin: Function;
  userLevel: any;
  hasStoreManagementPermission: boolean;
  hasMerchantUserManagement: boolean;
}

class CreationMerchantPopin extends Component<Props, {}> {
  render() {
    const {
      onClosePopin,
      objectName,
      onClickButton,
      onClickStoreButton,
      userLevel,
      hasStoreManagementPermission,
      hasMerchantUserManagement
    } = this.props;
    const capitalizedUserLevel = capitalize(userLevel);

    return (
      <div>
        <div className="popin">
          <div className="popinContent">
            <a onClick={onClosePopin} className="closeButton" />
            {hasStoreManagementPermission && hasMerchantUserManagement && (
              <div>
                <div className="popinTitle">
                  <I18nSpan msgKey="popin.creationUserOrStore" />
                </div>
                <I18nSpan
                  msgKey={"popin.message.createUserOrStore"}
                  args={[objectName]}
                />
              </div>
            )}
            {hasMerchantUserManagement && !hasStoreManagementPermission && (
              <div>
                <div className="popinTitle">
                  <I18nSpan msgKey="popin.creation" />
                </div>
                <I18nSpan
                  msgKey={`popin.message.create${capitalizedUserLevel}`}
                  args={[objectName]}
                />
              </div>
            )}
            {hasStoreManagementPermission && !hasMerchantUserManagement && (
              <div>
                <div className="popinTitle">
                  <I18nSpan msgKey="popin.creationStore" />
                </div>
                <I18nSpan
                  msgKey={"popin.message.createStore"}
                  args={[objectName]}
                />
              </div>
            )}

            <div className="button-list">
              {hasMerchantUserManagement && (
                <li>
                  <a onClick={onClickButton} className="btn btn-ingenico">
                    <I18nSpan msgKey="popin.button.create" />
                  </a>
                </li>
              )}
              {hasStoreManagementPermission && (
                <li>
                  <a onClick={onClickStoreButton} className="btn btn-ingenico">
                    <I18nSpan msgKey="popin.button.createStore" />
                  </a>
                </li>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreationMerchantPopin;
