import React, { Component } from "react";
import { connect } from "react-redux";

import constants from "../../connection/constants/ConnectionConstant";
import ListCustomerView from "../../customer/components/ListCustomerView";

import { getPalettes } from "../../redux/actions";

import MatchMobileContainer from "../../gridCharts/MatchMobileContainer";

import { HomeMobileViewWrapper } from "./HomeMobileView";
import HomeDesktopViewWrapper from "./HomeDesktopView";

interface Props {
  history: any;
  getWidgets: Function;
  deleteWidget: Function;
  updateWidget: Function;
  resetWidgetsState: Function;
  resetWidgetsStatsState: Function;
  getPalettes: Function;
  widgets: any;
  canSeeWidget: boolean;
  levelId: string;
  userType: string;
  loading: boolean;
}

interface State {
  color: string;
  level: string;
  user: any;
  services: Array<string>;
}

class HomeView extends Component<Props, State> {
  async componentDidMount() {
    const { canSeeWidget, getPalettes } = this.props;
    if (canSeeWidget) {
      getPalettes();
    }
  }

  render() {
    const { loading, userType, currentUser, logoContent } = this.props;

    if (userType === constants.INGENICO) {
      return (
        <div className="home data-table-wrapper">
          <ListCustomerView key="ListCustomerView" />
        </div>
      );
    }

    if (loading) {
      return null;
    }

    return (
      <div>
        <MatchMobileContainer
          renderMobile={() => (
            <HomeMobileViewWrapper
              user={currentUser}
              logoContent={logoContent}
            />
          )}
          renderDesktop={() => <HomeDesktopViewWrapper />}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: { user: currentUser },
    theme: {
      logo: { data: logoContent }
    }
  } = state;

  const {
    scope: {
      level: { type: userType }
    }
  } = currentUser;

  const { loading } = state;

  return {
    userType,
    loading,
    currentUser,
    logoContent
  };
};

const mapDispatchToProps = dispatch => ({
  getPalettes: () => dispatch(getPalettes())
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
