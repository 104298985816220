import React, { Component } from "react";
import _ from "lodash";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import FormTitle from "../../ingenicoForm/components/FormTitle";
import If from "../../components/if";
import TagInput from "../../ingenicoForm/components/TagInput";
import TagUtil from "../../tag/model/TagUtil";
import Validators from "../../ingenicoForm/validation/Validators";
import { requiredMinLength } from "../../merchant/validations/StoreValidations";

import constants from "../../connection/constants/ConnectionConstant";

import {
  createMerchant,
  getCountries,
  getMerchants,
  getTags,
  getTimezones,
  resetCountriesSate,
  getStore,
  updateStore,
  resetTagsState,
  addNotificationSuccess,
  addNotificationError
} from "../../redux/actions";

interface Props {
  getCountries: Function;
  resetCountriesSate: Function;
  getTags: Function;
  isMerchant: boolean;
  isCustomer: boolean;
  getMerchants: Function;
  getTimezones: Function;
  initForm: Function;
  getStore: Function;
  updateStore: Function;
  user: any;
  merchantId: any;
  storeId: string;
  form: any;
  resetTags: Function;
}

interface State {}

class EditStoreView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillUnmount() {
    const { resetTags, resetCountriesSate } = this.props;
    resetTags();
    resetCountriesSate();
  }

  componentDidMount() {
    const {
      getStore,
      getTags,
      isMerchant,
      isCustomer,
      getCountries,
      getMerchants,
      getTimezones,
      storeId: id
    } = this.props;

    if (isMerchant) {
      getTags();
    } else if (isCustomer) {
      getMerchants({
        filters: [],
        fields: ["merchant.id", "merchant.name"]
      });
    }

    getStore({ id });
    getCountries();
    getTimezones();
  }

  componentWillReceiveProps(nextProps) {
    const { form, errors } = nextProps;

    return this.setState({
      form,
      errors
    });
  }

  _updateStore = e => {
    e.preventDefault();

    const {
      updateStore,
      addNotificationSuccess,
      addNotificationError
    } = this.props;
    const { form: store } = this.state;

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });
      if (!hasErrors) {
        try {
          const selectedTags = TagUtil.selectedTagsToObject(store.selectedTags);
          await updateStore({ store: { ...store, selectedTags } });
          addNotificationSuccess("store.edit.success");
          this._goToList();
        } catch (error) {
          const { key: errorKey } = await error;
          const notificationErrorKey = `notification.store.error.${errorKey}`;

          addNotificationError(notificationErrorKey);
        }
      }
    });
  };

  _goToList = () => {
    this.props.history.push("/main/settings/store");
  };

  _onChangeHandler = (name, value) => {
    const newForm = this.state.form;
    newForm[name] = value === "" ? null : value;
    this.setState(newForm, this._checkError(name));
  };

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _checkError(name) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.props.form), {}, then);
  }

  _noop() {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });
    return Promise.all([requiredPromise]);
  }

  render() {
    const { isCustomer, t } = this.props;
    const { form } = this.state;

    const showTags = !_.isEmpty(this.props.options.tags);

    return (
      <div>
        <FormTitle titleKey="store.title" actionKey="store.edit.action" />

        <form className="ingenico-form form-horizontal edit-store-form">
          <BootstrapInput
            validation={requiredMinLength(t(`store.form.name.label`))}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("name")}
            name="name"
            errors={this.state.errors.name}
            required={true}
            descriptor={{
              type: "text",
              label: "store.form.name.label",
              placeholder: "store.form.name.placeholder"
            }}
            formValue={form.name}
          />
          <BootstrapInput
            validation={value =>
              Promise.resolve(Validators.requiredValidator(value))
            }
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("timezone")}
            name="timezone"
            errors={this.state.errors.timezone}
            required={true}
            descriptor={{
              type: "singleautocomplete",
              label: "store.form.timezone.label",
              options: this.props.options.timezone
            }}
            formValue={form.timezone}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("storeReference")}
            name="storeReference"
            errors={this.state.errors.storeReference}
            required={false}
            descriptor={{
              type: "text",
              label: "store.form.storeReference.label"
            }}
            formValue={form.storeReference}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("address")}
            name="address"
            errors={this.state.errors.address}
            required={false}
            descriptor={{ type: "text", label: "store.form.address.label" }}
            formValue={form.address}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("city")}
            name="city"
            errors={this.state.errors.city}
            required={false}
            descriptor={{ type: "text", label: "store.form.city.label" }}
            formValue={form.city}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("zipCode")}
            name="zipCode"
            errors={this.state.errors.zipCode}
            required={false}
            descriptor={{ type: "text", label: "store.form.zipCode.label" }}
            formValue={form.zipCode}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("state")}
            name="state"
            errors={this.state.errors.state}
            required={false}
            descriptor={{ type: "text", label: "store.form.state.label" }}
            formValue={form.state}
          />
          <BootstrapInput
            validation={this._noop}
            onChange={this._onChangeHandler}
            inputRef={this.setInputRef("country")}
            name="country"
            errors={this.state.errors.country}
            required={false}
            descriptor={{
              type: "singleautocomplete",
              label: "store.form.country.label",
              options: this.props.options.country
            }}
            formValue={form.country}
          />
          <If test={isCustomer}>
            <BootstrapInput
              validation={value =>
                Promise.resolve(Validators.requiredValidator(value))
              }
              onChange={this._onChangeHandler}
              inputRef={this.setInputRef("merchantId")}
              name="merchantId"
              errors={this.state.errors.merchantId}
              required={true}
              descriptor={{
                type: "multipleselectwindowed",
                label: "store.form.merchantId.label",
                options: this.props.options.merchantId,
                readOnly: isCustomer
              }}
              formValue={{
                label: this.props.options.merchantName,
                value: form.merchantId
              }}
            />
          </If>
          <BootstrapInput
            descriptor={{
              type: "jsonarea",
              label: "store.form.applicationSettings.label"
            }}
            inputRef={this.setInputRef("applicationSettings")}
            name="applicationSettings"
            onChange={this._onChangeHandler}
            formValue={form.applicationSettings}
            errors={this.state.errors.applicationSettings}
          />
          <If test={showTags}>
            <div name="selectedTags" className="form-group">
              <label className="tag-header-merchant control-label col-sm-3">
                Tags
              </label>
              <TagInput
                key={"selectedTags.Tag"}
                name="selectedTags"
                tags={this.props.options.tags}
                formValue={form.selectedTags}
                onChange={this._onChangeHandler}
              />
            </div>
          </If>

          <div className="pull-right">
            <button
              onClick={this._updateStore}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapMerchants = ({ merchants }) => {
  return _.map(merchants, merchant => {
    const {
      merchant: { name, id }
    } = merchant;

    return {
      id,
      name
    };
  });
};

const mapCountries = ({ countries }) => {
  return _.map(countries, country => {
    const { display: label, code: value } = country;

    return {
      label,
      value
    };
  });
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isCustomer, isMerchant },
    merchants: { data: prevMerchants },
    tags: { data: tags },
    countries: { data: prevCountries },
    timezones: { data: prevTimezones },
    store: { data: store }
  } = state;

  const {
    match: {
      params: { storeId }
    }
  } = ownProps;

  const {
    scope: {
      level: { type: levelType }
    }
  } = user;

  const merchants = isCustomer
    ? mapMerchants({ merchants: prevMerchants })
    : [];

  const merchantName = merchants.find(
    merchant => merchant.id === store.merchantId
  )?.name;

  const country = mapCountries({ countries: prevCountries });

  const options = {
    merchantId: merchants,
    merchantName,
    tags: isMerchant ? tags : [],
    timezone: _.map(prevTimezones, timezone => ({
      label: timezone,
      value: timezone
    })),
    country
  };

  const computeTags = ({ data }) => {
    if (isMerchant) {
      const userTags = TagUtil.getSelectedTags(user);
      _(data.selectedTags)
        .keys()
        .forEach(key => {
          if (!userTags.has(key)) {
            userTags.set(key, {
              name: key,
              value: data.selectedTags[key],
              fixed: false
            });
          }
        })
        .value();

      return {
        ...data,
        selectedTags: userTags
      };
    }
    return {
      ...data,
      selectedTags: new Map<string, any>()
    };
  };

  if (form) {
    if (!form.applicationSettings) {
      form.applicationSettings = {};
    } else {
      delete form.applicationSettings;
    }
  }

  const form = computeTags({ data: store });

  return {
    levelType,
    storeId,
    user,
    isCustomer,
    isMerchant,
    merchants,
    form,
    options,
    errors: {}
  };
};

const mapDipatchToProps = dispatch => ({
  getStore: ({ id }) => dispatch(getStore({ id })),
  updateStore: ({ store }) => dispatch(updateStore({ store })),
  createMerchant: merchant => dispatch(createMerchant(merchant)),
  getCountries: () => dispatch(getCountries()),
  resetCountriesSate: () => dispatch(resetCountriesSate()),
  getMerchants: ({ filters, fields, sort, tableCount }) =>
    dispatch(getMerchants({ filters, fields, sort, tableCount })),
  getTimezones: () => dispatch(getTimezones()),
  getTags: ({ tagType }) => dispatch(getTags({ tagType })),
  resetTags: ({ tagType }) => dispatch(resetTagsState({ tagType })),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { levelType } = stateProps;

  switch (levelType) {
    case constants.CUSTOMER: {
      const tagType = constants.CUSTOMER_NEW;

      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    case constants.MERCHANT: {
      const tagType = constants.MERCHANT;

      return {
        ...stateProps,
        ...dispatchProps,
        getTags: () => dispatchProps.getTags({ tagType }),
        resetTags: () => dispatchProps.resetTags({ tagType }),
        ...ownProps
      };
    }
    default:
      break;
  }
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDipatchToProps, mergeProps)
)(EditStoreView);
