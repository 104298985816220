import React from "react";
import * as moment from "moment";
import {
  CreateMultipleUsageAlert,
  MultipleUsageAlert
} from "../../model/MultipleUsageAlert";
import I18nSpan from "../../../../i18n/components/I18nSpan";
import PredefinedConstants from "../../PredefinedAlertConstant";

interface Props {
  onChange: (alert: CreateMultipleUsageAlert) => void;
}

interface State {
  maxMinutesIn48Hours?: number;
  minMinutes?: number;
  minStores?: number;
  maxStores?: number;
  form?: any;
}

export default class MultipleUsage extends React.Component<Props, State> {
  state = {
    maxMinutesIn48Hours: 2880,
    minMinutes: 1,
    minStores: 2,
    maxStores: Number.MAX_SAFE_INTEGER,
    form: {
      nbMinutes: PredefinedConstants.DEFAULT_DURATION,
      nbUsages: PredefinedConstants.DEFAULT_NB_USAGES
    }
  };

  private getNumberOrMinimum = (input: HTMLInputElement) => {
    if (input.value === "0" || input.value.trim() === "") {
      return input.name === "nbMinutes"
        ? this.state.minMinutes
        : input.name === "nbUsages"
        ? this.state.minStores
        : input.value;
    } else return input.value;
  };

  private onChange = (e: Event) => {
    const newForm = this.state.form;
    const input = e.target as HTMLInputElement;
    newForm[input.name] = this.getNumberOrMinimum(input);
    this.setState(
      {
        form: newForm
      },
      this.createAlertFromState
    );
  };

  private createAlertFromState = () => {
    const multipleUsageAlert = new CreateMultipleUsageAlert(
      moment.duration(parseInt(this.state.form.nbMinutes), "minutes"),
      parseInt(this.state.form.nbUsages)
    );
    this.props.onChange(multipleUsageAlert);
  };

  private onNumberInputClick = (e: Event) => {
    const input = e.target as HTMLInputElement;
    input.select();
  };

  render() {
    return (
      <div id="alert-multiple-usage" className="alert-item">
        <I18nSpan
          msgKey={`alert.predefined.${MultipleUsageAlert.I18N_KEY}.create-1`}
        />
        <input
          type="number"
          onChange={this.onChange}
          value={this.state.form.nbUsages}
          min={this.state.minStores}
          max={this.state.maxStores}
          onClick={this.onNumberInputClick}
          name="nbUsages"
        />
        <I18nSpan
          msgKey={`alert.predefined.${MultipleUsageAlert.I18N_KEY}.create-2`}
        />
        <input
          type="number"
          onChange={this.onChange}
          value={this.state.form.nbMinutes}
          min={this.state.minMinutes}
          max={this.state.maxMinutesIn48Hours}
          onClick={this.onNumberInputClick}
          name="nbMinutes"
        />
        <I18nSpan
          msgKey={`alert.predefined.${MultipleUsageAlert.I18N_KEY}.create-3`}
        />
      </div>
    );
  }
}
