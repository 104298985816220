import i18next from "i18next";

export default {
  makeDataZipObject({ list, key }) {
    return _.zipObject(list.map(item => [item, i18next.t(`${key}.${item}`)]));
  },
  mapCustomers({ customers }) {
    return _.map(customers, customer => {
      const { name: label, id: value } = customer;

      return {
        label,
        value
      };
    });
  },
  mapMerchants({ merchants }) {
    return _.map(merchants, merchant => {
      const {
        merchant: { name: label, id: value }
      } = merchant;

      return {
        label,
        value
      };
    });
  },
  mapStores({ stores }) {
    return _.map(stores, store => {
      const {
        store: { name: label, id: value }
      } = store;

      return {
        label,
        value
      };
    });
  },
  findPaletteById(paletteId, palettes) {
    return _.find(palettes, item => item.id === paletteId);
  }
};
