import React, { Component } from "react";
import { connect } from "react-redux";

import KeyIndicator from "./KeyIndicator";
import I18nAmount from "../../../i18n/components/I18nAmount";
import { WidgetConfig } from "../Widget";
import { getData } from "../../utils/DataProvider";

interface Data {
  amount?: number;
  currency?: string;
  decimal?: number;
  alpha3?: string;
}

interface Props {
  data: Data;
  loading?: boolean;
  error?: boolean;
  title: string;
  color?: string;
  config?: WidgetConfig;
}

class NetRevenuePerTerminal extends Component<Props, any> {
  componentDidMount() {
    const { config, getData } = this.props;
    const { user, desktopView } = config;
    if (desktopView) {
      return getData({ user, config });
    }
  }

  render() {
    const { data, title, color, loading, error, config } = this.props;

    const { amount } = data;
    const { userCurrency = {} } = config;
    const { currency, currencyDecimal, currencyCodeAlpha3 } = userCurrency;

    return (
      <KeyIndicator
        color={color}
        titleKey={title}
        loading={loading}
        error={error}
      >
        <I18nAmount
          number={amount}
          currency={currency}
          currencyDecimal={currencyDecimal}
          currencyCodeAlpha3={currencyCodeAlpha3}
        />
      </KeyIndicator>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getData: ({ config }) => dispatch(getData({ config }))
});

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const {
    widgets: { [id]: widget }
  } = state.widgetData;

  if (widget) {
    const {
      data: { stats = {} },
      loading,
      error
    } = widget;

    return {
      data: stats,
      loading,
      error
    };
  }

  return {
    data: {},
    loading: true
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetRevenuePerTerminal);
