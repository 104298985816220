import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Redirect, withRouter } from "react-router-dom";
import classNames from "classnames";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import { AuthorizationRedirect } from "./auth/AuthorizationRedirect";
import Header from "./Header";
import Sidebar from "../sidebar/components/Sidebar";
import ThemeView from "./ThemeView";

import I18nSpan from "../i18n/components/I18nSpan";
import NotificationContainer from "../notifications/components/NotificationContainer";
import { Notification } from "../notifications/model/Notification";
import MainBottomNavigation from "../components/BottomBar/BottomNavigation";

import Container from "@material-ui/core/Container";

import { changeLang } from "../i18n";

import styles from "./styles/styles.css";

import {
  addNotificationInfo,
  getColor,
  getLogo,
  getConfig,
  getCountries,
  getTimezones,
  getServices,
  getPalettes
} from "../redux/actions";
import MatchMobileContainer from "../gridCharts/MatchMobileContainer";

type MainSectionProps = {
  userLogin: string;
  isIngenico: boolean;
  permissions: Array<string>;
  levelSettings: any;
  children: any;
  isConfigLoaded: boolean;
  getColor: Function;
  getLogo: Function;
  getCountries: Function;
  getTimezones: Function;
  getServices: Function;
};

class MainSection extends Component<MainSectionProps, {}> {
  render() {
    const {
      userLogin,
      isIngenico,
      permissions,
      isConfigLoaded,
      children
    } = this.props;

    if (isConfigLoaded && (isIngenico || permissions)) {
      return (
        <div className="main-section">
          <Sidebar />
          <div className="section content">
            <Header userLogin={userLogin} />
            <div className={classNames("content-view", styles.virtualized)}>
              {children}
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export class MainView extends Component<MainViewProps, MainViewState> {
  getChildContext() {
    const { location } = this.props;

    return {
      location
    };
  }

  async componentDidMount() {
    const {
      user: currentUser,
      addNotificationInfo,
      getColor,
      getLogo,
      getConfig,
      getCountries,
      getTimezones,
      getServices,
      getPalettes
    } = this.props;

    changeLang(currentUser.language);

    if (currentUser.nbDaysToExpiration) {
      const isExpiringToday = currentUser.nbDaysToExpiration === 1;
      const expiringMessage = `resetPassword.expiring.message.${
        isExpiringToday ? "today" : "other"
      }`;
      const notification: Notification = {
        title: "",
        children: (
          <div>
            <I18nSpan
              msgKey={expiringMessage}
              args={[currentUser.nbDaysToExpiration]}
            />
            <Link to="/main/edit-password">
              <I18nSpan msgKey="resetPassword.expiring.link" />
            </Link>
          </div>
        ),
        autoDismiss: 0
      };

      addNotificationInfo(notification);
    }

    currentUser.login
      ? await Promise.all([
          getColor(),
          getLogo(),
          getConfig(),
          getCountries(),
          getTimezones(),
          getServices(),
          getPalettes()
        ])
      : null;
  }

  render() {
    const {
      location,
      children,
      classNameCurrentRoute,
      hasCode,
      code,
      cscUser,
      clientId,
      user,
      isIngenico,
      isConfigLoaded,
      permissions
    } = this.props;

    const style = {
      //See this file : https://github.com/igorprado/react-notification-system/blob/master/src/styles.js
      Containers: {
        // Override the notification item
        tr: {
          // Applied to every notification, regardless of the notification level
          top: "40px"
        }
      }
    };

    if (hasCode) {
      return (
        <Redirect
          to={{
            pathname: `/redirectOauth/${code}`,
            state: {
              from: location
            }
          }}
        />
      );
    }

    if (!user.login && !cscUser) {
      return (
        <AuthorizationRedirect
          clientId={clientId}
          locationPathname={location.pathname}
          locationSearch={location.search}
        />
      );
    }

    return (
      <MatchMobileContainer
        renderDesktop={() => (
          <div
            id={`page${classNameCurrentRoute}`}
            className={`page${classNameCurrentRoute}`}
          >
            <MainSection
              isIngenico={isIngenico}
              permissions={permissions}
              children={children}
              userLogin={user.login}
              isConfigLoaded={isConfigLoaded}
            />
            <ThemeView isIngenico={isIngenico} />
            <NotificationContainer />
          </div>
        )}
        renderMobile={() => (
          <Container
            disableGutters
            id={`page${classNameCurrentRoute}`}
            className={`page${classNameCurrentRoute}`}
          >
            {children}
            <ThemeView isIngenico={isIngenico} />
            <NotificationContainer />
            <MainBottomNavigation />
          </Container>
        )}
      />
    );
  }
}

MainView.childContextTypes = {
  location: PropTypes.object
};

const _strPathToDashName = str => {
  return str.toLowerCase().replace(/\/(.)/g, (match, group1) => `-${group1}`);
};

const _hasQueryParam = (searchParams, queryParam) => {
  return searchParams.has(queryParam);
};

export const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user,
      oauth: { clientId },
      isIngenico,
      isCustomer,
      isMerchant,
      levelSettings
    },
    config: { isLoaded: isConfigLoaded }
  } = state;
  const {
    location: { pathname, search }
  } = ownProps;
  const classNameCurrentRoute = _strPathToDashName(pathname);
  const searchParams = new URLSearchParams(search);
  const hasCode = _hasQueryParam(searchParams, "code");
  const code = searchParams.get("code");
  const oauthState = searchParams.get("state");
  const cscUser = user.cscUser;
  const { permissions } = user;

  return {
    classNameCurrentRoute,
    hasCode,
    code,
    oauthState,
    clientId,
    user,
    isIngenico,
    isCustomer,
    isMerchant,
    levelSettings,
    isConfigLoaded,
    cscUser,
    permissions
  };
};

const mapDispatchToProps = dispatch => ({
  addNotificationInfo: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationInfo(i18nKeyOrNotification, args)),
  getColor: () => dispatch(getColor()),
  getLogo: () => dispatch(getLogo()),
  getConfig: () => dispatch(getConfig()),
  getCountries: () => dispatch(getCountries()),
  getTimezones: () => dispatch(getTimezones()),
  getServices: () => dispatch(getServices()),
  getPalettes: () => dispatch(getPalettes())
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(MainView);
