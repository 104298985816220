import React from "react";

import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "../styles/styles.css";

export const LoginCopyright = () => {
  const copyrightYear = new Date().getFullYear();

  return (
    <div className={styles.mentions}>
      <I18nSpan msgKey="login.copyright" args={[copyrightYear]} />
    </div>
  );
};
