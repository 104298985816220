import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import get from "get-value";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import DataTable from "../../datatable/components/DataTable";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";
import Permissions from "../../user/constants/Permissions";
import Counter from "../../datatable/components/Counter";
import { Refresh } from "../../datatable/components/Refresh";

import { setUrlWithParams } from "../../searchContext/URLizer";

import {
  getMerchants,
  getScroll,
  updateStatus,
  getFields,
  getCountries,
  updateUserColumnsSettings,
  deleteUserColumnsSettings,
  resetMerchants,
  updateStatusEvent,
  setSearchContext
} from "../../redux/actions";

import styles from "../styles/styles.css";

interface Props {
  history: any;
  loading: boolean;
  getMerchants: Function;
  getMerchantsScroll: Function;
  updateStatus: Function;
  resetMerchants: Function;
  attributes: any;
  activationButtonStatus: Function;
  updateUserColumnsSettings: Function;
  deleteUserColumnsSettings: Function;
  getCountries: Function;
  getFields: Function;
  merchantsList: any;
  merchantCount: any;
  merchantScrollId: string;
  merchantFields: Array<any>;
  visibleAttributes: any;
  selectedMerchantIds: any;
  selection: any;
  isIngenico: any;
  color: any;
  isCustomer: any;
  hasRestrictionByMerchant: boolean;
  hasMerchantUserPermission: boolean;
  hasStoreManagementPermission: boolean;
}

interface State {
  merchantNames: Array<string>;
  showDeleteConfirmation: boolean;
}

const advancedSearchKey = AdvancedSearchConstants.MERCHANT_KEY;

class ListMerchantView extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { searchContext } = this.props;
    this.state = {
      showDeleteConfirmation: false
    };
    this.setUrlParams({ searchContext });
  }

  formatter = {
    "merchant.selectedTags": (selectedTags: any) =>
      _.values(selectedTags).join(", ")
  };

  async componentDidMount() {
    const { getFields, getCountries, searchContext } = this.props;
    const name = "merchants";

    await getFields({ name });
    await getCountries();

    await this._onRefresh({ searchContext });
  }

  setUrlParams = ({ searchContext }) => {
    const {
      location: { pathname }
    } = this.props;
    setUrlWithParams(searchContext, pathname);
  };

  componentWillUnmount() {
    const { resetMerchants } = this.props;

    resetMerchants();
  }

  _onRefresh = ({ searchContext }) => {
    const { tableCount, getMerchants } = this.props;

    const {
      filtersByAdvancedSearchKey: { [advancedSearchKey]: filters = [] },
      sortByAdvancedSearchKey: { [advancedSearchKey]: sort }
    } = searchContext;

    return getMerchants({ filters, fields: [], sort, tableCount });
  };

  _search = ({ searchContext }) => {
    const {
      getMerchants,
      tableCount,
      setSearchContext,
      location: { pathname }
    } = this.props;
    const { sort, filters } = searchContext;

    setSearchContext({ context: searchContext, pathname, updateUrl: true });
    return getMerchants({ filters, fields: [], sort, tableCount });
  };

  _updateColumns = (columns: any) => {
    const selectAll = Object.entries(columns).every(
      columns => columns[1] === true
    );
    const columnSetting = Object.entries(columns).reduce((acc, column) => {
      if (column[1]) acc.push(column[0]);

      return acc;
    }, []);

    const { updateUserColumnsSettings, deleteUserColumnsSettings } = this.props;
    const category = advancedSearchKey;

    if (selectAll) {
      return deleteUserColumnsSettings({ columnSetting, category });
    }

    return updateUserColumnsSettings({ columnSetting, category });
  };

  _loadMoreRows = () => {
    const { getMerchantsScroll, merchantScrollId } = this.props;

    return getMerchantsScroll({ scrollId: merchantScrollId });
  };

  render() {
    const {
      color,
      visibleAttributes,
      merchantsList,
      merchantCount,
      selectedMerchantIds,
      attributes,
      loading,
      sortDescription,
      searchContext,
      t
    } = this.props;

    const showOpenedSessionsAction = ({ ["merchant.id"]: merchantId }) => (
      <Link
        to={`/main/monitoring/open-sessions/${merchantId}`}
        key={merchantId}
        className="btn btn-circle"
      >
        <i
          className="glyphicon glyphicon-list-alt"
          aria-hidden="true"
          title={t("taxAudit.sessions.action")}
        />
      </Link>
    );

    const showSecureLogAction = ({ ["merchant.id"]: merchantId }) => (
      <Link
        to={`/main/monitoring/logs/${merchantId}`}
        key={merchantId}
        className="btn btn-circle"
      >
        <i
          className="glyphicon glyphicon-list"
          aria-hidden="true"
          title={t("taxAudit.log.action")}
        />
      </Link>
    );

    return (
      <div className="data-table-wrapper">
        <div className={styles["title-container"]}>
          <div className={styles["title-wrapper"]}>
            <FormTitle
              titleKey="taxAudit.title"
              actionKey="taxAudit.sessions.merchantSelect"
            />

            <div className={styles["countAndRefresh-container"]}>
              <Counter
                loading={loading}
                count={merchantCount}
                loadingKey="merchant.list.loading"
              />
              <Refresh onRefresh={() => this._onRefresh({ searchContext })} />
            </div>
          </div>
        </div>

        <DataTable
          data={merchantsList}
          notSortableFields={["merchant.selectedTags"]}
          attributes={attributes}
          visibleAttributes={visibleAttributes}
          formatter={this.formatter}
          i18nKey="merchant.list"
          idAttribute="merchant.id"
          hideSearchBox={true}
          toggleable={false}
          color={color}
          hasActions={true}
          actionsElement={[showOpenedSessionsAction, showSecureLogAction]}
          onRefresh={this._search}
          disableStatusValues={["Suspended"]}
          onUpdateColumns={this._updateColumns}
          version="new"
          selectableNew={false}
          selection={selectedMerchantIds}
          useSelection={false}
          loadMoreRowsAction={this._loadMoreRows}
          remoteRowCount={merchantCount}
          sortKey={advancedSearchKey}
          sortDescription={sortDescription}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const _getColumns = ({ type, fields }) => {
  const merchantFields = fields.reduce(
    (prevField: Array<string>, field: any) => {
      const { key, required, visible } = field;

      switch (type) {
        case "required": {
          if (visible || required) prevField.push(key);

          break;
        }
        default: {
          if (visible) prevField.push(key);

          break;
        }
      }

      return prevField;
    },
    []
  );

  return merchantFields;
};

const merchantsWithStatus = ({ state }) => {
  const {
    countries: { data: countries },
    merchants: { data: merchants, count, scrollId },
    genericFields: { data: genericFields }
  } = state;

  const fields = _getColumns({ type: "required", fields: genericFields });

  const merchantsList = merchants.map((merchant: any) => {
    const normalizedMerchant = fields.reduce(
      (prevField: Array<string>, field: any) => {
        if (field === "merchant.country") {
          const merchantCountry = countries.find(
            country => country.code === get(merchant, field)
          );

          return {
            ...prevField,
            [field]: merchantCountry ? merchantCountry.display : ""
          };
        }

        return {
          ...prevField,
          [field]: get(merchant, field)
        };
      },
      {}
    );

    return {
      ...normalizedMerchant
    };
  });

  return {
    merchantCount: count,
    scrollId,
    merchants: merchantsList
  };
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: { user, isCustomer, isIngenico, isRestrictedByIds },
    genericFields: { data: fields },
    merchants: { selection, loading },
    theme: {
      color: {
        data: { color }
      }
    },
    config: {
      data: { tableCount, eventGatewayURL }
    },
    searchContext: {
      data: {
        sortByAdvancedSearchKey: { [advancedSearchKey]: sortDescription } = {}
      }
    },
    searchContext: { data: searchContext }
  } = state;

  const {
    merchants: merchantsList,
    merchantCount,
    scrollId: merchantScrollId
  } = merchantsWithStatus({
    state
  });

  const { permissions } = user;

  const hasRestrictionByMerchant = (isCustomer && isRestrictedByIds) || false;
  const hasMerchantUserPermission =
    !isIngenico && permissions.includes(Permissions.MERCHANT_USER_MANAGEMENT);
  const hasStoreManagementPermission = permissions.includes(
    Permissions.STORE_MANAGEMENT
  );

  const selectedMerchantIds = _.keys(_.pick(selection, _.identity));
  const columnSetting = user ? user.columnSetting : false;

  const visibleAttributes = getVisibleAttributes({ columnSetting, fields });
  const attributes = _getColumns({ fields });

  return {
    loading,
    attributes,
    hasRestrictionByMerchant,
    color,
    merchantCount,
    merchantScrollId,
    merchantsList,
    hasMerchantUserPermission,
    hasStoreManagementPermission,
    selectedMerchantIds,
    selection,
    tableCount,
    isCustomer,
    visibleAttributes,
    sortDescription,
    eventGatewayURL,
    searchContext
  };
};

const getVisibleAttributes = ({ columnSetting, fields }) => {
  return columnSetting &&
    columnSetting.merchant &&
    columnSetting.merchant.length > 0
    ? columnSetting.merchant
    : _getColumns({ fields });
};

const mapDispatchToProps = dispatch => ({
  getMerchants: ({ filters, fields, sort, tableCount }) =>
    dispatch(getMerchants({ filters, fields, sort, tableCount })),
  getMerchantsScroll: ({ scrollId }) =>
    dispatch(getScroll({ type: "merchants", scrollId })),
  updateStatus: ({ merchantIds, newStatus }) =>
    dispatch(updateStatus({ merchantIds, newStatus })),
  getFields: ({ name }) => dispatch(getFields({ name })),
  getCountries: () => dispatch(getCountries()),
  updateUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(updateUserColumnsSettings({ columnSetting, category })),
  deleteUserColumnsSettings: ({ columnSetting, category }) =>
    dispatch(deleteUserColumnsSettings({ columnSetting, category })),
  resetMerchants: () => dispatch(resetMerchants()),
  updateMerchantsStatusView: ({ ids, status, sectionType }) =>
    dispatch(updateStatusEvent({ ids, status, sectionType })),
  setSearchContext: ({ context, pathname, updateUrl }) =>
    dispatch(
      setSearchContext({
        key: advancedSearchKey,
        context,
        pathname,
        updateUrl
      })
    )
});

export default compose(
  withTranslation(),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ListMerchantView);
