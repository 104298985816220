import React, { Component } from "react";
import { connect } from "react-redux";

import I18nSpan from "../i18n/components/I18nSpan";

interface Props {
  titleKey: string;
  icon: string;
  trailingIcon: any;
  color: any;
  children: any;
}

class HoverMenu extends Component<Props, {}> {
  render() {
    const { icon, trailingIcon, titleKey, children, color } = this.props;

    return (
      <div className="hover-menu">
        <ul className="hover-menu-list">
          <li className="menuItem glyphicon">
            <span
              className={`downItemMenu btn-ingenico glyphicon icon ${icon} ${trailingIcon}`}
            >
              <I18nSpan msgKey={titleKey} />
            </span>
            <div className="downItemMenu-list-wrapper">
              <ul>{children}</ul>
            </div>
          </li>
        </ul>

        <style>
          {`
                    .hover-menu-list .downItemMenu.icon:before,
                    .hover-menu-list .downItemMenu.icon:after  {
                      background-color: ${color};
                    }

                    .hover-menu-list .downItemMenu.icon.glyphicon-download:before {
                      background-color: white;
                      color: ${color};
                    }                 
                `}
        </style>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    theme: {
      color: {
        data: { color }
      }
    }
  } = state;

  return { color };
};

export default connect(mapStateToProps, null)(HoverMenu);
