import React from "react";
import classNames from "classnames";

import I18nSpan from "../../../i18n/components/I18nSpan";

import styles from "../../styles/styles.css";

export const AssignSecondFactorAction = ({ onAssign, msgKey }) => {
  return (
    <a
      onClick={onAssign}
      className={classNames(
        "btn",
        "multiple-action",
        styles["multiple-action"]
      )}
    >
      <span
        className={classNames("glyphicon", "glyphicon-plus", styles.glyphicon)}
      />
      <I18nSpan msgKey={msgKey} />
    </a>
  );
};
