import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import * as _ from "lodash";
import {
  PENDING_UPDATE_CUSTOMER_SETTINGS_ACTION,
  SUCCESS_UPDATE_CUSTOMER_SETTINGS_ACTION,
  FAILURE_UPDATE_CUSTOMER_SETTINGS_ACTION
} from "../actionsTypes";
import { getColorSucceeded } from "./theme";

const replace = function(key, value) {
  if (key === "services" && !_.isArray(value)) {
    return [];
  }
  return value;
};

const updateCustomerSettingsStart = () => ({
  type: PENDING_UPDATE_CUSTOMER_SETTINGS_ACTION
});

const updateCustomerSettingsSucceeded = settings => ({
  type: SUCCESS_UPDATE_CUSTOMER_SETTINGS_ACTION,
  payload: {
    settings
  }
});

const updateCustomerSettingsFailure = errors => ({
  type: FAILURE_UPDATE_CUSTOMER_SETTINGS_ACTION,
  payload: {
    errors
  }
});

export const updateSettings = (settings: any) => dispatch => {
  dispatch(updateCustomerSettingsStart());

  return F.put("/me/level-settings", {
    body: JSON.parse(JSON.stringify(settings, replace)),
    contentType: ContentTypes.JSON
  }).then(
    data => {
      const { color } = data;

      dispatch(getColorSucceeded({ color: { color } }));
      dispatch(updateCustomerSettingsSucceeded(data));
    },
    errors => {
      dispatch(updateCustomerSettingsFailure(errors));

      throw errors;
    }
  );
};
