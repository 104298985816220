import i18next from "i18next";

import {
  ErrorCode,
  ErrorResponse,
  ErrorMessage,
  ErrorKey
} from "../../notifications/model/ErrorResponse";

export default class Errors {
  private static printErrorCode(error: ErrorCode): string {
    const message = i18next.t(error.key);

    return `${error.code} : ${message}`;
  }

  private static printErrorMessage(error: ErrorMessage): string {
    return error.messages;
  }

  private static printErrorKey(key: ErrorKey, args: Array<String>): string {
    const defaultArgs = i18next.t("notification.error");

    return i18next.t(key, args ?? defaultArgs);
  }

  private static isErrorMessage(error: any): error is ErrorMessage {
    return error && error.messages;
  }

  private static isErrorCode(error: any): error is ErrorCode {
    return error && error.code && error.key;
  }

  private static isErrorKey(error: any): error is ErrorKey {
    return typeof error === "string";
  }

  static print(error: ErrorResponse, args: Array<String>): string {
    if (Errors.isErrorMessage(error)) {
      return Errors.printErrorMessage(error);
    } else if (Errors.isErrorCode(error)) {
      return Errors.printErrorCode(error);
    } else if (Errors.isErrorKey(error)) {
      return Errors.printErrorKey(error, args);
    }

    return Errors.printErrorKey("notification.error", args);
  }
}
