import React, { Component } from "react";
import { connect } from "react-redux";

import AdvancedSearch from "../../advancedSearch/components/AdvancedSearch";
import AdvancedSearchConstants from "../../advancedSearch/constants/AdvancedSearchConstants";

interface Props {
  tableCount: any;
  getSims: Function;
  getSimsFields: Function;
  fields: Array<any>;
  simFields: any;
  searchFilters: any;
}

interface State {
  tableCount: any;
}

class SimAdvancedSearch extends Component<Props, State> {
  render() {
    const {
      searchFilters,
      simFields = [],
      searchContext,
      onChange
    } = this.props;

    return (
      <div>
        <AdvancedSearch
          name={AdvancedSearchConstants.SIM_KEY}
          searchFilters={searchFilters}
          onChange={onChange}
          genericFields={simFields}
          searchContext={searchContext}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    auth: { isIngenico },
    genericFields: { data: fields },
    config: {
      data: { tableCount }
    }
  } = state;
  const searchFilters = fields
    ? fields.reduce((prevField: any, field: any) => {
        const { key, type, searchable } = field;

        if (searchable) {
          const tagsKey = /\b(selectedTags).([^.]+)/g.exec(key);
          prevField.push({
            i18nKey: `sim.list.header.${key}`,
            rawLabel: tagsKey ? tagsKey[2] : null,
            name: key,
            type
          });
        }

        return prevField;
      }, [])
    : [];

  return {
    searchFilters,
    simFields: fields,
    isIngenico,
    tableCount
  };
};

export default connect(mapStateToProps, null)(SimAdvancedSearch);
