import React, { Component } from "react";
import { withRouter } from "react-router";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import FormTitle from "../../ingenicoForm/components/FormTitle";
import OfferUtils from "../../offer/util/OfferUtils";
import TransactionUtils from "../../reporting/transactions/Utils";
import TransactionConstants from "../../reporting/constants/TransactionConstants";
import UserUtil from "../UserUtil";
import TagUtil from "../../tag/model/TagUtil";
import UserValidations from "../validations/UserValidations";
import Validators from "../../ingenicoForm/validation/Validators";
import BootstrapInput from "../../ingenicoForm/components/BootstrapInput";
import I18nSpan from "../../i18n/components/I18nSpan";
import RestrictedByContainer from "./RestrictedByContainer";
import ConnectionConstant from "../../connection/constants/ConnectionConstant";
import { userErrorNotification } from "../validations/UserValidations";
import SelectFactorPopin from "../../components/SelectFactorPopin";

import {
  createUser,
  getUserAccessibleScope,
  getCustomer,
  getMerchant,
  getFields,
  getMerchants,
  getStores,
  getTags,
  resetUserState,
  addNotificationSuccess,
  addNotificationError,
  getPermissionsForm
} from "../../redux/actions";

class CreateUserView extends Component<Props, State> {
  state = {
    form: {},
    errors: {}
  };

  inputRefs = {};

  setInputRef = name => element => {
    this.inputRefs[name] = element;
  };

  componentWillReceiveProps(nextProps) {
    const { form, errors } = nextProps;

    return this.setState({
      form,
      errors
    });
  }

  componentDidMount() {
    const { initUser } = this.props;

    initUser();
  }

  componentWillUnmount() {
    const { resetUserState } = this.props;

    resetUserState();
  }

  _showSelectFactorPopin = userId => {
    this.setState({
      showSelectFactorPopin: true,
      userId
    });
  };

  _closeSelectFactorPopin = () => {
    this._goToList();
  };

  _saveUser = e => {
    e.preventDefault();

    const {
      createUser,
      isMerchant,
      urlLevel,
      entityId,
      addNotificationSuccess,
      addNotificationError,
      authenticatedUserLevel,
      defaultPaletteId
    } = this.props;

    if (this.state.form.restrictedByTransactionField == "") {
      this.state.errors.restrictedByTransactionField = {
        code: "form.error.required"
      };
    }

    this._checkErrors(async () => {
      const hasErrors = _.any(this.state.form, (value, name) => {
        const error = this.state.errors[name];
        return !_.isEmpty(error);
      });

      if (!hasErrors) {
        const { form } = this.state;
        const userLevel = urlLevel
          ? { type: urlLevel, id: entityId }
          : authenticatedUserLevel;
        const newUser = UserUtil.buildFromForm(form, userLevel);

        if (isMerchant) {
          const { applicationSettings } = form;

          Object.assign(newUser, {
            applicationSettings
          });
        }

        const {
          scope: { access }
        } = newUser;
        delete newUser.scope;
        let user = {
          ...newUser,
          access,
          defaultPaletteId
        };

        try {
          const { login: userId } = await createUser({ user });

          addNotificationSuccess("user.create.success");
          return this._showSelectFactorPopin(userId);
        } catch (error) {
          userErrorNotification({ error, addNotificationError, form: newUser });

          throw new Error(`CreateUserView._saveUser() error: ${error}`);
        }
      }
    });
  };

  _goToList = () => {
    const { isIngenico, history, urlLevel } = this.props;

    return history.push(
      `/main/${isIngenico ? "" : "settings/"}user${
        urlLevel ? `/${urlLevel}` : ""
      }`
    );
  };

  _checkError(name) {
    return then => {
      this._waitForValidation([name], this.state.errors, then);
    };
  }

  _checkErrors(then) {
    this._waitForValidation(_.keys(this.state.form), {}, then);
  }

  _waitForValidation(names: Array<string>, newErrors: Array<String>, then) {
    if (_.isEmpty(names)) {
      this.setState({ errors: newErrors }, then);
    } else {
      const name: string = _.first(names);
      const value = _.get(this.state.form, name);
      const refsValue = _.get(this.inputRefs, name);

      const errors = refsValue
        ? [refsValue.props.validation(value)]
        : [this._noop()];
      Promise.all(errors).then(errors => {
        newErrors[name] = _.chain(errors)
          .flatten()
          .compact()
          .value();
        this._waitForValidation(_.tail(names), newErrors, then);
      });
    }
  }

  _noop() {
    const requiredPromise = new Promise(resolve => {
      resolve(null);
    });

    return Promise.all([requiredPromise]);
  }

  _onChangeHandler = (name, value) => {
    return this.setState(state => {
      return {
        form: {
          ...state.form,
          [name]: value
        }
      };
    }, this._checkError(name));
  };

  _uniqueWithoutSpaceLogin = value => {
    const { t } = this.props;
    const loginTranslation = t("user.form.login.label");

    return Promise.all([
      UserValidations.required(value),
      UserValidations.plainLogin(value),
      UserValidations.minLength(loginTranslation)(value),
      UserValidations.withoutSpecialChars({
        field: loginTranslation,
        value
      })
    ]);
  };

  _emailStyle = value => {
    return Promise.all([
      UserValidations.required(value),
      Validators.emailValidator(value)
    ]);
  };

  render() {
    const {
      form: { name: formName },
      options,
      readOnly,
      isCustomer,
      isMerchant,
      fullMode,
      urlLevel,
      authenticatedUserLevel
    } = this.props;
    const { form, errors, showSelectFactorPopin, userId } = this.state;

    const LevelName = ({ name, level }) => {
      if (level) {
        return (
          <BootstrapInput
            formValue={name}
            descriptor={{
              type: "text",
              label: `user.form.${level}Name.label`,
              readOnly: true
            }}
            name={"levelName"}
            onChange={() => undefined}
          />
        );
      }

      return null;
    };

    const ApplicationSettings = ({ fullMode, isMerchant }) => {
      if (fullMode && isMerchant) {
        return (
          <BootstrapInput
            descriptor={{
              type: "jsonarea",
              label: "user.form.applicationSettings.label"
            }}
            name="applicationSettings"
            onChange={this._onChangeHandler}
            formValue={form.applicationSettings}
            errors={errors.applicationSettings}
          />
        );
      }

      return null;
    };

    return (
      <div>
        <FormTitle titleKey="user.title" actionKey="user.create.action" />
        {showSelectFactorPopin && (
          <SelectFactorPopin
            onClosePopin={this._closeSelectFactorPopin}
            userId={userId}
            required={true}
          />
        )}
        <form className="ingenico-form form-horizontal users-form">
          <LevelName name={formName} level={urlLevel} />
          <BootstrapInput
            descriptor={{
              id: "loginUser",
              type: "text",
              label: "user.form.login.label",
              placeholder: "user.form.login.placeholder"
            }}
            inputRef={this.setInputRef("login")}
            name="login"
            onChange={this._onChangeHandler}
            required={true}
            formValue={form.login}
            validation={this._uniqueWithoutSpaceLogin}
            errors={errors.login}
          />
          <BootstrapInput
            descriptor={{
              id: "emailUser",
              type: "email",
              label: "user.form.email.label"
            }}
            inputRef={this.setInputRef("email")}
            name="email"
            onChange={this._onChangeHandler}
            required={true}
            formValue={form.email}
            validation={this._emailStyle}
            errors={errors.email}
          />
          <BootstrapInput
            descriptor={{
              id: "permissionsUser",
              className: "permissions mtn",
              type: "multipleselect",
              label: "user.form.permissions.label",
              options: options.permissions
            }}
            inputRef={this.setInputRef("permissions")}
            name="permissions"
            onChange={this._onChangeHandler}
            formValue={form.permissions}
            errors={errors.permissions}
          />
          {(isCustomer || urlLevel === ConnectionConstant.CUSTOMER_NEW) && (
            <BootstrapInput
              descriptor={{
                id: "singleSession",
                className: "mtn custom-control-input",
                type: "checkbox",
                label: "user.form.singleSession"
              }}
              inputRef={this.setInputRef("singleSession")}
              name="singleSession"
              onChange={this._onChangeHandler}
              formValue={form.singleSession}
              errors={errors.singleSession}
            />
          )}

          <RestrictedByContainer
            fullMode={fullMode}
            form={form}
            options={options}
            readOnly={readOnly}
            errors={errors}
            onChangeHandler={this._onChangeHandler}
            levelType={authenticatedUserLevel.type}
          />

          <ApplicationSettings fullMode={fullMode} isMerchant={isMerchant} />

          <div className="pull-right">
            <button
              onClick={this._saveUser}
              className="btn btn-ingenico save-button"
            >
              <I18nSpan msgKey={"button.label.ok"} />
            </button>
            <button
              onClick={this._goToList}
              className="btn btn-ingenico btn-ingenico-alert exit-button"
            >
              <I18nSpan msgKey="button.label.exit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const buildPermissionsOptions = permissions => {
  const permissionsOptions = permissions.map(UserUtil.toMultipleSelectFormat);

  return permissionsOptions;
};

const buildSimpleForm = ({ state, level }) => {
  const {
    merchant: { data: merchant },
    customer: { data: customer },
    permissionsForm: { data: permissionsForm }
  } = state;
  const currentUser = {
    customer,
    merchant
  };
  const permissions = buildPermissionsOptions(permissionsForm);

  const formModel = {
    login: null,
    email: null,
    name: currentUser[level] ? currentUser[level].name : "",
    permissions: UserUtil.toSelectOption(permissionsForm),
    restrictedByServices: false,
    restrictedByTags: new Map(),
    tag_or_scope: "full"
  };

  if (level === ConnectionConstant.CUSTOMER_NEW) {
    formModel.singleSession = false;
  }

  return {
    form: formModel,
    options: {
      permissions,
      restrictedByIds: []
    },
    readOnly: {
      restrictedByServices: false,
      restrictedByTags: false
    }
  };
};

const _buildFieldOptions = ({ state, getMessage }) => {
  const {
    auth: { isCustomer },
    genericFields: { data: transactionFieldGroups = [] }
  } = state;

  if (isCustomer) {
    const transactionFieldsKeys = TransactionUtils.extractFieldKeys({
      transactionFieldGroups,
      type: "visibleAndExtras"
    });

    return TransactionUtils.i18nFields(transactionFieldsKeys, field =>
      getMessage(`${TransactionConstants.I18N_PREFIX}.${field}.label`, field)
    );
  } else {
    return [];
  }
};

const filterAccessibleScopes = ({ accessibleScope, allScope }) => {
  const access = accessibleScope || { full: true };
  const filteredAccessibleScopes = _.chain(allScope)
    .filter(item => access.full || _.includes(access.restrictedByIds, item.id))
    .map((item: any) => ({ id: item.id, name: item.name }))
    .value();

  return filteredAccessibleScopes;
};

const buildCompleteForm = ({ state, getMessage }) => {
  const {
    auth: { user: authenticatedUser, isCustomer, isMerchant },
    user: { accessibleScope },
    merchants: { data: merchants },
    stores: { data: stores },
    tags: { data: tags },
    services: { data: services = [] }
  } = state;
  const currentUser = {
    permissions: authenticatedUser.permissions,
    services: authenticatedUser.services,
    scope: authenticatedUser.scope
  };

  let allScope = [];
  let tagOptions = null;
  const applicationSettings = {};

  if (isCustomer) {
    allScope = merchants;
    allScope.map(item => {
      item = Object.assign(item, {
        id: item.merchant.id,
        name: item.merchant.name
      });
    });
    tagOptions = tags;
  } else if (isMerchant) {
    allScope = stores;
    allScope.map(item => {
      item = Object.assign(item, {
        id: item.store.id,
        name: item.store.name
      });
    });
    tagOptions = tags;
    const defaultApplicationSettings = { mPos: {} };
    Object.assign(applicationSettings, {
      applicationSettings: defaultApplicationSettings
    });
  }

  const accessibleScopes = filterAccessibleScopes({
    accessibleScope,
    allScope
  });
  const fieldOptions = _buildFieldOptions({ state, getMessage });

  //Init Permissions
  const { permissions } = currentUser;
  const permissionsOptions = permissions.map(UserUtil.toMultipleSelectFormat);

  //Init Services
  const serviceSetOptions = OfferUtils.servicesToFormOptions(services);

  const formModel: any = Object.assign(
    UserUtil.buildToForm(currentUser, accessibleScopes),
    applicationSettings
  );

  let restrictedByServicesReadOnly = false;
  if (authenticatedUser && authenticatedUser.services) {
    restrictedByServicesReadOnly = true;
    formModel.restrictedByServices = true;
  }

  formModel.restrictedByTags = TagUtil.getSelectedTags(authenticatedUser);

  const restrictedByIdsReadOnly =
    formModel.restrictedByIds && formModel.restrictedByIds.length > 0;
  const restrictedByTagsReadOnly = formModel.restrictedByTags.size > 0;
  const restrictedByTransactionFieldReadOnly =
    "restrictedByTransactionField" in formModel;

  if (isCustomer) {
    formModel.singleSession = false;
  }

  return {
    form: formModel,
    options: {
      permissions: permissionsOptions,
      serviceSet: serviceSetOptions,
      restrictedByIds: accessibleScopes,
      tags: tagOptions,
      fields: fieldOptions
    },
    readOnly: {
      restrictedByServices: restrictedByServicesReadOnly,
      restrictedByIds: restrictedByIdsReadOnly,
      restrictedByTags: restrictedByTagsReadOnly,
      restrictedByTransactionField: restrictedByTransactionFieldReadOnly
    }
  };
};

export const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      user: {
        scope: { level: authenticatedUserLevel },
        defaultPaletteId
      },
      isIngenico,
      isCustomer,
      isMerchant
    }
  } = state;
  const {
    t: getMessage,
    match: {
      params: { level: urlLevel, entityId }
    }
  } = ownProps;
  const fullMode = urlLevel ? false : true;

  const { form, options, readOnly } = urlLevel
    ? buildSimpleForm({ state, level: urlLevel })
    : buildCompleteForm({ state, getMessage });

  return {
    form,
    options,
    readOnly,
    errors: {},
    isIngenico,
    isCustomer,
    isMerchant,
    fullMode,
    urlLevel,
    entityId,
    authenticatedUserLevel,
    defaultPaletteId
  };
};

const mapDispatchToProps = dispatch => ({
  createUser: ({ user, level, entityId }) =>
    dispatch(createUser({ user, level, entityId })),
  getUserAccessibleScope: () => dispatch(getUserAccessibleScope()),
  getCustomer: id => dispatch(getCustomer(id)),
  getMerchant: id => dispatch(getMerchant(id)),
  getFields: ({ name, version }) => dispatch(getFields({ name, version })),
  getMerchants: ({ filters, fields }) =>
    dispatch(getMerchants({ filters, fields })),
  getStores: ({ filters, fields }) => dispatch(getStores({ filters, fields })),
  getTags: ({ tagType }) => dispatch(getTags({ tagType })),
  resetUserState: () => dispatch(resetUserState()),
  addNotificationSuccess: (i18nKeyOrNotification, args) =>
    dispatch(addNotificationSuccess(i18nKeyOrNotification, args)),
  addNotificationError: (error, args) =>
    dispatch(addNotificationError(error, args)),
  getPermissionsForm: ({ restrictionLevel }) =>
    dispatch(getPermissionsForm({ restrictionLevel }))
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { isCustomer, isMerchant, urlLevel, entityId } = stateProps;
  const initUser = () => {
    if (urlLevel) {
      switch (urlLevel) {
        case ConnectionConstant.CUSTOMER_NEW: {
          dispatchProps.getPermissionsForm({
            restrictionLevel: ConnectionConstant.CUSTOMER
          });
          dispatchProps.getCustomer(entityId);

          break;
        }
        case ConnectionConstant.MERCHANT: {
          dispatchProps.getPermissionsForm({
            restrictionLevel: ConnectionConstant.MERCHANT
          });
          dispatchProps.getMerchant(entityId);

          break;
        }
        default: {
          break;
        }
      }
    } else {
      const filters = [];

      if (isCustomer) {
        const tagType = ConnectionConstant.CUSTOMER_NEW;
        Promise.all([
          dispatchProps.getMerchants({
            filters,
            fields: ["merchant.id", "merchant.name"],
            sort: {
              field: "merchant.name.raw",
              order: "ASC"
            }
          }),
          dispatchProps.getTags({ tagType }),
          dispatchProps.getFields({ name: "transaction", version: "v2" })
        ]);
      } else if (isMerchant) {
        const tagType = ConnectionConstant.MERCHANT;
        Promise.all([
          dispatchProps.getStores({
            filters,
            fields: ["store.id", "store.name"],
            sort: {
              field: "store.name.raw",
              order: "ASC"
            }
          }),
          dispatchProps.getTags({ tagType })
        ]);
      }

      dispatchProps.getUserAccessibleScope();
    }
  };

  if (urlLevel) {
    return {
      ...stateProps,
      ...dispatchProps,
      createUser: ({ user }) =>
        dispatchProps.createUser({ user, level: urlLevel, entityId }),
      ...ownProps,
      initUser
    };
  } else {
    if (isCustomer) {
      return {
        ...stateProps,
        ...dispatchProps,
        createUser: ({ user }) =>
          dispatchProps.createUser({
            user,
            level: ConnectionConstant.CUSTOMER_NEW,
            entityId
          }),
        ...ownProps,
        initUser
      };
    } else if (isMerchant) {
      return {
        ...stateProps,
        ...dispatchProps,
        createUser: ({ user }) =>
          dispatchProps.createUser({
            user,
            level: ConnectionConstant.MERCHANT,
            entityId
          }),
        ...ownProps,
        initUser
      };
    }
  }

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    initUser
  };
};

export const CreateUserViewWithRouter = withRouter(CreateUserView);

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(CreateUserViewWithRouter);
