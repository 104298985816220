import React from "react";

import styles from "../styles/styles.mobile.css";

export default function ReCaptcha() {
  return (
    <div className={styles["grecaptcha-badge"]}>
      <span>This site is protected by reCAPTCHA</span>
      <span>
        and the Google{" "}
        <a
          className={styles["grecaptcha-link"]}
          href="https://policies.google.com/privacy"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          className={styles["grecaptcha-link"]}
          href="https://policies.google.com/terms"
        >
          Terms of Service
        </a>{" "}
        apply.
      </span>
    </div>
  );
}
