import F from "../../commons/HTTPFetcher";

import {
  FAILURE_CREATE_SIM_ACTION,
  PENDING_CREATE_SIM_ACTION,
  SUCCESS_CREATE_SIM_ACTION,
  RESET_SIM_STATE_ACTION,
  PENDING_GET_SIM_ACTION,
  SUCCESS_GET_SIM_ACTION,
  FAILURE_GET_SIM_ACTION,
  FAILURE_UPDATE_SIM_ACTION,
  PENDING_UPDATE_SIM_ACTION,
  SUCCESS_UPDATE_SIM_ACTION,
  PENDING_LOAD_PROVIDERS_ACTION,
  SUCCESS_LOAD_PROVIDERS_ACTION,
  FAILURE_LOAD_PROVIDERS_ACTION,
  PENDING_LOAD_CONTRACTS_ACTION,
  SUCCESS_LOAD_CONTRACTS_ACTION,
  FAILURE_LOAD_CONTRACTS_ACTION,
  PENDING_LOAD_OFFER_IDS_ACTION,
  SUCCESS_LOAD_OFFER_IDS_ACTION,
  FAILURE_LOAD_OFFER_IDS_ACTION,
  PENDING_IMPORT_SIERRA_SIM_ACTION,
  SUCCESS_IMPORT_SIERRA_SIM_ACTION,
  FAILURE_IMPORT_SIERRA_SIM_ACTION,
  PENDING_ASSIGN_SIM_ACTION,
  SUCCESS_ASSIGN_SIM_ACTION,
  FAILURE_ASSIGN_SIM_ACTION
} from "../actionsTypes";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import _ from "lodash";
import { Provider } from "../../sim/model/Provider";

export const createSim = ({ sim }) => async dispatch => {
  dispatch(createSimStart());

  return F.post("/sim", {
    body: sim,
    contentType: ContentTypes.JSON
  }).then(
    posCreated => {
      dispatch(createSimSucceeded());
      return posCreated;
    },
    errors => {
        dispatch(createSimFailure( errors ));

      throw errors;
    }
  );
};

const createSimStart = () => ({
  type: PENDING_CREATE_SIM_ACTION
});

const createSimSucceeded = () => ({
  type: SUCCESS_CREATE_SIM_ACTION
});

const createSimFailure = errors => ({
  type: FAILURE_CREATE_SIM_ACTION,
  payload: {
    errors
  }
});

export const assignSim = ({ activation, provider }) => async dispatch => {
  dispatch(assignSimStart());

  const url =
  provider === Provider.Sierra
    ? "/sim/sierra/activate"
    : "/sim/radius/activate";



  return F.post(url, {
    contentType: ContentTypes.JSON,
  body: activation
  }).then(
    sim => {
      dispatch(assignSimSucceeded());
      return sim;
    },
    errors => {
        dispatch(assignSimFailure( errors ));

      throw errors;
    }
  );
};

const assignSimStart = () => ({
  type: PENDING_ASSIGN_SIM_ACTION
});

const assignSimSucceeded = () => ({
  type: SUCCESS_ASSIGN_SIM_ACTION
});

const assignSimFailure = errors => ({
  type: FAILURE_ASSIGN_SIM_ACTION,
  payload: {
    errors
  }
});

export const importSierraSim = ({ sim }) => async dispatch => {
  dispatch(importSierraSimStart());

  const { orderId } = sim
  return F.post(`/sim/sierra/import?orderId=${orderId}`, undefined).then(
    sims => {
      dispatch(importSierraSimSucceeded());
      return sims;
    },
    errors => {
        dispatch(importSierraSimFailure( errors ));

      throw errors;
    }
  );
};

const importSierraSimStart = () => ({
  type: PENDING_IMPORT_SIERRA_SIM_ACTION
});

const importSierraSimSucceeded = () => ({
  type: SUCCESS_IMPORT_SIERRA_SIM_ACTION
});

const importSierraSimFailure = errors => ({
  type: FAILURE_IMPORT_SIERRA_SIM_ACTION,
  payload: {
    errors
  }
});


export const getSim = ({ id }) => async dispatch => {
  dispatch(getSimStart());

  try {
    const sim = await F.get(`/sim/${id}`);

    return dispatch(
      getSimSucceeded(sim)
    );
  } catch (errors) {
    dispatch(getSimFailure({ errors }));

    throw errors;
  }
};

const getSimStart = () => ({
  type: PENDING_GET_SIM_ACTION
});

const getSimSucceeded = sim => ({
  type: SUCCESS_GET_SIM_ACTION,
  payload: {
    sim
  }
});

const getSimFailure = errors => ({
  type: FAILURE_GET_SIM_ACTION,
  payload: {
    errors
  }
});

export const loadProviders = ({ creatable }) => async dispatch => {
    dispatch(loadProvidersStart());
  
    try {
      const providers = await F.get(`/sim/providers?creatable=${creatable}`);
  
      return dispatch(
        loadProvidersSucceeded(providers)
      );
    } catch (errors) {
      dispatch(loadProvidersFailure({ errors }));
  
      throw errors;
    }
  };
  
  const loadProvidersStart = () => ({
    type: PENDING_LOAD_PROVIDERS_ACTION
  });
  
  const loadProvidersSucceeded = providers => ({
    type: SUCCESS_LOAD_PROVIDERS_ACTION,
    payload: {
        providers
    }
  });
  
  const loadProvidersFailure = errors => ({
    type: FAILURE_LOAD_PROVIDERS_ACTION,
    payload: {
      errors
    }
  });

  export const loadContracts = () => async dispatch => {
    dispatch(loadContractsStart());
  
    try {
      const contracts = await F.get("/sim/contracts");
  
      return dispatch(
        loadContractsSucceeded(contracts)
      );
    } catch (errors) {
      dispatch(loadContractsFailure({ errors }));
  
      throw errors;
    }
  };
  
  const loadContractsStart = () => ({
    type: PENDING_LOAD_CONTRACTS_ACTION
  });
  
  const loadContractsSucceeded = contracts => ({
    type: SUCCESS_LOAD_CONTRACTS_ACTION,
    payload: {
        contracts
    }
  });
  
  const loadContractsFailure = errors => ({
    type: FAILURE_LOAD_CONTRACTS_ACTION,
    payload: {
      errors
    }
  });


  export const loadOfferIds = () => async dispatch => {
    dispatch(loadOfferIdsStart());
  
    try {
      const offers = await F.get("/sim/offers");
  
      return dispatch(loadOfferIdsSucceeded(offers));
    } catch (errors) {
      dispatch(loadOfferIdsFailure({ errors }));
  
      throw errors;
    }
  };
  
  const loadOfferIdsStart = () => ({
    type: PENDING_LOAD_OFFER_IDS_ACTION
  });
  
  const loadOfferIdsSucceeded = offers => ({
    type: SUCCESS_LOAD_OFFER_IDS_ACTION,
    payload: {
      offers
    }
  });
  
  const loadOfferIdsFailure = errors => ({
    type: FAILURE_LOAD_OFFER_IDS_ACTION,
    payload: {
      errors
    }
  });

export const editSim = ({ simId, sim }) => async dispatch => {
  dispatch(editSimStart());

    return F.put(`/sim/${simId}`, {
      body: sim,
      contentType: ContentTypes.JSON
    }).then(
      () => dispatch(editSimSucceeded()),
      errors => {
        dispatch(editSimFailure({ errors }));
  
        throw errors;
      }
    );
};

const editSimStart = () => ({
  type: PENDING_UPDATE_SIM_ACTION
});

const editSimSucceeded = () => ({
  type: SUCCESS_UPDATE_SIM_ACTION
});

const editSimFailure = errors => ({
  type: FAILURE_UPDATE_SIM_ACTION,
  payload: {
    errors
  }
});


export const resetSim = () => ({
  type: RESET_SIM_STATE_ACTION
});

