import React, { Component } from "react";
import classNames from "classnames";

import I18nSpan from "../../i18n/components/I18nSpan";

import styles from "./KeyIndicator.css";

import Indicator, {
  TrendIndicator,
  IndicatorTitle,
  IndicatorValue
} from "../Indicator/Indicator";

interface Props {
  titleKey: string;
  args?: any;
  children: any;
  forbidden?: boolean;
  error?: boolean;
  color: string;
  loading: boolean;
  renderTrend: Function;
}

interface State {}

class KeyIndicator extends Component<Props, State> {
  render() {
    const {
      titleKey,
      args,
      children,
      forbidden,
      color,
      error,
      loading,
      renderTrend
    } = this.props;

    if (error) {
      return (
        <Indicator loading={loading} color={color}>
          <div>
            <IndicatorTitle titleKey={titleKey} args={args} />
            <div className={styles.error}>
              <I18nSpan msgKey={"notification.error"} />
            </div>
          </div>
        </Indicator>
      );
    }

    if (forbidden) {
      return (
        <Indicator loading={loading} color={color}>
          <div>
            <IndicatorTitle titleKey={titleKey} args={args} />
            <div className={styles.error}>Access forbidden</div>
          </div>
        </Indicator>
      );
    }
    return (
      <Indicator loading={loading} color={color}>
        <div className={classNames(styles["indicator-container"])}>
          <IndicatorValue>{children}</IndicatorValue>
          <IndicatorTitle titleKey={titleKey} args={args} />
          <TrendIndicator renderTrend={renderTrend} />
        </div>
      </Indicator>
    );
  }
}

export default KeyIndicator;
