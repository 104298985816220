import F from "../../commons/HTTPFetcher";
import * as ContentTypes from "../../commons/constants/HTTPContentTypes";
import {
  FAILURE_CREATE_PREDEFINED_ALERT_ACTION,
  PENDING_CREATE_PREDEFINED_ALERT_ACTION,
  SUCCESS_CREATE_PREDEFINED_ALERT_ACTION
} from "../actionsTypes";

export const createPredefinedAlert = ({ predefinedAlert }) => dispatch => {
  dispatch(createPredefinedAlertStart());

  return F.post("/fraud-alerts", {
    body: predefinedAlert,
    contentType: ContentTypes.JSON
  }).then(
    () => dispatch(createPredefinedAlertSucceeded()),
    errors => {
      dispatch(createPredefinedAlertFailure({ errors }));

      throw errors;
    }
  );
};

const createPredefinedAlertStart = () => ({
  type: PENDING_CREATE_PREDEFINED_ALERT_ACTION
});

const createPredefinedAlertSucceeded = () => ({
  type: SUCCESS_CREATE_PREDEFINED_ALERT_ACTION
});

const createPredefinedAlertFailure = (errors: any) => ({
  type: FAILURE_CREATE_PREDEFINED_ALERT_ACTION,
  payload: {
    errors
  }
});
